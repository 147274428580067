import { assertViewName } from '../assert-view-name'
import { RevenuePreviewProps } from '../components/revenue/preview'
import { download } from '../download'
import { t } from '../i18n'
import { getCreditRevenuePdfFilename, getRevenuePdfFilename } from '../revenue-utils'
import { getCompany } from '../state/company-actions'
import { createPreview, getCreditRevenueById, getRevenueById } from '../state/revenue-actions'
import { RootData } from '../state/root-data'

export const getRevenuePreviewProps = (rootData: RootData): RevenuePreviewProps => {
    const { companyData, invoiceData, session, view } = rootData
    const url = invoiceData.pdfBlobUrl || ''

    const isReady = Boolean(
        url && invoiceData.invoices && companyData.companies && invoiceData.pdfLanguage,
    )

    const props: RevenuePreviewProps = {
        isReady,
        buttons: [
            {
                onClick: () => history.back(),
                text: t.back.get(),
            },
        ],
        languageButtons: [],
        url,
    }

    if (isReady) {
        const { id } = assertViewName(view, 'RevenuePreview')
        const revenue = getRevenueById(invoiceData, id)!
        const company = getCompany(companyData, session)

        if (url) {
            const filename = getRevenuePdfFilename(company, revenue, invoiceData.pdfLanguage!)

            props.buttons.push({
                text: t.download.pdf.get(),
                onClick: () => download(url, filename),
            })
        }

        props.languageButtons.push({
            src: '/lang/en.svg',
            onClick: async () => createPreview(id, false, 'en'),
        })

        props.languageButtons.push({
            src: '/lang/ee.svg',
            onClick: async () => createPreview(id, false, 'et'),
        })
    }

    return props
}

export const getCreditRevenuePreviewProps = (rootData: RootData): RevenuePreviewProps => {
    const { companyData, invoiceData, creditRevenueData, session, view } = rootData
    const url = invoiceData.pdfBlobUrl || ''

    const isReady = Boolean(
        url && creditRevenueData.creditRevenues && companyData.companies && invoiceData.pdfLanguage,
    )

    const props: RevenuePreviewProps = {
        isReady,
        buttons: [
            {
                onClick: () => history.back(),
                text: t.back.get(),
            },
        ],
        languageButtons: [],
        url,
    }

    if (isReady) {
        const { id } = assertViewName(view, 'CreditRevenuePreview')
        const creditRevenue = getCreditRevenueById(creditRevenueData, id)!
        const company = getCompany(companyData, session)

        if (url) {
            const filename = getCreditRevenuePdfFilename(
                company,
                creditRevenue.number,
                invoiceData.pdfLanguage!,
            )

            props.buttons.push({
                text: t.download.pdf.get(),
                onClick: () => download(url, filename),
            })
        }

        props.languageButtons.push({
            src: '/lang/en.svg',
            onClick: async () => createPreview(id, true, 'en'),
        })

        props.languageButtons.push({
            src: '/lang/ee.svg',
            onClick: async () => createPreview(id, true, 'et'),
        })
    }

    return props
}
