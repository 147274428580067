export const shortenIpv6 = (ip: string | null) => {
    if (!ip) {
        return ''
    }

    const groups = ip.split(':')

    if (groups.length === 8) {
        // These groups seem useful for recognizing different machines in the same network.
        // The first two refer to the network and the last one to the machine.
        return groups[2] + ':' + groups[3] + '~' + groups[7]
    } else {
        // Not IPv6 (most likely IPv4), return original
        return ip
    }
}
