import { roundDown, roundToNearestCent, roundUp } from './amount-utils'
import { assertNever } from './assert-never'
import {
    assetChangeModes,
    assetTypes,
    calculationModes,
    expenseItemTypes,
    expenseItemTypesArray,
} from './enums'
import { Day } from './time'
import { AssetType, CalculationMode } from './types/enums'
import {
    AssetCurrent,
    AssetInitial,
    AssetValueChange,
    DbAsset,
    ExpenseItem,
    ExpenseItemType,
} from './types/expense'
import { AutomaticItem, Item, ItemType, ManualItem, Totals } from './types/item'
import { ExpenseVatPercentage } from './types/vat'

export const isAutomaticItem = <IT extends ItemType, N extends string | number>(
    _item: Item<IT, N, ExpenseVatPercentage>,
    calculationMode: CalculationMode,
): _item is AutomaticItem<IT, N, ExpenseVatPercentage> =>
    calculationMode === calculationModes.automatic

export const isManualItem = <IT extends ItemType, N extends string | number>(
    _item: Item<IT, N, ExpenseVatPercentage>,
    calculationMode: CalculationMode,
): _item is ManualItem<IT, N> => calculationMode === calculationModes.manual

export const isAutomaticItemArray = <IT extends ItemType, N extends string | number>(
    _items: Item<IT, N, ExpenseVatPercentage>[],
    calculationMode: CalculationMode,
): _items is AutomaticItem<IT, N, ExpenseVatPercentage>[] =>
    calculationMode === calculationModes.automatic

export const isManualItemArray = <IT extends ItemType, N extends string | number>(
    _items: Item<IT, N, ExpenseVatPercentage>[],
    calculationMode: CalculationMode,
): _items is ManualItem<IT, N>[] => calculationMode === calculationModes.manual

export const calculateTotalWithoutVat = (quantity: number, unitPrice: number) =>
    roundDown(quantity * unitPrice)

const calculateDiscount = (totalWithoutVat: number, discountPercentage: number) =>
    roundUp(totalWithoutVat * discountPercentage * 0.01)

const calculatePayableWithoutVat = (totalWithoutVat: number, discount: number) =>
    totalWithoutVat - discount

const calculateVatAmount = (payableWithoutVat: number, vatPercentage: number) =>
    roundToNearestCent(payableWithoutVat * vatPercentage * 0.01)

const calculatePayableWithVat = (payableWithoutVat: number, vat: number) => payableWithoutVat + vat

export const calculateAutomaticItemFromValues = (
    quantity: number,
    unitPrice: number,
    discountPercentage: number,
    vatPercentage: number,
): Totals<number> => {
    const totalWithoutVat = calculateTotalWithoutVat(quantity, unitPrice)
    const discount = calculateDiscount(totalWithoutVat, discountPercentage)
    const payableWithoutVat = calculatePayableWithoutVat(totalWithoutVat, discount)
    const vatAmount = calculateVatAmount(payableWithoutVat, vatPercentage)
    const payableWithVat = calculatePayableWithVat(payableWithoutVat, vatAmount)

    return {
        totalWithoutVat,
        discount,
        payableWithoutVat,
        vatAmount,
        payableWithVat,
    }
}

export const calculateManualItemFromValues = (
    totalWithoutVat: number,
    discountPercentage: number,
    vatPercentage: number,
): Totals<number> => {
    const discount = calculateDiscount(totalWithoutVat, discountPercentage)
    const payableWithoutVat = calculatePayableWithoutVat(totalWithoutVat, discount)
    const vatAmount = calculateVatAmount(payableWithoutVat, vatPercentage)
    const payableWithVat = calculatePayableWithVat(payableWithoutVat, vatAmount)

    return {
        totalWithoutVat,
        discount,
        payableWithoutVat,
        vatAmount,
        payableWithVat,
    }
}

const isExpenseItem = (
    item: Item<ItemType, number, ExpenseVatPercentage>,
): item is ExpenseItem<number> => expenseItemTypesArray.includes(item.type as ExpenseItemType)

const isStockItem = (
    item: Item<ItemType, number, ExpenseVatPercentage>,
): item is ExpenseItem<number> =>
    Boolean(isExpenseItem(item) && item.type === expenseItemTypes.goodsStock)

// Use MIN_DATE as refDate for the initial total before any stock changes
export const getItemQuantity = <T extends ItemType>(
    item: Item<T, number, ExpenseVatPercentage>,
    refDate: string,
): number => {
    if (isStockItem(item)) {
        let { quantity } = item

        for (const change of item.stockChanges || []) {
            if (change.date > refDate) {
                break
            } else if (typeof change.newQuantity === 'number') {
                quantity = change.newQuantity
            }
        }

        return quantity
    } else {
        return item.quantity
    }
}

export const calculateManualItemUnitPriceFromValues = (
    quantity: number,
    payableWithoutVat: number,
) => {
    if (quantity === 0) {
        return 0
    } else {
        const unitPrice = payableWithoutVat / quantity
        return roundToNearestCent(unitPrice)
    }
}

const calculateManualItemUnitPrice = (item: ManualItem<any, number>): number => {
    const { quantity, payableWithoutVat } = item
    return calculateManualItemUnitPriceFromValues(quantity, payableWithoutVat)
}

const getInitialUnitPrice = <T extends ItemType>(
    calculationMode: CalculationMode,
    item: AutomaticItem<T, number, ExpenseVatPercentage> | ManualItem<T, number>,
): number => {
    if (isAutomaticItem(item, calculationMode)) {
        return item.unitPrice
    } else {
        return calculateManualItemUnitPrice(item)
    }
}

// Use MIN_DATE as refDate for the initial total before any stock changes
export const getItemUnitPrice = <T extends ItemType>(
    calculationMode: CalculationMode,
    item: AutomaticItem<T, number, ExpenseVatPercentage> | ManualItem<T, number>,
    refDate: string,
): number => {
    let unitPrice = getInitialUnitPrice(calculationMode, item)

    if (isStockItem(item)) {
        for (const change of item.stockChanges || []) {
            if (change.date > refDate) {
                break
            } else if (typeof change.newUnitPrice === 'number') {
                unitPrice = change.newUnitPrice
            }
        }
    }
    return unitPrice
}

const expenseVatPercentageToNumber = (vatPercentage: ExpenseVatPercentage) => vatPercentage || 0

// Use MIN_DATE as refDate for the initial total before any stock changes
export const calculateAutomaticItem = <T extends ItemType>(
    item: AutomaticItem<T, number, ExpenseVatPercentage> | ManualItem<T, number>,
    refDate: string,
    /**
     * In case of stock changes, we sometimes have to
     * combine manual and automatic calculation logic.
     */
    calculationMode: CalculationMode = calculationModes.automatic,
): Totals<number> => {
    const { discount, vatPercentage } = item
    const quantity = getItemQuantity(item, refDate)
    const unitPrice = getItemUnitPrice(calculationMode, item, refDate)
    const numVatPercentage = expenseVatPercentageToNumber(vatPercentage)
    return calculateAutomaticItemFromValues(quantity, unitPrice, discount, numVatPercentage)
}

// Use MIN_DATE as refDate for the initial total before any stock changes
export const calculateManualItem = <T extends ItemType>(
    item: ManualItem<T, number>,
    refDate: string,
): Totals<number> => {
    if (isStockItem(item) && item.stockChanges) {
        const changesToApply = item.stockChanges.filter((change) => change.date <= refDate)

        if (changesToApply.length) {
            return calculateAutomaticItem(item, refDate, calculationModes.manual)
        }
    }

    const { payableWithoutVat: totalWithoutVat, discount: discountPercentage, vatPercentage } = item
    return calculateManualItemFromValues(totalWithoutVat, discountPercentage, vatPercentage || 0)
}

// Use MIN_DATE as refDate for the initial total before any stock changes
export const calculateItem = <T extends ItemType>(
    calculationMode: CalculationMode,
    item: AutomaticItem<T, number, ExpenseVatPercentage> | ManualItem<T, number>,
    refDate: string,
): Totals<number> => {
    if (isAutomaticItem(item, calculationMode)) {
        return calculateAutomaticItem(item, refDate)
    } else {
        return calculateManualItem(item, refDate)
    }
}

export const calculateAssetInitialFromValues = (
    withoutVat: number,
    other: number,
    vatPercentage: number,
): AssetInitial => {
    const payableWithoutVat = withoutVat + other
    const vatAmount = roundToNearestCent(payableWithoutVat * vatPercentage * 0.01)
    const withVat = payableWithoutVat + vatAmount
    return { payableWithoutVat, vatAmount, withVat }
}

export const calculateResidualPercentage = (refDate: Day, startingDate: Day, eol: Day) => {
    let residualPercentage = 1 // 100%

    if (refDate.isAfter(startingDate)) {
        if (refDate.isBefore(eol)) {
            const daysLeft = eol.diffDays(refDate)
            const totalDays = eol.diffDays(startingDate)
            residualPercentage = daysLeft / totalDays
        } else {
            residualPercentage = 0
        }
    }

    return residualPercentage
}

export const calculateAssetCurrentFromValues = (
    refDate: Day,
    type: AssetType,
    initialValue: number, // AssetInitial.payableWithoutVat
    amortBegin: string | undefined,
    eolDate: string | undefined,
    changes: AssetValueChange<number>[],
): AssetCurrent => {
    let startingValue = initialValue
    let startingDate = amortBegin ? Day.fromYmd(amortBegin).addDays(-1) : undefined
    let eol = eolDate ? Day.fromYmd(eolDate) : undefined

    // We assume the changes are ordered from oldest to newest
    for (const change of changes) {
        if (refDate.isBefore(Day.fromYmd(change.date))) {
            // Ignore any changes after reference date
            break
        }

        if (change.mode === assetChangeModes.residual) {
            startingValue = change.residual
            startingDate = Day.fromYmd(change.date)
        } else if (change.mode === assetChangeModes.eol) {
            // Calculate residual value at the time of this change
            const newStartingDate = Day.fromYmd(change.date)
            const residualPercentage = calculateResidualPercentage(
                newStartingDate,
                startingDate!,
                eol!,
            )

            startingDate = newStartingDate
            startingValue *= residualPercentage
            eol = Day.fromYmd(change.eolDate)
        } else {
            throw assertNever(change, 'asset change mode', (change as { mode?: string }).mode)
        }
    }

    let amortRate = 0
    let residualPercentage = 1 // 100%

    if (type !== assetTypes.land) {
        // Land does not depreciate
        if (!startingDate) {
            throw new Error('Starting date is missing')
        }

        if (!eol) {
            throw new Error('EoL date is missing')
        }

        // Amort rate is only calculated for display and never used as an input for
        // further calculations, therefore the formula is a bit simplified.
        const remainingYears = eol.diffDays(startingDate) / 365.25
        const depreciationPerYear = startingValue / remainingYears
        amortRate = depreciationPerYear / initialValue

        if (initialValue === 0) {
            // Most likely it's a new asset being added and the value hasn't been entered yet.
            // In this case, remainingYears is equal to the total lifetime.
            // This formula matches what the amort rate will be, without dividing by zero.
            amortRate = 1 / remainingYears
        }

        residualPercentage = calculateResidualPercentage(refDate, startingDate, eol)
    }

    const residual = roundToNearestCent(startingValue * residualPercentage)
    const depreciation = initialValue - residual
    return { amortRate, eolDate: eol, residual, depreciation }
}

export const calculateAssetInitial = (asset: DbAsset): AssetInitial => {
    const { withoutVat, other, vatPercentage } = asset.totals
    const numVatPercentage = expenseVatPercentageToNumber(vatPercentage)
    return calculateAssetInitialFromValues(withoutVat, other, numVatPercentage)
}

export const calculateAssetCurrent = (refDate: Day, asset: DbAsset): AssetCurrent => {
    const { type, amortBegin, eolDate, valueChanges } = asset
    const { payableWithoutVat: initialValue } = calculateAssetInitial(asset)

    return calculateAssetCurrentFromValues(
        refDate,
        type,
        initialValue,
        amortBegin,
        eolDate,
        valueChanges,
    )
}

export const calculateTotalsFromSubtotals = (subtotals: Totals<number>[]): Totals<number> => {
    const totals = {
        totalWithoutVat: 0,
        discount: 0,
        payableWithoutVat: 0,
        vatAmount: 0,
        payableWithVat: 0,
    }

    for (const {
        totalWithoutVat,
        discount,
        payableWithoutVat,
        vatAmount,
        payableWithVat,
    } of subtotals) {
        totals.totalWithoutVat += totalWithoutVat
        totals.discount += discount
        totals.payableWithoutVat += payableWithoutVat
        totals.vatAmount += vatAmount
        totals.payableWithVat += payableWithVat
    }

    return totals
}

// Use MIN_DATE as refDate for the initial total before any stock changes
export const calculateTotalsFromAutomaticItems = <T extends ItemType>(
    items: AutomaticItem<T, number, ExpenseVatPercentage>[],
    refDate: string,
) => {
    const subtotals = items.map((item) => calculateAutomaticItem(item, refDate))
    return calculateTotalsFromSubtotals(subtotals)
}

// Use MIN_DATE as refDate for the initial total before any stock changes
const calculateTotalsFromManualItems = (
    items: ManualItem<any, number>[],
    refDate: string,
): Totals<number> => {
    const subtotals = items.map((item) => calculateManualItem(item, refDate))
    return calculateTotalsFromSubtotals(subtotals)
}

// Use MIN_DATE as refDate for the initial total before any stock changes
export const calculateTotalsFromItems = <T extends ItemType>(
    calculationMode: CalculationMode,
    items: Item<T, number, ExpenseVatPercentage>[],
    refDate: string,
): Totals<number> => {
    if (isAutomaticItemArray(items, calculationMode)) {
        return calculateTotalsFromAutomaticItems(items, refDate)
    } else if (isManualItemArray(items, calculationMode)) {
        return calculateTotalsFromManualItems(items, refDate)
    } else {
        throw new Error('Unexpected calculation mode: ' + calculationMode)
    }
}

export const getTotalsFromAssetInitial = (assetCalc: AssetInitial): Totals<number> => {
    const { payableWithoutVat, vatAmount, withVat } = assetCalc

    return {
        totalWithoutVat: payableWithoutVat,
        discount: 0,
        payableWithoutVat,
        vatAmount,
        payableWithVat: withVat,
    }
}

export const calculateTotalsFromAssets = (assets: DbAsset[]) => {
    const subtotals = assets.map(calculateAssetInitial).map(getTotalsFromAssetInitial)
    return calculateTotalsFromSubtotals(subtotals)
}
