import { CardPaymentStatus } from './enums'
import { calculateAutomaticItemFromValues } from './item-utils'
import { Day } from './time'

export const isSuccessful = (status: CardPaymentStatus | undefined) => {
    return status === CardPaymentStatus.settled
}

export const getAmountWithoutVat = (isVatPayer: boolean, vatPercentage: number) => {
    if (isVatPayer) {
        return 6.99
    }

    // For non-VAT payers, we adjust the base price so the final price would be as close to 6.99 as possible
    // 5.82 * 1.20 ~= 6.98
    // 5.73 * 1.22 ~= 6.99
    return vatPercentage === 20 ? 5.82 : 5.73
}

export const getAmountWithVat = (isVatPayer: boolean, vatPercentage: number) => {
    const amountWithoutVat = getAmountWithoutVat(isVatPayer, vatPercentage)
    const totals = calculateAutomaticItemFromValues(1, amountWithoutVat, 0, vatPercentage)
    return totals.payableWithVat
}

export const getPaymentDate = (firstPayment: Day, monthsPaid: number) => {
    const dayOfMonth = firstPayment.dayOfMonth()

    const endOfPaid = firstPayment.firstOfMonth().addMonths(monthsPaid).lastOfMonth()

    if (endOfPaid.dayOfMonth() > dayOfMonth) {
        return endOfPaid.withDayOfMonth(dayOfMonth)
    } else {
        return endOfPaid
    }
}
