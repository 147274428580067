export const MAX_BALANCE_DATES = 100
export const MAX_REPORT_PERIODS = 100
export const MAX_ROWS = 10
export const MB = 1024 * 1024
export const MAX_UPLOAD_MB = 5
export const MAX_COMPANY_STORAGE_MB = 100
export const MIN_PASSWORD_LENGTH = 8
export const MIN_REGISTRATION_DATE = '1990-01-01'
export const REGEX_NON_NEGATIVE_AMOUNT = /^(0|[1-9]\d*)(\.\d{1,2})?$/
export const SESSION_RENEWALS = 2
export const SESSION_RENEW_MINUTES = 15
export const MAX_BANK_ACCOUNTS = 3
export const VAT_WARNING_THRESHOLD = 35000
