import * as api from '../api'
import { inputs } from '../inputs'
import { setRoute } from '../route-utils'
import { run } from './process-actions'
import { getState } from './store'
import { clearErrors } from './validation-actions'

export const PASSWORD_RESET_INIT_PROCESS = 'password-reset/init'
export const PASSWORD_RESET_PROCESS = 'password-reset'

export const initEmailForm = () => {
    clearErrors(PASSWORD_RESET_INIT_PROCESS)
    const { inputValues } = getState()
    const email = inputs.login.email.get(inputValues)
    inputs.passwordReset.email.set(email)
}

export const initChangeForm = () => {
    clearErrors(PASSWORD_RESET_PROCESS)
}

export const initReset = async () =>
    run(PASSWORD_RESET_INIT_PROCESS, async () => {
        const { inputValues } = getState()
        const email = inputs.passwordReset.email.get(inputValues)
        await api.initPasswordReset(email)
        setRoute('#/password-reset/pending/' + encodeURIComponent(email))
    })

export const reset = async (resetCode: string) =>
    run(PASSWORD_RESET_PROCESS, async () => {
        const { inputValues } = getState()
        const password = inputs.passwordReset.password.get(inputValues)
        await api.resetPassword(resetCode, password)
        setRoute('#/password-reset/success')
    })
