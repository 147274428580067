import Immutable from 'immutable'

import { Time } from '../../common/time'
import { CommandFilters } from '../../common/types/command'
import * as api from '../api'
import { inputs } from '../inputs'
import { prepareForm } from './form-actions'
import { dispatch, getState } from './store'

const invalidateCache = () =>
    dispatch(({ commandData }) => {
        commandData.commands = null
        // @ts-expect-error Looks like a TypeScript bug
        commandData.expanded = Immutable.Set<string>()
    })

const load = async () => {
    const { inputValues } = getState()

    const filters: CommandFilters = {}

    if (inputs.commandLog.filterTime.get(inputValues)) {
        const { center, range } = inputs.commandLog.time.get(inputValues)
        filters.from = center.clone().subtract(range, 'seconds').toISOString()
        filters.to = center.clone().add(range, 'seconds').toISOString()
    }

    if (inputs.commandLog.filterIp.get(inputValues)) {
        filters.ip = inputs.commandLog.ip.get(inputValues)
    }

    if (inputs.commandLog.filterUser.get(inputValues)) {
        filters.user = inputs.commandLog.user.get(inputValues)
    }

    if (inputs.commandLog.filterCommand.get(inputValues)) {
        filters.command = inputs.commandLog.command.get(inputValues)
    }

    if (inputs.commandLog.filterObject.get(inputValues)) {
        filters.type = inputs.commandLog.object.type.get(inputValues)
        filters.id = inputs.commandLog.object.id.get(inputValues)
    }

    const commands = await api.loadCommands(filters)

    dispatch(({ commandData }) => {
        commandData.commands = commands
        // @ts-expect-error Looks like a TypeScript bug
        commandData.expanded = Immutable.Set()
    })
}

export const initForm = async () =>
    prepareForm('command-filter', async () => {
        // Default to last 24 hours
        inputs.commandLog.time.set({
            center: Time.now().addHours(-12).asLocal().toMoment(),
            range: 12 * 60 * 60,
        })

        void load()

        const errorInfo = await api.getErrorInfo()
        dispatch(({ commandData }) => (commandData.errorInfo = errorInfo))
    })

export const toggle = (id: string) =>
    dispatch(({ commandData }) => {
        if (commandData.expanded.has(id)) {
            // @ts-expect-error Looks like a TypeScript bug
            commandData.expanded = commandData.expanded.delete(id)
        } else {
            commandData.expanded = commandData.expanded.add(id)
        }
    })

export const applyFilters = async () => {
    invalidateCache()
    await load()
}

export const processErrors = async () =>
    prepareForm('command-filter', async () => {
        await api.processErrors()
        invalidateCache()
        const [errorInfo] = await Promise.all([api.getErrorInfo(), load()])
        dispatch(({ commandData }) => (commandData.errorInfo = errorInfo))
    })
