import React, { ReactNode } from 'react'

import { MIN_DATE } from '../common/clock'
import { expenseItemTypes, expenseTypes } from '../common/enums'
import { findById } from '../common/find-by-id'
import { calculateTotalsFromAssets, calculateTotalsFromItems } from '../common/item-utils'
import { keys } from '../common/keys'
import { sort } from '../common/sort'
import { Day } from '../common/time'
import { BusinessFields } from '../common/types/business'
import { ApiExpense, FilterSection } from '../common/types/expense'
import { ChoiceOption, Input, InputValues } from '../common/types/inputs'
import { Column } from '../common/types/table'
import { Button } from './components/button'
import type { BaseRow } from './components/table'
import { ViewIcon } from './components/view-icon'
import { t, withElements } from './i18n'
import { inputs } from './inputs'
import { getDueDateDescription } from './payment-utils'
import { renderAmountOrDash } from './render-amount'
import { renderExcelMoney } from './render-excel-money'
import { browserOnly } from './table-utils'

interface ExpenseFilter {
    id: string
    inputs: Input<string>[]
    predicate: (expense: ApiExpense, ...values: string[]) => boolean
    label: string
    render: (...values: string[]) => ReactNode
}

interface ActiveFilter {
    filter: ExpenseFilter
    values: string[]
}

export interface Row extends BaseRow {
    id: string
    vendor: BusinessFields<string>
    date: string
    term: number
    withoutVat: number
    withVat: number
    number?: string
    paid?: boolean
}

const archiveInputs = inputs.expense.archive
const vendorInputs = archiveInputs.vendor

export const getStatusOptions = (): ChoiceOption<string>[] => [
    { id: 'confirmed', label: t.confirmed.get() },
    { id: 'unconfirmed', label: t.unconfirmed.get() },
]

export const getDueDateOptions = (): ChoiceOption<string>[] => [
    { id: 'paid', label: t.expenses.paidInvoices.get() },
    { id: 'unpaid', label: t.expenses.unpaidInvoices.get() },
    { id: 'not-overdue', label: t.dueDate.future.get() },
    { id: 'overdue', label: t.expenses.overdue.get() },
]

export const getTermOptions = (): ChoiceOption<string>[] => {
    const days = '21'
    const numberSpan = React.createElement('span', { className: 'numeric' }, days)

    return [
        {
            id: 'up-to-21',
            label: t.archive.term.upTo.get(days),
            renderButtonContents: () => withElements(t.archive.term.upTo, numberSpan),
        },
        {
            id: 'over-21',
            label: t.archive.term.over.get(days),
            renderButtonContents: () => withElements(t.archive.term.over, numberSpan),
        },
    ]
}

export const getTypeOptions = (): ChoiceOption<string>[] => [
    { id: 'general', label: t.expenses.general.get() },
    { id: 'goods', label: t.expenses.goods.get() },
    { id: 'stock', label: t.expenses.stock.get() },
    { id: 'asset', label: t.asset.get() },
]

const renderChoice = (choice: string, options: ChoiceOption<string>[]) => {
    const option = findById(options, choice)

    if (!option) {
        throw new Error('Option not found: ' + choice)
    }

    return option.label
}

const getFilters = (): { [section in FilterSection]: ExpenseFilter[] } => ({
    general: [
        {
            id: 'status',
            inputs: [archiveInputs.status],
            predicate: (expense, confirmStatus) =>
                expense.confirmed === (confirmStatus === 'confirmed'),
            label: t.status.get(),
            render: (confirmStatus) => renderChoice(confirmStatus, getStatusOptions()),
        },
        {
            id: 'date',
            inputs: [archiveInputs.date.from, archiveInputs.date.to],
            predicate: (expense, from, to) =>
                (expense.date >= from || !from) && (expense.date <= to || !to),
            label: t.invoices.date.get(),
            render: (from: string, to: string) => {
                const fromStr = from ? Day.fromYmd(from).longDate() : '...'
                const toStr = to ? Day.fromYmd(to).longDate() : '...'
                return fromStr + ' - ' + toStr
            },
        },
        {
            id: 'dueDate',
            inputs: [archiveInputs.dueDate],
            predicate: (expense, dueDate) => {
                if (dueDate === 'paid' && !expense.paid) {
                    return false
                } else if (dueDate === 'unpaid' && expense.paid) {
                    return false
                }

                const overdue = Day.today().isAfter(Day.fromYmd(expense.dueDate))

                if (dueDate === 'not-overdue' && (expense.paid || overdue)) {
                    return false
                } else if (dueDate === 'overdue' && (expense.paid || !overdue)) {
                    return false
                }

                return true
            },
            label: t.dueDate.get(),
            render: (dueDate) => renderChoice(dueDate, getDueDateOptions()),
        },
        {
            id: 'term',
            inputs: [archiveInputs.term],
            predicate: (expense, term) => {
                const expTerm = Day.fromYmd(expense.dueDate).diffDays(Day.fromYmd(expense.date))

                return (
                    (expTerm <= 21 || term !== 'up-to-21') && (expTerm > 21 || term !== 'over-21')
                )
            },
            label: t.term.get(),
            render: (term) => renderChoice(term, getTermOptions()),
        },
    ],
    vendor: [
        {
            id: 'business',
            inputs: [vendorInputs.regCode, vendorInputs.name],
            predicate: (expense, regCode, _name) => expense.vendor.regCode === regCode,
            label: t.business.get(),
            render: (_regCode, businessName) => businessName,
        },
    ],
    types: [
        {
            id: 'type',
            inputs: [archiveInputs.type],
            predicate: (expense, type) => {
                if (type === 'general' || type === 'goods' || type === 'stock') {
                    // Further filtering will be done on the expense item level in these cases.
                    return expense.type === expenseTypes.regular
                } else {
                    return expense.type === type
                }
            },
            label: t.type.get(),
            render: (type) => renderChoice(type, getTypeOptions()),
        },
    ],
})

const getActiveFilters = (inputValues: InputValues): ActiveFilter[] => {
    const filters = getFilters()
    const activeFilters = []

    for (const section of keys(filters)) {
        for (const filter of filters[section]) {
            const values = filter.inputs.map((input) => input.get(inputValues))

            if (values.some(Boolean)) {
                activeFilters.push({ filter, values })
            }
        }
    }

    return activeFilters
}

export const renderFilterSummary = (inputValues: InputValues) => {
    let summary
    const activeFilters = getActiveFilters(inputValues)

    if (activeFilters.length) {
        summary = activeFilters.map(({ filter: { id, label, render }, values }) =>
            React.createElement(
                'div',
                { key: id },
                React.createElement('b', null, label, ':'),
                ' ',
                render(...values),
            ),
        )
    } else {
        summary = t.archive.filterSummary.none.get()
    }

    return React.createElement(
        'div',
        null,
        React.createElement(
            'div',
            { className: 'archive__filter-summary-title' },
            t.archive.filterSummary.get(),
            ':',
        ),
        React.createElement('div', { className: 'archive__filter-summary' }, summary),
    )
}

const getLeftAligned = () => ({ className: 'text-left' })
const getRightAligned = () => ({ className: 'text-right' })

export const getColumns = (inputValues: InputValues) => {
    const columns: Column<Row>[] = [
        {
            header: { content: t.date.get(), getProps: getLeftAligned },
            render: (row) => {
                const date = Day.fromYmd(row.date)
                return { browser: date.dmy(), excel: date }
            },
            excelWidth: 12,
        },
        {
            header: { content: t.invoices.number.get(), getProps: getRightAligned },
            getProps: getRightAligned,
            render: (row) => ({
                browser: row.number,
                excel: {
                    value: row.number || '',
                    style: { alignment: { horizontal: 'right' } },
                },
            }),
            excelWidth: 13,
        },
        {
            header: { content: t.expenses.vendor.get(), getProps: getLeftAligned },
            render: (row) => row.vendor.name,
            excelWidth: 30,
        },
        {
            header: { content: t.vat.without.get(), getProps: getRightAligned },
            getProps: () => ({ className: 'amount' }),
            render: (row) => ({
                browser: renderAmountOrDash(row.withoutVat),
                excel: renderExcelMoney(row.withoutVat, false),
            }),
            excelWidth: 14,
        },
    ]

    const term = archiveInputs.term.get(inputValues)

    if (term) {
        columns.push({
            header: { content: t.term.get(), getProps: getLeftAligned },
            render: (row) => ({
                browser: row.term + ' ' + (row.term === 1 ? t.day.get() : t.days.get()),
                excel: row.term,
            }),
            excelWidth: 15,
        })
    }

    const dueDate = archiveInputs.dueDate.get(inputValues)

    if (dueDate) {
        columns.push(
            {
                header: { content: t.vat.with.get(), getProps: getRightAligned },
                getProps: () => ({ className: 'amount' }),
                render: (row) => ({
                    browser: renderAmountOrDash(row.withVat),
                    excel: renderExcelMoney(row.withVat, false),
                }),
                excelWidth: 14,
            },
            {
                header: { content: t.dueDate.get(), getProps: getLeftAligned },
                render: (row) => {
                    // TODO util that takes dueDate instead of date + term.
                    // No point in calculating term from dueDate and then dueDate from term.
                    return getDueDateDescription(Boolean(row.paid), row.date, row.term)
                },
                excelWidth: 20,
            },
        )
    }

    columns.push({
        header: {
            content: t.actions.get(),
            getProps: () => ({ className: 'text-center' }),
        },
        getProps: () => ({ className: 'text-center' }),
        render: browserOnly(({ id }) =>
            React.createElement(ViewIcon, { href: '#/expenses/view/' + id }),
        ),
        hideInExcel: true,
    })

    return columns
}

const filterExpenses = (expenses: ApiExpense[], activeFilters: ActiveFilter[]) =>
    expenses.filter((expense) =>
        activeFilters.every(({ filter, values }) => filter.predicate(expense, ...values)),
    )

const getTotals = (expense: ApiExpense, typeFilter: string | null) => {
    if (expense.type === expenseTypes.regular) {
        let items = expense.items!

        if (typeFilter === 'goods') {
            items = items.filter((item) => item.type === expenseItemTypes.goodsExpense)
        } else if (typeFilter === 'stock') {
            items = items.filter((item) => item.type === expenseItemTypes.goodsStock)
        }

        if (!items.length) {
            return null
        }

        return calculateTotalsFromItems(expense.calculationMode, items, MIN_DATE)
    } else if (expense.type === expenseTypes.asset) {
        return calculateTotalsFromAssets(expense.assets!)
    } else {
        throw new Error('Unexpected type: ' + expense.type)
    }
}

export const getRows = (expenses: ApiExpense[], inputValues: InputValues): Row[] => {
    const activeFilters = getActiveFilters(inputValues)
    const filteredExpenses = filterExpenses(expenses, activeFilters)
    const rows = []
    let typeFilter = null

    for (const { filter, values } of activeFilters) {
        if (filter.id === 'type') {
            typeFilter = values[0]
        }
    }

    for (const expense of filteredExpenses) {
        const { _id, vendor, date, dueDate, number, paid } = expense
        const totals = getTotals(expense, typeFilter)

        if (!totals) {
            continue
        }

        const { payableWithoutVat: withoutVat, payableWithVat: withVat } = totals
        const term = Day.fromYmd(dueDate).diffDays(Day.fromYmd(date))
        rows.push({ id: _id, vendor, date, withoutVat, withVat, term, number, paid })
    }

    // TODO additional sort levels?
    return sort(rows, [{ getKey: (row) => row.date, reverse: true }])
}

const clearFilters = (section: FilterSection, inputValues: InputValues) => {
    for (const filter of getFilters()[section]) {
        for (const input of filter.inputs) {
            if (input.hasValue(inputValues)) {
                input.set('')
            }
        }
    }
}

export const renderClearFiltersButton = (section: FilterSection, inputValues: InputValues) => {
    const anyToClear = getFilters()[section].some((filter) =>
        filter.inputs.some((input) => Boolean(input.get(inputValues))),
    )

    if (anyToClear) {
        return React.createElement(Button, {
            text: t.expenses.archive.clearFilter[section].get(),
            onClick: () => clearFilters(section, inputValues),
            className: 'button--secondary',
        })
    } else {
        return null
    }
}
