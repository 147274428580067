import { AccountData, AccountType } from '../common/types/account'
import { t, TranslationWithDefault } from './i18n'
import { getByNumber } from './state/account-actions'

export const getDefaultAccountName = (number: string): string => {
    let level3Number = number
    const levels = number.split('.')

    if (levels.length === 4) {
        if (levels[3] === '1') {
            level3Number = levels[0] + '.' + levels[1] + '.' + levels[2]
        } else {
            throw new Error('Non-default account number: ' + number)
        }
    }

    const translation = t.account[level3Number as keyof typeof t.account] as TranslationWithDefault
    // TODO report warning if missing
    return translation ? translation.get() : 'Account ' + level3Number
}

export const getAccountName = (
    level3Number: string,
    number: number,
    accountData: AccountData,
    type: AccountType,
) => {
    if (number === 1) {
        return getDefaultAccountName(level3Number)
    } else {
        const account = getByNumber(accountData, type, number)!
        return account.name
    }
}
