import { ReactNode } from 'react'

import { Column, TableValue } from '../common/types/table'

export interface TableSpec<Row> {
    columns: Column<Row>[]
    rows: Row[]
}
type RowSpecs<Rows> = { [Row in keyof Rows]: TableSpec<Rows[Row]> }

interface CombinedRow<Rows> {
    originalRows: Rows
    reactKey?: string
    domId?: string
    className?: string
    onClick?: () => void
    domTitle?: string
}

interface WrappedRow<Row> {
    specIndex: number
    originalRow: Row
}

export const browserOnly = <Row>(renderForBrowser: (row: Row) => ReactNode) => {
    return (row: Row): TableValue => ({
        browser: renderForBrowser(row),
        excel: null,
    })
}

export const combineHorizontally = <Rows extends unknown[]>(
    ...specs: RowSpecs<Rows>
): TableSpec<CombinedRow<Rows>> => {
    const combinedColumns: Column<CombinedRow<Rows>>[] = []

    for (const [specIndex, { columns }] of specs.entries()) {
        for (const column of columns) {
            const wrappedColumn: Column<CombinedRow<Rows>> = {
                ...column,
                getProps: (combinedRow, context) => {
                    if (column.getProps) {
                        const originalRow = combinedRow.originalRows[specIndex]
                        return originalRow ? column.getProps(originalRow, context) : null
                    } else {
                        return null
                    }
                },
                render: (combinedRow) => {
                    const originalRow = combinedRow.originalRows[specIndex]
                    return originalRow ? column.render(combinedRow.originalRows[specIndex]) : ''
                },
            }

            combinedColumns.push(wrappedColumn)
        }
    }

    const combinedRows: CombinedRow<Rows>[] = []
    const maxRowCount = specs.reduce((max, { rows }) => Math.max(max, rows.length), 0)

    for (let rowIndex = 0; rowIndex < maxRowCount; rowIndex += 1) {
        const combinedRow: CombinedRow<Rows> = { originalRows: [] as unknown as Rows }

        for (const [specIndex, { rows }] of specs.entries()) {
            const originalRow = rows[rowIndex]
            combinedRow.originalRows.push(originalRow)

            const origAny: any = originalRow || {}

            if (specIndex === 0) {
                combinedRow.reactKey = origAny.reactKey as string | undefined
                combinedRow.domId = origAny.domId as string | undefined
                combinedRow.className = origAny.className as string | undefined
                combinedRow.onClick = origAny.onClick as (() => void) | undefined
                combinedRow.domTitle = origAny.domTitle as string | undefined
            } else {
                if (origAny.reactKey !== combinedRow.reactKey) {
                    throw new Error('reactKey must be identical on each combined row')
                }

                if (origAny.domId !== combinedRow.domId) {
                    throw new Error('domId must be identical on each combined row')
                }

                if (origAny.className !== combinedRow.className) {
                    throw new Error('className must be identical on each combined row')
                }

                if (origAny.onClick !== combinedRow.onClick) {
                    throw new Error('onClick must be identical on each combined row')
                }

                if (origAny.domTitle !== combinedRow.domTitle) {
                    throw new Error('domTitle must be identical on each combined row')
                }
            }
        }

        combinedRows.push(combinedRow)
    }

    return { columns: combinedColumns, rows: combinedRows }
}

const getExcelWidth = (spec: TableSpec<any>, colIndex: number) => {
    const column = spec.columns[colIndex] || {}
    return column.excelWidth || 0
}

export const combineVertically = <Row>(...specs: TableSpec<Row>[]): TableSpec<WrappedRow<Row>> => {
    const combinedColumns: Column<WrappedRow<Row>>[] = []
    const maxColCount = specs.reduce((max, { columns }) => Math.max(max, columns.length), 0)

    for (let colIndex = 0; colIndex < maxColCount; colIndex += 1) {
        const combinedColumn: Column<WrappedRow<Row>> = {
            getProps: (wrappedRow, context) => {
                const originalColumn = specs[wrappedRow.specIndex].columns[colIndex]

                return originalColumn?.getProps
                    ? originalColumn.getProps(wrappedRow.originalRow, context)
                    : null
            },
            render: (wrappedRow) => {
                const originalColumn = specs[wrappedRow.specIndex].columns[colIndex]
                return originalColumn ? originalColumn.render(wrappedRow.originalRow) : ''
            },
            excelWidth: Math.max(...specs.map((spec) => getExcelWidth(spec, colIndex))),
        }

        combinedColumns.push(combinedColumn)
    }

    const combinedRows: WrappedRow<Row>[] = []

    for (const [specIndex, { rows }] of specs.entries()) {
        for (const originalRow of rows) {
            combinedRows.push({ specIndex, originalRow })
        }
    }

    return { columns: combinedColumns, rows: combinedRows }
}
