import {
    calculationModes,
    CompanyRole,
    EntryItemType,
    InitDateType,
    loginModes,
    UrlCommand,
} from '../common/enums'
import { CommandName } from '../common/types/api'
import {
    AssetType,
    BalanceDateMode,
    CalculationMode,
    LoginMode,
    MoneyForm,
    ReportMode,
    ReportPeriodMode,
    Unit,
} from '../common/types/enums'
import { ExpenseItemType } from '../common/types/expense'
import { RevenueItemType } from '../common/types/invoice'
import { MonthNumber } from '../common/types/month-number'
import { SortId as AssetListSortId } from './components/asset-list'
import { SortId as ExpenseRegisterSortId } from './components/expense/register'
import { SortId as UnpaidExpenseSortId } from './components/expense/unpaid'
import { SortId as GeneralExpenseListSortId } from './components/general-expense-list'
import { SortId as GoodsListSortId } from './components/goods-list'
import { RevenueRegisterSortId } from './components/revenue/register'
import { SortId as UnpaidInvoiceSortId } from './components/revenue/unpaid'
import { SortId as StockListSortId } from './components/stock-list'
import { TimeRangeInput } from './components/time-range'
import { BOOL, getPlaceholder, NUM, STR, transform } from './input-utils'

export const inputs = transform({
    changeCompany: {
        password: STR,
    },
    commandLog: {
        filterTime: BOOL,
        time: getPlaceholder<TimeRangeInput>('object'),
        filterIp: BOOL,
        ip: STR,
        filterUser: BOOL,
        user: STR,
        filterCommand: BOOL,
        command: getPlaceholder<CommandName | UrlCommand>('string'),
        filterObject: BOOL,
        object: { type: STR, id: STR },
    },
    dashboard: {
        month: STR,
    },
    entries: {
        row: (_id: string) => ({ expanded: BOOL }),
    },
    entry: {
        amount: STR,
        date: STR,
        description: STR,
        item: (_id: string) => ({
            type: getPlaceholder<EntryItemType>('string'),
            amount: STR,
            accountNumber: STR,
        }),
    },
    expense: {
        canClear: BOOL,
        rev: NUM,
        calculationMode: getPlaceholder<CalculationMode>('string', calculationModes.automatic),
        number: STR,
        date: STR,
        dueDate: STR,
        vendor: {
            region: {
                ee: getPlaceholder<'yes' | 'no'>('string'), // TODO button group with Input<boolean>
                eu: getPlaceholder<'yes' | 'no'>('string'), // TODO button group with Input<boolean>
            },
            name: STR,
            regCode: STR,
            address: STR,
            vatId: STR,
        },
        item: (_id: string) => ({
            // TODO undup
            type: getPlaceholder<ExpenseItemType>('string'),
            account: {
                opened: BOOL,
                text: STR,
                number: NUM,
                saving: BOOL,
                highlight: NUM,
            },
            description: STR,
            quantity: getPlaceholder('string', '1'),
            unit: getPlaceholder<Unit | ''>('string'),
            unitPrice: STR,
            payableWithoutVat: STR,
            discount: STR,

            // In a non-vat-payer context, this input must not be read from (but can be written to)
            vatPercentage: getPlaceholder('string', '0'),

            vatAmount: STR,
            withVat: STR,
        }),
        totals: {
            vat: STR, // TODO rename to vatAmount?
            withVat: STR,
        },
        asset: (_id: string) => ({
            type: getPlaceholder<AssetType>('string'),
            description: STR,
            lifetime: NUM,
            amortBegin: STR,
            withoutVat: STR,
            other: STR,

            // In a non-vat-payer context, this input must not be read from (but can be written to)
            vatPercentage: getPlaceholder('string', '0'),
        }),
        assetChange: {
            eolDate: STR,
            residual: STR,
        },
        assetList: {
            showAll: BOOL,
            date: STR,
            sort: getPlaceholder<AssetListSortId>('string'),
        },
        register: {
            showAll: BOOL,
            sort: getPlaceholder<ExpenseRegisterSortId>('string'),
        },
        goods: {
            stock: {
                showAll: BOOL,
                date: STR,
                sort: getPlaceholder<StockListSortId>('string'),
                changes: (_expenseId: string) => (_itemId: string) => ({
                    newQuantity: STR,
                    newUnitPrice: STR,
                }),
            },
            expense: {
                showAll: BOOL,
                sort: getPlaceholder<GoodsListSortId>('string'),
            },
        },
        comment: STR,
        general: {
            showAll: BOOL,
            sort: getPlaceholder<GeneralExpenseListSortId>('string'),
        },
        archive: {
            // TODO split by section?
            status: STR,
            date: { from: STR, to: STR },
            dueDate: STR,
            term: STR,
            vendor: { type: STR, search: STR, regCode: STR, name: STR },
            type: STR,
        },
        unpaid: {
            date: STR,
            sort: getPlaceholder<UnpaidExpenseSortId>('string'),
        },
    },
    financialHistory: {
        balance: (_account: string) => STR,
    },
    initCompany: {
        rev: NUM,
        dateType: getPlaceholder<InitDateType>('string'),
        general: {
            name: STR,
            regCode: STR,
            address: { street: STR, city: STR, postcode: STR },
            email: STR,
            website: STR,
            registrationDate: STR,
            fiscalYearBegin: {
                dayOfMonth: getPlaceholder('number', 1),
                month: getPlaceholder<MonthNumber>('number', 1),
            },
            longFirstYear: getPlaceholder<'yes' | 'no' | ''>('string'), // TODO button group with Input<boolean>
            vatPayer: getPlaceholder<'yes' | 'no' | ''>('string'), // TODO button group with Input<boolean>
            vatId: STR,
            vatBegin: STR,
            bankAccounts: (_index: number) => ({ name: STR, number: STR }),
        },
        billing: {
            contact: {
                name: STR,
                email: STR,
            },
            agree: BOOL,
        },
    },
    invoice: {
        canClear: BOOL,
        rev: NUM,
        customer: {
            type: getPlaceholder<'business' | 'citizen' | ''>('string'),
            details: STR,
            name: STR,
            regCode: STR,
            address: STR,
            vatId: STR,
        },
        date: STR,
        term: STR,
        item: (_id: string) => ({
            type: getPlaceholder<RevenueItemType>('string'),
            account: {
                opened: BOOL,
                text: STR,
                number: NUM,
                saving: BOOL,
                highlight: NUM,
            },
            description: STR,
            quantity: getPlaceholder('string', '1'),
            unit: getPlaceholder<Unit | ''>('string'),
            unitPrice: STR,
            discount: getPlaceholder('string', '0'),

            // In a non-vat-payer context, this input must not be read from (but can be written to)
            vatPercentage: getPlaceholder('string', '0'),
        }),
        comment: STR,
        unpaid: {
            date: STR,
            sort: getPlaceholder<UnpaidInvoiceSortId>('string'),
        },
        archive: {
            // TODO split by section?
            status: STR,
            date: { from: STR, to: STR },
            paid: STR,
            dueDate: STR,
            term: STR,
            customer: { type: STR, search: STR, regCode: STR, name: STR },
            itemType: STR,
            discount: STR,
        },
        searchNumber: STR,
        register: {
            showAll: BOOL,
            sort: getPlaceholder<RevenueRegisterSortId>('string'),
        },
    },
    labourCost: {
        month: NUM,
        year: STR, // TODO NUM?
        note: STR,
        amountMode: getPlaceholder<'net' | 'gross'>('string'),
        amount: STR,
        calculatePension: BOOL,
        increaseSocialTax: BOOL,
        taxFreeMode: getPlaceholder<'automatic' | 'manual'>('string'),
        customTaxFree: STR,
    },
    login: {
        email: STR,
        password: STR,
        mode: getPlaceholder<LoginMode>('string', loginModes.public), // TODO remember last choice?
        modeInfoExpanded: BOOL,
    },
    note: {
        rev: NUM,
        text: STR,
        hasChanged: BOOL,
    },
    passwordReset: {
        email: STR,
        password: STR,
        repeatPassword: STR,
    },
    payment: {
        rev: NUM,
        full: getPlaceholder<'yes' | 'no'>('string'), // TODO button group with Input<boolean>
        amount: STR,
        moneyForm: getPlaceholder<MoneyForm>('string'),
        date: STR,
    },
    reports: {
        balance: {
            mode: getPlaceholder<ReportMode>('string'),
            dates: {
                overlayOpen: BOOL,
                mode: getPlaceholder<BalanceDateMode>('string'),
                years: { from: STR, to: STR },
                months: {
                    from: STR,
                    to: STR,
                    dayOfMonth: getPlaceholder('string', '1'),
                },
            },
        },
        income: {
            mode: getPlaceholder<ReportMode>('string'),
        },
        turnover: {
            mode: getPlaceholder<ReportMode>('string'),
        },
        periods: {
            overlayOpen: BOOL,
            mode: getPlaceholder<ReportPeriodMode>('string'),
            dates: { from: STR, to: STR },
            years: { from: STR, to: STR },
            months: {
                from: STR,
                to: STR,
                dayOfMonth: getPlaceholder('string', '1'),
            },
            days: { from: STR, to: STR },
        },
    },
    settings: {
        rev: NUM,
        companyRev: NUM,
        general: {
            name: STR,
            regCode: STR,
            address: {
                street: STR,
                city: STR,
                postcode: STR,
            },
            email: STR,
            website: STR,
        },
        vat: {
            vatId: STR,
            vatPercentage: STR,
        },
        logo: {
            dataUrl: STR,
            base64: STR,
        },
        billing: {
            contact: {
                name: STR,
                email: STR,
            },
        },
        invoice: {
            paymentTerm: STR,
            phone: STR,
            bankAccount: (_id: number) => ({
                name: STR,
                number: STR,
            }),
            address: {
                street: STR,
                city: STR,
                postcode: STR,
            },
            email: STR,
            website: STR,
        },
        users: {
            editedUser: {
                id: STR,
                rev: NUM,
                role: getPlaceholder<CompanyRole>('string'),
            },
            newUserEmail: STR,
            newUserRole: getPlaceholder<CompanyRole>('string'),
        },
        closeAccount: {
            confirm: STR,
        },
    },
    signup: {
        firstName: STR,
        lastName: STR,
        email: STR,
        password: STR,
        repeatPassword: STR,
        showTerms: BOOL,
        agree: BOOL,
    },
    taxes: {
        vat: {
            payment: {
                amount: getPlaceholder('string', '0'),
                interest: getPlaceholder('string', '0'),
                date: STR,
            },
        },
    },
    userSettings: {
        general: {
            firstName: STR,
            lastName: STR,
        },
    },
})
