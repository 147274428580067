import classnames from 'classnames'
import React, { FC } from 'react'

import { canAddPayments } from '../../../common/access'
import { CompanyStatus } from '../../../common/enums'
import { calculateExpenseInitial } from '../../../common/expense-utils'
import { keys } from '../../../common/keys'
import { getDaysUntil, getPaidAmount } from '../../../common/payment-utils'
import { sort, SortOption } from '../../../common/sort'
import { Day } from '../../../common/time'
import { ApiExpense, PaymentState } from '../../../common/types/expense'
import { InputValues } from '../../../common/types/inputs'
import { Column } from '../../../common/types/table'
import { getExcelButtonProps } from '../../excel-utils'
import { ExcelSpec } from '../../excel/types'
import { getExpenseTypeLabel } from '../../expense-utils'
import { formatAmount, formatAmountOrDash } from '../../format-amount'
import { t } from '../../i18n'
import { inputs } from '../../inputs'
import { getExpensePaymentProps } from '../../props/expense-payment-props'
import { getLabourCostPaymentProps } from '../../props/labour-cost-payment-props'
import { renderExcelMoney } from '../../render-excel-money'
import { wrapExcelButton } from '../../standard-page-utils'
import { getCompany } from '../../state/company-actions'
import { openExpensePaymentForm } from '../../state/payment-actions'
import { RootData } from '../../state/root-data'
import { browserOnly } from '../../table-utils'
import { DateInput } from '../date-input'
import { ExcelButtonProps } from '../excel-button'
import { Link } from '../link'
import { LoadingPage } from '../loading-page'
import { NoData } from '../no-data'
import { Payment, PaymentProps } from '../payment'
import { renderSortOptions } from '../sort-options'
import { StandardPage } from '../standard-page'
import { renderTable } from '../table'
import { ViewIcon } from '../view-icon'

// TODO unduplicate with unpaid revenues

interface Row {
    id: string
    className?: string
    type: string
    vendor: string
    dueGroup: string
    total: number
    unpaid: number
    percentUnpaid: number
}

type Group = '>14' | '8...14' | '0...7' | 'overdue' | 'unpaid' | 'full'

interface Totals {
    '>14': number
    '8...14': number
    '0...7': number
    overdue: number
    unpaid: number
    full: number
}

export type SortId = 'amount' | 'vendor' | 'type'

const getDueGroup = (daysUntil: number): Group => {
    if (daysUntil > 14) {
        return '>14'
    } else if (daysUntil > 7) {
        return '8...14'
    } else if (daysUntil >= 0) {
        return '0...7'
    } else {
        return 'overdue'
    }
}

const getBordered = () => ({ className: 'table__body-cell table__body-cell--bordered' })
const getAmountClass = () => ({ className: 'table__body-cell table__body-cell--bordered amount' })
const getRightAligned = () => ({ className: 'text-right' })

const getDueCol = (group: Group): Column<Row, Totals> => ({
    secondHeader: {
        content: group,
        getProps: () => ({ className: 'due-second' }),
    },
    getProps: getAmountClass,
    render: (row) => {
        const value = row.dueGroup === group ? row.unpaid : 0

        return {
            browser: formatAmountOrDash(value),
            excel: renderExcelMoney(value, false),
        }
    },
    getTotalProps: () => ({ className: 'amount' }),
    getTotal: (totals) => formatAmount(totals[group]),
    getExcelTotal: (totals) => ({ type: 'sum-money', value: totals[group] }),
    excelWidth: 12,
})

const renderPaymentLink = (row: Row) =>
    React.createElement(Link, {
        text: t.expenses.percentUnpaid.get(row.percentUnpaid),
        onClick: async () => openExpensePaymentForm(row.id, 'expense'),
        className: 'payment-link',
    })

const getColumns = (companyStatus: CompanyStatus): Column<Row, Totals>[] => [
    {
        header: { content: t.expenses.vendor.get() },
        getProps: getBordered,
        render: (row) => row.vendor,
        excelWidth: 30,
    },
    {
        header: { content: t.expenses.type.get() },
        getProps: getBordered,
        render: (row) => row.type,
        excelWidth: 25,
    },
    {
        header: { content: t.invoices.sum.get(), getProps: getRightAligned },
        getProps: getAmountClass,
        render: (row) => ({
            browser: formatAmountOrDash(row.total),
            excel: renderExcelMoney(row.total, false),
        }),
        getTotalProps: () => ({ className: 'amount' }),
        getTotal: (totals) => formatAmount(totals.full),
        getExcelTotal: (totals) => ({ type: 'sum-money', value: totals.full }),
        excelWidth: 12,
    },
    {
        // Padding before
        getProps: () => ({ className: 'pad-before' }),
        render: () => '',
        hideInExcel: true,
    },
    {
        ...getDueCol('>14'),
        header: {
            content: t.dueDate.daysUntil.get(),
            span: 3,
            getProps: () => ({ className: 'due-first' }),
        },
    },
    getDueCol('8...14'),
    getDueCol('0...7'),
    {
        header: { content: t.overdue.get(), getProps: getRightAligned },
        getProps: getAmountClass,
        render: (row) => {
            const value = row.dueGroup === 'overdue' ? row.unpaid : 0

            return {
                browser: formatAmountOrDash(value),
                excel: renderExcelMoney(value, false),
            }
        },
        getTotalProps: () => ({ className: 'amount' }),
        getTotal: (totals) => formatAmount(totals['overdue']),
        getExcelTotal: (totals) => ({ type: 'sum-money', value: totals['overdue'] }),
        excelWidth: 12,
    },
    {
        // Padding after
        hideInExcel: true,
        render: () => '',
    },
    {
        header: {
            content: t.actions.get(),
            getProps: () => ({ className: 'text-center' }),
        },
        getProps: () => ({ className: 'actions' }),
        render: browserOnly((row) => {
            const buttons = []
            buttons.push(React.createElement(ViewIcon, { href: '#/expenses/view/' + row.id }))

            if (canAddPayments(companyStatus)) {
                buttons.push(renderPaymentLink(row))
            }

            return React.createElement('span', null, ...buttons)
        }),
        hideInExcel: true,
    },
]

const getRows = (
    expenses: ApiExpense[],
    totals: Totals,
    date: string,
    sortOption: SortOption<Row>,
    paymentState: PaymentState | null,
) => {
    const rows: Row[] = []
    const maxTime = date + 'T24'

    for (const expense of expenses) {
        if (!expense.confirmed || expense.date > date) {
            continue
        }

        const filteredPayments = expense.payments.filter((entry) => entry.date < maxTime)
        const paid = expense.paid && filteredPayments.length === expense.payments.length

        if (paid) {
            continue
        }

        const { payableWithVat: total } = calculateExpenseInitial(expense)
        const alreadyPaid = getPaidAmount(filteredPayments)
        const unpaid = total - alreadyPaid

        const { _id, dueDate } = expense

        const type = getExpenseTypeLabel(expense)
        const daysUntil = getDaysUntil(date, dueDate)
        const dueGroup = getDueGroup(daysUntil)

        totals[dueGroup] += unpaid
        totals.unpaid += unpaid
        totals.full += total

        const isActivePayment =
            paymentState && paymentState.type === 'expense' && paymentState.id === _id

        rows.push({
            id: _id,
            className: classnames('table__row', 'table__row--bordered', {
                shaded: isActivePayment,
            }),
            type,
            vendor: expense.vendor.name,
            dueGroup,
            total,
            unpaid,
            percentUnpaid: total === 0 ? 0 : Math.round((100 * unpaid) / total),
        })
    }

    return sort(rows, sortOption)
}

const renderDateButton = (inputValues: InputValues) =>
    React.createElement(DateInput, {
        input: inputs.expense.unpaid.date,
        inputValues,
        text: t.chooseDate.get(),
        maxDate: Day.today(),
        className: 'button--wide button--primary',
    })

export const UnpaidExpenses: FC<RootData> = (rootData) => {
    const { companyData, expenseData, formsReady, inputValues, processes, progress, session } =
        rootData
    const { expenses, paymentState } = expenseData

    if (!expenses || !companyData.companies || !formsReady.has('unpaid-expenses')) {
        return React.createElement(LoadingPage)
    }

    if (!expenses.length) {
        return React.createElement(NoData, {
            addRoute: '#/expenses/add/regular',
            addButtonText: t.expenses.add.get(),
        })
    }

    let paymentSidebar: PaymentProps | undefined

    if (paymentState?.type === 'expense') {
        paymentSidebar = getExpensePaymentProps(rootData, paymentState.id)
    } else if (paymentState?.type) {
        paymentSidebar = getLabourCostPaymentProps(rootData, paymentState.type, paymentState.id)
    }

    const company = getCompany(companyData, session)

    const sortOptions: { [S in SortId]: SortOption<Row> } = {
        amount: [{ getKey: (row) => row.unpaid, reverse: true }],
        vendor: [{ getKey: (row) => row.vendor }],
        type: [{ getKey: (row) => row.type }],
    }

    const date = inputs.expense.unpaid.date.get(inputValues)
    const sortId = inputs.expense.unpaid.sort.get(inputValues)

    const totals: Totals = { '>14': 0, '8...14': 0, '0...7': 0, overdue: 0, unpaid: 0, full: 0 }

    const columns = getColumns(company.status)
    const rows = getRows(expenses, totals, date, sortOptions[sortId], paymentState)

    const title = t.expenses.unpaidInvoices.get()
    const subtitle = t.asOf.get(Day.fromYmd(date).longDate())

    let excelButton: ExcelButtonProps | undefined

    if (rows.length) {
        const excelSpec: ExcelSpec<Row, Totals> = {
            columns,
            rows,
            totals,
            hasSecondHeader: true,
            outputName: title + ' ' + subtitle,
        }

        excelButton = getExcelButtonProps(
            excelSpec,
            t.expenses.processed.get(),
            processes,
            progress,
            'button button--wide button--primary',
        )
    }

    return React.createElement(StandardPage, {
        sidebar: paymentSidebar && React.createElement(Payment, paymentSidebar),
        title,
        subtitle,
        sortOptions: renderSortOptions({
            input: inputs.expense.unpaid.sort,
            inputValues,
            options: keys(sortOptions).map((key) => ({
                id: key,
                label: t.expenses.sortOption[key].get(),
            })),
        }),
        buttons: [renderDateButton(inputValues), excelButton && wrapExcelButton(excelButton)],
        table: renderTable({
            columns,
            rows,
            totals,
            stickyHeader: true,
            hasSecondHeader: true,
            tableClassName: 'table main-table unpaid-expenses',
            wrapperClassName: 'main-table-wrapper',
        }), // TODO showAll button?
    })
}
