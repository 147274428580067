import { CompanyRole } from '../enums'
import { AccountType } from './account'
import { CardPaymentType } from './card-payment'
import { ErrorInfo } from './command'
import { BillingDetails, CompanyInput, CompanyUpdate, GeneralUpdate } from './company'
import { EntryInput } from './entry'
import { LabourPaymentType, LoginMode } from './enums'
import {
    AssetValueChange,
    ExpenseInput,
    ExpenseUpdate,
    FileStorageUsage,
    PendingStockChangeItem,
} from './expense'
import { RevenueInput } from './invoice'
import { InvoiceSettingsUpdate } from './invoice-settings'
import { LabourCostInput } from './labour-cost'
import { PaymentInput } from './payment'
import {
    BalanceState,
    Period,
    PeriodCashFlow,
    PeriodIncome,
    SimpleData,
    TurnoverData,
} from './reports'
import { ClientFields } from './resolver'
import { ApiSession } from './session'
import { VatUpdate } from './settings'
import { UserInput } from './user'
import { VatPaymentInput } from './vat'

export enum CommandName {
    acceptInvite = 'acceptInvite',
    activateCompany = 'activateCompany',
    addCreditRevenuePayment = 'addCreditRevenuePayment',
    addExpenseFile = 'addExpenseFile',
    addExpensePayment = 'addExpensePayment',
    addInvoicePayment = 'addInvoicePayment',
    addLabourPayment = 'addLabourPayment',
    addVatPayment = 'addVatPayment',
    archiveCompany = 'archiveCompany',
    assignVatMonth = 'assignVatMonth',
    clearData = 'clearData',
    confirmAssetChange = 'confirmAssetChange',
    confirmExpense = 'confirmExpense',
    confirmInvoice = 'confirmInvoice',
    confirmLabourCost = 'confirmLabourCost',
    confirmStockChange = 'confirmStockChange',
    createAccount = 'createAccount',
    createAssetChange = 'createAssetChange',
    createCompany = 'createCompany',
    createCreditRevenue = 'createCreditRevenue',
    createEntry = 'createEntry',
    createExpense = 'createExpense',
    createInvoice = 'createInvoice',
    createLabourCost = 'createLabourCost',
    createStockChange = 'createStockChange',
    getBalanceReport = 'getBalanceReport',
    getCashFlowReport = 'getCashFlowReport',
    getErrorInfo = 'getErrorInfo',
    getExpenseFileStorageUsage = 'getExpenseFileStorageUsage',
    getIncomeReport = 'getIncomeReport',
    getTurnoverReport = 'getTurnoverReport',
    initCardTransaction = 'initCardTransaction',
    initPasswordReset = 'initPasswordReset',
    inviteUser = 'inviteUser',
    load = 'load',
    login = 'login',
    logout = 'logout',
    processErrors = 'processErrors',
    removeAssetChange = 'removeAssetChange',
    removeCompanyLogo = 'removeCompanyLogo',
    removeEntry = 'removeEntry',
    removeExpense = 'removeExpense',
    removeExpenseFile = 'removeExpenseFile',
    removeExpensePayment = 'removeExpensePayment',
    removeInvoice = 'removeInvoice',
    removeLabourCost = 'removeLabourCost',
    removeLabourPayment = 'removeLabourPayment',
    removeRevenuePayment = 'removeRevenuePayment',
    removeStockChange = 'removeStockChange',
    removeUserFromCompany = 'removeUserFromCompany',
    renewSession = 'renewSession',
    resetPassword = 'resetPassword',
    selectCompany = 'selectCompany',
    signUp = 'signUp',
    updateAssetChange = 'updateAssetChange',
    updateBillingDetails = 'updateBillingDetails',
    updateCompanyGeneral = 'updateCompanyGeneral',
    updateExpense = 'updateExpense',
    updateGeneralSettings = 'updateGeneralSettings',
    updateInitDate = 'updateInitDate',
    updateInterimBalance = 'updateInterimBalance',
    updateInvoice = 'updateInvoice',
    updateInvoiceSettings = 'updateInvoiceSettings',
    updateNote = 'updateNote',
    updateSelf = 'updateSelf',
    updateStockChange = 'updateStockChange',
    updateUserRole = 'updateUserRole',
    updateVatSettings = 'updateVatSettings',
    uploadCompanyLogo = 'uploadCompanyLogo',
}

export interface AssignVatMonthParams {
    month: string
    expenseIds?: string[]
    revenueIds?: string[]
    creditRevenueIds?: string[]
}

export interface Api {
    acceptInvite: (params: { inviteId: string }) => null
    activateCompany: (params: undefined) => ApiSession
    addCreditRevenuePayment: (params: { id: string; rev: number; payment: PaymentInput }) => number
    addExpenseFile: (params: {
        expenseId: string
        filename: string
        fileContentBase64: string
    }) => null
    addExpensePayment: (params: { id: string; rev: number; payment: PaymentInput }) => number
    addInvoicePayment: (params: { id: string; rev: number; payment: PaymentInput }) => number
    addLabourPayment: (params: {
        id: string
        rev: number
        type: LabourPaymentType
        payment: PaymentInput
    }) => number
    addVatPayment: (params: { payment: VatPaymentInput }) => string
    archiveCompany: (params: undefined) => ApiSession
    assignVatMonth: (params: AssignVatMonthParams) => null
    clearData: (params: undefined) => null
    confirmAssetChange: (params: { changeId: string }) => void
    confirmExpense: (params: { id: string; rev: number }) => number
    confirmInvoice: (params: { id: string; rev: number }) => number
    confirmLabourCost: (params: { id: string; rev: number }) => number
    confirmStockChange: (params: { changeId: string }) => void
    createAccount: (params: { type: AccountType; name: string }) => { id: string; number: number }
    createAssetChange: (params: {
        expenseId: string
        assetId: string
        valueChange: AssetValueChange<string>
    }) => string
    createCompany: (params: { company: CompanyInput }) => ApiSession
    createCreditRevenue: (params: { id: string; date: string; term: string }) => string
    createEntry: (params: { entry: EntryInput }) => string
    createExpense: (params: { expense: ExpenseInput }) => string
    createInvoice: (params: { invoice: RevenueInput }) => string
    createLabourCost: (params: { labourCost: LabourCostInput }) => string
    createStockChange: (params: { date: string; items: PendingStockChangeItem<string>[] }) => string
    getBalanceReport: (params: { dates: string[] }) => BalanceState[]
    getCashFlowReport: (params: { periods: Period[] }) => PeriodCashFlow[]
    getErrorInfo: (params: undefined) => ErrorInfo
    getExpenseFileStorageUsage: (params: undefined) => FileStorageUsage
    getIncomeReport: (params: { periods: Period[] }) => PeriodIncome[]
    getStockData: (params: undefined) => SimpleData<number>
    getTurnoverReport: (params: { period: Period }) => TurnoverData
    initCardTransaction: (params: { type: CardPaymentType }) => { paymentLink: string }
    initPasswordReset: (params: { email: string }) => null
    inviteUser: (params: { email: string; role: CompanyRole }) => string
    load: <T>(params: { resolver: string; fields?: ClientFields<T>; args?: any }) => T | T[] | null
    logError: (params: {
        message: string
        stack: string
        route: string
        id: string
        host?: string
    }) => null
    login: (params: { email: string; password: string; mode: LoginMode }) => ApiSession
    logout: (params: undefined) => null
    processErrors: (params: undefined) => { processed: string[] }
    removeAssetChange: (params: { changeId: string }) => null
    removeCompanyLogo: (params: { rev: number }) => number
    removeExpense: (params: { id: string }) => null
    removeExpenseFile: (params: { expenseId: string; hash: string }) => null
    removeExpensePayment: (params: { expenseId: string; rev: number; paymentId: string }) => number
    removeEntry: (params: { id: string }) => null
    removeInvoice: (params: { id: string }) => null
    removeLabourCost: (params: { id: string }) => null
    removeLabourPayment: (params: {
        labourCostId: string
        rev: number
        type: LabourPaymentType
        paymentId: string
    }) => number
    removeRevenuePayment: (params: { revenueId: string; rev: number; paymentId: string }) => number
    removeStockChange: (params: { changeId: string }) => null
    removeUserFromCompany: (params: { id: string }) => null | ApiSession
    renewSession: (params: undefined) => ApiSession
    resetPassword: (params: { resetCode: string; password: string }) => null
    selectCompany: (params: { id: string | null; password: string | null }) => ApiSession
    signUp: (params: { user: UserInput }) => null
    updateAssetChange: (params: {
        changeId: string
        rev: number
        residual?: string
        eolDate?: string
    }) => number
    updateBillingDetails: (params: { rev: number; details: BillingDetails }) => number
    updateCompanyGeneral: (params: { rev: number; company: CompanyUpdate }) => number
    updateExpense: (params: { id: string; rev: number; expense: ExpenseUpdate }) => number
    updateGeneralSettings: (params: { rev: number; company: GeneralUpdate }) => number
    updateInitDate: (params: { rev: number; date: string }) => number
    updateInterimBalance: (params: { rev: number; interimBalance: SimpleData<string> }) => number
    updateInvoice: (params: { id: string; rev: number; invoice: RevenueInput }) => number
    updateInvoiceSettings: (params: InvoiceSettingsUpdate) => null
    updateNote: (params: { rev: number; text: string }) => number
    updateSelf: (params: { firstName: string; lastName: string }) => ApiSession
    updateStockChange: (params: {
        changeId: string
        rev: number
        items: PendingStockChangeItem<string>[]
    }) => number
    updateUserRole: (params: {
        userId: string
        rev: number
        role: CompanyRole
    }) => null | ApiSession
    updateVatSettings: (params: {
        companyRev: number
        settingsRev: number
        update: VatUpdate
    }) => null
    uploadCompanyLogo: (params: { rev: number; fileContentBase64: string }) => number
}

export type ApiParams<C extends CommandName> = Api[C] extends (params: infer P) => any ? P : never
export type ApiResponse<C extends CommandName> = Api[C] extends (params: any) => infer R ? R : never
export interface FullResponse<C extends CommandName> {
    success: boolean
    response: ApiResponse<C>
}
