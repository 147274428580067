import {
    canAddCreditRevenuePayments,
    canAddPayments,
    canConfirm,
    canRemoveAllRevenues,
} from '../../common/access'
import { MAX_ROWS } from '../../common/constants'
import { CompanyStatus, logActions } from '../../common/enums'
import { invariant } from '../../common/invariant'
import { calculateRevenue } from '../../common/invoice-utils'
import { keys } from '../../common/keys'
import { getDaysOverdue, getPaidAmount } from '../../common/payment-utils'
import { sort, SortOption } from '../../common/sort'
import { Day } from '../../common/time'
import { ApiRevenue, CreditRevenue, RevenueData } from '../../common/types/invoice'
import { ApiSession } from '../../common/types/session'
import { VatPaymentState } from '../../common/types/vat'
import { upperCaseFirst } from '../../common/upper-case-first'
import { assertViewName } from '../assert-view-name'
import { LinkProps } from '../components/link'
import {
    getRevenueRegisterColumns,
    RevenueRegisterProps,
    RevenueRegisterRow,
    RevenueRegisterSortId,
    RevenueRegisterTotals,
} from '../components/revenue/register'
import { getExcelButtonProps } from '../excel-utils'
import { ExcelSpec } from '../excel/types'
import { t } from '../i18n'
import { createMonthUrlInput } from '../input-utils'
import { inputs } from '../inputs'
import { getDueDateDescription, getPercentUnpaid } from '../payment-utils'
import { getCustomerName } from '../revenue-utils'
import { getShowAllButtonProps } from '../standard-page-utils'
import { getCompany } from '../state/company-actions'
import {
    confirmRevenue,
    getCreditRevenueById,
    getRevenueById,
    openPaymentForm,
    remove,
    REMOVE_PROCESS,
} from '../state/revenue-actions'
import { RootData } from '../state/root-data'
import { getCreditRevenuePaymentProps } from './credit-revenue-payment-props'
import { getRevenuePaymentProps } from './revenue-payment-props'

const getVatNoteProps = (vatMonth: string): LinkProps => ({
    to: '#/taxes/vat/' + vatMonth,
    text: t.vatDeclared.get(),
    tooltip: upperCaseFirst(Day.fromYm(vatMonth).longMonth()),
})

const canRemove = (
    revenue: ApiRevenue,
    session: ApiSession,
    companyStatus: CompanyStatus,
    hasCreditRevenue: boolean,
) => {
    if (hasCreditRevenue) {
        return false
    }

    const canRemoveAll = canRemoveAllRevenues(session.companyRole, companyStatus)

    if (revenue.confirmed) {
        if (revenue.vatMonth) {
            return false
        }

        return canRemoveAll
    }

    if (canRemoveAll) {
        return true
    }

    const [firstEntry] = revenue.log
    invariant(firstEntry.action === logActions.create)
    return firstEntry.userId === session.userId
}

const getRevenuePaymentText = (hasCreditRevenue: boolean, paid: boolean, percentUnpaid: number) => {
    if (hasCreditRevenue) {
        return t.revenues.hasCreditRevenue.get()
    }

    if (paid) {
        return t.revenues.paid.get()
    }

    return t.revenues.percentUnpaid.get(percentUnpaid)
}

const getCreditRevenuePaymentText = (
    originalHasPayments: boolean,
    paid: boolean,
    percentUnpaid: number,
) => {
    if (!originalHasPayments) {
        return t.payments.noNeed.get()
    }

    if (paid) {
        return t.creditRevenues.paid.get()
    }

    return t.creditRevenues.percentUnpaid.get(percentUnpaid)
}

const getRows = (
    revenues: ApiRevenue[],
    creditRevenues: CreditRevenue[],
    session: ApiSession,
    companyStatus: CompanyStatus,
    sortOption: SortOption<RevenueRegisterRow>,
    revenueData: RevenueData,
    paymentState: VatPaymentState,
): RevenueRegisterRow[] => {
    const { justCreatedId, payment } = revenueData

    const revenueRows = revenues.map((revenue): RevenueRegisterRow => {
        const { _id, rev, confirmed, date, term } = revenue
        const shaded = _id === justCreatedId || (!payment?.isCredit && _id === payment?.id)
        const number = revenue.confirmed ? revenue.number! : t.unconfirmed.get()
        const paid = revenue.confirmed ? revenue.paid! : false
        const totals = calculateRevenue(revenue)

        const percentUnpaid = confirmed
            ? getPercentUnpaid(revenue.paid!, revenue.payments, totals.payableWithVat)
            : 100

        const row: RevenueRegisterRow = {
            className: shaded ? 'shaded' : undefined,
            number,
            date: Day.fromYmd(date),
            customer: getCustomerName(revenue.customer),
            withVat: totals.payableWithVat,
            dueDate: getDueDateDescription(paid, date, term),
            sortKeys: {
                confirmed,
                createTime: revenue.log[0].time,
                daysOverdue: getDaysOverdue(date, term),
                paid,
            },
            viewIcon: { href: '#/invoices/view/' + _id },
            actions: [],
        }

        const hasCreditRevenue = creditRevenues.some(
            (creditRevenue) => creditRevenue._id === revenue._id,
        )

        if (confirmed && canAddPayments(companyStatus)) {
            row.actions.push({
                text: getRevenuePaymentText(hasCreditRevenue, paid, percentUnpaid),
                onClick: async () => openPaymentForm(_id, false),
                className: 'payment-link',
            })
        }

        if (!confirmed && canConfirm(session.companyRole, companyStatus)) {
            row.actions.push({
                text: t.confirm.get(),
                onClick: () =>
                    confirm(t.confirm.confirmRevenue.get()) ? confirmRevenue(_id, rev) : null,
                className: 'confirm-link',
            })
        }

        if (canRemove(revenue, session, companyStatus, hasCreditRevenue)) {
            row.removeLink = {
                onClick: async () => {
                    const message = confirmed
                        ? t.confirm.removeConfirmedInvoice.get()
                        : t.confirm.removeRevenue.get()

                    // TODO custom confirmation UI
                    if (confirm(message)) {
                        await remove(_id)
                    }
                },
            }
        }

        if (revenue.vatMonth && revenue.vatMonth in paymentState.paidMonths) {
            row.vatNote = getVatNoteProps(revenue.vatMonth)
        }

        return row
    })

    const creditRevenueRows = creditRevenues.map((creditRevenue): RevenueRegisterRow => {
        const { _id, date, term } = creditRevenue
        const shaded = payment?.isCredit && _id === payment?.id
        const origRevenue = getRevenueById(revenueData, _id)!
        const number = creditRevenue.number
        const paid = creditRevenue.paid
        const totals = calculateRevenue(origRevenue)

        const percentUnpaid = getPercentUnpaid(
            creditRevenue.paid,
            creditRevenue.payments,
            getPaidAmount(origRevenue.payments),
        )

        const row: RevenueRegisterRow = {
            className: shaded ? 'shaded' : undefined,
            number,
            date: Day.fromYmd(date),
            customer: getCustomerName(origRevenue.customer),
            withVat: -totals.payableWithVat,
            dueDate: getDueDateDescription(paid, date, term),
            sortKeys: {
                confirmed: true,
                createTime: creditRevenue.log[0].time,
                daysOverdue: getDaysOverdue(date, term),
                paid,
            },
            viewIcon: { href: '#/credit-revenues/view/' + _id },
            actions: [],
        }

        if (canAddCreditRevenuePayments(session.companyRole, companyStatus)) {
            const originalHasPayments = origRevenue.payments.length > 0

            row.actions.push({
                text: getCreditRevenuePaymentText(originalHasPayments, paid, percentUnpaid),
                onClick: async () => openPaymentForm(_id, true),
                className: 'payment-link',
            })
        }

        if (creditRevenue.vatMonth && creditRevenue.vatMonth in paymentState.paidMonths) {
            row.vatNote = getVatNoteProps(creditRevenue.vatMonth)
        }

        return row
    })

    const rows = revenueRows.concat(creditRevenueRows)

    return sort(rows, sortOption)
}

export const getRevenueRegisterProps = (rootData: RootData): RevenueRegisterProps => {
    const {
        companyData,
        creditRevenueData,
        inputValues,
        invoiceData,
        processes,
        progress,
        session,
        taxes: {
            vat: { paymentState },
        },
        validationErrors,
        view,
    } = rootData

    const revenues = invoiceData.invoices
    const { creditRevenues } = creditRevenueData

    if (
        !revenues ||
        !creditRevenues ||
        !companyData.companies ||
        !paymentState ||
        processes.has(REMOVE_PROCESS)
    ) {
        return { status: 'loading' }
    }

    if (!revenues.length) {
        return {
            status: 'no-data',
            noData: {
                addRoute: '#/invoices/add',
                addButtonText: t.revenues.add.get(),
            },
        }
    }

    const company = getCompany(companyData, session)

    const sortOptions: { [S in RevenueRegisterSortId]: SortOption<RevenueRegisterRow> } = {
        number: [
            // TODO test
            { getKey: (row) => (row.sortKeys.confirmed ? 0 : 1) },
            { getKey: (row) => row.number, reverse: true },
            { getKey: (row) => row.date.toTimestamp(), reverse: true },
            { getKey: (row) => row.sortKeys.createTime, reverse: true },
        ],
        date: [
            { getKey: (row) => row.date.toTimestamp(), reverse: true },
            { getKey: (row) => row.sortKeys.createTime, reverse: true },
        ],
        dueDate: [{ getKey: (row) => row.sortKeys.daysOverdue }],
        paid: [
            { getKey: (row) => (row.sortKeys.confirmed ? 0 : 1) },
            { getKey: (row) => (row.sortKeys.paid ? 1 : 0) },
            { getKey: (row) => row.withVat, reverse: true }, // TODO by unpaid amount?
        ],
    }

    // TODO undup
    const tableInputs = inputs.invoice.register
    const showAll = tableInputs.showAll.get(inputValues)
    const sortId = tableInputs.sort.get(inputValues)

    const { month } = assertViewName(view, 'RevenueRegister')
    const shownMonth = month || Day.today().ym()
    const monthInput = createMonthUrlInput('#/invoices/register/', shownMonth)

    const revenuesInRange = revenues.filter((revenue) => revenue.date.startsWith(shownMonth))
    const creditRevenuesInRange = creditRevenues.filter((creditRevenue) =>
        creditRevenue.date.startsWith(shownMonth),
    )

    const allRows = getRows(
        revenuesInRange,
        creditRevenuesInRange,
        session!,
        company.status,
        sortOptions[sortId],
        invoiceData,
        paymentState,
    )

    const hasMore = allRows.length > MAX_ROWS
    const rows: RevenueRegisterRow[] = showAll ? allRows : allRows.slice(0, MAX_ROWS)

    const totals: RevenueRegisterTotals = {
        withVat: allRows.reduce((sum, row) => sum + row.withVat, 0),
    }

    const parsedMonth = Day.fromYm(shownMonth)
    const title = t.revenues.register.get()
    const subtitle = parsedMonth.longMonth()

    const interimDate = Day.fromYmd(company.interimDate)

    const props: RevenueRegisterProps = {
        status: 'ok',
        title,
        subtitle,
        monthNav: { day: parsedMonth, input: monthInput, minMonth: interimDate },
        rows,
        totals,
    }

    if (invoiceData.payment?.id) {
        const revenueId = invoiceData.payment.id

        const revenue = getRevenueById(invoiceData, revenueId)!

        if (invoiceData.payment.isCredit) {
            const creditRevenue = getCreditRevenueById(creditRevenueData, revenueId)!

            props.sidebar = getCreditRevenuePaymentProps(
                revenue,
                creditRevenue,
                inputValues,
                processes,
                validationErrors,
            )
        } else {
            const hasCreditRevenue = creditRevenueData.creditRevenues!.some((creditRevenue) => {
                return creditRevenue._id === revenueId
            })

            props.sidebar = getRevenuePaymentProps(
                revenue,
                hasCreditRevenue,
                inputValues,
                processes,
                validationErrors,
            )
        }
    }

    if (rows.length) {
        props.sortOptions = {
            input: tableInputs.sort,
            inputValues,
            options: keys(sortOptions).map((key) => ({
                id: key,
                label: t.revenues.sortOption[key].get(),
            })),
        }
    }

    if (rows.length) {
        // TODO Avoid invoking twice on each render
        const columns = getRevenueRegisterColumns()

        const excelSpec: ExcelSpec<RevenueRegisterRow, RevenueRegisterTotals> = {
            columns,
            rows,
            totals,
            outputName: title + ' - ' + subtitle,
        }

        props.excelButton = getExcelButtonProps(
            excelSpec,
            t.revenues.processed.get(),
            processes,
            progress,
            'button button--wide button--primary',
        )
    }

    if (!showAll && hasMore) {
        props.showAllButton = getShowAllButtonProps(tableInputs.showAll)
    }

    return props
}
