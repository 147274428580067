import { getDaysOverdue, getPaidAmount } from '../common/payment-utils'
import { DbPayment } from '../common/types/payment'
import { formatAmount } from './format-amount'
import { t } from './i18n'

export const getPaymentDescription = (payment: DbPayment, incoming: boolean) => {
    const moneyForm = incoming
        ? t.moneyForm.to[payment.moneyForm].get()
        : t.moneyForm.from[payment.moneyForm].get()

    const moneyFormLower = moneyForm.toLowerCase()
    const amount = formatAmount(payment.amount)

    return incoming
        ? t.payments.log.incoming.get(amount, moneyFormLower)
        : t.payments.log.outgoing.get(amount, moneyFormLower)
}

export const getDueDateDescription = (paid: boolean, date: string, term: number) => {
    if (paid) {
        return t.revenues.paid.get() // TODO less revenue-specific?
    }

    const daysOverdue = getDaysOverdue(date, term)

    if (daysOverdue > 0) {
        return t.dueDateDiff.past.get(daysOverdue)
    } else if (daysOverdue < 0) {
        return t.dueDateDiff.future.get(-daysOverdue)
    } else {
        return t.dueDateDiff.today.get()
    }
}

export const getPercentUnpaid = (paid: boolean, payments: DbPayment[], total: number) => {
    if (paid || total === 0) {
        return 0
    }

    const paidAmount = getPaidAmount(payments)
    const unpaidAmount = total - paidAmount
    return Math.round((100 * unpaidAmount) / total)
}
