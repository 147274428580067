import React, { FC } from 'react'

import { SESSION_RENEW_MINUTES } from '../../common/constants'
import { Time } from '../../common/time'
import { Props } from '../../common/types/banner'
import { emitCloseFakeBanner, emitCloseSessionExpiringBanner } from '../event-bus'
import { t } from '../i18n'
import { renew } from '../state/session-actions'
import { Button } from './button'

const renderRenewalLine = (renewable: boolean) =>
    renewable ? t.session.canRenew.get(SESSION_RENEW_MINUTES) : t.session.canNotRenew.get()

const renderButtons = (renewable: boolean) => {
    if (renewable) {
        const renewButton = React.createElement(Button, {
            text: t.session.renew.get(),
            onClick: renew,
            domId: 'renew',
            className: 'button--white',
        })

        const dontRenewButton = React.createElement(Button, {
            text: t.session.dontRenew.get(),
            onClick: emitCloseSessionExpiringBanner,
            domId: 'dont-renew',
            className: 'button--white',
        })

        return React.createElement(
            'div',
            { className: 'banner__button-section' },
            renewButton,
            ' ',
            dontRenewButton,
        )
    } else {
        const okButton = React.createElement(Button, {
            text: 'OK',
            onClick: emitCloseSessionExpiringBanner,
            domId: 'ok',
            className: 'button--white',
        })

        return React.createElement('div', { className: 'banner__button-section' }, okButton)
    }
}

const renderSessionExpiringBanner = (
    sessionExpirationVisible: boolean,
    expires: Time | null,
    renewable: boolean,
) => {
    if (sessionExpirationVisible) {
        return React.createElement(
            'div',
            { className: 'banner' },
            React.createElement(
                'div',
                { className: 'banner__session-expires' },
                t.session.expiresAt.get(expires!.asLocal().format('HH:mm')),
            ),
            React.createElement(
                'div',
                null,
                t.session.needLogin.get(),
                ' ',
                renderRenewalLine(renewable),
            ),
            renderButtons(renewable),
        )
    } else {
        return null
    }
}

const renderVersionMismatchBanner = (versionMismatch: boolean) => {
    if (versionMismatch) {
        return React.createElement('div', { className: 'banner' }, t.versionMismatch.get())
    } else {
        return null
    }
}

const renderFakeBanner = (visible: boolean) => {
    if (visible) {
        return React.createElement(
            'div',
            { className: 'banner' },
            React.createElement(
                'div',
                { className: 'banner__session-expires' },
                t.session.expiresAt.get('XX:XX'),
            ),
            React.createElement(
                'div',
                null,
                t.session.needLogin.get(),
                ' ',
                renderRenewalLine(false),
            ),
            React.createElement(
                'div',
                { className: 'banner__button-section' },
                React.createElement(Button, {
                    text: 'OK',
                    onClick: emitCloseFakeBanner,
                    domId: 'ok',
                    className: 'button--white',
                }),
            ),
        )
    } else {
        return null
    }
}

export const Banners: FC<Props> = (props) => {
    const { sessionExpirationVisible, expires, renewable, versionMismatch, fakeVisible } = props

    return React.createElement(
        'div',
        null,
        renderSessionExpiringBanner(sessionExpirationVisible, expires, renewable),
        renderVersionMismatchBanner(versionMismatch),
        renderFakeBanner(fakeVisible),
    )
}
