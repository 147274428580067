import React, { FC, Fragment } from 'react'

import { getMinDay } from '../../common/company-utils'
import { Day } from '../../common/time'
import { AllAccounts } from '../../common/types/account'
import { EntryItem } from '../../common/types/entry'
import { ValidationError } from '../../common/types/errors'
import { InputValues } from '../../common/types/inputs'
import { Processes } from '../../common/types/processes'
import { Column } from '../../common/types/table'
import { assertViewName } from '../assert-view-name'
import {
    getAccountPrefix,
    getLevel4AccountName,
    getPresetItemsFromInputs,
    getPresetState,
    PresetState,
} from '../entry-utils'
import { t } from '../i18n'
import { inputs } from '../inputs'
import { renderAmount } from '../render-amount'
import { getCompany } from '../state/company-actions'
import { addItem, create, SAVE_PROCESS } from '../state/entry-actions'
import { RootData } from '../state/root-data'
import { browserOnly } from '../table-utils'
import { valErr } from '../val-err'
import { BackLink } from './back-link'
import { Button } from './button'
import { DateInput } from './date-input'
import { EntryItemTable } from './entry-item-table'
import { Input } from './input'
import { LoadingIcon } from './loading-icon'
import { LoadingPage } from './loading-page'
import { type BaseRow, renderTable } from './table'

const getLeftAligned = () => ({ className: 'text-left' })
const getRightAligned = () => ({ className: 'text-right' })

const renderDateInput = (
    inputValues: InputValues,
    minDate: Day,
    valErrors: ValidationError[] | undefined,
) =>
    React.createElement(
        'div',
        null,
        React.createElement('span', { className: 'entries__label' }, t.entries.date.get(), ': '),
        React.createElement(DateInput, {
            input: inputs.entry.date,
            inputValues,
            minDate,
        }),
        valErr(valErrors, 'entry.date'),
    )

const renderDescriptionInput = (
    inputValues: InputValues,
    valErrors: ValidationError[] | undefined,
) =>
    React.createElement(
        'div',
        { className: 'top-margin' },
        React.createElement(
            'span',
            { className: 'entries__label' },
            t.entries.description.get(),
            ': ',
        ),
        React.createElement(Input, {
            type: 'text',
            input: inputs.entry.description,
            inputValues,
            className: 'entries__description-input',
        }),
        valErr(valErrors, 'entry.description'),
    )

const renderAmountInput = (inputValues: InputValues, valErrors: ValidationError[] | undefined) =>
    React.createElement(
        'div',
        { className: 'top-margin' },
        React.createElement('span', { className: 'entries__label' }, t.amount.get(), ': '),
        React.createElement(Input, {
            type: 'text',
            input: inputs.entry.amount,
            inputValues,
            className: 'amount-input',
        }),
        ' €',
        valErr(valErrors, 'entry.items.item-0.amount'),
    )

const renderReadOnlyItems = (accounts: AllAccounts | null, presetState: PresetState) => {
    if (!accounts) {
        return React.createElement(LoadingIcon, { color: 'black' })
    }

    const columns: Column<EntryItem<number>>[] = [
        {
            header: { content: t.account.get(), getProps: getLeftAligned },
            render: (row) => {
                const prefix = getAccountPrefix(row.accountNumber)
                const name = getLevel4AccountName(row.accountNumber, accounts)
                return prefix + ': ' + row.accountNumber + ' ' + name
            },
        },
        {
            header: { content: t.amount.get(), getProps: getRightAligned },
            getProps: getRightAligned,
            render: (row) => renderAmount(row.amount),
        },
        {
            header: { content: t.type.get(), getProps: getLeftAligned },
            render: browserOnly((row) =>
                React.createElement(Button, {
                    text: t[row.type].get(),
                    className: 'button--primary-selected button--min-width',
                    disabled: true,
                }),
            ),
        },
    ]

    return renderTable<EntryItem<number> & BaseRow>({
        columns,
        rows: getPresetItemsFromInputs(presetState, presetState.amountNumber),
        tableClassName: 'table table--bottom-border entries__item-table',
    })
}

const renderMaxAmountError = (maxAmount: number) =>
    React.createElement(
        'div',
        { className: 'text-warning top-margin' },
        t.entries.overMaxAmount.get(maxAmount),
    )

const renderItems = (
    presetState: PresetState | undefined,
    itemIds: string[],
    inputValues: InputValues,
    accounts: AllAccounts | null,
    valErrors: ValidationError[] | undefined,
) => {
    if (presetState) {
        return React.createElement(
            Fragment,
            null,
            renderAmountInput(inputValues, valErrors),
            presetState.isValidAmount
                ? renderReadOnlyItems(accounts, presetState)
                : renderMaxAmountError(presetState.preset.maxAmount!),
        )
    } else {
        return React.createElement(EntryItemTable, { itemIds, inputValues, accounts, valErrors })
    }
}

const renderItemsValidationErrors = (valErrors: ValidationError[] | undefined) => {
    return valErr(valErrors, 'entry.items', {
        'cash-flow-mismatch': t.entries.cashFlowMismatch.get(),
        'income-statement-mismatch': (error) => {
            const level4Number = error.level4Number as string
            const accountType = error.accountType as 'credit' | 'debit'
            return t.entries.incomeStatementMismatch[accountType].get(level4Number)
        },
        'negative-balance': (error) => {
            return t.entries.negativeBalance.get(Day.fromYmd(error.date).dmy(), error.account)
        },
    })
}

const renderAddButton = (visible: boolean) => {
    if (!visible) {
        return null
    }

    return React.createElement(Button, {
        onClick: addItem,
        text: t.entries.addItem.get(),
        className: 'button--secondary',
    })
}

const renderSaveButton = (presetId: string, processes: Processes, disabled: boolean) =>
    React.createElement(Button, {
        className: 'button--primary',
        onClick: async () => create(presetId),
        text: t.save.get(),
        processes,
        processName: SAVE_PROCESS,
        domId: 'save',
        disabled,
    })

export const EntryEdit: FC<RootData> = (rootData) => {
    const {
        accountData: { allAccounts },
        companyData,
        entryData: { itemIds },
        inputValues,
        processes,
        session,
        validationErrors,
        view,
    } = rootData

    if (!companyData.companies) {
        return React.createElement(LoadingPage)
    }

    const { presetId } = assertViewName(view, 'EntryEdit')

    const company = getCompany(companyData, session)
    const minDate = getMinDay(company.interimDate)
    const valErrors = validationErrors[SAVE_PROCESS]
    const presetState = getPresetState(presetId, inputValues)

    return React.createElement(
        'div',
        { className: 'content-area' },
        React.createElement(
            'div',
            { className: 'content entries entries--edit' },
            React.createElement(
                'div',
                null,
                React.createElement(BackLink),
                React.createElement('h1', { className: 'title' }, t.entries.adding.get()),
                renderDateInput(inputValues, minDate, valErrors),
                renderDescriptionInput(inputValues, valErrors),
                renderItems(presetState, itemIds, inputValues, allAccounts, valErrors),
                renderItemsValidationErrors(valErrors),
                React.createElement(
                    'div',
                    { className: 'top-margin' },
                    renderAddButton(!presetState),
                ),
            ),
            renderSaveButton(
                presetId,
                processes,
                Boolean(presetState && !presetState.isValidAmount),
            ),
        ),
    )
}
