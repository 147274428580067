import React, { FC, Fragment } from 'react'

import { renderAmount } from '../../render-amount'
import { renderValidationErrors } from '../../val-err'
import { Button, ButtonProps } from '../button'
import { DateInput, DateInputProps } from '../date-input'
import { Input, InputProps } from '../input'
import { MenuToggle } from '../menu-toggle'
import { ValidationErrorProps } from '../validation-error'

interface AmountInputsProps {
    interest: {
        title: string
        input: InputProps
        errors: ValidationErrorProps[]
    }
    totalToPay: {
        title: string
        amount: number
        isInvalid: boolean
    }
    fromPrepaid: {
        title: string
        input: InputProps
        errors: ValidationErrorProps[]
    }
    fromBank: {
        title: string
        amount: number
        isInvalid: boolean
    }
}

export interface VatPaymentSidebarProps {
    onClose: () => void
    title: string
    overpaid?: {
        title: string
        amount: number
    }
    amountInputs?: AmountInputsProps
    date: {
        title: string
        input: DateInputProps
        errors: ValidationErrorProps[]
    }
    errors: ValidationErrorProps[]
    saveButton: Omit<ButtonProps, 'className'>
}

const renderAmountInputs = ({ interest, totalToPay, fromPrepaid, fromBank }: AmountInputsProps) => {
    const totalClass = totalToPay.isInvalid ? 'sidebar-warning' : ''
    const fromBankClass = fromBank.isInvalid ? 'sidebar-warning' : ''

    return React.createElement(
        Fragment,
        null,
        React.createElement('h3', { className: 'sidebar__section-title' }, interest.title),
        React.createElement(
            'div',
            null,
            React.createElement(Input, {
                ...interest.input,
                className: 'input input--white sidebar__input amount',
            }),
            ' €',
            renderValidationErrors(interest.errors),
        ),
        React.createElement('h3', { className: 'sidebar__section-title' }, totalToPay.title),
        React.createElement('div', { className: totalClass }, renderAmount(totalToPay.amount)),
        React.createElement('h3', { className: 'sidebar__section-title' }, fromPrepaid.title),
        React.createElement(
            'div',
            null,
            React.createElement(Input, {
                ...fromPrepaid.input,
                className: 'input input--white sidebar__input amount',
            }),
            ' €',
            renderValidationErrors(fromPrepaid.errors),
        ),
        React.createElement('h3', { className: 'sidebar__section-title' }, fromBank.title),
        React.createElement('div', { className: fromBankClass }, renderAmount(fromBank.amount)),
    )
}

const renderDate = ({ title, input, errors }: VatPaymentSidebarProps['date']) =>
    React.createElement(
        Fragment,
        null,
        React.createElement('h3', { className: 'sidebar__section-title' }, title),
        React.createElement(DateInput, {
            ...input,
            className: 'date-button date-button--white sidebar__wide-button',
            renderAsBlock: true,
        }),
        renderValidationErrors(errors),
    )

export const VatPaymentSidebar: FC<VatPaymentSidebarProps> = (props) => {
    const { onClose, title, overpaid, amountInputs, date, errors, saveButton } = props

    return React.createElement(
        'div',
        { className: 'sidebar sidebar--flex open' },
        React.createElement(
            'div',
            null,
            React.createElement(MenuToggle, { onClick: onClose }),
            React.createElement('h1', { className: 'title sidebar__title' }, title),
            overpaid &&
                React.createElement(
                    Fragment,
                    null,
                    React.createElement(
                        'h3',
                        { className: 'sidebar__section-title' },
                        overpaid.title,
                    ),
                    React.createElement('div', null, renderAmount(overpaid.amount)),
                ),
            amountInputs && renderAmountInputs(amountInputs),
            renderDate(date),
            React.createElement(
                'div',
                { className: 'top-margin bottom-margin' },
                renderValidationErrors(errors),
            ),
        ),
        React.createElement(Button, {
            ...saveButton,
            className: 'payment__save-button button--white',
            loadingColor: 'white',
        }),
    )
}
