import { Day } from './time'
import { CompanyInput, DayOfYear } from './types/company'

export interface FiscalYear {
    start: Day
    end: Day
    isFirst: boolean
}

// For example, clampToMonth(2017, 2, 29) returns a Day for 2017-02-28.
// The month parameter is 1-based.
export const clampToMonth = (year: number, month: number, dayOfMonth: number): Day => {
    const firstOfMonth = Day.fromNumeric(year, month, 1)
    const lastOfMonth = firstOfMonth.lastOfMonth()

    if (dayOfMonth < 1) {
        // TODO needed?
        return firstOfMonth
    } else if (dayOfMonth > lastOfMonth.dayOfMonth()) {
        return lastOfMonth
    } else {
        return firstOfMonth.withDayOfMonth(dayOfMonth)
    }
}

const getRegularStart = (year: number, fiscalYearBegin: DayOfYear) => {
    return clampToMonth(year, fiscalYearBegin.month, fiscalYearBegin.dayOfMonth)
}

export const getFirstFiscalYear = (
    registrationDate: string,
    fiscalYearBegin: DayOfYear,
    longFirstYear: boolean,
): FiscalYear => {
    const startOfFirst = Day.fromYmd(registrationDate)
    const firstYear = startOfFirst.year()
    const startInFirstYear = getRegularStart(firstYear, fiscalYearBegin)

    const yearOffset =
        (startInFirstYear.isSameOrBefore(startOfFirst) ? 1 : 0) + (longFirstYear ? 1 : 0)

    const startOfSecond = getRegularStart(firstYear + yearOffset, fiscalYearBegin)
    return { start: startOfFirst, end: startOfSecond.addDays(-1), isFirst: true }
}

export const getFirstFiscalYearForCompany = (company: CompanyInput) => {
    const { registrationDate, fiscalYearBegin, longFirstYear } = company
    return getFirstFiscalYear(registrationDate, fiscalYearBegin, longFirstYear)
}

export const getFiscalYear = (
    refDate: Day,
    registrationDate: string,
    fiscalYearBegin: DayOfYear,
    longFirstYear: boolean,
): FiscalYear => {
    const firstYear = getFirstFiscalYear(registrationDate, fiscalYearBegin, longFirstYear)

    if (refDate.isAfter(firstYear.end)) {
        let startOfCurrent = getRegularStart(refDate.year(), fiscalYearBegin)

        if (startOfCurrent.isAfter(refDate)) {
            startOfCurrent = getRegularStart(refDate.year() - 1, fiscalYearBegin)
        }

        const startOfNext = getRegularStart(startOfCurrent.year() + 1, fiscalYearBegin)
        return { start: startOfCurrent, end: startOfNext.addDays(-1), isFirst: false }
    } else if (refDate.isSameOrAfter(firstYear.start)) {
        return firstYear
    } else {
        throw new Error('Cannot get fiscal year before registration date')
    }
}

export const getFiscalYearForCompany = (refDate: Day, company: CompanyInput) => {
    const { registrationDate, fiscalYearBegin, longFirstYear } = company
    return getFiscalYear(refDate, registrationDate, fiscalYearBegin, longFirstYear)
}

export const canHaveLongFirstYear = (registrationDate: string, fiscalYearBegin: DayOfYear) => {
    const max = Day.fromYmd(registrationDate).addMonths(18)
    const firstYear = getFirstFiscalYear(registrationDate, fiscalYearBegin, true)
    return firstYear.end.isBefore(max)
}
