import classnames from 'classnames'
import React, { FC, ImgHTMLAttributes } from 'react'

import { cleanString } from '../../common/clean-string'
import { invariant } from '../../common/invariant'
import { sort } from '../../common/sort'
import { AccountData, AccountInputs, AccountType } from '../../common/types/account'
import { InputValues } from '../../common/types/inputs'
import { ItemInputs, ItemType } from '../../common/types/item'
import { getDefaultAccountName } from '../account-utils'
import { emitFocusInput, runAfterNextRender } from '../event-bus'
import { t } from '../i18n'
import { addNew as addNewAccount, getByNumber } from '../state/account-actions'
import { Input } from './input'
import { LoadingIcon } from './loading-icon'

export interface AccountInputProps<IT extends ItemType> {
    id: string // Must be different from other AccountInput elements rendered in the same tree
    itemInputs: ItemInputs<IT>
    inputValues: InputValues
    getAccountType: (itemType: IT) => AccountType
    getAccountNumber: (itemType: IT) => string
    accountData: AccountData
    editMode: boolean
    afterChange?: (number: number) => void
}

interface SimpleAccount {
    name: string
    number: number
    type: AccountType
}

interface Match {
    account: SimpleAccount
    pos: number
}

const renderAddNew = (
    visible: boolean,
    addNew: () => void,
    index: number,
    inputValues: InputValues,
    accountInputs: AccountInputs,
) => {
    if (!visible) {
        return null
    }

    const text = accountInputs.text.get(inputValues)

    if (text) {
        const highlightInput = accountInputs.highlight
        const highlightedIndex = highlightInput.get(inputValues)
        const className = classnames('add-new', { highlighted: index === highlightedIndex })
        const onMouseEnter = () => highlightInput.set(index)

        return React.createElement(
            'div',
            { className, onClick: addNew, onMouseEnter },
            t.addNew.get(),
            ': ',
            React.createElement('b', null, text),
        )
    } else {
        return React.createElement('div', { className: 'add-info' }, t.account.addInfo.get())
    }
}

const getMatches = (accounts: SimpleAccount[], text: string, accountType: AccountType) => {
    const textToMatch = cleanString(text, true)
    const matches: Match[] = []

    for (const account of accounts) {
        if (account.type !== accountType) {
            continue
        }

        const isDefault = account.number === 1

        // Always match default
        const pos = isDefault ? 0 : cleanString(account.name, true).indexOf(textToMatch)

        if (pos !== -1) {
            matches.push({ account, pos })
        }
    }

    return sort(matches, [
        { getKey: (match) => (match.account.number === 1 ? 0 : 1) }, // Default account first
        { getKey: (match) => match.pos },
        { getKey: (match) => match.account.name },
    ])
}

const getOnKeyDown = (
    accountInputs: AccountInputs,
    inputValues: InputValues,
    matches: Match[],
    setAccountFromMatch: (match: Match) => void,
    addNew: () => void,
) => {
    const highlightInput = accountInputs.highlight
    const highlightedIndex = highlightInput.get(inputValues)

    return (evt: React.KeyboardEvent) => {
        const { key } = evt

        if (key === 'ArrowUp') {
            if (highlightedIndex > 0) {
                highlightInput.set(highlightedIndex - 1)
            }
        } else if (key === 'ArrowDown') {
            if (highlightedIndex < matches.length) {
                highlightInput.set(highlightedIndex + 1)
            }
        } else if (key === 'Enter') {
            if (highlightedIndex < matches.length) {
                setAccountFromMatch(matches[highlightedIndex])
            } else {
                addNew()
            }
        } else {
            return
        }

        evt.stopPropagation()
        evt.preventDefault()
    }
}

const renderNameOrInput = (
    editMode: boolean,
    id: string,
    accountInputs: AccountInputs,
    inputValues: InputValues,
    level3Number: string,
    defaultAccount: SimpleAccount,
    accountData: AccountData,
    accountType: AccountType,
) => {
    const openedInput = accountInputs.opened
    const numberInput = accountInputs.number

    const opened = openedInput.get(inputValues)
    const number = numberInput.get(inputValues) || 1
    const level4Number = level3Number + '.' + number

    if (editMode && (number === 1 || opened)) {
        const afterTextChange = (newText: string) => {
            accountInputs.highlight.set(0)

            const isEmpty = newText.length === 0
            openedInput.set(!isEmpty)

            if (isEmpty) {
                numberInput.set(1)
            }
        }

        return React.createElement(Input, {
            input: accountInputs.text,
            inputValues,
            afterChange: afterTextChange,
            placeholder: level4Number + ' ' + defaultAccount.name,
            className: 'account-input',
            focusEventId: 'item-account-' + id,
        })
    } else {
        const account =
            number === 1 ? defaultAccount : getByNumber(accountData, accountType, number)
        invariant(account, 'Account not found: ' + number)
        return React.createElement('span', null, level4Number, ' ', account.name)
    }
}

const renderOpenButton = (
    editMode: boolean,
    id: string,
    accountInputs: AccountInputs,
    inputValues: InputValues,
    accountData: AccountData,
    accountType: AccountType,
) => {
    if (!editMode) {
        return null
    }

    const props: ImgHTMLAttributes<HTMLImageElement> = {
        src: '/icons/triangle.svg',
        className: 'open-auto-complete',
    }

    const openedInput = accountInputs.opened
    const textInput = accountInputs.text

    if (openedInput.get(inputValues)) {
        props.onClick = () => {
            openedInput.set(false)
            textInput.set('')
            accountInputs.highlight.set(0)
        }
    } else {
        props.onClick = () => {
            const number = accountInputs.number.get(inputValues)

            if (number > 1) {
                const account = getByNumber(accountData, accountType, number)
                invariant(account, 'Account not found: ' + number)
                textInput.set(account.name)
            }

            openedInput.set(true)
            runAfterNextRender(() => emitFocusInput('item-account-' + id))
        }
    }

    return React.createElement('img', props)
}

const renderMatches = (
    opened: boolean,
    matches: Match[],
    inputValues: InputValues,
    accountInputs: AccountInputs,
    level3Number: string,
    setAccountFromMatch: (match: Match) => void,
    addNew: () => void,
) => {
    if (!opened) {
        return null
    }

    const highlightInput = accountInputs.highlight
    const highlightedIndex = highlightInput.get(inputValues)
    const text = accountInputs.text.get(inputValues)

    const exactMatchFound = matches.some((match) => match.account.name === text)

    return React.createElement(
        'div',
        { className: 'auto-complete' },
        ...matches.map((match, index) => {
            const { number } = match.account

            const className = classnames('match', {
                highlighted: index === highlightedIndex,
                default: number === 1,
            })

            const onClick = () => setAccountFromMatch(match)
            const onMouseEnter = () => highlightInput.set(index)
            const level4Number = level3Number + '.' + number
            return React.createElement(
                'div',
                { className, onClick, onMouseEnter },
                level4Number,
                ' ',
                match.account.name,
            )
        }),
        renderAddNew(!exactMatchFound, addNew, matches.length, inputValues, accountInputs),
    )
}

const AccountInput: FC<AccountInputProps<any>> = <IT extends ItemType>(
    props: AccountInputProps<IT>,
) => {
    const {
        id,
        itemInputs,
        inputValues,
        getAccountType,
        getAccountNumber,
        accountData,
        editMode,
        afterChange,
    } = props

    const accountInputs = itemInputs.account
    const saving = accountInputs.saving.get(inputValues)

    if (saving) {
        return React.createElement(LoadingIcon, { color: 'black' })
    } else {
        // TODO better keyboard support

        const itemType: IT = itemInputs.type.get(inputValues)
        const accountType = getAccountType(itemType)
        const level3Number = getAccountNumber(itemType)

        const defaultAccount: SimpleAccount = {
            name: getDefaultAccountName(level3Number),
            number: 1,
            type: accountType,
        }

        const opened = accountInputs.opened.get(inputValues)

        const setAccountFromMatch = (match: Match) => {
            accountInputs.opened.set(false)
            accountInputs.number.set(match.account.number)
            accountInputs.text.set('')
            accountInputs.highlight.set(0)

            if (afterChange) {
                afterChange(match.account.number)
            }
        }

        const addNew = async () => {
            const newNumber = await addNewAccount(accountInputs, accountType, inputValues)

            if (afterChange) {
                afterChange(newNumber)
            }
        }

        let matches: Match[] = [],
            onKeyDown

        if (opened) {
            const accounts = [defaultAccount, ...accountData.accounts!]
            const text = accountInputs.text.get(inputValues)
            matches = getMatches(accounts, text, accountType)
            onKeyDown = getOnKeyDown(
                accountInputs,
                inputValues,
                matches,
                setAccountFromMatch,
                addNew,
            )
        }

        return React.createElement(
            'div',
            { className: 'inline nowrap', onKeyDown },
            renderNameOrInput(
                editMode,
                id,
                accountInputs,
                inputValues,
                level3Number,
                defaultAccount,
                accountData,
                accountType,
            ),
            renderOpenButton(editMode, id, accountInputs, inputValues, accountData, accountType),
            renderMatches(
                editMode && opened,
                matches,
                inputValues,
                accountInputs,
                level3Number,
                setAccountFromMatch,
                addNew,
            ),
        )
    }
}

export const renderAccountInput = <IT extends ItemType>(props: AccountInputProps<IT>) =>
    React.createElement<AccountInputProps<IT>>(AccountInput, props)
