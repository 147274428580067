import React, { FC } from 'react'

import { getExpenseAccountNumber } from '../../common/accounts'
import { MIN_DATE } from '../../common/clock'
import { MAX_ROWS } from '../../common/constants'
import { expenseAccountTypes, expenseItemTypes, expenseTypes } from '../../common/enums'
import { calculateItem } from '../../common/item-utils'
import { keys } from '../../common/keys'
import { sort, SortOption } from '../../common/sort'
import { Day } from '../../common/time'
import { AccountData } from '../../common/types/account'
import { ApiExpense } from '../../common/types/expense'
import { Column } from '../../common/types/table'
import { getAccountName } from '../account-utils'
import { assertViewName } from '../assert-view-name'
import { getExcelButtonProps } from '../excel-utils'
import { ExcelSpec } from '../excel/types'
import { t } from '../i18n'
import { createMonthUrlInput } from '../input-utils'
import { inputs } from '../inputs'
import { renderAmount, renderAmountOrDash } from '../render-amount'
import { renderExcelMoney } from '../render-excel-money'
import { getShowAllButtonProps, wrapExcelButton } from '../standard-page-utils'
import { getCompany } from '../state/company-actions'
import { RootData } from '../state/root-data'
import { browserOnly } from '../table-utils'
import { Button } from './button'
import { ExcelButtonProps } from './excel-button'
import { LoadingPage } from './loading-page'
import { MonthNav } from './month-nav'
import { NoData } from './no-data'
import { renderSortOptions } from './sort-options'
import { StandardPage } from './standard-page'
import { type BaseRow, renderTable } from './table'
import { ViewIcon } from './view-icon'

interface Row extends BaseRow {
    expenseId: string
    date: Day
    vendor: string
    account: string
    description: string
    withoutVat: number
    extraRows: number
}

interface Totals {
    withoutVat: number
}

export type SortId = 'date' | 'account' | 'withoutVat' | 'vendor'

const LEVEL3_NUMBER = getExpenseAccountNumber(expenseItemTypes.general)

const alignRight = () => ({ className: 'text-right' })
const getAmountClass = () => ({ className: 'amount' })

const getColumns = (): Column<Row, Totals>[] => [
    {
        header: { content: t.date.get() },
        render: (row) => ({ browser: row.date.dmy(), excel: row.date }),
        excelWidth: 15,
    },
    {
        header: { content: t.expenses.vendor.get() },
        render: (row) => row.vendor,
        excelWidth: 30,
    },
    {
        header: { content: t.expenses.account.get() },
        render: (row) => row.account,
        excelWidth: 25,
    },
    {
        header: { content: t.sum.get(), getProps: alignRight },
        getProps: getAmountClass,
        render: (row) => ({
            browser: renderAmountOrDash(row.withoutVat),
            excel: renderExcelMoney(row.withoutVat, false),
        }),
        getTotal: (totals) => renderAmount(totals.withoutVat),
        getExcelTotal: (totals) => ({ type: 'sum-money', value: totals.withoutVat }),
        getTotalProps: getAmountClass,
        excelWidth: 15,
    },
    {
        header: { content: t.description.get() },
        render: (row) => row.description,
        excelWidth: 30,
    },
    {
        header: {
            content: t.actions.get(),
            getProps: () => ({ className: 'text-center' }),
        },
        getProps: () => ({ className: 'actions text-center' }),
        render: browserOnly((row) =>
            React.createElement(ViewIcon, { href: '#/expenses/view/' + row.expenseId }),
        ),
        hideInExcel: true,
    },
]

const getRows = (
    shownMonth: string,
    confirmedRegularExpenses: ApiExpense[],
    sortOption: SortOption<Row>,
    accountData: AccountData,
) => {
    const rows: Row[] = []

    for (const expense of confirmedRegularExpenses) {
        if (!expense.date.startsWith(shownMonth)) {
            continue
        }

        const { items, calculationMode, vendor } = expense
        const byAccount = new Map<number, Row>()

        for (const item of items!) {
            if (item.type === expenseItemTypes.general) {
                const { payableWithoutVat } = calculateItem(calculationMode, item, MIN_DATE)
                const { account } = item

                if (byAccount.has(account)) {
                    const row = byAccount.get(account)!
                    row.withoutVat += payableWithoutVat
                    row.extraRows += 1
                } else {
                    byAccount.set(account, {
                        expenseId: expense._id,
                        date: Day.fromYmd(expense.date),
                        vendor: vendor.name,
                        account: getAccountName(
                            LEVEL3_NUMBER,
                            account,
                            accountData,
                            expenseAccountTypes.general,
                        ),
                        description: item.description,
                        withoutVat: payableWithoutVat,
                        extraRows: 0,
                    })
                }
            }
        }

        for (const row of byAccount.values()) {
            if (row.extraRows) {
                row.description += ' (+' + row.extraRows + ')'
            }

            rows.push(row)
        }
    }

    return sort(rows, sortOption)
}

export const GeneralExpenseList: FC<RootData> = (rootData) => {
    const {
        accountData,
        companyData,
        expenseData: { expenses },
        inputValues,
        processes,
        progress,
        session,
        view,
    } = rootData

    if (!expenses || !accountData.accounts || !companyData.companies) {
        return React.createElement(LoadingPage)
    }

    const anyGeneral = expenses.some(
        (expense) =>
            expense.type === expenseTypes.regular &&
            expense.items!.some((item) => item.type === expenseItemTypes.general),
    )

    if (!anyGeneral) {
        return React.createElement(NoData, {
            addRoute: '#/expenses/add/regular',
            addButtonText: t.expenses.add.general.get(),
        })
    }

    const sortOptions: { [S in SortId]: SortOption<Row> } = {
        date: [{ getKey: (row) => row.date.toTimestamp(), reverse: true }],
        account: [{ getKey: (row) => row.account }],
        withoutVat: [{ getKey: (row) => row.withoutVat, reverse: true }],
        vendor: [{ getKey: (row) => row.vendor }],
    }

    const confirmedRegularExpenses: ApiExpense[] = expenses.filter(
        (expense) => expense.type === expenseTypes.regular && expense.confirmed,
    )

    // TODO undup
    const tableInputs = inputs.expense.general
    const showAll = tableInputs.showAll.get(inputValues)
    const sortId = tableInputs.sort.get(inputValues)

    const { month } = assertViewName(view, 'GeneralExpenseList')
    const shownMonth = month || Day.today().ym()
    const monthInput = createMonthUrlInput('#/expenses/general/', shownMonth)

    const allRows = getRows(shownMonth, confirmedRegularExpenses, sortOptions[sortId], accountData)
    const hasMore = allRows.length > MAX_ROWS
    const rows: Row[] = showAll ? allRows : allRows.slice(0, MAX_ROWS)
    const columns = getColumns()

    const totals: Totals = { withoutVat: rows.reduce((sum, row) => sum + row.withoutVat, 0) }

    const parsedMonth = Day.fromYm(shownMonth)
    const title = t.expenses.general.get()
    const subtitle = parsedMonth.longMonth()

    const company = getCompany(companyData, session)
    const interimDate = Day.fromYmd(company.interimDate)

    let excelButton: ExcelButtonProps | undefined

    if (rows.length) {
        const excelSpec: ExcelSpec<Row, Totals> = {
            columns,
            rows,
            totals,
            outputName: title + ' - ' + subtitle,
        }

        const progressPrefix = t.expenses.processed.get()

        excelButton = getExcelButtonProps(
            excelSpec,
            progressPrefix,
            processes,
            progress,
            'button button--wide button--primary',
        )
    }

    return React.createElement(StandardPage, {
        title,
        subtitle,
        sortOptions: rows.length
            ? renderSortOptions({
                  // TODO move condition to StandardPage?
                  input: tableInputs.sort,
                  inputValues,
                  options: keys(sortOptions).map((key) => ({
                      id: key,
                      label: t.expenses.general.sortOption[key].get(),
                  })),
              })
            : null,
        buttons: [
            React.createElement(MonthNav, {
                day: parsedMonth,
                input: monthInput,
                minMonth: interimDate,
            }),
            excelButton && wrapExcelButton(excelButton),
        ],
        table: React.createElement(
            'div',
            null,
            renderTable({
                columns,
                rows,
                totals,
                stickyHeader: true,
                tableClassName: 'main-table',
                wrapperClassName: 'main-table-wrapper',
            }),
            // TODO move to StandardPage
            !showAll &&
                hasMore &&
                React.createElement(Button, getShowAllButtonProps(tableInputs.showAll)),
        ),
    })
}
