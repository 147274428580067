import { Day } from '../time'
import { BusinessFields, DbBusinessFields } from './business'
import {
    AssetChangeMode,
    AssetType,
    CalculationMode,
    ExpenseType,
    LabourPaymentType,
} from './enums'
import { AutomaticItem, ManualItem } from './item'
import { LogEntry } from './log'
import { DbPayment } from './payment'
import { ExpenseVatPercentage } from './vat'

export type ExpenseItemType = 'goods-expense' | 'goods-stock' | 'general'

interface StockChangeValues<N extends number | string> {
    newQuantity?: N
    newUnitPrice?: N
}

export interface ItemStockChange extends StockChangeValues<number> {
    date: string
}

interface ExpenseItemAddedFields {
    stockChanges?: ItemStockChange[]
}

export interface AutomaticExpenseItem<N>
    extends AutomaticItem<ExpenseItemType, N, ExpenseVatPercentage>,
        ExpenseItemAddedFields {}

export interface ManualExpenseItem<N>
    extends ManualItem<ExpenseItemType, N>,
        ExpenseItemAddedFields {}

export type ExpenseItem<N> = AutomaticExpenseItem<N> | ManualExpenseItem<N>

export interface AutomaticExpenseItemInput extends AutomaticExpenseItem<string> {}
export interface ManualExpenseItemInput extends ManualExpenseItem<string> {}

export type ExpenseItemInput = AutomaticExpenseItemInput | ManualExpenseItemInput

export interface AssetResidualChange<N> {
    mode: 'residual'
    date: string
    residual: N
}
export interface AssetEolChange {
    mode: 'eol'
    date: string
    eolDate: string
}
export type AssetValueChange<N> = AssetResidualChange<N> | AssetEolChange

export interface AssetTotals<N> {
    withoutVat: N
    other: N
    vatPercentage: ExpenseVatPercentage
}

interface AssetCommon<N> {
    id: string
    type: AssetType
    description: string
    totals: AssetTotals<N>
    amortBegin?: string
    eolDate?: string
}

export interface AssetInput extends AssetCommon<string> {}

export interface DbAsset extends AssetCommon<number> {
    valueChanges: AssetValueChange<number>[]
}

export interface ExpenseTotals<N> {
    vat: N // TODO rename to vatAmount?
    payableWithVat: N
}

export interface ExpenseFile {
    filename: string
    hash: string
    size: number
}

export enum ExpenseRegion {
    ee = 'ee',
    eu = 'eu',
    other = 'other',
}

interface UpdateFields<N, V, A> {
    calculationMode: CalculationMode
    number: string
    date: string
    dueDate: string
    vendor: V & { region: ExpenseRegion }
    items?: Array<AutomaticExpenseItem<N> | ManualExpenseItem<N>> // if type === 'regular'
    assets?: A[] // if type === 'asset'
    comment?: string
    totals?: ExpenseTotals<N> // if calculationMode === 'manual'
}

export interface ExpenseUpdate extends UpdateFields<string, BusinessFields<string>, AssetInput> {}

interface InputFields<N, V, A> extends UpdateFields<N, V, A> {
    type: ExpenseType
}
export interface ExpenseInput extends InputFields<string, BusinessFields<string>, AssetInput> {}

interface Common<V> extends InputFields<number, V, DbAsset> {
    _id: string
    rev: number
    log: LogEntry[]
    confirmed: boolean
    paid: boolean
    payments: DbPayment[]
    removedPayments?: DbPayment[]
    vatPayer?: boolean // Added after confirmation
    files?: ExpenseFile[]
    vatMonth: string
}

export interface DbExpense extends Common<DbBusinessFields> {
    companyId: string
}
export interface ApiExpense extends Common<BusinessFields<string>> {}

export interface PaymentState {
    type: 'expense' | LabourPaymentType
    id: string
}

export interface AutomaticTotalsMismatch {
    vat: number
    total: number
}
export interface ManualTotalsMismatch {
    payableWithoutVat: number
}

export type TotalsMismatch = AutomaticTotalsMismatch | ManualTotalsMismatch

export interface FileStorageUsage {
    maxMb: number
    currentMb: number
}

export interface ExpenseData {
    expenses: ApiExpense[] | null
    pendingAssetChanges: PendingAssetChange[] | null
    pendingStockChanges: PendingStockChange[] | null
    itemIds: string[]
    assetIds: string[]
    logVisible: boolean
    paymentState: PaymentState | null
    justCreatedId: string | null
    totalsMismatch: TotalsMismatch | null
    fileStorageUsage: FileStorageUsage | null
}

export type FilterSection = 'general' | 'vendor' | 'types'

export interface AssetInitial {
    payableWithoutVat: number
    vatAmount: number
    withVat: number
}

export interface AssetCurrent {
    amortRate: number // ex: 4% is 0.04, not 4
    eolDate?: Day
    residual: number
    depreciation: number
}

export interface AssetChangeParams {
    isNew: boolean
    date?: string
    mode?: AssetChangeMode
    expenseId?: string
    assetId?: string
    existing?: PendingAssetChange
}

export interface PendingAssetChange {
    _id: string
    companyId: string // TODO omit from Api type?
    expenseId: string
    assetId: string
    rev: number
    valueChange: AssetValueChange<number>
}

export interface PendingStockChangeItem<N extends number | string> extends StockChangeValues<N> {
    expenseId: string
    itemId: string
}

export interface PendingStockChange {
    _id: string
    companyId: string // TODO omit from Api type?
    rev: number
    date: string
    items: PendingStockChangeItem<number>[]
}
