import lodashFind from 'lodash/find'
import React, { FC, Fragment } from 'react'

import { getAmountWithoutVat, getAmountWithVat } from '../../../common/card-payment-utils'
import { addressToString } from '../../../common/company-utils'
import { CardPaymentStatus } from '../../../common/enums'
import { Day } from '../../../common/time'
import { ApiCardPayment } from '../../../common/types/card-payment'
import { ApiCompany } from '../../../common/types/company'
import { InputValues } from '../../../common/types/inputs'
import { Processes } from '../../../common/types/processes'
import { getDefaultVatPercentage, isVatPayer } from '../../../common/vat-utils'
import { formatAmount } from '../../format-amount'
import { inputs } from '../../inputs'
import { getCompany, INIT_PAYMENT_PROCESS, initFirstPayment } from '../../state/company-actions'
import { RootData } from '../../state/root-data'
import { valErr } from '../../val-err'
import { Button } from '../button'
import { Checkbox } from '../checkbox'
import { Input } from '../input'
import { LoadingPage } from '../loading-page'
import { InitCompanyNav } from './nav'

// TODO i18n

const bilInputs = inputs.initCompany.billing

const isPending = (status: CardPaymentStatus) =>
    status === CardPaymentStatus.initial ||
    status === CardPaymentStatus.waiting_for_sca ||
    status === CardPaymentStatus.sent_for_processing ||
    status === CardPaymentStatus.waiting_for_3ds_response

const renderCompany = (company: ApiCompany) => {
    return React.createElement(
        Fragment,
        null,
        React.createElement(
            'div',
            { className: 'init-company__billing-company-title' },
            'BOOKY TEENUSE EEST TASUJA',
        ),
        React.createElement(
            'div',
            { className: 'init-company__billing-company-field' },
            React.createElement(
                'div',
                { className: 'init-company__billing-company-label' },
                'Nimi',
                ':',
            ),
            React.createElement(
                'div',
                { className: 'init-company__billing-company-value' },
                company.name,
            ),
        ),
        React.createElement(
            'div',
            { className: 'init-company__billing-company-field' },
            React.createElement(
                'div',
                { className: 'init-company__billing-company-label' },
                'Registrinumber',
                ':',
            ),
            React.createElement(
                'div',
                { className: 'init-company__billing-company-value' },
                company.regCode,
            ),
        ),
        React.createElement(
            'div',
            { className: 'init-company__billing-company-field' },
            React.createElement(
                'div',
                { className: 'init-company__billing-company-label' },
                'Aadress',
                ':',
            ),
            React.createElement(
                'div',
                { className: 'init-company__billing-company-value' },
                addressToString(company.address),
            ),
        ),
        company.vatId
            ? React.createElement(
                  'div',
                  { className: 'init-company__billing-company-field' },
                  React.createElement(
                      'div',
                      { className: 'init-company__billing-company-label' },
                      'KMKR number',
                      ':',
                  ),
                  React.createElement(
                      'div',
                      { className: 'init-company__billing-company-value' },
                      company.vatId,
                  ),
              )
            : null,
    )
}

const renderPendingPaymentWarning = (isVisible: boolean) => {
    if (!isVisible) {
        return null
    }

    return React.createElement(
        'div',
        { className: 'init-company__text-row' },
        'NB! Teil on mõni varasem makse veel pooleli. Soovitame uut makset alustada ainult juhul, ',
        'kui olete veendunud, et varasemad maksed on katkestatud.',
    )
}

const renderPaymentButton = (
    anyPendingPayments: boolean,
    inputValues: InputValues,
    processes: Processes,
) => {
    const agreementNeeded = !bilInputs.agree.get(inputValues)

    return React.createElement(
        Fragment,
        null,
        renderPendingPaymentWarning(anyPendingPayments),
        React.createElement(
            'div',
            { className: 'init-company__payment-button-row' },
            React.createElement(Button, {
                text: 'Maksma',
                onClick: initFirstPayment,
                processes,
                processName: INIT_PAYMENT_PROCESS,
                className: 'button--white init-company__payment-button',
                loadingColor: 'white',
                disabled: agreementNeeded,
                title: agreementNeeded ? 'Jätkamiseks peate tingimustega nõustuma' : null,
            }),
        ),
    )
}

const renderInformation = () =>
    React.createElement(
        'div',
        { className: 'init-company__payment-info' },
        React.createElement(
            'div',
            null,
            'Enne kasutamist tutvuge ',
            React.createElement(
                'a',
                {
                    href: 'https://minubooky.ee/terms/privacy',
                    target: '_blank',
                    className: 'init-company__link',
                },
                'Booky Privaatsustingimustega',
            ),
            '.',
        ),
        React.createElement(
            'p',
            null,
            'Teenuse eest tasu laekumisel kasutab Booky ',
            React.createElement(
                'a',
                {
                    href: 'https://every-pay.com/et/',
                    target: '_blank',
                    className: 'init-company__link',
                },
                'EveryPay',
            ),
            ' makselahendust ning teie poolt sisestatud kaardiandmeid Booky ei näe.',
        ),
        React.createElement(
            'p',
            null,
            'Teil on võimalik teenusest igal hetkel loobuda, sulgedes Booky rakenduses oma ettevõtte konto.',
        ),
        React.createElement('p', null, 'Jooksva kuu eest juba ettemakstud tasu ei tagastata.'),
        React.createElement(
            'p',
            null,
            'Igakuise arve saadame teile siin lehel teie poolt märgitud e-posti aadressile.',
        ),
        React.createElement(
            'p',
            null,
            'Soovi korral võtke meiega ühendust ',
            React.createElement(
                'a',
                { href: 'mailto:info@minubooky.ee', className: 'init-company__link' },
                'info@minubooky.ee',
            ),
        ),
    )

const renderPrice = (vatPayer: boolean) => {
    const invoiceDate = Day.today()
    const vatPercentage = getDefaultVatPercentage(invoiceDate)
    const amountWithVat = getAmountWithVat(vatPayer, vatPercentage)

    if (vatPayer) {
        const amountWithoutVat = getAmountWithoutVat(vatPayer, vatPercentage)

        return React.createElement(
            'div',
            { className: 'init-company__text-row' },
            React.createElement(
                'span',
                { className: 'init-company__text init-company__text--bold' },
                'Raamatupidamisprogramm Booky kasutamine kuutasuga ',
                formatAmount(amountWithoutVat),
                '€',
            ),
            React.createElement(
                'span',
                { className: 'init-company__text init-company__text--thin' },
                ' (käibemaksuga ',
                formatAmount(amountWithVat),
                '€)',
            ),
        )
    } else {
        return React.createElement(
            'div',
            { className: 'init-company__text-row' },
            React.createElement(
                'span',
                { className: 'init-company__text init-company__text--bold' },
                'Raamatupidamisprogramm Booky kasutamine kuutasuga ',
                formatAmount(amountWithVat),
                '€',
            ),
        )
    }
}

const renderContent = (rootData: RootData, cardPayments: ApiCardPayment[]) => {
    const { companyData, inputValues, processes, session, validationErrors } = rootData
    const successfulPayment = lodashFind(cardPayments, (payment) => payment.success)

    if (successfulPayment) {
        return React.createElement(
            'div',
            { className: 'init-company__already-paid' },
            React.createElement('div', null, 'Olete ettevõtte kasutamise eest juba tasunud.'),
            React.createElement(
                'div',
                { className: 'top-margin' },
                React.createElement(
                    'a',
                    {
                        href: '#/init-company/payment-status/' + successfulPayment._id,
                        className: 'button button--white',
                    },
                    'Vaata makse infot',
                ),
            ),
        )
    }

    const anyPendingPayments = cardPayments.some((payment) => isPending(payment.status))
    const company = getCompany(companyData, session)
    const valErrors = validationErrors[INIT_PAYMENT_PROCESS]

    const footerLabels = [
        'Booky OÜ',
        'Pärnu mnt 15 10141, Tallinn, Eesti',
        'Registrinumber 14176553',
        'KMKR EE102054679',
    ]

    const vatPayer = isVatPayer(company.vatPeriods)

    return React.createElement(
        Fragment,
        null,
        React.createElement(
            'div',
            { className: 'flex-center' },
            React.createElement(
                'div',
                { className: 'init-company__billing-fields' },
                renderCompany(company),
                React.createElement(
                    'div',
                    { className: 'init-company__billing-contact-title' },
                    'BOOKY ARVE SAAJA',
                ),
                React.createElement(
                    'div',
                    { className: 'init-company__input-row' },
                    React.createElement(Input, {
                        input: bilInputs.contact.name,
                        inputValues,
                        placeholder: 'Nimi',
                        className: 'init-company__input init-company__input--wide',
                    }),
                    valErr(valErrors, 'details.billingName'),
                ),
                React.createElement(
                    'div',
                    { className: 'init-company__input-row init-company__input-row--left' },
                    React.createElement(
                        'datalist',
                        { id: 'emails' },
                        React.createElement('option', { value: company.email }),
                        React.createElement('option', { value: session!.email }),
                    ),
                    React.createElement(Input, {
                        input: bilInputs.contact.email,
                        inputValues,
                        list: 'emails',
                        placeholder: 'E-post',
                        className: 'init-company__input init-company__input--wide',
                    }),
                    valErr(valErrors, 'details.billingEmail'),
                ),
            ),
            renderInformation(),
        ),
        React.createElement(
            'div',
            { className: 'init-company__billing-bottom' },
            renderPrice(vatPayer),
            React.createElement(
                'div',
                { className: 'init-company__text-row' },
                React.createElement(Checkbox, {
                    input: bilInputs.agree,
                    inputValues,
                }),
                ' Nõustun tingimuste ning igakuiste maksetega',
            ),
            React.createElement(
                'div',
                { className: 'init-company__credit-card-icons' },
                React.createElement('img', {
                    src: '/icons/mastercard.png',
                    className: 'init-company__credit-card-icon',
                    alt: 'Mastercard',
                    title: 'Mastercard',
                }),
                React.createElement('img', {
                    src: '/icons/visa.png',
                    className: 'init-company__credit-card-icon',
                    alt: 'VISA',
                    title: 'VISA',
                }),
            ),
            renderPaymentButton(anyPendingPayments, inputValues, processes),
        ),
        React.createElement(
            'div',
            { className: 'init-company__billing-footer' },
            footerLabels.join(' \u2022 '),
        ),
    )
}

export const InitCompanyBilling: FC<RootData> = (rootData) => {
    const { companyData, serverConf, session } = rootData

    if (!companyData.companies || !companyData.cardPayments || !serverConf) {
        return React.createElement(LoadingPage)
    }

    const company = getCompany(companyData, session)

    return React.createElement(
        'div',
        { className: 'init-company' },
        React.createElement(InitCompanyNav, { activeItem: 'billing', company }),
        React.createElement(
            'div',
            { className: 'init-company__main' },
            renderContent(rootData, companyData.cardPayments),
        ),
    )
}
