import classnames from 'classnames'

import { canAddPayments } from '../../common/access'
import { CompanyStatus } from '../../common/enums'
import { calculateRevenue } from '../../common/invoice-utils'
import { keys } from '../../common/keys'
import { getDaysUntil, getPaidAmount } from '../../common/payment-utils'
import { sort, SortOption } from '../../common/sort'
import { Day } from '../../common/time'
import { ApiRevenue, CreditRevenueData } from '../../common/types/invoice'
import {
    getUnpaidRevenuesColumns,
    SortId,
    UnpaidRevenuesGroup,
    UnpaidRevenuesProps,
    UnpaidRevenuesRow,
    UnpaidRevenuesTotals,
} from '../components/revenue/unpaid'
import { getExcelButtonProps } from '../excel-utils'
import { ExcelSpec } from '../excel/types'
import { t } from '../i18n'
import { inputs } from '../inputs'
import { getCustomerName } from '../revenue-utils'
import { getCompany } from '../state/company-actions'
import { getCreditRevenueById, getRevenueById, openPaymentForm } from '../state/revenue-actions'
import { RootData } from '../state/root-data'
import { getCreditRevenuePaymentProps } from './credit-revenue-payment-props'
import { getRevenuePaymentProps } from './revenue-payment-props'

const getOverdueGroup = (daysOverdue: number): UnpaidRevenuesGroup => {
    if (daysOverdue < 1) {
        return 'future'
    } else if (daysOverdue < 31) {
        return '1...30'
    } else if (daysOverdue < 61) {
        return '31...60'
    } else if (daysOverdue < 91) {
        return '61...90'
    } else {
        return '>90'
    }
}

const getRows = (
    revenues: ApiRevenue[],
    totals: UnpaidRevenuesTotals,
    date: string,
    sortOption: SortOption<UnpaidRevenuesRow>,
    payment: { id: string; isCredit: boolean } | null,
    companyStatus: CompanyStatus,
    creditRevenueData: CreditRevenueData,
) => {
    const rows: UnpaidRevenuesRow[] = []
    const maxTime = date + 'T24'

    for (const revenue of revenues) {
        if (!revenue.confirmed || revenue.date > date) {
            continue
        }

        const creditRevenue = getCreditRevenueById(creditRevenueData, revenue._id)

        if (creditRevenue) {
            continue
        }

        const filteredPayments = revenue.payments.filter((entry) => entry.date < maxTime)
        const paid = revenue.paid! && filteredPayments.length === revenue.payments.length

        if (paid) {
            continue
        }

        const { payableWithVat: total } = calculateRevenue(revenue)
        const alreadyPaid = getPaidAmount(filteredPayments)
        const unpaid = total - alreadyPaid

        const { _id, customer, number, term } = revenue
        const daysOverdue = getDaysUntil(revenue.date, date) - term
        const overdueGroup = getOverdueGroup(daysOverdue)

        totals[overdueGroup] += unpaid
        totals.unpaid += unpaid
        totals.full += total

        if (daysOverdue > 0) {
            totals.overdue += unpaid // TODO or total?
        }

        const row: UnpaidRevenuesRow = {
            className: classnames('table__row', 'table__row--bordered', {
                shaded: _id === payment?.id,
            }),
            number: number!,
            customer: getCustomerName(customer),
            overdueGroup,
            future: overdueGroup === 'future' ? unpaid : 0,
            unpaid,
            total,
            viewIcon: { href: '#/invoices/view/' + _id },
            actions: [],
        }

        if (canAddPayments(companyStatus)) {
            const percentUnpaid = total === 0 ? 0 : Math.round((100 * unpaid) / total)

            row.actions.push({
                text: t.revenues.percentUnpaid.get(percentUnpaid),
                onClick: async () => openPaymentForm(_id, false),
                className: 'payment-link',
            })
        }

        rows.push(row)
    }

    return sort(rows, sortOption)
}

export const getUnpaidRevenuesProps = (rootData: RootData): UnpaidRevenuesProps => {
    const {
        companyData,
        creditRevenueData,
        formsReady,
        inputValues,
        invoiceData,
        processes,
        progress,
        session,
        validationErrors,
    } = rootData

    const { invoices: revenues, payment } = invoiceData

    if (!revenues || !companyData.companies || !formsReady.has('unpaid-invoices')) {
        return { status: 'loading' }
    }

    if (!revenues.length) {
        return {
            status: 'no-data',
            noData: { addRoute: '#/invoices/add', addButtonText: t.revenues.add.get() },
        }
    }

    const date = inputs.invoice.unpaid.date.get(inputValues)
    const sortId = inputs.invoice.unpaid.sort.get(inputValues)

    const title = t.revenues.unpaidInvoices.get()
    const subtitle = t.asOf.get(Day.fromYmd(date).longDate())

    const totals: UnpaidRevenuesTotals = {
        future: 0,
        '1...30': 0,
        '31...60': 0,
        '61...90': 0,
        '>90': 0,
        overdue: 0,
        unpaid: 0,
        full: 0,
    }

    const sortOptions: { [S in SortId]: SortOption<UnpaidRevenuesRow> } = {
        amount: [{ getKey: (row) => row.unpaid, reverse: true }],
        customer: [{ getKey: (row) => row.customer, reverse: false }],
        number: [{ getKey: (row) => row.number, reverse: true }],
    }

    const company = getCompany(companyData, session)

    const rows = getRows(
        revenues,
        totals,
        date,
        sortOptions[sortId],
        payment,
        company.status,
        creditRevenueData,
    )

    const props: UnpaidRevenuesProps = {
        status: 'ok',
        title,
        subtitle,
        dateInput: {
            input: inputs.invoice.unpaid.date,
            inputValues,
            text: t.chooseDate.get(),
            maxDate: Day.today(),
        },
        sortOptions: {
            input: inputs.invoice.unpaid.sort,
            inputValues,
            options: keys(sortOptions).map((key) => ({
                id: key,
                label: t.revenues.sortOption[key].get(),
            })),
        },
        rows,
        totals,
    }

    if (payment?.id) {
        const revenueId = payment.id
        const revenue = getRevenueById(invoiceData, revenueId)!

        if (payment.isCredit) {
            const creditRevenue = getCreditRevenueById(creditRevenueData, revenueId)!

            props.paymentSidebar = getCreditRevenuePaymentProps(
                revenue,
                creditRevenue,
                inputValues,
                processes,
                validationErrors,
            )
        } else {
            const hasCreditRevenue = creditRevenueData.creditRevenues!.some((creditRevenue) => {
                return creditRevenue._id === revenueId
            })

            props.paymentSidebar = getRevenuePaymentProps(
                revenue,
                hasCreditRevenue,
                inputValues,
                processes,
                validationErrors,
            )
        }
    }

    if (rows.length) {
        // TODO Avoid invoking twice on each render
        const columns = getUnpaidRevenuesColumns()

        const excelSpec: ExcelSpec<UnpaidRevenuesRow, UnpaidRevenuesTotals> = {
            columns,
            rows,
            totals,
            hasSecondHeader: true,
            outputName: title + ' ' + subtitle,
        }

        props.excelButton = getExcelButtonProps(
            excelSpec,
            t.revenues.processed.get(),
            processes,
            progress,
            'button button--wide button--primary',
        )
    }

    return props
}
