import { sort } from '../../common/sort'
import { Day } from '../../common/time'
import { VatTableMode } from '../../common/types/taxes'
import { upperCaseFirst } from '../../common/upper-case-first'
import { INVOICE_LIMIT, VatCalc, VatSource, VatSourceType } from '../../common/vat-utils'
import { Row, VatTableProps } from '../components/taxes/vat-table'
import { getCurrentLanguage, t } from '../i18n'
import { createCustomInput } from '../input-utils'
import { dispatch } from '../state/store'

enum TypeSortKey {
    summary = 0,
    note = 1,
    regular = 2,
}

const createRow = (mainText: string, showAmounts: boolean, typeSortKey: TypeSortKey): Row => ({
    main: { text: mainText },
    date: '',
    showAmounts,
    withoutVat: 0,
    vatAmount: 0,
    withVat: 0,
    sortKeys: {
        type: typeSortKey,
        id: '',
    },
})

export const getVatTableProps = (
    mode: VatTableMode,
    ids: Record<VatSourceType, Set<string>>,
    getAllSources: () => VatSource[],
    getCalc: () => VatCalc | null,
    getSourcesFromCalc: (calc: VatCalc) => VatSource[],
    month: Day,
    customerHeader: string,
    setMode: (mode: VatTableMode) => void,
): VatTableProps => {
    const editMode = mode === VatTableMode.editing
    const isFiltered = mode === VatTableMode.showOverLimit

    const rows: Row[] = []
    let summaryRow: Row | undefined
    let sources: VatSource[] = []

    if (editMode) {
        sources = getAllSources()

        if (!sources.length) {
            summaryRow = createRow(t.taxes.vat.noneSelected.get(), !isFiltered, TypeSortKey.summary)

            rows.push(summaryRow)
        }
    } else {
        const calc = getCalc()!
        sources = getSourcesFromCalc(calc)

        if (sources.length) {
            const mainText = t.taxes.vat.monthTotal.get(
                upperCaseFirst(month.monthName()),
                month.year(),
            )

            summaryRow = createRow(mainText, !isFiltered, TypeSortKey.summary)

            summaryRow.main.toggleExpanded = () => {
                setMode(
                    mode === VatTableMode.collapsed ? VatTableMode.showAll : VatTableMode.collapsed,
                )
            }

            summaryRow.main.expandToggle = { expanded: mode !== VatTableMode.collapsed }
        } else {
            summaryRow = createRow(t.taxes.vat.noneSelected.get(), !isFiltered, TypeSortKey.summary)
        }

        rows.push(summaryRow)
    }

    const anySources = sources.length > 0

    if (isFiltered && sources.filter((source) => source.isOverLimit).length === 0) {
        const mainText = t.taxes.vat.noOverLimitInvoices.get(INVOICE_LIMIT)
        rows.push(createRow(mainText, false, TypeSortKey.note))
    }

    const language = getCurrentLanguage()

    for (const source of sources) {
        const {
            type,
            id,
            date,
            getCustomer,
            payableTotal: { withoutVat, vatAmount, withVat },
            link,
            isOverLimit,
        } = source

        if (summaryRow) {
            summaryRow.withoutVat += withoutVat
            summaryRow.vatAmount += vatAmount
            summaryRow.withVat += withVat
        }

        if (
            mode === VatTableMode.editing ||
            mode === VatTableMode.showAll ||
            (mode === VatTableMode.showOverLimit && isOverLimit)
        ) {
            const row: Row = {
                main: {
                    viewIcon: { href: link },
                    text: getCustomer(language),
                },
                checkbox: {
                    input: createCustomInput<boolean>({
                        inputType: 'boolean',
                        get: () => ids[type].has(id),
                        set: () => {}, // Handled by row.onClick
                    }),
                    inputValues: {},
                },
                date,
                showAmounts: true,
                withoutVat,
                vatAmount,
                withVat,
                sortKeys: {
                    type: TypeSortKey.regular,
                    id,
                },
            }

            if (editMode) {
                row.onClick = () =>
                    dispatch(({ taxes }) => {
                        const typeIds = taxes.vat.ids[type]

                        if (typeIds.has(id)) {
                            typeIds.delete(id)
                        } else {
                            typeIds.add(id)
                        }
                    })

                if (ids[type].has(id)) {
                    row.className = 'shaded'
                }
            }

            rows.push(row)
        }
    }

    sort(rows, [
        { getKey: (row) => row.sortKeys.type },
        { getKey: (row) => row.date, reverse: true },
        { getKey: (row) => row.sortKeys.id }, // For deterministic order
    ])

    const props: VatTableProps = {
        headers: {
            main: customerHeader,
            date: t.date.get(),
            withoutVat: t.taxes.vat.withoutVat.get(),
            vatAmount: t.taxes.vat.vatAmount.get(),
            withVat: t.taxes.vat.withVat.get(),
        },
        showCheckboxColumn: editMode && anySources,
        rows,
    }

    if (mode === VatTableMode.showAll || mode === VatTableMode.showOverLimit) {
        props.mode = {
            type: 'buttons',
            input: createCustomInput({
                inputType: 'string',
                get: () => mode,
                set: setMode,
            }),
            inputValues: {},
            options: [
                {
                    id: VatTableMode.showAll,
                    label: t.taxes.vat.showAllInvoices.get(),
                },
                {
                    id: VatTableMode.showOverLimit,
                    label: t.taxes.vat.showOverLimitInvoices.get(INVOICE_LIMIT),
                },
            ],
            forceSelection: true,
            buttonClassName: 'button--primary',
            selectedButtonClassName: 'button--primary-selected',
        }
    }

    return props
}
