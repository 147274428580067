import React from 'react'

import { hasProp } from '../../common/has-prop'
import { keys } from '../../common/keys'
import { sort } from '../../common/sort'
import { Column } from '../../common/types/table'
import { t } from '../i18n'
import { type BaseRow, renderTable } from './table'

interface Row extends BaseRow {
    id: string
    et: string
}

const columns: Column<Row>[] = [
    {
        header: { content: 'ID' },
        getProps: () => ({ style: { width: '25em' } }),
        render: (row) => row.id,
    },
    {
        header: { content: 'Eesti keel' },
        render: (row) => row.et,
    },
]

export const Texts = () => {
    const rows: Row[] = []

    const addRows = (obj: Record<string, unknown>, key: string[]) => {
        if (hasProp(obj, 'getFormat') && typeof obj.getFormat === 'function') {
            rows.push({ id: key.join('.'), et: obj.getFormat() as string })
        }

        for (const subKey of keys(obj)) {
            if (subKey !== 'get') {
                addRows(obj[subKey] as Record<string, unknown>, [...key, subKey])
            }
        }
    }

    addRows(t, [])
    sort(rows, [{ getKey: (row) => row.id, natural: true }])

    return React.createElement(
        'div',
        { className: 'content-area' },
        React.createElement(
            'div',
            { className: 'content' },
            renderTable({
                columns,
                rows,
                tableClassName: 'text-multiline main-table',
                noWrapper: true,
                stickyHeader: true,
            }),
        ),
    )
}
