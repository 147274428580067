import React, { FC } from 'react'

import { canConfirm, canRemoveAllLabourCosts } from '../../common/access'
import { assertNever } from '../../common/assert-never'
import { CompanyStatus, labourPaymentTypes, logActions } from '../../common/enums'
import { findByDbId } from '../../common/find-by-db-id'
import { invariant } from '../../common/invariant'
import {
    calculateLabourCost,
    getLabourCostMinMonth,
    isAddMode,
} from '../../common/labour-cost-utils'
import { sort } from '../../common/sort'
import { Day } from '../../common/time'
import { LabourPaymentType } from '../../common/types/enums'
import { PaymentState } from '../../common/types/expense'
import { ApiLabourCost, LabourCostViewMode } from '../../common/types/labour-cost'
import { ApiSession } from '../../common/types/session'
import { Column } from '../../common/types/table'
import { upperCaseFirst } from '../../common/upper-case-first'
import { assertViewName } from '../assert-view-name'
import { getExcelButtonProps } from '../excel-utils'
import { ExcelSpec } from '../excel/types'
import { t } from '../i18n'
import { createYearUrlInput } from '../input-utils'
import { getPercentUnpaid } from '../payment-utils'
import { getExpensePaymentProps } from '../props/expense-payment-props'
import { getLabourCostPaymentProps } from '../props/labour-cost-payment-props'
import { renderAmount, renderAmountOrDash } from '../render-amount'
import { renderExcelMoney } from '../render-excel-money'
import { wrapExcelButton } from '../standard-page-utils'
import { getCompany } from '../state/company-actions'
import {
    confirm as confirmLabourCost,
    remove,
    REMOVE_PROCESS,
    SAVE_PROCESS,
} from '../state/labour-cost-actions'
import { openExpensePaymentForm } from '../state/payment-actions'
import { RootData } from '../state/root-data'
import { browserOnly } from '../table-utils'
import { DeleteIcon } from './delete-icon'
import { ExcelButtonProps } from './excel-button'
import { LabourCostEdit } from './labour-cost-edit'
import { Link } from './link'
import { LoadingPage } from './loading-page'
import { NoData } from './no-data'
import { Payment } from './payment'
import { StandardPage } from './standard-page'
import { renderTable } from './table'
import { ViewIcon } from './view-icon'
import { YearNav } from './year-nav'

interface Row {
    className?: string
    id: string
    rev: number
    month: string
    note: string
    payroll: number
    net: number
    confirmed: boolean
    netPaid: boolean
    netPercentUnpaid: number
    taxes: number
    taxPaid: boolean
    taxPercentUnpaid: number
    canRemove: boolean
}

interface Totals {
    payroll: number
    net: number
    taxes: number
}

const getRightAligned = () => ({ className: 'text-right' })
const getAmountClass = () => ({ className: 'amount' })

const getRoutePrefix = (mode: LabourCostViewMode, id: string) => {
    switch (mode) {
        case LabourCostViewMode.list:
            return '#/labour-costs/list/'
        case LabourCostViewMode.view:
            return '#/labour-costs/view/' + id + '/'
        case LabourCostViewMode.addInit:
        case LabourCostViewMode.addSocial:
        case LabourCostViewMode.addFinal:
            return '#/labour-costs/add/'
        default:
            throw assertNever(mode, 'labour cost view mode')
    }
}

const renderPaymentLink = (
    confirmed: boolean,
    paid: boolean,
    percentUnpaid: number,
    id: string,
    type: LabourPaymentType,
) => {
    if (!confirmed) {
        return null
    }

    return React.createElement(Link, {
        text: paid ? t.expenses.paid.get() : t.expenses.percentUnpaid.get(percentUnpaid),
        onClick: async () => openExpensePaymentForm(id, type),
        className: 'payment-link',
    })
}

const renderConfirmLink = (
    confirmed: boolean,
    hasConfirmAccess: boolean,
    id: string,
    rev: number,
) => {
    if (confirmed) {
        return React.createElement('span', null, t.confirmed.get())
    } else if (hasConfirmAccess) {
        return React.createElement(Link, {
            text: t.confirm.get(),
            onClick: () =>
                confirm(t.confirm.confirmExpense.get()) ? confirmLabourCost(id, rev) : null,
            className: 'confirm-link',
        })
    } else {
        return null
    }
}

const getColumns = (hasConfirmAccess: boolean, year: number): Column<Row, Totals>[] => [
    {
        header: { content: t.month.get() },
        render: (row) => upperCaseFirst(Day.fromYm(row.month).longMonth()),
        excelWidth: 15,
        // TODO 'mmmm yyyy' in Excel?
    },
    {
        header: { content: t.explanation.get() },
        render: (row) => row.note,
        excelWidth: 25,
    },
    {
        header: { content: t.payroll.get(), getProps: getRightAligned },
        getProps: getAmountClass,
        render: (row) => ({
            browser: renderAmountOrDash(row.payroll),
            excel: renderExcelMoney(row.payroll, false),
        }),
        getTotal: (totals) => renderAmount(totals.payroll),
        getExcelTotal: (totals) => ({ type: 'sum-money', value: totals.payroll }),
        getTotalProps: getAmountClass,
        excelWidth: 16,
    },
    {
        header: { content: t.labourCosts.net.get(), getProps: getRightAligned },
        getProps: getAmountClass,
        render: (row) => ({
            browser: renderAmountOrDash(row.net),
            excel: renderExcelMoney(row.net, false),
        }),
        getTotal: (totals) => renderAmount(totals.net),
        getExcelTotal: (totals) => ({ type: 'sum-money', value: totals.net }),
        getTotalProps: getAmountClass,
        excelWidth: 16,
    },
    {
        getProps: () => ({ className: 'actions' }),
        render: browserOnly((row) =>
            renderPaymentLink(
                row.confirmed,
                row.netPaid,
                row.netPercentUnpaid,
                row.id,
                labourPaymentTypes.net,
            ),
        ),
        hideInExcel: true,
    },
    {
        header: { content: t.taxes.get(), getProps: getRightAligned },
        getProps: getAmountClass,
        render: (row) => ({
            browser: renderAmountOrDash(row.taxes),
            excel: renderExcelMoney(row.taxes, false),
        }),
        getTotal: (totals) => renderAmount(totals.taxes),
        getExcelTotal: (totals) => ({ type: 'sum-money', value: totals.taxes }),
        getTotalProps: getAmountClass,
        excelWidth: 16,
    },
    {
        getProps: () => ({ className: 'actions' }),
        render: browserOnly((row) =>
            renderPaymentLink(
                row.confirmed,
                row.taxPaid,
                row.taxPercentUnpaid,
                row.id,
                labourPaymentTypes.tax,
            ),
        ),
        hideInExcel: true,
    },
    {
        header: {
            content: t.actions.get(),
            getProps: () => ({ className: 'text-center' }),
        },
        getProps: () => ({ className: 'actions' }),
        render: browserOnly((row) =>
            React.createElement(
                'span',
                null,
                React.createElement(ViewIcon, {
                    href: '#/labour-costs/view/' + row.id + '/' + year,
                }),
                renderConfirmLink(row.confirmed, hasConfirmAccess, row.id, row.rev),
            ),
        ),
        hideInExcel: true,
    },
    {
        getProps: () => ({ className: 'actions' }),
        render: browserOnly((row) => {
            if (row.canRemove) {
                const onClick = async () => {
                    const message = row.confirmed
                        ? t.confirm.removeConfirmedLabourCost.get()
                        : t.confirm.removeLabourCost.get()

                    // TODO custom confirmation UI
                    if (confirm(message)) {
                        await remove(row.id, '#/labour-costs/list/' + year)
                    }
                }

                return React.createElement(DeleteIcon, { onClick })
            } else {
                return null
            }
        }),
        hideInExcel: true,
    },
]

const canRemove = (
    labourCost: ApiLabourCost,
    session: ApiSession,
    companyStatus: CompanyStatus,
) => {
    const canRemoveAll = canRemoveAllLabourCosts(session.companyRole, companyStatus)

    if (canRemoveAll) {
        return true
    }

    if (labourCost.confirmed) {
        return false
    }

    const [firstEntry] = labourCost.log
    invariant(firstEntry.action === logActions.create)
    return firstEntry.userId === session.userId
}

const getRows = (
    labourCosts: ApiLabourCost[],
    totals: Totals,
    session: ApiSession,
    companyStatus: CompanyStatus,
    paymentState: PaymentState | null,
) =>
    labourCosts.map((labourCost): Row => {
        const {
            _id: id,
            rev,
            month,
            note,
            confirmed,
            netPaid,
            netPayments,
            taxPaid,
            taxPayments,
        } = labourCost

        const isActivePayment =
            paymentState && paymentState.type in labourPaymentTypes && paymentState.id === id

        const { payroll, net, taxes } = calculateLabourCost(labourCost)
        totals.payroll += payroll
        totals.net += net
        totals.taxes += taxes

        return {
            className: isActivePayment ? 'shaded' : undefined,
            id,
            rev,
            month,
            note,
            payroll,
            net,
            confirmed,
            netPaid,
            netPercentUnpaid: getPercentUnpaid(netPaid, netPayments, net),
            taxes,
            taxPaid,
            taxPercentUnpaid: getPercentUnpaid(taxPaid, taxPayments, taxes),
            canRemove: canRemove(labourCost, session, companyStatus),
        }
    })

const renderSidebar = (rootData: RootData, year: number) => {
    const {
        companyData,
        expenseData,
        inputValues,
        labourCostData: { labourCosts, mode, id },
        processes,
        session,
        validationErrors,
    } = rootData

    const listRoute = '#/labour-costs/list/' + year

    if (mode === LabourCostViewMode.list) {
        if (expenseData.paymentState) {
            const { type: paymentType, id: paymentExpenseId } = expenseData.paymentState

            if (paymentType === 'expense') {
                return React.createElement(
                    Payment,
                    getExpensePaymentProps(rootData, paymentExpenseId),
                )
            } else {
                return React.createElement(
                    Payment,
                    getLabourCostPaymentProps(rootData, paymentType, paymentExpenseId),
                )
            }
        } else {
            return null
        }
    } else if (mode === LabourCostViewMode.view) {
        const existing = findByDbId(labourCosts!, id)
        return React.createElement(LabourCostEdit, {
            mode,
            listRoute,
            existing,
            inputValues,
            processes,
            valErrors: undefined,
        })
    } else {
        const valErrors = validationErrors[SAVE_PROCESS]
        const { interimDate } = getCompany(companyData, session)
        return React.createElement(LabourCostEdit, {
            mode,
            listRoute,
            inputValues,
            processes,
            valErrors,
            interimDate,
        })
    }
}

const renderAddNewButton = (visible: boolean, year: number) =>
    visible
        ? React.createElement(
              'a',
              { className: 'button button--primary', href: '#/labour-costs/add/' + year },
              t.addNew.get(),
          )
        : undefined

export const LabourCostList: FC<RootData> = (rootData) => {
    const {
        companyData,
        expenseData: { paymentState },
        labourCostData: { labourCosts, mode, id },
        processes,
        progress,
        session,
        view,
    } = rootData

    if (!labourCosts || !companyData.companies || processes.has(REMOVE_PROCESS)) {
        return React.createElement(LoadingPage)
    }

    if (mode === LabourCostViewMode.list && !labourCosts.length) {
        return React.createElement(NoData, {
            addRoute: '#/labour-costs/add',
            addButtonText: t.labourCosts.addNew.get(),
        })
    }

    const { year } = assertViewName(view, 'LabourCostList')

    const company = getCompany(companyData, session)
    const shownYear = year
    const yearInput = createYearUrlInput(getRoutePrefix(mode, id), shownYear)

    const filteredLabourCosts = labourCosts.filter((labourCost) => {
        const costYear = Number(labourCost.month.substring(0, 4))
        return costYear === shownYear
    })

    const hasConfirmAccess = canConfirm(session!.companyRole, company.status)
    const columns = getColumns(hasConfirmAccess, year)
    const totals = { payroll: 0, net: 0, taxes: 0 }
    const rows = getRows(filteredLabourCosts, totals, session!, company.status, paymentState)

    sort(rows, [
        { getKey: (labourCost) => labourCost.month, reverse: true },
        { getKey: (labourCost) => labourCost.payroll, reverse: true },
    ])

    const title = t.labourCosts.get()
    const subtitle = shownYear

    let excelButton: ExcelButtonProps | undefined

    if (rows.length) {
        const excelSpec: ExcelSpec<Row, Totals> = {
            columns,
            rows,
            totals,
            outputName: title + ' ' + subtitle,
        }

        excelButton = getExcelButtonProps(
            excelSpec,
            t.expenses.processed.get(),
            processes,
            progress,
            'button button--wide button--primary',
        )
    }

    return React.createElement(StandardPage, {
        className: 'labour-cost-list',
        sidebar: renderSidebar(rootData, year),
        title,
        subtitle,
        buttons: [
            React.createElement(YearNav, {
                year,
                input: yearInput,
                minYear: getLabourCostMinMonth(Day.fromYmd(company.interimDate)).year(),
                maxYear: Day.today().year(),
            }),
            excelButton && wrapExcelButton(excelButton),
        ],
        table: React.createElement(
            'div',
            null,
            renderTable({
                columns,
                rows,
                totals,
                stickyHeader: true,
                tableClassName: 'main-table',
                wrapperClassName: 'main-table-wrapper',
            }),
            React.createElement(
                'div',
                { className: 'top-margin' },
                // TODO show all button?
                renderAddNewButton(!isAddMode(mode), year),
            ),
        ),
    })
}
