import { amountEquals } from './amount-equals'
import { roundToNearestCent } from './amount-utils'
import { labourPaymentTypes, logActions } from './enums'
import { Day } from './time'
import { LabourPaymentType } from './types/enums'
import {
    ApiLabourCost,
    LabourCostCalc,
    LabourCostInput,
    LabourCostViewMode,
} from './types/labour-cost'
import { DbPayment } from './types/payment'

interface LabourTaxRates {
    socialTaxRate: number
    unemploymentEmployerRate: number
    unemploymentEmployeeRate: number
    defaultPensionRate: number
    incomeTaxRate: number
    maxTaxFree: number
    minSocialTax: number
}

export const getLabourCostMinMonth = (interimDate: Day): Day => {
    return Day.max(interimDate, Day.fromYm('2018-01'))
}

export const getLabourTaxRates = (monthYm: string): LabourTaxRates => {
    const rates: LabourTaxRates = {
        // 2024 rates
        socialTaxRate: 0.33,
        unemploymentEmployerRate: 0.008,
        unemploymentEmployeeRate: 0.016,
        defaultPensionRate: 0.02,
        incomeTaxRate: 0.2,
        maxTaxFree: 654,
        minSocialTax: 239.25, // Gross 725
    }

    const year = Day.fromYm(monthYm).year()

    if (year <= 2023) {
        rates.minSocialTax = 215.82 // Gross 654
    }

    if (year <= 2022) {
        rates.maxTaxFree = 500
    }

    if (year === 2021 || year === 2022) {
        rates.minSocialTax = 192.72 // Gross 584
    }
    if (year === 2020) {
        rates.minSocialTax = 178.2 // Gross 540
    } else if (year === 2019) {
        rates.minSocialTax = 165 // Gross 500
    } else if (year === 2018) {
        rates.minSocialTax = 155.1 // Gross 470
    } else if (year < 2018) {
        throw new Error('No labour tax rates available before 2018.')
    }

    return rates
}

export const isAddMode = (mode: LabourCostViewMode) =>
    mode === LabourCostViewMode.addInit ||
    mode === LabourCostViewMode.addSocial ||
    mode === LabourCostViewMode.addFinal

export const getPayments = (labourCost: ApiLabourCost, type: LabourPaymentType): DbPayment[] => {
    if (type === labourPaymentTypes.net) {
        return labourCost.netPayments
    } else if (type === labourPaymentTypes.tax) {
        return labourCost.taxPayments
    } else {
        throw new Error('Unexpected labour payment type: ' + type)
    }
}

export const getPayable = (calc: LabourCostCalc, type: LabourPaymentType): number => {
    if (type === labourPaymentTypes.net) {
        return calc.net
    } else if (type === labourPaymentTypes.tax) {
        return calc.taxes
    } else {
        throw new Error('Unexpected labour payment type: ' + type)
    }
}

export const getLogAction = (type: LabourPaymentType) => {
    if (type === labourPaymentTypes.net) {
        return logActions.paymentNet
    } else if (type === labourPaymentTypes.tax) {
        return logActions.paymentTax
    } else {
        throw new Error('Unexpected labour payment type: ' + type)
    }
}

export const calculateLabourCostFromValues = (
    rates: LabourTaxRates,
    gross: number,
    customTaxFree: number | null,
    calculatePension: boolean,
    increaseSocialTax: boolean,
): LabourCostCalc => {
    const {
        socialTaxRate,
        unemploymentEmployerRate,
        unemploymentEmployeeRate,
        defaultPensionRate,
        incomeTaxRate,
        maxTaxFree,
        minSocialTax,
    } = rates

    const socialTax = roundToNearestCent(gross * socialTaxRate)
    let socialTaxIncrease = 0

    if (increaseSocialTax && socialTax < minSocialTax) {
        socialTaxIncrease = minSocialTax - socialTax
    }

    const unemploymentEmployer = roundToNearestCent(gross * unemploymentEmployerRate)
    const unemploymentEmployee = roundToNearestCent(gross * unemploymentEmployeeRate)
    const pensionRate = calculatePension ? defaultPensionRate : 0
    const pensionPayment = roundToNearestCent(gross * pensionRate)
    const beforeIncome = gross - pensionPayment - unemploymentEmployee

    const minTaxFree = 0
    const maxTaxFreeSum = Math.min(maxTaxFree, beforeIncome)
    const initialTaxFree = ((2100 - gross) * maxTaxFree) / 900
    const expectedTaxFree = Math.max(minTaxFree, Math.min(initialTaxFree, maxTaxFreeSum))

    // TODO convert old data (ensure customTaxFree <= maxTaxFree), remove Math.min
    const taxFree =
        typeof customTaxFree === 'number' ? Math.min(customTaxFree, maxTaxFreeSum) : expectedTaxFree

    if (taxFree > beforeIncome) {
        throw new Error('Tax free amount is larger than taxable amount')
    }

    const incomeTax = roundToNearestCent((beforeIncome - taxFree) * incomeTaxRate)
    const net = beforeIncome - incomeTax

    const payroll = gross + socialTax + socialTaxIncrease + unemploymentEmployer
    const taxes = payroll - net

    return {
        gross: roundToNearestCent(gross),
        net: roundToNearestCent(net),
        payroll: roundToNearestCent(payroll),
        socialTax,
        socialTaxIncrease,
        unemploymentEmployer,
        pensionPayment,
        unemploymentEmployee,
        incomeTax,
        expectedTaxFree: roundToNearestCent(expectedTaxFree),
        actualTaxFree: roundToNearestCent(taxFree),
        taxes: roundToNearestCent(taxes),
    }
}

export const calculateLabourCost = (labourCost: LabourCostInput) => {
    const { month, gross, taxFree, calculatePension, increaseSocialTax } = labourCost
    const rates = getLabourTaxRates(month)

    return calculateLabourCostFromValues(
        rates,
        gross,
        taxFree,
        calculatePension,
        Boolean(increaseSocialTax),
    )
}

export const calculateFromNet = (
    rates: LabourTaxRates,
    net: number,
    customTaxFree: number | null,
    calculatePension: boolean,
    increaseSocialTax: boolean,
) => {
    const { unemploymentEmployeeRate, defaultPensionRate, incomeTaxRate, maxTaxFree } = rates

    const pensionRate = calculatePension ? defaultPensionRate : 0

    /*
     * When starting from the net amount (neto),
     * we need to use it to calculate the gross amount (bruto).
     *
     * The formula for calculating net from gross is:
     * net = gross - pensionPayment - unemploymentEmployee - incomeTax
     *
     * If we ignore rounding, (gross - pensionPayment - unemploymentEmployee) can be expressed as
     * gross * (1 - pensionRate - unemploymentEmployeeRate).
     *
     * Let's define p so this becomes (gross * p)
     */
    const p = 1 - pensionRate - unemploymentEmployeeRate

    /*
     * The formula is now: net = gross * p - incomeTax
     * Where incomeTax = (gross * p - taxFree) * incomeTaxRate
     *
     * So we can write the formula as:
     * net = gross * p - (gross * p - taxFree) * incomeTaxRate
     *     = gross * p - gross * p * incomeTaxRate + taxFree * incomeTaxRate
     *     = gross * p * (1 - incomeTaxRate) + taxFree * incomeTaxRate
     *
     * To make the following formulas simpler, let's define q:
     */
    const q = p * (1 - incomeTaxRate)

    /*
     * The formula is now:
     *    net       = gross * q + taxFree * incomeTaxRate
     * -> gross * q =  net - taxFree * incomeTaxRate
     * -> gross     = (net - taxFree * incomeTaxRate) / q
     *
     * Next we need to determine taxFree from net.
     * Ignoring the minimum (0) and maximum (maxTaxFree) bounds, the base formula is:
     * taxFree = (2100 - gross) * maxTaxFree / 900
     *
     * To avoid repeating maxTaxFree / 900, let's define it as r:
     */
    const r = maxTaxFree / 900

    /*
     * To recap:
     * taxFree = (2100 - gross) * r
     *         = 2100 * r - gross * r
     * gross = (net - taxFree * INCOME_TAX_RATE) / q
     *
     * By combining these, we get:
     * taxFree = 2100 * r - (net - taxFree * INCOME_TAX_RATE) * r / q
     *
     * Let's multiply both sides with q and divide them by r:
     *    taxFree * q / r                           =  2100 * q - net + taxFree * incomeTaxRate
     * -> taxFree * q / r - taxFree * incomeTaxRate =  2100 * q - net
     * -> taxFree * (q / r - incomeTaxRate)         =  2100 * q - net
     * -> taxFree                                   = (2100 * q - net) / (q / r - incomeTaxRate)
     */
    const initialTaxFree = (2100 * q - net) / (q / r - incomeTaxRate)

    // Now we can apply the min and max bounds to it:
    const expectedTaxFree = Math.max(0, Math.min(initialTaxFree, maxTaxFree))
    const taxFree = typeof customTaxFree === 'number' ? customTaxFree : expectedTaxFree

    // In case the net amount is smaller than the allowed tax free amount:
    const effectiveTaxFree = Math.min(net, taxFree)

    // From above: gross = (net - taxFree * INCOME_TAX_RATE) / q
    const gross = roundToNearestCent((net - effectiveTaxFree * incomeTaxRate) / q)

    const calc = calculateLabourCostFromValues(
        rates,
        gross,
        customTaxFree,
        calculatePension,
        increaseSocialTax,
    )

    // Attempt to fix small discrepancies by tweaking the gross amount
    if (amountEquals(calc.net, net - 0.01)) {
        return calculateLabourCostFromValues(
            rates,
            gross + 0.01,
            customTaxFree,
            calculatePension,
            increaseSocialTax,
        )
    } else if (amountEquals(calc.net, net + 0.01)) {
        return calculateLabourCostFromValues(
            rates,
            gross - 0.01,
            customTaxFree,
            calculatePension,
            increaseSocialTax,
        )
    } else {
        // No difference greater than 0.01 has been observed, so this is likely accurate.
        // TODO send Slack notification if not accurate?
        return calc
    }
}
