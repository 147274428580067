import { calculateRevenue } from '../../common/invoice-utils'
import { getPaidAmount } from '../../common/payment-utils'
import { Day } from '../../common/time'
import { ValidationErrors } from '../../common/types/errors'
import { InputValues } from '../../common/types/inputs'
import { ApiRevenue, CreditRevenue } from '../../common/types/invoice'
import { Processes } from '../../common/types/processes'
import { PaymentProps } from '../components/payment'
import { t } from '../i18n'
import { REMOVE_PAYMENT_PROCESS, SAVE_PAYMENT_PROCESS } from '../state/payment-actions'
import { addCreditPayment, closePaymentForm } from '../state/revenue-actions'
import { getPaymentProps, RawPaymentProps } from './payment-props'

export const getCreditRevenuePaymentProps = (
    originalRevenue: ApiRevenue,
    creditRevenue: CreditRevenue,
    inputValues: InputValues,
    processes: Processes,
    validationErrors: ValidationErrors,
): PaymentProps => {
    const totals = calculateRevenue(originalRevenue)

    const rawProps: RawPaymentProps = {
        labels: {
            main: t.payments.creditRevenue.get(),
            amountSection: t.totals.creditRevenue.get(),
            isFull: t.payments.creditRevenue.isFull.get(),
            amount: t.payments.creditRevenue.amount.get(),
        },
        incoming: false,
        minDate: Day.fromYmd(creditRevenue.date),
        payments: creditRevenue.payments,
        totals,
        totalToPay: getPaidAmount(originalRevenue.payments),
        inputValues,
        onSave: async () => addCreditPayment(creditRevenue._id),
        // TODO: onRemove
        onClose: closePaymentForm,
        processes,
        saveProcessName: SAVE_PAYMENT_PROCESS,
        removeProcessName: REMOVE_PAYMENT_PROCESS,
        valErrors: validationErrors[SAVE_PAYMENT_PROCESS],
        isCredit: true,
    }

    if (!originalRevenue.payments.length) {
        rawProps.disabledText = t.payments.noNeed.get()
    } else if (creditRevenue.paid) {
        rawProps.disabledText = t.payments.fullyPaid.outgoing.get()
    }

    return getPaymentProps(rawProps)
}
