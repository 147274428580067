import React, { FC, Fragment } from 'react'

import { Day } from '../../../common/time'
import { Column } from '../../../common/types/table'
import { VatTableMode } from '../../../common/types/taxes'
import { formatAmount } from '../../format-amount'
import { browserOnly } from '../../table-utils'
import { Checkbox, CheckboxProps } from '../checkbox'
import { ChoiceProps, renderChoice } from '../choice'
import { ExpandToggle, ExpandToggleProps } from '../expand-toggle'
import { BaseRow, renderTable } from '../table'
import { ViewIcon, ViewIconProps } from '../view-icon'

export interface Row extends BaseRow {
    main: {
        toggleExpanded?: () => void
        expandToggle?: ExpandToggleProps
        viewIcon?: ViewIconProps
        text: string
    }
    checkbox?: CheckboxProps
    date: string
    showAmounts: boolean
    withoutVat: number
    vatAmount: number
    withVat: number
    sortKeys: {
        type: number
        id: string
    }
}

export interface VatTableProps {
    headers: {
        main: string
        date: string
        withoutVat: string
        vatAmount: string
        withVat: string
    }
    showCheckboxColumn: boolean
    rows: Row[]
    mode?: ChoiceProps<VatTableMode.showAll | VatTableMode.showOverLimit>
}

const getRightAligned = () => ({ className: 'text-right' })

const getColumns = ({ headers, showCheckboxColumn }: VatTableProps): Column<Row>[] => {
    const columns: Column<Row>[] = []

    if (showCheckboxColumn) {
        columns.push({
            getProps: () => ({ className: 'taxes__checkbox-cell' }),
            render: browserOnly(
                (row) => row.checkbox && React.createElement(Checkbox, row.checkbox),
            ),
        })
    }

    columns.push({
        header: { content: headers.main },
        getProps: (row) => {
            if (row.main.toggleExpanded) {
                return { onClick: row.main.toggleExpanded, className: 'clickable' }
            } else {
                return null
            }
        },
        render: browserOnly((row) => {
            const { expandToggle, viewIcon, text } = row.main

            return React.createElement(
                Fragment,
                null,
                expandToggle && React.createElement(ExpandToggle, expandToggle),
                viewIcon && React.createElement(ViewIcon, viewIcon),
                (expandToggle || viewIcon) && ' ',
                text,
            )
        }),
    })

    columns.push(
        {
            header: { content: headers.date, getProps: getRightAligned },
            getProps: getRightAligned,
            render: (row) => (row.date ? Day.fromYmd(row.date).dmy() : ''),
        },
        {
            header: { content: headers.withoutVat, getProps: getRightAligned },
            getProps: getRightAligned,
            render: (row) => (row.showAmounts ? formatAmount(row.withoutVat) : ''),
        },
        {
            header: { content: headers.vatAmount, getProps: getRightAligned },
            getProps: getRightAligned,
            render: (row) => (row.showAmounts ? formatAmount(row.vatAmount) : ''),
        },
        {
            header: { content: headers.withVat, getProps: getRightAligned },
            getProps: getRightAligned,
            render: (row) => (row.showAmounts ? formatAmount(row.withVat) : ''),
        },
    )

    return columns
}

export const renderVatTable: FC<VatTableProps> = (props) => {
    const { rows, mode } = props

    return React.createElement(
        Fragment,
        null,
        renderTable({
            columns: getColumns(props),
            rows,
            tableClassName: 'main-table',
            wrapperClassName: 'main-table-wrapper',
            stickyHeader: true,
        }),
        mode && renderChoice(mode),
    )
}
