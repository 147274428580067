import { compare as getNaturalCompareFunction } from 'natural-orderby'

interface Options {
    clone?: boolean
}

export type GetKey<T> = (value: T) => string | number

interface SortLevel<T> {
    getKey: GetKey<T>
    reverse?: boolean
    natural?: boolean
}

export type SortOption<T> = SortLevel<T>[]

type LevelsOrFunc<T> = SortOption<T> | GetKey<T>

const naturalCompare = getNaturalCompareFunction()

const compare = <T>(elem1: T, elem2: T, levels: SortOption<T>) => {
    for (const { getKey, reverse, natural } of levels) {
        const key1 = getKey(elem1)
        const key2 = getKey(elem2)
        const direction = reverse ? -1 : 1

        if (key1 === key2) {
            continue
        } else if (natural) {
            const result = naturalCompare(key1, key2)

            if (result === 0) {
                continue
            } else {
                return result * direction
            }
        } else {
            return (key1 > key2 ? 1 : -1) * direction
        }
    }

    return 0
}

const getLevels = <T>(levelsOrFunc: LevelsOrFunc<T>): SortLevel<T>[] => {
    if (typeof levelsOrFunc === 'function') {
        return [{ getKey: levelsOrFunc }]
    } else if (Array.isArray(levelsOrFunc)) {
        return levelsOrFunc
    } else {
        throw new Error('Invalid levelsOrFunc parameter')
    }
}

// NB! Modifies and returns the input array unless options.clone = true
export const sort = <T>(inputArray: T[], levelsOrFunc: LevelsOrFunc<T>, options?: Options): T[] => {
    const { clone = false } = options || {}
    const array = clone ? [...inputArray] : inputArray
    const levels = getLevels(levelsOrFunc)
    array.sort((elem1, elem2) => compare(elem1, elem2, levels))
    return array
}
