import lodashFind from 'lodash/find'

import { findById } from '../../common/find-by-id'
import { AccountData, AccountInputs, AccountType, ApiAccount } from '../../common/types/account'
import { InputValues } from '../../common/types/inputs'
import * as api from '../api'
import { setInvalid } from './invalid-cache-actions'
import { loadAccounts } from './load-actions'

export const invalidateCache = () => setInvalid('account')

export const getById = (data: AccountData, id: string): ApiAccount | undefined => {
    if (!data.accounts) {
        throw new Error('Accounts not loaded')
    }

    return findById(data.accounts, id)
}

export const getByNumber = (
    data: AccountData,
    type: AccountType,
    number: number,
): ApiAccount | undefined => {
    if (!data.accounts) {
        throw new Error('Accounts not loaded')
    }

    return lodashFind(
        data.accounts,
        (account) => account.type === type && account.number === number,
    )
}

export const addNew = async (
    accountInputs: AccountInputs,
    accountType: AccountType,
    inputValues: InputValues,
) => {
    const savingInput = accountInputs.saving
    const textInput = accountInputs.text

    savingInput.set(true)
    const accountName = textInput.get(inputValues)
    const { number } = await api.createAccount(accountType, accountName)
    invalidateCache()
    await loadAccounts()
    accountInputs.number.set(number)
    accountInputs.opened.set(false)
    textInput.set('')
    savingInput.set(false)
    return number
}
