import React, { ReactNode } from 'react'

export const renderJson = (value: any, key: string = 'root'): ReactNode[] => {
    if (Array.isArray(value)) {
        return [
            React.createElement('span', { key: key + '.type', className: 'json-literal' }, 'arr'),
            React.createElement(
                'div',
                { key: key + '.elems', className: 'indented' },
                // Flatten into one array using reduce
                ...value.reduce(arrayReducer, []),
            ),
        ]
    } else if (value && typeof value === 'object') {
        const obj = value as Record<string, unknown>

        const lines = Object.keys(value).map((propKey) => {
            const shownKey = propKey === '' ? "''" : propKey

            return React.createElement(
                'div',
                { key: propKey },
                React.createElement('span', { className: 'json-attr' }, shownKey),
                ' ',
                ...renderJson(obj[propKey], propKey),
            )
        })

        return [
            React.createElement('span', { key: key + '.type', className: 'json-literal' }, 'obj'),
            React.createElement('div', { key: key + '.props', className: 'indented' }, ...lines),
        ]
    } else if (typeof value === 'string') {
        if (value === '') {
            return [React.createElement('span', { key, className: 'json-literal' }, "''")]
        } else {
            return [React.createElement('span', { key, className: 'json-string' }, value)]
        }
    } else if (
        typeof value === 'number' ||
        typeof value === 'boolean' ||
        typeof value === 'undefined' ||
        value === null
    ) {
        return [React.createElement('span', { key, className: 'json-literal' }, String(value))]
    } else {
        throw new Error('Unexpected value: ' + String(value))
    }
}

const arrayReducer = (previous: any[], current: any, index: number): any[] => {
    const key = String(index)
    return previous.concat(React.createElement('div', { key }, renderJson(current, key)))
}
