import { Language } from '../common/i18n'
import { getCustomerName as getCustomerNameInLanguage } from '../common/invoice-utils'
import { ApiCompany } from '../common/types/company'
import { ApiCustomer, ApiRevenue } from '../common/types/invoice'
import { getCurrentLanguage, t } from './i18n'

// Some calls are passing a second parameter that is currently ignored.
// Adding an actual second param may result in unexpected changes.
export const getCustomerName = (customer: ApiCustomer) => {
    return getCustomerNameInLanguage(customer, getCurrentLanguage())
}

export const getRevenuePdfFilename = (
    company: ApiCompany,
    revenue: ApiRevenue,
    language: Language,
) => {
    const number = revenue.confirmed && revenue.number ? ' ' + revenue.number : ''
    return company.name + ' ' + t.invoice.getForLanguage(language).toLowerCase() + number + '.pdf'
}

export const getCreditRevenuePdfFilename = (
    company: ApiCompany,
    number: string,
    language: Language,
) =>
    company.name +
    ' ' +
    t.revenues.creditRevenue.getForLanguage(language).toLowerCase() +
    ' ' +
    number +
    '.pdf'
