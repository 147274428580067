import React, { FC } from 'react'

import { Day } from '../../../common/time'
import { t } from '../../i18n'
import { InputOrValue, InputOrValueProps } from '../../input-utils'
import { renderValidationErrors } from '../../val-err'
import { DateInput, DateInputProps } from '../date-input'
import { ValidationErrorProps } from '../validation-error'

export interface RevenueGeneralProps {
    number: string
    dateInput?: DateInputProps
    date: string
    dateErrors?: ValidationErrorProps[]
    term: InputOrValueProps
    termErrors?: ValidationErrorProps[]
    dueDate?: Day
}

export const RevenueGeneral: FC<RevenueGeneralProps> = (general) =>
    React.createElement(
        'table',
        { className: 'table' },
        React.createElement(
            'tbody',
            null,
            React.createElement(
                'tr',
                null,
                React.createElement(
                    'td',
                    { className: 'table__body-cell table__body-cell--no-left-pad' },
                    t.invoices.number.get(),
                ),
                React.createElement('td', { className: 'numeric' }, general.number),
            ),
            React.createElement(
                'tr',
                null,
                React.createElement(
                    'td',
                    { className: 'table__body-cell table__body-cell--no-left-pad' },
                    t.invoices.date.get(),
                ),
                React.createElement(
                    'td',
                    null,
                    general.dateInput
                        ? React.createElement(DateInput, {
                              ...general.dateInput,
                              className: 'date-button invoice__date-button',
                          })
                        : Day.fromYmd(general.date).longDate(),
                    renderValidationErrors(general.dateErrors),
                ),
            ),
            React.createElement(
                'tr',
                null,
                React.createElement(
                    'td',
                    { className: 'table__body-cell table__body-cell--no-left-pad' },
                    t.term.get(),
                ),
                React.createElement(
                    'td',
                    null,
                    React.createElement(InputOrValue, general.term),
                    ' ',
                    t.days.get(),
                    renderValidationErrors(general.termErrors),
                ),
            ),
            React.createElement(
                'tr',
                null,
                React.createElement(
                    'td',
                    { className: 'table__body-cell table__body-cell--no-left-pad' },
                    t.dueDate.get(),
                ),
                React.createElement(
                    'td',
                    { className: 'numeric' },
                    general.dueDate ? general.dueDate.longDate() : '-',
                ),
            ),
        ),
    )
