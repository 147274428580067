import React, { FC, Fragment } from 'react'

import { Day } from '../../common/time'
import { Input } from '../../common/types/inputs'
import { t } from '../i18n'
import { ArrowIcon } from './arrow-icon'
import { Button, ButtonProps } from './button'

interface Props {
    year: number
    input: Input<number>
    getDefault?: () => number
    defaultLabel?: string
    minYear?: number
    maxYear?: number
    afterChange?: (newYear: number) => void
}

const getArrow = (modifierClass: string) => React.createElement(ArrowIcon, { modifierClass })

const setYear = ({ input, afterChange }: Props, newYear: number) => {
    input.set(newYear)

    if (afterChange) {
        afterChange(newYear)
    }
}

const renderPreviousButton = (props: Props) => {
    const { year, minYear } = props

    const buttonProps: ButtonProps = {
        text: getArrow('arrow--left'),
        className: 'button--primary',
    }

    const prevYear = year - 1

    if (minYear && prevYear < minYear) {
        buttonProps.disabled = true
    } else {
        buttonProps.onClick = () => setYear(props, props.year - 1)
    }

    return React.createElement(Button, buttonProps)
}

const renderNextButton = (props: Props) => {
    const { year, maxYear } = props

    const buttonProps: ButtonProps = {
        text: getArrow('arrow--right'),
        className: 'button--primary',
    }

    const nextYear = year + 1

    if (maxYear && nextYear > maxYear) {
        buttonProps.disabled = true
    } else {
        buttonProps.onClick = () => setYear(props, nextYear)
    }

    return React.createElement(Button, buttonProps)
}

const renderDefaultButton = (props: Props) => {
    const { getDefault = () => Day.today().year(), defaultLabel = t.showCurrentYear.get() } = props

    return React.createElement(Button, {
        onClick: () => setYear(props, getDefault()),
        text: defaultLabel,
        className: 'button--primary button--wide top-margin',
    })
}

const YearNav: FC<Props> = (props) => {
    const { year } = props

    return React.createElement(
        Fragment,
        null,
        React.createElement(
            'div',
            { className: 'year-navigation' },
            renderPreviousButton(props),
            year,
            renderNextButton(props),
        ),
        renderDefaultButton(props),
    )
}

export { YearNav }
