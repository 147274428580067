import { amountEquals } from '../../common/amount-equals'
import { Day } from '../../common/time'
import { ApiExpense } from '../../common/types/expense'
import { ApiRevenue, CreditRevenue } from '../../common/types/invoice'
import { VatTableMode } from '../../common/types/taxes'
import { getExpenseSources, getRevenueSources, VatCalc } from '../../common/vat-utils'
import {
    DeclarationRow,
    TaxesVatMainProps,
    VatPaymentProps,
    VatSectionProps,
} from '../components/taxes/vat-main'
import { formatAmount } from '../format-amount'
import { t } from '../i18n'
import { createMonthUrlInput } from '../input-utils'
import { RootData } from '../state/root-data'
import {
    ASSIGN_EXPENSES_PROCESS,
    ASSIGN_REVENUES_PROCESS,
    assignVatMonthToExpenses,
    assignVatMonthToRevenues,
    cancelExpenseSelection,
    cancelRevenueSelection,
    clearVatState,
    editExpenseSelection,
    editRevenueSelection,
    hideDeclarationInfo,
    openVatPayment,
    setExpensesMode,
    setRevenuesMode,
    showDeclarationInfo,
} from '../state/tax-actions'
import { getVatTableProps } from './taxes-vat-table-props'

const getRevenueSection = (
    rootData: RootData,
    selectableRevenues: ApiRevenue[] | null,
    selectableCreditRevenues: CreditRevenue[] | null,
    getCalc: () => VatCalc | null,
    month: Day,
    paid: boolean,
): VatSectionProps => {
    const {
        processes,
        taxes: {
            vat: { revenuesMode, ids },
        },
        invoiceData,
    } = rootData

    const editMode = revenuesMode === VatTableMode.editing

    const section: VatSectionProps = {
        title: t.incomes.get(),
        table: getVatTableProps(
            revenuesMode,
            ids,
            () =>
                getRevenueSources(
                    selectableRevenues!,
                    selectableCreditRevenues!,
                    invoiceData.invoices!,
                ),
            getCalc,
            (calc) => calc.revenueSources,
            month,
            t.taxes.vat.salesInvoices.get(),
            setRevenuesMode,
        ),
    }

    const monthYm = month.ym()

    if (editMode) {
        section.editButtons = [
            {
                onClick: async () => assignVatMonthToRevenues(monthYm),
                text: t.taxes.vat.saveInvoiceSelection.get(),
                processes,
                processName: ASSIGN_REVENUES_PROCESS,
                className: 'button--primary',
            },
            {
                onClick: cancelRevenueSelection,
                text: t.cancel.get(),
                className: 'button--secondary',
            },
        ]
    } else if (!paid) {
        section.selectButton = {
            onClick: () => editRevenueSelection(monthYm),
            text: t.taxes.vat.selectInvoices.get(),
        }
    }

    return section
}

const getExpenseSection = (
    rootData: RootData,
    selectableExpenses: ApiExpense[] | null,
    getCalc: () => VatCalc | null,
    month: Day,
    paid: boolean,
): VatSectionProps => {
    const {
        processes,
        taxes: {
            vat: { expensesMode, ids },
        },
    } = rootData

    const expensesEditMode = expensesMode === VatTableMode.editing

    const expenseSection: VatSectionProps = {
        title: t.expenses.get(),
        table: getVatTableProps(
            expensesMode,
            ids,
            () => getExpenseSources(selectableExpenses!),
            getCalc,
            (calc) => calc.expenseSources,
            month,
            t.taxes.vat.purchaseInvoices.get(),
            setExpensesMode,
        ),
    }

    const monthYm = month.ym()

    if (expensesEditMode) {
        expenseSection.editButtons = [
            {
                onClick: async () => assignVatMonthToExpenses(monthYm),
                text: t.taxes.vat.saveInvoiceSelection.get(),
                processes,
                processName: ASSIGN_EXPENSES_PROCESS,
                className: 'button--primary',
            },
            {
                onClick: cancelExpenseSelection,
                text: t.cancel.get(),
                className: 'button--secondary',
            },
        ]
    } else if (!paid) {
        expenseSection.selectButton = {
            onClick: () => editExpenseSelection(monthYm),
            text: t.taxes.vat.selectInvoices.get(),
        }
    }

    return expenseSection
}

const getPrepaidText = (prepaid: number | null) => {
    if (prepaid === null) {
        return t.taxes.vat.prepaidUnavailable.get()
    } else {
        return t.taxes.vat.prepaidAmount.get(Day.today().dmy(), formatAmount(prepaid))
    }
}

const getPayment = (
    getCalc: () => VatCalc | null,
    paymentDate: string,
    interest: number,
): VatPaymentProps => {
    const { differenceMta } = getCalc()!

    if (amountEquals(differenceMta, 0)) {
        return {
            title: t.taxes.vat.toBePaid.get(),
            amount: 0,
        }
    }

    if (differenceMta > 0) {
        const props: VatPaymentProps = {
            title: t.taxes.vat.toBePaid.get(),
            amount: differenceMta + interest,
        }

        if (paymentDate) {
            const formattedDate = Day.fromYmd(paymentDate).longDate()
            props.paidText = t.taxes.vat.paid.get(formattedDate).toUpperCase()
        } else {
            props.paymentLink = {
                text: t.expenses.unpaid.get(),
                onClick: openVatPayment,
            }
        }

        return props
    } else {
        const props: VatPaymentProps = {
            title: t.taxes.vat.overpaid.get(),
            amount: -differenceMta,
        }

        if (paymentDate) {
            const formattedDate = Day.fromYmd(paymentDate).longDate()
            props.paidText = t.taxes.vat.transferredToPrepaid.get(formattedDate).toUpperCase()
        } else {
            props.paymentLink = {
                text: t.taxes.vat.transferToPrepaid.get(),
                onClick: openVatPayment,
            }
        }

        return props
    }
}

export const getTaxesVatMainProps = (
    rootData: RootData,
    month: Day,
    getCalc: () => VatCalc | null,
    selectableExpenses: ApiExpense[] | null,
    selectableRevenues: ApiRevenue[] | null,
    selectableCreditRevenues: CreditRevenue[] | null,
): TaxesVatMainProps | undefined => {
    const {
        taxes: {
            vat: { revenuesMode, expensesMode, declarationExpanded, paymentState },
        },
    } = rootData

    const revenuesEditMode = revenuesMode === VatTableMode.editing
    const expensesEditMode = expensesMode === VatTableMode.editing

    if (
        !paymentState ||
        (revenuesEditMode && !selectableRevenues) ||
        (expensesEditMode && !selectableExpenses)
    ) {
        return undefined
    }

    const anyViewMode = !revenuesEditMode || !expensesEditMode
    const anyEditMode = revenuesEditMode || expensesEditMode

    if (anyViewMode) {
        const calc = getCalc()

        if (!calc) {
            return undefined
        }
    }

    const firstOfPrevious = Day.today().firstOfPreviousMonth()
    const monthYm = month.ym()

    const { date: paymentDate, interest } = paymentState.paidMonths[monthYm] || {
        date: '',
        interest: 0,
    }

    const paid = paymentDate !== ''

    const monthInput = createMonthUrlInput('#/taxes/vat/', monthYm)

    const props: TaxesVatMainProps = {
        isLoading: false,
        monthNav: {
            day: month,
            input: monthInput,
            getDefault: () => firstOfPrevious,
            defaultLabel: t.taxes.vat.showPreviousMonth.get(),
            maxMonth: firstOfPrevious,
            afterChange: clearVatState,
        },
        revenueSection: getRevenueSection(
            rootData,
            selectableRevenues,
            selectableCreditRevenues,
            getCalc,
            month,
            paid,
        ),
        expenseSection: getExpenseSection(rootData, selectableExpenses, getCalc, month, paid),
        prepaidText: getPrepaidText(paymentState.prepaid),
    }

    if (!anyEditMode) {
        props.payment = getPayment(getCalc, paymentDate, interest)

        if (!declarationExpanded) {
            props.declarationInfo = {
                showHideButton: {
                    text: t.taxes.vat.showDeclarationInfo.get(),
                    onClick: showDeclarationInfo,
                    className: 'button--primary',
                },
                rows: [],
            }
        } else {
            const calc = getCalc()!

            const declarationRows: DeclarationRow[] = [
                // TODO 22
                { text: 'Rida 1', amount: calc.revenueTotals.get(20)! },
                { text: 'Rida 2', amount: calc.revenueTotals.get(9)! },
                { text: 'Rida 3', amount: calc.revenueTotals.get(0)! },
                { text: 'Rida 4', amount: calc.revenueVatMta },
                { text: 'Rida 5', amount: calc.expenseVat },
            ]

            if (calc.differenceMta >= 0) {
                declarationRows.push({
                    text: t.taxes.vat.toBePaid.get(),
                    amount: calc.differenceMta,
                })
            } else {
                declarationRows.push({
                    text: t.taxes.vat.overpaid.get(),
                    amount: -calc.differenceMta,
                })
            }

            props.declarationInfo = {
                showHideButton: {
                    text: t.taxes.vat.hideDeclarationInfo.get(),
                    onClick: hideDeclarationInfo,
                    className: 'button--primary bottom-margin',
                },
                rows: declarationRows,
            }
        }
    }

    return props
}
