import React, { FC } from 'react'

import { assertViewName } from '../assert-view-name'
import { t } from '../i18n'
import { getCompany } from '../state/company-actions'
import { RootData } from '../state/root-data'
import { Link } from './link'
import { BillingSettings } from './settings/billing'
import { CardUpdateStatus } from './settings/card-update-status'
import { CloseCompanyAccount } from './settings/close-account'
import { GeneralSettings } from './settings/general'
import { LogoEdit } from './settings/logo-edit'
import { LogoView } from './settings/logo-view'
import { RevenueSettings } from './settings/revenue'
import { CompanyUserSettings } from './settings/users'
import { VatSettings } from './settings/vat'

const renderContent = (rootData: RootData) => {
    const { page, pageParams } = assertViewName(rootData.view, 'Settings')

    if (page === 'general') {
        return React.createElement(GeneralSettings, rootData)
    } else if (page === 'vat') {
        return React.createElement(VatSettings, rootData)
    } else if (page === 'logo') {
        if (pageParams[0] === 'edit') {
            return React.createElement(LogoEdit, rootData)
        } else {
            return React.createElement(LogoView, rootData)
        }
    } else if (page === 'billing') {
        if (pageParams[0] === 'card-update') {
            return React.createElement(CardUpdateStatus, rootData)
        } else {
            return React.createElement(BillingSettings, rootData)
        }
    } else if (page === 'invoice') {
        return React.createElement(RevenueSettings, rootData)
    } else if (page === 'users') {
        return React.createElement(CompanyUserSettings, rootData)
    } else if (page === 'close-account') {
        return React.createElement(CloseCompanyAccount, rootData)
    } else if (!page) {
        return null
    } else {
        throw new Error('Invalid settings page: ' + page)
    }
}

export const Settings: FC<RootData> = (rootData) => {
    const { companyData, session } = rootData

    const settingsTranslation = t.settings.get()
    let menuTitle = settingsTranslation

    if (companyData.companies) {
        const company = getCompany(companyData, session)
        menuTitle = company.name + ' ' + settingsTranslation
    }

    return React.createElement(
        'div',
        { className: 'content-area' },
        React.createElement(
            'div',
            { className: 'sidebar settings-menu' },
            React.createElement(
                'h1',
                { className: 'settings-menu__title sidebar__title-with-icon title' },
                React.createElement('img', {
                    src: '/menu/settings.svg',
                    className: 'sidebar__title-icon',
                }),
                React.createElement('div', null, menuTitle),
            ),
            React.createElement(
                'div',
                { className: 'settings-menu__section' },
                React.createElement(
                    'div',
                    null,
                    React.createElement(Link, {
                        to: '#/settings/general',
                        text: t.settings.company.get(),
                        className: 'settings-menu__link',
                    }),
                ),
                React.createElement(
                    'div',
                    null,
                    React.createElement(Link, {
                        to: '#/settings/vat',
                        text: t.vat.get(),
                        className: 'settings-menu__link',
                    }),
                ),
                React.createElement(
                    'div',
                    null,
                    React.createElement(Link, {
                        to: '#/settings/logo',
                        text: t.settings.logo.get(),
                        className: 'settings-menu__link',
                    }),
                ),
                React.createElement(
                    'div',
                    null,
                    React.createElement(Link, {
                        to: '#/settings/billing',
                        text: t.settings.billing.get(),
                        className: 'settings-menu__link',
                    }),
                ),
                React.createElement(
                    'div',
                    null,
                    React.createElement(Link, {
                        to: '#/settings/invoice',
                        text: t.settings.revenue.get(),
                        className: 'settings-menu__link',
                    }),
                ),
                React.createElement(
                    'div',
                    null,
                    React.createElement(Link, {
                        to: '#/settings/users',
                        text: t.users.get(),
                        className: 'settings-menu__link',
                    }),
                ),
                React.createElement(
                    'div',
                    null,
                    React.createElement(Link, {
                        to: '#/settings/close-account',
                        text: t.settings.closeAccount.get(),
                        className: 'settings-menu__link',
                    }),
                ),
            ),
        ),
        React.createElement('div', { className: 'content settings' }, renderContent(rootData)),
    )
}
