import React from 'react'

import { LoadingIcon } from './loading-icon'

export const LoadingPage = () =>
    React.createElement(
        'div',
        { className: 'loading-page' },
        React.createElement(LoadingIcon, { color: 'white' }),
    )
