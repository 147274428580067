import React, { FC, Fragment } from 'react'

import { Processes } from '../../../common/types/processes'
import { t } from '../../i18n'
import { inputs } from '../../inputs'
import { ARCHIVE_PROCESS, archiveCompany } from '../../state/company-actions'
import { RootData } from '../../state/root-data'
import { Button, ButtonProps } from '../button'
import { Input } from '../input'
import { LoadingIcon } from '../loading-icon'

const renderButton = (isConfirmed: boolean, processes: Processes) => {
    const props: ButtonProps = {
        text: t.settings.closeAccount.close.get(),
        className: 'settings__close-account-button',
    }

    if (isConfirmed) {
        props.onClick = archiveCompany
        props.processes = processes
        props.processName = ARCHIVE_PROCESS
    } else {
        props.disabled = true
    }

    return React.createElement(Button, props)
}

const renderContent = (rootData: RootData) => {
    const {
        inputValues,
        processes,
        userData: { usersLimited: users },
    } = rootData
    const canArchive = users && users.length === 1

    if (canArchive) {
        const confirmInput = inputs.settings.closeAccount.confirm
        const confirmText = t.settings.closeAccount.confirm.get()
        const isConfirmed = confirmInput.get(inputValues) === confirmText

        return React.createElement(
            Fragment,
            null,
            React.createElement(
                'div',
                { className: 'text-warning' },
                t.settings.closeAccount.warning.get(),
            ),
            React.createElement(
                'div',
                { className: 'text-warning' },
                t.settings.closeAccount.confirmInstructions.get(confirmText),
                ':',
            ),
            React.createElement(
                'div',
                { className: 'top-margin' },
                React.createElement(Input, { input: confirmInput, inputValues }),
            ),
            React.createElement(
                'div',
                { className: 'top-margin' },
                renderButton(isConfirmed, processes),
            ),
        )
    } else {
        return React.createElement(
            'div',
            { className: 'text-multiline' },
            t.settings.closeAccount.usersStillLinked.get(),
        )
    }
}

export const CloseCompanyAccount: FC<RootData> = (rootData) => {
    const {
        userData: { usersLimited: users },
    } = rootData

    if (!users) {
        return React.createElement(LoadingIcon, { color: 'black' })
    }

    return React.createElement(
        'div',
        null,
        React.createElement('h1', { className: 'title' }, t.settings.closeAccount.get()),
        renderContent(rootData),
    )
}
