import React, { FC } from 'react'

import { getMinDay } from '../../../common/company-utils'
import { expenseTypes } from '../../../common/enums'
import { Day } from '../../../common/time'
import { ExpenseType } from '../../../common/types/enums'
import { t } from '../../i18n'
import { renderInputOrValue } from '../../input-utils'
import { inputs } from '../../inputs'
import { getCompany } from '../../state/company-actions'
import { SAVE_PROCESS } from '../../state/expense-actions'
import { RootData } from '../../state/root-data'
import { valErr } from '../../val-err'
import { DateInput, DateInputProps } from '../date-input'

interface Props {
    editMode: boolean
    rootData: RootData
    type: ExpenseType
}

const renderDate = (editMode: boolean, props: DateInputProps) => {
    if (editMode) {
        return React.createElement(DateInput, props)
    } else {
        const date = props.input.get(props.inputValues)
        return Day.fromYmd(date).longDate()
    }
}

export const ExpenseTopRight: FC<Props> = (props) => {
    const { editMode, rootData, type } = props
    const { companyData, inputValues, session, validationErrors } = rootData

    const company = getCompany(companyData, session)
    const minDate = getMinDay(company.interimDate)
    const valErrors = validationErrors[SAVE_PROCESS]

    return React.createElement(
        'div',
        null,
        React.createElement(
            'h1',
            { className: 'title' },
            type === expenseTypes.asset ? t.expenses.invoice.asset.get() : t.expenses.invoice.get(),
        ),
        React.createElement(
            'table',
            { className: 'table' },
            React.createElement(
                'tbody',
                null,
                React.createElement(
                    'tr',
                    null,
                    React.createElement(
                        'td',
                        { className: 'table__body-cell table__body-cell--no-left-pad' },
                        t.expenses.number.get(),
                    ),
                    React.createElement(
                        'td',
                        null,
                        renderInputOrValue(editMode, {
                            input: inputs.expense.number,
                            inputValues,
                            className: 'expense__general-input',
                        }),
                        valErr(valErrors, 'expense.number'),
                    ),
                ),
                React.createElement(
                    'tr',
                    null,
                    React.createElement(
                        'td',
                        { className: 'table__body-cell table__body-cell--no-left-pad' },
                        t.expenses.date.get(),
                    ),
                    React.createElement(
                        'td',
                        null,
                        renderDate(editMode, {
                            input: inputs.expense.date,
                            inputValues,
                            className: 'date-button expense__general-input',
                            minDate,
                        }),
                        valErr(valErrors, 'expense.date'),
                    ),
                ),
                React.createElement(
                    'tr',
                    null,
                    React.createElement(
                        'td',
                        { className: 'table__body-cell table__body-cell--no-left-pad' },
                        t.dueDate.get(),
                    ),
                    React.createElement(
                        'td',
                        null,
                        renderDate(editMode, {
                            input: inputs.expense.dueDate,
                            inputValues,
                            className: 'date-button expense__general-input',
                            minDate,
                        }),
                        valErr(valErrors, 'expense.dueDate', {
                            'under-min': t.invoices.dueDate.beforeDate.get(),
                        }),
                    ),
                ),
            ),
        ),
    )
}
