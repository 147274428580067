import * as api from '../api'
import { inputs } from '../inputs'
import { setRoute } from '../route-utils'
import { updateSession } from '../session-utils'
import { setInvalid } from './invalid-cache-actions'
import { loadCompanies, loadProfile, loadReceivedInvites } from './load-actions'
import { run } from './process-actions'
import { getState } from './store'
import { clearErrors } from './validation-actions'

export const ACCEPT_PROCESS = 'user-settings/accept-invite'
export const SAVE_USER_PROCESS = 'user-settings/save'

const genInputs = inputs.userSettings.general

export const initGeneral = async () => {
    clearErrors(SAVE_USER_PROCESS)
    await loadProfile()
    const profile = getState().userData.profile!
    genInputs.firstName.set(profile.firstName)
    genInputs.lastName.set(profile.lastName)
}

export const initCompanies = async () => Promise.all([loadCompanies(), loadReceivedInvites()])

export const acceptInvite = async (inviteId: string) =>
    run(ACCEPT_PROCESS, async () => {
        await api.acceptInvite(inviteId)
        setInvalid('company')
        setInvalid('received-invite')

        void Promise.all([loadCompanies(), loadReceivedInvites()])
    })

export const saveUser = async () =>
    run(SAVE_USER_PROCESS, async () => {
        const { inputValues } = getState()
        const firstName = inputs.userSettings.general.firstName.get(inputValues)
        const lastName = inputs.userSettings.general.lastName.get(inputValues)
        const newSession = await api.updateSelf(firstName, lastName)
        updateSession(newSession)
        setRoute('#/user-settings/general')
    })
