import React, { FC } from 'react'

import { Button, ButtonProps } from './button'

export interface ExcelButtonProps {
    button: ButtonProps
    progressText?: string
}

export const ExcelButton: FC<ExcelButtonProps> = ({ button, progressText }) =>
    React.createElement(
        'span',
        null,
        React.createElement(Button, button),
        progressText && React.createElement('span', null, ' ', progressText),
    )
