import moment from 'moment'

type CreateLocalMoment = (timestamp: number) => moment.Moment

const MS_PER_DAY = 86400000

let createLocalMoment: CreateLocalMoment = (timestamp) => moment(timestamp)

let fakeTime: number | null = null

// In most cases, you should use setTime() from clock.ts which calls this and does additional work
export const setFakeTime = (newFakeTime: number) => (fakeTime = newFakeTime)

export const isFakeTime = () => typeof fakeTime === 'number'

export const setLocalMomentFactory = (factory: CreateLocalMoment) => (createLocalMoment = factory)

/**
 * Represents a physical moment in time without the notion of timezones.
 *
 * To perform any operation that requires a specific timezone (e.g. converting to
 * a human readable date or time string), use asUtc() or asLocal() to obtain an
 * instance of ZonedTime.
 */
export class Time {
    public readonly timestamp: number

    private constructor(timestamp: number) {
        this.timestamp = timestamp
    }

    public static fromTimestamp(timestamp: number) {
        return new Time(timestamp)
    }

    public static fromDate(date: Date) {
        return new Time(date.getTime())
    }

    public static now() {
        return Time.fromTimestamp(fakeTime || new Date().getTime())
    }

    public static fromIso(iso: string) {
        // Must throw an error if the string is invalid,
        // validators depend on this.
        const date = new Date(iso)
        const newIso = date.toISOString()

        if (newIso !== iso) {
            throw new Error('Invalid date: ' + iso)
        }

        return Time.fromDate(date)
    }

    public toDate() {
        return new Date(this.timestamp)
    }

    /** Returns a ZonedTime instance in the UTC timezone */
    public asUtc() {
        return ZonedTime.fromTimestamp(this.timestamp, true)
    }

    /** Returns a ZonedTime instance in the default local timezone of the browser or server */
    public asLocal() {
        return ZonedTime.fromTimestamp(this.timestamp, false)
    }

    public iso() {
        return this.toDate().toISOString()
    }

    public addMilliseconds(ms: number) {
        return new Time(this.timestamp + ms)
    }

    public addMinutes(minutes: number) {
        return new Time(this.timestamp + minutes * 60000)
    }

    public addHours(hours: number) {
        return new Time(this.timestamp + hours * 3600000)
    }

    public diff(other: Time) {
        return this.timestamp - other.timestamp
    }

    public isSameOrBefore(other: Time) {
        return this.timestamp <= other.timestamp
    }

    public isBefore(other: Time) {
        return this.timestamp < other.timestamp
    }
}

class ZonedTime {
    /** Number of seconds (not milliseconds) since the Unix epoch */
    public readonly timestamp: number

    /** Currently only supports UTC or local timezone, so we use a boolean */
    public readonly utc: boolean

    private constructor(timestamp: number, utc: boolean) {
        this.timestamp = timestamp
        this.utc = utc
    }

    public static fromTimestamp(timestamp: number, utc: boolean) {
        return new ZonedTime(timestamp, utc)
    }

    /** Discards timezone interpretation */
    public asAbsolute() {
        return Time.fromTimestamp(this.timestamp)
    }

    public toMoment() {
        return this.utc ? moment.utc(this.timestamp) : createLocalMoment(this.timestamp)
    }

    public format(format: string) {
        return this.toMoment().format(format)
    }

    public ymd() {
        return this.format('YYYY-MM-DD')
    }

    public hms() {
        return this.format('HH:mm:ss')
    }

    public shortDateTime() {
        return this.format('D.MM.YYYY HH:mm')
    }

    public longDateTime() {
        return this.format('D. MMMM YYYY HH:mm')
    }
}

/** Represents a day without a specific time of day (and therefore no timezone) */
export class Day {
    /**
     * 0 = 1970-01-01
     * 1 = 1970-01-02
     * etc
     */
    private readonly unixDay: number

    /** For easier debugging */
    private readonly ymdStr: string

    private constructor(unixDay: number) {
        if (!Number.isInteger(unixDay)) {
            throw new Error('Non-integer unix day: ' + unixDay)
        }

        this.unixDay = unixDay
        this.ymdStr = new Date(unixDay * MS_PER_DAY).toISOString().substr(0, 10)
    }

    private static fromTimestamp(timestamp: number) {
        return new Day(timestamp / MS_PER_DAY)
    }

    public static fromYmd(ymd: string) {
        const ms = new Date(ymd + 'T00:00:00.000Z').getTime()
        const day = Day.fromTimestamp(ms)

        if (day.ymdStr !== ymd) {
            throw new Error('Invalid date: ' + ymd)
        }

        return day
    }

    /** The month parameter is 1-based: 1 = January, ..., 12 = December */
    public static fromNumeric(year: number, month: number, dayOfMonth: number) {
        const ms = Date.UTC(year, month - 1, dayOfMonth)
        const day = Day.fromTimestamp(ms)

        if (day.dayOfMonth() !== dayOfMonth || day.month() !== month || day.year() !== year) {
            throw new Error('Invalid date: ' + year + '-' + month + '-' + dayOfMonth)
        }

        return day
    }

    /** Interprets the Date object in local time, not UTC */
    public static fromDate(date: Date) {
        return Day.fromNumeric(date.getFullYear(), date.getMonth() + 1, date.getDate())
    }

    /** Today in the local timezone, not UTC */
    public static today() {
        return Day.fromYmd(Time.now().asLocal().ymd())
    }

    /** Parses string in YYYY-MM format, returns first Day of the month */
    public static fromYm(ym: string) {
        return Day.fromYmd(ym + '-01')
    }

    public static min(day1: Day, day2: Day) {
        return day1.unixDay < day2.unixDay ? day1 : day2
    }

    public static max(day1: Day, day2: Day) {
        return day1.unixDay > day2.unixDay ? day1 : day2
    }

    /** Number of milliseconds (not seconds) since the Unix epoch */
    public toTimestamp() {
        return this.unixDay * MS_PER_DAY
    }

    public toDate() {
        return new Date(this.toTimestamp())
    }

    // TODO review public usage, possibly change back to private
    public toMoment() {
        return moment.utc(this.toTimestamp())
    }

    public format(format: string) {
        return this.toMoment().format(format)
    }

    private useMoment(transform: (value: moment.Moment) => void) {
        const momentValue = this.toMoment()
        transform(momentValue)
        return Day.fromYmd(momentValue.format('YYYY-MM-DD'))
    }

    public dayOfMonth() {
        return this.toDate().getUTCDate()
    }

    /** 1-based: 1 = January, ..., 12 = December */
    public month() {
        return this.toDate().getUTCMonth() + 1
    }

    public year() {
        return this.toDate().getUTCFullYear()
    }

    public ymd() {
        return this.ymdStr // YYYY-MM-DD
    }

    public dmy() {
        return this.format('D.MM.YYYY')
    }

    public longDate() {
        return this.format('D. MMMM YYYY')
    }

    public ym() {
        return this.format('YYYY-MM')
    }

    public longMonth() {
        return this.format('MMMM YYYY')
    }

    public shortMonth() {
        return this.format('MM.YYYY')
    }

    public monthName() {
        return this.format('MMMM')
    }

    public addDays(days: number) {
        if (!Number.isInteger(days)) {
            throw new Error('Non-integer number of days: ' + days)
        }

        return new Day(this.unixDay + days)
    }

    public firstOfYear() {
        return Day.fromNumeric(this.year(), 1, 1)
    }

    public firstOfPreviousYear() {
        return Day.fromNumeric(this.year() - 1, 1, 1)
    }

    public lastOfYear() {
        return Day.fromNumeric(this.year(), 12, 31)
    }

    public lastOfPreviousYear() {
        return Day.fromNumeric(this.year() - 1, 12, 31)
    }

    public firstOfNextYear() {
        return Day.fromNumeric(this.year() + 1, 1, 1)
    }

    // TODO additional parameter for strategy to use when there aren't enough days in the month.
    // For example, should adding 1 year to Feb 29 give Feb 28 or Mar 1?
    public addYears(years: number) {
        return this.useMoment((m) => m.add(years, 'years'))
    }

    public firstOfMonth() {
        return this.addDays(1 - this.dayOfMonth())
    }

    // TODO additional parameter for strategy to use when there aren't enough days in the month.
    // For example, should adding 1 month to Jan 31 give Feb 28/29 or Mar 1?
    public addMonths(months: number) {
        return this.useMoment((m) => m.add(months, 'months'))
    }

    public firstOfPreviousMonth() {
        return this.firstOfMonth().addDays(-1).firstOfMonth()
    }

    public firstOfNextMonth() {
        return this.firstOfMonth().addDays(32).firstOfMonth()
    }

    public lastOfMonth() {
        return this.firstOfNextMonth().addDays(-1)
    }

    public lastOfPreviousMonth() {
        return this.firstOfMonth().addDays(-1)
    }

    public withDayOfMonth(dayOfMonth: number) {
        const newDay = this.addDays(dayOfMonth - this.dayOfMonth())

        if (newDay.dayOfMonth() === dayOfMonth) {
            return newDay
        } else {
            // Can happen if the parameter is less than 1, more than 31, etc
            throw new Error('Invalid day of month')
        }
    }

    public isSame(other: Day) {
        return this.unixDay === other.unixDay
    }

    public isBefore(other: Day) {
        return this.unixDay < other.unixDay
    }

    public isAfter(other: Day) {
        return this.unixDay > other.unixDay
    }

    public isSameOrBefore(other: Day) {
        return this.unixDay <= other.unixDay
    }

    public isSameOrAfter(other: Day) {
        return this.unixDay >= other.unixDay
    }

    public diffDays(other: Day) {
        return this.unixDay - other.unixDay
    }
}
