import classnames from 'classnames'
import React, {
    Attributes,
    ButtonHTMLAttributes,
    FC,
    OptionHTMLAttributes,
    SelectHTMLAttributes,
} from 'react'

import { ChoiceType } from '../../common/types/enums'
import { ChoiceOption, Input, InputValues } from '../../common/types/inputs'

type ButtonProps = Attributes & ButtonHTMLAttributes<HTMLButtonElement>
type OptionProps = Attributes & OptionHTMLAttributes<HTMLOptionElement>
type SelectProps = SelectHTMLAttributes<HTMLSelectElement>

type OnChange<T> = (newValue: T) => void

export interface ChoiceProps<T extends string> {
    input: Input<T>
    inputValues: InputValues
    type: ChoiceType
    options: ChoiceOption<T>[]
    afterChange?: OnChange<T>
    forceSelection?: boolean
    emptyLabel?: string
    domId?: string
    disabled?: boolean
    groupClassName?: string
    /** This class will not be applied to selected buttons */
    buttonClassName?: string
    selectedButtonClassName?: string
}

const renderDropdown = <T extends string>(
    props: ChoiceProps<T>,
    value: T,
    onChange: OnChange<T>,
) => {
    const {
        options,
        forceSelection,
        domId,
        disabled: groupDisabled,
        emptyLabel,
        groupClassName,
    } = props

    const optionElements = options.map(({ id: buttonId, label, disabled: optionDisabled }) => {
        const optionProps: OptionProps = {
            key: String(buttonId),
            value: String(buttonId),
            disabled: optionDisabled,
        }

        return React.createElement('option', optionProps, label)
    })

    if (!forceSelection) {
        // Empty option for deselecting
        const optionProps: OptionProps = { key: '', value: '' }
        optionElements.unshift(React.createElement('option', optionProps, emptyLabel || ''))
    }

    const selectProps: SelectProps = { value: String(value), id: domId, className: groupClassName }

    if (groupDisabled) {
        selectProps.disabled = true
    } else {
        selectProps.onChange = (evt) => onChange(evt.currentTarget.value as T)
    }

    return React.createElement('select', selectProps, optionElements)
}

const renderButtons = <T extends string>(
    props: ChoiceProps<T>,
    value: T,
    onChange: OnChange<T>,
) => {
    const {
        options,
        forceSelection,
        domId: groupDomId,
        disabled: groupDisabled,
        groupClassName,
        buttonClassName,
        selectedButtonClassName,
    } = props

    if (!buttonClassName || !selectedButtonClassName) {
        throw new Error('Button class names are required')
    }

    const buttons = options.map((option) => {
        const {
            id: buttonId,
            label,
            disabled: optionDisabled,
            domId: buttonDomId,
            renderButtonContents,
        } = option

        const selected = buttonId === value

        const classNames = ['button']

        if (!selected && buttonClassName) {
            classNames.push(buttonClassName)
        }

        if (selected && selectedButtonClassName) {
            classNames.push(selectedButtonClassName)
        }

        const buttonProps: ButtonProps = {
            key: String(buttonId),
            id: buttonDomId,
            className: classNames.join(' '),
        }

        if (groupDisabled || optionDisabled) {
            buttonProps.disabled = true
        } else {
            buttonProps.onClick = () => {
                if (!selected) {
                    onChange(buttonId)
                } else if (!forceSelection) {
                    onChange('' as T)
                }
            }
        }

        return React.createElement('button', buttonProps, renderButtonContents?.() || label)
    })

    return React.createElement(
        'div',
        { className: classnames('button-group', groupClassName), id: groupDomId },
        buttons,
    )
}

const Choice: FC<ChoiceProps<any>> = <T extends string>(props: ChoiceProps<T>) => {
    const { input, inputValues, type, options, afterChange, forceSelection } = props

    const onChange: OnChange<T> = (newValue) => {
        if (input) {
            input.set(newValue)
        }

        if (afterChange) {
            afterChange(newValue)
        }
    }

    const value = input.get(inputValues)

    if (forceSelection) {
        const anySelected = options.some(({ id: optionId }) => value === optionId)

        if (!anySelected) {
            const error = new Error('Invalid selection: ' + String(value))

            if (process.env.NODE_ENV === 'development') {
                // This error is thrown during some tests even when they pass.
                // This line suppresses unwanted logging that clutters the output.
                const suppressedError = error as { suppressReactErrorLogging?: boolean }
                suppressedError.suppressReactErrorLogging = true
            }

            throw error
        }
    }

    if (type === 'dropdown') {
        return renderDropdown<T>(props, value, onChange)
    } else if (type === 'buttons') {
        return renderButtons<T>(props, value, onChange)
    } else {
        throw new Error('Unrecognized choice type: ' + type)
    }
}

export const renderChoice = <T extends string>(props: ChoiceProps<T>) =>
    React.createElement<ChoiceProps<T>>(Choice, props)
