import React, { Fragment } from 'react'

import { Overrides, ValidationError as TValidationError } from '../common/types/errors'
import { ValidationError, ValidationErrorProps } from './components/validation-error'

export const getValidationErrorProps = (
    errors: TValidationError[] = [],
    key: string,
    overrides?: Overrides,
): ValidationErrorProps[] => {
    return errors.filter((error) => error.key === key).map((error) => ({ error, overrides }))
}

export const renderValidationErrors = (errorsProps: ValidationErrorProps[] = []) => {
    return React.createElement(
        Fragment,
        null,
        ...errorsProps.map((errorProps) => React.createElement(ValidationError, errorProps)),
    )
}

// TODO inline?
export const valErr = (errors: TValidationError[] = [], key: string, overrides?: Overrides) => {
    return renderValidationErrors(getValidationErrorProps(errors, key, overrides))
}
