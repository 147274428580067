import classnames from 'classnames'
import React, { ClassAttributes, Component, HTMLAttributes, ReactNode } from 'react'

import { Column, ColumnHeader } from '../../common/types/table'
import { t } from '../i18n'

// TODO separate components for simple table, wrapped scrolling table, unwrapped table with sticky header, etc

export interface BaseRow {
    reactKey?: string
    domId?: string
    className?: string
    onClick?: () => void
    domTitle?: string
}

export interface TableProps<Row extends BaseRow, Totals> {
    columns: Column<Row, Totals>[]
    rows: Row[]
    totals?: Totals
    noHeader?: boolean
    hasSecondHeader?: boolean
    // TODO use position: sticky on tr or thead once it works in Chrome, Firefox and Safari
    stickyHeader?: boolean
    domId?: string
    noWrapper?: boolean
    wrapperClassName?: string
    tableClassName?: string
    headerClassName?: string
}

type DivProps = ClassAttributes<HTMLDivElement> & HTMLAttributes<HTMLDivElement>
type TableSectionProps = ClassAttributes<HTMLTableSectionElement> &
    HTMLAttributes<HTMLTableSectionElement>

// TODO convert into functional component
/* eslint-disable @typescript-eslint/unbound-method */

class Table<Row extends BaseRow, Totals> extends Component<TableProps<Row, Totals>> {
    innerWrapper: HTMLDivElement | null = null
    normalHeader: HTMLTableSectionElement | null = null
    floatHeader: HTMLTableSectionElement | null = null
    resizeObserver: ResizeObserver | null = null

    constructor(props: TableProps<Row, Totals>) {
        super(props)
        this.updateStickyHeader = this.updateStickyHeader.bind(this)
    }

    override componentDidMount() {
        if (this.props.stickyHeader) {
            const scrollable = this.props.noWrapper ? window : this.innerWrapper
            const parent = this.props.noWrapper ? document.documentElement : this.innerWrapper

            if (scrollable && parent) {
                scrollable.addEventListener('scroll', this.updateStickyHeader)

                this.resizeObserver = new ResizeObserver(this.updateStickyHeader)
                this.resizeObserver.observe(parent)

                this.updateStickyHeader()
            }
        }
    }

    override componentDidUpdate() {
        if (this.props.stickyHeader) {
            this.updateStickyHeader()
        }
    }

    override componentWillUnmount() {
        if (this.props.stickyHeader && this.innerWrapper) {
            this.innerWrapper.removeEventListener('scroll', this.updateStickyHeader)
        }

        if (this.resizeObserver) {
            this.resizeObserver.disconnect()
        }
    }

    updateStickyHeader() {
        const { normalHeader, floatHeader } = this

        if (!normalHeader || !floatHeader) {
            // Most likely the table isn't rendered at the moment, so we can skip the updates.
            return
        }

        // Synchronize column widths from normal header to floating header
        const normalCells = normalHeader.querySelectorAll('th')
        const floatCells = floatHeader.querySelectorAll('th')
        const widths = []

        for (let i = 0; i < normalCells.length; i += 1) {
            const { width } = normalCells[i].getBoundingClientRect()
            widths[i] = width
        }

        for (let i = 0; i < widths.length; i += 1) {
            const { style } = floatCells[i]
            const widthStr = widths[i] + 'px'
            style.minWidth = widthStr
            style.maxWidth = widthStr
        }

        // Tweak left margin if needed
        const floatHeaderLeft = floatHeader.getBoundingClientRect().left
        const floatRow = floatHeader.querySelector('tr')!
        const floatRowLeft = floatRow.getBoundingClientRect().left

        // 0 in Chrome, -1 in Firefox if cells have borders
        const leftOffset = floatHeaderLeft - floatRowLeft

        if (leftOffset !== 0) {
            floatHeader.style.marginLeft = leftOffset + 'px'
        }

        if (this.props.noWrapper) {
            const tableNode = floatHeader.parentNode

            if (tableNode instanceof HTMLTableElement) {
                const showFloatHeader = normalHeader.getBoundingClientRect().top < 0

                // Keeping the floating header in the DOM and hiding/showing it using
                // transform provides a smoother transition than using 'display: none'
                floatHeader.style.transform = showFloatHeader ? 'scaleX(1)' : 'scaleX(0)'

                // Align by table bottom if needed
                const headerHeight = floatHeader.getBoundingClientRect().height
                const tableBottom = tableNode.getBoundingClientRect().bottom

                floatHeader.style.top = Math.min(0, tableBottom - headerHeight) + 'px'
            }
        }
    }

    renderHeaderCells(headers: Array<{ header: ColumnHeader; spanRow: boolean }>) {
        const cells = []
        let toSkip = 0

        for (const { header, spanRow } of headers) {
            if (toSkip > 0) {
                toSkip -= 1
                continue
            }

            if (!header) {
                throw new Error('Missing header definition')
            }

            const props = header.getProps ? header.getProps() || {} : {}

            if (header.span) {
                props.colSpan = header.span
                toSkip = header.span - 1
            }

            if (spanRow) {
                if (header.span) {
                    throw new Error('Mixing row and column spans is currently not supported')
                }

                props.rowSpan = 2
            }

            const content: ReactNode = 'content' in header ? header.content : ''
            const cell = React.createElement('th', props, content)
            cells.push(cell)
        }

        return cells
    }

    renderHeader(visibleColumns: Column<Row, Totals>[], renderingEmpty: boolean) {
        const { noHeader, noWrapper, hasSecondHeader, stickyHeader, headerClassName } = this.props

        if (noHeader) {
            return null
        }

        const setNormalHeader = (node: HTMLTableSectionElement | null) => {
            this.normalHeader = node
        }

        const normalCells = this.renderHeaderCells(
            visibleColumns.map((column) => ({
                header: column.header || {},
                spanRow: Boolean(hasSecondHeader && !column.secondHeader),
            })),
        )

        const rows = [React.createElement('tr', null, ...normalCells)]

        if (hasSecondHeader) {
            const secondColumns = []

            for (const column of visibleColumns) {
                if (column.secondHeader) {
                    secondColumns.push({ header: column.secondHeader, spanRow: false })
                }
            }

            rows.push(React.createElement('tr', null, ...this.renderHeaderCells(secondColumns)))
        }

        const normalHeader = React.createElement<TableSectionProps>(
            'thead',
            { key: 'normal', ref: setNormalHeader, className: headerClassName },
            ...rows,
        )

        if (stickyHeader && !renderingEmpty) {
            const setFloatHeader = (node: HTMLTableSectionElement | null) => {
                this.floatHeader = node
            }
            const className = classnames('floating', { fixed: noWrapper }, headerClassName)
            const props: TableSectionProps = { key: 'floating', ref: setFloatHeader, className }
            const floatHeader = React.createElement('thead', props, ...rows)
            return [normalHeader, floatHeader]
        } else {
            return normalHeader
        }
    }

    renderBodyRow(row: Row, visibleColumns: Column<Row, Totals>[]) {
        const cells = []
        let toSkip = 0
        const context = { columnCount: visibleColumns.length }

        for (const column of visibleColumns) {
            if (toSkip > 0) {
                toSkip -= 1
                continue
            }

            const cellProps = column.getProps ? column.getProps(row, context) : null

            if (cellProps?.colSpan) {
                if (toSkip > 0) {
                    throw new Error('Overlapping col spans')
                }

                toSkip = cellProps.colSpan - 1
            }

            const value = column.render(row)
            cells.push(
                React.createElement(
                    'td',
                    cellProps,
                    typeof value === 'string' ? value || null : value.browser,
                ),
            )
        }

        const rowProps = {
            key: row.reactKey,
            id: row.domId,
            className: row.className,
            onClick: row.onClick,
            title: row.domTitle,
        }

        return React.createElement('tr', rowProps, ...cells)
    }

    renderTotals(visibleColumns: Column<Row, Totals>[]) {
        const { totals } = this.props

        if (!totals) {
            return null
        }

        let isFirst = true

        const cells = visibleColumns.map((column) => {
            let contents

            if (isFirst) {
                if (column.getTotal) {
                    throw new Error('First column should not have a getTotal method')
                }

                isFirst = false
                contents = t.total.get()
            } else {
                contents = column.getTotal ? column.getTotal(totals) : ''
            }

            const props = column.getTotalProps ? column.getTotalProps() : null
            return React.createElement('td', props, contents)
        })

        return React.createElement('tr', { className: 'totals' }, ...cells)
    }

    override render() {
        const { columns, rows, domId, noWrapper, wrapperClassName, tableClassName } = this.props

        const visibleColumns = columns.filter((column) => !column.hideInBrowser)

        if (rows.length === 0) {
            return React.createElement(
                'table',
                { id: domId, className: classnames(tableClassName, 'unwrapped') },
                this.renderHeader(visibleColumns, true),
                React.createElement(
                    'tbody',
                    null,
                    React.createElement(
                        'tr',
                        null,
                        ...visibleColumns.map(() => React.createElement('td', null, '\u00a0')),
                    ),
                    this.renderTotals(visibleColumns),
                ),
            )
        }

        const table = React.createElement(
            'table',
            { id: domId, className: tableClassName },
            this.renderHeader(visibleColumns, false),
            React.createElement(
                'tbody',
                null,
                ...rows.map((row) => this.renderBodyRow(row, visibleColumns)),
                this.renderTotals(visibleColumns),
            ),
        )

        if (noWrapper) {
            return table
        }

        const innerWrapperProps: DivProps = {
            className: 'table-wrapper-inner',
            ref: (node) => {
                this.innerWrapper = node
            },
        }

        return React.createElement(
            'div',
            { className: classnames('table-wrapper-outer', wrapperClassName) },
            React.createElement('div', innerWrapperProps, table),
        )
    }
}

export const renderTable = <Row extends BaseRow, Totals = undefined>(
    props: TableProps<Row, Totals>,
) => React.createElement<TableProps<Row, Totals>>(Table, props)
