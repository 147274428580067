import classnames from 'classnames'
import React, { FC } from 'react'

import { BANK, CASH, NET_PROFIT } from '../../../common/accounts'
import { amountEquals } from '../../../common/amount-equals'
import { reportModes } from '../../../common/enums'
import { Day } from '../../../common/time'
import { ValidationError } from '../../../common/types/errors'
import { InputValues } from '../../../common/types/inputs'
import { Processes } from '../../../common/types/processes'
import { Column } from '../../../common/types/table'
import { amountFromString } from '../../amount-from-string'
import { assertViewName } from '../../assert-view-name'
import { getRows, getTopLevelGroups, IsVisible } from '../../balance-utils'
import { formatAmount } from '../../format-amount'
import { t } from '../../i18n'
import { renderInputOrValue } from '../../input-utils'
import { inputs } from '../../inputs'
import { anyNonZero, Row } from '../../report-utils'
import {
    getCompany,
    SAVE_INTERIM_BALANCE_PROCESS,
    updateInterimBalance,
} from '../../state/company-actions'
import { RootData } from '../../state/root-data'
import { browserOnly, combineHorizontally } from '../../table-utils'
import { valErr } from '../../val-err'
import { BackLink } from '../back-link'
import { Button } from '../button'
import { LoadingPage } from '../loading-page'
import { renderTable } from '../table'

type ColumnType = 'interim' // TODO: 'interim' | 'current'

interface BalanceRow extends Row<ColumnType> {
    balanceTitle?: string
}

type RowFilter = (row: BalanceRow) => boolean

const getIsVisible = (editMode: boolean): IsVisible<ColumnType> => {
    if (editMode) {
        return () => true
    } else {
        return (amounts, number) => number === NET_PROFIT || anyNonZero(amounts)
    }
}

const getColumns = (
    inputValues: InputValues,
    valErrors: ValidationError[] | undefined,
    anyMismatches: boolean,
    editMode: boolean,
): Column<BalanceRow>[] => [
    {
        getProps: (row) => {
            const classDict: Record<string, boolean> = {
                'table__body-cell': true,
                'table__body-cell--no-left-pad': true,
                balance__cell: true,
            }

            if (!row.balanceTitle) {
                classDict['balance__account-name'] = true
                classDict['balance__cell--invalid'] = Boolean(anyMismatches && row.topLevel)
                classDict['balance__account-name--total'] = row.level === 0

                // Level 2 accounts are omitted, so level 3 accounts use level 2 indentation
                classDict['balance__account-name--level2'] = row.level === 3
                classDict['balance__total-row-cell'] = Boolean(row.topLevel)
            }

            return { className: classnames(classDict) }
        },
        render: browserOnly((row) => {
            if (row.balanceTitle) {
                return React.createElement(
                    'h2',
                    { className: 'balance__side-title' },
                    row.balanceTitle,
                )
            } else {
                // Use non-breaking space as empty row content to avoid collapsing the height
                return row.isEmpty ? '\u00a0' : row.label || ''
            }
        }),
    },
    {
        getProps: (row) => {
            if (row.balanceTitle) {
                return null
            }

            const className = classnames('amount', 'balance__cell', {
                'text-bold': !row.level,
                'balance__cell--invalid': anyMismatches && row.topLevel,
                'balance__total-row-cell': row.topLevel,
            })

            return { className }
        },
        render: browserOnly((row) => {
            if (row.isEmpty) {
                return ''
            }

            if (row.level === 3) {
                const number = row.number! + '.1'
                const input = inputs.financialHistory.balance(number)

                return React.createElement(
                    'div',
                    null,
                    renderInputOrValue(
                        editMode,
                        { input, inputValues, className: 'amount' },
                        (value) => formatAmount(Number(value)),
                    ),
                    valErr(valErrors, 'interimBalance.' + number, {
                        'under-min': (error) => {
                            if (error.reason === 'non-negative') {
                                return t.balance.validation.negativeAmount.get()
                            } else {
                                return null
                            }
                        },
                    }),
                )
            } else if (row.level === 0 || (row.level === 1 && !editMode)) {
                return formatAmount(row.amounts!.get('interim')!)
            } else {
                return ''
            }
        }),
    },
]

const getRowFilter = (editMode: boolean): RowFilter => {
    if (editMode) {
        return (row) => row.level !== 2
    } else {
        return () => true
    }
}

const addEmptyRows = (rows: BalanceRow[], targetLength: number) => {
    while (rows.length < targetLength) {
        rows.push({ isEmpty: true })
    }
}

const getNegativeBalanceErrorMessage = (error: ValidationError): string => {
    const date = Day.fromYmd(error.date).dmy()

    if (error.account === CASH) {
        return t.balance.validation.negativeBalance.cash.get(date)
    } else if (error.account === BANK) {
        return t.balance.validation.negativeBalance.bank.get(date)
    }

    return t.balance.validation.negativeBalance.get(date, error.account)
}

const renderEditButton = (editMode: boolean) => {
    if (editMode) {
        return null
    }

    return React.createElement(
        'a',
        {
            className: 'button button--primary',
            href: '#/financial-history/balance/edit',
        },
        t.edit.get(),
    )
}

const renderSaveButton = (editMode: boolean, disabled: boolean, processes: Processes) => {
    if (!editMode) {
        return null
    }

    return React.createElement(Button, {
        text: t.save.get(),
        disabled,
        onClick: updateInterimBalance,
        processes,
        processName: SAVE_INTERIM_BALANCE_PROCESS,
        className: 'button--primary',
    })
}

export const FinancialHistoryBalance: FC<RootData> = (rootData) => {
    const { companyData, inputValues, processes, session, validationErrors, view } = rootData

    if (!companyData.companies || !companyData.interimBalance) {
        return React.createElement(LoadingPage)
    }

    const company = getCompany(companyData, session)

    const getAmounts = (number: string) => {
        const amounts = new Map<ColumnType, number>()

        const input = inputs.financialHistory.balance(number)
        amounts.set('interim', amountFromString(input.get(inputValues)))

        return amounts
    }

    const { editMode } = assertViewName(view, 'FinancialHistoryBalance')
    const isVisible = getIsVisible(editMode)
    const { debit, credit } = getTopLevelGroups(getAmounts, isVisible, [])

    let anyMismatches = false

    const totalDebit = debit.amounts!.get('interim')!
    const totalCredit = credit.amounts!.get('interim')!

    if (!amountEquals(totalDebit, totalCredit)) {
        anyMismatches = true
    }

    const valErrors = validationErrors[SAVE_INTERIM_BALANCE_PROCESS]
    const columns = getColumns(inputValues, valErrors, anyMismatches, editMode)

    const debitRows: BalanceRow[] = [{ balanceTitle: t.balance.debit.get() }]
    const creditRows: BalanceRow[] = [{ balanceTitle: t.balance.credit.get() }]

    const mode = editMode ? reportModes.long : reportModes.short
    const rowFilter = getRowFilter(editMode)
    debitRows.push(...getRows(debit, mode).filter(rowFilter))
    creditRows.push(...getRows(credit, mode).filter(rowFilter))

    const debitTotalRow = debitRows.pop()!
    const creditTotalRow = creditRows.pop()!

    const maxRowCount = Math.max(debitRows.length, creditRows.length)
    addEmptyRows(debitRows, maxRowCount)
    addEmptyRows(creditRows, maxRowCount)

    debitRows.push(debitTotalRow)
    creditRows.push(creditTotalRow)

    const { columns: combinedColumns, rows: combinedRows } = combineHorizontally(
        { columns, rows: debitRows },
        {
            columns: [
                {
                    getProps: () => ({ className: 'balance__padding' }),
                    render: () => '',
                },
            ],
            rows: [{}],
        },
        { columns, rows: creditRows },
    )

    return React.createElement(
        'div',
        { className: 'content-area' },
        React.createElement(
            'div',
            { className: 'content report' },
            editMode ? React.createElement(BackLink) : null,
            React.createElement(
                'h1',
                { className: 'title' },
                t.interimBalance.get(),
                React.createElement(
                    'span',
                    { className: 'title__sub-title' },
                    t.asOf.get(Day.fromYmd(company.interimDate).dmy()),
                ),
            ),
            React.createElement(
                'div',
                { className: 'flex' },
                renderTable({
                    columns: combinedColumns,
                    rows: combinedRows,
                    noHeader: true,
                    noWrapper: true,
                    tableClassName: 'table balance',
                }),
            ),
            valErr(valErrors, 'interimBalance', {
                'negative-balance': getNegativeBalanceErrorMessage,
            }),
            React.createElement(
                'div',
                { className: 'top-margin' },
                renderEditButton(editMode),
                renderSaveButton(editMode, anyMismatches, processes),
            ),
        ),
    )
}
