import { Column } from '../../common/types/table'
import { XmlBuilder } from '../../common/xml'

export enum ExcelNumberFormat {
    // Built-in formats
    percentage = 10,
    date = 14,

    // Custom number format ids must be within specific ranges, one of them is 164-382:
    // https://msdn.microsoft.com/en-us/library/dd944946(v=office.12).aspx
    // We use 200+n for ours.

    month = 201,
    money,
    /** Same as money, but uses dash instead of zero */
    moneyDash,
}

// TODO replace hardcoded choices with customizable objects like ExcelStyle?
export enum ExcelFont {
    regular = 0,
    bold = 1,
}
export enum ExcelFill {
    header = 2,
}
export enum ExcelBorder {
    header = 1,
}

export interface ExcelStyle {
    alignment?: { horizontal?: 'right'; indent?: number }
    font?: ExcelFont
    fill?: ExcelFill
    border?: ExcelBorder
    numberFormat?: ExcelNumberFormat
}

export interface StyleManager {
    getIndex: (style: ExcelStyle) => Promise<number>
    writeXml: (builder: XmlBuilder) => void
}

export interface ExcelSpec<Row, Totals = undefined> {
    outputName: string
    columns: Column<Row, Totals>[]
    rows: Row[]
    totals?: Totals
    noHeader?: boolean
    hasSecondHeader?: true
}
