import React, { FC } from 'react'

import { cleanString } from '../../../common/clean-string'
import { normalizeBusinessName } from '../../../common/normalize-business-name'
import { ApiExpense } from '../../../common/types/expense'
import { InputValues } from '../../../common/types/inputs'
import { highlight } from '../../business-utils'
import { renderClearFiltersButton } from '../../expense-archive-utils'
import { t } from '../../i18n'
import { inputs } from '../../inputs'
import { RootData } from '../../state/root-data'
import { Button } from '../button'
import { Input } from '../input'
import { LoadingPage } from '../loading-page'
import { ExpenseArchiveSidebar } from './archive-sidebar'

interface SimpleBusiness {
    regCode: string
    name: string
    invoiceCount: number
}

const vendorInputs = inputs.expense.archive.vendor

const matches = (search: string, normalized: string[]) => {
    return normalized.some((norm) => norm.startsWith(search))
}

const renderFoundBusiness = (business: SimpleBusiness, search: string) => {
    const onClick = () => {
        vendorInputs.regCode.set(business.regCode)
        // TODO only set regCode and later determine name through that?
        vendorInputs.name.set(business.name)
    }

    return React.createElement(
        'div',
        { onClick, className: 'business-lookup__match' },
        highlight(business.name, search),
        React.createElement(
            'div',
            { className: 'business-lookup__match-note' },
            t.businessLookup.invoiceCount.get(),
            ': ',
            business.invoiceCount,
        ),
    )
}

const renderFoundBusinesses = (inputValues: InputValues, expenses: ApiExpense[]) => {
    const search = cleanString(vendorInputs.search.get(inputValues), true)
    const map: Record<string, SimpleBusiness> = {}

    for (const { vendor } of expenses) {
        const { regCode } = vendor

        if (!(regCode in map)) {
            if (!matches(search, normalizeBusinessName(vendor.name))) {
                continue
            }

            map[regCode] = { regCode, name: vendor.name, invoiceCount: 0 }
        }

        const business = map[regCode]
        business.invoiceCount += 1
    }

    const businesses = Object.keys(map).map((key) => map[key])

    if (businesses.length) {
        const businessElements = businesses
            // TODO secondary comparison by most recent date?
            .sort((b1, b2) => b2.invoiceCount - b1.invoiceCount)
            .slice(0, 5)
            .map((business) => renderFoundBusiness(business, search))

        return React.createElement(
            'div',
            { className: 'business-lookup__matches top-margin' },
            ...businessElements,
        )
    } else {
        return React.createElement('div', { className: 'top-margin' }, t.business.noneFound.get())
    }
}

const renderBusinessSearch = (inputValues: InputValues, expenses: ApiExpense[]) =>
    React.createElement(
        'div',
        null,
        React.createElement(Input, {
            input: vendorInputs.search,
            inputValues,
            placeholder: t.searchByName.get(),
        }),
        renderFoundBusinesses(inputValues, expenses),
    )

const renderBusinessChoice = (inputValues: InputValues, expenses: ApiExpense[]) => {
    const regCode = vendorInputs.regCode.get(inputValues)

    if (regCode) {
        const businessName = vendorInputs.name.get(inputValues)

        const onClick = () => {
            vendorInputs.regCode.set('')
            vendorInputs.name.set('')
        }

        return React.createElement(
            'span',
            null,
            t.chosenBusiness.get(),
            ': ',
            businessName,
            ' ',
            React.createElement(Button, {
                onClick,
                text: t.cancelSelection.get(),
                className: 'button--primary',
            }),
        )
    } else {
        return renderBusinessSearch(inputValues, expenses)
    }
}

export const ExpenseArchiveVendor: FC<RootData> = ({ expenseData: { expenses }, inputValues }) => {
    if (!expenses) {
        return React.createElement(LoadingPage)
    }

    return React.createElement(
        'div',
        { className: 'content-area' },
        React.createElement(ExpenseArchiveSidebar, { selected: 'vendor' }),
        React.createElement(
            'div',
            { className: 'content archive' },
            React.createElement(
                'div',
                null,
                React.createElement('h1', { className: 'title archive__title' }, t.business.get()),
                renderBusinessChoice(inputValues, expenses),
            ),
            React.createElement(
                'div',
                { className: 'top-margin' },
                React.createElement(
                    'a',
                    { className: 'button button--primary', href: '#/expenses/archive/types' },
                    t.forward.get(),
                ),
                ' ',
                React.createElement(
                    'a',
                    { className: 'button button--secondary', href: '#/expenses/archive/results' },
                    t.show.get(),
                ),
                ' ',
                renderClearFiltersButton('vendor', inputValues),
            ),
        ),
    )
}
