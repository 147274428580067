import lodashFind from 'lodash/find'
import { TDocumentDefinitions } from 'pdfmake/interfaces'

import { getIncomeAccountType } from '../../common/accounts'
import { moneyForms } from '../../common/enums'
import { findByDbId } from '../../common/find-by-db-id'
import { Language } from '../../common/i18n'
import { getPdfDefinition, RevenuePdfInputs } from '../../common/invoice-pdf-utils'
import { calculateRevenue, getCompanyVendorFields } from '../../common/invoice-utils'
import { getPaidAmount } from '../../common/payment-utils'
import { ServerError } from '../../common/server-error'
import { sort } from '../../common/sort'
import { Day } from '../../common/time'
import { AccountData } from '../../common/types/account'
import { ApiCompany } from '../../common/types/company'
import { WrappedWarning } from '../../common/types/errors'
import { Input, InputValues } from '../../common/types/inputs'
import {
    ApiCustomer,
    ApiRevenue,
    CreditRevenue,
    CreditRevenueData,
    DbRevenueItem,
    RevenueData,
    RevenueInput,
    RevenueItemInput,
    RevenueItemType,
} from '../../common/types/invoice'
import { ItemInputs } from '../../common/types/item'
import { ApiSettings } from '../../common/types/settings'
import { VatPeriod } from '../../common/types/vat'
import { VendorFields } from '../../common/types/vendor'
import { isVatPayerAt } from '../../common/vat-utils'
import * as api from '../api'
import { download } from '../download'
import { fromErrorCode, processWarning } from '../error-manager'
import { emitFocusInput, onNextNavigation, runAfterNextRender } from '../event-bus'
import { getNumeric } from '../get-numeric'
import { getCurrentLanguage, t } from '../i18n'
import { generateId } from '../id-utils'
import { buildDataUrlFromUrl } from '../image-utils'
import { setIfEmpty, setUnlessDefault } from '../input-utils'
import { inputs } from '../inputs'
import { generatePdf } from '../pdf-utils'
import { getCreditRevenuePdfFilename, getRevenuePdfFilename } from '../revenue-utils'
import { setRoute } from '../route-utils'
import { getRevenueVatInput } from '../vat-utils'
import { getByNumber } from './account-actions'
import { clearLookup } from './business-lookup-actions'
import { getCompany, loadCompany } from './company-actions'
import { prepareForm } from './form-actions'
import { clearInputs } from './input-actions'
import { setInvalid } from './invalid-cache-actions'
import {
    loadAccounts,
    loadCompanies,
    loadCreditRevenues,
    loadRevenues,
    loadSettings,
    loadUsersLimited,
    loadVatState,
} from './load-actions'
import { getPaymentInput, REMOVE_PAYMENT_PROCESS, SAVE_PAYMENT_PROCESS } from './payment-actions'
import { run } from './process-actions'
import { getSettings } from './settings-actions'
import { dispatch, getState } from './store'
import { clearErrors } from './validation-actions'

export const CONFIRM_PROCESS = 'invoice/confirm'
export const REMOVE_PROCESS = 'invoice/remove'
export const SAVE_PROCESS = 'invoice/save'
export const EXCEL_PROCESS = 'invoice/excel'
export const SEARCH_PROCESS = 'invoice/search'
export const PDF_PROCESS = 'invoice/pdf'

export const invalidateCache = () => setInvalid('invoice')

const getCustomerInput = (inputValues: InputValues): ApiCustomer => {
    const customerInputs = inputs.invoice.customer
    const customerType = customerInputs.type.get(inputValues)

    if (customerType === 'citizen') {
        return { isBusiness: false, details: customerInputs.details.get(inputValues) }
    } else if (customerType === 'business') {
        return {
            isBusiness: true,
            name: customerInputs.name.get(inputValues),
            regCode: customerInputs.regCode.get(inputValues),
            address: customerInputs.address.get(inputValues),
            vatId: customerInputs.vatId.get(inputValues),
        }
    } else {
        throw new Error('Unexpected customer type: ' + customerType)
    }
}

const getItemsInput = (
    inputValues: InputValues,
    itemIds: string[],
    vatPayer: boolean,
): RevenueItemInput[] => {
    return itemIds.map((id) => {
        const itemInputs = inputs.invoice.item(id)

        const item: RevenueItemInput = {
            id,
            type: itemInputs.type.get(inputValues),
            account: itemInputs.account.number.get(inputValues) || 1,
            description: itemInputs.description.get(inputValues),
            quantity: getNumeric(itemInputs.quantity, inputValues),
            unit: itemInputs.unit.get(inputValues) || undefined,
            unitPrice: getNumeric(itemInputs.unitPrice, inputValues),
            discount: getNumeric(itemInputs.discount, inputValues),
            vatPercentage: vatPayer ? getRevenueVatInput(itemInputs.vatPercentage, inputValues) : 0,
        }

        return item
    })
}

const getInput = async (
    inputValues: InputValues,
    itemIds: string[],
    vatPeriods: VatPeriod[],
): Promise<RevenueInput> => {
    const date = inputs.invoice.date.get(inputValues)
    const vatPayer = isVatPayerAt(vatPeriods, date)

    return {
        customer: getCustomerInput(inputValues),
        date,
        term: inputs.invoice.term.get(inputValues),
        items: getItemsInput(inputValues, itemIds, vatPayer),
        comment: inputs.invoice.comment.get(inputValues),
    }
}

const clearItems = () => {
    dispatch(({ invoiceData }) => (invoiceData.itemIds = []))
    clearInputs(inputs.invoice.item, true)
}

export const addItem = async (type: RevenueItemType) => {
    const company = await loadCompany()
    const { inputValues } = getState()

    const id = generateId()
    dispatch(({ invoiceData }) => invoiceData.itemIds.push(id))
    const itemInputs = inputs.invoice.item(id)

    itemInputs.type.set(type)
    itemInputs.description.set('')
    itemInputs.quantity.set('')
    itemInputs.unit.set('')
    itemInputs.unitPrice.set('')
    itemInputs.discount.set('')

    const date = inputs.invoice.date.get(inputValues)

    if (isVatPayerAt(company.vatPeriods, date)) {
        await loadSettings()
        const settings = getSettings(getState().settingsData)
        itemInputs.vatPercentage.set(String(settings.vatPercentage))
    }
}

export const removeItem = (id: string) => {
    dispatch(({ invoiceData }) => {
        const newIds = invoiceData.itemIds.filter((existing) => existing !== id)

        if (newIds.length === invoiceData.itemIds.length) {
            throw new Error('Item ' + id + ' not found')
        }

        invoiceData.itemIds = newIds
    })

    clearInputs(inputs.invoice.item(id), true)
}

export const getRevenueById = (data: RevenueData, id: string): ApiRevenue | undefined => {
    if (!data.invoices) {
        throw new Error('Invoices not loaded')
    }

    return findByDbId(data.invoices, id)
}

export const getCreditRevenueById = (
    data: CreditRevenueData,
    id: string,
): CreditRevenue | undefined => {
    if (!data.creditRevenues) {
        throw new Error('Credit revenues not loaded')
    }

    return findByDbId(data.creditRevenues, id)
}

export const syncDefaultDescription = (
    number: number,
    itemType: RevenueItemType,
    accountData: AccountData,
    itemInputs: ItemInputs<RevenueItemType>,
) => {
    if (number === 1) {
        itemInputs.description.setDefaultValue('')
    } else {
        const accountType = getIncomeAccountType(itemType)
        const account = getByNumber(accountData, accountType, number)!
        itemInputs.description.setDefaultValue(account.name)
    }
}

export const afterAccountChange = async (
    number: number,
    itemId: string,
    itemType: RevenueItemType,
    itemInputs: ItemInputs<RevenueItemType>,
) => {
    await loadAccounts()
    const { accountData } = getState()
    syncDefaultDescription(number, itemType, accountData, itemInputs)

    // The nodes aren't rendered/updated yet, so we wait until the next render
    runAfterNextRender(() => {
        // The focus event also updates the height for autoResize fields.
        // So we always emit this event for the description even if we actually
        // want to focus on the account input.
        // A bit hacky, but simpler than managing two separate event types.
        emitFocusInput('item-description-' + itemId)

        if (number === 1) {
            // We actually want to focus on the account input.
            emitFocusInput('item-account-' + itemId)
        }
    })
}

export const setLogVisible = (visible: boolean) =>
    dispatch(({ invoiceData }) => {
        invoiceData.logVisible = visible
    })

const getPdfInputs = async (
    revenue: ApiRevenue,
    company: ApiCompany,
    settings: ApiSettings,
): Promise<RevenuePdfInputs> => {
    let number = null
    let vendor: VendorFields<string>
    let vatPayer
    let logoUrl

    if (revenue.confirmed) {
        number = revenue.number!
        vendor = revenue.vendor!
        vatPayer = Boolean(revenue.vatPayer)
        logoUrl = revenue.logoUrl
    } else {
        vendor = getCompanyVendorFields(company)
        vatPayer = isVatPayerAt(company.vatPeriods, revenue.date)
        logoUrl = settings.logoUrl
    }

    const parsedDate = Day.fromYmd(revenue.date)
    const dueDate = parsedDate.addDays(revenue.term)

    return {
        number,
        customer: revenue.customer,
        vatPayer,
        date: parsedDate,
        dueDate,
        items: revenue.items,
        comment: revenue.comment || '',
        vendor,
        logoDataUrl: logoUrl ? await buildDataUrlFromUrl(logoUrl) : '',
    }
}

const getCreditRevenuePdfInputs = async (
    revenue: ApiRevenue,
    creditRevenue: CreditRevenue,
    language: Language,
): Promise<RevenuePdfInputs> => {
    const number = creditRevenue.number
    const vendor = revenue.vendor!
    const vatPayer = Boolean(revenue.vatPayer)
    const logoUrl = revenue.logoUrl

    const parsedDate = Day.fromYmd(creditRevenue.date)
    const dueDate = parsedDate.addDays(creditRevenue.term)

    return {
        number,
        customer: revenue.customer,
        vatPayer,
        date: parsedDate,
        dueDate,
        items: revenue.items.map((item) => ({ ...item, unitPrice: -item.unitPrice })),
        comment:
            t.revenues.creditRevenue.forRevenue.getForLanguage(language) + ' ' + revenue.number,
        vendor,
        logoDataUrl: logoUrl ? await buildDataUrlFromUrl(logoUrl) : '',
    }
}

export const createPreview = async (id: string, isCredit: boolean, language: Language) => {
    dispatch(({ invoiceData }) => (invoiceData.pdfBlobUrl = null))
    const promises = [loadRevenues(), loadCompanies(), loadSettings()]

    if (isCredit) {
        promises.push(loadCreditRevenues())
    }

    // TODO load only needed invoice?
    await Promise.all(promises)

    const { creditRevenueData, companyData, invoiceData, session, settingsData } = getState()
    const revenue = getRevenueById(invoiceData, id)

    if (!revenue) {
        throw new Error('Revenue ' + id + ' not found')
    }

    let definition: TDocumentDefinitions

    if (isCredit) {
        const creditRevenue = getCreditRevenueById(creditRevenueData, id)

        if (!creditRevenue) {
            throw new Error('Credit revenue ' + id + ' not found')
        }

        const creditRevenuePdfInputs = await getCreditRevenuePdfInputs(
            revenue,
            creditRevenue,
            language,
        )
        definition = getPdfDefinition(creditRevenuePdfInputs, language, true)
    } else {
        const company = getCompany(companyData, session)
        const settings = getSettings(settingsData)
        definition = getPdfDefinition(
            await getPdfInputs(revenue, company, settings),
            language,
            false,
        )
    }

    const url = await generatePdf(definition)

    dispatch((draft) => {
        draft.invoiceData.pdfBlobUrl = url
        draft.invoiceData.pdfLanguage = language
    })
}

export const initPreview = async (isCredit: boolean, id?: string) => {
    await createPreview(id || '', isCredit, getCurrentLanguage())

    onNextNavigation(() =>
        dispatch(({ invoiceData }) => {
            invoiceData.pdfBlobUrl = null
            invoiceData.pdfLanguage = null
        }),
    )
}

export const downloadPdf = async (id: string) =>
    run(PDF_PROCESS, async () => {
        // TODO load only needed invoice?
        await Promise.all([loadRevenues(), loadCompanies(), loadSettings()])

        const { companyData, invoiceData, session, settingsData } = getState()
        const company = getCompany(companyData, session)
        const revenue = getRevenueById(invoiceData, id)!
        const settings = getSettings(settingsData)

        const language = getCurrentLanguage()

        const definition = getPdfDefinition(
            await getPdfInputs(revenue, company, settings),
            language,
            false,
        )

        const url = await generatePdf(definition)
        const filename = getRevenuePdfFilename(company, revenue, language)
        download(url, filename)
    })

export const downloadCreditRevenuePdf = async (id: string) =>
    run(PDF_PROCESS, async () => {
        await Promise.all([loadRevenues(), loadCreditRevenues(), loadCompanies(), loadSettings()])

        const { companyData, invoiceData, session, creditRevenueData } = getState()
        const company = getCompany(companyData, session)
        const revenue = getRevenueById(invoiceData, id)!
        const creditRevenue = getCreditRevenueById(creditRevenueData, id)!

        const language = getCurrentLanguage()
        const pdfInputs = await getCreditRevenuePdfInputs(revenue, creditRevenue, language)
        const definition = getPdfDefinition(pdfInputs, language, true)

        const url = await generatePdf(definition)
        const filename = getCreditRevenuePdfFilename(company, creditRevenue.number, language)
        download(url, filename)
    })

const initItemInputs = (items: DbRevenueItem[], accountData: AccountData, isCredit: boolean) => {
    for (const item of items) {
        const itemInputs = inputs.invoice.item(item.id)
        syncDefaultDescription(item.account, item.type, accountData, itemInputs)
    }

    // Get updated default values
    const inputValues = getState().inputValues

    for (const item of items) {
        const itemInputs = inputs.invoice.item(item.id)
        itemInputs.type.set(item.type)
        itemInputs.account.number.set(item.account)
        setUnlessDefault(itemInputs.description, inputValues, item.description)
        setUnlessDefault(itemInputs.quantity, inputValues, String(item.quantity))
        itemInputs.unit.set(item.unit || '')
        const unitPrice = isCredit ? -item.unitPrice : item.unitPrice
        itemInputs.unitPrice.set(String(unitPrice))
        setUnlessDefault(itemInputs.discount, inputValues, String(item.discount))
        itemInputs.vatPercentage.set(String(item.vatPercentage))
    }

    dispatch(({ invoiceData }) => (invoiceData.itemIds = items.map((item) => item.id)))
}

export const getLatestRevenueToSameCompany = (
    revenues: ApiRevenue[] | null,
    inputValues: InputValues,
) => {
    if (revenues) {
        const regCode = inputs.invoice.customer.regCode.get(inputValues)

        const sameCompanyRevenues = revenues.filter((revenue) => {
            return revenue.customer.isBusiness && revenue.customer.regCode === regCode
        })

        if (sameCompanyRevenues.length) {
            sort(sameCompanyRevenues, [
                { getKey: (revenue) => revenue.date, reverse: true },
                { getKey: (revenue) => revenue._id }, // For deterministic order
            ])

            const [latestRevenue] = sameCompanyRevenues
            return latestRevenue
        }
    }

    return null
}

export const copyItemsFromRevenue = async (revenue: ApiRevenue) => {
    await loadAccounts()
    const { accountData } = getState()
    initItemInputs(revenue.items, accountData, false)
}

export const initForm = async (isNew: boolean, id?: string) =>
    prepareForm('invoice', async () => {
        clearErrors(SAVE_PROCESS)
        clearLookup()
        setLogVisible(false)

        if (!isNew) {
            // TODO also load users who have been removed from company but are linked to log entries.
            // Or return their names from revenue resolver?
            void loadUsersLimited()
        }

        const accountPromise = loadAccounts()

        await loadSettings()
        const { settingsData } = getState()
        const settings = getSettings(settingsData)
        inputs.invoice.term.setDefaultValue(String(settings.paymentTerm))

        const { inputValues } = getState()
        const canClear = inputs.invoice.canClear.get(inputValues)

        if (canClear) {
            clearItems()
        }

        // On a new revenue, we render the UI even if this hasn't finished yet.
        const revenuePromise = loadRevenues()
        await loadCreditRevenues()

        if (!isNew) {
            if (!id) {
                throw new Error('Missing ID')
            }

            await revenuePromise
            const { invoiceData } = getState()
            const revenue = getRevenueById(invoiceData, id)

            if (!revenue) {
                throw new WrappedWarning(
                    fromErrorCode('not-found', { collectionName: 'Invoices', id }),
                )
            }

            inputs.invoice.canClear.set(true)

            const { customer } = revenue
            const customerInputs = inputs.invoice.customer

            if (customer.isBusiness) {
                customerInputs.type.set('business')
                customerInputs.name.set(customer.name)
                customerInputs.regCode.set(customer.regCode)
                customerInputs.address.set(customer.address)
                customerInputs.vatId.set(customer.vatId)
            } else {
                customerInputs.type.set('citizen')
                customerInputs.details.set(customer.details)
            }

            inputs.invoice.rev.set(revenue.rev)
            inputs.invoice.date.set(revenue.date)
            setUnlessDefault(inputs.invoice.term, inputValues, String(revenue.term))
            inputs.invoice.comment.set(revenue.comment || '')

            await accountPromise
            const { accountData } = getState()
            initItemInputs(revenue.items, accountData, false)
        } else {
            // TODO refactor into an easily reusable util?

            // Keep values previously entered on an unsaved add form.
            // Clear values that have been saved or loaded for editing existing data.
            // Initialize empty fields with default values.

            if (canClear) {
                inputs.invoice.canClear.set(false)
            }

            const init = <T>(input: Input<T>, defaultValue: T) => {
                if (canClear || !input.hasValue(inputValues)) {
                    input.set(defaultValue)
                }
            }

            init(inputs.invoice.customer.type, '')
            init(inputs.invoice.date, Day.today().ymd())
            init(inputs.invoice.term, '')
            init(inputs.invoice.comment, '')
        }
    })

export const initCreditForm = async (isNew: boolean, id: string) =>
    prepareForm('invoice', async () => {
        clearErrors(SAVE_PROCESS)
        clearLookup()
        setLogVisible(false)

        if (!isNew) {
            // TODO also load users who have been removed from company but are linked to log entries.
            // Or return their names from revenue resolver?
            void loadUsersLimited()
        }

        const accountPromise = loadAccounts()

        await loadSettings()
        const { settingsData } = getState()
        const settings = getSettings(settingsData)
        inputs.invoice.term.setDefaultValue(String(settings.paymentTerm))

        const { inputValues } = getState()
        const canClear = inputs.invoice.canClear.get(inputValues)

        if (canClear) {
            clearItems()
        }

        await Promise.all([loadRevenues(), loadCreditRevenues()])
        const { invoiceData, creditRevenueData } = getState()
        const revenue = getRevenueById(invoiceData, id)

        if (!revenue) {
            throw new WrappedWarning(fromErrorCode('not-found', { collectionName: 'Invoices', id }))
        }

        const { customer } = revenue
        const customerInputs = inputs.invoice.customer

        if (customer.isBusiness) {
            customerInputs.type.set('business')
            customerInputs.name.set(customer.name)
            customerInputs.regCode.set(customer.regCode)
            customerInputs.address.set(customer.address)
            customerInputs.vatId.set(customer.vatId)
        } else {
            customerInputs.type.set('citizen')
            customerInputs.details.set(customer.details)
        }

        await accountPromise
        const { accountData } = getState()
        initItemInputs(revenue.items, accountData, true)

        if (!isNew) {
            const creditRevenue = getCreditRevenueById(creditRevenueData, id)

            if (!creditRevenue) {
                throw new WrappedWarning(
                    fromErrorCode('not-found', { collectionName: 'CreditRevenues', id }),
                )
            }

            inputs.invoice.canClear.set(true)
            inputs.invoice.date.set(revenue.date)
            setUnlessDefault(inputs.invoice.term, inputValues, String(revenue.term))
        } else {
            // TODO refactor into an easily reusable util?

            // Keep values previously entered on an unsaved add form.
            // Clear values that have been saved or loaded for editing existing data.
            // Initialize empty fields with default values.

            if (canClear) {
                inputs.invoice.canClear.set(false)
            }

            const init = <T>(input: Input<T>, defaultValue: T) => {
                if (canClear || !input.hasValue(inputValues)) {
                    input.set(defaultValue)
                }
            }

            init(inputs.invoice.date, Day.today().ymd())
            init(inputs.invoice.term, '')
        }
    })

export const create = async () =>
    run(SAVE_PROCESS, async () => {
        const company = await loadCompany()
        const {
            inputValues,
            invoiceData: { itemIds },
        } = getState()
        const revenue = await getInput(inputValues, itemIds, company.vatPeriods)
        const id = await api.createRevenue(revenue)
        invalidateCache()
        dispatch(({ invoiceData }) => (invoiceData.justCreatedId = id))
        setRoute('#/invoices/view/' + id)
        inputs.invoice.canClear.set(true)
    })

export const update = async (id: string) =>
    run(SAVE_PROCESS, async () => {
        const company = await loadCompany()
        const {
            inputValues,
            invoiceData: { itemIds },
        } = getState()
        const rev = inputs.invoice.rev.get(inputValues)
        const revenue = await getInput(inputValues, itemIds, company.vatPeriods)
        await api.updateRevenue(id, rev, revenue)
        invalidateCache()
        setRoute('#/invoices/view/' + id)
    })

export const confirmRevenue = async (id: string, rev: number) =>
    run(CONFIRM_PROCESS, async () => {
        await api.confirmRevenue(id, rev)
        invalidateCache()
        await loadRevenues()
    })

export const remove = async (id: string) =>
    run(REMOVE_PROCESS, async () => {
        if (getState().invoiceData.payment?.id === id) {
            // If the payment sidebar is open for this revenue, close it
            closePaymentForm()
        }

        try {
            await api.removeRevenue(id)
            invalidateCache()
            await loadRevenues()
        } catch (error) {
            if (error instanceof ServerError && error.response.errorCode === 'negative-balance') {
                const date = error.response.date as string

                const message = t.invoices.remove.negativeBalance.get(
                    Day.fromYmd(date).dmy(),
                    error.response.account,
                )

                processWarning(fromErrorCode('negative-balance').withMessage(message).dontReport())
            } else {
                throw error
            }
        }
    })

export const createCredit = async (id: string) =>
    run(SAVE_PROCESS, async () => {
        const company = await loadCompany()
        const {
            inputValues,
            invoiceData: { itemIds },
        } = getState()
        const revenue = await getInput(inputValues, itemIds, company.vatPeriods)
        await api.createCreditRevenue(id, revenue.date, revenue.term)
        setInvalid('credit-revenue')
        setRoute('#/credit-revenues/view/' + id)
        inputs.invoice.canClear.set(true)
    })

export const initRegister = () => {
    inputs.invoice.register.showAll.set(false)
    inputs.invoice.register.sort.set('number')

    void loadCompanies()
    void loadRevenues()
    void loadCreditRevenues()
    void loadVatState()

    if (getState().invoiceData.justCreatedId) {
        onNextNavigation(() => dispatch(({ invoiceData }) => (invoiceData.justCreatedId = null)))
    }
}

export const openPaymentForm = async (id: string, isCredit: boolean) => {
    clearErrors(SAVE_PAYMENT_PROCESS)

    // TODO loading state?
    if (isCredit) {
        await loadCreditRevenues()
        const creditRevenue = getCreditRevenueById(getState().creditRevenueData, id)!
        inputs.payment.rev.set(creditRevenue.rev)
    } else {
        await loadRevenues()
        const revenue = getRevenueById(getState().invoiceData, id)!
        inputs.payment.rev.set(revenue.rev)
    }

    inputs.payment.full.set('yes')
    inputs.payment.amount.set('')
    inputs.payment.moneyForm.set(moneyForms.bank)
    inputs.payment.date.set(Day.today().ymd())
    dispatch(({ invoiceData }) => (invoiceData.payment = { id, isCredit }))
}

export const closePaymentForm = () =>
    dispatch(({ invoiceData }) => {
        invoiceData.payment = null
    })

export const addPayment = async (id: string) =>
    run(SAVE_PAYMENT_PROCESS, async () => {
        const { invoiceData, inputValues } = getState()
        const revenue = getRevenueById(invoiceData, id)!
        const totals = calculateRevenue(revenue)
        const remaining = totals.payableWithVat - getPaidAmount(revenue.payments)

        const rev = inputs.payment.rev.get(inputValues)
        const payment = getPaymentInput(remaining)

        await api.addRevenuePayment(id, rev, payment)
        invalidateCache()
        await loadRevenues()
        closePaymentForm()
    })

export const addCreditPayment = async (id: string) =>
    run(SAVE_PAYMENT_PROCESS, async () => {
        const { creditRevenueData, invoiceData, inputValues } = getState()
        const origRevenue = getRevenueById(invoiceData, id)!
        const creditRevenue = getCreditRevenueById(creditRevenueData, id)!

        const origPaidAmt = getPaidAmount(origRevenue.payments)
        const creditPaidAmt = getPaidAmount(creditRevenue.payments)
        const remaining = origPaidAmt - creditPaidAmt

        const rev = inputs.payment.rev.get(inputValues)
        const payment = getPaymentInput(remaining)

        await api.addCreditRevenuePayment(id, rev, payment)
        setInvalid('credit-revenue')
        await loadCreditRevenues()
        closePaymentForm()
    })

export const removeRevenuePayment = async (revenueId: string, paymentId: string) =>
    run(REMOVE_PAYMENT_PROCESS, async () => {
        const { inputValues } = getState()
        const rev = inputs.payment.rev.get(inputValues)

        try {
            await api.removeRevenuePayment(revenueId, rev, paymentId)

            invalidateCache()
            await loadRevenues()

            const revenue = findByDbId(getState().invoiceData.invoices!, revenueId)!
            inputs.payment.rev.set(revenue.rev)
        } catch (error) {
            if (error instanceof ServerError && error.response.errorCode === 'negative-balance') {
                const date = error.response.date as string

                const message = t.payments.negativeBalance.onRemove.incoming.get(
                    Day.fromYmd(date).dmy(),
                    error.response.account,
                )

                processWarning(fromErrorCode('negative-balance').withMessage(message).dontReport())
            } else {
                throw error
            }
        }
    })

export const initUnpaidForm = async () =>
    prepareForm('unpaid-invoices', async () => {
        // TODO load only needed revenues?
        await Promise.all([loadCompanies(), loadRevenues(), loadCreditRevenues()])

        inputs.invoice.unpaid.date.set(Day.today().ymd())

        const { inputValues } = getState()
        setIfEmpty(inputs.invoice.unpaid.sort, inputValues, 'number')
    })

export const resetSearchByNumberForm = () => {
    dispatch(({ invoiceData }) => {
        invoiceData.searchByNumberStatus = { failed: false, searchText: '' }
    })

    inputs.invoice.searchNumber.set('')
}

export const searchByNumber = async () =>
    run(SEARCH_PROCESS, async () => {
        await loadRevenues() // TODO load only needed invoice(s)?
        const {
            inputValues,
            invoiceData: { invoices },
        } = getState()
        const searchText = inputs.invoice.searchNumber.get(inputValues)
        const revenue = lodashFind(invoices, (inv) => inv.confirmed && inv.number === searchText)

        dispatch(({ invoiceData }) => {
            invoiceData.searchByNumberStatus = { failed: !revenue, searchText }
        })

        if (revenue) {
            setRoute('#/invoices/view/' + revenue._id)

            // The search panel should be open when viewing the found revenue.
            // However, it should be closed if the user navigates away and opens the same revenue later.
            // Therefore we set up a callback that clears the search state when the user
            // navigates away from the revenue view.

            // Opening a different revenue via the search panel that is still open next to the previously
            // found revenue also counts as navigating away from it and triggers the callback.

            // Therefore some extra measures are required to keep the panel open:
            // * The callback must check whether the search state has changed in the meantime
            //   and skip its own cleanup if that is the case.
            // * If we register the new callback too early, it may get executed while leaving the
            //   previous revenue's view. We use runAfterNextRender to make sure we register the
            //   new callback at a time when it's safe.

            runAfterNextRender(() => {
                onNextNavigation(() => {
                    const newState = getState().invoiceData.searchByNumberStatus

                    if (!newState.failed && newState.searchText === searchText) {
                        // Search state has not changed, it is safe to perform the cleanup
                        resetSearchByNumberForm()
                    }
                })
            })
        } else {
            // In case the search was made next to a successful result, we need to navigate back here
            setRoute('#/invoices/search-by-number')
        }
    })

export const initSearchByNumberForm = () => {
    onNextNavigation(() => {
        const newState = getState().invoiceData.searchByNumberStatus

        // Clear failure state when navigating away.
        // On success, we navigate to revenue view and should retain state.
        if (newState.failed || !newState.searchText) {
            resetSearchByNumberForm()
        }
    })
}
