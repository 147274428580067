import React, { AnchorHTMLAttributes } from 'react'

import { t } from '../i18n'

interface Props {
    route?: string
}

const onClick = () => history.back() // Simulates browser's "Back" button

const getProps = (route: string | undefined): AnchorHTMLAttributes<HTMLAnchorElement> => {
    const common = { className: 'back-link', title: t.back.get() }
    return route ? { ...common, href: route } : { ...common, onClick }
}

export const BackLink = ({ route }: Props) =>
    React.createElement(
        'a',
        getProps(route),
        React.createElement('img', { src: '/icons/back.svg' }),
    )
