import { Day } from './time'
import { DbPayment } from './types/payment'

export const getPaidAmount = (payments: DbPayment[]) => {
    return payments.reduce((sum, payment) => sum + payment.amount, 0)
}

export const getDaysUntil = (from: string, to: string) => {
    return Day.fromYmd(to).diffDays(Day.fromYmd(from))
}

export const getDaysOverdue = (date: string, term: number) => {
    return getDaysUntil(date, Day.today().ymd()) - term
}

// TODO should return true also if equal to 0? And rename function to isDue?
export const isOverdue = (date: string, term: number) => getDaysOverdue(date, term) > 0
