import { formatAmount, formatAmountOrDash } from './format-amount'
import { t } from './i18n'

export const renderAmount = (amount: number) => {
    return isNaN(amount) ? t.notANumber.get() : formatAmount(amount) + ' €'
}

export const renderAmountOrDash = (amount: number) => {
    return isNaN(amount) ? t.notANumber.get() : formatAmountOrDash(amount) + ' €'
}
