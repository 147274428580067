import {
    CREDIT,
    DEBIT,
    FINANCIAL,
    getLevel1Number,
    INCOME_STATEMENT,
    INCOME_TAX,
    OPERATING_PROFIT,
} from './accounts'
import { getMinDay } from './company-utils'
import { getFiscalYearForCompany } from './fiscal-year-utils'
import { Day } from './time'
import { CommonCompanyFields } from './types/company'
import { IncomeTotals, Period, SimpleData } from './types/reports'

export const getBalanceTotals = (accounts: SimpleData<number>) => {
    let debit = 0,
        credit = 0

    for (const level4Number of Object.keys(accounts)) {
        const amount = Number(accounts[level4Number])
        const level1Number = getLevel1Number(level4Number)

        if (level1Number in DEBIT) {
            debit += amount
        } else if (level1Number in CREDIT) {
            credit += amount
        } else {
            throw new Error('Invalid account: ' + level4Number)
        }
    }

    return { debit, credit }
}

export const getIncomeTotals = (accounts: SimpleData<number>): IncomeTotals => {
    const totals: IncomeTotals = {
        operatingProfit: 0,
        profitBeforeTax: 0,
        netProfit: 0,
    }

    let financial = 0
    let incomeTax = 0

    for (const level4Number of Object.keys(accounts)) {
        const level1Number = getLevel1Number(level4Number)
        const amount = accounts[level4Number]

        if (level1Number in OPERATING_PROFIT) {
            // amount can be positive or negative
            totals.operatingProfit += amount
        } else if (level1Number in FINANCIAL) {
            // amount can be positive or negative
            financial += amount
        } else if (level1Number in INCOME_TAX) {
            if (amount > 0) {
                throw new Error('Unexpected positive income tax')
            }

            incomeTax += amount
        } else if (level1Number in INCOME_STATEMENT) {
            throw new Error('Unexpected income statement account: ' + level1Number)
        }
    }

    totals.profitBeforeTax = totals.operatingProfit + financial
    totals.netProfit = totals.profitBeforeTax + incomeTax
    return totals
}

export const getDefaultIncomePeriod = (company: CommonCompanyFields): Period => {
    const fiscalYear = getFiscalYearForCompany(Day.today(), company)
    const minDate = getMinDay(company.interimDate)
    const fiscalBeginOrMin = Day.max(minDate, fiscalYear.start)

    return {
        from: fiscalBeginOrMin.ymd(),
        to: Day.today().ymd(),
    }
}
