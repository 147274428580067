import { UserInput } from '../../common/types/user'
import * as api from '../api'
import { inputs } from '../inputs'
import { setRoute } from '../route-utils'
import { clearInputs } from './input-actions'
import { loadTerms } from './load-actions'
import { run } from './process-actions'
import { getState } from './store'
import { clearErrors } from './validation-actions'

export const SIGNUP_PROCESS = 'signup'

const termsInput = inputs.signup.showTerms

export const initSignup = () => {
    clearErrors(SIGNUP_PROCESS)
    clearInputs(inputs.signup)
    termsInput.set(false)
}

export const toggleTerms = () => {
    const { inputValues } = getState()
    const isVisible = termsInput.get(inputValues)

    if (!isVisible) {
        void loadTerms()
    }

    termsInput.set(!isVisible)
}

const getUserInput = (): UserInput => {
    const { inputValues } = getState()

    return {
        email: inputs.signup.email.get(inputValues),
        password: inputs.signup.password.get(inputValues),
        firstName: inputs.signup.firstName.get(inputValues),
        lastName: inputs.signup.lastName.get(inputValues),
    }
}

export const signUp = async () =>
    run(SIGNUP_PROCESS, async () => {
        const user = getUserInput()
        await api.signUp(user)
        setRoute('#/email-confirmation/pending/' + encodeURIComponent(user.email))
    })
