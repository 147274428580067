import { calculateRevenue } from '../../common/invoice-utils'
import { Day } from '../../common/time'
import { ValidationErrors } from '../../common/types/errors'
import { InputValues } from '../../common/types/inputs'
import { ApiRevenue } from '../../common/types/invoice'
import { Processes } from '../../common/types/processes'
import { PaymentProps } from '../components/payment'
import { t } from '../i18n'
import { REMOVE_PAYMENT_PROCESS, SAVE_PAYMENT_PROCESS } from '../state/payment-actions'
import { addPayment, closePaymentForm, removeRevenuePayment } from '../state/revenue-actions'
import { getPaymentProps, RawPaymentProps } from './payment-props'

export const getRevenuePaymentProps = (
    revenue: ApiRevenue,
    hasCreditRevenue: boolean,
    inputValues: InputValues,
    processes: Processes,
    validationErrors: ValidationErrors,
): PaymentProps => {
    const totals = calculateRevenue(revenue)

    const rawProps: RawPaymentProps = {
        labels: {
            main: t.payments.invoice.get(),
            amountSection: t.totals.revenue.get(),
            isFull: t.payments.invoice.isFull.get(),
            amount: t.payments.invoice.amount.get(),
        },
        incoming: true,
        minDate: Day.fromYmd(revenue.date),
        payments: revenue.payments,
        totals,
        totalToPay: totals.payableWithVat,
        inputValues,
        onSave: async () => addPayment(revenue._id),
        onClose: closePaymentForm,
        processes,
        saveProcessName: SAVE_PAYMENT_PROCESS,
        removeProcessName: REMOVE_PAYMENT_PROCESS,
        valErrors: validationErrors[SAVE_PAYMENT_PROCESS],
    }

    if (hasCreditRevenue) {
        rawProps.disabledText = t.payments.hasCreditRevenue.get()
    } else {
        rawProps.onRemove = async (paymentId) => removeRevenuePayment(revenue._id, paymentId)

        if (revenue.paid) {
            rawProps.disabledText = t.payments.fullyPaid.outgoing.get()
        }
    }

    return getPaymentProps(rawProps)
}
