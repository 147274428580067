import React from 'react'

import { Input } from '../common/types/inputs'
import { ButtonProps } from './components/button'
import { ExcelButton, ExcelButtonProps } from './components/excel-button'
import { t } from './i18n'

export const wrapExcelButton = (props: ExcelButtonProps) =>
    React.createElement(
        'div',
        { className: 'top-margin' },
        React.createElement(ExcelButton, { ...props }),
    )

export const getShowAllButtonProps = (input: Input<boolean>): ButtonProps => ({
    text: t.showMore.get(),
    onClick: () => input.set(true),
    className: 'button--primary',
})
