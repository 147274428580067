import React, { FC } from 'react'

import { t } from '../i18n'
import { RootData } from '../state/root-data'
import { LoadingIcon } from './loading-icon'

export const SelectCompany: FC<RootData> = ({ companyData: { companies } }) => {
    if (!companies) {
        return React.createElement(
            'div',
            { className: 'company-selection' },
            React.createElement(LoadingIcon, { color: 'white' }),
        )
    }

    if (!companies.length) {
        return React.createElement(
            'div',
            { className: 'company-selection' },
            React.createElement(
                'a',
                { href: '#/add-company', className: 'button company-selection__button' },
                t.addCompany.get(),
            ),
        )
    }

    return React.createElement(
        'div',
        { className: 'company-selection' },
        t.selectCompany.instructions.get(),
    )
}
