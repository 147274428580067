import classnames from 'classnames'
import React, { FC } from 'react'

import { formatAmount } from '../format-amount'

interface Props {
    max: number
    current: number
    radius?: number
    noViewBox?: true
    showText?: boolean
    className?: string
}

const renderTexts = (showText: boolean, ratio: number, current: number) => {
    if (!showText) {
        return []
    }

    return [
        React.createElement(
            'text',
            {
                className: 'percentage',
                x: '50%',
                y: '46%',
                textAnchor: 'middle',
                dominantBaseline: 'middle',
            },
            Math.round(ratio * 100) + '%',
        ),
        React.createElement(
            'text',
            {
                className: 'amount',
                x: '50%',
                y: '62%',
                textAnchor: 'middle',
                dominantBaseline: 'middle',
            },
            formatAmount(current) + ' €',
        ),
    ]
}

export const ArcGraph: FC<Props> = (props) => {
    const { max, current, noViewBox, showText = true, className } = props
    const radius = typeof props.radius === 'number' ? props.radius : 40

    const full = radius * 2 * Math.PI
    const ratio = max === 0 ? 0 : current / max
    const offset = (1 - ratio) * full

    const viewBoxSize = radius * 2.5
    const viewBox = noViewBox ? undefined : '0 0 ' + viewBoxSize + ' ' + viewBoxSize

    return React.createElement(
        'svg',
        { className: classnames('arc-graph', className), viewBox },
        React.createElement('circle', {
            cx: '50%',
            cy: '50%',
            r: radius,
            className: 'bg',
        }),
        React.createElement('circle', {
            cx: '50%',
            cy: '50%',
            r: radius,
            className: 'fg',
            strokeWidth: radius / 4,
            strokeDasharray: full,
            strokeDashoffset: offset,
        }),
        ...renderTexts(showText, ratio, current),
    )
}
