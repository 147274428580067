import React from 'react'

import { ArcGraph } from './arc-graph'

export interface RadialProgressProps {
    max: number
    current: number
}

export const RadialProgress = ({ max, current }: RadialProgressProps) =>
    React.createElement(ArcGraph, {
        current,
        max,
        radius: 8,
        noViewBox: true,
        showText: false,
        className: 'radial-progress',
    })
