import { Day } from '../common/time'
import { t } from './i18n'

export interface DateShortcut {
    label: string
    from: string
    to: string
}

export const getMonthShortcuts = (): DateShortcut[] => {
    const today = Day.today()

    return [
        {
            label: t.dateShortcut.currentMonth.get(),
            from: today.firstOfMonth().ymd(),
            to: today.lastOfMonth().ymd(),
        },
        {
            label: t.dateShortcut.previousMonth.get(),
            from: today.firstOfPreviousMonth().ymd(),
            to: today.lastOfPreviousMonth().ymd(),
        },
    ]
}

export const getYearShortcuts = (): DateShortcut[] => {
    const today = Day.today()

    return [
        {
            label: t.dateShortcut.currentYear.get(),
            from: today.firstOfYear().ymd(),
            to: today.lastOfYear().ymd(),
        },
        {
            label: t.dateShortcut.previousYear.get(),
            from: today.firstOfPreviousYear().ymd(),
            to: today.lastOfPreviousYear().ymd(),
        },
    ]
}
