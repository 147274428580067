import { Set as ImmutableSet } from 'immutable'
import React, { FC } from 'react'

import {
    canCreateEntry,
    canUseTestUtils,
    canViewAdminSection,
    canViewAllExpenses,
    canViewAllRevenues,
    canViewLogs,
    canViewReports,
    canViewSettings,
    canViewTaxes,
} from '../../common/access'
import { CompanyRole, CompanyStatus } from '../../common/enums'
import { ApiCompany } from '../../common/types/company'
import { MenuSection, MenuState } from '../../common/types/menu'
import { ApiSession } from '../../common/types/session'
import { t } from '../i18n'
import { toggleMenu, toggleSection } from '../state/menu-actions'
import { Link } from './link'
import { LoadingIcon } from './loading-icon'
import { MenuToggle } from './menu-toggle'

interface Props {
    session: ApiSession
    company?: ApiCompany
    menuState: MenuState
}

const renderLink = (route: string, text: string) =>
    React.createElement(
        'div',
        null,
        React.createElement(Link, { to: '#' + route, text, className: 'menu__link' }),
    )

const renderLinkIf = (visible: boolean, route: string, text: string) =>
    visible ? renderLink(route, text) : null

const renderSectionLink = (text: string, section: MenuSection) =>
    React.createElement(
        'a',
        { onClick: () => toggleSection(section), className: 'menu__link' },
        React.createElement('img', { src: '/menu/' + section + '.svg', className: 'menu__icon' }),
        React.createElement('span', { className: 'menu__item-text' }, text),
    )

const renderTaxesLink = (
    companyRole: CompanyRole | null,
    companyStatus: CompanyStatus,
    anyVatPeriods: boolean,
) => {
    if (anyVatPeriods && canViewTaxes(companyRole, companyStatus)) {
        return React.createElement(
            'h1',
            { className: 'menu__item' },
            React.createElement(
                'a',
                { href: '#/taxes/summary', className: 'menu__link' },
                React.createElement('img', { src: '/menu/taxes.svg', className: 'menu__icon' }),
                React.createElement('span', { className: 'menu__item-text' }, t.taxes.get()),
            ),
        )
    } else {
        return null
    }
}

const renderReportsSection = (
    companyRole: CompanyRole | null,
    visibleSections: ImmutableSet<string>,
    companyStatus: CompanyStatus,
) => {
    if (!canViewReports(companyRole, companyStatus)) {
        return []
    }

    const section = [
        React.createElement(
            'h1',
            { className: 'menu__item' },
            renderSectionLink(t.reports.get(), 'reports'),
        ),
    ]

    if (visibleSections.has('reports')) {
        section.push(
            React.createElement(
                'div',
                { className: 'menu__section' },
                renderLink('/reports/balance', t.reports.balance.get()),
                renderLink('/reports/income', t.reports.income.get()),
                renderLink('/reports/cash-flow', t.reports.cashFlow.get()),
                renderLink('/reports/turnover', t.reports.turnover.get()),
                renderLink('/reports/accounts', t.reports.accounts.get()),
                renderLink('/financial-history/balance/view', t.financialHistory.get()),
            ),
        )
    }

    return section
}

const renderEntriesSection = (companyRole: CompanyRole | null, companyStatus: CompanyStatus) => {
    if (!canCreateEntry(companyRole, companyStatus)) {
        return null
    }

    return React.createElement(
        'h1',
        { className: 'menu__item' },
        React.createElement(
            'a',
            { href: '#/entries/list', className: 'menu__link' },
            React.createElement('img', { src: '/menu/debit-credit.svg', className: 'menu__icon' }),
            React.createElement('span', { className: 'menu__item-text' }, t.entries.get()),
        ),
    )
}

const renderSettingsSection = (companyRole: CompanyRole | null, companyStatus: CompanyStatus) => {
    if (!canViewSettings(companyRole, companyStatus)) {
        return null
    }

    return React.createElement(
        'h1',
        { className: 'menu__item' },
        React.createElement(
            'a',
            { href: '#/settings', className: 'menu__link' },
            React.createElement('img', { src: '/menu/settings.svg', className: 'menu__icon' }),
            React.createElement('span', { className: 'menu__item-text' }, t.settings.get()),
        ),
    )
}

const renderAdminSection = (session: ApiSession, visibleSections: ImmutableSet<string>) => {
    if (canViewAdminSection(session.companyRole, session.globalRole)) {
        const logVisible = canViewLogs(session.globalRole)
        const testVisible = canUseTestUtils(session.companyRole)

        return React.createElement(
            'div',
            null,
            React.createElement(
                'h1',
                { className: 'menu__item' },
                renderSectionLink('Admin', 'admin'),
            ),
            visibleSections.has('admin')
                ? React.createElement(
                      'div',
                      { className: 'menu__section' },
                      renderLinkIf(logVisible, '/command-log', 'Log'),
                      renderLinkIf(testVisible, '/texts', 'Tekstid'),
                      renderLinkIf(testVisible, '/test', 'Testimine'),
                  )
                : null,
        )
    } else {
        return null
    }
}

export const Menu: FC<Props> = ({ session, company, menuState: { visibleSections } }) => {
    if (!company) {
        return React.createElement(LoadingIcon, { color: 'white' })
    }

    const { companyRole } = session
    const allExpensesVisible = canViewAllExpenses(companyRole, company.status)
    const allRevenuesVisible = canViewAllRevenues(companyRole, company.status)

    return React.createElement(
        'div',
        null,
        React.createElement(MenuToggle, { onClick: toggleMenu }),
        React.createElement(
            'h1',
            { className: 'menu__item' },
            React.createElement(
                'a',
                { href: '#/', className: 'menu__link' },
                React.createElement('img', {
                    src: '/menu/front-page.svg',
                    className: 'menu__icon',
                }),
                React.createElement('span', { className: 'menu__item-text' }, t.frontPage.get()),
            ),
        ),
        React.createElement(
            'h1',
            { className: 'menu__item' },
            renderSectionLink(t.incomes.get(), 'incomes'),
        ),
        visibleSections.has('incomes')
            ? React.createElement(
                  'div',
                  { className: 'menu__section' },
                  renderLink('/invoices/add', t.revenues.add.get()),
                  renderLink('/invoices/register', t.revenues.register.get()),
                  renderLinkIf(
                      allRevenuesVisible,
                      '/invoices/unpaid',
                      t.revenues.unpaidInvoices.get(),
                  ),
                  renderLinkIf(
                      allRevenuesVisible,
                      '/invoices/archive/general',
                      t.revenues.archive.get(),
                  ),
                  renderLinkIf(
                      allRevenuesVisible,
                      '/invoices/search-by-number',
                      t.revenues.searchByNumber.get(),
                  ),
              )
            : null,
        React.createElement(
            'h1',
            { className: 'menu__item' },
            renderSectionLink(t.expenses.get(), 'expenses'),
        ),
        visibleSections.has('expenses')
            ? React.createElement(
                  'div',
                  { className: 'menu__section' },
                  renderLink('/expenses/add/regular', t.expenses.add.get()),
                  renderLink('/expenses/register', t.expenses.register.get()),
                  renderLinkIf(
                      allExpensesVisible,
                      '/expenses/unpaid',
                      t.expenses.unpaidInvoices.get(),
                  ),
                  renderLinkIf(allExpensesVisible, '/expenses/assets', t.asset.get()),
                  renderLinkIf(allExpensesVisible, '/expenses/stock', t.expenses.stock.get()),
                  renderLinkIf(allExpensesVisible, '/expenses/goods', t.expenses.goods.get()),
                  renderLinkIf(allExpensesVisible, '/expenses/general', t.expenses.general.get()),
                  renderLink('/labour-costs', t.labourCosts.get()),
                  renderLinkIf(
                      allExpensesVisible,
                      '/expenses/archive/general',
                      t.expenses.archive.get(),
                  ),
              )
            : null,
        renderTaxesLink(companyRole, company.status, company.vatPeriods.length > 0),
        ...renderReportsSection(companyRole, visibleSections, company.status),
        renderEntriesSection(companyRole, company.status),
        renderSettingsSection(companyRole, company.status),
        renderAdminSection(session, visibleSections),
    )
}
