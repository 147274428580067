import { canChangeAssetValues } from '../../common/access'
import { getExpenseAccountType } from '../../common/accounts'
import { amountEquals } from '../../common/amount-equals'
import { MAX_UPLOAD_MB, MB } from '../../common/constants'
import {
    assetChangeModes,
    assetTypes,
    calculationModes,
    expenseItemTypes,
    expenseTypes,
} from '../../common/enums'
import { calculateExpenseInitial } from '../../common/expense-utils'
import { findByDbId } from '../../common/find-by-db-id'
import { findById } from '../../common/find-by-id'
import {
    calculateAssetCurrent,
    calculateTotalWithoutVat,
    getItemQuantity,
    getItemUnitPrice,
    isAutomaticItemArray,
    isManualItemArray,
} from '../../common/item-utils'
import { getPaidAmount } from '../../common/payment-utils'
import { ServerError } from '../../common/server-error'
import { sort } from '../../common/sort'
import { Day } from '../../common/time'
import { AccountData } from '../../common/types/account'
import { AssetChangeMode, AssetType, CalculationMode } from '../../common/types/enums'
import { WrappedWarning } from '../../common/types/errors'
import {
    ApiExpense,
    AssetChangeParams,
    AssetInput,
    AssetValueChange,
    AutomaticExpenseItem,
    AutomaticExpenseItemInput,
    ExpenseData,
    ExpenseFile,
    ExpenseInput,
    ExpenseItem,
    ExpenseItemInput,
    ExpenseItemType,
    ExpenseRegion,
    ExpenseTotals,
    ExpenseUpdate,
    ManualExpenseItem,
    ManualExpenseItemInput,
    PendingAssetChange,
    PendingStockChangeItem,
} from '../../common/types/expense'
import { Input, InputValues } from '../../common/types/inputs'
import { ItemInputs, Totals } from '../../common/types/item'
import { VatPeriod } from '../../common/types/vat'
import { isVatPayerAt } from '../../common/vat-utils'
import { amountFromString } from '../amount-from-string'
import * as api from '../api'
import { fromErrorCode, processWarning } from '../error-manager'
import { emitFocusInput, onNextNavigation, runAfterNextRender } from '../event-bus'
import { fileToBase64 } from '../file-utils'
import { formatAmountForInput } from '../format-amount-for-input'
import { getNumeric } from '../get-numeric'
import { t } from '../i18n'
import { generateId } from '../id-utils'
import { getIfNotDefault, setUnlessDefault } from '../input-utils'
import { inputs } from '../inputs'
import { calculateTotalsFromAssetInputs, calculateTotalsFromItemInputs } from '../item-utils'
import { normalizeNumber } from '../number-utils'
import { setRoute } from '../route-utils'
import { getExpenseVatInput } from '../vat-utils'
import { getByNumber } from './account-actions'
import { clearLookup } from './business-lookup-actions'
import { loadCompany } from './company-actions'
import { prepareForm } from './form-actions'
import { clearInputs } from './input-actions'
import { setInvalid } from './invalid-cache-actions'
import {
    loadAccounts,
    loadCompanies,
    loadExpenses,
    loadLabourCosts,
    loadPendingAssetChanges,
    loadPendingStockChanges,
    loadServerConf,
    loadUsersLimited,
    loadVatState,
} from './load-actions'
import {
    closeExpensePaymentForm,
    getPaymentInput,
    REMOVE_PAYMENT_PROCESS,
    SAVE_PAYMENT_PROCESS,
} from './payment-actions'
import { run } from './process-actions'
import { dispatch, getState } from './store'
import { clearErrors } from './validation-actions'

export const CONFIRM_PROCESS = 'expense/confirm'
export const REMOVE_PROCESS = 'expense/remove'
export const SAVE_PROCESS = 'expense/save'
export const UPLOAD_PROCESS = 'expense/upload'
export const REMOVE_FILE_PROCESS_PREFIX = 'expense/remove-file/'

export const CONFIRM_ASSET_CHANGE_PROCESS = 'expense/asset-change/confirm'
export const REMOVE_ASSET_CHANGE_PROCESS = 'expense/asset-change/remove'
export const SAVE_ASSET_CHANGE_PROCESS = 'expense/asset-change/save'

export const CONFIRM_STOCK_CHANGE_PROCESS = 'expense/stock-change/confirm'
export const REMOVE_STOCK_CHANGE_PROCESS = 'expense/stock-change/remove'
export const SAVE_STOCK_CHANGE_PROCESS = 'expense/stock-change/save'

export const invalidateCache = () => setInvalid('expense')
export const invalidateAssetChangeCache = () => setInvalid('pending-asset-change')
export const invalidateStockChangeCache = () => setInvalid('pending-stock-change')

const getRegionFromInputs = (inputValues: InputValues) => {
    const regionInputs = inputs.expense.vendor.region

    if (regionInputs.ee.get(inputValues) === 'yes') {
        return ExpenseRegion.ee
    } else if (regionInputs.eu.get(inputValues) === 'yes') {
        return ExpenseRegion.eu
    } else {
        return ExpenseRegion.other
    }
}

const getVendorInput = (inputValues: InputValues) => {
    const vendorInputs = inputs.expense.vendor

    return {
        region: getRegionFromInputs(inputValues),
        name: vendorInputs.name.get(inputValues),
        regCode: vendorInputs.regCode.get(inputValues),
        address: vendorInputs.address.get(inputValues),
        vatId: vendorInputs.vatId.get(inputValues),
    }
}

const getOptionalNumeric = (input: Input<string>, inputValues: InputValues) => {
    const value = getNumeric(input, inputValues)
    return value === '' ? '0' : value
}

const getNumericIfNotDefault = (input: Input<string>, inputValues: InputValues) => {
    const value = getIfNotDefault(input, inputValues)
    return typeof value === 'string' ? normalizeNumber(value) : value
}

const getCommonItemInput = (
    id: string,
    itemInputs: ItemInputs<ExpenseItemType>,
    inputValues: InputValues,
    vatPayer: boolean,
) => ({
    id,
    type: itemInputs.type.get(inputValues),
    account: itemInputs.account.number.get(inputValues) || 1,
    description: itemInputs.description.get(inputValues),
    quantity: getNumeric(itemInputs.quantity, inputValues),
    unit: itemInputs.unit.get(inputValues) || undefined,
    discount: '0',
    vatPercentage: vatPayer ? getExpenseVatInput(itemInputs.vatPercentage, inputValues) : 0,
})

const getItemsInput = async (
    calculationMode: CalculationMode,
    inputValues: InputValues,
    itemIds: string[],
    vatPayer: boolean,
): Promise<ExpenseItemInput[]> => {
    let getInput: (id: string, itemInputs: ItemInputs<ExpenseItemType>) => ExpenseItemInput

    if (calculationMode === calculationModes.automatic) {
        getInput = (id, itemInputs): AutomaticExpenseItemInput => ({
            ...getCommonItemInput(id, itemInputs, inputValues, vatPayer),
            unitPrice: getNumeric(itemInputs.unitPrice, inputValues),
        })
    } else if (calculationMode === calculationModes.manual) {
        getInput = (id, itemInputs): ManualExpenseItemInput => ({
            ...getCommonItemInput(id, itemInputs, inputValues, vatPayer),
            payableWithoutVat: getNumeric(itemInputs.payableWithoutVat!, inputValues),
        })
    } else {
        throw new Error('Unexpected calculation mode: ' + calculationMode)
    }

    return itemIds.map((id) => {
        const itemInputs = inputs.expense.item(id)
        return getInput(id, itemInputs)
    })
}

const getAssetsInput = async (assetIds: string[], inputValues: InputValues, vatPayer: boolean) =>
    assetIds.map((id) => {
        const assetInputs = inputs.expense.asset(id)
        const type = assetInputs.type.get(inputValues)

        const asset: AssetInput = {
            id,
            type,
            description: assetInputs.description.get(inputValues),
            totals: {
                withoutVat: getNumeric(assetInputs.withoutVat, inputValues),
                other: getOptionalNumeric(assetInputs.other, inputValues),
                vatPercentage: vatPayer
                    ? getExpenseVatInput(assetInputs.vatPercentage, inputValues)
                    : 0,
            },
        }

        if (type !== assetTypes.land) {
            asset.amortBegin = assetInputs.amortBegin.get(inputValues)
            const lifetime = assetInputs.lifetime.get(inputValues)
            asset.eolDate = Day.fromYmd(asset.amortBegin).addYears(lifetime).ymd()
        }

        return asset
    })

const getManualTotals = async (
    inputValues: InputValues,
    vatPayer: boolean,
): Promise<ExpenseTotals<string>> => {
    const { withVat } = inputs.expense.totals
    const vatAmount = vatPayer ? getNumeric(inputs.expense.totals.vat, inputValues) : '0'

    return {
        vat: vatAmount,
        payableWithVat: getNumeric(withVat, inputValues),
    }
}

const getRegularUpdate = async (
    inputValues: InputValues,
    itemIds: string[],
    vatPeriods: VatPeriod[],
): Promise<ExpenseUpdate> => {
    const calculationMode = inputs.expense.calculationMode.get(inputValues)
    const date = inputs.expense.date.get(inputValues)
    const vatPayer = isVatPayerAt(vatPeriods, date)

    const update: ExpenseUpdate = {
        calculationMode,
        number: inputs.expense.number.get(inputValues),
        date,
        dueDate: inputs.expense.dueDate.get(inputValues),
        vendor: getVendorInput(inputValues),
        items: await getItemsInput(calculationMode, inputValues, itemIds, vatPayer),
        comment: inputs.expense.comment.get(inputValues),
    }

    if (calculationMode === calculationModes.manual) {
        update.totals = await getManualTotals(inputValues, vatPayer)
    }

    return update
}

const getRegularInput = async (
    inputValues: InputValues,
    itemIds: string[],
    vatPeriods: VatPeriod[],
): Promise<ExpenseInput> => {
    const regularUpdate = await getRegularUpdate(inputValues, itemIds, vatPeriods)
    return { type: expenseTypes.regular, ...regularUpdate }
}

const getAssetUpdate = async (
    assetIds: string[],
    inputValues: InputValues,
    vatPeriods: VatPeriod[],
): Promise<ExpenseUpdate> => {
    const calculationMode = inputs.expense.calculationMode.get(inputValues)
    const date = inputs.expense.date.get(inputValues)
    const vatPayer = isVatPayerAt(vatPeriods, date)

    const update: ExpenseUpdate = {
        // TODO undup?
        calculationMode: inputs.expense.calculationMode.get(inputValues),
        number: inputs.expense.number.get(inputValues),
        date,
        dueDate: inputs.expense.dueDate.get(inputValues),
        vendor: getVendorInput(inputValues),
        assets: await getAssetsInput(assetIds, inputValues, vatPayer),
        comment: inputs.expense.comment.get(inputValues),
    }

    if (calculationMode === calculationModes.manual) {
        update.totals = await getManualTotals(inputValues, vatPayer)
    }

    return update
}

const getAssetInput = async (
    assetIds: string[],
    inputValues: InputValues,
    vatPeriods: VatPeriod[],
): Promise<ExpenseInput> => {
    const assetUpdate = await getAssetUpdate(assetIds, inputValues, vatPeriods)
    return { type: expenseTypes.asset, ...assetUpdate }
}

const clearItems = () => {
    dispatch(({ expenseData }) => (expenseData.itemIds = []))
    clearInputs(inputs.expense.item, true)
}

export const addItem = (type: ExpenseItemType) => {
    const id = generateId()
    dispatch(({ expenseData }) => expenseData.itemIds.push(id))
    const itemInputs = inputs.expense.item(id)

    itemInputs.type.set(type)
    itemInputs.description.set('')
    itemInputs.quantity.set('')
    itemInputs.unit.set('')
    itemInputs.unitPrice.set('')
    itemInputs.vatPercentage.set('22')
}

export const removeItem = (id: string) => {
    dispatch(({ expenseData }) => {
        const newIds = expenseData.itemIds.filter((existing) => existing !== id)

        if (newIds.length === expenseData.itemIds.length) {
            throw new Error('Item ' + id + ' not found')
        }

        expenseData.itemIds = newIds
    })

    clearInputs(inputs.expense.item(id), true)
}

export const clearAssets = () => {
    dispatch(({ expenseData }) => (expenseData.assetIds = []))
    clearInputs(inputs.expense.asset, true)
}

export const addAsset = (type: AssetType) => {
    const { inputValues } = getState()

    const id = generateId()
    dispatch(({ expenseData }) => expenseData.assetIds.push(id))

    const expenseDate = inputs.expense.date.get(inputValues)

    const assetInputs = inputs.expense.asset(id)
    assetInputs.description.set('')
    assetInputs.withoutVat.set('')
    assetInputs.type.set(type)
    assetInputs.amortBegin.set(expenseDate)
    assetInputs.vatPercentage.set('22')

    if (type !== assetTypes.land) {
        assetInputs.lifetime.set(1)
    }
}

export const removeAsset = (id: string) => {
    dispatch(({ expenseData }) => {
        const newIds = expenseData.assetIds.filter((existing) => existing !== id)

        if (newIds.length === expenseData.assetIds.length) {
            throw new Error('Asset ' + id + ' not found')
        }

        expenseData.assetIds = newIds
    })

    clearInputs(inputs.expense.asset(id), true)
}

export const getById = (data: ExpenseData, id: string): ApiExpense | undefined => {
    if (!data.expenses) {
        throw new Error('Expenses not loaded')
    }

    return findByDbId(data.expenses, id)
}

export const syncDefaultDescription = (
    number: number,
    itemType: ExpenseItemType,
    accountData: AccountData,
    itemInputs: ItemInputs<ExpenseItemType>,
) => {
    if (number === 1) {
        itemInputs.description.setDefaultValue('')
    } else {
        const accountType = getExpenseAccountType(itemType)
        const account = getByNumber(accountData, accountType, number)!
        itemInputs.description.setDefaultValue(account.name)
    }
}

export const afterAccountChange = async (
    number: number,
    itemId: string,
    itemType: ExpenseItemType,
    itemInputs: ItemInputs<ExpenseItemType>,
) => {
    await loadAccounts()
    const { accountData } = getState()
    syncDefaultDescription(number, itemType, accountData, itemInputs)

    // The nodes aren't rendered/updated yet, so we wait until the next render
    runAfterNextRender(() => {
        // The focus event also updates the height for autoResize fields.
        // So we always emit this event for the description even if we actually
        // want to focus on the account input.
        // A bit hacky, but simpler than managing two separate event types.
        emitFocusInput('item-description-' + itemId)

        if (number === 1) {
            // We actually want to focus on the account input.
            emitFocusInput('item-account-' + itemId)
        }
    })
}

export const setLogVisible = (visible: boolean) =>
    dispatch(({ expenseData }) => {
        expenseData.logVisible = visible
    })

const clearTotalMismatch = () => dispatch(({ expenseData }) => (expenseData.totalsMismatch = null))

const initCommonItemInput = (item: ExpenseItem<number>, accountData: AccountData) => {
    const itemInputs = inputs.expense.item(item.id)
    syncDefaultDescription(item.account, item.type, accountData, itemInputs)

    // Get updated default values
    const { inputValues } = getState()

    itemInputs.type.set(item.type)
    itemInputs.account.number.set(item.account)
    setUnlessDefault(itemInputs.description, inputValues, item.description)
    setUnlessDefault(itemInputs.quantity, inputValues, String(item.quantity))
    itemInputs.unit.set(item.unit || '')
    itemInputs.vatPercentage.set(String(item.vatPercentage))
}

const initAutomaticItemInputs = (
    items: AutomaticExpenseItem<number>[],
    accountData: AccountData,
) => {
    for (const item of items) {
        initCommonItemInput(item, accountData)
        const itemInputs = inputs.expense.item(item.id)
        itemInputs.unitPrice.set(String(item.unitPrice))
    }
}

const initManualItemInputs = (items: ManualExpenseItem<number>[], accountData: AccountData) => {
    for (const item of items) {
        initCommonItemInput(item, accountData)
        const itemInputs = inputs.expense.item(item.id)
        itemInputs.payableWithoutVat.set(String(item.payableWithoutVat))
    }
}

const initItemInputs = (
    calculationMode: CalculationMode,
    items: ExpenseItem<number>[],
    accountData: AccountData,
) => {
    let itemIds: string[]

    if (isAutomaticItemArray(items, calculationMode)) {
        initAutomaticItemInputs(items, accountData)
        itemIds = items.map((item) => item.id)
    } else if (isManualItemArray(items, calculationMode)) {
        initManualItemInputs(items, accountData)
        itemIds = items.map((item) => item.id)
    } else {
        throw new Error('Unexpected expense calculation mode: ' + calculationMode)
    }

    dispatch(({ expenseData }) => (expenseData.itemIds = itemIds))
}

export const getLatestExpenseFromSameCompany = (
    expenses: ApiExpense[] | null,
    inputValues: InputValues,
) => {
    if (expenses) {
        const regCode = inputs.expense.vendor.regCode.get(inputValues)

        const sameCompanyExpenses = expenses.filter(
            (expense) =>
                expense.type === expenseTypes.regular && expense.vendor.regCode === regCode,
        )

        if (sameCompanyExpenses.length) {
            sort(sameCompanyExpenses, [
                { getKey: (expense) => expense.date, reverse: true },
                { getKey: (expense) => expense._id }, // For deterministic order
            ])

            const [latestExpense] = sameCompanyExpenses
            return latestExpense
        }
    }

    return null
}

export const copyItemsFromExpense = async (expense: ApiExpense) => {
    await loadAccounts()
    const { accountData } = getState()
    const { calculationMode } = expense
    inputs.expense.calculationMode.set(calculationMode)
    initItemInputs(calculationMode, expense.items!, accountData)
}

const loadFileStorageUsage = async () => {
    dispatch(({ expenseData }) => (expenseData.fileStorageUsage = null))
    const usage = await api.getExpenseFileStorageUsage()
    dispatch(({ expenseData }) => (expenseData.fileStorageUsage = usage))
}

export const initForm = async (isNew: boolean, id: string) =>
    prepareForm('expense', async () => {
        clearErrors(SAVE_PROCESS)
        clearLookup()
        setLogVisible(false)
        clearTotalMismatch()

        const { inputValues } = getState()
        const canClear = inputs.expense.canClear.get(inputValues)

        if (canClear) {
            clearItems()
            clearAssets()
        }

        if (!isNew) {
            // TODO also load users who have been removed from company but are linked to log entries
            void loadUsersLimited()
        }

        const accountPromise = loadAccounts()
        void loadCompanies()

        // On a new expense, we render the UI even if this hasn't finished yet.
        const expensePromise = loadExpenses()

        if (!isNew) {
            if (!id) {
                throw new Error('Missing ID')
            }

            await expensePromise
            const expense = getById(getState().expenseData, id)

            if (!expense) {
                throw new WrappedWarning(
                    fromErrorCode('not-found', { collectionName: 'Expenses', id }),
                )
            }

            void loadServerConf()
            void loadFileStorageUsage()

            inputs.expense.canClear.set(true)

            const { calculationMode } = expense

            inputs.expense.rev.set(expense.rev)
            inputs.expense.calculationMode.set(calculationMode)
            inputs.expense.number.set(expense.number)
            inputs.expense.date.set(expense.date)
            inputs.expense.dueDate.set(expense.dueDate)
            inputs.expense.comment.set(expense.comment || '')

            const { vendor } = expense
            const vendorInputs = inputs.expense.vendor
            vendorInputs.region.ee.set(vendor.region === ExpenseRegion.ee ? 'yes' : 'no')
            vendorInputs.region.eu.set(vendor.region === ExpenseRegion.other ? 'no' : 'yes')
            vendorInputs.name.set(vendor.name)
            vendorInputs.regCode.set(vendor.regCode)
            vendorInputs.address.set(vendor.address)
            vendorInputs.vatId.set(vendor.vatId)

            if (expense.type === expenseTypes.regular) {
                await accountPromise
                const { accountData } = getState()
                initItemInputs(expense.calculationMode, expense.items!, accountData)
            } else if (expense.type === expenseTypes.asset) {
                for (const asset of expense.assets!) {
                    const assetInputs = inputs.expense.asset(asset.id)
                    assetInputs.type.set(asset.type)
                    assetInputs.description.set(asset.description)
                    assetInputs.withoutVat.set(String(asset.totals.withoutVat))
                    assetInputs.other.set(
                        asset.totals.other === 0 ? '' : String(asset.totals.other),
                    )
                    assetInputs.vatPercentage.set(String(asset.totals.vatPercentage))

                    if (asset.type !== assetTypes.land) {
                        // TODO Day.diffYears?
                        const lifetime = Day.fromYmd(asset.eolDate!)
                            .toMoment()
                            .diff(asset.amortBegin, 'years')
                        assetInputs.lifetime.set(lifetime)
                        assetInputs.amortBegin.set(asset.amortBegin!)
                    }
                }

                dispatch(({ expenseData }) => {
                    expenseData.assetIds = expense.assets!.map((asset) => asset.id)
                })
            } else {
                throw new Error('Unexpected expense type: ' + expense.type)
            }

            if (calculationMode === calculationModes.automatic) {
                if (!expense.confirmed) {
                    const totals = calculateExpenseInitial(expense)
                    inputs.expense.totals.vat.set(formatAmountForInput(totals.vatAmount))
                    inputs.expense.totals.withVat.set(formatAmountForInput(totals.payableWithVat))
                }
            } else if (calculationMode === calculationModes.manual) {
                const totals = expense.totals!
                inputs.expense.totals.vat.set(formatAmountForInput(totals.vat))
                inputs.expense.totals.withVat.set(formatAmountForInput(totals.payableWithVat))
            } else {
                throw new Error('Unexpected calculation mode: ' + calculationMode)
            }
        } else {
            // TODO refactor into an easily reusable util?

            // Keep values previously entered on an unsaved add form.
            // Clear values that have been saved or loaded for editing existing data.
            // Initialize empty fields with default values.

            if (canClear) {
                inputs.expense.canClear.set(false)
            }

            const init = <T>(input: Input<T>, defaultValue: T) => {
                if (canClear || !input.hasValue(inputValues)) {
                    input.set(defaultValue)
                }
            }

            const todayYmd = Day.today().ymd()

            init(inputs.expense.number, '')
            init(inputs.expense.date, todayYmd)
            init(inputs.expense.dueDate, todayYmd)
            init(inputs.expense.comment, '')
            init(inputs.expense.calculationMode, calculationModes.automatic)

            const vendorInputs = inputs.expense.vendor
            init(vendorInputs.region.ee, 'yes')
            init(vendorInputs.region.eu, 'yes')
            init(vendorInputs.name, '')
            init(vendorInputs.regCode, '')
            init(vendorInputs.address, '')
            init(vendorInputs.vatId, '')

            inputs.expense.totals.vat.set('')
            inputs.expense.totals.withVat.set('')
        }
    })

const toggleCalculationMode = (inputValues: InputValues) => {
    const calculationModeInput = inputs.expense.calculationMode
    const currentCalculationMode = calculationModeInput.get(inputValues)

    if (currentCalculationMode === calculationModes.automatic) {
        calculationModeInput.set(calculationModes.manual)
    } else if (currentCalculationMode === calculationModes.manual) {
        calculationModeInput.set(calculationModes.automatic)
    } else {
        throw new Error('Unexpected current calculation mode: ' + currentCalculationMode)
    }

    clearTotalMismatch()
}

const convertItemAutomaticToManual = (id: string, inputValues: InputValues) => {
    const rowInputs = inputs.expense.item(id)
    const quantity = amountFromString(rowInputs.quantity.get(inputValues))
    const unitPrice = amountFromString(rowInputs.unitPrice.get(inputValues))
    const payableWithoutVat = calculateTotalWithoutVat(quantity, unitPrice)

    if (payableWithoutVat === 0) {
        rowInputs.payableWithoutVat.clear()
    } else {
        rowInputs.payableWithoutVat.set(formatAmountForInput(payableWithoutVat))
    }
}

const convertItemManualToAutomatic = (id: string, inputValues: InputValues) => {
    const rowInputs = inputs.expense.item(id)
    const payableWithoutVat = amountFromString(rowInputs.payableWithoutVat.get(inputValues))
    const quantity = amountFromString(rowInputs.quantity.get(inputValues))
    const unitPrice = quantity !== 0 ? payableWithoutVat / quantity : 0

    if (unitPrice === 0) {
        rowInputs.unitPrice.clear()
    } else {
        rowInputs.unitPrice.set(formatAmountForInput(unitPrice))
    }
}

export const toggleRegularCalculationMode = () => {
    toggleCalculationMode(getState().inputValues)

    // Input values have been changed, get new state
    const { expenseData, inputValues } = getState()
    const newCalculationMode = inputs.expense.calculationMode.get(inputValues)
    let convertItem: typeof convertItemManualToAutomatic

    if (newCalculationMode === calculationModes.automatic) {
        convertItem = convertItemManualToAutomatic
    } else if (newCalculationMode === calculationModes.manual) {
        convertItem = convertItemAutomaticToManual
    } else {
        throw new Error('Unexpected calculation mode: ' + newCalculationMode)
    }

    expenseData.itemIds.forEach((id) => convertItem(id, inputValues))
}

const convertAssetAutomaticToManual = (id: string) => {
    const { inputValues } = getState()
    const rowInputs = inputs.expense.asset(id)
    const withoutVat = amountFromString(rowInputs.withoutVat.get(inputValues))
    const other = amountFromString(rowInputs.other.get(inputValues))
    const newWithoutVat = withoutVat + other

    if (newWithoutVat === 0) {
        rowInputs.withoutVat.clear()
    } else {
        rowInputs.withoutVat.set(formatAmountForInput(newWithoutVat))
    }

    rowInputs.other.clear()
}

export const toggleAssetCalculationMode = () => {
    toggleCalculationMode(getState().inputValues)

    // Input values have been changed, get new state
    const { expenseData, inputValues } = getState()
    const newCalculationMode = inputs.expense.calculationMode.get(inputValues)

    if (newCalculationMode === calculationModes.manual) {
        expenseData.assetIds.forEach(convertAssetAutomaticToManual)
    }
}

const checkAutomaticTotals = (expectedTotals: Totals<number>, inputValues: InputValues) => {
    const { vatAmount: expectedVat, payableWithVat: expectedWithVat } = expectedTotals

    const actualVat = amountFromString(inputs.expense.totals.vat.get(inputValues))
    const actualWithVat = amountFromString(inputs.expense.totals.withVat.get(inputValues))

    if (amountEquals(actualVat, expectedVat) && amountEquals(actualWithVat, expectedWithVat)) {
        clearTotalMismatch()
        return true
    } else {
        dispatch(({ expenseData }) => {
            expenseData.totalsMismatch = { vat: expectedVat, total: expectedWithVat }
        })

        return false
    }
}

const checkManualTotals = (expectedTotals: Totals<number>, inputValues: InputValues) => {
    const { payableWithoutVat: expectedPayableWithoutVat } = expectedTotals

    const actualVat = amountFromString(inputs.expense.totals.vat.get(inputValues))
    const actualWithVat = amountFromString(inputs.expense.totals.withVat.get(inputValues))
    const actualPayableWithoutVat = actualWithVat - actualVat

    if (amountEquals(actualPayableWithoutVat, expectedPayableWithoutVat)) {
        clearTotalMismatch()
        return true
    } else {
        dispatch(({ expenseData }) => {
            expenseData.totalsMismatch = { payableWithoutVat: expectedPayableWithoutVat }
        })

        return false
    }
}

const checkTotals = (expectedTotals: Totals<number>, inputValues: InputValues) => {
    const calculationMode = inputs.expense.calculationMode.get(inputValues)

    if (calculationMode === calculationModes.automatic) {
        return checkAutomaticTotals(expectedTotals, inputValues)
    } else if (calculationMode === calculationModes.manual) {
        return checkManualTotals(expectedTotals, inputValues)
    } else {
        throw new Error('Unexpected expense calculation mode: ' + calculationMode)
    }
}

export const createRegular = async () =>
    run(SAVE_PROCESS, async () => {
        const company = await loadCompany()
        const {
            expenseData: { itemIds },
            inputValues,
        } = getState()

        const calculationMode = inputs.expense.calculationMode.get(inputValues)
        const date = inputs.expense.date.get(inputValues)
        const vatPayer = isVatPayerAt(company.vatPeriods, date)

        const expectedTotals = calculateTotalsFromItemInputs(
            itemIds,
            inputs.expense.item,
            inputValues,
            calculationMode,
            vatPayer,
        )

        if (!checkTotals(expectedTotals, inputValues)) {
            return
        }

        const expense = await getRegularInput(inputValues, itemIds, company.vatPeriods)
        const id = await api.createExpense(expense)
        invalidateCache()
        dispatch(({ expenseData }) => (expenseData.justCreatedId = id))
        setRoute('#/expenses/register')
        inputs.expense.canClear.set(true)
    })

export const createAsset = async () =>
    run(SAVE_PROCESS, async () => {
        const company = await loadCompany()
        const {
            expenseData: { assetIds },
            inputValues,
        } = getState()

        const date = inputs.expense.date.get(inputValues)
        const vatPayer = isVatPayerAt(company.vatPeriods, date)

        const expectedTotals = calculateTotalsFromAssetInputs(
            assetIds,
            inputs.expense.asset,
            inputValues,
            vatPayer,
        )

        if (!checkTotals(expectedTotals, inputValues)) {
            return
        }

        const expense = await getAssetInput(assetIds, inputValues, company.vatPeriods)
        await api.createExpense(expense)
        invalidateCache()
        setRoute('#/expenses/assets')
        inputs.expense.canClear.set(true)
    })

export const updateRegular = async (id: string) =>
    run(SAVE_PROCESS, async () => {
        const company = await loadCompany()
        const {
            expenseData: { itemIds },
            inputValues,
        } = getState()

        const calculationMode = inputs.expense.calculationMode.get(inputValues)
        const date = inputs.expense.date.get(inputValues)
        const vatPayer = isVatPayerAt(company.vatPeriods, date)

        const expectedTotals = calculateTotalsFromItemInputs(
            itemIds,
            inputs.expense.item,
            inputValues,
            calculationMode,
            vatPayer,
        )

        if (!checkTotals(expectedTotals, inputValues)) {
            return
        }

        const expense = await getRegularUpdate(inputValues, itemIds, company.vatPeriods)
        const rev = inputs.expense.rev.get(inputValues)
        await api.updateExpense(id, rev, expense)
        invalidateCache()
        setRoute('#/expenses/register')
    })

export const updateAsset = async (id: string) =>
    run(SAVE_PROCESS, async () => {
        const company = await loadCompany()
        const {
            expenseData: { assetIds },
            inputValues,
        } = getState()

        const date = inputs.expense.date.get(inputValues)
        const vatPayer = isVatPayerAt(company.vatPeriods, date)

        const expectedTotals = calculateTotalsFromAssetInputs(
            assetIds,
            inputs.expense.asset,
            inputValues,
            vatPayer,
        )

        if (!checkTotals(expectedTotals, inputValues)) {
            return
        }

        const rev = inputs.expense.rev.get(inputValues)
        const expense = await getAssetUpdate(assetIds, inputValues, company.vatPeriods)
        await api.updateExpense(id, rev, expense)
        invalidateCache()
        setRoute('#/expenses/assets')
    })

export const confirmExpense = async (id: string, rev: number) =>
    run(CONFIRM_PROCESS, async () => {
        await api.confirmExpense(id, rev)
        invalidateCache()
        await loadExpenses()
    })

export const remove = async (id: string) =>
    run(REMOVE_PROCESS, async () => {
        const { paymentState } = getState().expenseData

        if (paymentState && paymentState.id === id) {
            // If the payment sidebar is open for this expense, close it
            closeExpensePaymentForm()
        }

        try {
            await api.removeExpense(id)
            invalidateCache()
            await loadExpenses()
        } catch (error) {
            if (error instanceof ServerError && error.response.errorCode === 'negative-balance') {
                const date = error.response.date as string

                const message = t.invoices.remove.negativeBalance.get(
                    Day.fromYmd(date).dmy(),
                    error.response.account,
                )

                processWarning(fromErrorCode('negative-balance').withMessage(message).dontReport())
            } else {
                throw error
            }
        }
    })

// TODO rename prepareForm to something less form-specific like prepareView?
export const initRegister = async () =>
    prepareForm('expense-register', async () => {
        await Promise.all([loadCompanies(), loadExpenses(), loadLabourCosts(), loadVatState()])

        inputs.expense.register.showAll.set(false)
        inputs.expense.register.sort.set('date')

        if (getState().expenseData.justCreatedId) {
            onNextNavigation(() =>
                dispatch(({ expenseData }) => (expenseData.justCreatedId = null)),
            )
        }
    })

export const addExpensePayment = async (id: string) =>
    run(SAVE_PAYMENT_PROCESS, async () => {
        const { expenseData, inputValues } = getState()
        const expense = getById(expenseData, id)!
        const totals = calculateExpenseInitial(expense)
        const remaining = totals.payableWithVat - getPaidAmount(expense.payments)

        const rev = inputs.payment.rev.get(inputValues)
        const payment = getPaymentInput(remaining)

        await api.addExpensePayment(id, rev, payment)
        invalidateCache()
        await loadExpenses()
        closeExpensePaymentForm()
    })

export const removeExpensePayment = async (expenseId: string, paymentId: string) =>
    run(REMOVE_PAYMENT_PROCESS, async () => {
        const { inputValues } = getState()
        const rev = inputs.payment.rev.get(inputValues)
        await api.removeExpensePayment(expenseId, rev, paymentId)

        invalidateCache()
        await loadExpenses()

        const expense = findByDbId(getState().expenseData.expenses!, expenseId)!
        inputs.payment.rev.set(expense.rev)
    })

const getAssetChangeMode = (mode: string): AssetChangeMode | undefined => {
    if (mode === assetChangeModes.residual || mode === assetChangeModes.eol) {
        return mode
    } else {
        // TODO report warning if mode is not empty
        return undefined
    }
}

export const isAssetChangeDateSelection = (routeParams: string[]) => {
    const [action, date] = routeParams
    return action === 'add-value-change' && !date
}

export const parseAssetRouteParams = (
    routeParams: string[],
    pendingAssetChanges: PendingAssetChange[],
): AssetChangeParams | null => {
    const [action, ...actionParams] = routeParams

    if (action === 'add-value-change') {
        const [date, expenseId, assetId, changeMode] = actionParams

        // Check if there already is a pending change on the same asset.
        // In add mode, this is an error condition.
        const [existing] = pendingAssetChanges.filter(
            (pendingChange) =>
                pendingChange.expenseId === expenseId && pendingChange.assetId === assetId,
        )

        return {
            isNew: true,
            date,
            expenseId,
            assetId,
            mode: getAssetChangeMode(changeMode),
            existing,
        }
    } else if (action === 'edit-value-change') {
        const [changeId] = actionParams
        const existing = findByDbId(pendingAssetChanges, changeId)

        if (!existing) {
            throw new Error('Invalid asset change id: ' + changeId)
        }

        const { expenseId, assetId, valueChange } = existing
        const { date, mode } = valueChange
        return { isNew: false, date, expenseId, assetId, mode, existing }
    } else {
        return null
    }
}

export const initAssetList = async (routeParams: string[]) =>
    prepareForm('asset-list', async () => {
        const expensesPromise = loadExpenses() // TODO load only needed expenses?
        const company = await loadCompany()

        if (canChangeAssetValues(getState().session!.companyRole, company.status)) {
            await loadPendingAssetChanges()
            const pendingAssetChanges = getState().expenseData.pendingAssetChanges!
            const changeParams = parseAssetRouteParams(routeParams, pendingAssetChanges)

            if (changeParams) {
                if (changeParams.isNew) {
                    if (changeParams.expenseId && changeParams.assetId && changeParams.mode) {
                        await expensesPromise
                        const expenses = getState().expenseData.expenses!
                        const expense = findByDbId(expenses, changeParams.expenseId)!
                        const asset = findById(expense.assets!, changeParams.assetId)!
                        const refDate = Day.fromYmd(changeParams.date!)
                        const { residual, eolDate } = calculateAssetCurrent(refDate, asset)

                        if (changeParams.mode === assetChangeModes.residual) {
                            inputs.expense.assetChange.residual.set(formatAmountForInput(residual))
                        } else if (changeParams.mode === assetChangeModes.eol) {
                            if (eolDate) {
                                inputs.expense.assetChange.eolDate.set(eolDate.ymd())
                            }
                        } else {
                            throw new Error('Unexpected asset change mode: ' + changeParams.mode)
                        }
                    }
                } else if (changeParams.existing) {
                    const change = changeParams.existing.valueChange

                    if (change.mode === assetChangeModes.residual) {
                        inputs.expense.assetChange.residual.set(
                            formatAmountForInput(change.residual),
                        )
                    } else if (change.mode === assetChangeModes.eol) {
                        inputs.expense.assetChange.eolDate.set(change.eolDate)
                    } else {
                        throw new Error(
                            'Unexpected asset change mode: ' + (change as { mode?: string }).mode,
                        )
                    }
                }
            }
        }

        // TODO Only set if not set? Skip if table isn't shown?
        const tableInputs = inputs.expense.assetList
        tableInputs.showAll.set(false)
        tableInputs.date.set(Day.today().ymd())
        const input = tableInputs.sort

        if (!input.hasValue(getState().inputValues)) {
            input.set('date')
        }
    })

export const initUnpaidForm = async () =>
    prepareForm('unpaid-expenses', async () => {
        await Promise.all([loadCompanies(), loadExpenses()]) // TODO load only needed expenses?

        inputs.expense.unpaid.date.set(Day.today().ymd())
        const input = inputs.expense.unpaid.sort

        if (!input.hasValue(getState().inputValues)) {
            input.set('type')
        }
    })

export const initGoodsList = () => {
    void loadExpenses() // TODO load only needed expenses?
    void loadAccounts()

    inputs.expense.goods.expense.showAll.set(false)
    inputs.expense.goods.expense.sort.set('date')
}

export const initStockList = () => {
    void loadCompanies()
    void loadExpenses() // TODO load only needed expenses?
    void loadAccounts()
    void loadPendingStockChanges()

    inputs.expense.goods.stock.showAll.set(false)
    inputs.expense.goods.stock.date.set(Day.today().ymd())
    inputs.expense.goods.stock.sort.set('date')
}

export const initStockChange = async (isNew: boolean, changeId?: string, date?: string) => {
    if (isNew && !date) {
        // Only need to render date selection, no data loading
        return
    }

    clearErrors(SAVE_STOCK_CHANGE_PROCESS)
    const expensePromise = loadExpenses()
    const changePromise = loadPendingStockChanges()

    void loadAccounts()

    inputs.expense.goods.stock.sort.set('date')

    let actualDate: string
    let existingItems: PendingStockChangeItem<number>[] | null = null

    if (!isNew) {
        await changePromise
        const {
            expenseData: { pendingStockChanges },
        } = getState()
        const change = findByDbId(pendingStockChanges!, changeId)

        if (!change) {
            throw new Error('Invalid stock change id: ' + changeId)
        }

        actualDate = change.date
        existingItems = change.items
    } else {
        actualDate = date!
    }

    await expensePromise
    const expenses = getState().expenseData.expenses!

    for (const expense of expenses) {
        // TODO filter by date as well?
        // Would then need to add the filter to getStockChangeItems as well,
        // to avoid reading data from outdated inputs.
        // Alternatively, clear all these inputs when navigating away.
        if (expense.type === expenseTypes.regular) {
            const { calculationMode } = expense

            for (const item of expense.items!) {
                if (item.type === expenseItemTypes.goodsStock) {
                    const itemInputs = inputs.expense.goods.stock.changes(expense._id)(item.id)

                    const quantity = getItemQuantity(item, actualDate)
                    itemInputs.newQuantity.setDefaultValue(formatAmountForInput(quantity))
                    itemInputs.newQuantity.set('')

                    const unitPrice = getItemUnitPrice(calculationMode, item, actualDate)
                    itemInputs.newUnitPrice.setDefaultValue(formatAmountForInput(unitPrice))
                    itemInputs.newUnitPrice.set('')
                }
            }
        }
    }

    if (existingItems) {
        for (const changeItem of existingItems) {
            const itemInputs = inputs.expense.goods.stock.changes(changeItem.expenseId)(
                changeItem.itemId,
            )

            if (typeof changeItem.newQuantity === 'number') {
                itemInputs.newQuantity.set(String(changeItem.newQuantity))
            }

            if (typeof changeItem.newUnitPrice === 'number') {
                itemInputs.newUnitPrice.set(String(changeItem.newUnitPrice))
            }
        }
    }
}

export const initGeneralList = () => {
    void loadExpenses() // TODO load only needed expenses?
    void loadAccounts()

    const sortInput = inputs.expense.general.sort

    if (!sortInput.hasValue(getState().inputValues)) {
        sortInput.set('date')
    }

    inputs.expense.general.showAll.set(false)
}

const getValueChange = (
    mode: AssetChangeMode,
    date: string,
    inputValues: InputValues,
): AssetValueChange<string> => {
    if (mode === assetChangeModes.residual) {
        const residual = getNumeric(inputs.expense.assetChange.residual, inputValues)
        return { mode, date, residual }
    } else if (mode === assetChangeModes.eol) {
        const eolDate = inputs.expense.assetChange.eolDate.get(inputValues)
        return { mode, date, eolDate }
    } else {
        throw new Error('Unexpected asset change mode: ' + mode)
    }
}

export const createAssetChange = async (changeParams: AssetChangeParams) =>
    run(SAVE_ASSET_CHANGE_PROCESS, async () => {
        const { inputValues } = getState()
        const valueChange = getValueChange(changeParams.mode!, changeParams.date!, inputValues)
        await api.createAssetChange(changeParams.expenseId!, changeParams.assetId!, valueChange)

        invalidateAssetChangeCache()

        // Reload expenses as well to get updated log entries
        invalidateCache()

        await Promise.all([loadPendingAssetChanges(), loadExpenses()])
        inputs.expense.assetChange.residual.set('')
        inputs.expense.assetChange.eolDate.set('')
        setRoute('#/expenses/assets')
    })

export const updateAssetChange = async (changeParams: AssetChangeParams) =>
    run(SAVE_ASSET_CHANGE_PROCESS, async () => {
        const { existing } = changeParams

        if (!existing) {
            throw new Error('Existing value change must be loaded')
        }

        const { inputValues } = getState()

        const valueChange = getValueChange(
            existing.valueChange.mode,
            existing.valueChange.date,
            inputValues,
        )

        const residual =
            valueChange.mode === assetChangeModes.residual ? valueChange.residual : undefined
        const eolDate = valueChange.mode === assetChangeModes.eol ? valueChange.eolDate : undefined

        await api.updateAssetChange(existing._id, existing.rev, residual, eolDate)
        invalidateAssetChangeCache()
        await loadPendingAssetChanges()
        inputs.expense.assetChange.residual.set('')
        inputs.expense.assetChange.eolDate.set('')
        setRoute('#/expenses/assets')
    })

export const removeAssetChange = async (changeId: string) =>
    run(REMOVE_ASSET_CHANGE_PROCESS, async () => {
        await api.removeAssetChange(changeId)
        invalidateAssetChangeCache()
        invalidateCache() // Reload expenses as well to get updated log entries
        await Promise.all([loadPendingAssetChanges(), loadExpenses()])
    })

export const confirmAssetChange = async (changeId: string) =>
    run(CONFIRM_ASSET_CHANGE_PROCESS, async () => {
        await api.confirmAssetChange(changeId)
        invalidateAssetChangeCache()
        invalidateCache()
        await Promise.all([loadPendingAssetChanges(), loadExpenses()])
    })

const getStockChangeItems = (expenses: ApiExpense[] | null, inputValues: InputValues) => {
    if (!expenses) {
        throw new Error('Expenses not loaded')
    }

    const items: PendingStockChangeItem<string>[] = []

    for (const expense of expenses) {
        // TODO filter by date as well?
        if (expense.type === expenseTypes.regular) {
            for (const item of expense.items!) {
                if (item.type === expenseItemTypes.goodsStock) {
                    const itemInputs = inputs.expense.goods.stock.changes(expense._id)(item.id)
                    const newQuantity = getNumericIfNotDefault(itemInputs.newQuantity, inputValues)
                    const newUnitPrice = getNumericIfNotDefault(
                        itemInputs.newUnitPrice,
                        inputValues,
                    )

                    if (typeof newQuantity === 'string' || typeof newUnitPrice === 'string') {
                        items.push({
                            expenseId: expense._id,
                            itemId: item.id,
                            newQuantity,
                            newUnitPrice,
                        })
                    }
                }
            }
        }
    }

    return items
}

export const createStockChange = async (date: string) =>
    run(SAVE_STOCK_CHANGE_PROCESS, async () => {
        const {
            expenseData: { expenses },
            inputValues,
        } = getState()
        const items = getStockChangeItems(expenses, inputValues)
        await api.createStockChange(date, items)

        invalidateStockChangeCache()

        // Reload expenses as well to get updated log entries
        invalidateCache()

        await Promise.all([loadPendingStockChanges(), loadExpenses()])
        setRoute('#/expenses/stock')
    })

export const updateStockChange = async (changeId: string) =>
    run(SAVE_STOCK_CHANGE_PROCESS, async () => {
        const { expenseData, inputValues } = getState()
        const pendingStockChanges = expenseData.pendingStockChanges!
        const change = findByDbId(pendingStockChanges, changeId)!

        const items = getStockChangeItems(expenseData.expenses, inputValues)
        await api.updateStockChange(changeId, change.rev, items)

        invalidateStockChangeCache()

        // Reload expenses as well to get updated log entries
        invalidateCache()

        await Promise.all([loadPendingStockChanges(), loadExpenses()])
        setRoute('#/expenses/stock')
    })

export const removeStockChange = async (changeId: string) =>
    run(REMOVE_STOCK_CHANGE_PROCESS, async () => {
        await api.removeStockChange(changeId)
        invalidateStockChangeCache()
        invalidateCache() // Reload expenses as well to get updated log entries
        await Promise.all([loadPendingStockChanges(), loadExpenses()])
    })

export const confirmStockChange = async (changeId: string) =>
    run(CONFIRM_STOCK_CHANGE_PROCESS, async () => {
        await api.confirmStockChange(changeId)
        invalidateStockChangeCache()
        invalidateCache()
        await Promise.all([loadPendingStockChanges(), loadExpenses()])
    })

export const addExpenseFile = async (expenseId: string, file: File) =>
    run(UPLOAD_PROCESS, async () => {
        if (file.size > MAX_UPLOAD_MB * MB) {
            throw new WrappedWarning(fromErrorCode('file-too-large').dontReport())
        }

        const base64 = await fileToBase64(file)

        try {
            await api.addExpenseFile(expenseId, file.name, base64)
        } catch (error) {
            if (error instanceof ServerError && error.response.errorCode === 'duplicate') {
                const message = t.files.duplicate.get()
                throw new WrappedWarning(
                    fromErrorCode('duplicate').withMessage(message).dontReport(),
                )
            } else {
                throw error
            }
        }

        // TODO reload only file list of one expense?
        invalidateCache()
        await Promise.all([loadExpenses(), loadFileStorageUsage()])

        const expense = getById(getState().expenseData, expenseId)!
        inputs.expense.rev.set(expense.rev)
    })

export const removeExpenseFile = async (expenseId: string, file: ExpenseFile) =>
    run(REMOVE_FILE_PROCESS_PREFIX + file.hash, async () => {
        await api.removeExpenseFile(expenseId, file.hash)

        // TODO reload only file list of one expense?
        invalidateCache()
        await Promise.all([loadExpenses(), loadFileStorageUsage()])
    })
