import { roundToNearestCent } from './amount-utils'
import { assertNever } from './assert-never'
import { MIN_DATE } from './clock'
import { calculationModes, expenseTypes } from './enums'
import { calculateExpenseInitial } from './expense-utils'
import { findByDbId } from './find-by-db-id'
import { Language } from './i18n'
import { getCustomerName } from './invoice-utils'
import { calculateAssetInitial, calculateAutomaticItem, calculateItem } from './item-utils'
import { Day } from './time'
import { ApiExpense, DbExpense } from './types/expense'
import { ApiRevenue, CreditRevenue, DbRevenue } from './types/invoice'
import { Totals } from './types/item'
import { ExpenseVatPercentage, RevenueVatPercentage, VatPeriod } from './types/vat'

export interface VatTotal {
    withoutVat: number
    vatAmount: number
    withVat: number
}

export type VatSourceType = 'revenue' | 'expense' | 'creditRevenue'

export interface VatSource {
    type: VatSourceType
    id: string
    date: string
    getCustomer: (language: Language) => string
    /** May be different from the sum of item totals in case of manual expenses */
    payableTotal: VatTotal
    itemTotal22: VatTotal
    itemTotal20: VatTotal
    itemTotal9: VatTotal
    itemTotal0: VatTotal
    link: string
    isOverLimit: boolean
}

export interface VatCalc {
    month: Day
    revenueSources: VatSource[]
    expenseSources: VatSource[]
    revenueTotals: Map<RevenueVatPercentage, number>
    revenueVatLocal: number
    revenueVatMta: number
    expenseVat: number
    differenceMta: number
}

interface CompanySummary {
    total: number
    sources: VatSource[]
}

export const INVOICE_LIMIT = 1000

const isDateInPeriod = (date: string, period: VatPeriod) => {
    return period.from <= date && (!period.to || period.to >= date)
}

export const isVatPayerAt = (periods: VatPeriod[], refDate: string) =>
    periods.some((period) => isDateInPeriod(refDate, period))

export const isVatPayer = (periods: VatPeriod[]) => isVatPayerAt(periods, Day.today().ymd())

export const getDefaultVatPercentage = (invoiceDate: Day) => {
    // ...-2023
    if (invoiceDate.isBefore(Day.fromYmd('2024-01-01'))) {
        return 20
    }

    // 2024-...
    return 22
}

const getEmptyTotal = (): VatTotal => ({ withoutVat: 0, vatAmount: 0, withVat: 0 })

const addTotals = (from: Totals<number>, to: VatTotal) => {
    to.withoutVat += from.payableWithoutVat
    to.vatAmount += from.vatAmount
    to.withVat += from.payableWithVat
}

const addItemTotals = (
    vatPercentage: ExpenseVatPercentage,
    subtotal: Totals<number>,
    source: VatSource,
) => {
    if (vatPercentage === 22) {
        addTotals(subtotal, source.itemTotal22)
    } else if (vatPercentage === 20) {
        addTotals(subtotal, source.itemTotal20)
    } else if (vatPercentage === 9) {
        addTotals(subtotal, source.itemTotal9)
    } else if (vatPercentage === 0) {
        addTotals(subtotal, source.itemTotal0)
    } else if (vatPercentage === null) {
        // Although 0 and null both count as 0 in most calculations,
        // here we make a distinction and omit the item in case of null.
    } else {
        throw assertNever(vatPercentage, 'vat percentage')
    }
}

const sumTotals = (...subtotals: VatTotal[]) =>
    subtotals.reduce((acc, subtotal) => {
        return {
            withoutVat: acc.withoutVat + subtotal.withoutVat,
            vatAmount: acc.vatAmount + subtotal.vatAmount,
            withVat: acc.withVat + subtotal.withVat,
        }
    }, getEmptyTotal())

const negateTotals = (origTotals: Totals<number>): Totals<number> => {
    const { totalWithoutVat, discount, payableWithoutVat, vatAmount, payableWithVat } = origTotals

    return {
        totalWithoutVat: -totalWithoutVat,
        discount: -discount,
        payableWithoutVat: -payableWithoutVat,
        vatAmount: -vatAmount,
        payableWithVat: -payableWithVat,
    }
}

export const getRevenueSources = (
    revenues: Array<ApiRevenue | DbRevenue>,
    creditRevenues: CreditRevenue[],
    allRevenues: Array<ApiRevenue | DbRevenue>,
) => {
    const byRegCode = new Map<string, CompanySummary>()

    const revenueSources = revenues.map((revenue) => {
        const source: VatSource = {
            type: 'revenue',
            id: revenue._id,
            date: revenue.date,
            getCustomer: (language) => getCustomerName(revenue.customer, language),
            payableTotal: getEmptyTotal(),
            itemTotal22: getEmptyTotal(),
            itemTotal20: getEmptyTotal(),
            itemTotal9: getEmptyTotal(),
            itemTotal0: getEmptyTotal(),
            link: '#/invoices/view/' + revenue._id,
            isOverLimit: false,
        }

        for (const item of revenue.items) {
            const subtotal = calculateAutomaticItem(item, MIN_DATE)
            addItemTotals(item.vatPercentage, subtotal, source)
        }

        source.payableTotal = sumTotals(
            source.itemTotal22,
            source.itemTotal20,
            source.itemTotal9,
            source.itemTotal0,
        )

        if (revenue.customer.isBusiness) {
            const { regCode } = revenue.customer
            let summary = byRegCode.get(regCode)

            if (!summary) {
                summary = { total: 0, sources: [] }
                byRegCode.set(regCode, summary)
            }

            // itemTotal0 must be excluded
            summary.total +=
                source.itemTotal22.withoutVat +
                source.itemTotal20.withoutVat +
                source.itemTotal9.withoutVat

            summary.sources.push(source)
        }

        return source
    })

    const creditRevenueSources = creditRevenues.map((creditRevenue) => {
        const origRevenue = findByDbId(allRevenues, creditRevenue._id)!

        const source: VatSource = {
            type: 'creditRevenue',
            id: creditRevenue._id,
            date: creditRevenue.date,
            getCustomer: (language) => getCustomerName(origRevenue.customer, language),
            payableTotal: getEmptyTotal(),
            itemTotal22: getEmptyTotal(),
            itemTotal20: getEmptyTotal(),
            itemTotal9: getEmptyTotal(),
            itemTotal0: getEmptyTotal(),
            link: '#/credit-revenues/view/' + creditRevenue._id,
            isOverLimit: false,
        }

        for (const item of origRevenue.items) {
            const posSubtotal = calculateAutomaticItem(item, MIN_DATE)
            const subtotal = negateTotals(posSubtotal)
            addItemTotals(item.vatPercentage, subtotal, source)
        }

        source.payableTotal = sumTotals(
            source.itemTotal22,
            source.itemTotal20,
            source.itemTotal9,
            source.itemTotal0,
        )

        if (origRevenue.customer.isBusiness) {
            const { regCode } = origRevenue.customer
            let summary = byRegCode.get(regCode)

            if (!summary) {
                summary = { total: 0, sources: [] }
                byRegCode.set(regCode, summary)
            }

            // itemTotal0 must be excluded
            summary.total +=
                source.itemTotal22.withoutVat +
                source.itemTotal20.withoutVat +
                source.itemTotal9.withoutVat

            summary.sources.push(source)
        }

        return source
    })

    for (const summary of byRegCode.values()) {
        if (summary.total > INVOICE_LIMIT) {
            for (const source of summary.sources) {
                source.isOverLimit = true
            }
        }
    }

    return revenueSources.concat(creditRevenueSources)
}

export const getExpenseSources = (expenses: Array<ApiExpense | DbExpense>) => {
    const byRegCode = new Map<string, CompanySummary>()

    const sources = expenses.map((expense) => {
        const source: VatSource = {
            type: 'expense',
            id: expense._id,
            date: expense.date,
            getCustomer: () => expense.vendor.name,
            payableTotal: getEmptyTotal(),
            itemTotal22: getEmptyTotal(),
            itemTotal20: getEmptyTotal(),
            itemTotal9: getEmptyTotal(),
            itemTotal0: getEmptyTotal(),
            link: '#/expenses/view/' + expense._id,
            isOverLimit: false,
        }

        if (expense.type === expenseTypes.regular) {
            for (const item of expense.items!) {
                const subtotal = calculateItem(expense.calculationMode, item, MIN_DATE)
                addItemTotals(item.vatPercentage, subtotal, source)
            }
        } else if (expense.type === expenseTypes.asset) {
            for (const asset of expense.assets!) {
                const initial = calculateAssetInitial(asset)

                // Convert to other format
                const subtotal = {
                    totalWithoutVat: initial.payableWithoutVat,
                    discount: 0,
                    payableWithoutVat: initial.payableWithoutVat,
                    vatAmount: initial.vatAmount,
                    payableWithVat: initial.withVat,
                }

                addItemTotals(asset.totals.vatPercentage, subtotal, source)
            }
        } else {
            throw assertNever(expense.type, 'expense type')
        }

        if (expense.calculationMode === calculationModes.manual) {
            const initial = calculateExpenseInitial(expense)

            source.payableTotal = {
                withoutVat: initial.payableWithoutVat,
                vatAmount: initial.vatAmount,
                withVat: initial.payableWithVat,
            }
        } else {
            source.payableTotal = sumTotals(
                source.itemTotal22,
                source.itemTotal20,
                source.itemTotal9,
                source.itemTotal0,
            )
        }

        const { regCode } = expense.vendor
        let summary = byRegCode.get(regCode)

        if (!summary) {
            summary = { total: 0, sources: [] }
            byRegCode.set(regCode, summary)
        }

        // itemTotal0 must be excluded
        summary.total +=
            source.itemTotal22.withoutVat +
            source.itemTotal20.withoutVat +
            source.itemTotal9.withoutVat

        summary.sources.push(source)

        return source
    })

    for (const summary of byRegCode.values()) {
        if (summary.total > INVOICE_LIMIT) {
            for (const source of summary.sources) {
                source.isOverLimit = true
            }
        }
    }

    return sources
}

const getRevenueTotals = (revenueSources: VatSource[]) => {
    let total22 = 0
    let total20 = 0
    let total9 = 0
    let total0 = 0

    for (const source of revenueSources) {
        total22 += source.itemTotal22.withoutVat
        total20 += source.itemTotal20.withoutVat
        total9 += source.itemTotal9.withoutVat
        total0 += source.itemTotal0.withoutVat
    }

    const totals = new Map<RevenueVatPercentage, number>()
    totals.set(22, total22)
    totals.set(20, total20)
    totals.set(9, total9)
    totals.set(0, total0)
    return totals
}

export const calculateVat = (
    expenses: Array<ApiExpense | DbExpense>,
    revenues: Array<ApiRevenue | DbRevenue>,
    creditRevenues: CreditRevenue[],
    month: Day,
    allRevenues: Array<ApiRevenue | DbRevenue>,
): VatCalc => {
    const revenueSources = getRevenueSources(revenues, creditRevenues, allRevenues)
    const expenseSources = getExpenseSources(expenses)

    // When reporting expense VAT to MTA, only the total VAT amount is used.
    // Any differences from rounding, manual calculation mode, etc do not matter.

    // VAT amout from payable totals (suitable for MTA)
    const expenseVat = roundToNearestCent(
        expenseSources.reduce((acc, source) => acc + source.payableTotal.vatAmount, 0),
    )

    // When reporting revenue VAT to MTA, in addition to the total VAT amount, the
    // original totals (one for each VAT percentage) are used as well.
    // In our own records, we round the VAT amount on each item of each invoice.
    // These rounding errors can accumulate so that the VAT amount calculated from
    // the total amounts reported to MTA can be different from the sum of the
    // rounded VAT amounts on all invoice items.
    // The difference between the amounts is accumulated on special accounts for
    // rounding differences when VAT is paid.

    // VAT amout from payable totals (not suitable for MTA)
    const revenueVatLocal = roundToNearestCent(
        revenueSources.reduce((acc, source) => acc + source.payableTotal.vatAmount, 0),
    )

    const revenueTotals = getRevenueTotals(revenueSources)

    // VAT amount calculated from totals without VAT (suitable for MTA)
    const revenueVatMta = roundToNearestCent(
        revenueTotals.get(22)! * 0.22 + revenueTotals.get(20)! * 0.2 + revenueTotals.get(9)! * 0.09,
    )

    return {
        month,
        revenueSources,
        expenseSources,
        revenueTotals,
        revenueVatLocal,
        revenueVatMta,
        expenseVat,
        differenceMta: roundToNearestCent(revenueVatMta - expenseVat),
    }
}
