import { Draft, produce } from 'immer'
import Immutable from 'immutable'

import { LabourCostViewMode } from '../../common/types/labour-cost'
import { VatIds, VatTableMode } from '../../common/types/taxes'
import { emitAfterStoreReset, emitUiStateUpdated } from '../event-bus'
import { View } from '../view'
import { RootData } from './root-data'

let state: RootData | undefined

export const getDefaultVatIds = (): VatIds => ({
    revenue: new Set(),
    expense: new Set(),
    creditRevenue: new Set(),
})

const getDefaultState = (): RootData => ({
    accountData: {
        accounts: null,
        allAccounts: null,
    },
    businessLookup: {
        previous: [],
        other: [],
        search: '',
        lastSelectionVat: 'none-selected',
    },
    commandData: {
        commands: null,
        errorInfo: null,
        expanded: Immutable.Set(),
    },
    companyData: {
        companies: null,
        cardPayments: null,
        bankAccountCount: 1,
        initAssetIds: [],
        interimBalance: null,
    },
    creditRevenueData: {
        creditRevenues: null,
    },
    dashboardData: {
        date: '',
        loaded: null,
        note: null,
    },
    entryData: {
        itemIds: [],
        entries: null,
        copyFrom: null,
    },
    expenseData: {
        expenses: null,
        pendingAssetChanges: null,
        pendingStockChanges: null,
        itemIds: [],
        assetIds: [],
        logVisible: false,
        paymentState: null,
        justCreatedId: null,
        totalsMismatch: null,
        fileStorageUsage: null,
    },
    formsReady: Immutable.Set(),
    inputValues: {},
    invalidCaches: Immutable.Set(),
    invoiceData: {
        invoices: null,
        itemIds: [],
        searchByNumberStatus: { failed: false, searchText: '' },
        logVisible: false,
        pdfBlobUrl: null,
        pdfLanguage: null,
        payment: null,
        justCreatedId: null,
    },
    labourCostData: {
        labourCosts: null,
        mode: LabourCostViewMode.list,
        id: '',
    },
    menu: {
        mode: 'open',
        visibleSections: Immutable.Set(),
    },
    processes: Immutable.Set(),
    progress: null,
    reports: {
        balance: {
            selectedDates: Immutable.Set(),
            datesError: null,
            loaded: [],
        },
        income: {
            loaded: [],
        },
        cashFlow: {
            loaded: [],
        },
        turnover: {
            loaded: null,
        },
        periodsError: null,
        interimDateToday: false,
    },
    serverConf: null,
    session: null,
    settingsData: {
        settings: null,
    },
    taxes: {
        vat: {
            paymentOpen: false,
            revenuesMode: VatTableMode.collapsed,
            expensesMode: VatTableMode.collapsed,
            ids: getDefaultVatIds(),
            declarationExpanded: false,
            paymentState: null,
        },
    },
    terms: null,
    userData: {
        profile: null,
        usersLimited: null,
        usersFull: null,
        receivedInvites: null,
    },
    validationErrors: {},
    view: null as unknown as View, // TODO
})

export const reset = (emitEvent: boolean) => {
    state = getDefaultState()

    if (emitEvent) {
        emitAfterStoreReset()
    }
}

export const dispatch = (recipe: (draft: Draft<RootData>) => void) => {
    if (!state) {
        throw new Error('UI state not initialized')
    }

    state = produce(state, (draft) => {
        recipe(draft)
    })
    emitUiStateUpdated()
}

/**
 * Must not be called during render.
 * Can be called from click handlers, etc even if they are specified in render functions,
 * as they will be executed after the render has completed.
 */
export const getState = () => {
    if (!state) {
        throw new Error('UI state not initialized')
    }

    return state
}

export const init = () => {
    if (!state) {
        reset(true)
    } else {
        throw new Error('Store already initialized')
    }
}
