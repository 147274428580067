import classnames from 'classnames'
import React, { FC, ReactNode } from 'react'

interface Props {
    className?: string
    sidebar?: ReactNode
    title: string
    subtitle?: ReactNode
    sortOptions?: ReactNode
    buttons?: ReactNode[]
    table: ReactNode
}

export const StandardPage: FC<Props> = (props) => {
    const { className, sidebar, title, subtitle, sortOptions, buttons, table } = props

    return React.createElement(
        'div',
        { key: 'main', className: classnames('content-area', className) },
        sidebar,
        React.createElement(
            'div',
            { className: 'content' },
            React.createElement(
                'h1',
                { className: 'title' },
                title,
                React.createElement('span', { className: 'title__sub-title' }, subtitle), // TODO omit elem if empty
            ),
            React.createElement(
                'div',
                { className: 'flex space-between flex-end' },
                // Always render at least something so the flexbox would have 2 children
                sortOptions || React.createElement('span'),
                React.createElement(
                    'div',
                    { className: 'standard-buttons flex-vertical flex-end' },
                    ...(buttons || []),
                ),
            ),
            table,
        ),
    )
}
