import React, { FC } from 'react'

import { formatAmount } from '../format-amount'
import { renderValidationErrors } from '../val-err'
import { Input, InputProps } from './input'
import { ValidationErrorProps } from './validation-error'

export interface TotalsRightCell {
    amount?: number
    input?: InputProps
    text?: string
    euroSign: boolean
    errors?: ValidationErrorProps[]
}

export interface TotalsRow {
    className?: string
    left: string
    right: TotalsRightCell
}

export interface TotalsTableProps {
    rows: TotalsRow[]
}

const renderRight = ({ amount, input, text, euroSign, errors }: TotalsRightCell) =>
    React.createElement(
        'td',
        { className: 'text-right numeric' },
        typeof amount === 'number' && formatAmount(amount),
        input && React.createElement(Input, input),
        text,
        euroSign && ' €',
        renderValidationErrors(errors),
    )

const renderRow = ({ className, left, right }: TotalsRow) =>
    React.createElement(
        'tr',
        { className },
        React.createElement('td', null, left),
        renderRight(right),
    )

export const TotalsTable: FC<TotalsTableProps> = ({ rows }) =>
    React.createElement(
        'table',
        { id: 'totals' },
        React.createElement('tbody', null, ...rows.map(renderRow)),
    )
