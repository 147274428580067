import React, { FC } from 'react'

import { SearchByNumberStatus } from '../../../common/types/invoice'
import { t } from '../../i18n'
import { RootData } from '../../state/root-data'
import { RevenueSearchPanel } from './search-panel'

const renderFailure = ({ failed, searchText }: SearchByNumberStatus) => {
    if (failed) {
        return t.revenues.searchByNumber.failed.get(searchText)
    } else {
        return null
    }
}

export const RevenueSearch: FC<RootData> = (rootData) => {
    const {
        inputValues,
        invoiceData: { searchByNumberStatus },
        processes,
    } = rootData

    return React.createElement(
        'div',
        { className: 'content-area' },
        React.createElement(RevenueSearchPanel, { fromResult: false, inputValues, processes }),
        React.createElement('div', { className: 'content' }, renderFailure(searchByNumberStatus)),
    )
}
