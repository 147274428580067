import classnames from 'classnames'
import { Set as ImmutableSet } from 'immutable'
import React, { FC, Fragment } from 'react'

import { NET_PROFIT } from '../../../common/accounts'
import { amountEquals } from '../../../common/amount-equals'
import { balanceDateModes, balanceDateModesArray } from '../../../common/enums'
import { Day } from '../../../common/time'
import { ApiCompany } from '../../../common/types/company'
import { BalanceDateMode } from '../../../common/types/enums'
import { ValidationErrors } from '../../../common/types/errors'
import { InputValues } from '../../../common/types/inputs'
import { BalanceState } from '../../../common/types/reports'
import { Column, TableValue } from '../../../common/types/table'
import { getRows, getTopLevelGroups } from '../../balance-utils'
import { getExcelButtonProps } from '../../excel-utils'
import { HEADER_STYLE } from '../../excel/style'
import { ExcelFont, ExcelNumberFormat, ExcelSpec, ExcelStyle } from '../../excel/types'
import { formatAmount } from '../../format-amount'
import { t } from '../../i18n'
import { createCustomInput } from '../../input-utils'
import { inputs } from '../../inputs'
import { anyNonZero, getBalanceMinDate, renderYearChoice, Row } from '../../report-utils'
import { getCompany } from '../../state/company-actions'
import {
    addBalanceDate,
    clearBalanceDatesError,
    LOAD_BALANCE_PROCESS,
    loadBalance,
    removeBalanceDate,
} from '../../state/report-actions'
import { RootData } from '../../state/root-data'
import { combineHorizontally, combineVertically, TableSpec } from '../../table-utils'
import { valErr } from '../../val-err'
import { Button } from '../button'
import { renderChoice } from '../choice'
import { DateInput } from '../date-input'
import { DeleteIcon } from '../delete-icon'
import { ExcelButton } from '../excel-button'
import { Input } from '../input'
import { LoadingIcon } from '../loading-icon'
import { MonthButton } from '../month-button'
import { renderTable } from '../table'
import { ReportModeButton } from './mode-button'
import { NoReportData } from './no-data'

interface BalanceRow extends Row<string> {
    isBalanceDateRow?: true
    balanceTitle?: string
}

const balanceInputs = inputs.reports.balance
const datesInputs = balanceInputs.dates

const getColumns = (loaded: BalanceState[]): Column<BalanceRow>[] => [
    {
        getProps: (row) => {
            if (row.isBalanceDateRow) {
                return { className: 'balance__date-row-cell' }
            }

            const classDict: Record<string, boolean> = {
                'table__body-cell': true,
                'table__body-cell--no-left-pad': true,
            }

            if (!row.balanceTitle) {
                classDict['balance__account-name'] = true
                classDict['balance__account-name--total'] = !row.level
                classDict['balance__account-name--level2'] = row.level === 2
                classDict['balance__account-name--level3'] = row.level === 3
                classDict['balance__account-name--level4'] = row.level === 4
                classDict['balance__total-row-cell'] = Boolean(row.topLevel)
            }

            return { className: classnames(classDict) }
        },
        render: (row) => {
            const value = row.balanceTitle || row.label || ''
            const tableValue: TableValue = { browser: '', excel: '' }

            if (row.balanceTitle) {
                tableValue.browser = React.createElement(
                    'h2',
                    { className: 'balance__side-title' },
                    row.balanceTitle,
                )
            } else if (!row.isBalanceDateRow) {
                // Use non-breaking space as empty row content to avoid collapsing the height
                tableValue.browser = row.isEmpty ? '\u00a0' : value
            }

            if (row.balanceTitle || row.isBalanceDateRow) {
                tableValue.excel = { value: value.toUpperCase(), style: HEADER_STYLE }
            } else {
                const style: ExcelStyle = { font: row.level ? ExcelFont.regular : ExcelFont.bold }

                if (row.level) {
                    style.alignment = { indent: row.level - 1 }
                }

                tableValue.excel = { value, style }
            }

            return tableValue
        },
        excelWidth: 50,
    },
    ...loaded.map(
        ({ date }): Column<BalanceRow> => ({
            getProps: (row) => {
                if (row.isBalanceDateRow) {
                    return { className: 'balance__date-row-cell' }
                }

                if (row.balanceTitle) {
                    return null
                }

                return {
                    className: classnames('amount', {
                        'text-bold': !row.level,
                        'balance__total-row-cell': row.topLevel,
                    }),
                }
            },
            render: (row) => {
                let value: Day | number | null = null

                if (row.isBalanceDateRow) {
                    value = Day.fromYmd(date)
                } else if (row.amounts?.has(date)) {
                    value = row.amounts.get(date)!
                }

                const tableValue: TableValue = { browser: '', excel: '' }

                if (row.isBalanceDateRow && value instanceof Day) {
                    tableValue.browser = value.dmy()
                } else if (typeof value === 'number') {
                    tableValue.browser = formatAmount(value)
                }

                if (row.balanceTitle) {
                    tableValue.excel = { value, style: HEADER_STYLE }
                } else if (row.isBalanceDateRow) {
                    tableValue.excel = {
                        value,
                        style: { ...HEADER_STYLE, numberFormat: ExcelNumberFormat.date },
                    }
                } else {
                    tableValue.excel = {
                        value,
                        style: {
                            numberFormat: ExcelNumberFormat.money,
                            font: row.level ? ExcelFont.regular : ExcelFont.bold,
                        },
                    }
                }

                return tableValue
            },
            excelWidth: 12,
        }),
    ),
]

const combineTables = (
    columns: Column<BalanceRow>[],
    debitRows: BalanceRow[],
    creditRows: BalanceRow[],
    useVerticalLayout: boolean,
): TableSpec<any> => {
    // TODO avoid 'any'
    if (useVerticalLayout) {
        return combineVertically(
            { columns, rows: debitRows },
            { columns: [], rows: [{}] },
            { columns, rows: creditRows },
        )
    } else {
        return combineHorizontally(
            { columns, rows: debitRows },
            {
                columns: [
                    {
                        getProps: () => ({ className: 'balance__padding' }),
                        render: () => '',
                        excelWidth: 5,
                    },
                ],
                rows: [{}],
            },
            { columns, rows: creditRows },
        )
    }
}

const renderDeleteIcon = (visible: boolean, date: string) =>
    visible ? React.createElement(DeleteIcon, { onClick: () => removeBalanceDate(date) }) : null

const renderDateOptions = (
    mode: BalanceDateMode,
    inputValues: InputValues,
    selectedDates: ImmutableSet<string>,
    company: ApiCompany,
) => {
    const minDate = getBalanceMinDate(company)

    if (mode === balanceDateModes.dates) {
        const addDateInput = createCustomInput({
            inputType: 'string',
            get: () => '',
            set: addBalanceDate,
        })

        const sortedDates = selectedDates.toArray().sort()
        const canRemoveDates = sortedDates.length > 1

        return React.createElement(
            Fragment,
            null,
            ...sortedDates.map((date) =>
                React.createElement(
                    'div',
                    { className: 'bottom-margin' },
                    Day.fromYmd(date).longDate(),
                    ' ',
                    renderDeleteIcon(canRemoveDates, date),
                ),
            ),
            React.createElement(
                'div',
                null,
                React.createElement(DateInput, {
                    input: addDateInput,
                    inputValues: {},
                    text: t.addDate.get(),
                    minDate,
                    maxDate: Day.today(),
                    className: 'date-button report-time-overlay__time-button',
                }),
            ),
        )
    } else if (mode === balanceDateModes.years) {
        return React.createElement(
            Fragment,
            null,
            React.createElement(
                'div',
                null,
                t.date.from.get(),
                ' ',
                renderYearChoice(datesInputs.years.from, inputValues, company),
            ),
            React.createElement(
                'div',
                { className: 'top-margin' },
                t.date.to.get(),
                ' ',
                renderYearChoice(datesInputs.years.to, inputValues, company),
            ),
        )
    } else if (mode === balanceDateModes.months) {
        return React.createElement(
            Fragment,
            null,
            React.createElement(
                'div',
                null,
                t.date.from.get(),
                ' ',
                React.createElement(MonthButton, {
                    input: datesInputs.months.from,
                    inputValues,
                    min: minDate,
                    max: Day.today(),
                    mainButtonClass:
                        'button--secondary button--numeric report-time-overlay__time-button',
                    monthButtonClass: 'button--primary',
                    selectedMonthButtonClass: 'button--primary-selected',
                }),
            ),
            React.createElement(
                'div',
                { className: 'top-margin' },
                t.date.to.get(),
                ' ',
                React.createElement(MonthButton, {
                    input: datesInputs.months.to,
                    inputValues,
                    min: minDate,
                    max: Day.today(),
                    mainButtonClass:
                        'button--secondary button--numeric report-time-overlay__time-button',
                    monthButtonClass: 'button--primary',
                    selectedMonthButtonClass: 'button--primary-selected',
                }),
            ),
            React.createElement(
                'div',
                { className: 'top-margin' },
                t.dayOfMonth.get(),
                ' ',
                React.createElement(Input, {
                    input: datesInputs.months.dayOfMonth,
                    inputValues,
                    className: 'day-of-month',
                }),
            ),
        )
    } else {
        throw new Error('Unexpected balance date mode: ' + mode)
    }
}

const renderDatesError = (datesError: string | null, validationErrors: ValidationErrors) => {
    if (datesError) {
        return React.createElement('div', { className: 'validation-error' }, datesError)
    } else {
        return valErr(validationErrors[LOAD_BALANCE_PROCESS], 'dates', {
            'too-long': t.reports.dates.tooManyDates.get(),
        })
    }
}

const renderDatesOverlay = (visible: boolean, rootData: RootData) => {
    if (!visible) {
        return null
    }

    const {
        companyData,
        inputValues,
        reports: {
            balance: { selectedDates, datesError },
        },
        session,
        validationErrors,
    } = rootData

    const company = getCompany(companyData, session)

    const options = balanceDateModesArray.map((mode) => ({
        id: mode,
        label: t.reports.dates.modes[mode].get(),
    }))

    const input = datesInputs.mode
    const mode = input.get(inputValues)

    return React.createElement(
        'div',
        { className: 'report-time-overlay' },
        React.createElement(
            'div',
            null,
            renderChoice({
                type: 'buttons',
                input,
                inputValues,
                options,
                groupClassName: 'vertical',
                forceSelection: true,
                afterChange: () => clearBalanceDatesError(),
                buttonClassName: 'balance-date-mode-button button--secondary',
                selectedButtonClassName: 'balance-date-mode-button button--primary-light',
            }),
        ),
        React.createElement(
            'div',
            { className: 'top-margin' },
            renderDateOptions(mode, inputValues, selectedDates, company),
        ),
        renderDatesError(datesError, validationErrors),
        React.createElement(
            'div',
            { className: 'top-margin' },
            React.createElement(Button, {
                text: t.update.get(),
                onClick: loadBalance,
                className: 'button--primary',
            }),
        ),
    )
}

const renderDatesChoice = (rootData: RootData) => {
    const input = datesInputs.overlayOpen
    const visible = input.get(rootData.inputValues)

    const onClick = () => {
        input.set(!visible)

        if (visible) {
            // Closing
            clearBalanceDatesError()
        }
    }

    return React.createElement(
        'div',
        { className: 'top-margin' },
        React.createElement(
            'button',
            { className: 'button button--wide button--primary', onClick },
            t.reports.dates.choose.get(),
        ),
        renderDatesOverlay(visible, rootData),
    )
}

const getSubtitle = (loaded: BalanceState[]) => {
    if (loaded.length === 1) {
        return t.asOf.get(Day.fromYmd(loaded[0].date).dmy())
    } else {
        return null
    }
}

const addEmptyRows = (rows: Row<unknown>[], targetLength: number) => {
    while (rows.length < targetLength) {
        rows.push({ isEmpty: true })
    }
}

const renderContent = (rootData: RootData) => {
    const {
        accountData: { accounts },
        companyData,
        inputValues,
        processes,
        progress,
        reports: {
            balance: { loaded },
        },
    } = rootData

    if (
        !loaded.length ||
        !accounts ||
        !companyData.companies ||
        processes.has(LOAD_BALANCE_PROCESS)
    ) {
        return React.createElement(LoadingIcon, { color: 'black' })
    }

    const getAmounts = (number: string) => {
        const amounts = new Map<string, number>()

        for (const balanceState of loaded) {
            amounts.set(balanceState.date, balanceState.accounts[number] || 0)
        }

        return amounts
    }

    const { debit, credit } = getTopLevelGroups(
        getAmounts,
        (amounts, number) => number === NET_PROFIT || anyNonZero(amounts),
        accounts,
    )

    for (const balanceState of loaded) {
        const totalDebit = debit.amounts!.get(balanceState.date)!
        const totalCredit = credit.amounts!.get(balanceState.date)!

        if (!amountEquals(totalDebit, totalCredit)) {
            throw new Error(
                t.balance.mismatch.get() + ': ' + String(totalDebit) + ' vs ' + String(totalCredit),
            )
        }
    }

    const hasMultipleDates = loaded.length > 1
    const mode = balanceInputs.mode.get(inputValues)

    const columns = getColumns(loaded)

    const debitRows: BalanceRow[] = [{ balanceTitle: t.balance.debit.get() }]
    const creditRows: BalanceRow[] = [{ balanceTitle: t.balance.credit.get() }]

    if (hasMultipleDates) {
        debitRows.push({ isBalanceDateRow: true })
        creditRows.push({ isBalanceDateRow: true })
    }

    debitRows.push(...getRows(debit, mode))
    creditRows.push(...getRows(credit, mode))

    const useVerticalLayout = loaded.length > 2

    if (!useVerticalLayout) {
        const debitTotalRow = debitRows.pop()!
        const creditTotalRow = creditRows.pop()!

        const maxRowCount = Math.max(debitRows.length, creditRows.length)
        addEmptyRows(debitRows, maxRowCount)
        addEmptyRows(creditRows, maxRowCount)

        debitRows.push(debitTotalRow)
        creditRows.push(creditTotalRow)
    }

    const { columns: combinedColumns, rows: combinedRows } = combineTables(
        columns,
        debitRows,
        creditRows,
        useVerticalLayout,
    )

    const title = t.reports.balance.get()
    const subtitle = getSubtitle(loaded)

    const spec: ExcelSpec<unknown> = {
        columns: combinedColumns,
        rows: combinedRows,
        outputName: title + (subtitle ? ' ' + subtitle : ''),
        noHeader: true,
    }

    const excelButton = getExcelButtonProps(
        spec,
        '',
        processes,
        progress,
        'button--wide button--primary',
    )

    return React.createElement(
        Fragment,
        null,
        React.createElement(
            'div',
            { className: 'align-right relative' },
            React.createElement(
                'div',
                null,
                React.createElement(
                    'div',
                    null,
                    React.createElement(ReportModeButton, {
                        input: balanceInputs.mode,
                        inputValues,
                    }),
                ),
                React.createElement(
                    'div',
                    { className: 'top-margin' },
                    React.createElement(ExcelButton, excelButton),
                ),
                renderDatesChoice(rootData),
            ),
        ),
        React.createElement(
            'h1',
            { className: 'title' },
            title,
            subtitle
                ? React.createElement('span', { className: 'title__sub-title' }, subtitle)
                : null,
        ),
        renderTable({
            columns: combinedColumns,
            rows: combinedRows,
            noHeader: true,
            noWrapper: true,
            tableClassName: 'table balance',
        }),
    )
}

export const BalanceReport: FC<RootData> = (rootData) => {
    const { companyData, session } = rootData

    if (companyData.companies) {
        const company = getCompany(companyData, session)

        if (!company.hasReportData) {
            return React.createElement(NoReportData)
        }
    }

    return React.createElement(
        'div',
        { className: 'content-area' },
        React.createElement('div', { className: 'content report' }, renderContent(rootData)),
    )
}
