import { CompanyStatus } from '../common/enums'
import { setRoute } from './route-utils'
import { getCompany } from './state/company-actions'
import { RootData } from './state/root-data'

const isActiveCompany = (rootData: RootData) => {
    const { companyData, session } = rootData

    if (!companyData.companies || !session?.companyId) {
        return false
    }

    const company = getCompany(companyData, session)
    return company.status === CompanyStatus.active
}

export const showBlank = (rootData: RootData) => {
    setRoute(isActiveCompany(rootData) ? '#/blank-menu' : '#/blank')
}
