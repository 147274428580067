import classnames from 'classnames'
import React, { FC } from 'react'

import {
    BusinessInputs,
    OtherBusiness,
    PrevBusiness,
    BusinessLookup as TBusinessLookup,
    VatState,
} from '../../common/types/business'
import { ValidationError } from '../../common/types/errors'
import { InputValues } from '../../common/types/inputs'
import { Processes } from '../../common/types/processes'
import { highlight } from '../business-utils'
import { t } from '../i18n'
import { renderInputOrValue } from '../input-utils'
import { lookupBusiness, selectBusiness } from '../state/business-lookup-actions'
import { valErr } from '../val-err'
import { LoadingIcon } from './loading-icon'

export interface BusinessLookupProps {
    collectionName?: 'Expenses' | 'Invoices'
    businessLookup: TBusinessLookup
    inputs: BusinessInputs
    inputValues: InputValues
    processes: Processes
    vatPayer: boolean
    errorPrefix: string
    valErrors?: ValidationError[]
    disabled?: boolean
}

const renderNonVatPayerNote = (lastSelectionVat: VatState) =>
    lastSelectionVat === 'non-payer'
        ? React.createElement('div', null, t.businessLookup.nonVatPayer.get())
        : null

const renderVatId = (props: BusinessLookupProps, lastSelectionVat: VatState) => {
    const { inputs, inputValues, vatPayer, errorPrefix, valErrors, disabled } = props

    if (disabled && !inputs.vatId.get(inputValues)) {
        return null
    }

    if (vatPayer) {
        return React.createElement(
            'tr',
            null,
            React.createElement(
                'td',
                { className: 'table__body-cell table__body-cell--no-left-pad' },
                t.business.vatId.get(),
            ),
            React.createElement(
                'td',
                null,
                renderInputOrValue(!disabled, {
                    input: inputs.vatId,
                    inputValues,
                    className: 'business-lookup__input',
                }),
                valErr(valErrors, errorPrefix + '.vatId'),
                renderNonVatPayerNote(lastSelectionVat),
            ),
        )
    } else {
        return null
    }
}

const renderFields = (
    props: BusinessLookupProps,
    inProgress: boolean,
    lastSelectionVat: VatState,
) => {
    const { collectionName, inputs, inputValues, errorPrefix, valErrors, disabled } = props

    const afterChange = async (text: string) => lookupBusiness(collectionName, text, inProgress)

    return React.createElement(
        'table',
        { className: 'table align-top' },
        React.createElement(
            'tbody',
            null,
            React.createElement(
                'tr',
                null,
                React.createElement(
                    'td',
                    {
                        className:
                            'table__body-cell table__body-cell--no-left-pad table__body-cell--no-top-pad',
                    },
                    t.business.name.get(),
                ),
                React.createElement(
                    'td',
                    { className: 'table__body-cell table__body-cell--no-top-pad' },
                    renderInputOrValue(!disabled, {
                        input: inputs.name,
                        inputValues,
                        afterChange,
                        className: classnames('business-lookup__input', {
                            'business-lookup__input--search': inputs.name.get(inputValues) === '',
                        }),
                        disabled,
                    }),
                    ' ',
                    valErr(valErrors, errorPrefix + '.name'),
                ),
            ),
            React.createElement(
                'tr',
                null,
                React.createElement(
                    'td',
                    { className: 'table__body-cell table__body-cell--no-left-pad' },
                    t.business.regCode.get(),
                ),
                React.createElement(
                    'td',
                    null,
                    renderInputOrValue(!disabled, {
                        input: inputs.regCode,
                        inputValues,
                        disabled,
                        className: 'business-lookup__input',
                    }),
                    valErr(valErrors, errorPrefix + '.regCode'),
                ),
            ),
            React.createElement(
                'tr',
                null,
                React.createElement(
                    'td',
                    { className: 'table__body-cell table__body-cell--no-left-pad' },
                    t.business.address.get(),
                ),
                React.createElement(
                    'td',
                    null,
                    renderInputOrValue(!disabled, {
                        type: 'multiline',
                        input: inputs.address,
                        inputValues,
                        disabled,
                        className: 'business-lookup__input',
                    }),
                    valErr(valErrors, errorPrefix + '.address'),
                ),
            ),
            renderVatId(props, lastSelectionVat),
        ),
    )
}

const isPrevBusiness = (business: OtherBusiness | PrevBusiness): business is PrevBusiness => {
    return 'invoiceCount' in business
}

const renderNote = (business: OtherBusiness | PrevBusiness) => {
    if (isPrevBusiness(business)) {
        return React.createElement(
            'span',
            { className: 'business-lookup__match-note' },
            t.businessLookup.invoiceCount.get(),
            ': ',
            business.invoiceCount,
        )
    } else {
        return null
    }
}

const renderMatch = (
    business: OtherBusiness | PrevBusiness,
    search: string,
    inputs: BusinessInputs,
) => {
    const onClick = () => selectBusiness(business, inputs)

    return React.createElement(
        'div',
        { onClick, className: 'business-lookup__match' },
        highlight(business.name, search),
        renderNote(business),
    )
}

const renderMatchSection = (
    businesses: Array<OtherBusiness | PrevBusiness>,
    search: string,
    inputs: BusinessInputs,
) => {
    if (!businesses.length) {
        return null
    }

    return React.createElement(
        'div',
        { className: 'business-lookup__match-section' },
        ...businesses.map((business) => renderMatch(business, search, inputs)),
    )
}

const renderMatches = (props: BusinessLookupProps, inProgress: boolean) => {
    const {
        businessLookup: { previous, other, search },
        inputs,
    } = props
    const count = previous.length + other.length

    if (inProgress) {
        return React.createElement(
            'div',
            { className: 'business-lookup__matches-title' },
            React.createElement(LoadingIcon, { color: 'black', size: 16 }),
        )
    }

    if (!count) {
        return null
    }

    return React.createElement(
        'div',
        null,
        React.createElement(
            'div',
            { className: 'business-lookup__matches-title' },
            t.businessLookup.title.get(),
            ':',
        ),
        React.createElement(
            'div',
            { className: 'business-lookup__matches business-lookup__matches--popup' },
            renderMatchSection(previous, search, inputs),
            renderMatchSection(other, search, inputs),
        ),
    )
}

export const BusinessLookup: FC<BusinessLookupProps> = (props) => {
    const { businessLookup, processes } = props
    const inProgress = processes.has('business-lookup')

    return React.createElement(
        'div',
        { className: 'business-lookup' },
        renderFields(props, inProgress, businessLookup.lastSelectionVat),
        renderMatches(props, inProgress),
    )
}
