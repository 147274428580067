import { canConfirm, canCreateCreditRevenue } from '../../common/access'
import { getIncomeAccountNumber, getIncomeAccountType } from '../../common/accounts'
import { getMinDay } from '../../common/company-utils'
import { calculationModes, revenueItemTypes } from '../../common/enums'
import { getCompanyVendorFields } from '../../common/invoice-utils'
import { Day } from '../../common/time'
import { ApiCompany } from '../../common/types/company'
import { InputValues } from '../../common/types/inputs'
import { ApiRevenue, CreditRevenue, RevenueItemType } from '../../common/types/invoice'
import { Processes } from '../../common/types/processes'
import { VendorFields } from '../../common/types/vendor'
import { isVatPayerAt } from '../../common/vat-utils'
import { assertViewName } from '../assert-view-name'
import { ButtonProps } from '../components/button'
import { ChoiceProps } from '../components/choice'
import { ItemTableProps } from '../components/item-table'
import { LogProps } from '../components/log'
import { RevenueButton, RevenueEditProps } from '../components/revenue/edit'
import { RevenueGeneralProps } from '../components/revenue/general'
import { t } from '../i18n'
import { InputOrValueProps } from '../input-utils'
import { inputs } from '../inputs'
import { calculateTotalsFromItemInputs } from '../item-utils'
import { clearLookup } from '../state/business-lookup-actions'
import { getCompany } from '../state/company-actions'
import {
    addItem,
    afterAccountChange,
    CONFIRM_PROCESS,
    confirmRevenue,
    copyItemsFromRevenue,
    create,
    createCredit,
    downloadCreditRevenuePdf,
    downloadPdf,
    getCreditRevenueById,
    getLatestRevenueToSameCompany,
    getRevenueById,
    PDF_PROCESS,
    removeItem,
    SAVE_PROCESS,
    setLogVisible,
    update,
} from '../state/revenue-actions'
import { RootData } from '../state/root-data'
import { getSettings } from '../state/settings-actions'
import { getValidationErrorProps } from '../val-err'
import { getCreditRevenuePaymentProps } from './credit-revenue-payment-props'
import { getRevenuePaymentProps } from './revenue-payment-props'
import { getTotalsTableProps } from './totals-table-props'

const getGeneral = (
    { inputValues, validationErrors }: RootData,
    number: string,
    editMode: boolean,
    isCredit: boolean,
    company: ApiCompany,
): RevenueGeneralProps => {
    const general: RevenueGeneralProps = {
        number: number || t.unconfirmed.get(),
        date: inputs.invoice.date.get(inputValues),
        term: {
            editMode,
            inputProps: {
                input: inputs.invoice.term,
                inputValues,
                className: 'invoice__term-input',
            },
        },
    }

    if (editMode) {
        const minDate = getMinDay(company.interimDate)
        general.dateInput = { input: inputs.invoice.date, inputValues, minDate }

        const valErrors = validationErrors[SAVE_PROCESS]

        if (isCredit) {
            general.termErrors = getValidationErrorProps(valErrors, 'term')
            general.dateErrors = getValidationErrorProps(valErrors, 'date')
        } else {
            general.termErrors = getValidationErrorProps(valErrors, 'invoice.term')
            general.dateErrors = getValidationErrorProps(valErrors, 'invoice.date')
        }
    }

    const term = Number(inputs.invoice.term.get(inputValues))

    if (!isNaN(term)) {
        const dateStr = inputs.invoice.date.get(inputValues)
        general.dueDate = Day.fromYmd(dateStr).addDays(term)
    }

    return general
}

const getItemTable = (
    { accountData, inputValues, invoiceData: { itemIds }, validationErrors }: RootData,
    editMode: boolean,
    vatPayer: boolean,
): ItemTableProps<RevenueItemType> => ({
    itemIds,
    removeItem,
    getItemInputs: inputs.invoice.item,
    getItemType: (itemInputs) => itemInputs.type.get(inputValues),
    renderItemType: (itemType: RevenueItemType) => t.incomeItemTypes[itemType].get(),
    getAccountInputProps: (itemId, itemInputs) => ({
        id: itemId,
        itemInputs,
        inputValues,
        getAccountType: getIncomeAccountType,
        getAccountNumber: getIncomeAccountNumber,
        accountData,
        editMode,
        afterChange: async (accountNumber) => {
            const itemType: any = itemInputs.type.get(inputValues)
            await afterAccountChange(accountNumber, itemId, itemType, itemInputs)
        },
    }),
    inputValues,
    editMode,
    valErrors: validationErrors[SAVE_PROCESS],
    errorPrefix: 'invoice.items',
    vatPayer,
    hasDiscount: true,
    calculationMode: calculationModes.automatic,
    isRevenue: true,
})

const getCommentInput = (editMode: boolean, inputValues: InputValues): InputOrValueProps => ({
    editMode,
    inputProps: {
        type: 'multiline',
        input: inputs.invoice.comment,
        inputValues,
        placeholder: t.revenues.comment.input.get(),
        className: 'invoice__comment',
        disabled: !editMode,
        maxLength: 500,
    },
})

const getSaveButtonProps = (
    id: string,
    inputValues: InputValues,
    processes: Processes,
): ButtonProps => {
    const save = async () => (id ? update(id) : create())
    let disabled = false
    let title = null

    const customerType = inputs.invoice.customer.type.get(inputValues)

    if (!customerType) {
        disabled = true
        title = t.revenues.disabled.customerType.get()
    }

    return {
        onClick: save,
        text: t.saveDraft.get(),
        processes,
        processName: SAVE_PROCESS,
        disabled,
        title,
        domId: 'save',
        className: 'button--primary',
    }
}

const getRevenueButtons = (
    { inputValues, processes }: RootData,
    confirmed: boolean,
    id: string,
    userCanConfirm: boolean,
    editMode: boolean,
): RevenueButton[] => {
    const buttons: RevenueButton[] = []

    if (!editMode) {
        buttons.push({
            type: 'link',
            props: {
                text: t.preview.get(),
                to: '#/invoices/preview/' + id,
                className: 'button button--secondary',
            },
        })

        buttons.push({
            type: 'button',
            props: {
                text: t.download.pdf.get(),
                domId: 'download-pdf',
                onClick: async () => downloadPdf(id),
                processes,
                processName: PDF_PROCESS,
                className: 'button--secondary',
            },
        })

        if (!confirmed) {
            buttons.push({
                type: 'link',
                props: {
                    text: t.edit.get(),
                    to: '#/invoices/edit/' + id,
                    className: 'button button--primary',
                },
            })
        }
    }

    if (editMode) {
        buttons.push({
            type: 'button',
            props: getSaveButtonProps(id, inputValues, processes),
        })
    }

    if (!confirmed && !editMode && userCanConfirm) {
        buttons.push({
            type: 'button',
            props: {
                onClick: () => {
                    const rev = inputs.invoice.rev.get(inputValues)
                    void confirmRevenue(id, rev)
                },
                text: t.confirm.get(),
                processes,
                processName: CONFIRM_PROCESS,
                className: 'button--primary',
            },
        })
    }

    return buttons
}

const getCreditRevenueButton = (id: string): RevenueButton => ({
    type: 'link',
    props: {
        text: t.revenues.creditRevenue.create.get(),
        to: '#/credit-revenues/add/' + id,
        className: 'button button--secondary full-width',
    },
})

const getBusinessDetails = (
    {
        businessLookup,
        inputValues,
        invoiceData: { invoices: revenues, itemIds },
        processes,
        validationErrors,
    }: RootData,
    isNew: boolean,
    editMode: boolean,
    vatPayer: boolean,
): RevenueEditProps['businessDetails'] => {
    const isEmptyNew = isNew && !itemIds.length

    const businessDetails: RevenueEditProps['businessDetails'] = {
        lookup: {
            collectionName: 'Invoices',
            businessLookup,
            inputs: inputs.invoice.customer,
            inputValues,
            processes,
            vatPayer,
            valErrors: validationErrors[SAVE_PROCESS],
            errorPrefix: 'invoice.customer',
            disabled: !editMode,
        },
    }

    if (isEmptyNew && inputs.invoice.customer.regCode.get(inputValues)) {
        const latestRevenue = getLatestRevenueToSameCompany(revenues, inputValues)

        if (latestRevenue) {
            businessDetails.copyRevenueLink = {
                onClick: async () => copyItemsFromRevenue(latestRevenue),
                text: t.copyItems.outgoing.get(),
            }
        }
    }

    return businessDetails
}

const getCitizenDetails = (
    editMode: boolean,
    inputValues: InputValues,
): RevenueEditProps['citizenDetails'] => {
    const detailsInput = inputs.invoice.customer.details

    const citizenDetails: RevenueEditProps['citizenDetails'] = {
        input: {
            editMode,
            inputProps: {
                type: 'multiline',
                input: detailsInput,
                inputValues,
                placeholder: t.citizen.details.placeholder.get(),
                className: 'invoice__citizen-details',
                maxLength: 100,
            },
            modify: (value) => value || t.citizen.get(),
        },
    }

    if (editMode) {
        citizenDetails.charactersLeft = {
            current: detailsInput.get(inputValues).length,
            max: 100,
        }
    }

    return citizenDetails
}

const getCustomerType = (inputValues: InputValues): ChoiceProps<'business' | 'citizen' | ''> => ({
    input: inputs.invoice.customer.type,
    inputValues,
    type: 'buttons',
    options: [
        { id: 'business', label: t.business.get() },
        { id: 'citizen', label: t.citizen.get() },
    ],
    afterChange: (newValue: string) => {
        if (newValue === 'business') {
            inputs.invoice.customer.name.set('')
            inputs.invoice.customer.regCode.set('')
            inputs.invoice.customer.address.set('')
            inputs.invoice.customer.vatId.set('')
            clearLookup()
        } else if (newValue === 'citizen') {
            inputs.invoice.customer.details.set('')
        }
    },
})

const getAddItemButtons = () => [
    {
        text: t.revenues.addNew.service.get(),
        onClick: async () => addItem(revenueItemTypes.service),
        className: 'button--secondary',
    },
    {
        text: t.revenues.addNew.goods.get(),
        onClick: async () => addItem(revenueItemTypes.goods),
        className: 'button--secondary',
    },
]

const getLogProps = (
    { invoiceData: { logVisible }, userData }: RootData,
    existing: ApiRevenue | CreditRevenue,
): LogProps => ({
    visible: logVisible,
    setVisible: setLogVisible,
    entries: existing.log,
    payments: (existing.payments || []).concat(existing.removedPayments || []),
    incoming: true,
    userData,
})

export const getRevenueEditProps = (rootData: RootData): RevenueEditProps | { isLoading: true } => {
    const {
        accountData,
        companyData,
        creditRevenueData,
        formsReady,
        inputValues,
        invoiceData,
        processes,
        session,
        settingsData,
        view,
        validationErrors,
    } = rootData

    const { mode, id } = assertViewName(view, 'RevenueEdit')
    const isNew = mode === 'add'

    if (
        !formsReady.has('invoice') ||
        !accountData.accounts ||
        (!isNew && !invoiceData.invoices) ||
        !settingsData.settings ||
        !companyData.companies
    ) {
        return { isLoading: true }
    }

    const { itemIds, searchByNumberStatus, payment } = invoiceData

    let editMode = true
    let existing: ApiRevenue | undefined
    let confirmed = false
    let vendorFields: VendorFields<string> | null = null // TODO: using only name?
    let vatPayer = false
    let number = ''
    let creditRevenueButton: RevenueButton | undefined

    const settings = getSettings(settingsData)
    let { logoUrl } = settings

    if (!isNew) {
        editMode = mode === 'edit'
        existing = getRevenueById(invoiceData, id)!
        confirmed = existing.confirmed

        if (existing.confirmed) {
            number = existing.number || ''
            vendorFields = existing.vendor!
            vatPayer = existing.vatPayer!
            logoUrl = existing.logoUrl
        }
    }

    const company = getCompany(companyData, session)

    if (!confirmed) {
        vendorFields = getCompanyVendorFields(company)
        const date = inputs.invoice.date.get(inputValues)
        vatPayer = isVatPayerAt(company.vatPeriods, date)
    }

    const totals = calculateTotalsFromItemInputs(
        itemIds,
        inputs.invoice.item,
        inputValues,
        calculationModes.automatic,
        vatPayer,
    )

    const userCanConfirm = canConfirm(session!.companyRole, company.status)

    const userCanCreateCreditRevenue = canCreateCreditRevenue(session!.companyRole, company.status)

    const creditRevenue = getCreditRevenueById(creditRevenueData, id)

    if (userCanCreateCreditRevenue && confirmed && !creditRevenue) {
        creditRevenueButton = getCreditRevenueButton(id)
    }

    const props: RevenueEditProps = {
        isLoading: false,
        backLink: !isNew,
        recipientTitle: t.revenues.recipient.get(),
        title: t.revenues.invoice.get(),
        general: getGeneral(rootData, number, editMode, false, company),
        itemTable: getItemTable(rootData, editMode, vatPayer),
        commentInput: getCommentInput(editMode, inputValues),
        totalsTable: getTotalsTableProps({
            editable: false,
            totals,
            vatMissing: false,
            vatPayer,
            calculationMode: calculationModes.automatic,
        }),
        buttons: getRevenueButtons(rootData, confirmed, id, userCanConfirm, editMode),
        creditRevenueButton,
    }

    const { failed, searchText } = searchByNumberStatus

    if (!failed && searchText && number === searchText) {
        props.searchSidebar = { fromResult: true, inputValues, processes }
    } else if (existing && id === payment?.id) {
        if (payment.isCredit) {
            props.paymentSidebar = getCreditRevenuePaymentProps(
                existing,
                creditRevenue!,
                inputValues,
                processes,
                validationErrors,
            )
        } else {
            props.paymentSidebar = getRevenuePaymentProps(
                existing,
                Boolean(creditRevenue),
                inputValues,
                processes,
                validationErrors,
            )
        }
    }

    const customerType = inputs.invoice.customer.type.get(inputValues)

    if (customerType === 'business') {
        props.businessDetails = getBusinessDetails(rootData, isNew, editMode, vatPayer)
    } else if (customerType === 'citizen') {
        props.citizenDetails = getCitizenDetails(editMode, inputValues)
    }

    if (editMode) {
        props.customerType = getCustomerType(inputValues)
        props.addItemButtons = getAddItemButtons()

        props.commentCharactersLeft = {
            current: inputs.invoice.comment.get(inputValues).length,
            max: 500,
        }

        if (!customerType) {
            props.customerTypeNote = t.revenues.disabled.customerType.get()
        }
    } else {
        props.vendor = {
            logoUrl,
            name: vendorFields!.name,
        }
    }

    if (existing) {
        props.log = getLogProps(rootData, existing)
    }

    return props
}

const getCreditRevenueSaveButton = (id: string, processes: Processes): RevenueButton => {
    return {
        type: 'button',
        props: {
            onClick: async () => createCredit(id),
            text: t.save.get(),
            processes,
            processName: SAVE_PROCESS,
            disabled: false,
            title: null,
            domId: 'save',
            className: 'button--primary',
        },
    }
}

const getCreditRevenueViewButtons = (id: string, processes: Processes): RevenueButton[] => [
    {
        type: 'link',
        props: {
            text: t.preview.get(),
            to: '#/credit-revenues/preview/' + id,
            className: 'button button--secondary',
        },
    },
    {
        type: 'button',
        props: {
            text: t.download.pdf.get(),
            domId: 'download-pdf',
            onClick: async () => downloadCreditRevenuePdf(id),
            processes,
            processName: PDF_PROCESS,
            className: 'button--secondary',
        },
    },
]

export const getCreditRevenueProps = (
    rootData: RootData,
): RevenueEditProps | { isLoading: true } => {
    const {
        accountData,
        creditRevenueData,
        companyData,
        formsReady,
        inputValues,
        invoiceData,
        processes,
        session,
        settingsData,
        view,
    } = rootData

    const { id, mode } = assertViewName(view, 'CreditRevenue')

    if (
        !formsReady.has('invoice') ||
        !accountData.accounts ||
        !invoiceData.invoices ||
        !settingsData.settings ||
        !companyData.companies
    ) {
        return { isLoading: true }
    }

    const revenue = getRevenueById(invoiceData, id)!
    const vatPayer = revenue.vatPayer!

    let businessDetails: RevenueEditProps['businessDetails']
    let citizenDetails: RevenueEditProps['citizenDetails']

    if (revenue.customer.isBusiness) {
        businessDetails = getBusinessDetails(rootData, false, false, vatPayer)
    } else {
        citizenDetails = getCitizenDetails(false, inputValues)
    }

    const { itemIds } = invoiceData

    const totals = calculateTotalsFromItemInputs(
        itemIds,
        inputs.invoice.item,
        inputValues,
        calculationModes.automatic,
        vatPayer,
    )

    const company = getCompany(companyData, session)

    const editMode = mode === 'add'

    const buttons = editMode
        ? [getCreditRevenueSaveButton(id, processes)]
        : getCreditRevenueViewButtons(id, processes)

    let log: LogProps | undefined
    let creditRevenueNumber = t.unconfirmed.get()

    if (!editMode) {
        const creditRevenue = getCreditRevenueById(creditRevenueData, id)!
        log = getLogProps(rootData, creditRevenue)
        creditRevenueNumber = creditRevenue.number
    }

    const general = getGeneral(rootData, creditRevenueNumber, editMode, true, company)

    return {
        isLoading: false,
        backLink: true,
        vendor: revenue.vendor,
        recipientTitle: t.revenues.recipient.get(),
        businessDetails,
        citizenDetails,
        title: t.revenues.creditRevenue.get(),
        general,
        itemTable: getItemTable(rootData, false, vatPayer),
        totalsTable: getTotalsTableProps({
            editable: false,
            totals,
            vatMissing: false,
            vatPayer,
            calculationMode: calculationModes.automatic,
        }),
        buttons,
        log,
        originalRevenue: { id: revenue._id, number: revenue.number! },
        creditRevenueComment: t.revenues.creditRevenue.forRevenue.get(),
    }
}
