import classnames from 'classnames'
import React, { FC } from 'react'

import { FilterSection } from '../../../common/types/expense'
import { t } from '../../i18n'
import { Link } from '../link'

interface Props {
    selected?: FilterSection
}

const pages: FilterSection[] = ['general', 'vendor', 'types']

const renderButton = (page: FilterSection, isSelected: boolean) =>
    React.createElement(Link, {
        to: '#/expenses/archive/' + page,
        text: t.expenses.archive.header[page].get(),
        className: classnames(
            'button',
            isSelected ? 'button--transparent-selected' : 'button--transparent',
            'archive__section-button',
        ),
    })

export const ExpenseArchiveSidebar: FC<Props> = ({ selected }) =>
    React.createElement(
        'div',
        { className: 'sidebar' },
        React.createElement(
            'h1',
            { className: 'sidebar__title-with-icon title' },
            React.createElement('img', {
                src: '/menu/expenses.svg',
                className: 'sidebar__title-icon',
            }),
            React.createElement('div', null, t.expenses.archive.get()),
        ),
        ...pages.map((page) => renderButton(page, page === selected)),
    )
