import { ServerError } from '../../common/server-error'
import { ValidationError } from '../../common/types/errors'
import { fromErrorCode, processWarning } from '../error-manager'
import { runAfterNextRender } from '../event-bus'
import { dispatch } from './store'

let debug = false

// Used in dev and production, but not in test mode, to keep console cleaner.
export const enableDebug = () => {
    debug = true
}

export const clearErrors = (key: string) => {
    dispatch(({ validationErrors }) => delete validationErrors[key])
}

export const setValidationErrors = (key: string, errors: ValidationError[]) => {
    dispatch(({ validationErrors }) => (validationErrors[key] = errors))
}

export const processError = (error: Error, key: string) => {
    if (error instanceof ServerError && error.response.errorCode === 'validation-failed') {
        const errors = error.response.validationErrors as ValidationError[]

        if (debug) {
            for (const { key: fieldKey, type, ...additional } of errors) {
                console.warn(fieldKey, type, additional)
            }
        }

        setValidationErrors(key, errors)

        // Components are responsible for rendering any possible validation error that may occur.
        // In case any of them fail to do that, we show a generic warning.
        // Ideally, this should never happen and when it does, the component should be fixed.
        runAfterNextRender(() => {
            const renderedErrors = document.querySelectorAll('.validation-error')

            if (!renderedErrors.length) {
                processWarning(fromErrorCode('validation-failure-not-rendered', errors))
            }
        })
    } else {
        throw error
    }
}
