import { FailedResponseBody } from './types/http'

export class ServerError extends Error {
    public readonly response: FailedResponseBody

    constructor(response: FailedResponseBody, message?: string) {
        super(message || 'Server error')
        Object.setPrototypeOf(this, new.target.prototype)
        this.response = response
    }
}
