import Immutable from 'immutable'
import ms from 'ms'

import { schedule } from '../../common/clock'
import { Scheduled } from '../../common/types/clock'
import { dispatch } from './store'

const INTERVAL = ms('5 min')

const scheduledMap = new Map<string, Scheduled>()

const clear = (cacheId: string) => {
    if (scheduledMap.has(cacheId)) {
        const scheduled = scheduledMap.get(cacheId)!
        scheduled.clear()
        scheduledMap.delete(cacheId)
    }
}

export const setInvalid = (cacheId: string) => {
    if (!cacheId) {
        throw new Error('Invalid parameter for setInvalid')
    }

    dispatch((draft) => (draft.invalidCaches = draft.invalidCaches.add(cacheId)))
    clear(cacheId)
}

export const setValid = (cacheId: string) => {
    // @ts-expect-error Looks like a TypeScript bug
    dispatch((draft) => (draft.invalidCaches = draft.invalidCaches.delete(cacheId)))
    clear(cacheId)
    const scheduled = schedule(INTERVAL, () => setInvalid(cacheId), 'invalid-cache.' + cacheId)
    scheduledMap.set(cacheId, scheduled)
}

export const clearScheduled = () => {
    for (const scheduled of scheduledMap.values()) {
        scheduled.clear()
    }

    scheduledMap.clear()
    // @ts-expect-error Looks like a TypeScript bug
    dispatch((draft) => (draft.invalidCaches = Immutable.Set()))
}
