import React, { FC, Fragment } from 'react'

import { assertNever } from '../../common/assert-never'
import { ValidationErrors } from '../../common/types/errors'
import { InputValues } from '../../common/types/inputs'
import { Processes } from '../../common/types/processes'
import { PasswordResetViewParams } from '../../common/types/user'
import { assertViewName } from '../assert-view-name'
import { t } from '../i18n'
import {
    PASSWORD_RESET_INIT_PROCESS,
    PASSWORD_RESET_PROCESS,
} from '../state/password-reset-actions'
import { RootData } from '../state/root-data'
import { Change } from './password-reset/change'
import { Init } from './password-reset/init'

const renderContent = (
    inputValues: InputValues,
    processes: Processes,
    validationErrors: ValidationErrors,
    viewParams: PasswordResetViewParams,
) => {
    if (viewParams.page === 'init') {
        const valErrors = validationErrors[PASSWORD_RESET_INIT_PROCESS]
        return React.createElement(Init, { inputValues, processes, valErrors })
    } else if (viewParams.page === 'pending') {
        return React.createElement(
            'div',
            { className: 'password-reset__message' },
            t.password.reset.pending.get(viewParams.email),
        )
    } else if (viewParams.page === 'change') {
        const valErrors = validationErrors[PASSWORD_RESET_PROCESS]
        const { resetCode } = viewParams
        return React.createElement(Change, { inputValues, processes, valErrors, resetCode })
    } else if (viewParams.page === 'success') {
        return React.createElement(
            Fragment,
            null,
            React.createElement(
                'div',
                { className: 'password-reset__message' },
                t.password.reset.success.get(),
            ),
            React.createElement(
                'div',
                null,
                React.createElement(
                    'a',
                    { href: '#/', className: 'button password-reset__main-button' },
                    t.login.get(),
                ),
            ),
        )
    } else {
        throw assertNever(viewParams, 'reset password page', (viewParams as { page?: string }).page)
    }
}

export const PasswordReset: FC<RootData> = (rootData) => {
    const { inputValues, processes, validationErrors, view } = rootData
    const { params } = assertViewName(view, 'PasswordReset')

    return React.createElement(
        'div',
        { className: 'password-reset' },
        renderContent(inputValues, processes, validationErrors, params),
    )
}
