export const fileToBase64 = async (file: File) =>
    new Promise<string>((resolve, reject) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(btoa(reader.result as string)))
        reader.addEventListener('error', reject)
        reader.readAsBinaryString(file)
    })

export const fileToDataUrl = async (file: File) =>
    new Promise<string>((resolve, reject) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result as string))
        reader.addEventListener('error', reject)
        reader.readAsDataURL(file)
    })
