import { Draft } from 'immer'

import { buildAllAccounts } from '../../common/accounts'
import { sort } from '../../common/sort'
import { ApiAccount } from '../../common/types/account'
import { Paragraph } from '../../common/types/terms'
import * as api from '../api'
import { addError } from '../error-manager'
import { setValid } from './invalid-cache-actions'
import { RootData } from './root-data'
import { dispatch, getState } from './store'

interface AccountsValue {
    accounts: ApiAccount[] | null
    allAccounts: Map<string, string | ApiAccount> | null
}

const promises = new Map<string, Promise<unknown>>()

const loadData = async <T>(
    cacheId: string, // TODO enum CacheId
    isLoaded: (state: RootData) => boolean,
    emptyValue: T,
    getValuePromise: () => Promise<T>,
    setValue: (draft: Draft<RootData>, value: T) => void,
    customPromiseKey?: string,
): Promise<void> => {
    const promiseKey = customPromiseKey || cacheId
    const existingPromise = promises.get(promiseKey)

    if (existingPromise) {
        await existingPromise
        return
    }

    const state = getState()
    let loaded = isLoaded(state)

    if (loaded && state.invalidCaches.has(cacheId)) {
        dispatch((draft) => setValue(draft, emptyValue))
        loaded = false
    }

    if (!loaded) {
        const newPromise = getValuePromise()
        promises.set(promiseKey, newPromise)

        try {
            const value = await newPromise
            dispatch((draft) => setValue(draft, value))
            setValid(cacheId)
        } catch (error) {
            await addError(error as Error)
        } finally {
            promises.delete(promiseKey)
        }
    }
}

export const loadAccounts = async () =>
    loadData<AccountsValue>(
        'account',
        (state) => Boolean(state.accountData.accounts),
        { accounts: null, allAccounts: null },
        async () => {
            const accounts = await api.loadAccounts()
            const allAccounts = buildAllAccounts(accounts)
            return { accounts, allAccounts }
        },
        (draft, value) => {
            draft.accountData.accounts = value.accounts
            draft.accountData.allAccounts = value.allAccounts
        },
    )

export const loadCardPayments = async () =>
    loadData(
        'card-payment',
        (state) => Boolean(state.companyData.cardPayments),
        null,
        async () => api.loadCardPayments(),
        (draft, cardPayments) => (draft.companyData.cardPayments = cardPayments),
    )

export const loadCompanies = async () =>
    loadData(
        'company',
        (state) => Boolean(state.companyData.companies),
        null,
        async () => api.loadCompanies(),
        (draft, companies) => (draft.companyData.companies = companies),
    )

export const loadEntries = async () =>
    loadData(
        'entry',
        (state) => Boolean(state.entryData.entries),
        null,
        async () => api.loadEntries(),
        (draft, entries) => (draft.entryData.entries = entries),
    )

export const loadExpenses = async () =>
    loadData(
        'expense',
        (state) => Boolean(state.expenseData.expenses),
        null,
        async () => api.loadExpenses(),
        (draft, expenses) => (draft.expenseData.expenses = expenses),
    )

export const loadInterimBalance = async () =>
    loadData(
        'interim-balance',
        (state) => Boolean(state.companyData.interimBalance),
        null,
        async () => api.loadInterimBalance(),
        (draft, interimBalance) => (draft.companyData.interimBalance = interimBalance),
    )

export const loadLabourCosts = async () =>
    loadData(
        'labour-cost',
        (state) => Boolean(state.labourCostData.labourCosts),
        null,
        async () => api.loadLabourCosts(),
        (draft, labourCosts) => (draft.labourCostData.labourCosts = labourCosts),
    )

export const loadPendingAssetChanges = async () =>
    loadData(
        'pending-asset-change',
        (state) => Boolean(state.expenseData.pendingAssetChanges),
        null,
        async () => api.loadPendingAssetChanges(),
        (draft, changes) => (draft.expenseData.pendingAssetChanges = changes),
    )

export const loadPendingStockChanges = async () =>
    loadData(
        'pending-stock-change',
        (state) => Boolean(state.expenseData.pendingStockChanges),
        null,
        async () => api.loadPendingStockChanges(),
        (draft, changes) => (draft.expenseData.pendingStockChanges = changes),
    )

export const loadProfile = async () =>
    loadData(
        'profile',
        (state) => Boolean(state.userData.profile),
        null,
        async () => api.loadProfile(),
        (draft, profile) => (draft.userData.profile = profile),
    )

export const loadReceivedInvites = async () =>
    loadData(
        'received-invite',
        (state) => Boolean(state.userData.receivedInvites),
        null,
        async () => api.loadReceivedInvites(),
        (draft, invites) => (draft.userData.receivedInvites = invites),
    )

export const loadRevenues = async () =>
    loadData(
        'invoice',
        (state) => Boolean(state.invoiceData.invoices),
        null,
        async () => api.loadRevenues(),
        (draft, revenues) => (draft.invoiceData.invoices = revenues),
    )

export const loadCreditRevenues = async () =>
    loadData(
        'credit-revenue',
        (state) => Boolean(state.creditRevenueData.creditRevenues),
        null,
        async () => api.loadCreditRevenues(),
        (draft, creditRevenues) => (draft.creditRevenueData.creditRevenues = creditRevenues),
    )

export const loadServerConf = async () =>
    loadData(
        'server-conf',
        (state) => Boolean(state.serverConf),
        null,
        async () => api.loadServerConf(),
        (draft, serverConf) => (draft.serverConf = serverConf),
    )

export const loadSettings = async () =>
    loadData(
        'settings',
        (state) => Boolean(state.settingsData.settings),
        null,
        async () => api.loadSettings(),
        (draft, settings) => (draft.settingsData.settings = settings),
    )

export const loadUsersLimited = async () =>
    loadData(
        'user',
        (state) => Boolean(state.userData.usersLimited),
        null,
        async () => api.loadUsersLimited(),
        (draft, users) => (draft.userData.usersLimited = users),
        'user-limited',
    )

export const loadUsersFull = async () =>
    loadData(
        'user',
        (state) => Boolean(state.userData.usersFull),
        null,
        async () =>
            sort(await api.loadUsersFull(), [
                { getKey: (user) => user.firstName },
                { getKey: (user) => user.lastName },
            ]),
        (draft, users) => (draft.userData.usersFull = users),
        'user-full',
    )

export const loadVatState = async () =>
    loadData(
        'vat-state',
        (state) => Boolean(state.taxes.vat.paymentState),
        null,
        async () => api.loadVatState(),
        (draft, paymentState) => (draft.taxes.vat.paymentState = paymentState),
    )

const loadTermsFromFile = async (type: 'user' | 'company' | 'privacy'): Promise<Paragraph[]> => {
    const language = 'et' // TODO
    const response = await fetch('/terms/' + type + '.' + language + '.json')
    return response.json()
}

export const loadTerms = async () =>
    loadData(
        'terms',
        (state) => Boolean(state.terms),
        null,
        async () => ({
            user: await loadTermsFromFile('user'),
            company: await loadTermsFromFile('company'),
            privacy: await loadTermsFromFile('privacy'),
        }),
        (draft, terms) => (draft.terms = terms),
    )
