import { calculationModes } from '../../common/enums'
import { CalculationMode } from '../../common/types/enums'
import { ValidationError } from '../../common/types/errors'
import { Input, InputValues } from '../../common/types/inputs'
import { Totals } from '../../common/types/item'
import { amountFromString } from '../amount-from-string'
import { TotalsRightCell, TotalsRow, TotalsTableProps } from '../components/totals-table'
import { t } from '../i18n'
import { getValidationErrorProps } from '../val-err'

export const getTotalsTableProps = (props: {
    // TODO refactor?
    editable: boolean
    inputs?: {
        // Required if editable
        vat: Input<string>
        withVat: Input<string>
    }
    inputValues?: InputValues // Required if editable
    totals: Totals<number>
    vatMissing: boolean

    /** Currently expense-specific. Add an 'errorPrefix' prop if needed elsewhere. */
    valErrors?: ValidationError[]

    vatPayer: boolean
    calculationMode: CalculationMode
}): TotalsTableProps => {
    const manualCalculatePayableWithoutVat = () => {
        const { inputs, inputValues } = props

        if (!inputs || !inputValues) {
            throw new Error('inputs and inputValues are required for editable TotalsTable')
        }

        const vat = amountFromString(inputs.vat.get(inputValues))
        const withVat = amountFromString(inputs.withVat.get(inputValues))

        return withVat - vat
    }

    const getRow = (text: string, amount: number): TotalsRow => ({
        left: text,
        right: { amount, euroSign: true },
    })

    const getSumBeforeDiscountRow = () => {
        const { discount, totalWithoutVat } = props.totals
        return discount > 0 ? getRow(t.sumBeforeDiscount.get(), totalWithoutVat) : null
    }

    const getDiscountRow = () => {
        const { discount } = props.totals
        return discount > 0 ? getRow(t.discount.get(), discount) : null
    }

    const getPayableWithoutVatRow = () => {
        const { editable, totals, calculationMode } = props
        let { payableWithoutVat } = totals

        if (editable && calculationMode === calculationModes.manual) {
            payableWithoutVat = manualCalculatePayableWithoutVat()
        }

        return getRow(t.sumWithoutVat.get(), payableWithoutVat)
    }

    const getVatCell = (): TotalsRightCell => {
        const cell: TotalsRightCell = {
            euroSign: true,
            errors: getValidationErrorProps(props.valErrors, 'expense.totals.vat'),
        }

        const { editable, inputs, inputValues, totals, vatMissing } = props

        if (editable) {
            if (!inputs || !inputValues) {
                throw new Error('inputs and inputValues are required for editable TotalsTable')
            }

            cell.input = { className: 'amount', inputValues, input: inputs.vat }
        } else if (vatMissing) {
            cell.text = t.none.get()
            cell.euroSign = false
        } else {
            cell.amount = totals.vatAmount
        }

        return cell
    }

    const getVatRow = (): TotalsRow => ({
        left: t.vat.get(),
        right: getVatCell(),
    })

    const getTotalCell = (): TotalsRightCell => {
        const cell: TotalsRightCell = {
            euroSign: true,
            errors: getValidationErrorProps(props.valErrors, 'expense.totals.payableWithVat'),
        }

        const { editable, inputs, inputValues, totals } = props

        if (editable) {
            if (!inputs || !inputValues) {
                throw new Error('inputs and inputValues are required for editable TotalsTable')
            }

            cell.input = { className: 'amount', inputValues, input: inputs.withVat }
        } else {
            cell.amount = totals.payableWithVat
        }

        return cell
    }

    const getTotalRow = (): TotalsRow => ({
        className: 'bottom',
        left: t.payableTotal.get(),
        right: getTotalCell(),
    })

    const getVatPayerRows = (): Array<TotalsRow | null> => [
        getSumBeforeDiscountRow(),
        getDiscountRow(),
        getPayableWithoutVatRow(),
        getVatRow(),
        getTotalRow(),
    ]

    const getNonVatPayerRows = (): Array<TotalsRow | null> => [
        getSumBeforeDiscountRow(),
        getDiscountRow(),
        getTotalRow(),
    ]

    const rowsAndNulls = props.vatPayer ? getVatPayerRows() : getNonVatPayerRows()

    return {
        rows: rowsAndNulls.filter((row): row is TotalsRow => Boolean(row)),
    }
}
