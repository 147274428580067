import React, { FC } from 'react'

import { MAX_UPLOAD_MB, MB } from '../../../common/constants'
import { getExpenseFileKey } from '../../../common/expense-utils'
import { sort } from '../../../common/sort'
import { ApiExpense, ExpenseFile, FileStorageUsage } from '../../../common/types/expense'
import { Processes } from '../../../common/types/processes'
import { ServerConf } from '../../../common/types/server-conf'
import { Column } from '../../../common/types/table'
import { t } from '../../i18n'
import {
    addExpenseFile,
    REMOVE_FILE_PROCESS_PREFIX,
    removeExpenseFile,
    UPLOAD_PROCESS,
} from '../../state/expense-actions'
import { browserOnly } from '../../table-utils'
import { DeleteIcon } from '../delete-icon'
import { FileInput } from '../file-input'
import { LoadingIcon } from '../loading-icon'
import { type BaseRow, renderTable } from '../table'

interface Props {
    expense: ApiExpense
    processes: Processes
    serverConf: ServerConf
    fileStorageUsage: FileStorageUsage
}

const roundMb = (value: number) => Math.round(value * 100) / 100

const renderFileInput = ({ expense, processes }: Props) => {
    if (processes.has(UPLOAD_PROCESS)) {
        return React.createElement(LoadingIcon, { color: 'black' })
    }

    return React.createElement(FileInput, {
        text: t.addFile.get(),
        onSelect: async (file) => addExpenseFile(expense._id, file),
        className: 'button--secondary',
    })
}

const getColumns = ({ expense, processes, serverConf }: Props): Column<ExpenseFile>[] => [
    {
        header: { content: t.name.get() },
        render: browserOnly((file) => {
            const key = getExpenseFileKey(expense._id, file.hash, file.filename)
            const href = serverConf.fileStorageBaseUrl + key
            return React.createElement('a', { href, target: '_blank' }, file.filename)
        }),
    },
    {
        header: { content: t.size.get() },
        getProps: () => ({ className: 'text-right' }),
        render: (row) => {
            if (row.size < 10240) {
                const roundedKb = Math.round((100 * row.size) / 1024) / 100
                return roundedKb + ' KB'
            } else {
                const roundedMb = Math.round((100 * row.size) / MB) / 100
                return roundedMb + ' MB'
            }
        },
    },
    {
        header: { content: t.actions.get() },
        getProps: () => ({ className: 'text-center' }),
        render: browserOnly((file) => {
            if (processes.has(REMOVE_FILE_PROCESS_PREFIX + file.hash)) {
                return React.createElement(LoadingIcon, { color: 'black' })
            }

            const onClick = async () => {
                if (confirm(t.confirm.removeExpenseFile.get())) {
                    await removeExpenseFile(expense._id, file)
                }
            }

            return React.createElement(DeleteIcon, { onClick })
        }),
    },
]

const renderFileTable = (props: Props) => {
    const files = props.expense.files || []

    if (!files.length) {
        return null
    }

    return renderTable<ExpenseFile & BaseRow>({
        columns: getColumns(props),
        rows: sort(files, (file) => file.filename, { clone: true }),
        tableClassName: 'table table--bottom-border bottom-margin',
    })
}

export const ExpenseFiles: FC<Props> = (props) => {
    const {
        fileStorageUsage: { currentMb, maxMb },
    } = props
    const usagePercent = Math.round((100 * currentMb) / maxMb)

    return React.createElement(
        'div',
        null,
        React.createElement('h3', { className: 'section-title' }, t.files.get()),
        renderFileTable(props),
        renderFileInput(props),
        React.createElement(
            'div',
            { className: 'top-margin' },
            React.createElement('div', null, t.files.singleFileLimit.get(MAX_UPLOAD_MB)),
            React.createElement(
                'div',
                null,
                t.files.companyUsage.get(usagePercent, roundMb(currentMb), roundMb(maxMb)),
            ),
        ),
    )
}
