// Due to the inaccuracy of floating point calculations, we need a pre-rounding step.
//
// roundDown() and roundUp() are meant to operate with an accuracy of one cent (0.01).
// For example, roundDown(0.777) should return 0.77
// This example would also work fine without preRound: Math.floor(0.777 * 100) / 100 === 0.77
//
// However, there are exceptions, for example: roundDown(1.13)
// Because in JavaScript, 1.13 * 100 = 112.99999999999999
// So Math.floor(1.13 * 100) / 100 === 1.12
//
// Therefore we use a preRound function that works like this:
// preRound(0.777 * 100) === 77.7
// preRound(1.13 * 100) === 113
//
// and Math.floor(preRound(1.13 * 100)) / 100 === 1.13
const preRound = (value: number) => Math.round(value * 1000) / 1000

export const roundDown = (value: number) => Math.floor(preRound(value * 100)) / 100
export const roundUp = (value: number) => Math.ceil(preRound(value * 100)) / 100
export const roundToNearestCent = (value: number) => Math.round(value * 100) / 100
