import React from 'react'

import { t } from '../../i18n'
import { FileInput } from '../file-input'

interface Props {
    onSelect: (file: File) => void
}

const acceptedFileTypes = 'image/jpeg,image/png'

export const LogoInput = ({ onSelect }: Props) =>
    React.createElement(FileInput, {
        className: 'button--primary-light',
        accept: acceptedFileTypes,
        text: t.settings.logo.pickNew.get(),
        onSelect,
    })
