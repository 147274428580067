import classnames from 'classnames'
import React, { FC, ReactNode } from 'react'

import { getExpenseAccountNumber } from '../../common/accounts'
import { roundDown } from '../../common/amount-utils'
import { MIN_DATE } from '../../common/clock'
import { expenseAccountTypes, expenseItemTypes, expenseTypes } from '../../common/enums'
import { getPendingStockChangeLookup } from '../../common/expense-utils'
import { findByDbId } from '../../common/find-by-db-id'
import {
    calculateAutomaticItemFromValues,
    calculateItem,
    getItemQuantity,
    getItemUnitPrice,
} from '../../common/item-utils'
import { keys } from '../../common/keys'
import { sort, SortOption } from '../../common/sort'
import { Day } from '../../common/time'
import { AccountData } from '../../common/types/account'
import { ValidationError } from '../../common/types/errors'
import { ApiExpense, PendingStockChange } from '../../common/types/expense'
import { InputValues, Input as TInput } from '../../common/types/inputs'
import { Column } from '../../common/types/table'
import { getAccountName } from '../account-utils'
import { amountFromString } from '../amount-from-string'
import { assertViewName } from '../assert-view-name'
import { formatAmountForInput } from '../format-amount-for-input'
import { t } from '../i18n'
import { createCustomInput } from '../input-utils'
import { inputs } from '../inputs'
import { toComma } from '../number-utils'
import { renderAmount, renderAmountOrDash } from '../render-amount'
import { setRoute } from '../route-utils'
import {
    createStockChange,
    SAVE_STOCK_CHANGE_PROCESS,
    updateStockChange,
} from '../state/expense-actions'
import { RootData } from '../state/root-data'
import { browserOnly } from '../table-utils'
import { valErr } from '../val-err'
import { BackLink } from './back-link'
import { Button } from './button'
import { DateInput } from './date-input'
import { Input } from './input'
import { LoadingIcon } from './loading-icon'
import { renderSortOptions } from './sort-options'
import { renderTable } from './table'
import { ViewIcon } from './view-icon'

interface ChangeInputs {
    newQuantity: TInput<string>
    newUnitPrice: TInput<string>
}

interface Row {
    className?: string
    disabled: boolean
    domTitle?: string
    expenseId: string
    itemId: string
    date: Day
    vendor: string
    account: string
    description: string
    oldQuantity: number
    unit: string
    oldUnitPrice: number
    oldWithoutVat: number
    originalWithoutVat: number
    newWithoutVat: number
    createTime: string
    changeInputs: ChangeInputs
    errorPrefix: string
}

interface Totals {
    oldWithoutVat: number
    newWithoutVat: number
}

export type SortId = 'date' | 'account' | 'amount' | 'vendor'

const tableInputs = inputs.expense.goods.stock

const LEVEL3_NUMBER = getExpenseAccountNumber(expenseItemTypes.goodsStock)

const alignRight = () => ({ className: 'text-right' })
const getAmountClass = () => ({ className: 'amount' })

const getColumns = (
    inputValues: InputValues,
    valErrors: ValidationError[] | undefined,
): Column<Row, Totals>[] => [
    {
        header: { content: t.date.get() },
        render: (row) => row.date.dmy(),
    },
    {
        header: { content: t.expenses.vendor.get() },
        render: (row) => row.vendor,
    },
    {
        header: { content: t.account.get() },
        render: (row) => row.account,
    },
    {
        header: { content: t.description.get() },
        render: (row) => row.description,
    },
    {
        header: { content: t.quantity.initial.get(), getProps: alignRight },
        getProps: getAmountClass,
        render: (row) => toComma(String(row.oldQuantity)) + ' ' + row.unit,
    },
    {
        header: { content: t.quantity.new.get(), getProps: alignRight },
        getProps: () => ({ className: 'stock-list__input-cell shaded' }),
        render: browserOnly((row) => {
            if (row.disabled) {
                return ''
            }

            return React.createElement(
                'div',
                null,
                React.createElement(Input, {
                    input: row.changeInputs.newQuantity,
                    inputValues,
                    className: 'amount',
                }),
                valErr(valErrors, row.errorPrefix + '.newQuantity'),
            )
        }),
    },
    {
        header: { content: t.unitPrice.initial.get(), getProps: alignRight },
        getProps: getAmountClass,
        render: (row) => renderAmount(row.oldUnitPrice) + (row.unit ? '/' + row.unit : ''),
    },
    {
        header: { content: t.unitPrice.new.get(), getProps: alignRight },
        getProps: () => ({ className: 'stock-list__input-cell shaded' }),
        render: browserOnly((row) => {
            const { disabled, changeInputs } = row

            if (disabled) {
                return ''
            }

            const input = changeInputs.newUnitPrice

            return React.createElement(
                'div',
                null,
                React.createElement(Input, { input, inputValues, className: 'amount' }),
                ' ',
                React.createElement(Button, {
                    text: t.calculateNew.get(),
                    onClick: () => {
                        const newQuantity = amountFromString(
                            changeInputs.newQuantity.get(inputValues),
                        )
                        // Round down to ensure that newWithoutVat <= oldWithoutVat
                        input.set(formatAmountForInput(roundDown(row.oldWithoutVat / newQuantity)))
                    },
                    className: 'button--primary stock-list__calc-button',
                }),
                valErr(valErrors, row.errorPrefix + '.newUnitPrice'),
            )
        }),
    },
    {
        header: { content: t.total.initial.get(), getProps: alignRight },
        getProps: getAmountClass,
        getTotalProps: getAmountClass,
        render: (row) => renderAmountOrDash(row.oldWithoutVat),
        getTotal: (totals) => renderAmount(totals.oldWithoutVat),
    },
    {
        header: { content: t.total.new.get(), getProps: alignRight },
        getProps: (row) => ({
            className: classnames('amount', 'shaded', {
                'text-warning': row.newWithoutVat > row.originalWithoutVat,
            }),
        }),
        getTotalProps: getAmountClass,
        render: browserOnly((row) => {
            if (row.disabled) {
                return ''
            }

            return React.createElement(
                'div',
                null,
                renderAmountOrDash(row.newWithoutVat),
                valErr(valErrors, row.errorPrefix + '.newWithoutVat', {
                    'over-max': t.expenses.stock.validation.overOriginal.get(),
                }),
            )
        }),
        getTotal: (totals) => renderAmount(totals.newWithoutVat),
    },
    {
        header: {
            content: t.actions.get(),
            getProps: () => ({ className: 'text-center' }),
        },
        getProps: () => ({ className: 'actions text-center' }),
        render: browserOnly((row) =>
            React.createElement(ViewIcon, { href: '#/expenses/view/' + row.expenseId }),
        ),
    },
]

const getRows = (
    date: string,
    expenses: ApiExpense[],
    inputValues: InputValues,
    totals: Totals,
    sortOption: SortOption<Row>,
    accountData: AccountData,
    pendingStockChanges: PendingStockChange[],
    isNew: boolean,
    changeId: string,
): Row[] => {
    const isAlreadyPending = getPendingStockChangeLookup(pendingStockChanges)

    let isFromCurrent: typeof isAlreadyPending = () => false

    if (!isNew) {
        const current = findByDbId(pendingStockChanges, changeId)!
        isFromCurrent = getPendingStockChangeLookup([current])
    }

    const rows: Row[] = []

    for (const expense of expenses) {
        if (expense.type !== expenseTypes.regular || !expense.confirmed || expense.date > date) {
            continue
        }

        const { calculationMode, items, vendor } = expense
        const expenseDate = Day.fromYmd(expense.date)
        const createTime = expense.log[0].time

        for (const item of items!) {
            const { id: itemId, type, description } = item

            if (type === expenseItemTypes.goodsStock) {
                if (!isNew && !isFromCurrent(expense._id, itemId)) {
                    continue
                }

                const account = getAccountName(
                    LEVEL3_NUMBER,
                    item.account,
                    accountData,
                    expenseAccountTypes.goods,
                )

                const unit = item.unit ? t.enums.units[item.unit].get() : ''
                const oldQuantity = getItemQuantity(item, date)
                const oldUnitPrice = getItemUnitPrice(calculationMode, item, date)
                const oldWithoutVat = calculateItem(calculationMode, item, date).payableWithoutVat
                totals.oldWithoutVat += oldWithoutVat

                const originalWithoutVat = calculateItem(
                    calculationMode,
                    item,
                    MIN_DATE,
                ).payableWithoutVat

                const changeInputs = inputs.expense.goods.stock.changes(expense._id)(itemId)
                const newQuantity = amountFromString(changeInputs.newQuantity.get(inputValues))
                const newUnitPrice = amountFromString(changeInputs.newUnitPrice.get(inputValues))

                let newWithoutVat

                const isChanged =
                    changeInputs.newQuantity.getRaw(inputValues) !== '' ||
                    changeInputs.newUnitPrice.getRaw(inputValues) !== ''

                if (isChanged) {
                    const vatPercentage = item.vatPercentage || 0

                    // Even if the expense is in manual mode, the totals modified by
                    // stock changes are always calculated with automatic mode logic.
                    const newTotals = calculateAutomaticItemFromValues(
                        newQuantity,
                        newUnitPrice,
                        0,
                        vatPercentage,
                    )

                    newWithoutVat = newTotals.payableWithoutVat
                } else {
                    newWithoutVat = calculateItem(calculationMode, item, date).payableWithoutVat
                }

                totals.newWithoutVat += newWithoutVat

                const alreadyPending =
                    isAlreadyPending(expense._id, item.id) && !isFromCurrent(expense._id, item.id)

                const hasLaterChanges = Boolean(
                    item.stockChanges?.some((change) => change.date >= date),
                )

                const disabled = alreadyPending || hasLaterChanges
                let domTitle

                if (alreadyPending) {
                    domTitle = t.expenses.stock.alreadyPendingChange.get()
                } else if (hasLaterChanges) {
                    const changes = item.stockChanges!
                    const latestChange = changes[changes.length - 1]
                    const latestDate = Day.fromYmd(latestChange.date).longDate()
                    domTitle = t.expenses.stock.hasLaterChanges.get(latestDate)
                }

                rows.push({
                    className: disabled ? 'stock-list__disabled-row' : undefined,
                    disabled,
                    domTitle,
                    expenseId: expense._id,
                    itemId,
                    date: expenseDate,
                    vendor: vendor.name,
                    account,
                    description,
                    oldQuantity,
                    unit,
                    oldUnitPrice,
                    oldWithoutVat,
                    originalWithoutVat,
                    newWithoutVat,
                    createTime,
                    changeInputs,
                    errorPrefix: 'items.' + expense._id + '.' + itemId,
                })
            }
        }
    }

    return sort(rows, sortOption)
}

const getDate = (
    isNew: boolean,
    date: string,
    changeId: string,
    pendingStockChanges: PendingStockChange[],
): string => {
    if (isNew) {
        if (!date) {
            // This check is mostly to aid TypeScript's inference.
            throw new Error('date can not be undefined')
        }

        return date
    } else {
        const change = findByDbId(pendingStockChanges, changeId)

        if (!change) {
            throw new Error('Invalid stock change id: ' + changeId)
        }

        return change.date
    }
}

const renderSubtitle = (rootData: RootData, isNew: boolean, date: string, changeId: string) => {
    const {
        expenseData: { pendingStockChanges },
    } = rootData

    if ((isNew && !date) || !pendingStockChanges) {
        return null
    }

    const dateYmd = getDate(isNew, date, changeId, pendingStockChanges)
    return React.createElement(
        'span',
        { className: 'title__sub-title' },
        t.asOf.get(Day.fromYmd(dateYmd).longDate()),
    )
}

const renderDateSelection = (): ReactNode[] => {
    const input = createCustomInput({
        inputType: 'string',
        get: () => '',
        set: (date: string) => setRoute('#/expenses/add-stock-change/' + date),
    })

    return [t.chooseDate.get(), ': ', React.createElement(DateInput, { input, inputValues: {} })]
}

const renderContent = (
    rootData: RootData,
    isNew: boolean,
    date: string,
    changeId: string,
): ReactNode[] => {
    const {
        accountData,
        expenseData: { expenses, pendingStockChanges },
        inputValues,
        processes,
        validationErrors,
    } = rootData

    if (isNew && !date) {
        return renderDateSelection()
    }

    if (!expenses || !pendingStockChanges || !accountData.accounts) {
        return [React.createElement(LoadingIcon, { color: 'black' })]
    }

    const sortOptions: { [S in SortId]: SortOption<Row> } = {
        date: [
            { getKey: (row) => row.date.toTimestamp(), reverse: true },
            { getKey: (row) => row.createTime, reverse: true },
        ],
        account: [{ getKey: (row) => row.account }],
        amount: [{ getKey: (row) => row.oldWithoutVat, reverse: true }],
        vendor: [{ getKey: (row) => row.vendor }],
    }

    const dateYmd = getDate(isNew, date, changeId, pendingStockChanges)
    const sortId = tableInputs.sort.get(inputValues)

    const totals: Totals = { oldWithoutVat: 0, newWithoutVat: 0 }

    const rows = getRows(
        dateYmd,
        expenses,
        inputValues,
        totals,
        sortOptions[sortId],
        accountData,
        pendingStockChanges,
        isNew,
        changeId,
    )

    const valErrors = validationErrors[SAVE_STOCK_CHANGE_PROCESS]
    const columns = getColumns(inputValues, valErrors)

    return [
        React.createElement(
            'div',
            { className: 'flex space-between flex-end' },
            rows.length
                ? renderSortOptions({
                      input: tableInputs.sort,
                      inputValues,
                      options: keys(sortOptions).map((key) => ({
                          id: key,
                          label: t.expenses.sortOption[key].get(),
                      })),
                  })
                : null,
        ),
        renderTable({
            columns,
            rows,
            totals: isNew ? totals : undefined, // Hide totals row when viewing existing
            stickyHeader: true,
            tableClassName: 'main-table',
            wrapperClassName: 'main-table-wrapper',
        }),
        valErr(valErrors, 'items', { empty: t.expenses.stock.noChanges.get() }),
        React.createElement(
            'div',
            { className: 'top-margin' },
            React.createElement(Button, {
                text: t.saveDraft.get(),
                onClick: async () =>
                    isNew ? createStockChange(dateYmd) : updateStockChange(changeId),
                processes,
                processName: SAVE_STOCK_CHANGE_PROCESS,
                className: 'button--primary',
            }),
        ),
    ]
}

export const StockChange: FC<RootData> = (rootData) => {
    const { isNew, date, changeId } = assertViewName(rootData.view, 'StockChange')

    return React.createElement(
        'div',
        { className: 'content-area stock-list' },
        React.createElement(
            'div',
            { className: 'content' },
            React.createElement(BackLink),
            React.createElement(
                'h1',
                { className: 'title' },
                t.expenses.stock.change.get(),
                renderSubtitle(rootData, isNew, date, changeId),
            ),
            ...renderContent(rootData, isNew, date, changeId),
        ),
    )
}
