import * as Sentry from '@sentry/react'

import { schedule } from '../../common/clock'
import { Time } from '../../common/time'
import { Scheduled } from '../../common/types/clock'
import { ApiSession } from '../../common/types/session'
import * as api from '../api'
import { fromErrorCode, onWarning, processWarning } from '../error-manager'
import {
    emitAfterStoreReset,
    emitCloseWarnings,
    emitUpdatedSession,
    onFirstVersionMismatch,
    onUpdatedSession,
} from '../event-bus'
import { inputs } from '../inputs'
import { setRoute } from '../route-utils'
import { logoutClient } from '../session-utils'
import { loadCompanies } from './load-actions'
import { run } from './process-actions'
import { dispatch, getState, reset } from './store'
import { updateView } from './view-actions'

export const LOGIN_PROCESS = 'login'
export const LOGOUT_PROCESS = 'logout'
export const SELECT_COMPANY_PROCESS = 'select-company'

let versionMismatch = false
let scheduled: Scheduled | null = null

const clearScheduled = () => {
    if (scheduled) {
        scheduled.clear()
        scheduled = null
    }
}

export const logout = async () => {
    await run(LOGOUT_PROCESS, async () => {
        await api.logout()
        logoutClient()
    })
}

export const selectCompany = async (id: string | null) => {
    await run(SELECT_COMPANY_PROCESS, async () => {
        const { inputValues, session } = getState()

        const password =
            id !== null && !session!.firstCompanySelection
                ? inputs.changeCompany.password.get(inputValues)
                : null

        const newSession = await api.selectCompany(id, password)
        reset(false)
        emitUpdatedSession(newSession)
        emitAfterStoreReset()

        const routeChanged = setRoute('#/')

        if (!routeChanged) {
            updateView()
        }
    })
}

export const renew = async (): Promise<void> => {
    const session = await api.renewSession()
    emitUpdatedSession(session)
}

// Will trigger logoutClient via onWarning
const onExpired = () => processWarning(fromErrorCode('session-expired').dontReport())

export const init = () => {
    onWarning((errorCode) => {
        if (errorCode === 'auth-failed' || errorCode === 'session-expired') {
            logoutClient()
        }
    })

    onUpdatedSession((session: ApiSession) => {
        localStorage.setItem('bookySession', JSON.stringify(session))
        dispatch((draft) => (draft.session = session))

        void loadCompanies()

        clearScheduled()
        const msLeft = Time.fromIso(session.expires).diff(Time.now())
        scheduled = schedule(msLeft, onExpired, 'session-expired')

        Sentry.setUser({
            email: session.email,
            username: session.displayName,
            companyId: session.companyId,

            // Our userId-s are not very readable, so we don't want to use them as Sentry.user.id.
            // Instead, we send it as a custom field, which makes Sentry display the email and/or
            // username instead.
            userId: session.userId,
        })
    })

    onFirstVersionMismatch(() => {
        versionMismatch = true
    })

    const savedSessionJson = localStorage.getItem('bookySession')

    if (savedSessionJson) {
        const savedSession = JSON.parse(savedSessionJson) as ApiSession
        const expDate = Time.fromIso(savedSession.expires)

        if (expDate.isBefore(Time.now())) {
            // TODO show session-expired warning?
            logoutClient()
        } else {
            emitUpdatedSession(savedSession)
        }
    }
}

export const login = async () => {
    await run(LOGIN_PROCESS, async () => {
        emitCloseWarnings('auth-failed')
        emitCloseWarnings('session-expired')

        const { inputValues } = getState()
        const email = inputs.login.email.get(inputValues)
        const password = inputs.login.password.get(inputValues)
        const mode = inputs.login.mode.get(inputValues)
        const session = await api.login(email, password, mode)

        // It might seem like the clearing of the values could be delayed until
        // the login form is shown again (which may not even happen during this session),
        // but at least clearing the password input is critical for security.
        // Otherwise it would remain in the state in plaintext form.
        inputs.login.email.set('')
        inputs.login.password.set('')

        emitUpdatedSession(session)
        const routeChanged = setRoute('#/')

        if (versionMismatch) {
            // Immediately after login, the user can't be in the middle of something, so we can
            // reload the page automatically instead of prompting the user to do it manually.
            window.location.reload()
        } else if (!routeChanged) {
            updateView()
        }
    })
}
