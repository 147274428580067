// TODO use Time instead of Moment
import moment from 'moment'
import ms from 'ms'
import React, { Component } from 'react'

import { Input, InputValues } from '../../common/types/inputs'

type Moment = moment.Moment

export interface TimeRangeInput {
    center: Moment
    range: number
}
interface Props {
    input: Input<TimeRangeInput>
    inputValues: InputValues
}

interface State {
    center: Moment
    range: number
    mouseDown: boolean
    downX: number
    downTime: Moment | null
}

type InitUnit = 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year'
type Increment = (time: Moment) => void
type IsMajor = (time: Moment) => boolean
type StripeUnit = 'day' | 'month' | 'year'

interface Conf {
    initUnit: InitUnit
    increment: Increment
    isMajor: IsMajor
    majorFormat: string | null
    stripeUnit: StripeUnit
}

interface Stripe {
    startPerc: number
    diff: number
    hasBg: boolean
    str: string | null
}
interface Marker {
    isMajor: boolean
    perc: number
    str?: string
}

// TODO take as props?
const WIDTH = 800
const HEIGHT = 40
const STRIPE_FORMATS = { day: 'DD.MM', month: 'MM.YYYY', year: 'YYYY' }
const MAX_RANGE = 5 * 366 * 24 * 60 * 60
const RANGE_EXP = Math.pow(MAX_RANGE, 1 / 100)

const GRAY = 'hsl(0, 0%, 70%)'

const roundToHalf = (value: number) => Math.round(value - 0.5) + 0.5

// Using a stateful component for performance reasons

export class TimeRange extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const { input, inputValues } = props
        const { center, range } = input.get(inputValues)
        this.state = { center, range, mouseDown: false, downX: 0, downTime: null }
    }

    override state: State
    svgElement: SVGElement | null = null

    override componentDidMount() {
        if (this.svgElement) {
            // Can't use preventDefault in onWheel().
            // See https://github.com/facebook/react/issues/14856
            this.svgElement.addEventListener('wheel', (evt) => evt.preventDefault())
        }
    }

    // TODO pinch zoom on touch screens?
    zoom = (zoomIn: boolean) =>
        this.setState(({ center, range }) => {
            const { input } = this.props
            const factor = zoomIn ? 0.8 : 1.2

            // Clamp range between 1 second and ~5 years
            const newRange = Math.min(Math.max(1, range * factor), MAX_RANGE)
            input.set({ center, range })
            return { range: newRange }
        })

    onWheel = (evt: WheelEvent) => this.zoom(evt.deltaY < 0)

    onMouseDown = (evt: MouseEvent) => {
        const { center } = this.state
        this.setState({ mouseDown: true, downX: evt.clientX, downTime: center.clone() })
        evt.preventDefault()
    }

    onMouseUp = () => {
        const { input, inputValues } = this.props
        const { center, range } = this.state
        this.setState({ mouseDown: false })

        const original: Moment = input.get(inputValues).center

        if (!center.isSame(original)) {
            input.set({ center, range })
        }
    }

    onMouseMove = (evt: MouseEvent) => {
        const { range, mouseDown, downX, downTime } = this.state

        if (mouseDown) {
            const totalDiff = range * ms('2s')
            const diff = evt.clientX - downX
            const perc = diff / WIDTH
            const timeDiff = totalDiff * perc
            const newTime = downTime!.clone().subtract(timeDiff, 'ms')
            this.setState({ center: newTime })
        }

        evt.preventDefault()
    }

    getConf(from: Moment, to: Moment): Conf {
        const totalDiff = to.diff(from)
        let initUnit: InitUnit
        let increment: Increment
        let isMajor: IsMajor
        let majorFormat = null
        let stripeUnit: StripeUnit

        if (totalDiff < ms('90s')) {
            if (totalDiff < ms('10s')) {
                initUnit = 'second'
                increment = (current) => current.add(1, 'second')
                isMajor = () => true
            } else if (totalDiff < ms('45s')) {
                initUnit = 'second'
                increment = (current) => current.add(1, 'second')
                isMajor = (current) => current.second() % 5 === 0
            } else {
                initUnit = 'minute'
                increment = (current) => current.add(5, 'seconds')
                isMajor = (current) => current.second() % 15 === 0
            }

            majorFormat = 'HH:mm:ss'
            stripeUnit = 'day'
        } else if (totalDiff < ms('40h')) {
            if (totalDiff < ms('10m')) {
                initUnit = 'minute'
                increment = (current) => current.add(15, 'seconds')
                isMajor = (current) => current.second() === 0
            } else if (totalDiff < ms('50m')) {
                initUnit = 'minute'
                increment = (current) => current.add(1, 'minute')
                isMajor = (current) => current.minute() % 5 === 0
            } else if (totalDiff < ms('2.5h')) {
                initUnit = 'hour'
                increment = (current) => current.add(5, 'minutes')
                isMajor = (current) => current.minute() % 15 === 0
            } else if (totalDiff < ms('10h')) {
                initUnit = 'hour'
                increment = (current) => current.add(15, 'minutes')
                isMajor = (current) => current.minute() === 0
            } else {
                initUnit = 'hour'
                increment = (current) => current.add(1, 'hour')
                isMajor = (current) => current.hour() % 4 === 0
            }

            majorFormat = 'HH:mm'
            stripeUnit = 'day'
        } else if (totalDiff < ms('10d')) {
            initUnit = 'day'
            // Can't use current.add(4, 'hours') because of DST
            increment = (current) => current.hour(current.hour() + 4)
            isMajor = (current) => current.hour() === 0
            stripeUnit = 'day'
        } else if (totalDiff < ms('50d')) {
            initUnit = 'day'
            increment = (current) => current.add(1, 'day')

            const days = new Set([1, 5, 10, 15, 20, 25])
            isMajor = (current) => days.has(current.date())

            majorFormat = 'DD.MM'
            stripeUnit = 'month'
        } else if (totalDiff < ms('100d')) {
            initUnit = 'month'

            increment = (current) => {
                const dayOfMonth = current.date()

                if (dayOfMonth === 1) {
                    current.date(5)
                } else if (dayOfMonth <= 20) {
                    current.add(5, 'days')
                } else if (dayOfMonth === 25) {
                    current.startOf('month').add(1, 'month')
                } else {
                    throw new Error('Unexpected day of month: ' + dayOfMonth)
                }
            }

            const days = new Set([1, 10, 20])
            isMajor = (current) => days.has(current.date())

            majorFormat = 'DD.MM'
            stripeUnit = 'month'
        } else if (totalDiff < ms('240d')) {
            initUnit = 'month'

            increment = (current) => {
                const dayOfMonth = current.date()

                if (dayOfMonth === 1) {
                    current.date(10)
                } else if (dayOfMonth === 10) {
                    current.date(20)
                } else if (dayOfMonth === 20) {
                    current.startOf('month').add(1, 'month')
                } else {
                    throw new Error('Unexpected day of month: ' + dayOfMonth)
                }
            }

            isMajor = (current) => current.date() === 1
            stripeUnit = 'month'
        } else if (totalDiff < ms('4y')) {
            initUnit = 'month'
            increment = (current) => current.add(1, 'month')
            isMajor = (current) => current.date() === 1 && current.month() % 3 === 0
            majorFormat = 'DD.MM'
            stripeUnit = 'year'
        } else {
            initUnit = 'year'
            increment = (current) => current.add(3, 'month')
            isMajor = (current) => current.dayOfYear() === 1
            stripeUnit = 'year'
        }

        return { initUnit, increment, isMajor, majorFormat, stripeUnit }
    }

    stripeHasBackground(start: Moment, unit: StripeUnit) {
        if (unit === 'day') {
            const baseline = moment.utc('2017-01-01', 'YYYY-MM-DD', true)
            return start.diff(baseline, 'days') % 2 === 0
        } else if (unit === 'month') {
            return start.month() % 2 === 0
        } else if (unit === 'year') {
            return start.year() % 2 === 0
        } else {
            throw new Error('Unexpected unit: ' + unit)
        }
    }

    getStripes(conf: Conf, from: Moment, to: Moment) {
        const unit = conf.stripeUnit
        const dateFormat = STRIPE_FORMATS[unit]

        if (!dateFormat) {
            throw new Error('Date format missing for ' + unit)
        }

        const stripes = []
        const totalDiff = to.diff(from)
        let start = from.clone().startOf(unit)
        let startPerc = 0

        while (start.isBefore(to)) {
            const end = start.clone().add(1, unit)
            const endPerc = Math.min(1, end.diff(from) / totalDiff)
            const diff = endPerc - startPerc
            const str = start.format(dateFormat)
            const hasBg = this.stripeHasBackground(start, unit)
            stripes.push({ startPerc, diff, str, hasBg })
            start = end
            startPerc = endPerc
        }

        return stripes
    }

    getMarkers(conf: Conf, from: Moment, to: Moment) {
        const markers = []
        const totalDiff = to.diff(from)
        const current = from.clone().startOf(conf.initUnit)

        while (current.isBefore(to)) {
            if (current.isAfter(from)) {
                const isMajor = conf.isMajor(current)
                const perc = current.diff(from) / totalDiff
                const marker: Marker = { isMajor, perc }

                if (isMajor && conf.majorFormat) {
                    marker.str = current.format(conf.majorFormat)
                }

                markers.push(marker)
            }

            conf.increment(current)
        }

        return markers
    }

    renderText(from: Moment, to: Moment, format: string) {
        return React.createElement(
            'div',
            null,
            React.createElement('div', { style: { float: 'right' } }, to.format(format)),
            React.createElement('div', null, from.format(format)),
        )
    }

    renderStripeBackground({ diff, hasBg, startPerc }: Stripe) {
        if (hasBg) {
            const startX = roundToHalf(startPerc * WIDTH)

            // Could also calculate width from diff * WIDTH but that can
            // lead to different rounding than what is used for markers.
            const endX = roundToHalf((startPerc + diff) * WIDTH)
            const width = endX - startX

            return React.createElement('rect', {
                x: startX,
                y: 1,
                width,
                height: HEIGHT - 1,
                style: { fill: 'hsl(212, 60%, 95%)' },
            })
        } else {
            return null
        }
    }

    renderStripeText(stripe: Stripe, isFirst: boolean, isLast: boolean, elevated: boolean) {
        const { diff, startPerc, str } = stripe

        if (str) {
            const needed = str.length * 0.02
            let textX = startPerc + diff / 2

            if (diff < needed) {
                if (isFirst) {
                    textX = diff - needed / 2
                } else if (isLast) {
                    textX = 1 - diff + needed / 2
                }
            }

            const props = {
                x: textX * WIDTH,
                y: HEIGHT * (elevated ? 0.55 : 0.9),
                textAnchor: 'middle',
                style: { fill: 'hsl(212, 60%, 85%)', fontWeight: 'bold', fontSize: 24 },
            }

            return React.createElement('text', props, str)
        } else {
            return null
        }
    }

    renderMarkerLine(x: number, y1: number) {
        return React.createElement('line', {
            x1: x,
            y1,
            x2: x,
            y2: HEIGHT,
            style: { stroke: GRAY },
        })
    }

    renderMarkerText({ isMajor, str }: Marker, x: number) {
        if (isMajor) {
            const props = { x, y: HEIGHT * 0.4, textAnchor: 'middle', style: { fill: GRAY } }
            return React.createElement('text', props, str)
        } else {
            return null
        }
    }

    renderCenterMarker() {
        const x = roundToHalf(WIDTH / 2)

        return React.createElement('line', {
            x1: x,
            y1: 1,
            x2: x,
            y2: HEIGHT - 1,
            style: { stroke: GRAY, strokeDasharray: '2, 2' },
        })
    }

    renderBorder() {
        return React.createElement('rect', {
            x: 0.5,
            y: 0.5,
            width: WIDTH,
            height: HEIGHT,
            style: { fill: 'none', stroke: GRAY },
        })
    }

    renderSvg(from: Moment, to: Moment) {
        const conf = this.getConf(from, to)
        const stripes = this.getStripes(conf, from, to)
        const markers = this.getMarkers(conf, from, to)

        return React.createElement(
            'svg',
            {
                ref: (node: SVGElement) => (this.svgElement = node),
                width: WIDTH + 1,
                height: HEIGHT + 1,
                style: { display: 'block', cursor: 'ew-resize' },
                onWheel: this.onWheel,
                onMouseDown: this.onMouseDown,
                onMouseMove: this.onMouseMove,
                onMouseUp: this.onMouseUp,
                onMouseLeave: this.onMouseUp,
            },
            React.createElement(
                'g',
                null,
                ...stripes.map((stripe, index) => {
                    const isFirst = index === 0
                    const isLast = index === stripes.length - 1
                    const elevated = !conf.majorFormat

                    return React.createElement(
                        'g',
                        null,
                        this.renderStripeBackground(stripe),
                        this.renderStripeText(stripe, isFirst, isLast, elevated),
                    )
                }),
            ),
            React.createElement(
                'g',
                null,
                ...markers.map((marker) => {
                    const { isMajor, perc } = marker
                    const x = roundToHalf(perc * WIDTH)
                    const y1 = HEIGHT * (isMajor ? 0.5 : 0.75)

                    return React.createElement(
                        'g',
                        null,
                        this.renderMarkerLine(x, y1),
                        this.renderMarkerText(marker, x),
                    )
                }),
            ),
            this.renderCenterMarker(),
            this.renderBorder(),
        )
    }

    renderZoom() {
        const { range } = this.state
        const rangeValue = 100 - Math.round(Math.log(range) / Math.log(RANGE_EXP))

        return React.createElement(
            'div',
            null,
            'Zoom: ',
            React.createElement('input', {
                type: 'range',
                value: rangeValue,
                onChange: (evt) => {
                    const newRange = Math.pow(RANGE_EXP, 100 - Number(evt.currentTarget.value))
                    this.setState({ range: newRange })
                },
                style: { width: WIDTH - 80, verticalAlign: 'middle' },
            }),
        )
    }

    override render() {
        const { center, range } = this.state
        const from = center.clone().subtract(range, 'seconds')
        const to = center.clone().add(range, 'seconds')

        return React.createElement(
            'div',
            { style: { width: WIDTH + 1 } },
            this.renderText(from, to, 'D. MMMM YYYY'),
            this.renderSvg(from, to),
            this.renderText(from, to, 'HH:mm:ss'),
            this.renderZoom(),
        )
    }
}
