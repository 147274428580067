import React from 'react'

import { t } from '../../i18n'

interface Props {
    url?: string
}

const renderImage = (src: string) =>
    React.createElement('img', { src, className: 'logo settings__logo' })

const renderNoLogoText = () => React.createElement('span', null, t.settings.logo.notSet.get())

// TODO render loading icon while generating data URL
export const Logo = ({ url }: Props) => (url ? renderImage(url) : renderNoLogoText())
