import { TDocumentDefinitions } from 'pdfmake/interfaces'

import { onNextNavigation } from './event-bus'

export type PdfGenerator = (definition: TDocumentDefinitions) => Promise<string>

const defaultGenerator: PdfGenerator = async (definition) =>
    new Promise<string>((resolve, reject) => {
        // TODO generic worker util shared by pdf and zip?
        const worker = new Worker('workers/pdf.js')
        onNextNavigation(() => worker.terminate())
        let anyErrors = false

        worker.onerror = (evt) => {
            worker.terminate()

            anyErrors = true

            const error = new Error(evt.message)

            // A bit hacky - overwrite the stack to include info from the event
            error.stack =
                'Error: ' +
                evt.message +
                '\n' +
                '  at (' +
                evt.filename +
                ':' +
                evt.lineno +
                ':' +
                evt.colno +
                ')'

            reject(error)
        }

        worker.onmessage = ({ data }) => {
            if (data.type === 'error') {
                anyErrors = true
                const error = new Error(data.message as string)
                error.stack = data.stack as string
                reject(error)
            } else if (data.type === 'done') {
                if (anyErrors) {
                    return
                }

                const url = data.url as string
                resolve(url)
            } else {
                reject(new Error('Unrecognized message type: ' + data.type))
            }
        }

        worker.postMessage({ type: 'generate', definition })
    })

let generator = defaultGenerator

export const generatePdf = async (definition: TDocumentDefinitions) => generator(definition)

// For tests
export const setGenerator = (
    newGenerator: PdfGenerator,
    onReset: (callback: () => void) => void,
) => {
    generator = newGenerator
    onReset(() => {
        generator = defaultGenerator
    })
}
