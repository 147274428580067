import { formatAmount as formatAmountWithLanguage } from '../common/format-amount'
import { getCurrentLanguage } from './i18n'

export const formatAmount = (amount: number) => {
    return formatAmountWithLanguage(getCurrentLanguage(), amount, true)
}

export const formatAmountOrDash = (amount: number) => {
    return formatAmountWithLanguage(getCurrentLanguage(), amount, false)
}
