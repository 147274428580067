import { expenseItemTypes, expenseTypes } from '../common/enums'
import { ApiExpense, ExpenseItemType } from '../common/types/expense'
import { t } from './i18n'

export const getItemTypeLabel = (itemType: ExpenseItemType) => {
    if (itemType === expenseItemTypes.general) {
        return t.expenseItemTypes.general.get()
    } else if (itemType === expenseItemTypes.goodsExpense) {
        return t.expenseItemTypes['goods-expense'].short.get()
    } else if (itemType === expenseItemTypes.goodsStock) {
        return t.expenses.stock.get()
    } else {
        throw new Error('Unexpected expense item type: ' + itemType)
    }
}

export const getExpenseTypeLabel = (expense: ApiExpense) => {
    if (expense.type === expenseTypes.regular) {
        const types = new Set<ExpenseItemType>()

        for (const item of expense.items!) {
            types.add(item.type)
        }

        return [...types].map(getItemTypeLabel).join(' / ')
    } else if (expense.type === expenseTypes.asset) {
        return t.asset.get()
    } else {
        throw new Error('Unexpected type: ' + expense.type)
    }
}
