import * as Sentry from '@sentry/react'

export const initSentry = () => {
    // The env variables are replaced by esbuild via the 'define' option
    const sentryDsn = process.env.APP_SENTRY_FRONTEND_DSN

    if (!sentryDsn) {
        return
    }

    Sentry.init({
        dsn: sentryDsn,
        integrations: [
            Sentry.globalHandlersIntegration({
                onunhandledrejection: false,
            }),
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        tracesSampleRate: Number(process.env.APP_SENTRY_TRACES_RATE),
        replaysSessionSampleRate: Number(process.env.APP_SENTRY_REPLAY_RATE),
        replaysOnErrorSampleRate: 1.0,
        ignoreErrors: [
            // Connectivity issues
            'Failed to fetch',
            'Load failed',
            'NetworkError when attempting to fetch resource',

            // Page closed while operation in progress?
            'The operation was aborted',

            // Browser extensions breaking React
            'The node to be removed is not a child of this node',
            'The node before which the new node is to be inserted is not a child of this node',
        ],
    })
}
