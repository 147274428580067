import { EventEmitter } from 'fbemitter'

import { ErrorCode } from '../common/types/enums'
import { ApiSession } from '../common/types/session'

const emitter = new EventEmitter()

export const emitAfterRender = () => emitter.emit('after-render')
export const runAfterNextRender = (callback: () => void) => emitter.once('after-render', callback)

export const emitUiStateUpdated = () => emitter.emit('ui-state-updated')

export const onUiStateUpdated = (callback: () => void) => {
    return emitter.addListener('ui-state-updated', callback)
}

export const emitAfterStoreReset = () => emitter.emit('after-store-reset')
export const runAfterStoreReset = (callback: () => void) =>
    emitter.addListener('after-store-reset', callback)

export const emitUpdatedSession = (session: ApiSession) => emitter.emit('updated-session', session)

export const onUpdatedSession = (callback: (session: ApiSession) => void) => {
    return emitter.addListener('updated-session', callback)
}

export const emitCloseSessionExpiringBanner = () => emitter.emit('close-session-expiring-banner')

export const onCloseSessionExpiringBanner = (callback: () => void) => {
    return emitter.addListener('close-session-expiring-banner', callback)
}

export const emitVersionMismatch = () => emitter.emit('version-mismatch')
export const onFirstVersionMismatch = (callback: () => void) =>
    emitter.once('version-mismatch', callback)

export const emitCloseWarnings = (errorCode: ErrorCode) => emitter.emit('close-warnings', errorCode)

export const onCloseWarnings = (callback: (errorCode: ErrorCode) => void) => {
    return emitter.addListener('close-warnings', callback)
}

export const emitNavigation = () => emitter.emit('navigation')
export const onNextNavigation = (callback: () => void) => emitter.once('navigation', callback)

export const emitOpenFakeBanner = () => emitter.emit('open-fake-banner')
export const onOpenFakeBanner = (callback: () => void) =>
    emitter.addListener('open-fake-banner', callback)
export const emitCloseFakeBanner = () => emitter.emit('close-fake-banner')
export const onCloseFakeBanner = (callback: () => void) =>
    emitter.addListener('close-fake-banner', callback)

export const emitFocusInput = (inputId: string) => emitter.emit('focus-input-' + inputId)

export const onFocusInput = (inputId: string, callback: () => void) => {
    return emitter.addListener('focus-input-' + inputId, callback)
}

export const reset = () => emitter.removeAllListeners()
