import React, { FC, Fragment } from 'react'

import { isSuccessful } from '../../../common/card-payment-utils'
import { CardPaymentStatus } from '../../../common/enums'
import { findByDbId } from '../../../common/find-by-db-id'
import { Time } from '../../../common/time'
import { ApiCardPayment } from '../../../common/types/card-payment'
import { Processes } from '../../../common/types/processes'
import { assertViewName } from '../../assert-view-name'
import { renderAmount } from '../../render-amount'
import { ACTIVATE_PROCESS, activateCompany, getCompany } from '../../state/company-actions'
import { RootData } from '../../state/root-data'
import { Button } from '../button'
import { LoadingPage } from '../loading-page'
import { InitCompanyNav } from './nav'

// TODO i18n

const renderStatus = (status: CardPaymentStatus) => {
    if (isSuccessful(status)) {
        return 'Õnnestunud'
    } else if (status === CardPaymentStatus.initial) {
        return 'Pooleli'
    } else {
        return 'Ebaõnnestunud'
    }
}

const renderActivateButton = (isVisible: boolean, processes: Processes) => {
    if (!isVisible) {
        return null
    }

    return React.createElement(
        'div',
        null,
        React.createElement(Button, {
            onClick: activateCompany,
            text: 'Hakka programmi kasutama',
            processes,
            processName: ACTIVATE_PROCESS,
            className: 'button--white init-company__payment-button',
            loadingColor: 'white',
        }),
    )
}

const renderRetryButton = (isVisible: boolean) => {
    if (!isVisible) {
        return null
    }

    return React.createElement(
        'div',
        null,
        React.createElement(
            'a',
            {
                href: '#/init-company/billing',
                className: 'button button--white init-company__payment-button',
            },
            'Proovi uuesti',
        ),
    )
}

const renderPayment = (payment: ApiCardPayment | undefined, processes: Processes) => {
    if (!payment) {
        return React.createElement('div', null, 'Makset ei õnnestunud leida')
    }

    const { amountWithVat, status, success, initTime } = payment

    return React.createElement(
        Fragment,
        null,
        React.createElement('div', null, Time.fromIso(initTime).asLocal().longDateTime()),
        React.createElement('div', { className: 'init-company__payment-sub-title' }, 'SUMMA'),
        React.createElement('div', null, renderAmount(amountWithVat), ' ', 'koos käibemaksuga'),
        React.createElement('div', { className: 'init-company__payment-sub-title' }, 'STAATUS'),
        React.createElement('div', null, renderStatus(status)),
        React.createElement(
            'div',
            { className: 'init-company__button-row' },
            renderActivateButton(success, processes),
            renderRetryButton(status !== CardPaymentStatus.initial && !success),
            // TODO reload info button if status === new?
        ),
    )
}

export const InitCompanyPaymentStatus: FC<RootData> = (rootData) => {
    const { companyData, processes, session, view } = rootData

    if (!companyData.companies || !companyData.cardPayments) {
        return React.createElement(LoadingPage)
    }

    const company = getCompany(companyData, session)
    const { id } = assertViewName(view, 'InitCompanyPaymentStatus')
    const payment = findByDbId(companyData.cardPayments, id)

    return React.createElement(
        'div',
        { className: 'init-company' },
        React.createElement(InitCompanyNav, { company }),
        React.createElement(
            'div',
            { className: 'init-company__main' },
            React.createElement(
                'div',
                { className: 'init-company__main-inner init-company__payment-status' },
                React.createElement('div', { className: 'init-company__payment-title' }, 'MAKSE'),
                renderPayment(payment, processes),
            ),
        ),
    )
}
