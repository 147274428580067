import { LogEntry } from './log'
import { DbPayment } from './payment'

export interface LabourCostInput {
    month: string
    note: string
    gross: number
    taxFree: number
    calculatePension: boolean
    increaseSocialTax?: boolean
}

export interface ApiLabourCost extends LabourCostInput {
    _id: string
    rev: number
    confirmed: boolean
    netPayments: DbPayment[]
    removedNetPayments?: DbPayment[]
    netPaid: boolean
    taxPayments: DbPayment[]
    removedTaxPayments?: DbPayment[]
    taxPaid: boolean
    log: LogEntry[]
}

export interface DbLabourCost extends ApiLabourCost {
    companyId: string
}

export enum LabourCostViewMode {
    list = 'list',
    view = 'view',
    addInit = 'addInit',
    addSocial = 'addSocial',
    addFinal = 'addFinal',
}

export interface LabourCostData {
    labourCosts: ApiLabourCost[] | null
    mode: LabourCostViewMode
    id: string
}

export interface LabourCostCalc {
    gross: number
    net: number
    payroll: number
    socialTax: number
    socialTaxIncrease: number
    unemploymentEmployer: number
    pensionPayment: number
    unemploymentEmployee: number
    incomeTax: number
    expectedTaxFree: number
    actualTaxFree: number
    taxes: number
}
