import React, { FC, Fragment } from 'react'

import { CompanyStatus } from '../../../common/enums'
import { ApiCompany } from '../../../common/types/company'
import { ApiInvite } from '../../../common/types/invite'
import { Processes } from '../../../common/types/processes'
import { Column } from '../../../common/types/table'
import { t } from '../../i18n'
import { setRoute } from '../../route-utils'
import { RootData } from '../../state/root-data'
import { ACCEPT_PROCESS, acceptInvite } from '../../state/user-settings-actions'
import { browserOnly } from '../../table-utils'
import { Button } from '../button'
import { LoadingIcon } from '../loading-icon'
import { LoadingPage } from '../loading-page'
import { MenuToggle } from '../menu-toggle'
import { type BaseRow, renderTable } from '../table'
import { UserSettingsMenu } from './menu'

const renderInvites = (invites: ApiInvite[], processes: Processes) => {
    if (processes.has(ACCEPT_PROCESS)) {
        return React.createElement(LoadingIcon, { color: 'white' })
    }

    if (!invites.length) {
        return null
    }

    const columns: Column<ApiInvite>[] = [
        {
            header: { content: t.business.name.get() },
            render: (row) => row.companyName,
        },
        {
            header: { content: t.role.get() },
            render: (row) => t.roles[row.role].get(),
        },
        {
            header: { content: t.actions.get() },
            render: browserOnly((row) =>
                React.createElement(Button, {
                    text: t.acceptInvite.get(),
                    onClick: async () => acceptInvite(row._id),
                    className: 'button--transparent',
                }),
            ),
        },
    ]

    return React.createElement(
        Fragment,
        null,
        React.createElement('h3', null, t.userSettings.companies.invites.get()),
        renderTable<ApiInvite & BaseRow>({
            columns,
            rows: invites,
            tableClassName: 'user-settings__table',
            headerClassName: 'user-settings__table-header',
        }),
    )
}

const renderActiveCompanies = (companies: ApiCompany[]) => {
    if (!companies.length) {
        return null
    }

    const columns: Column<ApiCompany>[] = [
        {
            header: { content: t.business.name.get() },
            render: (row) => row.name,
        },
        {
            header: { content: t.role.get() },
            render: (row) => t.roles[row.role].get(),
        },
    ]

    return React.createElement(
        Fragment,
        null,
        React.createElement('h3', null, t.userSettings.companies.active.get()),
        renderTable<ApiCompany & BaseRow>({
            columns,
            rows: companies,
            tableClassName: 'user-settings__table',
            headerClassName: 'user-settings__table-header',
        }),
    )
}

const renderInitCompanies = (companies: ApiCompany[]) => {
    if (!companies.length) {
        return null
    }

    const columns: Column<ApiCompany>[] = [
        {
            header: { content: t.business.name.get() },
            render: (row) => row.name,
        },
    ]

    return React.createElement(
        Fragment,
        null,
        React.createElement('h3', null, t.userSettings.companies.init.get()),
        renderTable<ApiCompany & BaseRow>({
            columns,
            rows: companies,
            tableClassName: 'user-settings__table',
            headerClassName: 'user-settings__table-header',
        }),
    )
}

const renderNoCompanies = (anyCompanies: boolean) => {
    if (!anyCompanies) {
        return React.createElement(
            'div',
            { className: 'text-center' },
            t.userSettings.companies.noCompanies.get(),
        )
    } else {
        return null
    }
}

export const UserSettingsCompanies: FC<RootData> = (rootData) => {
    const {
        companyData: { companies },
        processes,
        userData: { receivedInvites },
    } = rootData

    if (!companies || !receivedInvites) {
        return React.createElement(LoadingPage)
    }

    const activeCompanies = companies.filter((company) => company.status === CompanyStatus.active)
    const initCompanies = companies.filter((company) => company.status === CompanyStatus.init)
    const anyCompanies = activeCompanies.length + initCompanies.length + receivedInvites.length > 0

    return React.createElement(
        'div',
        { className: 'user-settings open' },
        React.createElement(MenuToggle, { onClick: () => setRoute('#/') }),
        React.createElement('div', { className: 'user-settings__title' }, t.userSettings.get()),
        React.createElement(UserSettingsMenu, { selected: 'companies' }),
        React.createElement(
            'div',
            { className: 'user-settings__body user-settings__body--wide' },
            renderInvites(receivedInvites, processes),
            renderActiveCompanies(activeCompanies),
            renderInitCompanies(initCompanies),
            renderNoCompanies(anyCompanies),
        ),
    )
}
