import React, { FC, Fragment } from 'react'

import { Button, ButtonProps } from '../button'
import { ChoiceProps, renderChoice } from '../choice'
import { DateInput, DateInputProps } from '../date-input'
import { Link, LinkProps } from '../link'
import { LoadingPage } from '../loading-page'
import { RevenueArchiveSidebar } from './archive-sidebar'

interface ArchiveChoiceProps {
    title: string
    choice: ChoiceProps<string>
}

interface ArchiveDateProps {
    label: string
    input: DateInputProps
}

const renderTitle = (text: string) =>
    React.createElement('h1', { className: 'title archive__title' }, text)

const ArchiveChoice: FC<ArchiveChoiceProps> = ({ title, choice }) =>
    React.createElement(
        Fragment,
        null,
        renderTitle(title),
        React.createElement(
            'div',
            null,
            renderChoice({
                ...choice,
                buttonClassName: 'button--secondary',
                selectedButtonClassName: 'button--primary',
            }),
        ),
    )

const ArchiveDate: FC<ArchiveDateProps> = ({ label, input }) =>
    React.createElement(
        'div',
        { className: 'archive__date' },
        React.createElement('span', { className: 'archive__date-label' }, label),
        React.createElement(DateInput, {
            ...input,
            className: 'date-button archive__date-input',
        }),
    )

const renderShortcut = (props: Omit<ButtonProps, 'className'>) =>
    React.createElement(Button, {
        ...props,
        className: 'button--secondary archive__date-shortcut',
    })

export interface RevenueArchiveGeneralOkProps {
    loading: false
    status: ArchiveChoiceProps
    dateTitle: string
    dateFrom: ArchiveDateProps
    dateTo: ArchiveDateProps
    monthShortcuts: Omit<ButtonProps, 'className'>[]
    yearShortcuts: Omit<ButtonProps, 'className'>[]
    paid: ArchiveChoiceProps
    dueDate: ArchiveChoiceProps
    term: ArchiveChoiceProps
    forwardLink: LinkProps
    showLink: LinkProps
    clearFiltersButton?: ButtonProps
}

export type RevenueArchiveGeneralProps = { loading: true } | RevenueArchiveGeneralOkProps

export const RevenueArchiveGeneral: FC<RevenueArchiveGeneralProps> = (props) => {
    if (props.loading) {
        return React.createElement(LoadingPage)
    }

    const {
        status,
        dateTitle,
        dateFrom,
        dateTo,
        monthShortcuts,
        yearShortcuts,
        paid,
        dueDate,
        term,
        forwardLink,
        showLink,
        clearFiltersButton,
    } = props

    return React.createElement(
        'div',
        { className: 'content-area' },
        React.createElement(RevenueArchiveSidebar, { selected: 'general' }),
        React.createElement(
            'div',
            { className: 'content archive' },
            React.createElement(
                'div',
                null,
                React.createElement(ArchiveChoice, status),
                renderTitle(dateTitle),
                React.createElement(ArchiveDate, dateFrom),
                React.createElement(ArchiveDate, dateTo),
                React.createElement(
                    'div',
                    null,
                    React.createElement('div', null, ...monthShortcuts.map(renderShortcut)),
                    React.createElement('div', null, ...yearShortcuts.map(renderShortcut)),
                ),
                React.createElement(ArchiveChoice, paid),
                React.createElement(ArchiveChoice, dueDate),
                React.createElement(ArchiveChoice, term),
            ),
            React.createElement(
                'div',
                null,
                React.createElement(Link, { ...forwardLink, className: 'button button--primary' }),
                ' ',
                React.createElement(Link, { ...showLink, className: 'button button--secondary' }),
                ' ',
                clearFiltersButton && React.createElement(Button, clearFiltersButton),
            ),
        ),
    )
}
