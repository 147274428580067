import { ServerError } from '../../common/server-error'
import { Day } from '../../common/time'
import { ApiEntry, EntryInput, EntryItem, EntryItemInputs } from '../../common/types/entry'
import { InputValues } from '../../common/types/inputs'
import * as api from '../api'
import { getPreset, getPresetItemsFromInputs, getPresetState } from '../entry-utils'
import { fromErrorCode, processWarning } from '../error-manager'
import { formatAmountForInput } from '../format-amount-for-input'
import { t } from '../i18n'
import { generateId } from '../id-utils'
import { inputs } from '../inputs'
import { normalizeNumber } from '../number-utils'
import { setRoute } from '../route-utils'
import { setInvalid } from './invalid-cache-actions'
import { loadAccounts, loadCompanies, loadEntries } from './load-actions'
import { run } from './process-actions'
import { dispatch, getState } from './store'
import { clearErrors } from './validation-actions'

export const SAVE_PROCESS = 'entry/save'
export const REMOVE_PROCESS = 'entry/remove'

const invalidateCache = () => setInvalid('entry')

const initItems = () => {
    const ids = [generateId(), generateId()]
    dispatch(({ entryData }) => (entryData.itemIds = ids))
}

export const addItem = () => {
    const id = generateId()
    dispatch(({ entryData }) => entryData.itemIds.push(id))
    const itemInputs = inputs.entry.item(id)
    itemInputs.amount.set('')
}

export const removeItem = (id: string) =>
    dispatch(({ entryData }) => {
        const newIds = entryData.itemIds.filter((existing) => existing !== id)

        if (newIds.length === entryData.itemIds.length) {
            throw new Error('Item ' + id + ' not found')
        }

        entryData.itemIds = newIds
    })

const getItemInput = (
    itemId: string,
    input: EntryItemInputs,
    inputValues: InputValues,
): EntryItem<string> => ({
    id: itemId,
    type: input.type.get(inputValues),
    amount: input.amount.get(inputValues).replace(',', '.'),
    accountNumber: input.accountNumber.get(inputValues),
})

const getItemInputs = (
    presetId: string | undefined,
    itemIds: string[],
    inputValues: InputValues,
): EntryItem<string>[] => {
    const presetState = getPresetState(presetId, inputValues)

    if (presetState) {
        if (!presetState.isValidAmount) {
            throw new Error('Invalid entry amount')
        }

        return getPresetItemsFromInputs(presetState, normalizeNumber(presetState.amountString))
    } else {
        return itemIds.map((itemId) => getItemInput(itemId, inputs.entry.item(itemId), inputValues))
    }
}

const getEntryInput = (
    presetId: string | undefined,
    itemIds: string[],
    inputValues: InputValues,
): EntryInput => ({
    date: inputs.entry.date.get(inputValues),
    description: inputs.entry.description.get(inputValues),
    items: getItemInputs(presetId, itemIds, inputValues),
})

export const initForm = (presetId?: string) => {
    void loadAccounts()
    void loadCompanies()

    clearErrors(SAVE_PROCESS)
    inputs.entry.date.set('')
    inputs.entry.amount.set('')

    const {
        entryData: { copyFrom },
    } = getState()

    if (copyFrom) {
        dispatch(({ entryData }) => {
            entryData.copyFrom = null
            entryData.itemIds = copyFrom.items.map((item) => item.id)
        })

        inputs.entry.description.set(copyFrom.description)

        for (const item of copyFrom.items) {
            const itemInputs = inputs.entry.item(item.id)
            itemInputs.accountNumber.set(item.accountNumber)
            itemInputs.amount.set(formatAmountForInput(item.amount))
            itemInputs.type.set(item.type)
        }
    } else {
        const preset = getPreset(presetId)

        if (preset) {
            inputs.entry.description.set(t.entries.presets[preset.id].get())
        } else {
            inputs.entry.description.set('')
        }

        initItems()
    }
}

export const initList = async () => Promise.all([loadEntries(), loadAccounts()])

export const create = async (presetId: string) =>
    run(SAVE_PROCESS, async () => {
        const state = getState()
        const {
            entryData: { itemIds },
            inputValues,
        } = state

        const entry = getEntryInput(presetId, itemIds, inputValues)
        await api.createEntry(entry)
        invalidateCache()
        setRoute('#/entries/list')
    })

export const copyEntryFrom = async (entry: ApiEntry) => {
    dispatch(({ entryData }) => (entryData.copyFrom = entry))
    setRoute('#/entries/add')
}

export const remove = async (id: string) =>
    run(REMOVE_PROCESS, async () => {
        try {
            await api.removeEntry(id)
            invalidateCache()
            await loadEntries()
        } catch (error) {
            if (error instanceof ServerError && error.response.errorCode === 'negative-balance') {
                const date = error.response.date as string

                const message = t.entries.remove.negativeBalance.get(
                    Day.fromYmd(date).dmy(),
                    error.response.account,
                )

                processWarning(fromErrorCode('negative-balance').withMessage(message).dontReport())
            } else {
                throw error
            }
        }
    })
