import { ObjectId } from 'mongodb'

import { CardPaymentStatus } from '../enums'
import { MonthNumber } from './month-number'

export interface Card {
    holderName: string
    lastFourDigits: string
    month: MonthNumber
    year: number
    type: '' | 'master_card' | 'visa'
}

export enum CardPaymentType {
    first = 'first',
    recurring = 'recurring',

    /** Zero-amount payment for saving new credit card details */
    update = 'update',
}

export interface CardPaymentPeriod {
    from: string
    to: string
}

export interface CardPaymentError {
    code: number
    message: string
}

interface Common {
    type: CardPaymentType
    amountWithVat: number
    amountWithoutVat: number
    status: CardPaymentStatus

    /**
     * If this is false, it doesn't mean the payment has failed.
     * It might just not have succeeded yet.
     * The status field provides more accurate information,
     * the success field is just a simplified version of it.
     */
    success: boolean

    initTime: string
    card: Card
    period?: CardPaymentPeriod
}

export interface ApiCardPayment extends Common {
    _id: string
}

export interface PaymentEmail {
    time: string
    address: string
    type: 'invoice' | 'failure2' | 'failure3'
    status: 'sending' | 'sent' | 'failed'
}

export interface DbCardPayment extends Common {
    _id: ObjectId
    companyId: string
    paymentReference: string
    /** Written to by the backstage system */
    invoiceNumber: string
    cardToken: string
    emails?: PaymentEmail[]
    /** Written to by the backstage system */
    failCount?: number
    error?: CardPaymentError
}
