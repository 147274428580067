import classnames from 'classnames'
import React, { Component, Fragment } from 'react'

import { ApiSession } from '../../common/types/session'
import { addError } from '../error-manager'
import { t } from '../i18n'
import { getCompany } from '../state/company-actions'
import { RootData } from '../state/root-data'
import { View } from '../view'
import { Header } from './header'
import { Login } from './login'
import { Main } from './main'
import { Menu } from './menu'

interface State {
    hasError: boolean
}

const requiresAuth = (view: View | undefined) => {
    if (view && 'allowUnauth' in view && view.allowUnauth) {
        return false
    }

    return true
}

const shouldShowMenu = (view: View) => {
    if (view && 'noMenu' in view && view.noMenu) {
        return false
    }

    return true
}

export class Root extends Component<RootData, State> {
    override state = { hasError: false }

    static getDerivedStateFromError(): Partial<State> {
        return { hasError: true }
    }

    override componentDidCatch(error: Error) {
        if (process.env.NODE_ENV === 'development' && !process.isBrowser) {
            process.BOOKY_TEST_RENDER_ERROR = error
        }

        if (process.env.NODE_ENV === 'production') {
            void addError(error)
        }
    }

    renderHeader() {
        const {
            companyData: { companies },
            processes,
            session,
        } = this.props
        return React.createElement(Header, { session, companies, processes })
    }

    renderMenu(session: ApiSession) {
        const { companyData, menu } = this.props

        const company =
            companyData.companies && session.companyId
                ? getCompany(companyData, session)
                : undefined

        return React.createElement(
            'div',
            { className: classnames('menu', menu.mode) },
            React.createElement(Menu, { session, company, menuState: menu }),
        )
    }

    renderBody() {
        const { session, view } = this.props

        if (!session && requiresAuth(view)) {
            return React.createElement(Login, this.props)
        } else if (view) {
            return React.createElement(
                'div',
                { id: 'body' },
                session && shouldShowMenu(view) ? this.renderMenu(session) : null,
                React.createElement('div', { id: 'main' }, React.createElement(Main, this.props)),
            )
        } else {
            return null
        }
    }

    override render() {
        if (this.state.hasError) {
            return React.createElement(
                'div',
                {
                    className: 'text-center text-multiline',
                    style: { paddingTop: 50, color: 'white' },
                },
                t.renderError.get(),
            )
        } else {
            return React.createElement(Fragment, null, this.renderHeader(), this.renderBody())
        }
    }
}
