import React, { FC } from 'react'

import { InputValues } from '../../../common/types/inputs'
import { getTypeOptions, renderClearFiltersButton } from '../../expense-archive-utils'
import { t } from '../../i18n'
import { inputs } from '../../inputs'
import { RootData } from '../../state/root-data'
import { renderChoice } from '../choice'
import { ExpenseArchiveSidebar } from './archive-sidebar'

const archiveInputs = inputs.expense.archive

const renderTypeChoice = (inputValues: InputValues) =>
    renderChoice({
        type: 'buttons',
        input: archiveInputs.type,
        inputValues,
        options: getTypeOptions(),
        buttonClassName: 'button--secondary',
        selectedButtonClassName: 'button--primary',
    })

export const ExpenseArchiveTypes: FC<RootData> = ({ inputValues }) =>
    React.createElement(
        'div',
        { className: 'content-area' },
        React.createElement(ExpenseArchiveSidebar, { selected: 'types' }),
        React.createElement(
            'div',
            { className: 'content archive' },
            React.createElement(
                'div',
                null,
                React.createElement(
                    'h1',
                    { className: 'title archive__title' },
                    t.expenses.type.get(),
                ),
                renderTypeChoice(inputValues),
            ),
            React.createElement(
                'div',
                { className: 'top-margin' },
                React.createElement(
                    'a',
                    { className: 'button button--primary', href: '#/expenses/archive/results' },
                    t.show.get(),
                ),
                ' ',
                renderClearFiltersButton('types', inputValues),
            ),
        ),
    )
