import classnames from 'classnames'
import React, { CSSProperties } from 'react'

import { Paragraph, Run, Terms as TTerms } from '../../common/types/terms'
import { LoadingIcon } from './loading-icon'

interface Props {
    terms: TTerms | null
}

const renderRun = (run: Run, index: number) => {
    const className = classnames('terms__run', {
        'terms__run--bold': run.bold,
        'terms__run--italic': run.italic,
    })

    return React.createElement('span', { key: index, className }, run.text)
}

// 300 was chosen by trial and error
const twipsToEm = (twips: number) => twips / 300 + 'em'

const renderParagraph = (paragraph: Paragraph, parIndex: number) => {
    const className = classnames('terms__paragraph', {
        'terms__paragraph--align-center': paragraph.alignment === 'center',
        'terms__paragraph--bullet': paragraph.isListItem && paragraph.listFormat === 'bullet',
    })

    const style: CSSProperties = {}
    const { indents } = paragraph

    if (indents) {
        const left = indents.left || 0
        const hanging = indents.hanging || 0

        if (left > hanging) {
            style.marginLeft = twipsToEm(left - hanging)
        }
    }

    return React.createElement(
        'div',
        { key: parIndex, className, style },
        paragraph.runs.map(renderRun),
        paragraph.runs.length ? null : '\u00a0',
    )
}

const renderParagraphs = (paragraphs: Paragraph[]) => paragraphs.map(renderParagraph)

export const Terms = ({ terms }: Props) => {
    if (!terms) {
        return React.createElement(LoadingIcon, { color: 'white' })
    }

    return React.createElement(
        'div',
        { className: 'terms' },
        renderParagraphs(terms.user),
        React.createElement('hr', { className: 'terms__horizontal-line' }),
        renderParagraphs(terms.company),
        React.createElement('hr', { className: 'terms__horizontal-line' }),
        renderParagraphs(terms.privacy),
    )
}
