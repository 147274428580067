import React from 'react'

import { Warning } from '../../common/types/errors'
import { t } from '../i18n'

type CloseWarning = (id: string) => void
interface Props {
    warnings: Warning[]
    closeWarning: CloseWarning
}

const renderWarning = ({ id, errorCode, customMessage }: Warning, closeWarning: CloseWarning) => {
    const onClick = () => closeWarning(id)
    const text = customMessage || t.serverError[errorCode].get()

    return React.createElement(
        'div',
        { key: id, className: 'warning' },
        text,
        React.createElement('div', { className: 'close-button', onClick }, '\u00d7'),
    )
}

export const WarningPanel = ({ warnings, closeWarning }: Props) =>
    React.createElement(
        'div',
        { className: 'warning-panel' },
        warnings.map((warning) => renderWarning(warning, closeWarning)),
    )
