import React, { FC } from 'react'

import { Day } from '../../common/time'
import { MoneyForm } from '../../common/types/enums'
import { Totals } from '../../common/types/item'
import { formatAmount } from '../format-amount'
import { t } from '../i18n'
import { renderValidationErrors } from '../val-err'
import { Button, ButtonProps } from './button'
import { ChoiceProps, renderChoice } from './choice'
import { DateInput, DateInputProps } from './date-input'
import { Input, InputProps } from './input'
import { Link, LinkProps } from './link'
import { LoadingIcon } from './loading-icon'
import { MenuToggle } from './menu-toggle'
import { ValidationErrorProps } from './validation-error'

export interface PaymentLabels {
    main: string
    amountSection: string
    isFull: string
    amount: string
}

interface PreviousPayment {
    date: Day
    description: string
}

export interface PaymentProps {
    onClose: () => void
    labels: PaymentLabels
    totals: Totals<number>
    disabledText?: string
    previous?: {
        title: string
        payments: PreviousPayment[]
        removeLastLink?: LinkProps
        isRemoving?: boolean
    }
    remaining?: {
        label: string
        amount: number
    }
    isFull?: {
        label: string
        choice: ChoiceProps<'yes' | 'no'>
    }
    amount?: {
        label: string
        input: InputProps
        errors: ValidationErrorProps[]
    }
    moneyForm?: {
        label: string
        choice: ChoiceProps<MoneyForm>
    }
    date?: {
        label: string
        dateInput: DateInputProps
    }
    errors: ValidationErrorProps[]
    saveButton?: Omit<ButtonProps, 'className'>
    isCredit?: boolean
    totalToPay: number
}

const renderVatRows = (payableWithoutVat: number, vatAmount: number, isCredit?: boolean) => {
    if (!isCredit && vatAmount > 0) {
        return [
            React.createElement(
                'div',
                null,
                t.vat.without.get(),
                ': ',
                formatAmount(payableWithoutVat),
                ' €',
            ),
            React.createElement(
                'div',
                { className: 'top-margin' },
                t.vat.get(),
                ': ',
                formatAmount(vatAmount),
                ' €',
            ),
        ]
    } else {
        return []
    }
}

const renderPrevious = (props: NonNullable<PaymentProps['previous']>) => {
    const { title, payments, removeLastLink, isRemoving } = props

    return React.createElement(
        'div',
        null,
        React.createElement('h3', { className: 'sidebar__section-title' }, title),
        React.createElement(
            'ul',
            { className: 'payment__previous-list' },
            ...payments.map((payment) =>
                React.createElement(
                    'li',
                    { className: 'payment__previous-item' },
                    React.createElement('b', null, payment.date.longDate()),
                    React.createElement('br'),
                    payment.description,
                ),
            ),
            (removeLastLink || isRemoving) &&
                React.createElement(
                    'li',
                    { className: 'payment__previous-item' },
                    removeLastLink &&
                        React.createElement(Link, {
                            ...removeLastLink,
                            className: 'sidebar__link',
                        }),
                    isRemoving && React.createElement(LoadingIcon, { color: 'white' }),
                ),
        ),
    )
}

export const Payment: FC<PaymentProps> = (props) => {
    const {
        onClose,
        labels,
        totals: { payableWithoutVat, vatAmount, payableWithVat },
        previous,
        disabledText,
        remaining,
        isFull: isFullProps,
        amount: amountProps,
        moneyForm: moneyFormProps,
        date: dateProps,
        errors,
        saveButton,
        isCredit,
        totalToPay,
    } = props

    const payableTotal = isCredit ? totalToPay : formatAmount(payableWithVat)

    return React.createElement(
        'div',
        { className: 'sidebar sidebar--flex open' },
        React.createElement(
            'div',
            null,
            React.createElement(MenuToggle, { onClick: onClose }),
            React.createElement('h1', { className: 'title sidebar__title' }, labels.main),
            React.createElement(
                'div',
                null,
                React.createElement(
                    'h3',
                    { className: 'sidebar__section-title' },
                    labels.amountSection,
                ),
                ...renderVatRows(payableWithoutVat, vatAmount, isCredit),
                React.createElement(
                    'div',
                    { className: 'top-margin' },
                    t.payableTotal.get(),
                    ': ',
                    React.createElement('b', null, payableTotal, ' €'),
                ),
            ),
            previous && renderPrevious(previous),
            disabledText && React.createElement('div', null, disabledText),
            remaining &&
                React.createElement(
                    'div',
                    null,
                    remaining.label,
                    ': ',
                    React.createElement('b', null, formatAmount(remaining.amount), ' €'),
                ),
            isFullProps &&
                React.createElement(
                    'div',
                    null,
                    React.createElement(
                        'h3',
                        { className: 'sidebar__section-title' },
                        isFullProps.label,
                    ),
                    renderChoice({
                        ...isFullProps.choice,
                        groupClassName: 'payment__full-buttons',
                        buttonClassName: 'sidebar__wide-button button--transparent',
                        selectedButtonClassName:
                            'sidebar__wide-button button--transparent-selected',
                    }),
                ),
            amountProps &&
                React.createElement(
                    'div',
                    null,
                    React.createElement(
                        'h3',
                        { className: 'sidebar__section-title' },
                        amountProps.label,
                    ),
                    React.createElement(
                        'div',
                        null,
                        React.createElement(Input, {
                            ...amountProps.input,
                            className: 'input input--white sidebar__input amount',
                        }),
                        ' €',
                        renderValidationErrors(amountProps.errors),
                    ),
                ),
            moneyFormProps &&
                React.createElement(
                    'div',
                    null,
                    React.createElement(
                        'h3',
                        { className: 'sidebar__section-title' },
                        moneyFormProps.label,
                    ),
                    renderChoice({
                        ...moneyFormProps.choice,
                        groupClassName: 'payment__money-form-buttons vertical',
                        buttonClassName: 'sidebar__wide-button button--transparent',
                        selectedButtonClassName:
                            'sidebar__wide-button button--transparent-selected',
                    }),
                ),
            dateProps &&
                React.createElement(
                    'div',
                    null,
                    React.createElement(
                        'h3',
                        { className: 'sidebar__section-title' },
                        dateProps.label,
                    ),
                    React.createElement(DateInput, {
                        ...dateProps.dateInput,
                        className: 'date-button date-button--white sidebar__wide-button',
                        renderAsBlock: true,
                    }),
                ),
            React.createElement(
                'div',
                { className: 'top-margin bottom-margin' },
                renderValidationErrors(errors),
            ),
        ),
        saveButton &&
            React.createElement(Button, {
                ...saveButton,
                className: 'payment__save-button button--white',
                loadingColor: 'white',
            }),
    )
}
