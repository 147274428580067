import { isTestMode } from './test-mode'
import { isFakeTime, setFakeTime, Time } from './time'
import { Scheduled } from './types/clock'

interface Entry {
    time: Time
    callback: () => any
    tag: string | null
}

export const MIN_DATE = '0001-01-01'
export const MAX_DATE = '9999-01-01'

let debugMode = false
let simulatedSchedule: Entry[] = []

// tag is required only if this callback may remain scheduled at the end of a test case
export const schedule = (ms: number, callback: () => any, tag: string | null = null): Scheduled => {
    if (isTestMode()) {
        if (!isFakeTime()) {
            throw new Error('Fake time required for scheduling in test mode')
        }

        const dueTime = Time.now().addMilliseconds(ms)
        const entry = { time: dueTime, callback, tag }

        if (debugMode) {
            console.log('[     Clock] scheduling', tag)
        }

        simulatedSchedule.push(entry)

        return {
            clear: () => {
                simulatedSchedule = simulatedSchedule.filter((otherEntry) => otherEntry !== entry)
            },
        }
    } else {
        const timeout = setTimeout(callback, ms)

        return {
            clear: () => clearTimeout(timeout),
        }
    }
}

// For automatic tests
export const setDebugMode = (debugModeParam: boolean) => (debugMode = debugModeParam)

export const setTime = (timeStr: string, isReset: boolean = false) => {
    const newTime = Time.fromIso(timeStr)

    if (newTime.iso() !== timeStr) {
        throw new Error('Must use valid ISO time string')
    }

    const prevTime = Time.now()
    setFakeTime(newTime.timestamp)

    if (isReset) {
        // When resetting, moving time backwards is allowed,
        // assuming there is nothing scheduled.

        if (simulatedSchedule.length) {
            throw new Error('Simulated schedule must be empty before reset')
        }
    } else if (isFakeTime()) {
        // When not resetting, time can only be moved forward.
        // Any scheduled callbacks since the previous time will be executed.

        if (newTime.isBefore(prevTime)) {
            throw new Error('Cannot go back in time, Marty')
        }

        const oldSchedule = simulatedSchedule
        simulatedSchedule = []

        // TODO need to sort entries by time?
        for (const entry of oldSchedule) {
            if (entry.time.isSameOrBefore(newTime)) {
                // The callback may add new entries to simulatedSchedule
                entry.callback()
            } else {
                simulatedSchedule.push(entry)
            }
        }
    }
}

export const getSimulatedSchedule = () => simulatedSchedule
export const clearSimulatedSchedule = () => {
    simulatedSchedule = []
}
