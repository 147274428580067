import { cleanString } from '../../common/clean-string'
import { getMinDay } from '../../common/company-utils'
import { normalizeBusinessName } from '../../common/normalize-business-name'
import { InputValues } from '../../common/types/inputs'
import { ApiRevenue, FilterSection } from '../../common/types/invoice'
import { ButtonProps } from '../components/button'
import { RevenueArchiveContentsProps } from '../components/revenue/archive-contents'
import {
    ChosenBusinessProps,
    FoundBusinessProps,
    RevenueArchiveCustomerOkProps,
    RevenueArchiveCustomerProps,
} from '../components/revenue/archive-customer'
import {
    RevenueArchiveGeneralOkProps,
    RevenueArchiveGeneralProps,
} from '../components/revenue/archive-general'
import {
    RevenueArchiveResultsOkProps,
    RevenueArchiveResultsProps,
} from '../components/revenue/archive-results'
import { getExcelButtonProps } from '../excel-utils'
import { ExcelSpec } from '../excel/types'
import { DateShortcut, getMonthShortcuts, getYearShortcuts } from '../get-date-shortcuts'
import { t } from '../i18n'
import { inputs } from '../inputs'
import {
    anyFiltersToClear,
    getActiveFilters,
    getClearFiltersButton,
    getColumns,
    getCustomerTypeOptions,
    getDiscountOptions,
    getDueDateOptions,
    getItemTypeOptions,
    getPaidOptions,
    getRows,
    getStatusOptions,
    getTermOptions,
    Row,
} from '../revenue-archive-utils'
import { getCompany } from '../state/company-actions'
import { RootData } from '../state/root-data'

interface SimpleBusiness {
    regCode: string
    name: string
    revenueCount: number
}

const archiveInputs = inputs.invoice.archive
const customerInputs = archiveInputs.customer
const dateInputs = archiveInputs.date

export const getRevenueArchiveContentsProps = ({
    inputValues,
}: RootData): RevenueArchiveContentsProps => {
    const props: RevenueArchiveContentsProps = {
        title: t.revenues.archive.header.contents.get(),
        itemType: {
            type: 'buttons',
            input: archiveInputs.itemType,
            inputValues,
            options: getItemTypeOptions(),
        },
        discount: {
            type: 'buttons',
            input: archiveInputs.discount,
            inputValues,
            options: getDiscountOptions(),
        },
        showLink: {
            text: t.show.get(),
            to: '#/invoices/archive/results',
        },
    }

    const section: FilterSection = 'contents'

    if (anyFiltersToClear(section, inputValues)) {
        props.clearFiltersButton = getClearFiltersButton(section, inputValues)
    }

    return props
}

const getChosenBusinessProps = (inputValues: InputValues): ChosenBusinessProps => {
    const businessName = customerInputs.name.get(inputValues)

    return {
        text: t.chosenBusiness.get() + ': ' + businessName,
        button: {
            onClick: () => {
                customerInputs.regCode.set('')
                customerInputs.name.set('')
            },
            text: t.cancelSelection.get(),
        },
    }
}

const getFoundBusinessProps = (business: SimpleBusiness, search: string): FoundBusinessProps => ({
    onClick: () => {
        customerInputs.regCode.set(business.regCode)
        // TODO only set regCode and later determine name through that?
        customerInputs.name.set(business.name)
    },
    businessName: business.name,
    searchText: search,
    note: t.businessLookup.invoiceCount.get() + ': ' + business.revenueCount,
})

const matches = (search: string, normalized: string[]) => {
    return normalized.some((norm) => norm.startsWith(search))
}

const getFoundBusinessesProps = (
    inputValues: InputValues,
    revenues: ApiRevenue[],
): FoundBusinessProps[] => {
    const search = cleanString(customerInputs.search.get(inputValues), true)
    const map: Record<string, SimpleBusiness> = {}

    for (const { customer } of revenues) {
        if (customer.isBusiness) {
            const { regCode } = customer

            if (!(regCode in map)) {
                if (!matches(search, normalizeBusinessName(customer.name))) {
                    continue
                }

                map[regCode] = { regCode, name: customer.name, revenueCount: 0 }
            }

            const business = map[regCode]
            business.revenueCount += 1
        }
    }

    return (
        Object.values(map)
            // TODO secondary comparison by most recent date?
            .sort((b1, b2) => b2.revenueCount - b1.revenueCount)
            .slice(0, 5)
            .map((business) => getFoundBusinessProps(business, search))
    )
}

export const getRevenueArchiveCustomerProps = (rootData: RootData): RevenueArchiveCustomerProps => {
    const {
        inputValues,
        invoiceData: { invoices: revenues },
    } = rootData

    if (!revenues) {
        return { loading: true }
    }

    const props: RevenueArchiveCustomerOkProps = {
        loading: false,
        title: t.customerType.get(),
        customerType: {
            type: 'buttons',
            input: customerInputs.type,
            inputValues,
            options: getCustomerTypeOptions(),
        },
        forwardLink: {
            text: t.forward.get(),
            to: '#/invoices/archive/contents',
        },
        showLink: {
            text: t.show.get(),
            to: '#/invoices/archive/results',
        },
    }

    if (customerInputs.type.get(inputValues) === 'business') {
        props.business = {
            title: t.businessLookup.search.get(),
        }

        const regCode = customerInputs.regCode.get(inputValues)

        if (regCode) {
            props.business.chosenBusiness = getChosenBusinessProps(inputValues)
        } else {
            props.business.search = {
                input: {
                    input: customerInputs.search,
                    inputValues,
                    placeholder: t.searchByName.get(),
                },
            }

            const businesses = getFoundBusinessesProps(inputValues, revenues)

            if (businesses.length) {
                props.business.search.foundBusinesses = businesses
            } else {
                props.business.search.noneFoundText = t.business.noneFound.get()
            }
        }
    }

    const section: FilterSection = 'customer'

    if (anyFiltersToClear(section, inputValues)) {
        props.clearFiltersButton = getClearFiltersButton(section, inputValues)
    }

    return props
}

const getShortcutProps = (shortcut: DateShortcut): Omit<ButtonProps, 'className'> => ({
    text: shortcut.label,
    onClick: () => {
        dateInputs.from.set(shortcut.from)
        dateInputs.to.set(shortcut.to)
    },
})

export const getRevenueArchiveGeneralProps = (rootData: RootData): RevenueArchiveGeneralProps => {
    const { inputValues, companyData, session } = rootData

    if (!companyData.companies) {
        return { loading: true }
    }

    const { interimDate } = getCompany(companyData, session)
    const minDate = getMinDay(interimDate)

    const props: RevenueArchiveGeneralOkProps = {
        loading: false,
        status: {
            title: t.status.get(),
            choice: {
                type: 'buttons',
                input: archiveInputs.status,
                inputValues,
                options: getStatusOptions(),
            },
        },
        dateTitle: t.invoices.date.get(),
        dateFrom: {
            label: t.date.from.get(),
            input: {
                input: dateInputs.from,
                inputValues,
                minDate,
            },
        },
        dateTo: {
            label: t.date.to.get(),
            input: {
                input: dateInputs.to,
                inputValues,
                minDate,
            },
        },
        monthShortcuts: getMonthShortcuts().map(getShortcutProps),
        yearShortcuts: getYearShortcuts().map(getShortcutProps),
        paid: {
            title: t.payments.incoming.get(),
            choice: {
                type: 'buttons',
                input: archiveInputs.paid,
                inputValues,
                options: getPaidOptions(),
            },
        },
        dueDate: {
            title: t.dueDate.get(),
            choice: {
                type: 'buttons',
                input: archiveInputs.dueDate,
                inputValues,
                options: getDueDateOptions(),
            },
        },
        term: {
            title: t.term.get(),
            choice: {
                type: 'buttons',
                input: archiveInputs.term,
                inputValues,
                options: getTermOptions(),
            },
        },
        forwardLink: {
            text: t.forward.get(),
            to: '#/invoices/archive/customer',
        },
        showLink: {
            text: t.show.get(),
            to: '#/invoices/archive/results',
        },
    }

    const section: FilterSection = 'general'

    if (anyFiltersToClear(section, inputValues)) {
        props.clearFiltersButton = getClearFiltersButton(section, inputValues)
    }

    return props
}

export const getRevenueArchiveResultsProps = (rootData: RootData): RevenueArchiveResultsProps => {
    const {
        inputValues,
        invoiceData: { invoices },
        processes,
        progress,
    } = rootData

    if (!invoices) {
        return { loading: true }
    }

    const showTermColumn = Boolean(archiveInputs.term.get(inputValues))
    const showDueDateColumn = Boolean(archiveInputs.dueDate.get(inputValues))
    const rows = getRows(invoices, inputValues)

    const props: RevenueArchiveResultsOkProps = {
        loading: false,
        title: t.archive.searchResults.get(),
        summaryTitle: t.archive.filterSummary.get(),
        showTermColumn,
        showDueDateColumn,
        rows,
        backLink: {
            text: t.backToSearch.get(),
            to: '#/invoices/archive/general',
        },
    }

    if (rows.length) {
        // TODO avoid invoking getColumns twice on each render?
        const columns = getColumns(showTermColumn, showDueDateColumn)

        const excelSpec: ExcelSpec<Row> = {
            columns,
            rows,
            outputName: t.invoices.get().toLowerCase(),
        }

        props.excelButton = getExcelButtonProps(
            excelSpec,
            t.revenues.processed.get(),
            processes,
            progress,
            'button--primary',
        )
    }

    const activeFilters = getActiveFilters(inputValues)

    if (activeFilters.length) {
        props.filters = activeFilters.map(({ filter: { id, label, render }, values }) => ({
            id,
            label,
            value: render(...values),
        }))
    } else {
        props.noFiltersText = t.archive.filterSummary.none.get()
    }

    return props
}
