import Immutable from 'immutable'

import { MAX_BALANCE_DATES, MAX_REPORT_PERIODS } from '../../common/constants'
import { balanceDateModes, reportModes, reportPeriodModes } from '../../common/enums'
import { getFiscalYearForCompany } from '../../common/fiscal-year-utils'
import { getDefaultIncomePeriod } from '../../common/report-utils'
import { Day } from '../../common/time'
import { ApiCompany } from '../../common/types/company'
import { Period } from '../../common/types/reports'
import * as api from '../api'
import { getBalanceDatesForCompany } from '../balance-utils'
import { t } from '../i18n'
import { inputs } from '../inputs'
import {
    getBalanceMinDate,
    getPeriodMinDate,
    getPeriods,
    getSinglePeriod,
    getYearOptionsForCompany,
} from '../report-utils'
import { loadCompany } from './company-actions'
import { loadAccounts } from './load-actions'
import { run } from './process-actions'
import { dispatch, getState } from './store'

const balanceInputs = inputs.reports.balance
const datesInputs = balanceInputs.dates
const periodInputs = inputs.reports.periods

export const LOAD_BALANCE_PROCESS = 'reports/balance/load'
export const LOAD_INCOME_PROCESS = 'reports/income/load'
export const LOAD_CASH_FLOW_PROCESS = 'reports/cash-flow/load'
export const LOAD_TURNOVER_PROCESS = 'reports/turnover/load'

export const setBalanceDatesError = (error: string) =>
    dispatch(({ reports }) => {
        reports.balance.datesError = error
    })

export const clearBalanceDatesError = () =>
    dispatch(({ reports }) => {
        reports.balance.datesError = null
    })

export const setReportPeriodsError = (error: string) =>
    dispatch(({ reports }) => {
        reports.periodsError = error
    })

export const clearReportPeriodsError = () =>
    dispatch(({ reports }) => {
        reports.periodsError = null
    })

export const loadBalance = async () =>
    run(LOAD_BALANCE_PROCESS, async () => {
        clearBalanceDatesError()
        dispatch(({ reports }) => (reports.balance.loaded = []))

        const company = await loadCompany()
        const {
            inputValues,
            reports: {
                balance: { selectedDates },
            },
        } = getState()

        let dates

        try {
            dates = getBalanceDatesForCompany(inputValues, selectedDates, company)
        } catch (error) {
            setBalanceDatesError((error as Error).message)
            return
        }

        if (!dates.length) {
            setBalanceDatesError(t.reports.dates.noDates.get())
        } else if (dates.length > MAX_BALANCE_DATES) {
            setBalanceDatesError(t.reports.dates.tooManyDates.get())
        } else {
            const data = await api.getBalanceReport(dates)
            datesInputs.overlayOpen.set(false)
            dispatch(({ reports }) => (reports.balance.loaded = data))
        }
    })

export const initBalance = async () => {
    const company = await loadCompany()

    if (!company.hasReportData) {
        return
    }

    dispatch(({ reports }) => {
        // @ts-expect-error Looks like a TypeScript bug
        reports.balance.selectedDates = Immutable.Set.of(Day.today().ymd())
    })

    balanceInputs.mode.set(reportModes.short)

    const fiscalYear = getFiscalYearForCompany(Day.today(), company)
    const minDate = getBalanceMinDate(company)
    const fiscalBeginOrMin = Day.max(minDate, fiscalYear.start)

    datesInputs.mode.set(balanceDateModes.dates)

    const yearOptions = getYearOptionsForCompany(company)
    const currentYearOption = yearOptions[yearOptions.length - 1]

    const prevYearOption =
        yearOptions.length > 1 ? yearOptions[yearOptions.length - 2] : currentYearOption

    datesInputs.years.from.set(prevYearOption.id)
    datesInputs.years.to.set(currentYearOption.id)

    datesInputs.months.from.set(fiscalBeginOrMin.ym())
    datesInputs.months.to.set(Day.today().ym())

    await Promise.all([loadAccounts(), loadBalance()])
}

const loadPeriodData = async <T extends { label?: string }>(
    getData: (periods: Period[]) => Promise<T[]>,
) => {
    clearReportPeriodsError()

    const company = await loadCompany()
    const interimDate = Day.fromYmd(company.interimDate)

    if (interimDate.isSame(Day.today())) {
        dispatch(({ reports }) => (reports.interimDateToday = true))
        return undefined
    }

    const { inputValues } = getState()

    let periodsWithLabels

    try {
        periodsWithLabels = getPeriods(
            inputValues,
            company.registrationDate,
            company.fiscalYearBegin,
            company.longFirstYear,
            getPeriodMinDate(company),
        )
    } catch (error) {
        setReportPeriodsError((error as Error).message)
        return undefined
    }

    if (!periodsWithLabels.length) {
        setReportPeriodsError(t.reports.periods.noPeriods.get())
    } else if (periodsWithLabels.length > MAX_REPORT_PERIODS) {
        setReportPeriodsError(t.reports.periods.tooManyPeriods.get())
    } else {
        const data = await getData(periodsWithLabels.map(({ period }) => period))

        for (let i = 0; i < data.length; i += 1) {
            data[i].label = periodsWithLabels[i].label
        }

        periodInputs.overlayOpen.set(false)
        return data
    }

    return undefined
}

const initPeriodInputs = async (company: ApiCompany, singlePeriodMode: boolean) => {
    const fiscalYear = getFiscalYearForCompany(Day.today(), company)
    const minDate = getPeriodMinDate(company)
    const fiscalBeginOrMin = Day.max(minDate, fiscalYear.start)

    periodInputs.mode.set(reportPeriodModes.dates)
    const period = getDefaultIncomePeriod(company)

    periodInputs.dates.from.set(period.from)
    periodInputs.dates.to.set(period.to)

    const yearOptions = getYearOptionsForCompany(company)
    const currentYearOption = yearOptions[yearOptions.length - 1]

    if (singlePeriodMode) {
        periodInputs.years.from.set(currentYearOption.id)
        periodInputs.months.from.set(Day.today().ym())
    } else {
        const prevYearOption =
            yearOptions.length > 1 ? yearOptions[yearOptions.length - 2] : currentYearOption

        periodInputs.years.from.set(prevYearOption.id)
        periodInputs.years.to.set(currentYearOption.id)

        periodInputs.months.from.set(fiscalBeginOrMin.ym())
        periodInputs.months.to.set(Day.today().ym())

        const fromDate = Day.max(minDate, Day.today().addDays(-6))
        periodInputs.days.from.set(fromDate.ymd())
        periodInputs.days.to.set(Day.today().ymd())
    }
}

export const loadIncome = async () =>
    run(LOAD_INCOME_PROCESS, async () => {
        const data = await loadPeriodData(api.getIncomeReport)

        if (data) {
            dispatch(({ reports }) => (reports.income.loaded = data))
        }
    })

export const initIncome = async () => {
    const company = await loadCompany()

    if (!company.hasReportData) {
        return
    }

    inputs.reports.income.mode.set(reportModes.short)
    await initPeriodInputs(company, false)
    await Promise.all([loadAccounts(), loadIncome()])
}

export const loadCashFlow = async () =>
    run(LOAD_CASH_FLOW_PROCESS, async () => {
        const data = await loadPeriodData(api.getCashFlowReport)

        if (data) {
            dispatch(({ reports }) => (reports.cashFlow.loaded = data))
        }
    })

export const initCashFlow = async () => {
    const company = await loadCompany()

    if (!company.hasReportData) {
        return
    }

    await initPeriodInputs(company, false)
    await loadCashFlow()
}

export const loadTurnover = async () =>
    run(LOAD_TURNOVER_PROCESS, async () => {
        clearReportPeriodsError()

        const company = await loadCompany()
        const interimDate = Day.fromYmd(company.interimDate)

        if (interimDate.isSame(Day.today())) {
            dispatch(({ reports }) => (reports.interimDateToday = true))
            return
        }

        const { inputValues } = getState()

        let period: Period

        try {
            period = getSinglePeriod(
                inputValues,
                company.registrationDate,
                company.fiscalYearBegin,
                company.longFirstYear,
                getPeriodMinDate(company),
            )
        } catch (error) {
            setReportPeriodsError((error as Error).message)
            return
        }

        const data = await api.getTurnoverReport(period)
        periodInputs.overlayOpen.set(false)
        dispatch(({ reports }) => (reports.turnover.loaded = { period, data }))
    })

export const initTurnover = async () => {
    const company = await loadCompany()

    if (!company.hasReportData) {
        return
    }

    await initPeriodInputs(company, true)
    inputs.reports.turnover.mode.set(reportModes.short)
    await Promise.all([loadAccounts(), loadTurnover()])
}

export const addBalanceDate = (date: string) =>
    dispatch(({ reports: { balance } }) => {
        balance.selectedDates = balance.selectedDates.add(date)
    })

export const removeBalanceDate = (date: string) =>
    dispatch(({ reports: { balance } }) => {
        // @ts-expect-error Looks like a TypeScript bug
        balance.selectedDates = balance.selectedDates.delete(date)
    })
