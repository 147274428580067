import React from 'react'

import { AssetList } from './components/asset-list'
import { ChangeCompany } from './components/change-company'
import { CommandLog } from './components/command-log'
import { Dashboard } from './components/dashboard'
import { Default } from './components/default'
import { EmailConfirmation } from './components/email-confirmation'
import { EntryEdit } from './components/entry-edit'
import { EntryList } from './components/entry-list'
import { ExpenseArchiveGeneral } from './components/expense/archive-general'
import { ExpenseArchiveResults } from './components/expense/archive-results'
import { ExpenseArchiveTypes } from './components/expense/archive-types'
import { ExpenseArchiveVendor } from './components/expense/archive-vendor'
import { ExpenseEdit } from './components/expense/edit'
import { ExpenseRegister } from './components/expense/register'
import { UnpaidExpenses } from './components/expense/unpaid'
import { FinancialHistoryBalance } from './components/financial-history/balance'
import { GeneralExpenseList } from './components/general-expense-list'
import { GoodsList } from './components/goods-list'
import { InitCompanyBilling } from './components/init-company/billing'
import { InitCompanyDate } from './components/init-company/date'
import { InitCompanyGeneral } from './components/init-company/general'
import { InitCompanyPaymentStatus } from './components/init-company/payment-status'
import { LabourCostList } from './components/labour-cost-list'
import { PasswordReset } from './components/password-reset'
import { BalanceReport } from './components/reports/balance'
import { CashFlowReport } from './components/reports/cash-flow'
import { ChartOfAccounts } from './components/reports/chart-of-accounts'
import { IncomeReport } from './components/reports/income'
import { TurnoverReport } from './components/reports/turnover'
import { RevenueArchiveContents } from './components/revenue/archive-contents'
import { RevenueArchiveCustomer } from './components/revenue/archive-customer'
import { RevenueArchiveGeneral } from './components/revenue/archive-general'
import { RevenueArchiveResults } from './components/revenue/archive-results'
import { RevenueEdit } from './components/revenue/edit'
import { RevenuePreview } from './components/revenue/preview'
import { RevenueRegister } from './components/revenue/register'
import { RevenueSearch } from './components/revenue/search'
import { UnpaidRevenues } from './components/revenue/unpaid'
import { SelectCompany } from './components/select-company'
import { Settings } from './components/settings'
import { SignUp } from './components/signup'
import { StockChange } from './components/stock-change'
import { StockList } from './components/stock-list'
import { TaxesSummary } from './components/taxes/summary'
import { TaxesVat } from './components/taxes/vat'
import { Test } from './components/test'
import { Texts } from './components/texts'
import { UserSettingsCompanies } from './components/user-settings/companies'
import { UserSettingsGeneral } from './components/user-settings/general'
import { fromErrorCode, processWarning } from './error-manager'
import {
    getRevenueArchiveContentsProps,
    getRevenueArchiveCustomerProps,
    getRevenueArchiveGeneralProps,
    getRevenueArchiveResultsProps,
} from './props/revenue-archive-props'
import { getCreditRevenueProps, getRevenueEditProps } from './props/revenue-edit-props'
import { getCreditRevenuePreviewProps, getRevenuePreviewProps } from './props/revenue-preview-props'
import { getRevenueRegisterProps } from './props/revenue-register-props'
import { getTaxesVatProps } from './props/taxes-vat-props'
import { getUnpaidRevenuesProps } from './props/unpaid-revenues-props'
import { showBlank } from './show-blank'
import { RootData } from './state/root-data'

export const renderTopLevelComponent = (rootData: RootData) => {
    const { view } = rootData

    if (!view) {
        throw new Error('View is not set')
    }

    switch (view.name) {
        case 'Blank':
            return null

        case 'AssetList':
            return React.createElement(AssetList, rootData)
        case 'BalanceReport':
            return React.createElement(BalanceReport, rootData)
        case 'CashFlowReport':
            return React.createElement(CashFlowReport, rootData)
        case 'ChartOfAccounts':
            return React.createElement(ChartOfAccounts, rootData)
        case 'ChangeCompany':
            return React.createElement(ChangeCompany, rootData)
        case 'CommandLog':
            return React.createElement(CommandLog, rootData)
        case 'CreditRevenue':
            return React.createElement(RevenueEdit, getCreditRevenueProps(rootData))
        case 'CreditRevenuePreview':
            return React.createElement(RevenuePreview, getCreditRevenuePreviewProps(rootData))
        case 'Dashboard':
            return React.createElement(Dashboard, rootData)
        case 'Default':
            return React.createElement(Default, rootData)
        case 'EmailConfirmation':
            return React.createElement(EmailConfirmation, rootData)
        case 'EntryEdit':
            return React.createElement(EntryEdit, rootData)
        case 'EntryList':
            return React.createElement(EntryList, rootData)
        case 'ExpenseArchiveGeneral':
            return React.createElement(ExpenseArchiveGeneral, rootData)
        case 'ExpenseArchiveResults':
            return React.createElement(ExpenseArchiveResults, rootData)
        case 'ExpenseArchiveTypes':
            return React.createElement(ExpenseArchiveTypes, rootData)
        case 'ExpenseArchiveVendor':
            return React.createElement(ExpenseArchiveVendor, rootData)
        case 'ExpenseEdit':
            return React.createElement(ExpenseEdit, rootData)
        case 'ExpenseRegister':
            return React.createElement(ExpenseRegister, rootData)
        case 'FinancialHistoryBalance':
            return React.createElement(FinancialHistoryBalance, rootData)
        case 'GeneralExpenseList':
            return React.createElement(GeneralExpenseList, rootData)
        case 'GoodsList':
            return React.createElement(GoodsList, rootData)
        case 'IncomeReport':
            return React.createElement(IncomeReport, rootData)
        case 'InitCompanyBilling':
            return React.createElement(InitCompanyBilling, rootData)
        case 'InitCompanyDate':
            return React.createElement(InitCompanyDate, rootData)
        case 'InitCompanyGeneral':
            return React.createElement(InitCompanyGeneral, rootData)
        case 'InitCompanyPaymentStatus':
            return React.createElement(InitCompanyPaymentStatus, rootData)
        case 'LabourCostList':
            return React.createElement(LabourCostList, rootData)
        case 'PasswordReset':
            return React.createElement(PasswordReset, rootData)
        case 'RevenueArchiveCustomer':
            return React.createElement(
                RevenueArchiveCustomer,
                getRevenueArchiveCustomerProps(rootData),
            )
        case 'RevenueArchiveContents':
            return React.createElement(
                RevenueArchiveContents,
                getRevenueArchiveContentsProps(rootData),
            )
        case 'RevenueArchiveGeneral':
            return React.createElement(
                RevenueArchiveGeneral,
                getRevenueArchiveGeneralProps(rootData),
            )
        case 'RevenueArchiveResults':
            return React.createElement(
                RevenueArchiveResults,
                getRevenueArchiveResultsProps(rootData),
            )
        case 'RevenueEdit':
            return React.createElement(RevenueEdit, getRevenueEditProps(rootData))
        case 'RevenuePreview':
            return React.createElement(RevenuePreview, getRevenuePreviewProps(rootData))
        case 'RevenueRegister':
            return React.createElement(RevenueRegister, getRevenueRegisterProps(rootData))
        case 'RevenueSearch':
            return React.createElement(RevenueSearch, rootData)
        case 'SelectCompany':
            return React.createElement(SelectCompany, rootData)
        case 'Settings':
            return React.createElement(Settings, rootData)
        case 'SignUp':
            return React.createElement(SignUp, rootData)
        case 'StockChange':
            return React.createElement(StockChange, rootData)
        case 'StockList':
            return React.createElement(StockList, rootData)
        case 'TaxesSummary':
            return React.createElement(TaxesSummary, rootData)
        case 'TaxesVat':
            return React.createElement(TaxesVat, getTaxesVatProps(rootData))
        case 'Test':
            return React.createElement(Test, rootData)
        case 'Texts':
            return React.createElement(Texts, rootData)
        case 'TurnoverReport':
            return React.createElement(TurnoverReport, rootData)
        case 'UnpaidExpenses':
            return React.createElement(UnpaidExpenses, rootData)
        case 'UnpaidRevenues':
            return React.createElement(UnpaidRevenues, getUnpaidRevenuesProps(rootData))
        case 'UserSettingsCompanies':
            return React.createElement(UserSettingsCompanies, rootData)
        case 'UserSettingsGeneral':
            return React.createElement(UserSettingsGeneral, rootData)
        default:
            processWarning(
                fromErrorCode('invalid-component', { name: (view as { name: string }).name }),
            )
            showBlank(rootData)
            return null
    }
}
