import { MonthNumber } from './types/month-number'

export const monthNumbers: MonthNumber[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

const isInteger = (number: number) => parseInt(String(number), 10) === number

export const isMonthNumber = (number: number): number is MonthNumber =>
    isInteger(number) && number >= 1 && number <= 12

export const asMonthNumber = (number: number): MonthNumber => {
    if (isMonthNumber(number)) {
        return number
    } else {
        throw new Error('Not a valid month number: ' + number)
    }
}
