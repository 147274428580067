import { CompanyRole } from '../common/enums'
import { ServerError } from '../common/server-error'
import { AccountType, ApiAccount } from '../common/types/account'
import { ApiParams, ApiResponse, AssignVatMonthParams, CommandName } from '../common/types/api'
import { BusinessFields, BusinessLookupResult } from '../common/types/business'
import { ApiCardPayment, CardPaymentType } from '../common/types/card-payment'
import { ApiCommand, CommandFilters } from '../common/types/command'
import {
    ApiCompany,
    BillingDetails,
    CompanyInput,
    CompanyUpdate,
    GeneralUpdate,
} from '../common/types/company'
import { DashboardData } from '../common/types/dashboard'
import { ApiEntry, EntryInput } from '../common/types/entry'
import { LabourPaymentType, LoginMode } from '../common/types/enums'
import {
    ApiExpense,
    AssetValueChange,
    ExpenseInput,
    ExpenseUpdate,
    PendingAssetChange,
    PendingStockChange,
    PendingStockChangeItem,
} from '../common/types/expense'
import {
    HttpClient,
    PostResponse,
    RequestBody,
    RequestHeaders,
    ResponseBody,
} from '../common/types/http'
import { ApiInvite } from '../common/types/invite'
import { ApiRevenue, CreditRevenue, RevenueInput } from '../common/types/invoice'
import { InvoiceSettingsUpdate } from '../common/types/invoice-settings'
import { AutomaticItem } from '../common/types/item'
import { ApiLabourCost, LabourCostInput } from '../common/types/labour-cost'
import { LogEntry } from '../common/types/log'
import { Note } from '../common/types/note'
import { DbPayment, PaymentInput } from '../common/types/payment'
import { Period, SimpleData } from '../common/types/reports'
import { ClientFields } from '../common/types/resolver'
import { ServerConf } from '../common/types/server-conf'
import { ApiSettings, VatUpdate } from '../common/types/settings'
import { ApiUserFull, ApiUserLimited, Profile, UserInput } from '../common/types/user'
import { UnwrapIfArray } from '../common/types/utils'
import { VatPaymentInput, VatPaymentState } from '../common/types/vat'
import { VendorFields } from '../common/types/vendor'
import { emitCloseWarnings, emitUpdatedSession, emitVersionMismatch } from './event-bus'
import { getState } from './state/store'

let debugMode = false
const pendingRequests: Set<Promise<PostResponse<CommandName>>> = new Set()

let httpClient: HttpClient = {
    async post<C extends CommandName>(
        command: C,
        body: RequestBody<C>,
        headers: RequestHeaders,
        debugInfo?: string,
    ) {
        let path = '/api/' + command

        if (debugInfo) {
            // The debug info is ignored by the server, but can be used to distinguish
            // different requests with the same command in browser dev tools, access logs, etc.
            path = path + '?' + debugInfo
        }

        const options = { method: 'POST', body: JSON.stringify(body), headers }
        const response = await fetch(path, options)
        const { status } = response

        if (status === 200) {
            return {
                headers: response.headers,
                body: (await response.json()) as ResponseBody<C>,
            }
        } else {
            const text = await response.text()
            const message = 'Unexpected HTTP status: ' + status + ' for command ' + command
            throw new ServerError({ success: false, errorCode: 'non-200', status, text }, message)
        }
    },
}

const send = async <C extends CommandName>(
    command: C,
    params?: ApiParams<C>,
    debugInfo?: string,
): Promise<ApiResponse<C>> => {
    const { session } = getState()
    const sessionId = session ? session.id : null
    const clientVersion = window.BOOKY_VERSION
    const headers = { 'booky-version': clientVersion }

    const promise = httpClient.post(command, { sessionId, params }, headers, debugInfo)
    pendingRequests.add(promise)
    let response

    try {
        if (debugMode) {
            console.log('[       API] starting', command, debugInfo || '')
        }

        response = await promise
    } finally {
        pendingRequests.delete(promise)

        if (debugMode) {
            console.log('[       API] done', command, debugInfo || '')
        }
    }

    // If no error has been thrown, we most likely have network connectivity
    emitCloseWarnings('failed-to-fetch')

    const serverVersion = response.headers.get('booky-version')

    if (serverVersion !== clientVersion) {
        emitVersionMismatch()
    }

    const updatedSession = response.headers.get('booky-updated-session')

    if (updatedSession) {
        emitUpdatedSession(JSON.parse(updatedSession))
    }

    const json = response.body

    if (json.success) {
        return json.response
    } else {
        throw new ServerError(json)
    }
}

const load = async <T>(
    resolver: string,
    fields?: ClientFields<UnwrapIfArray<T>>,
    args?: unknown,
): Promise<T> => send(CommandName.load, { resolver, fields, args }, resolver) as Promise<T>

const automaticItemFields: ClientFields<AutomaticItem<any, any, any>> = {
    id: {},
    type: {},
    account: {},
    description: {},
    quantity: {},
    unit: {},
    discount: {},
    vatPercentage: {},
    unitPrice: {},
}

const businessFields: ClientFields<BusinessFields<any>> = {
    name: {},
    regCode: {},
    address: {},
    vatId: {},
}

/** <T>: use <never> if the object is required and <undefined> if it's optional */
const getPaymentFields = <T extends undefined>(): ClientFields<DbPayment | T> => ({
    id: {},
    amount: {},
    moneyForm: {},
    date: {},
})

/** <T>: use <never> if the object is required and <undefined> if it's optional */
const getVendorFields = <T extends undefined>(): ClientFields<VendorFields<any> | T> => ({
    ...businessFields,
    phone: {},
    email: {},
    website: {},
    bankAccounts: { fields: { name: {}, number: {} } },
})

const logFields: ClientFields<LogEntry> = { time: {}, userId: {}, action: {}, paymentId: {} }

export const loadServerConf = async () => load<ServerConf>('serverConf', { fileStorageBaseUrl: {} })

// Accounts

export const loadAccounts = async () =>
    load<ApiAccount[]>('accounts', { id: {}, rev: {}, type: {}, number: {}, name: {} })

export const createAccount = async (type: AccountType, accountName: string) => {
    return send(CommandName.createAccount, { type, name: accountName })
}

// Commands

export const loadCommands = async (filters: CommandFilters) =>
    load<ApiCommand[]>(
        'commands',
        {
            id: {},
            clientVersion: {},
            serverVersion: {},
            redactedSessionId: { fields: { original: {}, resolved: {} } },
            time: {},
            ip: {},
            userId: {},
            displayName: {},
            companyId: {},
            command: {},
            params: {},
            success: {},
            response: {},
            fromError: {},
            processed: {},
            url: {},
            body: {},
            error: {},
            userAgent: {},
        },
        filters,
    )

// Companies

export const loadCompanies = async () =>
    load<ApiCompany[]>(
        'companies',
        {
            _id: {},
            rev: {},
            status: {},
            ...getVendorFields<never>(),
            // Override address field from vendorFields
            address: { fields: { street: {}, city: {}, postcode: {} } },
            registrationDate: {},
            fiscalYearBegin: { fields: { month: {}, dayOfMonth: {} } },
            longFirstYear: {},
            vatPeriods: { fields: { from: {}, to: {} } },
            interimDate: {},
            card: { fields: { holderName: {}, lastFourDigits: {}, month: {}, year: {}, type: {} } },
            billingName: {},
            billingEmail: {},
            role: {},
            hasReportData: {},
            firstPayment: {},
        },
        { onlyCurrent: false },
    )

export const loadInterimBalance = async () => load<SimpleData<number>>('interimBalance')

export const updateGeneralSettings = async (rev: number, company: GeneralUpdate) => {
    return send(CommandName.updateGeneralSettings, { rev, company })
}

export const updateVatSettings = async (
    companyRev: number,
    settingsRev: number,
    update: VatUpdate,
) => send(CommandName.updateVatSettings, { companyRev, settingsRev, update })

export const updateInvoiceSettings = async (update: InvoiceSettingsUpdate) => {
    return send(CommandName.updateInvoiceSettings, update)
}

// Dashboard

export const loadDashboardData = async (date: string) =>
    load<DashboardData>(
        'dashboardData',
        {
            hasReportData: {},
            revenue: {},
            unpaidRevenues: {},
            unpaidExpenses: {},
            showVatWarning: {},
        },
        { date },
    )

// Notes

export const loadNote = async () => load<Note>('note', { _id: {}, rev: {}, text: {} })

export const updateNote = async (rev: number, text: string) => {
    return send(CommandName.updateNote, { rev, text })
}

// Expenses

export const loadExpenses = async () =>
    load<ApiExpense[]>('expenses', {
        _id: {},
        rev: {},
        type: {},
        calculationMode: {},
        number: {},
        date: {},
        dueDate: {},
        vendor: { fields: { ...businessFields, region: {} } },
        items: {
            fields: {
                ...automaticItemFields,
                payableWithoutVat: {},
                stockChanges: { fields: { date: {}, newQuantity: {}, newUnitPrice: {} } },
            },
        },
        assets: {
            fields: {
                id: {},
                type: {},
                description: {},
                totals: { fields: { withoutVat: {}, other: {}, vatPercentage: {} } },
                amortBegin: {},
                eolDate: {},
                valueChanges: { fields: { date: {}, mode: {}, residual: {}, eolDate: {} } },
            },
        },
        comment: {},
        totals: { fields: { vat: {}, payableWithVat: {} } },
        log: { fields: { ...logFields, assetId: {}, itemIds: {}, filename: {} } },
        confirmed: {},
        paid: {},
        payments: { fields: getPaymentFields<never>() },
        removedPayments: { fields: getPaymentFields<undefined>() },
        vatPayer: {},
        files: { fields: { filename: {}, hash: {}, size: {} } },
        vatMonth: {},
    })

export const createExpense = async (expense: ExpenseInput) => {
    return send(CommandName.createExpense, { expense })
}

export const updateExpense = async (id: string, rev: number, expense: ExpenseUpdate) => {
    return send(CommandName.updateExpense, { id, rev, expense })
}

export const removeExpense = async (id: string) => send(CommandName.removeExpense, { id })

export const confirmExpense = async (id: string, rev: number) => {
    return send(CommandName.confirmExpense, { id, rev })
}

export const addExpensePayment = async (id: string, rev: number, payment: PaymentInput) => {
    return send(CommandName.addExpensePayment, { id, rev, payment })
}

export const removeExpensePayment = async (expenseId: string, rev: number, paymentId: string) => {
    return send(CommandName.removeExpensePayment, { expenseId, rev, paymentId })
}

export const addExpenseFile = async (
    expenseId: string,
    filename: string,
    fileContentBase64: string,
) => send(CommandName.addExpenseFile, { expenseId, filename, fileContentBase64 })

export const removeExpenseFile = async (expenseId: string, hash: string) => {
    return send(CommandName.removeExpenseFile, { expenseId, hash })
}

export const getExpenseFileStorageUsage = async () => send(CommandName.getExpenseFileStorageUsage)

// Pending asset changes

export const loadPendingAssetChanges = async () =>
    load<PendingAssetChange[]>('pendingAssetChanges', {
        _id: {},
        companyId: {},
        expenseId: {},
        assetId: {},
        rev: {},
        valueChange: { fields: { mode: {}, date: {}, residual: {}, eolDate: {} } },
    })

export const createAssetChange = async (
    expenseId: string,
    assetId: string,
    valueChange: AssetValueChange<string>,
) => send(CommandName.createAssetChange, { expenseId, assetId, valueChange })

export const updateAssetChange = async (
    changeId: string,
    rev: number,
    residual: string | undefined,
    eolDate: string | undefined,
) => send(CommandName.updateAssetChange, { changeId, rev, residual, eolDate })

export const removeAssetChange = async (changeId: string) => {
    return send(CommandName.removeAssetChange, { changeId })
}

export const confirmAssetChange = async (changeId: string) => {
    return send(CommandName.confirmAssetChange, { changeId })
}

// Pending stock changes

export const loadPendingStockChanges = async () =>
    load<PendingStockChange[]>('pendingStockChanges', {
        _id: {},
        companyId: {},
        rev: {},
        date: {},
        items: { fields: { expenseId: {}, itemId: {}, newQuantity: {}, newUnitPrice: {} } },
    })

export const createStockChange = async (date: string, items: PendingStockChangeItem<string>[]) => {
    return send(CommandName.createStockChange, { date, items })
}

export const updateStockChange = async (
    changeId: string,
    rev: number,
    items: PendingStockChangeItem<string>[],
) => send(CommandName.updateStockChange, { changeId, rev, items })

export const removeStockChange = async (changeId: string) => {
    return send(CommandName.removeStockChange, { changeId })
}

export const confirmStockChange = async (changeId: string) => {
    return send(CommandName.confirmStockChange, { changeId })
}

// Invoices

export const loadRevenues = async () =>
    load<ApiRevenue[]>(
        'invoices', // TODO change to 'revenues'
        {
            // TODO undup with expenses?
            _id: {},
            rev: {},
            customer: { fields: { isBusiness: {}, details: {}, ...businessFields } },
            date: {},
            term: {},
            items: { fields: automaticItemFields },
            comment: {},
            log: { fields: logFields },
            confirmed: {},
            number: {},
            vendor: { fields: getVendorFields<undefined>() },
            vatPayer: {},
            payments: { fields: getPaymentFields<undefined>() },
            removedPayments: { fields: getPaymentFields<undefined>() },
            paid: {},
            vatMonth: {},
            logoUrl: {},
        },
    )

export const createRevenue = async (invoice: RevenueInput) => {
    return send(CommandName.createInvoice, { invoice })
}

export const updateRevenue = async (id: string, rev: number, invoice: RevenueInput) => {
    return send(CommandName.updateInvoice, { id, rev, invoice })
}

export const confirmRevenue = async (id: string, rev: number) => {
    return send(CommandName.confirmInvoice, { id, rev })
}

export const removeRevenue = async (id: string) => send(CommandName.removeInvoice, { id })

export const addRevenuePayment = async (id: string, rev: number, payment: PaymentInput) => {
    return send(CommandName.addInvoicePayment, { id, rev, payment })
}

export const removeRevenuePayment = async (revenueId: string, rev: number, paymentId: string) => {
    return send(CommandName.removeRevenuePayment, { revenueId, rev, paymentId })
}

// Credit revenues

export const loadCreditRevenues = async () =>
    load<CreditRevenue[]>('creditRevenues', {
        _id: {},
        companyId: {},
        rev: {},
        number: {},
        date: {},
        term: {},
        log: { fields: logFields },
        payments: { fields: getPaymentFields<undefined>() },
        removedPayments: { fields: getPaymentFields<undefined>() },
        paid: {},
        vatMonth: {},
    })

export const createCreditRevenue = async (id: string, date: string, term: string) => {
    return send(CommandName.createCreditRevenue, { id, date, term })
}

export const addCreditRevenuePayment = async (id: string, rev: number, payment: PaymentInput) => {
    return send(CommandName.addCreditRevenuePayment, { id, rev, payment })
}

// Labour costs

export const loadLabourCosts = async () =>
    load<ApiLabourCost[]>('labourCosts', {
        _id: {},
        rev: {},
        month: {},
        note: {},
        gross: {},
        taxFree: {},
        calculatePension: {},
        increaseSocialTax: {},
        confirmed: {},
        netPayments: { fields: getPaymentFields<never>() },
        removedNetPayments: { fields: getPaymentFields<undefined>() },
        netPaid: {},
        taxPayments: { fields: getPaymentFields<never>() },
        removedTaxPayments: { fields: getPaymentFields<undefined>() },
        taxPaid: {},
        log: { fields: logFields },
    })

export const createLabourCost = async (labourCost: LabourCostInput) => {
    return send(CommandName.createLabourCost, { labourCost })
}

export const confirmLabourCost = async (id: string, rev: number) => {
    return send(CommandName.confirmLabourCost, { id, rev })
}

export const addLabourPayment = async (
    id: string,
    rev: number,
    type: LabourPaymentType,
    payment: PaymentInput,
) => send(CommandName.addLabourPayment, { id, rev, type, payment })

export const removeLabourPayment = async (
    labourCostId: string,
    rev: number,
    type: LabourPaymentType,
    paymentId: string,
) => send(CommandName.removeLabourPayment, { labourCostId, rev, type, paymentId })

export const removeLabourCost = async (id: string) => send(CommandName.removeLabourCost, { id })

// Entries

export const loadEntries = async () =>
    load<ApiEntry[]>('entries', {
        _id: {},
        date: {},
        description: {},
        items: { fields: { id: {}, type: {}, amount: {}, accountNumber: {} } },
    })

export const createEntry = async (entry: EntryInput) => send(CommandName.createEntry, { entry })

export const removeEntry = async (id: string) => send(CommandName.removeEntry, { id })

// Users

const getLimitedUserFields = (): ClientFields<ApiUserLimited> => ({
    _id: {},
    firstName: {},
    lastName: {},
})

const getFullUserFields = (): ClientFields<ApiUserFull> => ({
    ...getLimitedUserFields(),
    rev: {},
    email: {},
    role: {},
})

export const loadProfile = async () =>
    load<Profile>('profile', {
        ...getLimitedUserFields(),
        email: {},
        companies: { fields: { id: {}, role: {} } },
    })

export const loadUsersLimited = async () => load<ApiUserLimited[]>('users', getLimitedUserFields())
export const loadUsersFull = async () => load<ApiUserFull[]>('users', getFullUserFields())

// Reports

export const getBalanceReport = async (dates: string[]) => {
    return send(CommandName.getBalanceReport, { dates })
}

export const getIncomeReport = async (periods: Period[]) => {
    return send(CommandName.getIncomeReport, { periods })
}

export const getCashFlowReport = async (periods: Period[]) => {
    return send(CommandName.getCashFlowReport, { periods })
}

export const getTurnoverReport = async (period: Period) => {
    return send(CommandName.getTurnoverReport, { period })
}

// Other

export const login = async (email: string, password: string, mode: LoginMode) => {
    return send(CommandName.login, { email, password, mode })
}

export const logout = async () => send(CommandName.logout)

export const selectCompany = async (id: string | null, password: string | null) => {
    return send(CommandName.selectCompany, { id, password })
}

export const renewSession = async () => send(CommandName.renewSession)

export const signUp = async (user: UserInput) => send(CommandName.signUp, { user })

export const createCompany = async (company: CompanyInput) => {
    return send(CommandName.createCompany, { company })
}

export const updateCompanyGeneral = async (rev: number, company: CompanyUpdate) => {
    return send(CommandName.updateCompanyGeneral, { rev, company })
}

export const updateInitDate = async (rev: number, date: string) => {
    return send(CommandName.updateInitDate, { rev, date })
}

export const updateBillingDetails = async (rev: number, details: BillingDetails) => {
    return send(CommandName.updateBillingDetails, { rev, details })
}

export const initCardTransaction = async (type: CardPaymentType) => {
    return send(CommandName.initCardTransaction, { type })
}

export const loadCardPayments = async () =>
    load<ApiCardPayment[]>('cardPayments', {
        _id: {},
        type: {},
        initTime: {},
        amountWithVat: {},
        amountWithoutVat: {},
        status: {},
        success: {},
        card: {
            fields: { holderName: {}, lastFourDigits: {}, month: {}, year: {}, type: {} },
        },
    })

export const activateCompany = async () => send(CommandName.activateCompany)

export const updateInterimBalance = async (rev: number, interimBalance: SimpleData<string>) => {
    return send(CommandName.updateInterimBalance, { rev, interimBalance })
}

export const archiveCompany = async () => send(CommandName.archiveCompany)

export const getErrorInfo = async () => send(CommandName.getErrorInfo) // TODO get through 'load' cmd?
export const processErrors = async () => send(CommandName.processErrors)

export const lookupBusiness = async (
    collectionName: 'Expenses' | 'Invoices' | undefined,
    searchText: string,
) =>
    load<BusinessLookupResult>(
        'businesses',
        {
            previous: { fields: { _id: {}, name: {}, address: {}, vatId: {}, invoiceCount: {} } },
            other: { fields: { _id: {}, name: {}, address: {}, vatId: {} } },
        },
        { collectionName, searchText },
    )

export const loadSettings = async () =>
    load<ApiSettings>('settings', { rev: {}, vatPercentage: {}, paymentTerm: {}, logoUrl: {} })

export const uploadCompanyLogo = async (rev: number, fileContentBase64: string) => {
    return send(CommandName.uploadCompanyLogo, { rev, fileContentBase64 })
}

export const removeCompanyLogo = async (rev: number) => send(CommandName.removeCompanyLogo, { rev })

export const initPasswordReset = async (email: string) => {
    return send(CommandName.initPasswordReset, { email })
}

export const resetPassword = async (resetCode: string, password: string) => {
    return send(CommandName.resetPassword, { resetCode, password })
}

export const updateSelf = async (firstName: string, lastName: string) => {
    return send(CommandName.updateSelf, { firstName, lastName })
}

export const updateUserRole = async (userId: string, rev: number, role: CompanyRole) => {
    return send(CommandName.updateUserRole, { userId, rev, role })
}

export const removeUser = async (id: string) => {
    return send(CommandName.removeUserFromCompany, { id })
}

export const inviteUser = async (email: string, role: CompanyRole) => {
    return send(CommandName.inviteUser, { email, role })
}

export const acceptInvite = async (inviteId: string) => send(CommandName.acceptInvite, { inviteId })

export const loadReceivedInvites = async () =>
    load<ApiInvite[]>('receivedInvites', {
        _id: {},
        time: {},
        companyId: {},
        companyName: {},
        email: {},
        role: {},
    })

export const loadVatState = async () =>
    load<VatPaymentState>('vatState', { paidMonths: {}, prepaid: {} })

export const assignVatMonth = async (params: AssignVatMonthParams) => {
    return send(CommandName.assignVatMonth, params)
}

export const addVatPayment = async (payment: VatPaymentInput) => {
    return send(CommandName.addVatPayment, { payment })
}

// Only for manual testing with fake companies
export const clearData = async () => send(CommandName.clearData)

// Only for automatic tests

export const enableDebugMode = () => {
    debugMode = true
}
export const setHttpClient = (client: HttpClient) => {
    httpClient = client
}
export const anyPendingRequests = () => pendingRequests.size > 0

export const waitForPendingRequests = async (depth: number = 0) => {
    // Feel free to increase this limit if needed
    if (depth > 10) {
        throw new Error('Too much recursion while waiting for pending API requests')
    }

    if (pendingRequests.size) {
        const [first] = pendingRequests
        await first
        await waitForPendingRequests(depth + 1)
    }
}
