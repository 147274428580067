import classnames from 'classnames'
import React, { Fragment } from 'react'

import { ValidationError } from '../../../common/types/errors'
import { InputValues } from '../../../common/types/inputs'
import { Processes } from '../../../common/types/processes'
import { t } from '../../i18n'
import { inputs } from '../../inputs'
import { PASSWORD_RESET_PROCESS, reset } from '../../state/password-reset-actions'
import { valErr } from '../../val-err'
import { Button } from '../button'
import { Input } from '../input'

interface Props {
    inputValues: InputValues
    processes: Processes
    valErrors: ValidationError[] | undefined
    resetCode: string
}

export const Change = ({ inputValues, processes, valErrors, resetCode }: Props) => {
    const password = inputs.passwordReset.password.get(inputValues)
    const passwordRepeat = inputs.passwordReset.repeatPassword.get(inputValues)
    const passwordIsEmpty = password === ''
    const passwordsMatch = password === passwordRepeat
    const shouldDisableSubmit = passwordIsEmpty || !passwordsMatch
    const resetWithCode = async () => reset(resetCode)

    return React.createElement(
        Fragment,
        null,
        React.createElement('div', { className: 'bottom-margin' }, t.password.reset.enterNew.get()),
        React.createElement(
            'div',
            null,
            React.createElement(Input, {
                type: 'password',
                input: inputs.passwordReset.password,
                inputValues,
                placeholder: t.password.get(),
                focusOnMount: true,
                className: 'password-reset__input',
            }),
            valErr(valErrors, 'password'),
        ),
        React.createElement(
            'div',
            null,
            React.createElement(Input, {
                type: 'password',
                input: inputs.passwordReset.repeatPassword,
                inputValues,
                placeholder: t.password.repeat.get(),
                className: classnames('password-reset__input', {
                    'password-reset__input--invalid': !passwordsMatch,
                }),
                onEnter: shouldDisableSubmit ? undefined : resetWithCode,
            }),
        ),
        React.createElement(
            'div',
            { className: 'password-reset__section' },
            React.createElement(Button, {
                text: t.continue.get(),
                onClick: resetWithCode,
                processes,
                processName: PASSWORD_RESET_PROCESS,
                className: 'password-reset__main-button',
                loadingColor: 'white',
                loadingStyle: { marginTop: '0.8em', width: 25, height: 25 },
                disabled: shouldDisableSubmit,
            }),
        ),
    )
}
