import classnames from 'classnames'
import React, { FC, Fragment } from 'react'

import { keys } from '../../../common/keys'
import { Period, PeriodCashFlow } from '../../../common/types/reports'
import { Column } from '../../../common/types/table'
import { getExcelButtonProps } from '../../excel-utils'
import { HEADER_STYLE } from '../../excel/style'
import { ExcelFont, ExcelNumberFormat, ExcelSpec, ExcelStyle } from '../../excel/types'
import { formatAmount } from '../../format-amount'
import { t } from '../../i18n'
import { getCompany } from '../../state/company-actions'
import { LOAD_CASH_FLOW_PROCESS, loadCashFlow } from '../../state/report-actions'
import { RootData } from '../../state/root-data'
import { ExcelButton } from '../excel-button'
import { LoadingIcon } from '../loading-icon'
import { type BaseRow, renderTable } from '../table'
import { NoReportData } from './no-data'
import { PeriodsChoice } from './periods-choice'

interface Row extends BaseRow {
    isEmpty?: true
    label: string
    isTotal?: boolean
    amounts: Map<Period, number>
    total: number | null
}

const rightAlignedExcelHeader: ExcelStyle = {
    ...HEADER_STYLE,
    alignment: { horizontal: 'right' },
}

const renderBrowserAmount = (value: number | null) => (value === null ? '' : formatAmount(value))

const renderExcelAmount = (value: number | null, row: Row) => {
    if (value === null) {
        return ''
    }

    return {
        value,
        style: {
            numberFormat: ExcelNumberFormat.money,
            font: row.isTotal ? ExcelFont.bold : ExcelFont.regular,
        },
    }
}

const getColumns = (loaded: PeriodCashFlow[]) => {
    const hasMultiple = loaded.length > 1

    const columns: Column<Row>[] = [
        {
            getProps: (row) => ({
                className: classnames('table__body-cell', 'table__body-cell--no-left-pad', {
                    'text-bold': row.isTotal,
                }),
            }),
            render: (row) => ({
                // Use non-breaking space as the content to avoid collapsing the height
                browser: row.isEmpty ? '\u00a0' : row.label,
                excel: {
                    value: row.label,
                    style: { font: row.isTotal ? ExcelFont.bold : ExcelFont.regular },
                },
            }),
            excelWidth: 50,
        },
        ...loaded.map(
            ({ period, label }): Column<Row> => ({
                header: {
                    content: label,
                    excelContent: { value: label || '', style: rightAlignedExcelHeader },
                    getProps: () => ({ className: 'text-right' }),
                },
                getProps: (row) => ({
                    className: classnames(
                        'table__body-cell',
                        'table__body-cell--no-left-pad',
                        'amount',
                        {
                            'text-bold': row.isTotal,
                        },
                    ),
                }),
                render: (row) => {
                    const value = row.amounts?.get(period) ?? null

                    return {
                        browser: renderBrowserAmount(value),
                        excel: renderExcelAmount(value, row),
                    }
                },
                excelWidth: Math.max(15, label && hasMultiple ? label.length + 5 : 0),
            }),
        ),
    ]

    if (hasMultiple) {
        columns.push({
            header: {
                content: t.total.get(),
                excelContent: { value: t.total.get(), style: rightAlignedExcelHeader },
                getProps: () => ({ className: 'text-bold text-right' }),
            },
            getProps: () => ({ className: 'amount text-bold' }),
            render: (row) => {
                if (row.total === null) {
                    return ''
                }

                return {
                    browser: renderBrowserAmount(row.total),
                    excel: {
                        value: row.total,
                        style: { font: ExcelFont.bold, numberFormat: ExcelNumberFormat.money },
                    },
                }
            },
            excelWidth: 15,
        })
    }

    return columns
}

const getEmptyRow = (): Row => ({
    isEmpty: true,
    label: '',
    amounts: new Map<Period, number>(),
    total: null,
})

const getRows = (loaded: PeriodCashFlow[]) => {
    const [first] = loaded

    if (!first) {
        return []
    }

    const rows: Row[] = []

    const addRow = (
        label: string,
        isTotal: boolean,
        getValue: (periodCashFlow: PeriodCashFlow) => number,
    ) => {
        const amounts = new Map<Period, number>()
        let total = 0

        for (const periodCashFlow of loaded) {
            const { period } = periodCashFlow
            const value = getValue(periodCashFlow)
            amounts.set(period, value)
            total += value
        }

        const row: Row = { label, isTotal, amounts, total }
        rows.push(row)
        return row
    }

    for (const key of keys(first.operating)) {
        addRow(
            t.reports.cashFlow.operating[key].get(),
            key === 'total',
            (periodCashFlow) => periodCashFlow.operating[key],
        )
    }

    rows.push(getEmptyRow())

    for (const key of keys(first.investing)) {
        addRow(
            t.reports.cashFlow.investing[key].get(),
            key === 'total',
            (periodCashFlow) => periodCashFlow.investing[key],
        )
    }

    rows.push(getEmptyRow())

    for (const key of keys(first.financing)) {
        addRow(
            t.reports.cashFlow.financing[key].get(),
            key === 'total',
            (periodCashFlow) => periodCashFlow.financing[key],
        )
    }

    rows.push(getEmptyRow())

    addRow(
        t.reports.cashFlow.total.get().toUpperCase(),
        true,
        // Equal to operating.total + investing.total + financing.total
        (periodCashFlow) => periodCashFlow.totals.change,
    )

    rows.push(getEmptyRow())

    for (const key of keys(first.totals)) {
        const isTotal = key === 'change'

        const row = addRow(
            t.reports.cashFlow.totals[key].get(),
            isTotal,
            (periodCashFlow) => periodCashFlow.totals[key],
        )

        if (!isTotal) {
            row.total = null
        }
    }

    return rows
}

const getSubtitle = (loaded: PeriodCashFlow[]) =>
    loaded.length === 1 ? t.onPeriod.get(loaded[0].label!) : null

const renderContent = (rootData: RootData) => {
    const {
        companyData,
        inputValues,
        processes,
        progress,
        reports: {
            cashFlow: { loaded },
            interimDateToday,
            periodsError,
        },
        session,
        validationErrors,
    } = rootData

    const title = t.reports.cashFlow.get()
    const subtitle = getSubtitle(loaded)

    const titleElement = React.createElement(
        Fragment,
        null,
        React.createElement(
            'h1',
            { className: 'title cash-flow-title' },
            title,
            subtitle
                ? React.createElement('span', { className: 'title__sub-title' }, subtitle)
                : null,
        ),
        React.createElement(
            'div',
            { className: 'cash-flow-method' },
            t.reports.cashFlow.directMethod.get(),
        ),
    )

    if (interimDateToday) {
        return React.createElement(
            Fragment,
            null,
            titleElement,
            React.createElement(
                'div',
                { className: 'top-margin extra' },
                t.reports.interimDateToday.get(),
            ),
        )
    }

    if (!loaded.length || !companyData.companies || processes.has(LOAD_CASH_FLOW_PROCESS)) {
        return React.createElement(LoadingIcon, { color: 'black' })
    }

    const company = getCompany(companyData, session)
    const columns = getColumns(loaded)
    const rows = getRows(loaded)
    const valErrors = validationErrors[LOAD_CASH_FLOW_PROCESS]

    const spec: ExcelSpec<Row> = {
        columns,
        rows,
        outputName: title + (subtitle ? ' ' + subtitle : ''),
        noHeader: loaded.length < 2,
    }

    const excelButton = getExcelButtonProps(
        spec,
        '',
        processes,
        progress,
        'button--wide button--primary',
    )

    return React.createElement(
        Fragment,
        null,
        React.createElement(
            'div',
            { className: 'align-right relative' },
            React.createElement(
                'div',
                null,
                React.createElement(
                    'div',
                    { className: 'top-margin' },
                    React.createElement(ExcelButton, excelButton),
                ),
                React.createElement(PeriodsChoice, {
                    inputValues,
                    periodsError,
                    company,
                    valErrors,
                    load: loadCashFlow,
                }),
            ),
        ),
        titleElement,
        renderTable({
            columns,
            rows,
            noHeader: loaded.length < 2,
            noWrapper: true,
            stickyHeader: true,
            tableClassName: 'cash-flow-statement',
        }),
    )
}

export const CashFlowReport: FC<RootData> = (rootData) => {
    const { companyData, session } = rootData

    if (companyData.companies) {
        const company = getCompany(companyData, session)

        if (!company.hasReportData) {
            return React.createElement(NoReportData)
        }
    }

    return React.createElement(
        'div',
        { className: 'content-area' },
        React.createElement('div', { className: 'content report' }, renderContent(rootData)),
    )
}
