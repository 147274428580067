import React, { FC, Fragment } from 'react'

import { ValidationError } from '../../../common/types/errors'
import { Input, InputValues } from '../../../common/types/inputs'
import { t } from '../../i18n'
import { valErr } from '../../val-err'
import { InputRow } from './input-row'

interface AddressInputs {
    street: Input<string>
    city: Input<string>
    postcode: Input<string>
}

interface Props {
    editMode: boolean
    addressInputs: AddressInputs
    inputValues: InputValues
    valErrors: ValidationError[] | undefined
}

interface AddressElement {
    key: keyof AddressInputs
    label: string
}

const getAddressElements = (): AddressElement[] => [
    {
        key: 'street',
        label: t.business.address.get(),
    },
    {
        key: 'city',
        label: t.business.address.city.get(),
    },
    {
        key: 'postcode',
        label: t.business.address.postcode.get(),
    },
]

const renderRow = (
    { key, label }: AddressElement,
    addressInputs: AddressInputs,
    inputValues: InputValues,
    valErrors: ValidationError[] | undefined,
) =>
    React.createElement(
        Fragment,
        null,
        React.createElement(InputRow, {
            editMode: true,
            label,
            input: addressInputs[key],
            inputValues,
        }),
        valErr(valErrors, 'company.update.address.' + key),
    )

export const SettingsAddress: FC<Props> = (props) => {
    const { editMode, addressInputs, inputValues, valErrors } = props

    if (editMode) {
        const addressElements = getAddressElements()

        return React.createElement(
            Fragment,
            null,
            ...addressElements.map((elem) =>
                renderRow(elem, addressInputs, inputValues, valErrors),
            ),
        )
    } else {
        const street = addressInputs.street.get(inputValues)
        const city = addressInputs.city.get(inputValues)
        const postcode = addressInputs.postcode.get(inputValues)

        return React.createElement(
            'div',
            { className: 'settings__input-row' },
            React.createElement(
                'span',
                { className: 'settings__label' },
                t.business.address.get(),
                ': ',
            ),
            React.createElement(
                'div',
                null,
                React.createElement('div', null, street),
                React.createElement('div', null, city, ' ', postcode),
            ),
        )
    }
}
