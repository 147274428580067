import React, { FC } from 'react'

import { LoadingIcon } from '../loading-icon'
import { TaxesSidebar } from './sidebar'
import { TaxesVatMain, TaxesVatMainProps } from './vat-main'
import { VatPaymentSidebar, VatPaymentSidebarProps } from './vat-payment-sidebar'

type VatSidebarProps =
    | { type: 'loading' }
    | { type: 'taxes' }
    | { type: 'payment'; props: VatPaymentSidebarProps }

export interface TaxesVatProps {
    sidebar: VatSidebarProps
    title: string
    subtitle: string
    main?: TaxesVatMainProps
}

const VatSidebar: FC<VatSidebarProps> = (sidebar) => {
    if (sidebar.type === 'loading') {
        return React.createElement(
            'div',
            { className: 'sidebar' },
            React.createElement(LoadingIcon, { color: 'white' }),
        )
    } else if (sidebar.type === 'taxes') {
        return React.createElement(TaxesSidebar, { selected: 'vat' })
    } else {
        return React.createElement(VatPaymentSidebar, sidebar.props)
    }
}

export const TaxesVat: FC<TaxesVatProps> = ({ sidebar, title, subtitle, main: content }) => {
    return React.createElement(
        'div',
        { className: 'content-area' },
        React.createElement(VatSidebar, sidebar),
        React.createElement(
            'div',
            { className: 'content taxes' },
            React.createElement(
                'h1',
                { className: 'title' },
                title,
                React.createElement('span', { className: 'title__sub-title' }, subtitle),
            ),
            React.createElement(
                'div',
                null,
                content
                    ? React.createElement(TaxesVatMain, content)
                    : React.createElement(LoadingIcon, { color: 'black' }),
            ),
        ),
    )
}
