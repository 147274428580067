import React, { FC } from 'react'

import { calculateExpenseInitial } from '../../../common/expense-utils'
import { calculateRevenue } from '../../../common/invoice-utils'
import { calculateLabourCost } from '../../../common/labour-cost-utils'
import { range } from '../../../common/range'
import { Day } from '../../../common/time'
import { Column } from '../../../common/types/table'
import { upperCaseFirst } from '../../../common/upper-case-first'
import { formatAmount } from '../../format-amount'
import { t } from '../../i18n'
import { RootData } from '../../state/root-data'
import { LoadingIcon } from '../loading-icon'
import { type BaseRow, renderTable } from '../table'
import { TaxesSidebar } from './sidebar'

interface Row extends BaseRow {
    firstOfMonth: Day
    salesVat: number
    purchaseVat: number
    labourTaxes: number
}

const getRightAligned = () => ({ className: 'text-right' })

const renderSummaryTable = ({ expenseData, invoiceData, labourCostData }: RootData) => {
    const { expenses } = expenseData
    const { invoices } = invoiceData
    const { labourCosts } = labourCostData

    if (!expenses || !invoices || !labourCosts) {
        return React.createElement(LoadingIcon, { color: 'black' })
    }

    const columns: Column<Row>[] = [
        {
            header: { content: t.month.get() },
            render: (row) => upperCaseFirst(row.firstOfMonth.monthName()),
        },
        {
            header: { content: t.taxes.summary.salesVat.get(), getProps: getRightAligned },
            getProps: getRightAligned,
            render: (row) => formatAmount(row.salesVat),
        },
        {
            header: { content: t.taxes.summary.purchaseVat.get(), getProps: getRightAligned },
            getProps: getRightAligned,
            render: (row) => formatAmount(row.purchaseVat),
        },
        {
            header: { content: t.taxes.summary.labourTaxes.get(), getProps: getRightAligned },
            getProps: getRightAligned,
            render: (row) => formatAmount(row.labourTaxes),
        },
    ]

    const today = Day.today()
    const year = today.year()

    const rowByMonth = new Map<string, Row>()

    const rows = range(1, today.month())
        .reverse()
        .map((month): Row => {
            const firstOfMonth = Day.fromNumeric(year, month, 1)
            const row = { firstOfMonth, salesVat: 0, purchaseVat: 0, labourTaxes: 0 }
            rowByMonth.set(firstOfMonth.ym(), row)
            return row
        })

    const firstOfYear = Day.fromNumeric(year, 1, 1)
    const minMonth = firstOfYear.ym()

    for (const revenue of invoices) {
        if (!revenue.confirmed || !revenue.vatMonth) {
            continue
        }

        const month = revenue.vatMonth

        if (month >= minMonth) {
            const row = rowByMonth.get(month)!
            row.salesVat += calculateRevenue(revenue).vatAmount
        }
    }

    for (const expense of expenses) {
        if (!expense.confirmed || !expense.vatMonth) {
            continue
        }

        const month = expense.vatMonth

        if (month >= minMonth) {
            const row = rowByMonth.get(month)!
            row.purchaseVat += calculateExpenseInitial(expense).vatAmount
        }
    }

    for (const labourCost of labourCosts) {
        if (!labourCost.confirmed) {
            continue
        }

        if (labourCost.month >= minMonth) {
            const { taxes } = calculateLabourCost(labourCost)
            const row = rowByMonth.get(labourCost.month)!
            row.labourTaxes += taxes
        }
    }

    return renderTable({
        columns,
        rows,
        tableClassName: 'main-table',
        wrapperClassName: 'main-table-wrapper',
    })
}

export const TaxesSummary: FC<RootData> = (rootData) =>
    React.createElement(
        'div',
        { className: 'content-area' },
        React.createElement(TaxesSidebar, { selected: 'summary' }),
        React.createElement(
            'div',
            { className: 'content' },
            React.createElement(
                'div',
                null,
                React.createElement(
                    'h1',
                    { className: 'title' },
                    t.taxes.get(),
                    React.createElement(
                        'span',
                        { className: 'title__sub-title' },
                        Day.today().year(),
                    ),
                ),
                renderSummaryTable(rootData),
            ),
        ),
    )
