import React from 'react'

const bankNames = ['Bigbank', 'Coop Pank', 'Inbank', 'LHV', 'Luminor', 'SEB', 'Swedbank']

export const BanksList = () =>
    React.createElement(
        'datalist',
        { id: 'bank-names' },
        ...bankNames.map((name) => React.createElement('option', { value: name })),
    )
