import React, { FC } from 'react'

import { getColumns, Row } from '../../revenue-archive-utils'
import { ExcelButton, ExcelButtonProps } from '../excel-button'
import { Link, LinkProps } from '../link'
import { LoadingPage } from '../loading-page'
import { renderTable } from '../table'
import { RevenueArchiveSidebar } from './archive-sidebar'

export interface FilterItem {
    id: string
    label: string
    value: string
}

export interface RevenueArchiveResultsOkProps {
    loading: false
    title: string
    summaryTitle: string
    filters?: FilterItem[]
    noFiltersText?: string
    showTermColumn: boolean
    showDueDateColumn: boolean
    excelButton?: ExcelButtonProps
    rows: Row[]
    backLink: LinkProps
}

export type RevenueArchiveResultsProps = { loading: true } | RevenueArchiveResultsOkProps

export const RevenueArchiveResults: FC<RevenueArchiveResultsProps> = (props) => {
    if (props.loading) {
        return React.createElement(LoadingPage)
    }

    const {
        title,
        summaryTitle,
        filters,
        noFiltersText,
        showTermColumn,
        showDueDateColumn,
        excelButton,
        rows,
        backLink,
    } = props

    const columns = getColumns(showTermColumn, showDueDateColumn)

    return React.createElement(
        'div',
        { className: 'content-area' },
        React.createElement(RevenueArchiveSidebar, {}),
        React.createElement(
            'div',
            { className: 'content archive' },
            React.createElement(
                'div',
                null,
                React.createElement('h1', { className: 'title archive__title' }, title),
                React.createElement(
                    'div',
                    null,
                    React.createElement(
                        'div',
                        { className: 'archive__filter-summary-title' },
                        summaryTitle,
                        ':',
                    ),
                    React.createElement(
                        'div',
                        { className: 'archive__filter-summary' },
                        filters?.map(({ id, label, value }) =>
                            React.createElement(
                                'div',
                                { key: id },
                                React.createElement('b', null, label, ':'),
                                ' ',
                                value,
                            ),
                        ),
                        noFiltersText,
                    ),
                ),
                React.createElement(
                    'div',
                    { className: 'top-margin' },
                    excelButton && React.createElement(ExcelButton, { ...excelButton }),
                ),
                renderTable({
                    columns,
                    rows,
                    stickyHeader: true,
                    wrapperClassName: 'top-margin',
                    tableClassName: 'table table--bottom-border archive__result-table',
                }),
            ),
            React.createElement(
                'div',
                { className: 'top-margin' },
                React.createElement(Link, { ...backLink, className: 'button button--primary' }),
            ),
        ),
    )
}
