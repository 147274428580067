import * as Sentry from '@sentry/react'

import { ApiSession } from '../common/types/session'
import {
    emitAfterStoreReset,
    emitCloseSessionExpiringBanner,
    emitUpdatedSession,
} from './event-bus'
import { setRoute } from './route-utils'
import { reset } from './state/store'

export const logoutClient = () => {
    localStorage.removeItem('bookySession')
    Sentry.setUser(null)
    reset(true)
    emitCloseSessionExpiringBanner()
    setRoute('#/')
}

export const updateSession = (newSession: ApiSession) => {
    reset(false)
    emitUpdatedSession(newSession)
    emitAfterStoreReset()
}
