import React, { FC } from 'react'

import { canAlwaysChangeRoleFrom, regularRoles } from '../../../common/access'
import { InputValues } from '../../../common/types/inputs'
import { Column } from '../../../common/types/table'
import { ApiUserFull } from '../../../common/types/user'
import { getDisplayName } from '../../../common/user-utils'
import { t } from '../../i18n'
import { inputs } from '../../inputs'
import { RootData } from '../../state/root-data'
import {
    cancelUserRoleUpdate,
    editUserRole,
    REMOVE_USER_PROCESS,
    removeUser,
    SAVE_USER_ROLE_PROCESS,
    saveUserRole,
    SEND_INVITE_PROCESS,
    sendInvite,
} from '../../state/settings-actions'
import { browserOnly } from '../../table-utils'
import { valErr } from '../../val-err'
import { Button } from '../button'
import { renderChoice } from '../choice'
import { Input } from '../input'
import { LoadingIcon } from '../loading-icon'
import { type BaseRow, renderTable } from '../table'

const getRoleOptions = () => regularRoles.map((role) => ({ id: role, label: t.roles[role].get() }))

const getLeftAligned = () => ({ className: 'text-left' })

const renderEditRoleCell = (inputValues: InputValues) => {
    return renderChoice({
        type: 'dropdown',
        forceSelection: true,
        input: inputs.settings.users.editedUser.role,
        inputValues,
        options: getRoleOptions(),
        domId: 'select-role',
    })
}

const getNumManagers = (users: ApiUserFull[]) => {
    return users.filter(({ role }) => !canAlwaysChangeRoleFrom(role)).length
}

const canEditUser = (user: ApiUserFull, { session, userData }: RootData) => {
    const { role, _id } = user

    if (!regularRoles.includes(role)) {
        return false
    }

    if (canAlwaysChangeRoleFrom(role)) {
        return true
    }

    if (_id !== session!.userId) {
        return false
    }

    const numManagers = getNumManagers(userData.usersFull!)
    return numManagers > 1
}

const renderRoleCell = (user: ApiUserFull, rootData: RootData) => {
    const editedUserId = inputs.settings.users.editedUser.id.get(rootData.inputValues)

    if (editedUserId === user._id) {
        return renderEditRoleCell(rootData.inputValues)
    } else {
        return t.roles[user.role].get()
    }
}

const renderEditActionsCell = () => {
    return React.createElement(
        'div',
        null,
        React.createElement(Button, {
            text: t.save.get(),
            onClick: async () => saveUserRole(),
            domId: 'save-role',
            className: 'button--primary',
        }),
        ' ',
        React.createElement(Button, {
            text: t.cancel.get(),
            onClick: cancelUserRoleUpdate,
            className: 'button--secondary',
        }),
    )
}

const renderViewActionsCell = (user: ApiUserFull, rootData: RootData) => {
    if (!canEditUser(user, rootData)) {
        return null
    }

    return React.createElement(
        'div',
        null,
        React.createElement(Button, {
            text: t.settings.users.editRole.get(),
            onClick: () => editUserRole(user),
            domId: 'edit-user-' + user._id,
            className: 'button--primary',
        }),
        ' ',
        React.createElement(Button, {
            text: t.settings.users.removeUser.get(),
            onClick: async () => {
                if (confirm(t.settings.users.confirmRemoveUser.get())) {
                    await removeUser(user._id)
                }
            },
            domId: 'remove-user-' + user._id,
            className: 'button--secondary',
        }),
    )
}

const renderActionsCell = (user: ApiUserFull, rootData: RootData) => {
    const editedUserId = inputs.settings.users.editedUser.id.get(rootData.inputValues)

    if (editedUserId === user._id) {
        return renderEditActionsCell()
    } else {
        return renderViewActionsCell(user, rootData)
    }
}

const renderUsers = (rootData: RootData) => {
    const columns: Column<ApiUserFull>[] = [
        {
            header: { content: t.name.get(), getProps: getLeftAligned },
            render: (user) => getDisplayName(user),
        },
        {
            header: { content: t.email.get(), getProps: getLeftAligned },
            render: (user) => user.email,
        },
        {
            header: { content: t.role.get(), getProps: getLeftAligned },
            render: browserOnly((user) => renderRoleCell(user, rootData)),
        },
        {
            header: { content: t.actions.get(), getProps: getLeftAligned },
            render: browserOnly((user) => renderActionsCell(user, rootData)),
        },
    ]

    return renderTable<ApiUserFull & BaseRow>({
        columns,
        rows: rootData.userData.usersFull!,
        domId: 'users',
    })
}

export const CompanyUserSettings: FC<RootData> = (rootData) => {
    const { inputValues, processes, userData, validationErrors } = rootData

    if (
        !userData.usersFull ||
        processes.has(SAVE_USER_ROLE_PROCESS) ||
        processes.has(REMOVE_USER_PROCESS)
    ) {
        return React.createElement(LoadingIcon, { color: 'black' })
    } else {
        const valErrors = validationErrors[SEND_INVITE_PROCESS]

        return React.createElement(
            'div',
            null,
            React.createElement('h1', { className: 'title' }, t.users.get()),
            renderUsers(rootData),
            React.createElement('h1', { className: 'title' }, t.settings.users.addUser.get()),
            React.createElement(
                'div',
                { className: 'text-multiline' },
                t.settings.users.addUserInfo.get(),
            ),
            React.createElement(
                'div',
                { className: 'top-margin' },
                t.email.get(),
                ': ',
                React.createElement(Input, {
                    input: inputs.settings.users.newUserEmail,
                    inputValues,
                }),
                valErr(valErrors, 'email'),
            ),
            React.createElement(
                'div',
                { className: 'top-margin' },
                t.role.get(),
                ': ',
                renderChoice({
                    type: 'buttons',
                    input: inputs.settings.users.newUserRole,
                    inputValues,
                    options: getRoleOptions(),
                    buttonClassName: 'button--secondary',
                    selectedButtonClassName: 'button--secondary-selected',
                }),
                valErr(valErrors, 'role'),
            ),
            React.createElement(
                'div',
                null,
                React.createElement(Button, {
                    text: t.settings.users.sendInvite.get(),
                    onClick: sendInvite,
                    className: 'button--primary',
                    processes,
                    processName: SEND_INVITE_PROCESS,
                }),
            ),
        )
    }
}
