import numeral from 'numeral'

import { assertNever } from '../../common/assert-never'
import { findByDbId } from '../../common/find-by-db-id'
import {
    calculateFromNet,
    calculateLabourCost,
    calculateLabourCostFromValues,
    getLabourTaxRates,
} from '../../common/labour-cost-utils'
import { getPaidAmount } from '../../common/payment-utils'
import { Day } from '../../common/time'
import { LabourPaymentType } from '../../common/types/enums'
import { InputValues } from '../../common/types/inputs'
import {
    ApiLabourCost,
    LabourCostData,
    LabourCostInput,
    LabourCostViewMode,
} from '../../common/types/labour-cost'
import { DbPayment } from '../../common/types/payment'
import * as api from '../api'
import { getNumeric } from '../get-numeric'
import { inputs } from '../inputs'
import { setRoute } from '../route-utils'
import { setInvalid } from './invalid-cache-actions'
import { loadCompanies, loadLabourCosts } from './load-actions'
import {
    closeExpensePaymentForm,
    getPaymentInput,
    REMOVE_PAYMENT_PROCESS,
    SAVE_PAYMENT_PROCESS,
} from './payment-actions'
import { run } from './process-actions'
import { dispatch, getState } from './store'
import { clearErrors } from './validation-actions'

export const CONFIRM_PROCESS = 'labour-cost/confirm'
export const SAVE_PROCESS = 'labour-cost/save'
export const REMOVE_PROCESS = 'labour-cost/remove'

export const invalidateCache = () => setInvalid('labour-cost')

export const getById = (data: LabourCostData, id: string): ApiLabourCost | undefined => {
    if (!data.labourCosts) {
        throw new Error('Labour costs not loaded')
    }

    return findByDbId(data.labourCosts, id)
}

/** Returns NaN if number is negative or invalid */
const toNonNegativeNumber = (value: string) => {
    const number = Number(value)
    return number < 0 ? NaN : number
}

export const calculateLabourCostFromInputs = (inputValues: InputValues) => {
    const month = getMonth(inputValues)
    const labourTaxRates = getLabourTaxRates(month)

    const amount = toNonNegativeNumber(getNumeric(inputs.labourCost.amount, inputValues))
    const amountMode = inputs.labourCost.amountMode.get(inputValues)
    const taxFreeMode = inputs.labourCost.taxFreeMode.get(inputValues)
    const calculatePension = inputs.labourCost.calculatePension.get(inputValues)
    const increaseSocialTax = inputs.labourCost.increaseSocialTax.get(inputValues)

    const customTaxFree =
        taxFreeMode === 'manual'
            ? toNonNegativeNumber(getNumeric(inputs.labourCost.customTaxFree, inputValues))
            : null

    return amountMode === 'net'
        ? calculateFromNet(
              labourTaxRates,
              amount,
              customTaxFree,
              calculatePension,
              increaseSocialTax,
          )
        : calculateLabourCostFromValues(
              labourTaxRates,
              amount,
              customTaxFree,
              calculatePension,
              increaseSocialTax,
          )
}

export const getMonth = (inputValues: InputValues) =>
    inputs.labourCost.year.get(inputValues) +
    '-' +
    numeral(inputs.labourCost.month.get(inputValues)).format('00')

const getInput = (): LabourCostInput => {
    const { inputValues } = getState()

    const month = getMonth(inputValues)
    const note = inputs.labourCost.note.get(inputValues)
    const calculatePension = inputs.labourCost.calculatePension.get(inputValues)
    const increaseSocialTax = inputs.labourCost.increaseSocialTax.get(inputValues)
    const calc = calculateLabourCostFromInputs(inputValues)

    return {
        month,
        note,
        gross: calc.gross,
        calculatePension,
        increaseSocialTax,
        taxFree: calc.actualTaxFree,
    }
}

const loadListData = async () => Promise.all([loadCompanies(), loadLabourCosts()])

export const initList = async () => {
    dispatch(({ labourCostData }) => {
        labourCostData.mode = LabourCostViewMode.list
        labourCostData.id = ''
    })

    await loadListData()
}

export const initAddForm = async () => {
    dispatch(({ labourCostData }) => {
        labourCostData.mode = LabourCostViewMode.addInit
        labourCostData.id = ''
    })

    clearErrors(SAVE_PROCESS)
    await loadListData()
    const prevMonth = Day.today().firstOfPreviousMonth()
    inputs.labourCost.month.set(prevMonth.month())
    inputs.labourCost.year.set(String(prevMonth.year()))
    inputs.labourCost.note.set('')
    inputs.labourCost.amountMode.set('gross')
    inputs.labourCost.amount.set('')
    inputs.labourCost.calculatePension.set(true)
    inputs.labourCost.increaseSocialTax.set(false)
    inputs.labourCost.taxFreeMode.set('automatic')
    inputs.labourCost.customTaxFree.set('')
}

export const initViewForm = async (id: string) => {
    dispatch(({ labourCostData }) => {
        labourCostData.mode = LabourCostViewMode.view
        labourCostData.id = id
    })

    clearErrors(SAVE_PROCESS)
    await loadListData()
    const labourCost = getById(getState().labourCostData, id)!
    const month = Day.fromYm(labourCost.month)

    inputs.labourCost.year.set(month.year().toString())
    inputs.labourCost.month.set(month.month())
    inputs.labourCost.note.set(labourCost.note)
}

export const nextStep = () => {
    const {
        inputValues,
        labourCostData: { mode },
    } = getState()

    if (mode === LabourCostViewMode.addInit) {
        const calc = calculateLabourCostFromInputs(inputValues)

        const month = getMonth(inputValues)
        const { minSocialTax } = getLabourTaxRates(month)

        if (calc.socialTax < minSocialTax) {
            inputs.labourCost.increaseSocialTax.set(true)
            dispatch(({ labourCostData }) => (labourCostData.mode = LabourCostViewMode.addSocial))
        } else {
            dispatch(({ labourCostData }) => (labourCostData.mode = LabourCostViewMode.addFinal))
        }
    } else if (mode === LabourCostViewMode.addSocial) {
        dispatch(({ labourCostData }) => (labourCostData.mode = LabourCostViewMode.addFinal))
    }
}

export const previousStep = () => {
    const {
        inputValues,
        labourCostData: { mode },
    } = getState()

    if (mode === LabourCostViewMode.addFinal) {
        const calc = calculateLabourCostFromInputs(inputValues)

        const month = getMonth(inputValues)
        const { minSocialTax } = getLabourTaxRates(month)

        if (calc.socialTax < minSocialTax) {
            dispatch(({ labourCostData }) => (labourCostData.mode = LabourCostViewMode.addSocial))
        } else {
            dispatch(({ labourCostData }) => (labourCostData.mode = LabourCostViewMode.addInit))
        }
    } else if (mode === LabourCostViewMode.addSocial) {
        dispatch(({ labourCostData }) => (labourCostData.mode = LabourCostViewMode.addInit))
        inputs.labourCost.increaseSocialTax.set(false)
    }
}

export const create = async () =>
    run(SAVE_PROCESS, async () => {
        const labourCost = getInput()
        await api.createLabourCost(labourCost)
        invalidateCache()
        await loadLabourCosts()

        const year = Day.fromYm(labourCost.month).year()
        setRoute('#/labour-costs/list/' + year)
    })

export const confirm = async (id: string, rev: number) =>
    run(CONFIRM_PROCESS, async () => {
        await api.confirmLabourCost(id, rev)
        invalidateCache()
        await loadLabourCosts()
    })

export const remove = async (id: string, returnRoute: string) =>
    run(REMOVE_PROCESS, async () => {
        await api.removeLabourCost(id)
        invalidateCache()
        await loadLabourCosts()
        setRoute(returnRoute)
    })

export const addLabourPayment = async (id: string, type: LabourPaymentType) =>
    run(SAVE_PAYMENT_PROCESS, async () => {
        const { labourCostData, inputValues } = getState()
        const labourCost = getById(labourCostData, id)!
        const totals = calculateLabourCost(labourCost)

        let total: number
        let payments: DbPayment[]

        if (type === 'net') {
            total = totals.net
            payments = labourCost.netPayments
        } else if (type === 'tax') {
            total = totals.taxes
            payments = labourCost.taxPayments
        } else {
            throw assertNever(type, 'labour payment type')
        }

        const remaining = total - getPaidAmount(payments)
        const rev = inputs.payment.rev.get(inputValues)
        const payment = getPaymentInput(remaining)

        await api.addLabourPayment(id, rev, type, payment)
        invalidateCache()
        await loadLabourCosts()
        closeExpensePaymentForm()
    })

export const removeLabourPayment = async (
    labourCostId: string,
    type: LabourPaymentType,
    paymentId: string,
) =>
    run(REMOVE_PAYMENT_PROCESS, async () => {
        const { inputValues } = getState()
        const rev = inputs.payment.rev.get(inputValues)
        await api.removeLabourPayment(labourCostId, rev, type, paymentId)

        invalidateCache()
        await loadLabourCosts()

        const labourCost = findByDbId(getState().labourCostData.labourCosts!, labourCostId)!
        inputs.payment.rev.set(labourCost.rev)
    })
