import React, { FC, Fragment, ReactNode } from 'react'

import { MAX_BANK_ACCOUNTS } from '../../../common/constants'
import { range } from '../../../common/range'
import { ValidationError } from '../../../common/types/errors'
import { InputValues } from '../../../common/types/inputs'
import { Processes } from '../../../common/types/processes'
import { assertViewName } from '../../assert-view-name'
import { t } from '../../i18n'
import { renderInputOrValue } from '../../input-utils'
import { inputs } from '../../inputs'
import { addBankAccount } from '../../state/company-actions'
import { RootData } from '../../state/root-data'
import {
    removeBankAccount,
    SAVE_REVENUE_SETTINGS_PROCESS,
    saveRevenueSettings,
} from '../../state/settings-actions'
import { valErr } from '../../val-err'
import { BackLink } from '../back-link'
import { BanksList } from '../banks-list'
import { Button } from '../button'
import { DeleteIcon } from '../delete-icon'
import { LoadingIcon } from '../loading-icon'
import { SettingsAddress } from './address'
import { InputRow } from './input-row'

const renderBackLink = (editMode: boolean) => (editMode ? React.createElement(BackLink) : null)

const renderLogo = (logoUrl: string | undefined) => {
    if (!logoUrl) {
        return React.createElement('span', null, t.settings.logo.notSet.get())
    }

    return React.createElement('img', { src: logoUrl, className: 'logo settings__logo' })
}

const renderPaymentTermSection = (
    editMode: boolean,
    inputValues: InputValues,
    valErrors: ValidationError[] | undefined,
) => {
    const input = inputs.settings.invoice.paymentTerm
    const dayTranslation = input.get(inputValues) === '1' ? t.day.get() : t.days.get()

    return React.createElement(
        Fragment,
        null,
        React.createElement('h1', { className: 'title' }, t.term.get()),
        React.createElement(
            'div',
            { className: 'settings__input-row settings__input-row--invoice-term' },
            React.createElement(
                'span',
                { className: 'right-margin' },
                t.settings.defaultPaymentTerm.get(),
                ':',
            ),
            renderInputOrValue(editMode, {
                input,
                inputValues,
                className: 'settings__invoice-term-input',
            }),
            React.createElement('span', { className: 'left-margin' }, dayTranslation),
        ),
        valErr(valErrors, 'settings.paymentTerm'),
    )
}

const renderRemoveBankAccountButton = (editMode: boolean, index: number, accountsCount: number) => {
    if (!editMode || accountsCount <= 1) {
        return null
    }

    return React.createElement(DeleteIcon, {
        onClick: () => removeBankAccount(index),
        domId: 'remove-bank-account-' + index,
    })
}

const renderBankAccounts = (
    editMode: boolean,
    accountsCount: number,
    inputValues: InputValues,
    valErrors: ValidationError[] | undefined,
) =>
    range(0, accountsCount - 1).map((index): ReactNode => {
        const accountInput = inputs.settings.invoice.bankAccount(index)
        const { name, number } = accountInput

        return React.createElement(
            'div',
            { className: 'bottom-margin' },
            renderInputOrValue(editMode, {
                input: name,
                inputValues,
                placeholder: t.settings.revenue.bankName.get(),
                list: 'bank-names',
            }),
            ' ',
            renderInputOrValue(editMode, {
                input: number,
                inputValues,
                placeholder: t.settings.revenue.accountNumber.get(),
            }),
            ' ',
            renderRemoveBankAccountButton(editMode, index, accountsCount),
            valErr(valErrors, 'company.update.bankAccounts.' + index + '.name', {
                required: t.settings.revenue.enterBankName.get(),
            }),
            valErr(valErrors, 'company.update.bankAccounts.' + index + '.number', {
                required: t.settings.revenue.enterAccountNumber.get(),
            }),
        )
    })

const renderAddBankAccountButton = (editMode: boolean, accountsCount: number) => {
    if (!editMode || accountsCount >= MAX_BANK_ACCOUNTS) {
        return null
    }

    return React.createElement(
        'div',
        { className: 'init-company__input-row' },
        React.createElement(Button, {
            onClick: addBankAccount,
            text: t.settings.revenue.addBankAccount.get(),
            className: 'button--secondary',
            domId: 'add-bank-account',
        }),
    )
}

const renderBankAccountsSection = (
    editMode: boolean,
    accountsCount: number,
    inputValues: InputValues,
    valErrors: ValidationError[] | undefined,
) =>
    React.createElement(
        Fragment,
        null,
        React.createElement('h1', { className: 'title' }, t.settings.bankAccounts.get()),
        React.createElement(BanksList),
        ...renderBankAccounts(editMode, accountsCount, inputValues, valErrors),
        renderAddBankAccountButton(editMode, accountsCount),
        valErr(valErrors, 'company.update.bankAccounts'),
    )

const renderContactSection = (
    editMode: boolean,
    inputValues: InputValues,
    valErrors: ValidationError[] | undefined,
) => {
    const { address, email, website, phone } = inputs.settings.invoice

    return React.createElement(
        Fragment,
        null,
        React.createElement('h1', { className: 'title' }, t.settings.contact.get()),
        React.createElement(SettingsAddress, {
            editMode,
            addressInputs: address,
            inputValues,
            valErrors,
        }),
        React.createElement(InputRow, {
            editMode,
            label: t.settings.email.get(),
            input: email,
            inputValues,
        }),
        valErr(valErrors, 'company.update.email'),
        React.createElement(InputRow, {
            editMode,
            label: t.settings.website.get(),
            input: website,
            inputValues,
        }),
        valErr(valErrors, 'company.update.website'),
        React.createElement(InputRow, {
            editMode,
            label: t.settings.phone.get(),
            input: phone,
            inputValues,
        }),
        valErr(valErrors, 'company.update.phone'),
    )
}

const renderEditSaveButton = (editMode: boolean, processes: Processes) => {
    if (editMode) {
        return React.createElement(Button, {
            className: 'button--primary',
            text: t.save.get(),
            onClick: saveRevenueSettings,
            processes,
            processName: SAVE_REVENUE_SETTINGS_PROCESS,
            domId: 'save-invoice-settings',
        })
    } else {
        return React.createElement(
            'a',
            {
                id: 'edit-invoice-settings',
                href: '#/settings/invoice/edit',
                className: 'button button--primary',
            },
            t.edit.get(),
        )
    }
}

export const RevenueSettings: FC<RootData> = (rootData) => {
    const {
        settingsData: { settings },
        companyData,
        view,
        inputValues,
        processes,
        validationErrors,
    } = rootData

    if (!companyData.companies || !settings) {
        return React.createElement(LoadingIcon, { color: 'black' })
    }

    const { pageParams } = assertViewName(view, 'Settings')
    const editMode = pageParams[0] === 'edit'
    const valErrors = validationErrors[SAVE_REVENUE_SETTINGS_PROCESS]

    return React.createElement(
        Fragment,
        null,
        React.createElement(
            'div',
            null,
            renderBackLink(editMode),
            React.createElement(
                'div',
                { className: 'flex space-between' },
                React.createElement(
                    'div',
                    null,
                    renderPaymentTermSection(editMode, inputValues, valErrors),
                    renderBankAccountsSection(
                        editMode,
                        companyData.bankAccountCount,
                        inputValues,
                        valErrors,
                    ),
                    renderContactSection(editMode, inputValues, valErrors),
                ),
                React.createElement(
                    'div',
                    null,
                    React.createElement(
                        'h1',
                        { className: 'title' },
                        t.settings.logo.current.get(),
                    ),
                    renderLogo(settings.logoUrl),
                ),
            ),
        ),
        React.createElement('div', null, renderEditSaveButton(editMode, processes)),
    )
}
