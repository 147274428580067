import React, { FC } from 'react'

import { t } from '../i18n'

export interface DeleteIconProps {
    onClick: () => void
    white?: true
    domId?: string
}

export const DeleteIcon: FC<DeleteIconProps> = ({ onClick, white, domId }) =>
    React.createElement(
        'a',
        { onClick, title: t.remove.get(), id: domId },
        React.createElement('img', {
            src: white ? '/icons/delete-white.svg' : '/icons/delete.svg',
            className: 'delete-icon',
        }),
    )
