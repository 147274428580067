import React, { FC, Fragment, ImgHTMLAttributes } from 'react'

import { Button, ButtonProps } from '../button'
import { LoadingIcon } from '../loading-icon'

export interface RevenuePreviewProps {
    isReady: boolean
    buttons: Omit<ButtonProps, 'className'>[]
    languageButtons: ImgHTMLAttributes<HTMLImageElement>[]
    url: string
}

const renderMain = (isReady: boolean, url: string) => {
    if (isReady) {
        return React.createElement('object', {
            className: 'revenue-preview__pdf',
            data: url,
            type: 'application/pdf',
        })
    } else {
        return React.createElement(LoadingIcon, { color: 'white' })
    }
}

export const RevenuePreview: FC<RevenuePreviewProps> = (props) => {
    const { isReady, buttons, languageButtons, url } = props

    return React.createElement(
        'div',
        { className: 'revenue-preview' },
        React.createElement(
            'div',
            { className: 'revenue-preview__buttons' },
            ...buttons.map((button) =>
                React.createElement(
                    Fragment,
                    null,
                    React.createElement(Button, {
                        ...button,
                        className: 'revenue-preview__button button--transparent',
                    }),
                    ' ',
                ),
            ),
            ...languageButtons.map((button) =>
                React.createElement(
                    Fragment,
                    null,
                    React.createElement('img', {
                        ...button,
                        className: 'revenue-preview__flag',
                    }),
                    ' ',
                ),
            ),
        ),
        renderMain(isReady, url),
    )
}
