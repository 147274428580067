import { MAX_UPLOAD_MB, MB } from '../../common/constants'
import { CompanyRole } from '../../common/enums'
import { range } from '../../common/range'
import { ServerError } from '../../common/server-error'
import { GeneralUpdate } from '../../common/types/company'
import { WrappedWarning } from '../../common/types/errors'
import { ApiSession } from '../../common/types/session'
import { ApiSettings, SettingsData, VatUpdate } from '../../common/types/settings'
import { ApiUserFull } from '../../common/types/user'
import * as api from '../api'
import { fromErrorCode } from '../error-manager'
import { emitAfterStoreReset, emitUpdatedSession, onNextNavigation } from '../event-bus'
import { fileToBase64, fileToDataUrl } from '../file-utils'
import { getNumeric } from '../get-numeric'
import { t } from '../i18n'
import { inputs } from '../inputs'
import { setRoute } from '../route-utils'
import { getRevenueVatInput } from '../vat-utils'
import { loadCompany } from './company-actions'
import { prepareForm } from './form-actions'
import { setInvalid } from './invalid-cache-actions'
import { loadCardPayments, loadSettings, loadUsersFull, loadUsersLimited } from './load-actions'
import { run } from './process-actions'
import { dispatch, getState, reset } from './store'
import { clearErrors, setValidationErrors } from './validation-actions'

export const SAVE_GENERAL_PROCESS = 'settings/general/save'
export const SAVE_VAT_PROCESS = 'settings/vat/save'
export const REMOVE_LOGO_PROCESS = 'settings/logo/remove'
export const SAVE_LOGO_PROCESS = 'settings/logo/save'
export const VALIDATE_LOGO_PROCESS = 'settings/logo/validate'
export const SAVE_REVENUE_SETTINGS_PROCESS = 'settings/invoice/save'
export const SAVE_USER_ROLE_PROCESS = 'settings/update-user-role'
export const REMOVE_USER_PROCESS = 'settings/remove-user'
export const SEND_INVITE_PROCESS = 'settings/send-invite'

const invalidateCache = () => setInvalid('settings')

const invalidateCompanyCache = () => setInvalid('company')

export const getSettings = (data: SettingsData): ApiSettings => {
    if (!data.settings) {
        throw new Error('Settings not loaded')
    }

    return data.settings
}

const initGeneralForm = async () => {
    clearErrors(SAVE_GENERAL_PROCESS)
    const company = await loadCompany()
    const {
        rev,
        name,
        regCode,
        address: { street, city, postcode },
        email,
        website,
    } = company
    const { settings } = inputs
    const { general } = settings

    settings.companyRev.set(rev)
    general.name.set(name)
    general.regCode.set(regCode)
    general.address.street.set(street)
    general.address.city.set(city)
    general.address.postcode.set(postcode)
    general.email.set(email)
    general.website.set(website)
}

const initVatForm = async () => {
    clearErrors(SAVE_VAT_PROCESS)

    const settingsInputs = inputs.settings
    const vatInputs = settingsInputs.vat

    const [company] = await Promise.all([loadCompany(), loadSettings()])
    settingsInputs.companyRev.set(company.rev)
    vatInputs.vatId.set(company.vatId)

    const settings = getSettings(getState().settingsData)
    const { rev, vatPercentage } = settings
    settingsInputs.rev.set(rev)

    const strVatPercentage = vatPercentage !== null ? vatPercentage.toString() : 'null'
    vatInputs.vatPercentage.set(strVatPercentage)
}

export const unsetLogoInput = () => {
    inputs.settings.logo.dataUrl.set('')
    inputs.settings.logo.base64.set('')
}

const initLogoForm = async () =>
    prepareForm('settings', async () => {
        await loadSettings()
        const settings = getSettings(getState().settingsData)
        inputs.settings.rev.set(settings.rev)
        unsetLogoInput()

        // Discard unused image data from memory if user navigates away
        onNextNavigation(() => unsetLogoInput())
    })

const initBillingForm = async (pageParams: string[]) => {
    if (pageParams[0] === 'card-update') {
        await loadCardPayments()
    } else {
        const company = await loadCompany()
        const { billingName, billingEmail } = company
        const contactInputs = inputs.settings.billing.contact
        contactInputs.name.set(billingName)
        contactInputs.email.set(billingEmail)
    }
}

const initInvoiceForm = async () => {
    clearErrors(SAVE_REVENUE_SETTINGS_PROCESS)

    const settingsInputs = inputs.settings
    const invoiceInputs = settingsInputs.invoice
    const [company] = await Promise.all([loadCompany(), loadSettings()])
    const settings = getSettings(getState().settingsData)

    settingsInputs.companyRev.set(company.rev)
    settingsInputs.rev.set(settings.rev)

    invoiceInputs.paymentTerm.set(settings.paymentTerm.toString())

    dispatch(({ companyData }) => (companyData.bankAccountCount = company.bankAccounts.length))

    company.bankAccounts.forEach((account, idx) => {
        const { name, number } = account
        const accountInput = invoiceInputs.bankAccount(idx)
        accountInput.name.set(name)
        accountInput.number.set(number)
    })

    const {
        address: { street, city, postcode },
        email,
        website,
        phone,
    } = company
    invoiceInputs.address.street.set(street)
    invoiceInputs.address.city.set(city)
    invoiceInputs.address.postcode.set(postcode)
    invoiceInputs.email.set(email)
    invoiceInputs.website.set(website)
    invoiceInputs.phone.set(phone || '')
}

const resetUserInputs = () => {
    inputs.settings.users.newUserEmail.set('')
    inputs.settings.users.newUserRole.set(CompanyRole.base)
}

const initUsersForm = async () => {
    resetUserInputs()
    await loadUsersFull()
}

const initCloseAccountForm = async () => loadUsersLimited()

export const initForm = (page: string, pageParams: string[]) => {
    if (page === 'general') {
        return initGeneralForm()
    } else if (page === 'vat') {
        return initVatForm()
    } else if (page === 'logo') {
        return initLogoForm()
    } else if (page === 'billing') {
        return initBillingForm(pageParams)
    } else if (page === 'invoice') {
        return initInvoiceForm()
    } else if (page === 'users') {
        return initUsersForm()
    } else if (page === 'close-account') {
        return initCloseAccountForm()
    } else if (page) {
        throw new Error('Invalid settings page: ' + page)
    }

    return null
}

const getCompanyRevInputValue = () => {
    const { inputValues } = getState()
    return inputs.settings.companyRev.get(inputValues)
}

const getGeneralUpdate = (): GeneralUpdate => {
    const { inputValues } = getState()

    const {
        name,
        regCode,
        address: { street, city, postcode },
        email,
        website,
    } = inputs.settings.general

    return {
        name: name.get(inputValues).trim(),
        regCode: regCode.get(inputValues).trim(),
        address: {
            street: street.get(inputValues).trim(),
            city: city.get(inputValues).trim(),
            postcode: postcode.get(inputValues).trim(),
        },
        email: email.get(inputValues).trim(),
        website: website.get(inputValues).trim(),
    }
}

export const saveGeneral = async () =>
    run(SAVE_GENERAL_PROCESS, async () => {
        const rev = getCompanyRevInputValue()
        const update = getGeneralUpdate()
        await api.updateGeneralSettings(rev, update)
        invalidateCompanyCache()
        setRoute('#/settings/general')
    })

const getVatUpdate = (): VatUpdate => {
    const { inputValues } = getState()
    const { vatId, vatPercentage } = inputs.settings.vat

    return {
        vatId: vatId.get(inputValues).trim(),
        vatPercentage: getRevenueVatInput(vatPercentage, inputValues),
    }
}

export const saveVat = async () =>
    run(SAVE_VAT_PROCESS, async () => {
        const { inputValues } = getState()
        const settingsInputs = inputs.settings
        const companyRev = settingsInputs.companyRev.get(inputValues)
        const settingsRev = settingsInputs.rev.get(inputValues)
        const update = getVatUpdate()
        await api.updateVatSettings(companyRev, settingsRev, update)
        invalidateCache()
        invalidateCompanyCache()
        setRoute('#/settings/vat')
    })

const createLogoFileValidationError = (type: 'file-too-big' | 'file-image') => ({
    key: 'settings.logo',
    type,
})

const validateLogoInputFile = (file: File) => {
    let success = true
    const validationErrors = []

    if (file.size > MAX_UPLOAD_MB * MB) {
        success = false
        const error = createLogoFileValidationError('file-too-big')
        validationErrors.push(error)
    }

    if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        success = false
        const error = createLogoFileValidationError('file-image')
        validationErrors.push(error)
    }

    if (!success) {
        setValidationErrors(VALIDATE_LOGO_PROCESS, validationErrors)
    }

    return success
}

export const loadLogoInputFile = async (file: File) => {
    if (validateLogoInputFile(file)) {
        const [dataUrl, base64] = await Promise.all([fileToDataUrl(file), fileToBase64(file)])
        inputs.settings.logo.dataUrl.set(dataUrl)
        inputs.settings.logo.base64.set(base64)
    }
}

export const saveLogo = async () =>
    run(SAVE_LOGO_PROCESS, async () => {
        const { inputValues } = getState()
        const rev = inputs.settings.rev.get(inputValues)
        const fileContentBase64 = inputs.settings.logo.base64.get(inputValues)
        await api.uploadCompanyLogo(rev, fileContentBase64)
        invalidateCache()
        setRoute('#/settings/logo')
    })

export const removeLogo = async () =>
    run(REMOVE_LOGO_PROCESS, async () => {
        const rev = inputs.settings.rev.get(getState().inputValues)
        await api.removeCompanyLogo(rev)
        invalidateCache()
        await initLogoForm()
    })

export const removeBankAccount = (index: number) => {
    clearErrors(SAVE_REVENUE_SETTINGS_PROCESS)

    const {
        inputValues,
        companyData: { bankAccountCount },
    } = getState()
    const { bankAccount } = inputs.settings.invoice

    for (let i = index; i < bankAccountCount - 1; i += 1) {
        const currInput = bankAccount(i)
        const nextInput = bankAccount(i + 1)
        currInput.name.set(nextInput.name.get(inputValues))
        currInput.number.set(nextInput.number.get(inputValues))
        nextInput.name.set('')
        nextInput.number.set('')
    }

    if (bankAccountCount > 0) {
        dispatch(({ companyData }) => (companyData.bankAccountCount -= 1))
    }
}

const getInvoiceSettingsUpdate = () => {
    const {
        rev,
        companyRev,
        invoice: {
            address: { street, city, postcode },
            paymentTerm,
            email,
            website,
            phone,
            bankAccount,
        },
    } = inputs.settings

    const {
        inputValues,
        companyData: { bankAccountCount },
    } = getState()

    const bankAccounts = range(0, bankAccountCount - 1).map((index) => ({
        name: bankAccount(index).name.get(inputValues),
        number: bankAccount(index).number.get(inputValues),
    }))

    return {
        settings: {
            rev: rev.get(inputValues),
            paymentTerm: getNumeric(paymentTerm, inputValues),
        },
        company: {
            rev: companyRev.get(inputValues),
            update: {
                address: {
                    street: street.get(inputValues),
                    city: city.get(inputValues),
                    postcode: postcode.get(inputValues),
                },
                email: email.get(inputValues),
                website: website.get(inputValues),
                phone: phone.get(inputValues),
                bankAccounts,
            },
        },
    }
}

export const saveRevenueSettings = async () =>
    run(SAVE_REVENUE_SETTINGS_PROCESS, async () => {
        const update = getInvoiceSettingsUpdate()
        await api.updateInvoiceSettings(update)
        invalidateCache()
        invalidateCompanyCache()
        setRoute('#/settings/invoice')
    })

const getDuplicateInviteMessage = (reason: string) => {
    if (reason === 'already-linked') {
        return t.settings.users.duplicate.alreadyLinked.get()
    } else {
        return t.settings.users.duplicate.alreadySent.get()
    }
}

export const editUserRole = (user: ApiUserFull) => {
    const { id, rev, role } = inputs.settings.users.editedUser
    id.set(user._id)
    rev.set(user.rev)
    role.set(user.role)
}

export const cancelUserRoleUpdate = async () => {
    inputs.settings.users.editedUser.id.set('')
}

const updateSession = (newSession: ApiSession) => {
    reset(false)
    emitUpdatedSession(newSession)
    emitAfterStoreReset()
}

export const saveUserRole = async () =>
    run(SAVE_USER_ROLE_PROCESS, async () => {
        const { inputValues } = getState()
        const input = inputs.settings.users.editedUser
        const userId = input.id.get(inputValues)
        input.id.set('')
        const rev = input.rev.get(inputValues)
        const role = input.role.get(inputValues)
        const newSession = await api.updateUserRole(userId, rev, role)

        if (newSession) {
            updateSession(newSession)
            setRoute('#/dashboard')
        } else {
            setInvalid('user')
            await loadUsersFull()
        }
    })

export const removeUser = async (id: string) =>
    run(REMOVE_USER_PROCESS, async () => {
        const newSession = await api.removeUser(id)

        if (newSession) {
            updateSession(newSession)
            setRoute('#/select-company')
        } else {
            setInvalid('user')
            await loadUsersFull()
        }
    })

export const sendInvite = async () =>
    run(SEND_INVITE_PROCESS, async () => {
        const { inputValues } = getState()
        const email = inputs.settings.users.newUserEmail.get(inputValues)
        const role = inputs.settings.users.newUserRole.get(inputValues)

        try {
            await api.inviteUser(email, role)
        } catch (error) {
            if (error instanceof ServerError && error.response.errorCode === 'duplicate') {
                const message = getDuplicateInviteMessage(error.response.reason)
                throw new WrappedWarning(
                    fromErrorCode('duplicate').withMessage(message).dontReport(),
                )
            } else {
                throw error
            }
        }

        resetUserInputs()

        // TODO custom notification system
        alert(t.settings.users.inviteSent.get())
    })
