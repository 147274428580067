import React, { FC } from 'react'

import {
    canAddPayments,
    canConfirm,
    canRemoveAllExpenses,
    canRemoveAllLabourCosts,
} from '../../../common/access'
import { MAX_ROWS } from '../../../common/constants'
import { CompanyStatus, expenseTypes, labourPaymentTypes, logActions } from '../../../common/enums'
import { calculateExpenseInitial } from '../../../common/expense-utils'
import { invariant } from '../../../common/invariant'
import { calculateAssetInitial } from '../../../common/item-utils'
import { keys } from '../../../common/keys'
import { calculateLabourCost } from '../../../common/labour-cost-utils'
import { sort, SortOption } from '../../../common/sort'
import { Day } from '../../../common/time'
import { ApiExpense, ExpenseData } from '../../../common/types/expense'
import { ApiLabourCost } from '../../../common/types/labour-cost'
import { ApiSession } from '../../../common/types/session'
import { Column } from '../../../common/types/table'
import { VatPaymentState } from '../../../common/types/vat'
import { upperCaseFirst } from '../../../common/upper-case-first'
import { assertViewName } from '../../assert-view-name'
import { getExcelButtonProps } from '../../excel-utils'
import { ExcelSpec } from '../../excel/types'
import { getExpenseTypeLabel } from '../../expense-utils'
import { t } from '../../i18n'
import { createMonthUrlInput } from '../../input-utils'
import { inputs } from '../../inputs'
import { getPercentUnpaid } from '../../payment-utils'
import { getExpensePaymentProps } from '../../props/expense-payment-props'
import { getLabourCostPaymentProps } from '../../props/labour-cost-payment-props'
import { renderAmount, renderAmountOrDash } from '../../render-amount'
import { renderExcelMoney } from '../../render-excel-money'
import { getShowAllButtonProps, wrapExcelButton } from '../../standard-page-utils'
import { getCompany } from '../../state/company-actions'
import {
    confirmExpense,
    REMOVE_PROCESS,
    remove as removeExpense,
} from '../../state/expense-actions'
import {
    confirm as confirmLabourCost,
    REMOVE_PROCESS as labourCostRemoveProcess,
    remove as removeLabourCost,
} from '../../state/labour-cost-actions'
import { openExpensePaymentForm } from '../../state/payment-actions'
import { RootData } from '../../state/root-data'
import { browserOnly } from '../../table-utils'
import { Button } from '../button'
import { DeleteIcon } from '../delete-icon'
import { ExcelButtonProps } from '../excel-button'
import { Link } from '../link'
import { LoadingPage } from '../loading-page'
import { MonthNav } from '../month-nav'
import { NoData } from '../no-data'
import { Payment, PaymentProps } from '../payment'
import { renderSortOptions } from '../sort-options'
import { StandardPage } from '../standard-page'
import { renderTable } from '../table'
import { ViewIcon } from '../view-icon'

interface Row {
    className?: string
    date: Day
    dateStr: string
    vendor: string
    type: string
    withVat: number
    description: string
    dueDate: Day
    dueDateStr: string
    confirmed: boolean
    paid: boolean
    percentUnpaid: number
    createTime: string
    canAddPayments: boolean
    viewRoute: string
    confirm?: () => Promise<void>
    remove?: () => Promise<void>
    openPaymentForm: () => Promise<void>
    vatMonth: string
}

interface Totals {
    withoutVat: number
}

export type SortId = 'date' | 'type' | 'dueDate' | 'vendor'

const getRightAligned = () => ({ className: 'text-right' })
const getAmountClass = () => ({ className: 'amount' })

const renderPaymentLink = (row: Row) =>
    React.createElement(Link, {
        text: row.paid ? t.expenses.paid.get() : t.expenses.percentUnpaid.get(row.percentUnpaid),
        onClick: async () => row.openPaymentForm(),
        className: 'payment-link',
    })

const renderConfirmLink = (row: Row) =>
    React.createElement(Link, {
        text: t.confirm.get(),
        onClick: () => (confirm(t.confirm.confirmExpense.get()) ? row.confirm!() : null),
        className: 'confirm-link',
    })

const renderVatNote = (vatMonth: string, paymentState: VatPaymentState) => {
    if (!vatMonth || !(vatMonth in paymentState.paidMonths)) {
        return null
    }

    return React.createElement(Link, {
        to: '#/taxes/vat/' + vatMonth,
        text: t.vatDeclared.get(),
        tooltip: upperCaseFirst(Day.fromYm(vatMonth).longMonth()),
        className: 'vat-declaration-link',
    })
}

const renderRemoveLink = (row: Row) => {
    const removeFn = row.remove

    if (removeFn) {
        const onClick = async () => {
            const message = row.confirmed
                ? t.confirm.removeConfirmedInvoice.get()
                : t.confirm.removeExpense.get()

            // TODO custom confirmation UI
            if (confirm(message)) {
                await removeFn()
            }
        }

        return React.createElement(DeleteIcon, { onClick })
    } else {
        return null
    }
}

const getColumns = (vatPaymentState: VatPaymentState): Column<Row, Totals>[] => [
    {
        header: { content: t.date.get() },
        render: (row) => row.dateStr,
        excelWidth: 12,
    },
    {
        header: { content: t.expenses.vendor.get() },
        render: (row) => row.vendor,
        excelWidth: 30,
    },
    {
        header: { content: t.expenses.type.get() },
        render: (row) => row.type,
        excelWidth: 30,
    },
    {
        header: { content: t.sum.get(), getProps: getRightAligned },
        getProps: getAmountClass,
        render: (row) => ({
            browser: renderAmountOrDash(row.withVat),
            excel: renderExcelMoney(row.withVat, false),
        }),
        getTotal: (totals) => renderAmount(totals.withoutVat),
        getExcelTotal: (totals) => ({ type: 'sum-money', value: totals.withoutVat }),
        getTotalProps: getAmountClass,
        excelWidth: 15,
    },
    {
        header: { content: t.expense.get() },
        render: (row) => row.description,
        excelWidth: 30,
    },
    {
        header: { content: t.dueDate.get() },
        render: (row) => row.dueDateStr,
        excelWidth: 12,
    },
    {
        header: { content: t.confirmed.get() },
        render: (row) => (row.confirmed ? t.confirmed.get() : t.unconfirmed.get()),
    },
    {
        header: {
            content: t.actions.get(),
            getProps: () => ({ className: 'text-center' }),
        },
        getProps: () => ({ className: 'actions' }),
        render: browserOnly((row) => {
            const buttons = []
            buttons.push(React.createElement(ViewIcon, { href: row.viewRoute }))

            if (row.canAddPayments) {
                buttons.push(renderPaymentLink(row))
            }

            if (row.confirm) {
                buttons.push(renderConfirmLink(row))
            }

            return React.createElement('span', null, ...buttons)
        }),
        hideInExcel: true,
    },
    {
        getProps: () => ({ className: 'text-right' }),
        render: browserOnly((row) =>
            React.createElement(
                'span',
                null,
                renderVatNote(row.vatMonth, vatPaymentState),
                renderRemoveLink(row),
            ),
        ),
        hideInExcel: true,
    },
]

const canRemoveExpense = (
    expense: ApiExpense,
    session: ApiSession,
    companyStatus: CompanyStatus,
) => {
    const canRemoveAll = canRemoveAllExpenses(session.companyRole, companyStatus)

    if (expense.confirmed) {
        if (expense.vatMonth) {
            return false
        }

        return canRemoveAll
    }

    if (canRemoveAll) {
        return true
    }

    const [firstEntry] = expense.log
    invariant(firstEntry.action === logActions.create)
    return firstEntry.userId === session.userId
}

const canRemoveLabourCost = (
    labourCost: ApiLabourCost,
    session: ApiSession,
    companyStatus: CompanyStatus,
) => {
    if (labourCost.confirmed) {
        return false
    }

    if (canRemoveAllLabourCosts(session.companyRole, companyStatus)) {
        return true
    }

    const [firstEntry] = labourCost.log
    invariant(firstEntry.action === logActions.create)
    return firstEntry.userId === session.userId
}

const getRows = (
    expenses: ApiExpense[],
    labourCosts: ApiLabourCost[],
    session: ApiSession,
    companyStatus: CompanyStatus,
    sortOption: SortOption<Row>,
    { justCreatedId, paymentState }: ExpenseData,
    month: string,
) => {
    const rows: Row[] = []

    for (const expense of expenses) {
        const { _id, rev, confirmed, paid } = expense
        const date = Day.fromYmd(expense.date)
        const dueDate = Day.fromYmd(expense.dueDate)

        const isActivePayment =
            paymentState && paymentState.type === 'expense' && paymentState.id === _id

        const shaded = isActivePayment || _id === justCreatedId
        let confirm, remove

        if (!confirmed && canConfirm(session.companyRole, companyStatus)) {
            confirm = async () => confirmExpense(_id, rev)
        }

        if (canRemoveExpense(expense, session, companyStatus)) {
            remove = async () => removeExpense(_id)
        }

        const common = {
            className: shaded ? 'shaded' : undefined,
            date,
            dateStr: date.dmy(),
            vendor: expense.vendor.name,
            type: getExpenseTypeLabel(expense),
            dueDate,
            dueDateStr: dueDate.dmy(),
            confirmed,
            paid,
            createTime: expense.log[0].time,
            canAddPayments: confirmed && canAddPayments(companyStatus),
            viewRoute: '#/expenses/view/' + _id,
            confirm,
            remove,
            openPaymentForm: async () => openExpensePaymentForm(_id, 'expense'),
            vatMonth: expense.vatMonth,
        }

        if (expense.type === expenseTypes.regular) {
            const items = expense.items!
            let description = items[0].description

            if (items.length > 1) {
                description += ' (+' + (items.length - 1) + ')'
            }

            const totals = calculateExpenseInitial(expense)
            const withVat = totals.payableWithVat
            const percentUnpaid = getPercentUnpaid(expense.paid, expense.payments, withVat)
            rows.push({ ...common, description, withVat, percentUnpaid })
        } else if (expense.type === expenseTypes.asset) {
            let total = 0

            const withInitial = expense.assets!.map((asset) => {
                const initial = calculateAssetInitial(asset)
                total += initial.withVat
                return { asset, initial }
            })

            const percentUnpaid = getPercentUnpaid(expense.paid, expense.payments, total)

            for (const { asset, initial } of withInitial) {
                const { description } = asset
                const { withVat } = initial
                rows.push({ ...common, description, withVat, percentUnpaid })
            }
        } else {
            throw new Error('Unexpected type: ' + expense.type)
        }
    }

    const netType = t.labourCosts.net.plural.get()
    const taxType = t.labourCosts.tax.get()
    const labourDueDate = Day.fromYmd('3000-01-01') // After other expenses when sorted by due date

    for (const labourCost of labourCosts) {
        const { _id, rev, confirmed } = labourCost
        const date = Day.fromYm(labourCost.month).lastOfMonth()

        let confirm, remove

        if (!confirmed && canConfirm(session.companyRole, companyStatus)) {
            confirm = async () => confirmLabourCost(_id, rev)
        }

        if (canRemoveLabourCost(labourCost, session, companyStatus)) {
            remove = async () => removeLabourCost(_id, '#/expenses/register/' + month)
        }

        const common = {
            date,
            dateStr: upperCaseFirst(date.shortMonth()),
            vendor: t.labourCosts.get(),
            dueDate: labourDueDate,
            dueDateStr: '-',
            confirmed,
            createTime: labourCost.log[0].time,
            canAddPayments: confirmed && canAddPayments(companyStatus),
            viewRoute: '#/labour-costs/view/' + _id,
            confirm,
            remove,
            vatMonth: '', // Not applicable to labour costs
        }

        let netShaded = false,
            taxShaded = false

        if (paymentState && paymentState.id === _id) {
            netShaded = paymentState.type === labourPaymentTypes.net
            taxShaded = paymentState.type === labourPaymentTypes.tax
        }

        const calc = calculateLabourCost(labourCost)

        rows.push({
            ...common,
            className: netShaded ? 'shaded' : undefined,
            type: netType,
            withVat: calc.net,
            description: netType,
            paid: labourCost.netPaid,
            percentUnpaid: getPercentUnpaid(labourCost.netPaid, labourCost.netPayments, calc.net),
            openPaymentForm: async () => openExpensePaymentForm(_id, labourPaymentTypes.net),
        })

        rows.push({
            ...common,
            className: taxShaded ? 'shaded' : undefined,
            type: taxType,
            withVat: calc.taxes,
            description: taxType,
            paid: labourCost.taxPaid,
            percentUnpaid: getPercentUnpaid(labourCost.taxPaid, labourCost.taxPayments, calc.taxes),
            openPaymentForm: async () => openExpensePaymentForm(_id, labourPaymentTypes.tax),
        })
    }

    return sort(rows, sortOption)
}

export const ExpenseRegister: FC<RootData> = (rootData) => {
    const {
        companyData,
        expenseData,
        formsReady,
        inputValues,
        labourCostData: { labourCosts },
        processes,
        progress,
        session,
        taxes: {
            vat: { paymentState: vatPaymentState },
        },
        view,
    } = rootData

    const { expenses, paymentState: expensePaymentState } = expenseData

    if (
        !expenses ||
        !labourCosts ||
        !companyData.companies ||
        !vatPaymentState ||
        !formsReady.has('expense-register') ||
        processes.has(REMOVE_PROCESS) ||
        processes.has(labourCostRemoveProcess)
    ) {
        return React.createElement(LoadingPage)
    }

    if (!expenses.length && !labourCosts.length) {
        return React.createElement(NoData, {
            addRoute: '#/expenses/add/regular',
            addButtonText: t.expenses.add.get(),
        })
    }

    let paymentSidebar: PaymentProps | undefined

    if (expensePaymentState?.type === 'expense') {
        paymentSidebar = getExpensePaymentProps(rootData, expensePaymentState.id)
    } else if (expensePaymentState?.type) {
        paymentSidebar = getLabourCostPaymentProps(
            rootData,
            expensePaymentState.type,
            expensePaymentState.id,
        )
    }

    const company = getCompany(companyData, session)

    const sortOptions: { [S in SortId]: SortOption<Row> } = {
        date: [
            { getKey: (row) => row.date.toTimestamp(), reverse: true },
            { getKey: (row) => row.createTime, reverse: true },
        ],
        type: [{ getKey: (row) => row.type }],
        dueDate: [{ getKey: (row) => row.dueDate.toTimestamp() }],
        vendor: [{ getKey: (row) => row.vendor }],
    }

    // TODO undup
    const tableInputs = inputs.expense.register
    const showAll = tableInputs.showAll.get(inputValues)
    const sortId = tableInputs.sort.get(inputValues)

    const { month } = assertViewName(view, 'ExpenseRegister')
    const shownMonth = month || Day.today().ym()
    const monthInput = createMonthUrlInput('#/expenses/register/', shownMonth)

    const expensesInRange = expenses.filter((expense) => expense.date.startsWith(shownMonth))
    const labourCostsInRange = labourCosts.filter((labourCost) => labourCost.month === shownMonth)

    const allRows = getRows(
        expensesInRange,
        labourCostsInRange,
        session!,
        company.status,
        sortOptions[sortId],
        expenseData,
        shownMonth,
    )

    const hasMore = allRows.length > MAX_ROWS
    const rows: Row[] = showAll ? allRows : allRows.slice(0, MAX_ROWS)
    const columns = getColumns(vatPaymentState)
    const totals: Totals = { withoutVat: allRows.reduce((sum, row) => sum + row.withVat, 0) }

    const parsedMonth = Day.fromYm(shownMonth)
    const title = t.expenses.register.get()
    const subtitle = parsedMonth.longMonth()

    const interimDate = Day.fromYmd(company.interimDate)

    let excelButton: ExcelButtonProps | undefined

    if (rows.length) {
        const excelSpec: ExcelSpec<Row, Totals> = {
            columns,
            rows,
            totals,
            outputName: title + ' - ' + subtitle,
        }

        excelButton = getExcelButtonProps(
            excelSpec,
            t.expenses.processed.get(),
            processes,
            progress,
            'button button--wide button--primary',
        )
    }

    return React.createElement(StandardPage, {
        sidebar: paymentSidebar && React.createElement(Payment, paymentSidebar),
        title,
        subtitle,
        sortOptions: rows.length
            ? renderSortOptions({
                  // TODO move condition to StandardPage?
                  input: tableInputs.sort,
                  inputValues,
                  options: keys(sortOptions).map((key) => ({
                      id: key,
                      label: t.expenses.sortOption[key].get(),
                  })),
              })
            : null,
        buttons: [
            React.createElement(MonthNav, {
                day: parsedMonth,
                input: monthInput,
                minMonth: interimDate,
            }),
            excelButton && wrapExcelButton(excelButton),
        ],
        table: React.createElement(
            'div',
            null,
            renderTable({
                columns,
                rows,
                totals,
                stickyHeader: true,
                tableClassName: 'main-table',
                wrapperClassName: 'main-table-wrapper',
            }),
            // TODO move to StandardPage
            !showAll &&
                hasMore &&
                React.createElement(Button, getShowAllButtonProps(tableInputs.showAll)),
        ),
    })
}
