import classnames from 'classnames'
import React, { FC } from 'react'

import { t } from '../../i18n'
import { Link } from '../link'

type TaxesPage = 'summary' | 'vat'

interface Props {
    selected?: TaxesPage
}

const pages: TaxesPage[] = ['summary', 'vat']

const renderButton = (page: TaxesPage, isSelected: boolean) =>
    React.createElement(Link, {
        to: '#/taxes/' + page,
        text: t.taxes.pages[page].get(),
        className: classnames(
            'button',
            isSelected ? 'button--transparent-selected' : 'button--transparent',
            'archive__section-button',
        ),
    })

export const TaxesSidebar: FC<Props> = ({ selected }) =>
    React.createElement(
        'div',
        { className: 'sidebar' },
        React.createElement(
            'h1',
            { className: 'sidebar__title-with-icon title' },
            React.createElement('img', {
                src: '/menu/taxes.svg',
                className: 'sidebar__title-icon',
            }),
            React.createElement('div', null, t.taxes.get()),
        ),
        ...pages.map((page) => renderButton(page, page === selected)),
    )
