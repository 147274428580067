import { BANK, CASH } from '../../common/accounts'
import { moneyForms } from '../../common/enums'
import { Day } from '../../common/time'
import { ValidationError } from '../../common/types/errors'
import { ChoiceOption, InputValues } from '../../common/types/inputs'
import { Totals } from '../../common/types/item'
import { DbPayment } from '../../common/types/payment'
import { Processes } from '../../common/types/processes'
import { PaymentLabels, PaymentProps } from '../components/payment'
import { t } from '../i18n'
import { inputs } from '../inputs'
import { getPaymentDescription } from '../payment-utils'
import { getValidationErrorProps } from '../val-err'

// TODO refactor
export interface RawPaymentProps {
    labels: PaymentLabels
    incoming: boolean
    minDate: Day
    payments: DbPayment[]
    totals: Totals<number>
    totalToPay: number
    disabledText?: string
    inputValues: InputValues
    onSave: () => void
    onRemove?: (paymentId: string) => void
    onClose: () => void
    hideIsFull?: boolean
    hideMoneyForm?: boolean
    processes?: Processes
    saveProcessName?: string
    removeProcessName?: string
    valErrors: ValidationError[] | undefined
    isCredit?: boolean
}

const getValidationMessage = (error: ValidationError, incoming: boolean) => {
    const { account } = error
    const date = Day.fromYmd(error.date).dmy()

    if (incoming) {
        return t.payments.negativeBalance.onAdd.incoming.get(date, account)
    }

    if (account === CASH) {
        return t.payments.negativeBalance.onAdd.outgoing.cash.get(date)
    } else if (account === BANK) {
        return t.payments.negativeBalance.onAdd.outgoing.bank.get(date)
    }

    return t.payments.negativeBalance.onAdd.outgoing.get(date, account)
}

const getPrevious = (rawProps: RawPaymentProps): PaymentProps['previous'] => {
    const { incoming, payments, onRemove, processes, removeProcessName } = rawProps

    const direction = incoming ? 'incoming' : 'outgoing'

    const props: PaymentProps['previous'] = {
        title: t.payments.earlier[direction].get(),
        payments: payments.map((payment) => ({
            date: Day.fromYmd(payment.date),
            description: getPaymentDescription(payment, incoming),
        })),
    }

    if (processes && removeProcessName && processes.has(removeProcessName)) {
        props.isRemoving = true
    } else if (onRemove) {
        props.removeLastLink = {
            text: t.payments.removeLast[direction].get(),
            onClick: () => {
                if (confirm(t.confirm.removePayment[direction].get())) {
                    const lastPayment = payments[payments.length - 1]
                    onRemove(lastPayment.id)
                }
            },
        }
    }

    return props
}

const getIsFullProps = (
    inputValues: InputValues,
    anyPayments: boolean,
    incoming: boolean,
    labels: PaymentLabels,
): PaymentProps['isFull'] => {
    const options: ChoiceOption<'yes' | 'no'>[] = [
        { id: 'yes', label: t.yes.get() },
        { id: 'no', label: t.no.get() },
    ]

    let text

    if (anyPayments) {
        text = t.payments.isFull.rest[incoming ? 'incoming' : 'outgoing'].get()
    } else {
        text = labels.isFull
    }

    return {
        label: text,
        choice: {
            type: 'buttons',
            input: inputs.payment.full,
            inputValues,
            options,
            forceSelection: true,
        },
    }
}

const getAmountProps = (
    inputValues: InputValues,
    valErrors: ValidationError[] | undefined,
    incoming: boolean,
    labels: PaymentLabels,
): PaymentProps['amount'] => ({
    label: labels.amount,
    input: {
        input: inputs.payment.amount,
        inputValues,
    },
    errors: getValidationErrorProps(valErrors, 'payment.amount', {
        'over-max': t.payments.overMax[incoming ? 'incoming' : 'outgoing'].get(),
    }),
})

const getMoneyFormProps = (
    inputValues: InputValues,
    incoming: boolean,
): PaymentProps['moneyForm'] => {
    const options = [moneyForms.bank, moneyForms.cash].map((moneyForm) => {
        const label = t.moneyForm[incoming ? 'to' : 'from'][moneyForm].get()
        return { id: moneyForm, label }
    })

    return {
        label: t.payments[incoming ? 'incoming' : 'outgoing'].get(),
        choice: {
            type: 'buttons',
            input: inputs.payment.moneyForm,
            inputValues,
            options,
            forceSelection: true,
        },
    }
}

export const getPaymentProps = (props: RawPaymentProps): PaymentProps => {
    const {
        labels,
        incoming,
        minDate,
        payments,
        totals,
        totalToPay,
        disabledText,
        inputValues,
        onSave,
        onClose,
        hideIsFull,
        hideMoneyForm,
        processes,
        saveProcessName,
        valErrors,
        isCredit,
    } = props

    const newProps: PaymentProps = {
        onClose,
        labels,
        totals,
        disabledText,
        errors: getValidationErrorProps(valErrors, 'payment', {
            'negative-balance': (error) => getValidationMessage(error, incoming),
        }),
        isCredit,
        totalToPay,
    }

    if (payments.length) {
        newProps.previous = getPrevious(props)
    }

    if (!disabledText) {
        if (payments.length) {
            newProps.remaining = {
                label: t.payments.unpaid[incoming ? 'incoming' : 'outgoing'].get(),
                amount: payments.reduce((acc, payment) => acc - payment.amount, totalToPay),
            }
        }

        if (!hideIsFull) {
            newProps.isFull = getIsFullProps(inputValues, payments.length > 0, incoming, labels)
        }

        if (inputs.payment.full.get(inputValues) === 'no') {
            newProps.amount = getAmountProps(inputValues, valErrors, incoming, labels)
        }

        if (!hideMoneyForm) {
            newProps.moneyForm = getMoneyFormProps(inputValues, incoming)
        }

        newProps.date = {
            label: t.payments.date[incoming ? 'incoming' : 'outgoing'].get(),
            dateInput: {
                input: inputs.payment.date,
                inputValues,
                minDate,
                maxDate: Day.today(),
            },
        }

        newProps.saveButton = {
            onClick: onSave,
            text: t.save.get(),
            processes,
            processName: saveProcessName,
        }
    }

    return newProps
}
