export const range = (start: number, end: number): number[] => {
    const arr = new Array(end + 1 - start)
    let i = 0

    while (start + i <= end) {
        arr[i] = start + i
        i += 1
    }

    return arr
}
