import React, { Fragment } from 'react'

import { ValidationError } from '../../../common/types/errors'
import { InputValues } from '../../../common/types/inputs'
import { Processes } from '../../../common/types/processes'
import { t } from '../../i18n'
import { inputs } from '../../inputs'
import { initReset, PASSWORD_RESET_INIT_PROCESS } from '../../state/password-reset-actions'
import { valErr } from '../../val-err'
import { Button } from '../button'
import { Input } from '../input'

interface Props {
    inputValues: InputValues
    processes: Processes
    valErrors: ValidationError[] | undefined
}

export const Init = ({ inputValues, processes, valErrors }: Props) =>
    React.createElement(
        Fragment,
        null,
        React.createElement(
            'div',
            { className: 'bottom-margin' },
            t.password.reset.enterEmail.get(),
        ),
        React.createElement(
            'div',
            null,
            React.createElement(Input, {
                input: inputs.passwordReset.email,
                inputValues,
                placeholder: t.email.get(),
                onEnter: initReset,
                focusOnMount: true,
                className: 'password-reset__input',
            }),
            valErr(valErrors, 'email', { invalid: t.user.emailNotFound.get() }),
        ),
        React.createElement(
            'div',
            { className: 'password-reset__section' },
            React.createElement(Button, {
                text: t.continue.get(),
                onClick: initReset,
                processes,
                processName: PASSWORD_RESET_INIT_PROCESS,
                className: 'password-reset__main-button',
                loadingColor: 'white',
                loadingStyle: { marginTop: '0.8em', width: 25, height: 25 },
            }),
        ),
    )
