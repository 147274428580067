import classnames from 'classnames'
import React, { FC } from 'react'

import { MIN_PASSWORD_LENGTH } from '../../common/constants'
import { InputValues } from '../../common/types/inputs'
import { Terms as TTerms } from '../../common/types/terms'
import { t, withElements } from '../i18n'
import { inputs } from '../inputs'
import { RootData } from '../state/root-data'
import { signUp, SIGNUP_PROCESS, toggleTerms } from '../state/signup-actions'
import { valErr } from '../val-err'
import { Button } from './button'
import { Checkbox } from './checkbox'
import { Input } from './input'
import { Terms } from './terms'

const renderTerms = (inputValues: InputValues, terms: TTerms | null) => {
    const isVisible = inputs.signup.showTerms.get(inputValues)

    if (!isVisible) {
        return null
    }

    return React.createElement(
        'div',
        { className: 'signup__section' },
        React.createElement(Terms, { terms }),
    )
}

export const SignUp: FC<RootData> = ({ inputValues, processes, terms, validationErrors }) => {
    const password = inputs.signup.password.get(inputValues)
    const repeatPassword = inputs.signup.repeatPassword.get(inputValues)
    const passwordsMatch = password === repeatPassword

    const agreed = inputs.signup.agree.get(inputValues)

    const valErrors = validationErrors[SIGNUP_PROCESS]

    return React.createElement(
        'div',
        { className: 'signup' },
        React.createElement(
            'div',
            { className: 'signup__input-row' },
            React.createElement(
                'div',
                { className: 'signup__input-row-element' },
                React.createElement(Input, {
                    input: inputs.signup.firstName,
                    inputValues,
                    placeholder: t.firstName.get(),
                    className: 'signup__input',
                }),
                valErr(valErrors, 'user.firstName'),
            ),
            React.createElement(
                'div',
                { className: 'signup__input-row-element' },
                React.createElement(Input, {
                    input: inputs.signup.lastName,
                    inputValues,
                    placeholder: t.lastName.get(),
                    className: 'signup__input',
                }),
                valErr(valErrors, 'user.lastName'),
            ),
        ),
        React.createElement(
            'div',
            { className: 'signup__input-row' },
            React.createElement(
                'div',
                { className: 'signup__input-row-element' },
                React.createElement(Input, {
                    input: inputs.signup.email,
                    inputValues,
                    placeholder: t.email.get(),
                    className: 'signup__input',
                }),
                valErr(valErrors, 'user.email', { unique: t.signup.duplicateEmail.get() }),
            ),
        ),
        React.createElement(
            'div',
            { className: 'signup__input-row' },
            React.createElement(
                'div',
                { className: 'signup__input-row-element' },
                React.createElement(Input, {
                    input: inputs.signup.password,
                    inputValues,
                    type: 'password',
                    placeholder: t.password.get(),
                    className: 'signup__input',
                }),
                valErr(valErrors, 'user.password', {
                    'too-short': t.signup.passwordTooShort.get(MIN_PASSWORD_LENGTH),
                }),
            ),
            React.createElement(
                'div',
                { className: 'signup__input-row-element' },
                React.createElement(Input, {
                    input: inputs.signup.repeatPassword,
                    inputValues,
                    type: 'password',
                    placeholder: t.password.repeat.get(),
                    className: classnames('signup__input', {
                        'signup__input--invalid': !passwordsMatch,
                    }),
                }),
            ),
        ),
        React.createElement(
            'div',
            { className: 'signup__password-recommendation' },
            t.password.recommendation.get(),
        ),
        renderTerms(inputValues, terms),
        React.createElement(
            'div',
            { className: 'signup__section' },
            React.createElement(Checkbox, { input: inputs.signup.agree, inputValues }),
            ' ',
            withElements(
                t.terms.agree,
                React.createElement(
                    'a',
                    { onClick: toggleTerms, className: 'signup__link' },
                    t.terms.with.get(),
                ),
            ),
        ),
        React.createElement(
            'div',
            { className: 'signup__section' },
            React.createElement(Button, {
                text: t.signup.get(),
                disabled: !passwordsMatch || !agreed,
                onClick: signUp,
                processes,
                processName: SIGNUP_PROCESS,
                className: 'signup__main-button',
                loadingColor: 'white',
            }),
        ),
    )
}
