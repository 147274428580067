import React, { FC, Fragment } from 'react'

import { sort } from '../../common/sort'
import { Day } from '../../common/time'
import { AllAccounts } from '../../common/types/account'
import { ApiEntry, EntryItem } from '../../common/types/entry'
import { InputValues } from '../../common/types/inputs'
import { Column } from '../../common/types/table'
import { getLevel4AccountName, PRESETS } from '../entry-utils'
import { t } from '../i18n'
import { inputs } from '../inputs'
import { renderAmount } from '../render-amount'
import { setRoute } from '../route-utils'
import { copyEntryFrom, remove, REMOVE_PROCESS } from '../state/entry-actions'
import { RootData } from '../state/root-data'
import { browserOnly } from '../table-utils'
import { Button } from './button'
import { DeleteIcon } from './delete-icon'
import { ExpandToggle } from './expand-toggle'
import { Link } from './link'
import { LoadingIcon } from './loading-icon'
import { type BaseRow, renderTable } from './table'

interface EntryRow extends BaseRow {
    isItemsRow: false
    isExpanded: boolean
    entry: ApiEntry
}

interface ItemsRow extends BaseRow {
    isItemsRow: true
    items: EntryItem<number>[]
}

type Row = EntryRow | ItemsRow

const getLeftAligned = () => ({ className: 'text-left' })
const getCenterAligned = () => ({ className: 'text-center' })

const renderItem = (item: EntryItem<number>, accounts: AllAccounts) =>
    React.createElement(
        'li',
        null,
        t[item.type].get(),
        ' \u2022 ',
        renderAmount(item.amount),
        ' \u2022 ',
        getLevel4AccountName(item.accountNumber, accounts),
        ' (',
        item.accountNumber,
        ')',
    )

const renderItemsRow = (items: EntryItem<number>[], accounts: AllAccounts | null) => {
    if (!accounts) {
        return React.createElement(LoadingIcon, { color: 'black' })
    } else {
        return React.createElement('ul', null, ...items.map((item) => renderItem(item, accounts)))
    }
}

const renderDateCell = (row: Row, accounts: AllAccounts | null) => {
    if (row.isItemsRow) {
        return renderItemsRow(row.items, accounts)
    } else {
        return React.createElement(
            Fragment,
            null,
            React.createElement(ExpandToggle, { expanded: row.isExpanded }),
            Day.fromYmd(row.entry.date).dmy(),
        )
    }
}

const getColumns = (inputValues: InputValues, accounts: AllAccounts | null) => {
    const toggleRow = (row: EntryRow) => {
        const input = inputs.entries.row(row.entry._id).expanded
        const isExpanded = input.get(inputValues)
        input.set(!isExpanded)
    }

    const columns: Column<Row>[] = [
        {
            header: { content: t.entries.date.get(), getProps: getLeftAligned },
            getProps: (row) => {
                if (row.isItemsRow) {
                    return { colSpan: columns.length }
                } else {
                    return { onClick: () => toggleRow(row), className: 'clickable' }
                }
            },
            render: browserOnly((row) => renderDateCell(row, accounts)),
        },
        {
            header: { content: t.entries.description.get(), getProps: getLeftAligned },
            getProps: (row) => {
                if (row.isItemsRow) {
                    return null
                } else {
                    return { onClick: () => toggleRow(row), className: 'clickable' }
                }
            },
            render: (row) => (row.isItemsRow ? '' : row.entry.description),
        },
        {
            header: {
                content: t.actions.get(),
                getProps: () => ({ className: 'text-center' }),
                span: 2,
            },
            getProps: getCenterAligned,
            render: browserOnly((row) => {
                if (row.isItemsRow) {
                    return null
                }

                return React.createElement(
                    'span',
                    null,
                    React.createElement(Link, {
                        text: t.copy.get(),
                        onClick: async () => copyEntryFrom(row.entry),
                        className: 'entries__copy-link',
                    }),
                )
            }),
        },
        {
            render: browserOnly((row) => {
                if (row.isItemsRow) {
                    return null
                }

                const onClick = () =>
                    confirm(t.confirm.removeEntry.get()) ? remove(row.entry._id) : null
                return React.createElement(DeleteIcon, { onClick })
            }),
        },
    ]

    return columns
}

const getRows = (entries: ApiEntry[], inputValues: InputValues) => {
    const rows: Row[] = []

    const sortedEntries = sort(entries, [{ getKey: (entry) => entry.date, reverse: true }], {
        clone: true,
    })

    for (const entry of sortedEntries) {
        const isExpanded = inputs.entries.row(entry._id).expanded.get(inputValues)
        const entryRow: EntryRow = { isItemsRow: false, isExpanded, entry }
        rows.push(entryRow)

        if (isExpanded) {
            rows.push({ isItemsRow: true, items: entry.items })
        }
    }

    return rows
}

const renderEntriesTable = (
    entries: ApiEntry[],
    inputValues: InputValues,
    accounts: AllAccounts | null,
) =>
    renderTable({
        columns: getColumns(inputValues, accounts),
        rows: getRows(entries, inputValues),
        tableClassName: 'table table--bottom-border entries__table',
    })

const renderPresets = () => {
    return React.createElement(
        'div',
        { className: 'entry-presets' },
        React.createElement(
            'div',
            { className: 'entry-presets__title' },
            t.entries.presets.title.get(),
        ),
        ...PRESETS.map((preset) => {
            const href = '#/entries/add/' + preset.id

            return React.createElement(
                'div',
                null,
                React.createElement(
                    'a',
                    { href, className: 'button button--secondary entry-presets__button' },
                    t.entries.presets[preset.id].get(),
                ),
            )
        }),
    )
}

export const EntryList: FC<RootData> = (rootData) => {
    const {
        accountData,
        entryData: { entries },
        inputValues,
        processes,
    } = rootData

    if (!entries || processes.has(REMOVE_PROCESS)) {
        return React.createElement(
            'div',
            { className: 'content-area' },
            React.createElement(
                'div',
                { className: 'content' },
                React.createElement(LoadingIcon, { color: 'black' }),
            ),
        )
    }

    return React.createElement(
        'div',
        { className: 'content-area' },
        React.createElement(
            'div',
            { className: 'content entries' },
            React.createElement('h1', { className: 'title' }, t.entries.title.get()),
            React.createElement(
                'div',
                { className: 'flex' },
                renderEntriesTable(entries, inputValues, accountData.allAccounts),
                renderPresets(),
            ),
            React.createElement(
                'div',
                { className: 'top-margin' },
                React.createElement(Button, {
                    onClick: () => setRoute('#/entries/add'),
                    text: t.addNew.get(),
                    className: 'button--primary top-margin',
                }),
            ),
        ),
    )
}
