import React, { FC, Fragment, ReactNode } from 'react'

import { RevenueItemType } from '../../../common/types/invoice'
import { InputOrValue, InputOrValueProps } from '../../input-utils'
import { BackLink } from '../back-link'
import { BusinessLookup, BusinessLookupProps } from '../business-lookup'
import { Button, ButtonProps } from '../button'
import { CharactersLeft, CharactersLeftProps } from '../characters-left'
import { ChoiceProps, renderChoice } from '../choice'
import { ItemTable, ItemTableProps } from '../item-table'
import { Link, LinkProps } from '../link'
import { LoadingPage } from '../loading-page'
import { Log, LogProps } from '../log'
import { Payment, PaymentProps } from '../payment'
import { TotalsTable, TotalsTableProps } from '../totals-table'
import { RevenueGeneral, RevenueGeneralProps } from './general'
import { RevenueSearchPanel, RevenueSearchPanelProps } from './search-panel'

type Mode = 'view' | 'edit'
export type RevenueViewParams = { isNew: true } | { isNew: false; id: string; mode: Mode }

interface BusinessDetails {
    lookup: BusinessLookupProps
    copyRevenueLink?: LinkProps
}

interface CitizenDetails {
    input: InputOrValueProps
    charactersLeft?: CharactersLeftProps
}

interface VendorProps {
    logoUrl?: string
    name: string
}

export type RevenueButton =
    | { type: 'button'; props: ButtonProps }
    | { type: 'link'; props: LinkProps }

export interface RevenueEditProps {
    isLoading: false
    backLink: boolean
    searchSidebar?: RevenueSearchPanelProps
    paymentSidebar?: PaymentProps
    vendor?: VendorProps
    recipientTitle: string
    customerType?: ChoiceProps<'business' | 'citizen' | ''>
    businessDetails?: BusinessDetails
    citizenDetails?: CitizenDetails
    title: string
    general: RevenueGeneralProps
    itemTable: ItemTableProps<RevenueItemType>
    addItemButtons?: ButtonProps[]
    commentInput?: InputOrValueProps
    commentCharactersLeft?: CharactersLeftProps
    totalsTable: TotalsTableProps
    customerTypeNote?: string
    buttons: RevenueButton[]
    creditRevenueButton?: RevenueButton
    log?: LogProps
    originalRevenue?: { id: string; number: string }
    creditRevenueComment?: string
}

const renderVendor = ({ logoUrl, name }: VendorProps): ReactNode => {
    const contents = logoUrl
        ? React.createElement('img', { className: 'logo', src: logoUrl })
        : React.createElement('h1', { className: 'invoice__vendor-name' }, name)

    return React.createElement('div', { className: 'invoice__vendor' }, contents)
}

const renderBusinessDetails = ({ lookup, copyRevenueLink }: BusinessDetails): ReactNode => {
    return React.createElement(
        Fragment,
        null,
        React.createElement(BusinessLookup, lookup),
        copyRevenueLink &&
            React.createElement(
                'div',
                null,
                React.createElement(Link, { ...copyRevenueLink, className: 'copy-invoice-link' }),
            ),
    )
}

const renderCitizenDetails = ({ input, charactersLeft }: CitizenDetails): ReactNode =>
    React.createElement(
        'div',
        { className: 'text-multiline' },
        React.createElement(InputOrValue, input),
        charactersLeft && React.createElement(CharactersLeft, charactersLeft),
    )

const renderTopLeft = ({
    vendor,
    recipientTitle,
    customerType,
    businessDetails,
    citizenDetails,
}: RevenueEditProps): ReactNode =>
    React.createElement(
        'div',
        { className: 'invoice__top-left' },
        vendor && renderVendor(vendor),
        React.createElement('h1', { className: 'title' }, recipientTitle),
        customerType &&
            React.createElement(
                'div',
                null,
                renderChoice({
                    ...customerType,
                    buttonClassName: 'button--primary',
                    selectedButtonClassName: 'button--primary-selected',
                }),
            ),
        React.createElement(
            'div',
            { className: 'invoice__customer-details' },
            businessDetails && renderBusinessDetails(businessDetails),
            citizenDetails && renderCitizenDetails(citizenDetails),
        ),
    )

const renderTopRight = ({ general, title }: RevenueEditProps): ReactNode =>
    React.createElement(
        'div',
        { className: 'invoice__top-right' },
        React.createElement('h1', { className: 'title' }, title),
        React.createElement(RevenueGeneral, general),
    )

const renderBottomLeft = (props: RevenueEditProps): ReactNode => {
    const {
        addItemButtons,
        commentInput,
        commentCharactersLeft,
        originalRevenue,
        creditRevenueComment,
    } = props

    let comment: ReactNode

    if (commentInput) {
        comment = React.createElement(
            Fragment,
            null,
            React.createElement(InputOrValue, commentInput),
            commentCharactersLeft && React.createElement(CharactersLeft, commentCharactersLeft),
        )
    } else {
        const { id, number } = originalRevenue!

        comment = React.createElement(
            Fragment,
            null,
            creditRevenueComment + ' ',
            React.createElement('a', { href: '#/invoices/view/' + id }, number),
        )
    }

    return React.createElement(
        'div',
        { className: 'invoice__bottom-left' },
        addItemButtons &&
            React.createElement(
                'div',
                null,
                ...addItemButtons.map((button) =>
                    React.createElement(Fragment, null, React.createElement(Button, button), ' '),
                ),
            ),
        React.createElement('div', { className: 'invoice__comment-area' }, comment),
    )
}

const renderButton = (button: RevenueButton): ReactNode => {
    if (button.type === 'link') {
        return React.createElement(Link, button.props)
    } else {
        return React.createElement(Button, button.props)
    }
}

const renderCreditRevenueButton = (button?: RevenueButton): ReactNode => {
    if (!button) {
        return null
    }

    return React.createElement('div', { className: 'top-margin' }, renderButton(button))
}

const renderBottomRight = ({
    totalsTable,
    customerTypeNote,
    buttons,
    creditRevenueButton,
}: RevenueEditProps) =>
    React.createElement(
        'div',
        { className: 'invoice__bottom-right' },
        React.createElement(TotalsTable, totalsTable),
        React.createElement(
            'div',
            { className: 'text-right' },
            customerTypeNote,
            React.createElement(
                'div',
                { className: 'top-margin' },
                ...buttons.map((button) => {
                    return React.createElement(Fragment, null, renderButton(button), ' ')
                }),
            ),
            renderCreditRevenueButton(creditRevenueButton),
        ),
    )

export const RevenueEdit: FC<RevenueEditProps | { isLoading: true }> = (props) => {
    if (props.isLoading) {
        return React.createElement(LoadingPage)
    }

    const { backLink, searchSidebar, paymentSidebar, itemTable, log } = props

    return React.createElement(
        'div',
        { className: 'content-area' },
        searchSidebar && React.createElement(RevenueSearchPanel, searchSidebar),
        paymentSidebar && React.createElement(Payment, paymentSidebar),
        React.createElement(
            'div',
            { className: 'content invoice' },
            backLink && React.createElement(BackLink),
            React.createElement(
                'div',
                { className: 'invoice__top' },
                renderTopLeft(props),
                renderTopRight(props),
            ),
            React.createElement<ItemTableProps<RevenueItemType>>(ItemTable, itemTable),
            React.createElement(
                'div',
                { className: 'invoice__bottom' },
                renderBottomLeft(props),
                renderBottomRight(props),
            ),
            log && React.createElement(Log, log),
        ),
    )
}
