import React, { FC } from 'react'

import { getMinDay } from '../../../common/company-utils'
import { InputValues } from '../../../common/types/inputs'
import {
    getDueDateOptions,
    getStatusOptions,
    getTermOptions,
    renderClearFiltersButton,
} from '../../expense-archive-utils'
import { DateShortcut, getMonthShortcuts, getYearShortcuts } from '../../get-date-shortcuts'
import { t } from '../../i18n'
import { inputs } from '../../inputs'
import { getCompany } from '../../state/company-actions'
import { RootData } from '../../state/root-data'
import { Button } from '../button'
import { renderChoice } from '../choice'
import { DateInput } from '../date-input'
import { LoadingPage } from '../loading-page'
import { ExpenseArchiveSidebar } from './archive-sidebar'

const archiveInputs = inputs.expense.archive
const dateInputs = archiveInputs.date

const renderStatusChoice = (inputValues: InputValues) =>
    renderChoice({
        type: 'buttons',
        input: archiveInputs.status,
        inputValues,
        options: getStatusOptions(),
        buttonClassName: 'button--secondary',
        selectedButtonClassName: 'button--primary',
    })

const renderShortcut = (shortcut: DateShortcut) =>
    React.createElement(Button, {
        text: shortcut.label,
        onClick: () => {
            dateInputs.from.set(shortcut.from)
            dateInputs.to.set(shortcut.to)
        },
        className: 'button--secondary archive__date-shortcut',
    })

const renderDueDateChoice = (inputValues: InputValues) =>
    renderChoice({
        type: 'buttons',
        input: archiveInputs.dueDate,
        inputValues,
        options: getDueDateOptions(),
        buttonClassName: 'button--secondary',
        selectedButtonClassName: 'button--primary',
    })

const renderTermChoice = (inputValues: InputValues) =>
    renderChoice({
        type: 'buttons',
        input: archiveInputs.term,
        inputValues,
        options: getTermOptions(),
        buttonClassName: 'button--secondary',
        selectedButtonClassName: 'button--primary',
    })

export const ExpenseArchiveGeneral: FC<RootData> = ({ inputValues, companyData, session }) => {
    if (!companyData.companies) {
        return React.createElement(LoadingPage)
    }

    const { interimDate } = getCompany(companyData, session)
    const minDate = getMinDay(interimDate)

    return React.createElement(
        'div',
        { className: 'content-area' },
        React.createElement(ExpenseArchiveSidebar, { selected: 'general' }),
        React.createElement(
            'div',
            { className: 'content archive' },
            React.createElement(
                'div',
                null,
                React.createElement('h1', { className: 'title archive__title' }, t.status.get()),
                React.createElement('div', null, renderStatusChoice(inputValues)),
                React.createElement(
                    'h1',
                    { className: 'title archive__title' },
                    t.invoices.date.get(),
                ),
                React.createElement(
                    'div',
                    { className: 'archive__date' },
                    React.createElement(
                        'span',
                        { className: 'archive__date-label' },
                        t.date.from.get(),
                    ),
                    React.createElement(DateInput, {
                        input: dateInputs.from,
                        inputValues,
                        minDate,
                        className: 'date-button archive__date-input',
                    }),
                ),
                React.createElement(
                    'div',
                    { className: 'archive__date' },
                    React.createElement(
                        'span',
                        { className: 'archive__date-label' },
                        t.date.to.get(),
                    ),
                    React.createElement(DateInput, {
                        input: dateInputs.to,
                        inputValues,
                        minDate,
                        className: 'date-button archive__date-input',
                    }),
                ),
                React.createElement(
                    'div',
                    null,
                    React.createElement('div', null, ...getMonthShortcuts().map(renderShortcut)),
                    React.createElement('div', null, ...getYearShortcuts().map(renderShortcut)),
                ),
                React.createElement('h1', { className: 'title archive__title' }, t.dueDate.get()),
                React.createElement('div', null, renderDueDateChoice(inputValues)),
                React.createElement('h1', { className: 'title archive__title' }, t.term.get()),
                React.createElement('div', null, renderTermChoice(inputValues)),
            ),
            React.createElement(
                'div',
                { className: 'top-margin' },
                React.createElement(
                    'a',
                    { className: 'button button--primary', href: '#/expenses/archive/vendor' },
                    t.forward.get(),
                ),
                ' ',
                React.createElement(
                    'a',
                    { className: 'button button--secondary', href: '#/expenses/archive/results' },
                    t.show.get(),
                ),
                ' ',
                renderClearFiltersButton('general', inputValues),
            ),
        ),
    )
}
