import { assertNever } from './assert-never'
import { MIN_DATE } from './clock'
import { calculationModes, expenseTypes } from './enums'
import {
    calculateAutomaticItemFromValues,
    calculateTotalsFromAssets,
    calculateTotalsFromAutomaticItems,
    getItemQuantity,
    getItemUnitPrice,
    isAutomaticItemArray,
} from './item-utils'
import { mapMember } from './map-member'
import { CalculationMode } from './types/enums'
import {
    ApiExpense,
    AssetValueChange,
    DbExpense,
    ExpenseItem,
    PendingStockChange,
    PendingStockChangeItem,
} from './types/expense'
import { Totals } from './types/item'

export const calculateExpenseInitial = (expense: ApiExpense | DbExpense): Totals<number> => {
    const { calculationMode } = expense

    if (calculationMode === calculationModes.manual) {
        const { vat: vatAmount, payableWithVat } = expense.totals!
        const payableWithoutVat = payableWithVat - vatAmount

        return {
            totalWithoutVat: payableWithoutVat,
            discount: 0,
            payableWithoutVat,
            vatAmount,
            payableWithVat,
        }
    }

    if (expense.type === expenseTypes.regular) {
        const items = expense.items!

        if (isAutomaticItemArray(items, calculationMode)) {
            // MIN_DATE will give the initial values
            return calculateTotalsFromAutomaticItems(items, MIN_DATE)
        } else {
            throw new Error('Invalid calculation mode: ' + calculationMode)
        }
    } else if (expense.type === expenseTypes.asset) {
        return calculateTotalsFromAssets(expense.assets!)
    } else {
        throw assertNever(expense.type, 'expense type')
    }
}

export const addValueChange = (
    existingChanges: AssetValueChange<number>[],
    newChange: AssetValueChange<number>,
) => [
    // Discard any existing changes that have the same date or a newer one
    ...existingChanges.filter((existingChange) => {
        if (existingChange.date === newChange.date) {
            // Keep same date change if the mode is different.
            // So you can change both residual and EoL on the same day.
            return existingChange.mode !== newChange.mode
        } else {
            return existingChange.date < newChange.date
        }
    }),
    newChange,
]

export const getPendingStockChangeLookup = (changes: PendingStockChange[]) => {
    const lookup = new Map<string, Set<string>>()
    const getStringSet = () => new Set<string>()

    for (const change of changes) {
        for (const changeItem of change.items) {
            mapMember(lookup, changeItem.expenseId, getStringSet).add(changeItem.itemId)
        }
    }

    return (expenseId: string, itemId: string) => {
        const byItemId = lookup.get(expenseId)
        return byItemId ? byItemId.has(itemId) : false
    }
}

export const getDbStockChangeItem = (
    item: PendingStockChangeItem<string>,
): PendingStockChangeItem<number> => {
    const { expenseId, itemId, newQuantity, newUnitPrice } = item
    const dbItem: PendingStockChangeItem<number> = { expenseId, itemId }

    if (typeof newQuantity === 'string') {
        dbItem.newQuantity = Number(newQuantity)
    }

    if (typeof newUnitPrice === 'string') {
        dbItem.newUnitPrice = Number(newUnitPrice)
    }

    return dbItem
}

export const getNewTotal = (
    calculationMode: CalculationMode,
    changeItem: PendingStockChangeItem<number>,
    expenseItem: ExpenseItem<number>,
    date: string,
) => {
    const newQuantity =
        typeof changeItem.newQuantity === 'number'
            ? changeItem.newQuantity
            : getItemQuantity(expenseItem, date)

    const newUnitPrice =
        typeof changeItem.newUnitPrice === 'number'
            ? changeItem.newUnitPrice
            : getItemUnitPrice(calculationMode, expenseItem, date)

    const vatPercentage = expenseItem.vatPercentage || 0

    // Even if the expense is in manual mode, the totals modified by
    // stock changes are always calculated with automatic mode logic.
    const newTotals = calculateAutomaticItemFromValues(newQuantity, newUnitPrice, 0, vatPercentage)

    return newTotals.payableWithoutVat
}

export const getExpenseFileKey = (expenseId: string, hash: string, filename: string) => {
    return 'expense-files/' + expenseId + '/' + hash + '/' + filename
}
