import { Input, InputValues } from '../common/types/inputs'
import { ExpenseVatPercentage, RevenueVatPercentage } from '../common/types/vat'

const revenueVatValues: Record<string, RevenueVatPercentage> = {
    '22': 22,
    '20': 20,
    '9': 9,
    '0': 0,
}

const vatValues: Record<string, ExpenseVatPercentage> = { ...revenueVatValues, null: null }

export const getRevenueVatInput = (
    input: Input<string>,
    inputValues: InputValues,
): RevenueVatPercentage => {
    const stringValue = input.get(inputValues)

    if (!(stringValue in revenueVatValues)) {
        throw new Error('Unexpected revenue VAT value: ' + stringValue)
    }

    return revenueVatValues[stringValue]
}

export const getExpenseVatInput = (
    input: Input<string>,
    inputValues: InputValues,
): ExpenseVatPercentage => {
    const stringValue = input.get(inputValues)

    if (!(stringValue in vatValues)) {
        throw new Error('Unexpected expense VAT value: ' + stringValue)
    }

    return vatValues[stringValue]
}
