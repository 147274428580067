import {
    BANK,
    BANK_EXPENSES,
    CLAIMS_AGAINST_BUYERS,
    CREDIT,
    DEBIT,
    DEBT_TO_SUPPLIER,
    getLevel1Number,
    IncomeAccounts,
    SHORT_TERM_LOANS,
    VEHICLE_REIMBURSEMENT,
} from '../common/accounts'
import { EntryItemType } from '../common/enums'
import { findById } from '../common/find-by-id'
import { AllAccounts } from '../common/types/account'
import { EntryItem } from '../common/types/entry'
import { InputValues } from '../common/types/inputs'
import { getDefaultAccountName } from './account-utils'
import { amountFromString } from './amount-from-string'
import { t } from './i18n'
import { inputs } from './inputs'

type EntryPresetId = 'bank' | 'bankIncome' | 'loan' | 'repayment' | 'vehicle'

interface PresetItem {
    type: EntryItemType
    accountNumber: string
    negate?: boolean
}

export interface EntryPreset {
    id: EntryPresetId
    items: PresetItem[]
    maxAmount?: number
}

export interface PresetState {
    preset: EntryPreset
    amountString: string
    amountNumber: number
    isValidAmount: boolean
}

export const PRESETS: EntryPreset[] = [
    {
        id: 'bank',
        items: [
            { type: EntryItemType.debit, accountNumber: BANK_EXPENSES },
            { type: EntryItemType.credit, accountNumber: DEBT_TO_SUPPLIER },
            { type: EntryItemType.debit, accountNumber: DEBT_TO_SUPPLIER },
            { type: EntryItemType.credit, accountNumber: BANK },
        ],
    },
    {
        id: 'bankIncome',
        items: [
            { type: EntryItemType.debit, accountNumber: BANK },
            { type: EntryItemType.credit, accountNumber: CLAIMS_AGAINST_BUYERS },
            { type: EntryItemType.debit, accountNumber: BANK_EXPENSES, negate: true },
            { type: EntryItemType.debit, accountNumber: CLAIMS_AGAINST_BUYERS },
        ],
    },
    {
        id: 'loan',
        items: [
            { type: EntryItemType.debit, accountNumber: BANK },
            { type: EntryItemType.credit, accountNumber: SHORT_TERM_LOANS },
        ],
    },
    {
        id: 'repayment',
        items: [
            { type: EntryItemType.debit, accountNumber: SHORT_TERM_LOANS },
            { type: EntryItemType.credit, accountNumber: BANK },
        ],
    },
    {
        id: 'vehicle',
        items: [
            { type: EntryItemType.debit, accountNumber: VEHICLE_REIMBURSEMENT },
            { type: EntryItemType.credit, accountNumber: DEBT_TO_SUPPLIER },
            { type: EntryItemType.debit, accountNumber: DEBT_TO_SUPPLIER },
            { type: EntryItemType.credit, accountNumber: BANK },
        ],
        maxAmount: 355,
    },
]

export const getPreset = (id?: string) => findById(PRESETS, id)

export const getPresetState = (
    presetId: string | undefined,
    inputValues: InputValues,
): PresetState | undefined => {
    const preset = getPreset(presetId)

    if (!preset) {
        return undefined
    }

    const amountString = inputs.entry.amount.get(inputValues)
    const amountNumber = amountFromString(amountString) || 0
    const isValidAmount = typeof preset.maxAmount !== 'number' || amountNumber <= preset.maxAmount
    return { preset, amountString, amountNumber, isValidAmount }
}

const negate = <T extends string | number>(amount: T): T => {
    if (typeof amount === 'string') {
        return ('-' + amount) as T
    }

    if (typeof amount === 'number') {
        return -amount as T
    }

    throw new Error('Invalid type for amount: ' + amount)
}

export const getPresetItemsFromInputs = <T extends string | number>(
    presetState: PresetState,
    amountParam: T,
) =>
    presetState.preset.items.map((presetItem, index): EntryItem<T> => {
        const { type, accountNumber } = presetItem
        const amount = presetItem.negate ? negate(amountParam) : amountParam
        return { id: 'item-' + index, type, amount, accountNumber }
    })

export const getAccountPrefix = (level4Number: string) => {
    const level1Number = getLevel1Number(level4Number)

    if (level1Number in DEBIT) {
        return t.accounts.debit.get()
    } else if (level1Number in CREDIT) {
        return t.accounts.credit.get()
    } else if (IncomeAccounts.isCredit(level1Number)) {
        return t.accounts.incomeStatement.credit.get()
    } else if (IncomeAccounts.isDebit(level1Number)) {
        return t.accounts.incomeStatement.debit.get()
    } else if (IncomeAccounts.isCreditDebit(level1Number)) {
        return t.accounts.incomeStatement.get()
    } else {
        throw new Error('Invalid account number: ' + level4Number)
    }
}

export const getLevel4AccountName = (level4Number: string, accounts: AllAccounts) => {
    const accountOrNumber = accounts.get(level4Number)

    if (!accountOrNumber) {
        throw new Error('Invalid account number: ' + level4Number)
    }

    return typeof accountOrNumber === 'string'
        ? getDefaultAccountName(accountOrNumber)
        : accountOrNumber.name
}
