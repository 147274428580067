import { assertNever } from '../../common/assert-never'
import { labourPaymentTypes } from '../../common/enums'
import { calculateLabourCost, getPayable, getPayments } from '../../common/labour-cost-utils'
import { Day } from '../../common/time'
import { LabourPaymentType } from '../../common/types/enums'
import { ApiLabourCost } from '../../common/types/labour-cost'
import { PaymentProps } from '../components/payment'
import { t } from '../i18n'
import {
    addLabourPayment,
    getById as getLabourCostById,
    removeLabourPayment,
} from '../state/labour-cost-actions'
import {
    closeExpensePaymentForm,
    REMOVE_PAYMENT_PROCESS,
    SAVE_PAYMENT_PROCESS,
} from '../state/payment-actions'
import { RootData } from '../state/root-data'
import { getPaymentProps, RawPaymentProps } from './payment-props'

const isFullyPaid = (labourCost: ApiLabourCost, type: LabourPaymentType) => {
    if (type === labourPaymentTypes.net) {
        return labourCost.netPaid
    } else if (type === labourPaymentTypes.tax) {
        return labourCost.taxPaid
    } else {
        throw assertNever(type, 'labour cost payment type')
    }
}

export const getLabourCostPaymentProps = (
    rootData: RootData,
    type: LabourPaymentType,
    id: string,
): PaymentProps => {
    const { inputValues, labourCostData, processes, validationErrors } = rootData

    const labourCost = getLabourCostById(labourCostData, id)!
    const calc = calculateLabourCost(labourCost)
    const payable = getPayable(calc, type)

    const rawProps: RawPaymentProps = {
        labels: {
            main: t.payments.labourCost.main[type].get(),
            amountSection: t.totals.labourCost[type].get(),
            isFull: t.payments.labourCost.isFull.net.get(), // Not used when type === 'tax'
            amount: t.payments.labourCost.amount[type].get(),
        },
        incoming: false,
        minDate: Day.fromYm(labourCost.month),
        payments: getPayments(labourCost, type),
        totals: {
            totalWithoutVat: payable,
            discount: 0,
            payableWithoutVat: payable,
            vatAmount: 0,
            payableWithVat: payable,
        },
        totalToPay: payable,
        inputValues,
        onSave: async () => addLabourPayment(labourCost._id, type),
        onRemove: async (paymentId) => removeLabourPayment(id, type, paymentId),
        onClose: closeExpensePaymentForm,
        hideIsFull: type === labourPaymentTypes.tax,
        hideMoneyForm: type === labourPaymentTypes.tax,
        processes,
        saveProcessName: SAVE_PAYMENT_PROCESS,
        removeProcessName: REMOVE_PAYMENT_PROCESS,
        valErrors: validationErrors[SAVE_PAYMENT_PROCESS],
    }

    if (isFullyPaid(labourCost, type)) {
        rawProps.disabledText = t.payments.fullyPaid.outgoing.get()
    }

    return getPaymentProps(rawProps)
}
