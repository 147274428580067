import { cleanString } from './clean-string'

const NAME_PREFIXES = new Set([
    'aiandusuhistu',
    'aktsiaselts',
    'as',
    'fie',
    'garaaziuhistu',
    'hooneuhistu',
    'korteriuhistu',
    'ku',
    'mittetulundusuhing',
    'mtu',
    'osauhing',
    'ou',
    'sa',
    'sihtasutus',
    'spordiklubi',
    'tulundusuhistu',
    'taisuhing',
    'tu',
    'usaldusuhing',
    'uu',
])

export const normalizeBusinessName = (businessName: string) => {
    const normalized = []

    const clean = cleanString(businessName, false)
    normalized.push(clean.replace(/ /g, ''))
    const spacePos = clean.indexOf(' ')

    if (spacePos !== -1) {
        const firstWord = clean.substr(0, spacePos)

        if (NAME_PREFIXES.has(firstWord)) {
            // Add variant without common prefix
            normalized.push(clean.substr(spacePos + 1).replace(/ /g, ''))
        }
    }

    return normalized
}
