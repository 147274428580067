import { InputValues } from '../../common/types/inputs'
import { KEY } from '../input-utils'
import { dispatch } from './store'

type InputRecord = Record<string, unknown>
type InputRecordOrFunc = InputRecord | ((value: string) => InputRecord)

/**
 * Quite hacky, but convenient.
 *
 * To be used like this: clearInputs(inputs.expense.vendor)
 *
 * The parameter must be a group of inputs, not a single input.
 * For example, clearInputs(inputs.expense.vendor.name) would fail.
 * TypeScript will not catch this, as the solution is not
 * type-safe enough.
 *
 * However, it is more type-safe than an alternative solution where
 * we would take the prefix string as a parameter instead, e.g.
 * clearInputs('expense/vendor')
 *
 * If, for example, someone renames inputs.expense to inputs.exp,
 * they would have to know (and remember) that they also need to change
 * clearInputs('expense/vendor') to clearInputs('exp/vendor').
 * The current solution makes it easier to catch or avoid such errors.
 *
 * Also note that this only works on input groups created by
 * transform() in input-utils, which adds the untyped member
 * indexed by the KEY symbol.
 *
 * transform() adds this member to functions as well, so you can also
 * use clearInputs(inputs.expense.item), etc.
 */
export const clearInputs = (inputs: InputRecordOrFunc, clearDefaults?: boolean) => {
    if (KEY in inputs) {
        const prefix: unknown = inputs[KEY as keyof InputRecordOrFunc]

        if (typeof prefix === 'string') {
            dispatch((draft) => {
                const newState: InputValues = {}

                for (const key of Object.keys(draft.inputValues)) {
                    const shouldRemove =
                        key.startsWith(prefix) || (clearDefaults && key.startsWith('def:' + prefix))

                    if (!shouldRemove) {
                        newState[key] = draft.inputValues[key]
                    }
                }

                draft.inputValues = newState
            })

            return
        }
    }

    throw new Error('Valid input key prefix not found')
}
