import { MIN_DATE } from './clock'
import { addressToString } from './company-utils'
import { Language, t } from './i18n'
import { calculateTotalsFromAutomaticItems } from './item-utils'
import { CommonCompanyFields } from './types/company'
import { ApiCustomer, DbCustomer, DbRevenueItem } from './types/invoice'
import { Totals } from './types/item'
import { VendorFields } from './types/vendor'

export const getCompanyVendorFields = (company: CommonCompanyFields): VendorFields<string> => {
    const { name: vendorName, regCode, vatId, phone, email, website, bankAccounts } = company

    const address = addressToString(company.address)

    const fields: VendorFields<string> = {
        name: vendorName,
        address,
        regCode,
        vatId,
        email,
        website,
        bankAccounts,
    }

    if (phone) {
        fields.phone = phone
    }

    return fields
}

// The param type is a supertype of both ApiRevenue and DbRevenue
export const calculateRevenue = (revenue: { items: DbRevenueItem[] }): Totals<number> => {
    // Revenue item calculations currently don't depend on the date, so we can always pass MIN_DATE
    return calculateTotalsFromAutomaticItems(revenue.items, MIN_DATE)
}

export const getApiCustomer = (customer: DbCustomer): ApiCustomer => {
    if (customer.isBusiness) {
        const { name: customerName, regCode, address, vatId } = customer
        return { isBusiness: true, name: customerName, regCode, address, vatId }
    } else {
        return customer
    }
}

export const getCustomerName = (customer: ApiCustomer, language: Language) => {
    return customer.isBusiness ? customer.name : t.citizen.getForLanguage(language)
}
