import React from 'react'

import { Input, InputValues } from '../../../common/types/inputs'
import { renderInputOrValue } from '../../input-utils'

interface Props {
    editMode: boolean
    label: string
    input: Input<string>
    inputValues: InputValues
}

export const InputRow = ({ editMode, label, input, inputValues }: Props) =>
    React.createElement(
        'div',
        { className: 'settings__input-row' },
        React.createElement('span', { className: 'settings__label' }, label, ': '),
        renderInputOrValue(editMode, { input, inputValues }),
    )
