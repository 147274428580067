import { canUpdateBillingDetails } from '../../common/access'
import { CompanyStatus, EmailConfirmationStatus } from '../../common/enums'
import { Day } from '../../common/time'
import { ExpenseType } from '../../common/types/enums'
import { PasswordResetViewParams } from '../../common/types/user'
import { emitNavigation } from '../event-bus'
import { getRoute, setRoute } from '../route-utils'
import { SettingsPage, View } from '../view'
import * as CommandActions from './command-actions'
import * as CompanyActions from './company-actions'
import * as DashboardActions from './dashboard-actions'
import * as EntryActions from './entry-actions'
import * as ExpenseActions from './expense-actions'
import * as LabourCostActions from './labour-cost-actions'
import * as LoadActions from './load-actions'
import * as PasswordResetActions from './password-reset-actions'
import * as ReportActions from './report-actions'
import * as RevenueActions from './revenue-actions'
import * as SettingsActions from './settings-actions'
import * as SignupActions from './signup-actions'
import { dispatch, getState } from './store'
import * as TaxActions from './tax-actions'
import * as UserSettingsActions from './user-settings-actions'

const initDefault = async () => {
    const { session } = getState()

    if (!session) {
        return
    }

    if (!session.companyId) {
        setRoute('#/select-company')
    } else {
        const company = await CompanyActions.loadCompany()

        if (company.status === CompanyStatus.active) {
            setRoute('#/dashboard')
        } else if (canUpdateBillingDetails(company)) {
            setRoute('#/init-company/billing')
        } else {
            setRoute('#/init-company/date')
        }
    }
}

const getInitCompanyView = (page?: string, ...params: string[]): View | null => {
    if (page === 'general') {
        void CompanyActions.initGeneral()
        return { name: 'InitCompanyGeneral', noMenu: true, isNew: false }
    } else if (page === 'date') {
        void CompanyActions.initDate()
        return { name: 'InitCompanyDate', noMenu: true }
    } else if (page === 'billing') {
        void CompanyActions.initBilling()
        return { name: 'InitCompanyBilling', noMenu: true }
    } else if (page === 'payment-status') {
        const id = params[0] || ''

        void CompanyActions.initPaymentStatus()

        return { name: 'InitCompanyPaymentStatus', id, noMenu: true }
    }

    return null
}

const getUserSettingsView = (page?: string, mode?: string): View | null => {
    if (page === 'general') {
        void UserSettingsActions.initGeneral()
        const editMode = mode === 'edit'
        return { name: 'UserSettingsGeneral', noMenu: true, editMode }
    } else if (page === 'companies') {
        void UserSettingsActions.initCompanies()
        return { name: 'UserSettingsCompanies', noMenu: true }
    }

    return null
}

const getExpenseView = (mode?: string, ...params: string[]): View | null => {
    if (mode === 'register') {
        const month = params[0] || ''

        void ExpenseActions.initRegister()
        return { name: 'ExpenseRegister', month }
    } else if (mode === 'archive') {
        const [subView] = params

        if (subView === 'general') {
            return { name: 'ExpenseArchiveGeneral' }
        } else if (subView === 'vendor') {
            void LoadActions.loadExpenses() // TODO optimize?
            return { name: 'ExpenseArchiveVendor' }
        } else if (subView === 'types') {
            return { name: 'ExpenseArchiveTypes' }
        } else if (subView === 'results') {
            void LoadActions.loadExpenses() // TODO search-specific loader? load only needed data from server?
            return { name: 'ExpenseArchiveResults' }
        }
    } else if (mode === 'unpaid') {
        void ExpenseActions.initUnpaidForm()
        return { name: 'UnpaidExpenses' }
    } else if (mode === 'view') {
        const isNew = false
        const id = params[0] || ''

        void ExpenseActions.initForm(isNew, id)

        return { name: 'ExpenseEdit', mode: 'view', id }
    } else if (mode === 'edit') {
        const isNew = false
        const id = params[0] || ''

        void ExpenseActions.initForm(isNew, id)

        return { name: 'ExpenseEdit', mode: 'edit', id }
    } else if (mode === 'add') {
        const isNew = true
        const type = params[0] || ''

        void ExpenseActions.initForm(isNew, '')

        return { name: 'ExpenseEdit', mode: 'add', id: '', type: type as ExpenseType } // TODO
    } else if (mode === 'assets') {
        void ExpenseActions.initAssetList(params)
        return { name: 'AssetList', routeParams: params }
    } else if (mode === 'goods') {
        const month = params[0] || ''
        ExpenseActions.initGoodsList()
        return { name: 'GoodsList', month }
    } else if (mode === 'stock') {
        ExpenseActions.initStockList()
        return { name: 'StockList' }
    } else if (mode === 'add-stock-change') {
        const isNew = true
        const date = params[0] || ''

        void ExpenseActions.initStockChange(isNew, undefined, date)

        return { name: 'StockChange', isNew, date, changeId: '' }
    } else if (mode === 'edit-stock-change') {
        const isNew = false
        const changeId = params[0] || ''

        void ExpenseActions.initStockChange(isNew, changeId)

        return { name: 'StockChange', isNew, date: '', changeId }
    } else if (mode === 'general') {
        const month = params[0] || ''
        ExpenseActions.initGeneralList()
        return { name: 'GeneralExpenseList', month }
    }

    return null
}

const getRevenueView = (mode?: string, ...params: string[]): View | null => {
    if (mode === 'register') {
        RevenueActions.initRegister()
        const month = params[0] || ''
        return { name: 'RevenueRegister', month }
    } else if (mode === 'archive') {
        const id = params[0] || ''
        const subView = id

        if (subView === 'general') {
            return { name: 'RevenueArchiveGeneral' }
        } else if (subView === 'customer') {
            void LoadActions.loadRevenues() // TODO load only list of previous customers?
            return { name: 'RevenueArchiveCustomer' }
        } else if (subView === 'contents') {
            return { name: 'RevenueArchiveContents' }
        } else if (subView === 'results') {
            void LoadActions.loadRevenues() // TODO search-specific loader? load only needed data from server?
            return { name: 'RevenueArchiveResults' }
        }
    } else if (mode === 'unpaid') {
        void RevenueActions.initUnpaidForm()
        return { name: 'UnpaidRevenues' }
    } else if (mode === 'view') {
        const isNew = false
        const id = params[0] || ''

        void RevenueActions.initForm(isNew, id)

        return { name: 'RevenueEdit', mode: 'view', id }
    } else if (mode === 'edit') {
        const isNew = false
        const id = params[0] || ''

        void RevenueActions.initForm(isNew, id)

        return { name: 'RevenueEdit', mode: 'edit', id }
    } else if (mode === 'add') {
        const isNew = true

        void RevenueActions.initForm(isNew)

        return { name: 'RevenueEdit', mode: 'add', id: '' }
    } else if (mode === 'preview') {
        const id = params[0] || ''

        void RevenueActions.initPreview(false, id)
        return { name: 'RevenuePreview', id }
    } else if (mode === 'search-by-number') {
        RevenueActions.initSearchByNumberForm()
        return { name: 'RevenueSearch' }
    }

    return null
}

const getCreditRevenueView = (mode?: string, ...params: string[]): View | null => {
    const id = params[0] || ''

    if (mode === 'add') {
        void RevenueActions.initCreditForm(true, id)
        return { name: 'CreditRevenue', mode, id }
    } else if (mode === 'view') {
        void RevenueActions.initCreditForm(false, id)
        return { name: 'CreditRevenue', mode, id }
    } else if (mode === 'preview') {
        void RevenueActions.initCreditForm(false, id)
        void RevenueActions.initPreview(true, id)
        return { name: 'CreditRevenuePreview', id }
    }

    return null
}

const getLabourCostView = (mode?: string, ...params: string[]): View => {
    let yearStr: string | undefined

    if (mode === 'add') {
        yearStr = params[0]

        void LabourCostActions.initAddForm()
    } else if (mode === 'view') {
        const id = params[0] || ''
        yearStr = params[1]
        void LabourCostActions.initViewForm(id)
    } else {
        // mode === 'list' or missing
        yearStr = params[0]
        void LabourCostActions.initList()
    }

    const year = yearStr ? parseInt(yearStr, 10) : Day.today().year()
    return { name: 'LabourCostList', year }
}

const getTaxView = (page?: string, ...params: string[]): View | null => {
    if (page === 'summary') {
        TaxActions.initSummary()
        return { name: 'TaxesSummary' }
    } else if (page === 'vat') {
        const month = params[0] || ''
        TaxActions.initVat()
        return { name: 'TaxesVat', month }
    }

    return null
}

const getReportView = (report?: string): View | null => {
    if (report === 'balance') {
        void ReportActions.initBalance()
        return { name: 'BalanceReport' }
    } else if (report === 'income') {
        void ReportActions.initIncome()
        return { name: 'IncomeReport' }
    } else if (report === 'cash-flow') {
        void ReportActions.initCashFlow()
        return { name: 'CashFlowReport' }
    } else if (report === 'turnover') {
        void ReportActions.initTurnover()
        return { name: 'TurnoverReport' }
    } else if (report === 'accounts') {
        void LoadActions.loadAccounts()
        return { name: 'ChartOfAccounts' }
    }

    return null
}

const getFinancialHistoryView = (page?: string, mode?: string): View | null => {
    if (page === 'balance') {
        const editMode = mode === 'edit'

        void CompanyActions.initInterimBalance()

        return { name: 'FinancialHistoryBalance', editMode }
    }

    return null
}

const getEntryView = (mode?: string, presetId?: string): View | null => {
    if (mode === 'list') {
        void EntryActions.initList()
        return { name: 'EntryList' }
    } else if (mode === 'add') {
        EntryActions.initForm(presetId)
        return { name: 'EntryEdit', presetId: presetId || '' }
    }

    return null
}

const getPasswordResetView = (params: string[]) => {
    const get = (viewParams: PasswordResetViewParams): View => ({
        name: 'PasswordReset',
        params: viewParams,
        allowUnauth: true,
        noMenu: true,
    })

    const [page] = params
    const secondParam = params[1] || ''

    if (page === 'init') {
        PasswordResetActions.initEmailForm()
        return get({ page })
    } else if (page === 'pending') {
        return get({ page, email: decodeURIComponent(secondParam) })
    } else if (page === 'change') {
        PasswordResetActions.initChangeForm()
        return get({ page, resetCode: secondParam })
    } else if (page === 'success') {
        return get({ page })
    }

    return null
}

const getView = (route: string): View | null => {
    const { session } = getState()
    const [viewName, ...params] = route.split('/')

    // Views that don't require authentication

    if (viewName === 'signup') {
        SignupActions.initSignup()
        return { name: 'SignUp', noMenu: true, allowUnauth: true }
    } else if (viewName === 'email-confirmation') {
        // email param is only used if status is 'pending'
        const status = params[0] || ''
        const email = params[1] || ''

        return {
            name: 'EmailConfirmation',
            noMenu: true,
            allowUnauth: true,
            status: status as EmailConfirmationStatus, // TODO
            email: decodeURIComponent(email),
        }
    } else if (viewName === 'password-reset') {
        return getPasswordResetView(params)
    }

    if (!session || viewName === 'blank') {
        return { name: 'Blank', noMenu: true }
    }

    // Views that require authentication

    if (viewName === 'blank-menu') {
        return { name: 'Blank', noMenu: false }
    } else if (viewName === '') {
        void initDefault()
        return { name: 'Default', noMenu: true }
    } else if (viewName === 'select-company') {
        return { name: 'SelectCompany', noMenu: true }
    } else if (viewName === 'dashboard') {
        void DashboardActions.init()
        return { name: 'Dashboard' }
    } else if (viewName === 'add-company') {
        CompanyActions.initAddForm()
        return { name: 'InitCompanyGeneral', noMenu: true, isNew: true }
    } else if (viewName === 'change-company') {
        const id = params[0] || ''
        return { name: 'ChangeCompany', noMenu: true, id }
    } else if (viewName === 'init-company') {
        return getInitCompanyView(...params)
    } else if (viewName === 'user-settings') {
        return getUserSettingsView(...params)
    } else if (viewName === 'expenses') {
        return getExpenseView(...params)
    } else if (viewName === 'invoices') {
        return getRevenueView(...params)
    } else if (viewName === 'credit-revenues') {
        return getCreditRevenueView(...params)
    } else if (viewName === 'labour-costs') {
        return getLabourCostView(...params)
    } else if (viewName === 'taxes') {
        return getTaxView(...params)
    } else if (viewName === 'reports') {
        return getReportView(...params)
    } else if (viewName === 'financial-history') {
        return getFinancialHistoryView(...params)
    } else if (viewName === 'entries') {
        return getEntryView(...params)
    } else if (viewName === 'settings') {
        const [page, ...pageParams] = params
        void SettingsActions.initForm(page || '', pageParams)
        return { name: 'Settings', page: (page || '') as SettingsPage, pageParams } // TODO
    } else if (route === 'command-log') {
        void CommandActions.initForm()
        return { name: 'CommandLog' }
    } else if (route === 'test') {
        return { name: 'Test' }
    } else if (route === 'texts') {
        return { name: 'Texts' }
    }

    return null
}

export const updateView = () => {
    emitNavigation()
    const route = getRoute()
    const view = getView(route)

    if (!view) {
        // TODO avoid error if valid route with no company selected, but invalid with company selected
        throw new Error('Unrecognized route: ' + route)
    }

    dispatch((draft) => (draft.view = view))
}
