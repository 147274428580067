import { getAccountType, Level1Accounts } from '../common/accounts'
import { getMinDay } from '../common/company-utils'
import { reportModes, reportPeriodModes } from '../common/enums'
import { clampToMonth, FiscalYear, getFiscalYear } from '../common/fiscal-year-utils'
import { sort } from '../common/sort'
import { Day } from '../common/time'
import { ApiAccount } from '../common/types/account'
import { ApiCompany, DayOfYear } from '../common/types/company'
import { ReportMode } from '../common/types/enums'
import { ChoiceOption, Input, InputValues } from '../common/types/inputs'
import { Period } from '../common/types/reports'
import { getDefaultAccountName } from './account-utils'
import { renderChoice } from './components/choice'
import { t } from './i18n'
import { inputs } from './inputs'

export interface Row<T> {
    isEmpty?: true
    number?: string
    label?: string
    amounts?: Map<T, number>
    level?: number
    topLevel?: boolean
    className?: string
    isLeaf?: boolean
    total?: number
}

export interface AccountNode<T> {
    number: string
    label: string
    amounts: Map<T, number>
    visible: boolean
    subAccounts: AccountNode<T>[]
}

const fiscalYearToPeriod = (fiscalYear: FiscalYear) => ({
    from: fiscalYear.start.ymd(),
    to: fiscalYear.end.ymd(),
})

export const addAmounts = <T>(from: Map<T, number>, to: Map<T, number>) => {
    for (const [key, amount] of from.entries()) {
        const value = to.get(key)
        to.set(key, (value === undefined ? 0 : value) + amount)
    }
}

const calculateAccountTotals = <T>(account: AccountNode<T>) => {
    for (const subAccount of account.subAccounts) {
        calculateAccountTotals(subAccount)
        addAmounts(subAccount.amounts, account.amounts)
        account.visible = account.visible || subAccount.visible
    }
}

export const anyNonZero = <T>(amounts: Map<T, number>) => {
    return [...amounts.values()].some((amount) => amount !== 0)
}

export const getAccountNodes = <T>(
    level1Accounts: Level1Accounts,
    getAmounts: (number: string) => Map<T, number>,
    isVisible: (amounts: Map<T, number>, number: string) => boolean,
    /** Only needed for custom accounts. Use an empty array if they are not needed. */
    accounts: ApiAccount[],
) => {
    const nodes: AccountNode<T>[] = []

    const createNode = (number: string, label?: string): AccountNode<T> => {
        const amounts = getAmounts(number)

        return {
            number, // Not used by code, but useful when debugging
            label: label || getDefaultAccountName(number),
            amounts,
            visible: isVisible(amounts, number),
            subAccounts: [],
        }
    }

    for (const level1 of Object.keys(level1Accounts)) {
        const number1 = level1
        const node1 = createNode(number1)
        nodes.push(node1)
        const level2Accounts = level1Accounts[level1]

        for (const level2 of Object.keys(level2Accounts)) {
            const number2 = number1 + '.' + level2
            const node2 = createNode(number2)
            node1.subAccounts.push(node2)
            const level3Accounts = level2Accounts[level2]

            for (const level3 of level3Accounts) {
                const number3 = number2 + '.' + level3
                const node3 = createNode(number3)
                node2.subAccounts.push(node3)

                node3.subAccounts.push(createNode(number3 + '.1'))

                if (accounts.length) {
                    const accountType = getAccountType(number3)

                    if (accountType) {
                        const filteredAccounts = accounts.filter(
                            (account) => account.type === accountType,
                        )
                        sort(filteredAccounts, (account) => account.number)

                        for (const account of filteredAccounts) {
                            node3.subAccounts.push(
                                createNode(number3 + '.' + account.number, account.name),
                            )
                        }
                    }
                }
            }
        }

        calculateAccountTotals(node1)
    }

    return nodes
}

export const addAccountRows = <T>(
    accountNodes: AccountNode<T>[],
    rows: Row<T>[],
    mode: ReportMode,
    level: number,
) => {
    for (const { number, label, visible, amounts, subAccounts } of accountNodes) {
        if (!visible) {
            continue
        }

        const isLeaf = level === 4 || (level === 3 && subAccounts.length === 1)
        let total = 0
        amounts.forEach((amount) => (total += amount))

        rows.push({ number, label, amounts, level, isLeaf, total })

        if (mode === reportModes.long && !isLeaf) {
            addAccountRows(subAccounts, rows, mode, level + 1)
        }
    }
}

export const getPeriods = (
    inputValues: InputValues,
    registrationDate: string,
    fiscalYearBegin: DayOfYear,
    longFirstYear: boolean,
    minDate: Day,
): Array<{ period: Period; label: string }> => {
    const periodInputs = inputs.reports.periods
    const mode = periodInputs.mode.get(inputValues)
    const today = Day.today()

    if (mode === reportPeriodModes.dates) {
        const from = periodInputs.dates.from.get(inputValues)
        const to = periodInputs.dates.to.get(inputValues)

        const label =
            from === to
                ? Day.fromYmd(from).dmy()
                : Day.fromYmd(from).dmy() + ' - ' + Day.fromYmd(to).dmy()

        return [{ period: { from, to }, label }]
    } else if (mode === reportPeriodModes.years) {
        const from = periodInputs.years.from.get(inputValues)
        const to = periodInputs.years.to.get(inputValues)

        const result = []
        const parsedTo = Day.fromYmd(to)
        let current = getFiscalYear(
            Day.fromYmd(from),
            registrationDate,
            fiscalYearBegin,
            longFirstYear,
        )

        while (current.start.isSameOrBefore(parsedTo)) {
            const startYear = current.start.year()
            const endYear = current.end.year()
            const label = startYear === endYear ? String(startYear) : startYear + '-' + endYear
            const startOfNext = current.end.addDays(1)

            if (current.start.isBefore(minDate)) {
                current.start = minDate
            }

            if (current.end.isAfter(today)) {
                current.end = today
            }

            if (current.end.isBefore(current.start)) {
                const message = 'Invalid period: ' + current.start.ymd() + ' - ' + current.end.ymd()

                throw new Error(message)
            }

            result.push({ period: fiscalYearToPeriod(current), label })
            current = getFiscalYear(startOfNext, registrationDate, fiscalYearBegin, longFirstYear)
        }

        return result
    } else if (mode === reportPeriodModes.months) {
        const from = periodInputs.months.from.get(inputValues)
        const to = periodInputs.months.to.get(inputValues)
        const dayOfMonth = Number(periodInputs.months.dayOfMonth.get(inputValues))

        if (isNaN(dayOfMonth) || dayOfMonth < 1 || dayOfMonth > 31) {
            // Note: for 29-31, if there aren't enough days in the month, we pick the last day of the month
            throw new Error(t.reports.periods.invalidDayOfMonth.get())
        }

        const parsedTo = Day.fromYm(to)
        let firstOfMonth = Day.fromYm(from)
        const result = []
        let done = false

        while (!done && firstOfMonth.isSameOrBefore(parsedTo)) {
            const nextFirstOfMonth = firstOfMonth.addMonths(1)
            let monthFrom = clampToMonth(firstOfMonth.year(), firstOfMonth.month(), dayOfMonth)

            if (monthFrom.isAfter(today)) {
                break
            }

            if (monthFrom.isBefore(minDate)) {
                monthFrom = minDate
            }

            let monthTo = clampToMonth(
                nextFirstOfMonth.year(),
                nextFirstOfMonth.month(),
                dayOfMonth,
            ).addDays(-1)

            if (monthTo.isAfter(today)) {
                monthTo = today
                done = true
            }

            const period = { from: monthFrom.ymd(), to: monthTo.ymd() }

            const label =
                dayOfMonth === 1 ? monthFrom.shortMonth() : monthFrom.dmy() + ' - ' + monthTo.dmy()

            result.push({ period, label })
            firstOfMonth = nextFirstOfMonth
        }

        return result
    } else if (mode === reportPeriodModes.days) {
        const from = periodInputs.days.from.get(inputValues)
        const to = periodInputs.days.to.get(inputValues)

        const result = []
        let current = Day.fromYmd(from)
        const parsedTo = Day.fromYmd(to)

        while (current.isSameOrBefore(parsedTo)) {
            const ymd = current.ymd()
            result.push({ period: { from: ymd, to: ymd }, label: current.dmy() })
            current = current.addDays(1)
        }

        return result
    } else {
        throw new Error('Unexpected report period mode: ' + mode)
    }
}

export const getSinglePeriod = (
    inputValues: InputValues,
    registrationDate: string,
    fiscalYearBegin: DayOfYear,
    longFirstYear: boolean,
    minDate: Day,
): Period => {
    const periodInputs = inputs.reports.periods
    const mode = periodInputs.mode.get(inputValues)
    const today = Day.today()

    if (mode === reportPeriodModes.dates) {
        const from = periodInputs.dates.from.get(inputValues)
        const to = periodInputs.dates.to.get(inputValues)
        return { from, to }
    } else if (mode === reportPeriodModes.years) {
        const ymd = periodInputs.years.from.get(inputValues)

        const fiscalYear = getFiscalYear(
            Day.fromYmd(ymd),
            registrationDate,
            fiscalYearBegin,
            longFirstYear,
        )

        if (fiscalYear.start.isBefore(minDate)) {
            fiscalYear.start = minDate
        }

        if (fiscalYear.end.isAfter(today)) {
            fiscalYear.end = today
        }

        if (fiscalYear.end.isBefore(fiscalYear.start)) {
            const message =
                'Invalid period: ' + fiscalYear.start.ymd() + ' - ' + fiscalYear.end.ymd()

            throw new Error(message)
        }

        return fiscalYearToPeriod(fiscalYear)
    } else if (mode === reportPeriodModes.months) {
        const month = periodInputs.months.from.get(inputValues)
        const dayOfMonth = Number(periodInputs.months.dayOfMonth.get(inputValues))

        if (isNaN(dayOfMonth) || dayOfMonth < 1 || dayOfMonth > 31) {
            // Note: for 29-31, if there aren't enough days in the month, we pick the last day of the month
            throw new Error(t.reports.periods.invalidDayOfMonth.get())
        }

        const firstOfMonth = Day.fromYm(month)
        const nextFirstOfMonth = firstOfMonth.addMonths(1)
        let monthFrom = clampToMonth(firstOfMonth.year(), firstOfMonth.month(), dayOfMonth)

        if (monthFrom.isAfter(today)) {
            monthFrom = today
        }

        if (monthFrom.isBefore(minDate)) {
            monthFrom = minDate
        }

        let monthTo = clampToMonth(
            nextFirstOfMonth.year(),
            nextFirstOfMonth.month(),
            dayOfMonth,
        ).addDays(-1)

        if (monthTo.isAfter(today)) {
            monthTo = today
        }

        return { from: monthFrom.ymd(), to: monthTo.ymd() }
    } else {
        throw new Error('Unexpected single report period mode: ' + mode)
    }
}

export const getYearOptions = (
    interimDate: string,
    registrationDate: string,
    fiscalYearBegin: DayOfYear,
    longFirstYear: boolean,
) => {
    const options: ChoiceOption<string>[] = []

    const minDay = getMinDay(interimDate)
    let current = getFiscalYear(minDay, registrationDate, fiscalYearBegin, longFirstYear)
    const today = Day.today()

    while (current.start.isSameOrBefore(today)) {
        const startYear = current.start.year()
        const endYear = current.end.year()
        const label = startYear === endYear ? String(startYear) : startYear + '-' + endYear
        options.push({ id: current.start.ymd(), label })

        const startOfNext = current.end.addDays(1)
        current = getFiscalYear(startOfNext, registrationDate, fiscalYearBegin, longFirstYear)
    }

    return options
}

export const getYearOptionsForCompany = (company: ApiCompany) => {
    const { interimDate, registrationDate, fiscalYearBegin, longFirstYear } = company
    return getYearOptions(interimDate, registrationDate, fiscalYearBegin, longFirstYear)
}

export const renderYearChoice = (
    input: Input<string>,
    inputValues: InputValues,
    company: ApiCompany,
) =>
    renderChoice({
        type: 'dropdown',
        input,
        inputValues,
        options: getYearOptionsForCompany(company),
        forceSelection: true,
    })

export const getBalanceMinDate = (company: ApiCompany) => {
    // Unlike reports with periods, we don't add a day here.
    // TODO exception for opening date
    return Day.fromYmd(company.interimDate)
}

export const getPeriodMinDate = (company: ApiCompany) => {
    // Unlike with balance, the interim date itself is not valid.
    // The earliest possible date in any period is the day after.
    // TODO exception for period from opening date to interim date
    // (not needed anymore? Just inline the function?)
    return getMinDay(company.interimDate)
}
