import shortid from 'shortid'

const idQueue: string[] = []

export const generateId = (): string => {
    if (idQueue.length) {
        // For tests: remove and return first element
        return idQueue.shift()!
    } else {
        // Outside tests, generate random ID
        return shortid()
    }
}

// For tests
export const enqueueId = (id: string, onReset: (callback: () => void) => any) => {
    if (!idQueue.length) {
        onReset(() => {
            if (idQueue.length) {
                throw new Error('Unused ids in queue')
            }
        })
    }

    idQueue.push(id)
}
