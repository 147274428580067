import { AccountType, ExpenseAccountType, RevenueAccountType } from './types/account'
import { CommandName } from './types/api'
import {
    AssetChangeMode,
    AssetType,
    BalanceDateMode,
    CalculationMode,
    ExpenseType,
    LabourPaymentType,
    LoginMode,
    MoneyForm,
    ReportMode,
    ReportPeriodMode,
    TransactionState,
    Unit,
} from './types/enums'
import { ExpenseItemType } from './types/expense'
import { RevenueItemType } from './types/invoice'
import { LogAction } from './types/log'
import { values } from './values'

// TODO alphabetical order?

export const balanceDateModes: { [B in BalanceDateMode]: B } = {
    dates: 'dates',
    years: 'years',
    months: 'months',
}

export const balanceDateModesArray: BalanceDateMode[] = [
    balanceDateModes.dates,
    balanceDateModes.years,
    balanceDateModes.months,
]

export const logActions: { [L in LogAction]: L } = {
    addFile: 'addFile',
    create: 'create',
    update: 'update',
    confirm: 'confirm',
    payment: 'payment',
    paymentRemove: 'paymentRemove',
    paymentNet: 'paymentNet',
    paymentNetRemove: 'paymentNetRemove',
    paymentTax: 'paymentTax',
    paymentTaxRemove: 'paymentTaxRemove',
    removeFile: 'removeFile',
    valueChangeCreate: 'valueChangeCreate',
    valueChangeRemove: 'valueChangeRemove',
    valueChangeConfirm: 'valueChangeConfirm',
    stockChangeCreate: 'stockChangeCreate',
    stockChangeRemove: 'stockChangeRemove',
    stockChangeConfirm: 'stockChangeConfirm',
}

// TODO rename to EveryPayStatus and create a simplified local enum?
export enum CardPaymentStatus {
    initial = 'initial',
    waiting_for_sca = 'waiting_for_sca',
    sent_for_processing = 'sent_for_processing',
    waiting_for_3ds_response = 'waiting_for_3ds_response',
    settled = 'settled',
    failed = 'failed',
    abandoned = 'abandoned',
    voided = 'voided',
    refunded = 'refunded',
    chargebacked = 'chargebacked',
}

export const cardPaymentStatuses = values(CardPaymentStatus)

export enum CompanyRole {
    manager = 'manager',
    base = 'base',
    limited = 'limited',

    /**
     * Can access everything that a manager can, plus some testing tools.
     * To be used only in test companies, not real ones.
     */
    test = 'test',
}

export const companyRolesArray = values(CompanyRole)

export enum CompanyStatus {
    /**
     * Initial data is being entered and the account has not been paid for.
     * The company can only be accessed by the one user who created it
     * and is not yet ready for regular use.
     */
    init = 'init',

    /**
     * First card payment has been successfully processed.
     * The company can be accessible to several users and is ready for regular use.
     */
    active = 'active',

    /**
     * The company has been archived (effectively deleted, but can still be easily restored).
     * It is not accessible to regular users.
     */
    archived = 'archived',
}

export const companyStatuses = values(CompanyStatus)

export enum EmailConfirmationStatus {
    pending = 'pending',
    success = 'success',
    failed = 'failed',
    duplicate = 'duplicate',
    error = 'error',
}

// Will probably have more values in the future, for example 'support'
export enum GlobalRole {
    dev = 'dev',
}
export const globalRolesArray = values(GlobalRole)

export const revenueItemTypes: { [I in RevenueItemType]: I } = {
    service: 'service',
    goods: 'goods',
}

export const revenueItemTypesArray: RevenueItemType[] = [
    revenueItemTypes.service,
    revenueItemTypes.goods,
]

export const revenueAccountTypes: { [I in 'service' | 'goods']: RevenueAccountType } = {
    service: 'income-service',
    goods: 'income-goods',
}

const revenueAccountTypesArray: RevenueAccountType[] = [
    revenueAccountTypes.service,
    revenueAccountTypes.goods,
]

export const expenseItemTypes: {
    [E in 'goodsExpense' | 'goodsStock' | 'general']: ExpenseItemType
} = {
    goodsExpense: 'goods-expense',
    goodsStock: 'goods-stock',
    general: 'general',
}

export const expenseItemTypesArray: ExpenseItemType[] = [
    expenseItemTypes.goodsExpense,
    expenseItemTypes.goodsStock,
    expenseItemTypes.general,
]

export const calculationModes: { [C in CalculationMode]: C } = {
    automatic: 'automatic',
    manual: 'manual',
}

export const calculationModesArray: CalculationMode[] = [
    calculationModes.automatic,
    calculationModes.manual,
]

export const expenseAccountTypes: { [E in 'goods' | 'general']: ExpenseAccountType } = {
    goods: 'expense-goods',
    general: 'expense-general',
}

export const expenseAccountTypesArray: ExpenseAccountType[] = [
    expenseAccountTypes.goods,
    expenseAccountTypes.general,
]

export const accountTypesArray: AccountType[] = [
    ...revenueAccountTypesArray,
    ...expenseAccountTypesArray,
]

export const expenseTypes: { [E in ExpenseType]: E } = { regular: 'regular', asset: 'asset' }
export const expenseTypesArray: ExpenseType[] = [expenseTypes.regular, expenseTypes.asset]

export const assetTypes: { [A in AssetType]: A } = {
    device: 'device',
    land: 'land',
    building: 'building',
    otherMaterial: 'otherMaterial',
    software: 'software',
    license: 'license',
    otherImmaterial: 'otherImmaterial',
}

export const assetTypesArray: AssetType[] = [
    assetTypes.device,
    assetTypes.land,
    assetTypes.building,
    assetTypes.otherMaterial,
    assetTypes.software,
    assetTypes.license,
    assetTypes.otherImmaterial,
]

export const assetChangeModes: { [A in AssetChangeMode]: A } = { residual: 'residual', eol: 'eol' }
export const assetChangeModesArray = [assetChangeModes.residual, assetChangeModes.eol]

export const labourPaymentTypes: { [L in LabourPaymentType]: L } = { net: 'net', tax: 'tax' }

export const labourPaymentTypesArray: LabourPaymentType[] = [
    labourPaymentTypes.net,
    labourPaymentTypes.tax,
]

export const loginModes: { [L in LoginMode]: L } = { public: 'public', private: 'private' }
export const loginModesArray: LoginMode[] = [loginModes.public, loginModes.private]

export const moneyForms: { [M in MoneyForm]: M } = { cash: 'cash', bank: 'bank' }
export const moneyFormsArray: MoneyForm[] = [moneyForms.cash, moneyForms.bank]

export const reportModes: { [R in ReportMode]: R } = { short: 'short', long: 'long' }

export const reportPeriodModes: { [R in ReportPeriodMode]: R } = {
    dates: 'dates',
    years: 'years',
    months: 'months',
    days: 'days',
}

export const reportPeriodModesArray = [
    reportPeriodModes.dates,
    reportPeriodModes.years,
    reportPeriodModes.months,
    reportPeriodModes.days,
]

export const units: { [U in Unit]: U } = {
    pieces: 'pieces',
    h: 'h',
    kg: 'kg',
    m: 'm',
    l: 'l',
}

export const unitsArray: Unit[] = [units.pieces, units.h, units.kg, units.m, units.l]

export const transactionStates: { [T in TransactionState]: T } = {
    executing: 'executing',
    rollingBack: 'rollingBack',
    rolledBack: 'rolledBack',
    rollbackFailed: 'rollbackFailed',
}

export const commandNamesArray = values(CommandName)

export enum UrlCommand {
    confirmUser = '/confirm-user',
    everypayCallback = '/everypay/callback',
    everypayRedirect = '/everypay/redirect',
}

export const urlCommands = values(UrlCommand)

export enum EntryItemType {
    credit = 'credit',
    debit = 'debit',
}

export const entryItemTypes = values(EntryItemType)

export enum InitDateType {
    today = 'today',
    startOfMonth = 'startOfMonth',
    startOfFiscalYear = 'startOfFiscalYear',
}

export const initDateTypes = values(InitDateType)
