import React from 'react'

import { Overrides, ValidationError as TValidationError } from '../../common/types/errors'
import { t } from '../i18n'

export interface ValidationErrorProps {
    error: TValidationError
    overrides?: Overrides
}

const getMessage = (error: TValidationError, overrides: Overrides | undefined) => {
    const { type } = error

    if (overrides && type in overrides) {
        const override = overrides[type]

        if (typeof override === 'string') {
            return override
        } else {
            const message = override(error)

            if (message) {
                return message
            }

            // If return value is falsy, proceed with default message
        }
    }

    // TODO enum for validation error types?
    const translation = t.validation[type as keyof typeof t.validation]
    // TODO report warning if missing
    return translation ? translation.get() : 'Error: ' + type
}

export const ValidationError = ({ error, overrides }: ValidationErrorProps) => {
    return React.createElement(
        'div',
        { className: 'validation-error' },
        getMessage(error, overrides),
    )
}
