import React, { FC, Fragment } from 'react'

import { Day } from '../../../common/time'
import { ApiCompany } from '../../../common/types/company'
import { ValidationError } from '../../../common/types/errors'
import { InputValues } from '../../../common/types/inputs'
import { Processes } from '../../../common/types/processes'
import { assertViewName } from '../../assert-view-name'
import { t } from '../../i18n'
import { inputs } from '../../inputs'
import { getCompany } from '../../state/company-actions'
import { RootData } from '../../state/root-data'
import { SAVE_VAT_PROCESS, saveVat } from '../../state/settings-actions'
import { valErr } from '../../val-err'
import { BackLink } from '../back-link'
import { Button } from '../button'
import { LoadingIcon } from '../loading-icon'
import { VatChoice } from '../vat-choice'
import { InputRow } from './input-row'

const renderBackLink = (editMode: boolean) => (editMode ? React.createElement(BackLink) : null)

const renderVatPayerStatus = (
    editMode: boolean,
    company: ApiCompany,
    inputValues: InputValues,
    valErrors?: ValidationError[],
) => {
    const { vatPeriods } = company

    if (vatPeriods.length) {
        const startDate = Day.fromYmd(vatPeriods[0].from)
        const { vatId, vatPercentage } = inputs.settings.vat

        return React.createElement(
            'div',
            null,
            React.createElement(
                'div',
                { className: 'text-bold' },
                t.companyIsVatPayer.since.get(startDate.dmy()),
            ),
            React.createElement(InputRow, {
                editMode,
                label: t.business.vatId.get(),
                input: vatId,
                inputValues,
            }),
            valErr(valErrors, 'update.vatId'),
            React.createElement(
                'div',
                { className: 'settings__input-row' },
                React.createElement('span', { className: 'settings__label' }, t.vat.get(), ': '),
                React.createElement(VatChoice, { editMode, input: vatPercentage, inputValues }),
            ),
            valErr(valErrors, 'update.vatPercentage'),
        )
    } else {
        return React.createElement('div', null, t.companyIsNotVatPayer.get())
    }
}

const renderButton = (vatPayer: boolean, editMode: boolean, processes: Processes) => {
    if (!vatPayer) {
        return null
    }

    if (editMode) {
        return React.createElement(Button, {
            className: 'button--primary',
            text: t.save.get(),
            onClick: saveVat,
            processes,
            processName: SAVE_VAT_PROCESS,
        })
    } else {
        return React.createElement(
            'a',
            {
                href: '#/settings/vat/edit',
                className: 'button button--primary',
            },
            t.edit.get(),
        )
    }
}

export const VatSettings: FC<RootData> = (rootData) => {
    const { companyData, inputValues, processes, session, settingsData, validationErrors, view } =
        rootData

    if (!companyData.companies || !settingsData.settings) {
        return React.createElement(LoadingIcon, { color: 'black' })
    } else {
        const { pageParams } = assertViewName(view, 'Settings')
        const editMode = pageParams[0] === 'edit'
        const company = getCompany(companyData, session)
        const valErrors = validationErrors[SAVE_VAT_PROCESS]

        return React.createElement(
            Fragment,
            null,
            React.createElement(
                'div',
                null,
                renderBackLink(editMode),
                React.createElement('h1', { className: 'title' }, t.vat.get()),
                renderVatPayerStatus(editMode, company, inputValues, valErrors),
            ),
            React.createElement(
                'div',
                null,
                renderButton(company.vatPeriods.length > 0, editMode, processes),
            ),
        )
    }
}
