import React, { FC } from 'react'

import { Processes } from '../../../common/types/processes'
import { t } from '../../i18n'
import { setRoute } from '../../route-utils'
import { RootData } from '../../state/root-data'
import { loadLogoInputFile, REMOVE_LOGO_PROCESS, removeLogo } from '../../state/settings-actions'
import { Button } from '../button'
import { LoadingIcon } from '../loading-icon'
import { Logo } from './logo'
import { LogoInput } from './logo-input'
import { LogoNotes } from './logo-notes'

const loadInputFile = async (file: File) => {
    setRoute('#/settings/logo/edit')
    await loadLogoInputFile(file)
}

const renderDeleteButton = (processes: Processes) =>
    React.createElement(Button, {
        className: 'button--secondary',
        onClick: () => (confirm(t.confirm.removeLogo.get()) ? removeLogo() : null),
        text: t.remove.get(),
        processes,
        processName: REMOVE_LOGO_PROCESS,
        domId: 'remove',
    })

const renderButtons = (processes: Processes, logoUrl?: string) => {
    const deleteButton = logoUrl ? renderDeleteButton(processes) : null

    return React.createElement(
        'div',
        { className: 'top-margin' },
        React.createElement(LogoInput, { onSelect: loadInputFile }),
        ' ',
        deleteButton,
    )
}

export const LogoView: FC<RootData> = (rootData) => {
    const { settingsData, processes } = rootData
    const { settings } = settingsData

    if (!settings) {
        return React.createElement(LoadingIcon, { color: 'black' })
    } else {
        const { logoUrl } = settings

        return React.createElement(
            'div',
            null,
            React.createElement(
                'h1',
                { className: 'title' },
                t.settings.logo.current.get(),
                React.createElement(
                    'span',
                    { className: 'title__sub-title' },
                    React.createElement(LogoNotes),
                ),
            ),
            React.createElement(
                'div',
                { className: 'top-margin' },
                React.createElement(Logo, { url: logoUrl }),
            ),
            renderButtons(processes, logoUrl),
        )
    }
}
