import React, { FC } from 'react'

import { InputValues } from '../../../common/types/inputs'
import { Processes } from '../../../common/types/processes'
import { t } from '../../i18n'
import { inputs } from '../../inputs'
import {
    resetSearchByNumberForm,
    SEARCH_PROCESS,
    searchByNumber,
} from '../../state/revenue-actions'
import { Button } from '../button'
import { Input } from '../input'
import { MenuToggle } from '../menu-toggle'

export interface RevenueSearchPanelProps {
    fromResult: boolean
    inputValues: InputValues
    processes: Processes
}

const input = inputs.invoice.searchNumber

const renderMenuToggle = (fromResult: boolean) =>
    fromResult ? React.createElement(MenuToggle, { onClick: resetSearchByNumberForm }) : null

export const RevenueSearchPanel: FC<RevenueSearchPanelProps> = ({
    fromResult,
    inputValues,
    processes,
}) =>
    React.createElement(
        'div',
        { className: 'sidebar invoice-search open' },
        renderMenuToggle(fromResult),
        React.createElement(
            'h1',
            { className: 'sidebar__title-with-icon title' },
            React.createElement('img', {
                src: '/menu/incomes.svg',
                className: 'sidebar__title-icon',
            }),
            React.createElement('div', null, t.revenues.searchByNumber.get()),
        ),
        React.createElement(
            'div',
            { className: 'text-center' },
            React.createElement(Input, {
                inputValues,
                input,
                onEnter: searchByNumber,
                placeholder: t.invoices.number.get(),
                className: 'input input--white sidebar__input invoice-search__input',
            }),
        ),
        React.createElement(
            'div',
            { className: 'text-center top-margin extra' },
            React.createElement(Button, {
                text: t.search.get(),
                onClick: searchByNumber,
                processes,
                processName: SEARCH_PROCESS,
                domId: 'search',
                disabled: input.get(inputValues) === '',
                className: 'button--white invoice-search__save-button',
            }),
        ),
    )
