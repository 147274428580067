import classnames from 'classnames'
import numeral from 'numeral'
import React, { FC, Fragment } from 'react'
import DayPicker, { DayPickerProps } from 'react-day-picker'
import MomentLocaleUtils from 'react-day-picker/moment'

import { Day } from '../../common/time'
import { CompanyData } from '../../common/types/company'
import { DashboardData } from '../../common/types/dashboard'
import { InputValues } from '../../common/types/inputs'
import { Note } from '../../common/types/note'
import { Processes } from '../../common/types/processes'
import { ApiSession } from '../../common/types/session'
import { t } from '../i18n'
import { inputs } from '../inputs'
import { getCompany } from '../state/company-actions'
import { SAVE_NOTE_PROCESS, saveNote, setDate } from '../state/dashboard-actions'
import { RootData } from '../state/root-data'
import { Button } from './button'
import { Input } from './input'
import { LoadingIcon } from './loading-icon'

const monthInput = inputs.dashboard.month

const classNames = {
    // Removed many of DayPicker's default classes, added some dashboard-specific ones
    container: '',
    wrapper: 'dashboard__day-picker-wrapper',
    interactionDisabled: '',
    months: 'DayPicker-Months',
    month: '',

    navBar: '',
    navButtonPrev: '',
    navButtonNext: '',
    navButtonInteractionDisabled: '',

    caption: '',
    weekdays: 'DayPicker-Weekdays',
    weekdaysRow: 'DayPicker-WeekdaysRow',
    weekday: 'DayPicker-Weekday dashboard__weekday',
    body: 'DayPicker-Body',
    week: 'DayPicker-Week',
    weekNumber: '',
    day: 'DayPicker-Day dashboard__day',
    footer: '',
    todayButton: '',

    today: 'dashboard__day--today',
    selected: 'dashboard__day--selected',
    disabled: 'dashboard__day--disabled',
    outside: 'dashboard__day--outside',
}

const renderVatWarningPanel = () =>
    React.createElement(
        'div',
        { className: 'dashboard__vat-warning dashboard-panel dashboard-panel--titled' },
        React.createElement('div', { className: 'dashboard-panel__title' }, t.attention.get()),
        React.createElement(
            'div',
            { className: 'dashboard-panel__content dashboard-panel__content--text' },
            t.dashboard.vatWarning.get(),
        ),
        React.createElement(
            'a',
            {
                href:
                    'https://www.emta.ee/et/ariklient/tulu-kulu-kaive-kasum/kaibemaksuseaduse-selgitused' +
                    '/maksukohustuslasena-registreerimine-ja',
                className: 'button button--transparent',
                target: '_blank',
                rel: 'noopener nofollow',
            },
            t.view.get(),
        ),
    )

const renderDatePanel = (date: string) => {
    const parsedDate = Day.fromYmd(date)

    return React.createElement(
        'div',
        { className: 'dashboard__date dashboard-panel dashboard-panel--titled' },
        React.createElement('div', { className: 'dashboard-panel__title' }, parsedDate.monthName()),
        React.createElement(
            'div',
            { className: 'dashboard-panel__content dashboard-panel__content--numeric' },
            parsedDate.dayOfMonth(),
        ),
    )
}

const renderLoadingIcon = () =>
    React.createElement(
        'div',
        { className: 'dashboard-panel__content dashboard-panel__content--centered' },
        React.createElement(LoadingIcon, { color: 'white', size: 50 }),
    )

const renderNote = (note: Note | null, inputValues: InputValues, processes: Processes) => {
    if (!note) {
        return renderLoadingIcon()
    } else {
        return React.createElement(
            Fragment,
            null,
            React.createElement(Input, {
                input: inputs.note.text,
                inputValues,
                type: 'multiline',
                afterChange: () => inputs.note.hasChanged.set(true),
                className: 'dashboard-panel__notes-input',
                disabled: processes.has(SAVE_NOTE_PROCESS),
            }),
            React.createElement(
                'div',
                { className: 'text-center' },
                React.createElement(Button, {
                    text: t.save.get(),
                    className: 'button--transparent dashboard__wide-button',
                    onClick: saveNote,
                    disabled: !inputs.note.hasChanged.get(inputValues),
                    processes,
                    processName: SAVE_NOTE_PROCESS,
                    loadingColor: 'white',
                }),
            ),
        )
    }
}

const renderNotesPanel = (note: Note | null, inputValues: InputValues, processes: Processes) =>
    React.createElement(
        'div',
        { className: 'dashboard__notes dashboard-panel dashboard-panel--titled' },
        React.createElement(
            'div',
            { className: 'dashboard-panel__title' },
            t.dashboard.notes.get(),
        ),
        renderNote(note, inputValues, processes),
    )

const formatDashboardAmount = (amount: number) => numeral(amount).format('0,0$')

const renderNumber = (number: number | undefined) => {
    if (number === undefined) {
        return renderLoadingIcon()
    }

    return React.createElement(
        'div',
        { className: 'dashboard-panel__content dashboard-panel__content--numeric' },
        formatDashboardAmount(number),
    )
}

const renderRevenue = (loaded: DashboardData | null) => {
    if (!loaded) {
        return renderLoadingIcon()
    } else if (loaded.hasReportData) {
        return React.createElement(
            Fragment,
            null,
            renderNumber(loaded.revenue),
            React.createElement(
                'a',
                { href: '#/reports/income', className: 'button button--transparent' },
                t.view.get(),
            ),
        )
    } else {
        return React.createElement(
            Fragment,
            null,
            React.createElement(
                'div',
                {
                    className:
                        'dashboard__revenue-no-data dashboard-panel__content dashboard-panel__content--text',
                },
                t.reports.noData.get(),
            ),
            React.createElement(
                'a',
                {
                    href: '#/financial-history/balance/edit',
                    className: 'button button--transparent',
                },
                t.reports.noData.enter.get(),
            ),
        )
    }
}

const onDayClick: DayPickerProps['onDayClick'] = async (day, modifiers) => {
    if (!modifiers[classNames.disabled]) {
        await setDate(Day.fromDate(day))
    }
}

const renderMonthLink = (text: string, enabled: boolean, onClick: () => void) => {
    const className = 'dashboard__link dashboard__link--nav'

    if (enabled) {
        return React.createElement('a', { onClick, className }, text)
    } else {
        return React.createElement(
            'span',
            { className: classnames(className, 'dashboard__link--disabled') },
            text,
        )
    }
}

const renderCalendarHeader = (firstOfMonth: Day, minDate: Day, maxDate: Day) => {
    const canSelectPrevious = firstOfMonth.isAfter(minDate)

    const selectPreviousMonth = () => {
        monthInput.set(firstOfMonth.firstOfPreviousMonth().ym())
    }

    const firstOfNextMonth = firstOfMonth.firstOfNextMonth()
    const canSelectNext = firstOfNextMonth.isSameOrBefore(maxDate)
    const selectNextMonth = () => monthInput.set(firstOfNextMonth.ym())

    return React.createElement(
        'div',
        { className: 'dashboard__calendar-header' },
        renderMonthLink('<', canSelectPrevious, selectPreviousMonth),
        firstOfMonth.longMonth(),
        renderMonthLink('>', canSelectNext, selectNextMonth),
    )
}

const renderCalendarPanel = (
    selectedDate: string,
    disabled: boolean,
    inputValues: InputValues,
    companyData: CompanyData,
    session: ApiSession | null,
) => {
    if (!companyData.companies) {
        return React.createElement(
            'div',
            { className: 'dashboard__calendar dashboard__calendar--loading dashboard-panel' },
            renderLoadingIcon(),
        )
    }

    const company = getCompany(companyData, session)
    const minDate = Day.fromYmd(company.interimDate)
    const maxDate = Day.today()

    const monthString = monthInput.get(inputValues)
    const firstOfMonth = Day.fromYm(monthString)

    return React.createElement(
        'div',
        { className: 'dashboard__calendar dashboard-panel' },
        React.createElement(
            'div',
            { className: 'dashboard-panel__content' },
            React.createElement<DayPickerProps, any, any>(DayPicker, {
                canChangeMonth: true,
                captionElement: () => renderCalendarHeader(firstOfMonth, minDate, maxDate),
                classNames,
                disabledDays: { before: minDate.toDate(), after: maxDate.toDate() },
                fromMonth: minDate.toDate(),
                locale: 'et',
                localeUtils: MomentLocaleUtils,
                modifiers: { [classNames.today]: Day.today().toDate() },
                month: firstOfMonth.toDate(),
                navbarElement: () => null,
                onDayClick: disabled ? undefined : onDayClick,
                selectedDays: Day.fromYmd(selectedDate).toDate(),
                toMonth: maxDate.toDate(),
            }),
        ),
    )
}

export const Dashboard: FC<RootData> = (rootData) => {
    const {
        companyData,
        dashboardData: { date, loaded, note },
        inputValues,
        processes,
        session,
    } = rootData

    const showVatWarning = loaded ? loaded.showVatWarning : false

    return React.createElement(
        'div',
        { className: 'dashboard' },
        showVatWarning ? renderVatWarningPanel() : renderDatePanel(date),
        React.createElement(
            'div',
            { className: 'dashboard__revenue dashboard-panel dashboard-panel--titled' },
            React.createElement(
                'div',
                { className: 'dashboard-panel__title' },
                t.dashboard.revenue.get(),
            ),
            renderRevenue(loaded),
        ),
        renderNotesPanel(note, inputValues, processes),
        React.createElement(
            'div',
            { className: 'dashboard__add-new dashboard-panel' },
            React.createElement(
                'a',
                { href: '#/invoices/add', className: 'dashboard__add-new-link' },
                React.createElement(
                    'div',
                    { className: 'dashboard__add-new-text' },
                    t.addNew.get(),
                ),
                React.createElement('img', {
                    className: 'dashboard__add-new-icon',
                    src: '/dashboard/revenue.svg',
                }),
                React.createElement(
                    'div',
                    { className: 'dashboard__add-new-text' },
                    t.revenues.invoice.get(),
                ),
            ),
            React.createElement(
                'a',
                { href: '#/expenses/add/regular', className: 'dashboard__add-new-link' },
                React.createElement(
                    'div',
                    { className: 'dashboard__add-new-text' },
                    t.addNew.get(),
                ),
                React.createElement('img', {
                    className: 'dashboard__add-new-icon',
                    src: '/dashboard/expense.svg',
                }),
                React.createElement(
                    'div',
                    { className: 'dashboard__add-new-text' },
                    t.expenses.invoice.get(),
                ),
            ),
            React.createElement(
                'a',
                { href: '#/expenses/add/asset', className: 'dashboard__add-new-link' },
                React.createElement(
                    'div',
                    { className: 'dashboard__add-new-text' },
                    t.addNew.get(),
                ),
                React.createElement('img', {
                    className: 'dashboard__add-new-icon',
                    src: '/dashboard/asset.svg',
                }),
                React.createElement('div', { className: 'dashboard__add-new-text' }, t.asset.get()),
            ),
        ),
        React.createElement(
            'div',
            { className: 'dashboard__unpaid-revenues dashboard-panel dashboard-panel--titled' },
            React.createElement(
                'div',
                { className: 'dashboard-panel__title' },
                t.revenues.unpaidInvoices.get(),
            ),
            renderNumber(loaded ? loaded.unpaidRevenues : undefined),
        ),
        React.createElement(
            'div',
            { className: 'dashboard__unpaid-expenses dashboard-panel dashboard-panel--titled' },
            React.createElement(
                'div',
                { className: 'dashboard-panel__title' },
                t.expenses.unpaidInvoices.get(),
            ),
            renderNumber(loaded ? loaded.unpaidExpenses : undefined),
        ),
        renderCalendarPanel(date, !loaded, inputValues, companyData, session),
    )
}
