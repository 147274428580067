import { xml } from '../common/xml'
import { NS } from './excel/constants'
import { createStyleManager, getStylesheetXml } from './excel/style'
import { ExcelSpec } from './excel/types'
import { getWorksheetXml } from './excel/worksheet'
import { clearProgress } from './state/progress-actions'
import { createZipper, Zipper } from './zip-utils'

const RELS = {
    doc: NS.docRels + '/officeDocument',
    worksheet: NS.docRels + '/worksheet',
    styles: NS.docRels + '/styles',
}

const SSML = 'application/vnd.openxmlformats-officedocument.spreadsheetml'

const FILES = {
    workbook: 'xl/workbook.xml',
    sheetLocal: 'worksheets/sheet1.xml',
    sheet: 'TEMP',
    stylesLocal: 'styles.xml',
    styles: 'TEMP',
}

FILES.sheet = 'xl/' + FILES.sheetLocal
FILES.styles = 'xl/' + FILES.stylesLocal

const addBaseFiles = (zipper: Zipper) => {
    zipper.addFile(
        '[Content_Types].xml',
        // prettier-ignore
        xml('Types')
            .a('xmlns', NS.contentTypes)
            .e('Default')
                .a('Extension', 'rels')
                .a('ContentType', 'application/vnd.openxmlformats-package.relationships+xml')
            .done()
            .e('Default')
                .a('Extension', 'xml')
                .a('ContentType', 'application/xml')
            .done()
            .e('Override')
                .a('PartName', '/' + FILES.workbook)
                .a('ContentType', SSML + '.sheet.main+xml')
            .done()
            .e('Override')
                .a('PartName', '/' + FILES.sheet)
                .a('ContentType', SSML + '.worksheet+xml')
            .done()
            .e('Override')
                .a('PartName', '/' + FILES.styles)
                .a('ContentType', SSML + '.styles+xml')
            .done()
        .done(),
    )

    zipper.addFile(
        '_rels/.rels',
        // prettier-ignore
        xml('Relationships')
            .a('xmlns', NS.pkgRels)
            .e('Relationship')
                .a('Id', 'rId1').a('Target', FILES.workbook).a('Type', RELS.doc)
            .done()
        .done(),
    )

    zipper.addFile(
        FILES.workbook,
        // prettier-ignore
        xml('workbook')
            .a('xmlns', NS.main)
            .a('xmlns:r', NS.docRels)
            .e('sheets')
                .e('sheet').a('name', 'Sheet1').a('sheetId', 1).a('r:id', 'rId1').done()
            .done()
        .done(),
    )

    zipper.addFile(
        'xl/_rels/workbook.xml.rels',
        // prettier-ignore
        xml('Relationships')
            .a('xmlns', NS.pkgRels)
            .e('Relationship')
                .a('Id', 'rId1').a('Target', FILES.sheetLocal).a('Type', RELS.worksheet)
            .done()
            .e('Relationship')
                .a('Id', 'rId2').a('Target', FILES.stylesLocal).a('Type', RELS.styles)
            .done()
        .done(),
    )
}

export const generate = async <Row, Totals>(spec: ExcelSpec<Row, Totals>) => {
    const styleManager = createStyleManager()
    const worksheetXml = await getWorksheetXml(spec, styleManager)
    const stylesheetXml = getStylesheetXml(styleManager)

    const zipper = createZipper(spec.outputName + '.xlsx', () => clearProgress())
    addBaseFiles(zipper)
    zipper.addFile(FILES.sheet, worksheetXml)
    zipper.addFile(FILES.styles, stylesheetXml)
    await zipper.generate()
}

export const isRelevantForTests = (fileName: string) =>
    fileName === FILES.sheet || fileName === FILES.styles
