import { Processes } from '../common/types/processes'
import { Progress } from '../common/types/progress'
import { ExcelButtonProps } from './components/excel-button'
import { ExcelSpec } from './excel/types'
import { t } from './i18n'
import { download } from './state/excel-actions'

export const getExcelButtonProps = <Row, Totals>(
    spec: ExcelSpec<Row, Totals>,
    progressPrefix: string,
    processes: Processes,
    progress: Progress | null,
    className: string,
): ExcelButtonProps => {
    const processName = 'excel'

    const props: ExcelButtonProps = {
        button: {
            text: t.excel.open.get(),
            onClick: async () => download(spec, processName),
            processes,
            processName,
            className,
        },
    }

    if (processes.has(processName)) {
        props.progressText =
            (progressPrefix || t.rows.processed.get()) +
            ': ' +
            (progress ? progress.done + '/' + progress.total : '0')
    }

    return props
}
