import React from 'react'

import { t } from '../../i18n'

export const NoReportData = () =>
    React.createElement(
        'div',
        { className: 'no-data' },
        React.createElement('div', { className: 'no-data__text' }, t.reports.noData.get()),
        React.createElement(
            'div',
            null,
            React.createElement(
                'a',
                { href: '#/financial-history/balance/edit', className: 'button no-data__button' },
                t.reports.noData.enter.get(),
            ),
        ),
    )
