import { Day } from '../../common/time'
import * as api from '../api'
import { inputs } from '../inputs'
import { loadCompanies } from './load-actions'
import { run } from './process-actions'
import { dispatch, getState } from './store'

export const SAVE_NOTE_PROCESS = 'dashboard/save/note'

export const loadDashboardData = async (date: string) => {
    dispatch(({ dashboardData }) => {
        dashboardData.date = date
        dashboardData.loaded = null
    })

    const loaded = await api.loadDashboardData(date)
    dispatch(({ dashboardData }) => (dashboardData.loaded = loaded))
}

const loadNote = async () => {
    const note = await api.loadNote()
    dispatch(({ dashboardData }) => (dashboardData.note = note))
    inputs.note.text.set(note.text)
    inputs.note.rev.set(note.rev)
    inputs.note.hasChanged.set(false)
}

export const init = async () => {
    inputs.dashboard.month.set(Day.today().ym())
    return Promise.all([loadCompanies(), loadDashboardData(Day.today().ymd()), loadNote()])
}

export const setDate = async (date: Day) => loadDashboardData(date.ymd())

export const saveNote = async () =>
    run(SAVE_NOTE_PROCESS, async () => {
        const { inputValues } = getState()
        const noteInputs = inputs.note
        const rev = noteInputs.rev.get(inputValues)
        const text = noteInputs.text.get(inputValues)
        await api.updateNote(rev, text)
        await loadNote()
    })
