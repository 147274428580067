import { Set as ImmutableSet } from 'immutable'

import {
    CURRENT_ASSETS,
    CURRENT_LIABILITIES,
    EQUITY,
    FIXED_ASSETS,
    LONG_TERM_LIABILITIES,
} from '../common/accounts'
import { balanceDateModes } from '../common/enums'
import { clampToMonth, getFiscalYear } from '../common/fiscal-year-utils'
import { invariant } from '../common/invariant'
import { Day } from '../common/time'
import { ApiAccount } from '../common/types/account'
import { ApiCompany, DayOfYear } from '../common/types/company'
import { ReportMode } from '../common/types/enums'
import { InputValues } from '../common/types/inputs'
import { t } from './i18n'
import { inputs } from './inputs'
import {
    AccountNode,
    addAccountRows,
    addAmounts,
    getAccountNodes,
    getBalanceMinDate,
    Row,
} from './report-utils'

export type IsVisible<T> = (amounts: Map<T, number>, number: string) => boolean

interface GroupNode<T> {
    topLevel?: true
    label: string
    calculated: boolean
    amounts?: Map<T, number>
    visible: boolean
    subGroups?: GroupNode<T>[]
    accounts?: AccountNode<T>[]
}

const calculateGroupTotals = <T>(group: GroupNode<T>) => {
    invariant(!group.calculated)
    group.amounts = new Map()

    if (group.subGroups) {
        for (const subGroup of group.subGroups) {
            calculateGroupTotals(subGroup)
            addAmounts(subGroup.amounts!, group.amounts)
            group.visible = group.visible || subGroup.visible
        }
    }

    if (group.accounts) {
        for (const account of group.accounts) {
            addAmounts(account.amounts, group.amounts)
            group.visible = group.visible || account.visible
        }
    }
}

export const getTopLevelGroups = <T extends string>(
    getAmounts: (number: string) => Map<T, number>,
    isVisible: IsVisible<T>,
    accounts: ApiAccount[],
) => {
    const debit: GroupNode<T> = {
        topLevel: true,
        label: t.assets.get(),
        calculated: false,
        visible: true,
        subGroups: [
            {
                label: t.assets.current.get(),
                calculated: false,
                visible: false,
                accounts: getAccountNodes(CURRENT_ASSETS, getAmounts, isVisible, accounts),
            },
            {
                label: t.asset.get(),
                calculated: false,
                visible: false,
                accounts: getAccountNodes(FIXED_ASSETS, getAmounts, isVisible, accounts),
            },
        ],
    }

    calculateGroupTotals(debit)

    const credit: GroupNode<T> = {
        topLevel: true,
        label: t.liabilitiesAndEquity.get(),
        calculated: false,
        visible: true,
        subGroups: [
            {
                label: t.liabilities.obligations.get(),
                calculated: false,
                visible: false,
                subGroups: [
                    {
                        label: t.liabilities.current.get(),
                        calculated: false,
                        visible: false,
                        accounts: getAccountNodes(
                            CURRENT_LIABILITIES,
                            getAmounts,
                            isVisible,
                            accounts,
                        ),
                    },
                    {
                        label: t.liabilities.longTerm.get(),
                        calculated: false,
                        visible: false,
                        accounts: getAccountNodes(
                            LONG_TERM_LIABILITIES,
                            getAmounts,
                            isVisible,
                            accounts,
                        ),
                    },
                ],
            },
            {
                label: t.liabilities.equity.get(),
                calculated: false,
                visible: false,
                accounts: getAccountNodes(EQUITY, getAmounts, isVisible, accounts),
            },
        ],
    }

    calculateGroupTotals(credit)
    return { debit, credit }
}

const getEmptyRow = (): Row<any> => ({ isEmpty: true })

const addGroup = <T>(group: GroupNode<T>, rows: Row<T>[], mode: ReportMode) => {
    const { label, topLevel, subGroups, accounts, visible, amounts } = group

    if (!visible) {
        return
    }

    if (subGroups) {
        let first = true

        for (const subGroup of subGroups) {
            if (subGroup.visible) {
                if (first) {
                    first = false
                } else {
                    rows.push(getEmptyRow())
                }

                addGroup(subGroup, rows, mode)
            }
        }

        rows.push(getEmptyRow())
    } else if (accounts) {
        addAccountRows(accounts, rows, mode, 1)
    } else {
        throw new Error('Group has neither subgroups nor accounts')
    }

    let endText = t.balance.groupTotal.get(label)

    if (topLevel) {
        endText = endText.toUpperCase()
    }

    rows.push({ label: endText, amounts, level: 0, topLevel })
}

export const getRows = <T>(group: GroupNode<T>, mode: ReportMode) => {
    const rows: Row<T>[] = []
    addGroup(group, rows, mode)
    return rows
}

export const getBalanceDates = (
    inputValues: InputValues,
    selectedDates: ImmutableSet<string>,
    registrationDate: string,
    fiscalYearBegin: DayOfYear,
    longFirstYear: boolean,
    minDate: Day,
): string[] => {
    const datesInputs = inputs.reports.balance.dates
    const mode = datesInputs.mode.get(inputValues)
    const today = Day.today()

    if (mode === balanceDateModes.dates) {
        return selectedDates.toArray().sort()
    } else if (mode === balanceDateModes.years) {
        const from = datesInputs.years.from.get(inputValues)
        const to = datesInputs.years.to.get(inputValues)
        const parsedTo = Day.fromYmd(to)

        const dates = []
        let current = getFiscalYear(
            Day.fromYmd(from),
            registrationDate,
            fiscalYearBegin,
            longFirstYear,
        )

        while (current.start.isSameOrBefore(parsedTo)) {
            const startOfNext = current.end.addDays(1)

            if (current.end.isAfter(today)) {
                current.end = today
            }

            if (current.end.isBefore(minDate)) {
                current.end = minDate
            }

            dates.push(current.end.ymd())
            current = getFiscalYear(startOfNext, registrationDate, fiscalYearBegin, longFirstYear)
        }

        return dates
    } else if (mode === balanceDateModes.months) {
        const from = datesInputs.months.from.get(inputValues)
        const to = datesInputs.months.to.get(inputValues)
        const dayOfMonth = Number(datesInputs.months.dayOfMonth.get(inputValues))

        if (isNaN(dayOfMonth) || dayOfMonth < 1 || dayOfMonth > 31) {
            // Note: for 29-31, if there aren't enough days in the month, we pick the last day of the month
            throw new Error(t.reports.periods.invalidDayOfMonth.get())
        }

        const parsedTo = Day.fromYm(to)
        let firstOfMonth = Day.fromYm(from)
        const dates = []

        while (firstOfMonth.isSameOrBefore(parsedTo) && firstOfMonth.isSameOrBefore(today)) {
            let date = clampToMonth(firstOfMonth.year(), firstOfMonth.month(), dayOfMonth)

            if (date.isBefore(minDate)) {
                date = minDate
            }

            if (date.isAfter(today)) {
                date = today
            }

            dates.push(date.ymd())
            firstOfMonth = firstOfMonth.addMonths(1)
        }

        return dates
    } else {
        throw new Error('Unexpected balance date mode: ' + mode)
    }
}

export const getBalanceDatesForCompany = (
    inputValues: InputValues,
    selectedDates: ImmutableSet<string>,
    company: ApiCompany,
) =>
    getBalanceDates(
        inputValues,
        selectedDates,
        company.registrationDate,
        company.fiscalYearBegin,
        company.longFirstYear,
        getBalanceMinDate(company),
    )
