import { isTestMode } from '../common/test-mode'

let testRoute = ''
let onChange: (() => void) | null = null

export const getRoute = () => {
    let route = isTestMode() ? testRoute : window.location.hash

    if (route.startsWith('#/')) {
        route = route.substr(2)
    }

    return route
}

export const setRoute = (route: string) => {
    if (isTestMode()) {
        if (route !== testRoute) {
            testRoute = route
            onChange!()
            return true
        }
    } else if (route !== window.location.hash) {
        window.location.hash = route
        return true
    }

    return false
}

export const setOnRouteChange = (onRouteChange: () => void) => (onChange = onRouteChange)

export const resetTestState = () => {
    testRoute = ''
}
