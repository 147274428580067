import classnames from 'classnames'
import React, { FC, ReactNode } from 'react'

import { getMinDay } from '../../../common/company-utils'
import { reportPeriodModes, reportPeriodModesArray } from '../../../common/enums'
import { Day } from '../../../common/time'
import { ApiCompany } from '../../../common/types/company'
import { ReportPeriodMode } from '../../../common/types/enums'
import { ValidationError } from '../../../common/types/errors'
import { InputValues } from '../../../common/types/inputs'
import { t } from '../../i18n'
import { inputs } from '../../inputs'
import { renderYearChoice } from '../../report-utils'
import { clearReportPeriodsError } from '../../state/report-actions'
import { valErr } from '../../val-err'
import { Button } from '../button'
import { renderChoice } from '../choice'
import { DateInput } from '../date-input'
import { Input } from '../input'
import { MonthButton } from '../month-button'

interface Props {
    inputValues: InputValues
    periodsError: string | null
    company: ApiCompany
    // 'periods' is assumed as the error prefix (or 'period' in singlePeriodMode)
    valErrors: ValidationError[] | undefined
    load: () => void
    singlePeriodMode?: true
}

const periodInputs = inputs.reports.periods

const renderDatesOptions = (
    singlePeriodMode: boolean,
    inputValues: InputValues,
    valErrors: ValidationError[] | undefined,
    company: ApiCompany,
) => {
    const minDate = getMinDay(company.interimDate)

    return [
        React.createElement(
            'div',
            null,
            t.date.from.get(),
            ' ',
            React.createElement(DateInput, {
                input: periodInputs.dates.from,
                inputValues,
                minDate,
                maxDate: Day.today(),
                className: 'date-button report-time-overlay__time-button',
            }),
        ),
        React.createElement(
            'div',
            { className: 'top-margin' },
            t.date.to.get(),
            ' ',
            React.createElement(DateInput, {
                input: periodInputs.dates.to,
                inputValues,
                minDate,
                maxDate: Day.today(),
                className: 'date-button report-time-overlay__time-button',
            }),
            valErr(valErrors, singlePeriodMode ? 'period.to' : 'periods.0.to', {
                'under-min': t.reports.periods.toUnderMin.get(),
            }),
        ),
    ]
}

const renderYearsOptions = (
    singlePeriodMode: boolean,
    inputValues: InputValues,
    company: ApiCompany,
) => {
    const fromYearChoice = renderYearChoice(periodInputs.years.from, inputValues, company)

    if (singlePeriodMode) {
        return [fromYearChoice]
    }

    return [
        React.createElement('div', null, t.date.from.get(), ' ', fromYearChoice),
        React.createElement(
            'div',
            { className: 'top-margin' },
            t.date.to.get(),
            ' ',
            renderYearChoice(periodInputs.years.to, inputValues, company),
        ),
    ]
}

const renderMonthsOptions = (
    singlePeriodMode: boolean,
    inputValues: InputValues,
    company: ApiCompany,
) => {
    const minDate = getMinDay(company.interimDate)

    const fromMonthButton = React.createElement(MonthButton, {
        input: periodInputs.months.from,
        inputValues,
        min: minDate,
        max: Day.today(),
        mainButtonClass: 'button--secondary button--numeric report-time-overlay__time-button',
        monthButtonClass: 'button--primary',
        selectedMonthButtonClass: 'button--primary-selected',
    })

    const dayOfMonthSection = React.createElement(
        'div',
        { className: 'top-margin' },
        t.dayOfMonth.get(),
        ' ',
        React.createElement(Input, {
            input: periodInputs.months.dayOfMonth,
            inputValues,
            className: 'day-of-month',
        }),
    )

    if (singlePeriodMode) {
        return [
            React.createElement('div', null, t.month.get(), ' ', fromMonthButton),
            dayOfMonthSection,
        ]
    }

    return [
        React.createElement('div', null, t.date.from.get(), ' ', fromMonthButton),
        React.createElement(
            'div',
            { className: 'top-margin' },
            t.date.to.get(),
            ' ',
            React.createElement(MonthButton, {
                input: periodInputs.months.to,
                inputValues,
                min: minDate,
                max: Day.today(),
                mainButtonClass:
                    'button--secondary button--numeric report-time-overlay__time-button',
                monthButtonClass: 'button--primary',
                selectedMonthButtonClass: 'button--primary-selected',
            }),
        ),
        dayOfMonthSection,
    ]
}

const renderDaysOptions = (inputValues: InputValues, company: ApiCompany) => {
    const minDate = getMinDay(company.interimDate)

    return [
        React.createElement(
            'div',
            null,
            t.date.from.get(),
            ' ',
            React.createElement(DateInput, {
                input: periodInputs.days.from,
                inputValues,
                minDate,
                maxDate: Day.today(),
                className: 'date-button report-time-overlay__time-button',
            }),
        ),
        React.createElement(
            'div',
            { className: 'top-margin' },
            t.date.to.get(),
            ' ',
            React.createElement(DateInput, {
                input: periodInputs.days.to,
                inputValues,
                minDate,
                maxDate: Day.today(),
                className: 'date-button report-time-overlay__time-button',
            }),
        ),
    ]
}

const renderOptions = (
    mode: ReportPeriodMode,
    singlePeriodMode: boolean,
    inputValues: InputValues,
    company: ApiCompany,
    valErrors: ValidationError[] | undefined,
): ReactNode[] => {
    if (mode === reportPeriodModes.dates) {
        return renderDatesOptions(singlePeriodMode, inputValues, valErrors, company)
    } else if (mode === reportPeriodModes.years) {
        return renderYearsOptions(singlePeriodMode, inputValues, company)
    } else if (mode === reportPeriodModes.months) {
        return renderMonthsOptions(singlePeriodMode, inputValues, company)
    } else if (mode === reportPeriodModes.days) {
        return renderDaysOptions(inputValues, company)
    } else {
        throw new Error('Unexpected report period mode: ' + mode)
    }
}

const renderError = (periodsError: string | null, valErrors: ValidationError[] | undefined) => {
    if (periodsError) {
        return React.createElement('div', { className: 'validation-error' }, periodsError)
    } else {
        return valErr(valErrors, 'periods', { 'too-long': t.reports.periods.tooManyPeriods.get() })
    }
}

const getModeOptions = (singlePeriodMode: boolean) => {
    if (singlePeriodMode) {
        return [
            { id: reportPeriodModes.dates, label: t.reports.periods.modes.dates.get() },
            { id: reportPeriodModes.years, label: t.year.get() },
            { id: reportPeriodModes.months, label: t.month.get() },
        ]
    } else {
        return reportPeriodModesArray.map((mode) => {
            const label = t.reports.periods.modes[mode].get()
            return { id: mode, label }
        })
    }
}

const renderOverlay = (visible: boolean, props: Props) => {
    if (!visible) {
        return null
    }

    const { inputValues, periodsError, company, valErrors, load } = props
    const singlePeriodMode = Boolean(props.singlePeriodMode)

    const input = periodInputs.mode
    const mode = input.get(inputValues)

    return React.createElement(
        'div',
        { className: classnames('report-time-overlay', { 'single-period': singlePeriodMode }) },
        React.createElement(
            'div',
            null,
            renderChoice({
                type: 'buttons',
                input,
                inputValues,
                options: getModeOptions(singlePeriodMode),
                groupClassName: 'vertical',
                forceSelection: true,
                afterChange: () => clearReportPeriodsError(),
                buttonClassName: 'report-period-mode-button button--secondary',
                selectedButtonClassName: 'report-period-mode-button button--primary-light',
            }),
        ),
        React.createElement(
            'div',
            { className: 'top-margin' },
            ...renderOptions(mode, singlePeriodMode, inputValues, company, valErrors),
        ),
        renderError(periodsError, valErrors),
        React.createElement(
            'div',
            { className: 'top-margin' },
            React.createElement(Button, {
                text: t.update.get(),
                onClick: load,
                className: 'button--primary',
            }),
        ),
    )
}

export const PeriodsChoice: FC<Props> = (props) => {
    const input = periodInputs.overlayOpen
    const visible = input.get(props.inputValues)

    const onClick = () => {
        input.set(!visible)

        if (visible) {
            // Closing
            clearReportPeriodsError()
        }
    }

    const text = props.singlePeriodMode
        ? t.reports.periods.choose.singular.get()
        : t.reports.periods.choose.get()

    return React.createElement(
        'div',
        { className: 'top-margin' },
        React.createElement(Button, {
            className: 'button button--wide button--primary',
            onClick,
            text,
        }),
        renderOverlay(visible, props),
    )
}
