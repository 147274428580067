import { VatSourceType } from '../vat-utils'
import { VatPaymentState } from './vat'

export enum VatTableMode {
    showAll = 'showAll',
    showOverLimit = 'showOverLimit',
    collapsed = 'collapsed',
    editing = 'editing',
}

export type VatIds = Record<VatSourceType, Set<string>>

export interface TaxesState {
    vat: {
        paymentOpen: boolean
        revenuesMode: VatTableMode
        expensesMode: VatTableMode
        ids: VatIds
        declarationExpanded: boolean
        paymentState: VatPaymentState | null
    }
}
