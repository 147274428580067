import React, { FC, Fragment, ReactNode } from 'react'

import { expenseTypes } from '../../../common/enums'
import { ExpenseType } from '../../../common/types/enums'
import { ApiExpense } from '../../../common/types/expense'
import { InputValues } from '../../../common/types/inputs'
import { t } from '../../i18n'
import { inputs } from '../../inputs'
import {
    copyItemsFromExpense,
    getLatestExpenseFromSameCompany,
    SAVE_PROCESS,
} from '../../state/expense-actions'
import { RootData } from '../../state/root-data'
import { BusinessLookup } from '../business-lookup'
import { renderChoice } from '../choice'

interface Props {
    isNew: boolean
    type: ExpenseType
    editMode: boolean
    rootData: RootData
    vatPayer: boolean
}

const regionInputs = inputs.expense.vendor.region

const renderEeButtons = (inputValues: InputValues) =>
    React.createElement(
        'div',
        null,
        renderChoice({
            type: 'buttons',
            options: [
                { id: 'yes', label: t.expenses.region.ee.get() },
                { id: 'no', label: t.expenses.region.nonEe.get() },
            ],
            input: regionInputs.ee,
            inputValues,
            forceSelection: true,
            buttonClassName: 'button--primary',
            selectedButtonClassName: 'button--primary-selected',
        }),
    )

const renderEuButtons = (inputValues: InputValues) => {
    if (regionInputs.ee.get(inputValues) !== 'yes') {
        return React.createElement(
            'div',
            null,
            renderChoice({
                type: 'buttons',
                options: [
                    { id: 'yes', label: t.expenses.region.eu.get() },
                    { id: 'no', label: t.expenses.region.other.get() },
                ],
                input: regionInputs.eu,
                inputValues,
                forceSelection: true,
                buttonClassName: 'button--primary',
                selectedButtonClassName: 'button--primary-selected',
            }),
        )
    } else {
        return null
    }
}

const renderRegion = (editMode: boolean, inputValues: InputValues): ReactNode => {
    if (editMode) {
        return React.createElement(
            Fragment,
            null,
            renderEeButtons(inputValues),
            renderEuButtons(inputValues),
        )
    } else {
        if (regionInputs.ee.get(inputValues) === 'yes') {
            return t.expenses.region.ee.get()
        } else if (regionInputs.eu.get(inputValues) === 'yes') {
            return t.expenses.region.eu.get() + ' (' + t.expenses.region.nonEe.get() + ')'
        } else {
            return t.expenses.region.other.get()
        }
    }
}

const renderCopyExpenseLink = (
    isEmptyNewRegular: boolean,
    expenses: ApiExpense[] | null,
    inputValues: InputValues,
) => {
    if (isEmptyNewRegular) {
        const latestExpense = getLatestExpenseFromSameCompany(expenses, inputValues)

        if (latestExpense) {
            const onClick = async () => copyItemsFromExpense(latestExpense)

            return React.createElement(
                'div',
                null,
                React.createElement(
                    'a',
                    { onClick, className: 'copy-expense-link' },
                    t.copyItems.outgoing.get(),
                ),
            )
        }
    }

    return null
}

export const ExpenseTopLeft: FC<Props> = (props) => {
    const { isNew, type, editMode, rootData, vatPayer } = props

    const {
        businessLookup,
        expenseData: { expenses, itemIds },
        inputValues,
        processes,
        validationErrors,
    } = rootData

    const valErrors = validationErrors[SAVE_PROCESS]
    const isEmptyNewRegular = isNew && type === expenseTypes.regular && !itemIds.length

    return React.createElement(
        'div',
        { className: 'expense__top-left' },
        React.createElement('h1', { className: 'title' }, t.expenses.invoiceVendor.get()),
        React.createElement(
            'div',
            { className: 'top-margin' },
            React.createElement(
                'div',
                { className: 'bottom-margin' },
                renderRegion(editMode, inputValues),
            ),
            React.createElement(BusinessLookup, {
                collectionName: 'Expenses',
                businessLookup,
                inputs: inputs.expense.vendor,
                inputValues,
                processes,
                vatPayer,
                valErrors,
                errorPrefix: 'expense.vendor',
                disabled: !editMode,
            }),
            renderCopyExpenseLink(isEmptyNewRegular, expenses, inputValues),
        ),
    )
}
