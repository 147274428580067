import React, { FC } from 'react'

import { t } from '../i18n'

export interface ViewIconProps {
    href: string
}

export const ViewIcon: FC<ViewIconProps> = ({ href }) =>
    React.createElement(
        'a',
        { href, title: t.view.get() },
        React.createElement('img', { src: '/icons/view.svg', className: 'view-icon' }),
    )
