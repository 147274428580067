import { BANK, CASH } from '../../common/accounts'
import { Day } from '../../common/time'
import { ValidationError } from '../../common/types/errors'
import { ApiExpense, ExpenseRegion } from '../../common/types/expense'
import { ApiRevenue, CreditRevenue } from '../../common/types/invoice'
import { calculateVat, VatCalc } from '../../common/vat-utils'
import { amountFromString } from '../amount-from-string'
import { assertViewName } from '../assert-view-name'
import { TaxesVatProps } from '../components/taxes/vat'
import { VatPaymentSidebarProps } from '../components/taxes/vat-payment-sidebar'
import { t } from '../i18n'
import { inputs } from '../inputs'
import { RootData } from '../state/root-data'
import { addVatPayment, closeVatPayment, VAT_PAYMENT_PROCESS } from '../state/tax-actions'
import { getValidationErrorProps } from '../val-err'
import { getTaxesVatMainProps } from './taxes-vat-main-props'

const getNegativeBalanceErrorMessage = (error: ValidationError): string => {
    const { account } = error
    const date = Day.fromYmd(error.date).dmy()

    if (account === CASH) {
        return t.payments.negativeBalance.onAdd.outgoing.cash.get(date)
    } else if (account === BANK) {
        return t.payments.negativeBalance.onAdd.outgoing.bank.get(date)
    }

    return t.payments.negativeBalance.onAdd.outgoing.get(date, account)
}

const getPaymentSidebar = (rootData: RootData, calc: VatCalc): VatPaymentSidebarProps => {
    const { inputValues, processes, validationErrors } = rootData
    const valErrors = validationErrors[VAT_PAYMENT_PROCESS]
    const minDate = calc.month.firstOfNextMonth()
    const isOverpaid = calc.differenceMta < 0

    const props: VatPaymentSidebarProps = {
        onClose: closeVatPayment,
        title: isOverpaid ? t.taxes.vat.transferTitle.get() : t.taxes.vat.paymentTitle.get(),
        date: {
            title: t.payments.date.outgoing.get(),
            input: {
                input: inputs.taxes.vat.payment.date,
                inputValues,
                minDate,
            },
            errors: getValidationErrorProps(valErrors, 'payment.date'),
        },
        errors: getValidationErrorProps(valErrors, 'payment', {
            'negative-balance': getNegativeBalanceErrorMessage,
        }),
        saveButton: {
            onClick: async () => addVatPayment(calc),
            text: t.save.get(),
            processes,
            processName: VAT_PAYMENT_PROCESS,
        },
    }

    if (isOverpaid) {
        props.overpaid = {
            title: t.amount.get(),
            amount: -calc.differenceMta,
        }
    } else {
        const { amount: amountInput, interest: interestInput } = inputs.taxes.vat.payment

        const fromPrepaid = amountFromString(amountInput.get(inputValues))
        const interest = amountFromString(interestInput.get(inputValues))

        const totalToPay = calc.differenceMta + interest
        const fromBank = fromPrepaid >= 0 ? totalToPay - fromPrepaid : NaN

        props.amountInputs = {
            interest: {
                title: t.taxes.vat.interest.get(),
                input: { input: interestInput, inputValues },
                errors: getValidationErrorProps(valErrors, 'payment.interest'),
            },
            totalToPay: {
                title: t.taxes.vat.totalToPay.get(),
                amount: totalToPay,
                isInvalid: isNaN(totalToPay),
            },
            fromPrepaid: {
                title: t.taxes.vat.amountFromPrepaid.get(),
                input: { input: amountInput, inputValues },
                errors: getValidationErrorProps(valErrors, 'payment.fromPrepaid'),
            },
            fromBank: {
                title: t.taxes.vat.amountFromBank.get(),
                amount: fromBank,
                isInvalid: isNaN(fromBank) || fromBank < 0,
            },
        }
    }

    return props
}

const getSidebar = (
    rootData: RootData,
    getCalc: () => VatCalc | null,
): TaxesVatProps['sidebar'] => {
    if (rootData.taxes.vat.paymentOpen) {
        const calc = getCalc()

        if (calc) {
            return { type: 'payment', props: getPaymentSidebar(rootData, calc) }
        } else {
            return { type: 'loading' }
        }
    } else {
        return { type: 'taxes' }
    }
}

export const getTaxesVatProps = (rootData: RootData): TaxesVatProps => {
    const {
        expenseData: { expenses },
        invoiceData,
        creditRevenueData: { creditRevenues },
        view,
    } = rootData

    const revenues = invoiceData.invoices

    let getCalc: () => VatCalc | null = () => null

    const { month: monthOrEmpty } = assertViewName(view, 'TaxesVat')
    const monthYm = monthOrEmpty || Day.today().firstOfPreviousMonth().ym()

    const month = Day.fromYm(monthYm)

    let confirmedExpenses: ApiExpense[] | null = null
    let confirmedRevenues: ApiRevenue[] | null = null

    let selectableExpenses: ApiExpense[] | null = null
    let selectableRevenues: ApiRevenue[] | null = null
    let selectableCreditRevenues: CreditRevenue[] | null = null

    if (expenses) {
        confirmedExpenses = expenses.filter((expense) => expense.confirmed)

        selectableExpenses = confirmedExpenses.filter((expense) => {
            return (
                expense.vendor.region === ExpenseRegion.ee &&
                (expense.vatMonth === '' || expense.vatMonth === monthYm)
            )
        })
    }

    if (revenues) {
        confirmedRevenues = revenues.filter((revenue) => revenue.confirmed)

        selectableRevenues = confirmedRevenues.filter((revenue) => {
            return revenue.vatMonth === '' || revenue.vatMonth === monthYm
        })
    }

    if (creditRevenues) {
        selectableCreditRevenues = creditRevenues.filter(
            (creditRevenue) => creditRevenue.vatMonth === '' || creditRevenue.vatMonth === monthYm,
        )
    }

    if (confirmedExpenses && confirmedRevenues && creditRevenues) {
        const selectedExpenses = confirmedExpenses.filter((expense) => expense.vatMonth === monthYm)
        const selectedRevenues = confirmedRevenues.filter((revenue) => revenue.vatMonth === monthYm)

        const selectedCreditRevenues = creditRevenues.filter(
            (creditRevenue) => creditRevenue.vatMonth === monthYm,
        )

        let cachedCalc: VatCalc | null = null

        getCalc = () => {
            if (!cachedCalc) {
                cachedCalc = calculateVat(
                    selectedExpenses,
                    selectedRevenues,
                    selectedCreditRevenues,
                    month,
                    revenues!,
                )
            }

            return cachedCalc
        }
    }

    return {
        sidebar: getSidebar(rootData, getCalc),
        title: t.taxes.vat.declaration.get(),
        subtitle: month.monthName() + ' ' + month.year(),
        main: getTaxesVatMainProps(
            rootData,
            month,
            getCalc,
            selectableExpenses,
            selectableRevenues,
            selectableCreditRevenues,
        ),
    }
}
