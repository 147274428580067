import React, { ChangeEventHandler, FC } from 'react'

import { Input, InputValues } from '../../common/types/inputs'

export interface CheckboxProps {
    input: Input<boolean>
    inputValues: InputValues
    domId?: string
    className?: string
}

export const Checkbox: FC<CheckboxProps> = ({ input, inputValues, domId, className }) => {
    const onChange: ChangeEventHandler<HTMLInputElement> = (evt) => {
        input.set(evt.currentTarget.checked)
    }

    const checked = input.get(inputValues) || false
    return React.createElement('input', {
        type: 'checkbox',
        onChange,
        checked,
        id: domId,
        className,
    })
}
