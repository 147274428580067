export const buildDataUrlFromUrl = async (url: string) =>
    new Promise<string>((resolve, reject) => {
        const img = new Image()

        img.onload = () => {
            const canvas = document.createElement('canvas')
            canvas.width = img.naturalWidth
            canvas.height = img.naturalHeight
            canvas.getContext('2d')!.drawImage(img, 0, 0)
            resolve(canvas.toDataURL())
        }

        img.onerror = reject

        /*
    Loading the image from another origin (such as AWS S3) via JavaScript will break
    if there is no matching Access-Control-Allow-Origin header on the response.
    AWS S3 only adds this header when the request contains an Origin header.
    This line adds the Origin header to the request.
    */
        img.crossOrigin = 'anonymous'

        /*
    If the image is cached without the Access-Control-Allow-Origin header and the
    request initiated by JavaScript is served from the cache, the request will fail
    because of this missing header.

    Ideally, the responses with and without the Origin and Access-Control-Allow-Origin
    headers should be cached separately.

    There is a mechanism for this specific purpose - the Vary header.
    For example, if all requests to the same resource have a 'Vary: Origin' header, then
    a separate cache entry is used for each Origin header value in the requests.

    Fortunately, AWS S3 does set the 'Vary: Origin' header.
    Unfortunately, they only do so if the request has the Origin header.
    So if a request without the Origin header is made first, the browser will cache
    the response that has neither Access-Control-Allow-Origin nor Vary: Origin.

    If the browser then requests the same image with an Origin header, it will use the
    cached response without the Vary: Origin header and therefore never find out that
    separate cache entries are needed. So no new request to AWS is made.
    After that, any attempt to load the image via JavaScript will fail.

    This issue has been reported in 2014: https://forums.aws.amazon.com/thread.jspa?messageID=555417
    As of November 2018, AWS has not fixed it yet or even responded to the issue.

    Workarounds:

    We could always bypass the cache when loading the images via JavaScript, but that would
    result in too many requests to S3. We do want to use the cache, just with correct headers.

    We could add crossorigin="anonymous" to all <img> tags in Booky, but there would still be
    various possibilities for the image ending up in someone's cache without the Origin header.
    For example, a user might copy the image URL and use it outside Booky.

    Alternatively, we can use a technique similar to cache busters by accessing the image via
    slightly different URLs. When using the Origin header, we add ?with-origin to the end of
    the URL to get the same resource, but hopefully force the browser to use a separate cache
    entry for it.
    */
        img.src = url + '?with-origin'
    })
