import { calculateExpenseInitial } from '../../common/expense-utils'
import { Day } from '../../common/time'
import { PaymentProps } from '../components/payment'
import { t } from '../i18n'
import {
    addExpensePayment,
    getById as getExpenseById,
    removeExpensePayment,
} from '../state/expense-actions'
import {
    closeExpensePaymentForm,
    REMOVE_PAYMENT_PROCESS,
    SAVE_PAYMENT_PROCESS,
} from '../state/payment-actions'
import { RootData } from '../state/root-data'
import { getPaymentProps, RawPaymentProps } from './payment-props'

export const getExpensePaymentProps = (rootData: RootData, id: string): PaymentProps => {
    const { expenseData, inputValues, processes, validationErrors } = rootData
    const expense = getExpenseById(expenseData, id)!
    const totals = calculateExpenseInitial(expense)

    const rawProps: RawPaymentProps = {
        labels: {
            main: t.payments.expense.get(),
            amountSection: t.totals.expense.get(),
            isFull: t.payments.expense.isFull.get(),
            amount: t.payments.expense.amount.get(),
        },
        incoming: false,
        minDate: Day.fromYmd(expense.date),
        payments: expense.payments,
        totals,
        totalToPay: totals.payableWithVat,
        inputValues,
        onSave: async () => addExpensePayment(id),
        onRemove: async (paymentId) => removeExpensePayment(id, paymentId),
        onClose: closeExpensePaymentForm,
        processes,
        saveProcessName: SAVE_PAYMENT_PROCESS,
        removeProcessName: REMOVE_PAYMENT_PROCESS,
        valErrors: validationErrors[SAVE_PAYMENT_PROCESS],
    }

    if (expense.paid) {
        rawProps.disabledText = t.payments.fullyPaid.outgoing.get()
    }

    return getPaymentProps(rawProps)
}
