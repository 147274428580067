import { xml, XmlBuilder } from '../../common/xml'
import { NS } from './constants'
import {
    ExcelBorder,
    ExcelFill,
    ExcelFont,
    ExcelNumberFormat,
    ExcelStyle,
    StyleManager,
} from './types'

export const HEADER_STYLE: ExcelStyle = {
    font: ExcelFont.bold,
    fill: ExcelFill.header,
    border: ExcelBorder.header,
}

interface StyleEntry {
    index: number
    style: ExcelStyle
}

const uint8ArrayToText = async (array: Uint8Array): Promise<string> => {
    // This code may run in the browser or in Node.js (through automatic tests)

    if (typeof FileReader !== 'undefined') {
        // Most likely running in a browser
        const blob = new Blob([array])
        const reader = new FileReader()
        const promise = new Promise<unknown>((resolve) => reader.addEventListener('load', resolve))
        reader.readAsText(blob)
        await promise
        return reader.result as string
    } else if (typeof Buffer !== 'undefined') {
        // Most likely running in Node.js
        return Buffer.from(array).toString()
    } else {
        throw new Error('Neither FileReader nor Buffer is supported')
    }
}

const writeStyle = (style: ExcelStyle, builder: XmlBuilder) => {
    builder.a('applyFont', 1).a('fontId', style.font || ExcelFont.regular)

    if (style.fill) {
        builder.a('applyFill', 1).a('fillId', style.fill)
    }

    if (style.border) {
        builder.a('applyBorder', 1).a('borderId', style.border)
    }

    if (style.numberFormat) {
        builder.a('applyNumberFormat', 1).a('numFmtId', style.numberFormat)
    }

    builder.a('applyAlignment', 1)

    builder
        .e('alignment')
        .f((alignBuilder) => {
            if (style.alignment) {
                if (style.alignment.horizontal) {
                    alignBuilder.a('horizontal', style.alignment.horizontal)
                }

                if (style.alignment.indent) {
                    alignBuilder.a('indent', style.alignment.indent)
                }
            }
        })
        .a('vertical', 'top')
        .a('wrapText', 1)
        .done()
}

export const createStyleManager = (): StyleManager => {
    const stringMap = new Map<string, StyleEntry>()

    // Cache to avoid rebuilding XML string for same object
    const objectMap = new Map<ExcelStyle, StyleEntry>()

    return {
        getIndex: async (style: ExcelStyle) => {
            let entry = objectMap.get(style)

            if (entry) {
                return entry.index
            }

            const data = xml('xf')
                .f((builder) => writeStyle(style, builder))
                .done()
            const xmlString = await uint8ArrayToText(data)
            entry = stringMap.get(xmlString)

            if (!entry) {
                entry = { index: stringMap.size + 1, style }
                stringMap.set(xmlString, entry)
                objectMap.set(style, entry)
            }

            return entry.index
        },
        writeXml: (xmlBuilder) => {
            const xfsBuilder = xmlBuilder.e('cellXfs')
            xfsBuilder.e('xf').done() // default (index = 0)
            let expectedIndex = 1

            for (const entry of stringMap.values()) {
                if (entry.index !== expectedIndex) {
                    throw new Error('Expected index ' + expectedIndex + ' but got ' + entry.index)
                }

                xfsBuilder
                    .e('xf')
                    .f((builder) => writeStyle(entry.style, builder))
                    .done()
                expectedIndex += 1
            }

            xfsBuilder.done()
        },
    }
}

// prettier-ignore
export const getStylesheetXml = (styleManager: StyleManager) => xml('styleSheet')
    .a('xmlns', NS.main)
    .e('numFmts')
        .e('numFmt')
            .a('numFmtId', ExcelNumberFormat.month)
            .au('formatCode', 'mmmm yyyy')
        .done()
        .e('numFmt')
            .a('numFmtId', ExcelNumberFormat.money)
            .au('formatCode', '#,##0.00; -#,##0.00')
        .done()
        .e('numFmt')
            .a('numFmtId', ExcelNumberFormat.moneyDash)
            .au('formatCode', '#,##0.00; -#,##0.00; "-"')
        .done()
    .done()
    .e('fonts')
        .e('font') // fontId 0, Arial
            .e('name').a('val', 'Arial').done()
        .done()
        .e('font') // fontId 1, Arial bold
            .e('name').a('val', 'Arial').done().e('b').done()
        .done()
    .done()
    .e('fills')
        .e('fill').done() // fillId 0, no fill (reserved, not customizable)
        .e('fill').done() // fillId 1, gray 125 (reserved, not customizable)
        .e('fill') // fillId 2, header
            .e('patternFill')
                .a('patternType', 'solid')
                .e('fgColor').a('rgb', 'FFF2F2F2').done()
            .done()
        .done()
    .done()
    .e('borders')
        .e('border').done() // borderId 0, none
        .e('border') // borderId 1, header
            .e('left').a('style', 'thin').done()
            .e('right').a('style', 'thin').done()
            .e('top').a('style', 'thin').done()
            .e('bottom').a('style', 'thin').done()
        .done()
    .done()
    .f((builder) => styleManager.writeXml(builder))
.done()
