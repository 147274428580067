import { cleanString } from '../../common/clean-string'
import { delay } from '../../common/delay'
import { BusinessInputs, OtherBusiness } from '../../common/types/business'
import * as api from '../api'
import { run } from './process-actions'
import { dispatch } from './store'

let queued: string | null = null
let delayEnabled = true

const getMatches = async (collectionName: 'Expenses' | 'Invoices' | undefined, cleaned: string) => {
    if (cleaned === '') {
        return { previous: [], other: [] }
    } else {
        return api.lookupBusiness(collectionName, cleaned)
    }
}

export const lookupBusiness = async (
    collectionName: 'Expenses' | 'Invoices' | undefined,
    text: string,
    inProgress: boolean,
) => {
    if (inProgress) {
        // A request is in progress, don't start a new one until it has completed.
        queued = text
        return
    }

    const cleaned = cleanString(text, true)

    await run('business-lookup', async () => {
        const { previous, other } = await getMatches(collectionName, cleaned)

        dispatch(({ businessLookup }) => {
            businessLookup.previous = previous
            businessLookup.other = other
            businessLookup.search = text
        })

        if (delayEnabled) {
            // Extra delay to prevent too many requests on a fast connection
            await delay(500)
        }
    })

    if (queued !== null) {
        const copy = queued
        queued = null
        await lookupBusiness(collectionName, copy, false)
    }
}

export const clearResults = () =>
    dispatch(({ businessLookup }) => {
        businessLookup.previous = []
        businessLookup.other = []
        businessLookup.search = ''
    })

export const clearLookup = () => {
    clearResults()
    dispatch(({ businessLookup }) => (businessLookup.lastSelectionVat = 'none-selected'))
}

export const selectBusiness = (business: OtherBusiness, inputs: BusinessInputs) => {
    inputs.name.set(business.name)
    inputs.regCode.set(business._id)
    inputs.address.set(business.address)
    inputs.vatId.set(business.vatId)

    dispatch(({ businessLookup }) => {
        businessLookup.lastSelectionVat = business.vatId ? 'payer' : 'non-payer'
    })

    clearResults()
}

// Only for automatic tests
export const disableDelay = () => {
    delayEnabled = false
}
