import React, { Attributes, FC } from 'react'

import { asMonthNumber } from '../../common/month-numbers'
import { range } from '../../common/range'
import { Input, InputValues } from '../../common/types/inputs'
import { t } from '../i18n'
import { Button, ButtonProps } from './button'

interface Props {
    input: Input<number>
    inputValues: InputValues
    minMonth?: number
    maxMonth?: number
    buttonClassName: string
    selectedButtonClassName: string
}

export const MonthInput: FC<Props> = (props) => {
    const { input, inputValues, buttonClassName, selectedButtonClassName } = props
    const selected = input.get(inputValues)
    const minMonth = props.minMonth || 1
    const maxMonth = props.maxMonth || 12

    return React.createElement(
        'div',
        { className: 'month-input button-group' },
        range(0, 3).map((i) =>
            React.createElement(
                'div',
                { key: i },
                range(0, 2).map((j) => {
                    const month = asMonthNumber(i * 3 + j + 1)

                    const buttonProps: ButtonProps & Attributes = {
                        key: j,
                        text: t.month.short[month].get(),
                        className: buttonClassName,
                    }

                    if (month >= minMonth && month <= maxMonth) {
                        buttonProps.onClick = () => input.set(month)

                        if (month === selected) {
                            buttonProps.className = selectedButtonClassName
                        }
                    } else {
                        buttonProps.disabled = true
                    }

                    return React.createElement(Button, buttonProps)
                }),
            ),
        ),
    )
}
