import React, { FC, Fragment } from 'react'

import { renderAmount } from '../../render-amount'
import { Button, ButtonProps } from '../button'
import { Link, LinkProps } from '../link'
import { MonthNav, MonthNavProps } from '../month-nav'
import { renderVatTable, VatTableProps } from './vat-table'

export interface VatSectionProps {
    title: string
    table: VatTableProps
    selectButton?: Omit<ButtonProps, 'className'>
    editButtons?: ButtonProps[]
}

export interface VatPaymentProps {
    title: string
    amount: number
    paidText?: string
    paymentLink?: LinkProps
}

export interface DeclarationRow {
    text: string
    amount: number
}

interface DeclarationInfoProps {
    showHideButton: ButtonProps
    rows: DeclarationRow[]
}

export interface TaxesVatMainProps {
    isLoading: false
    monthNav: MonthNavProps
    revenueSection: VatSectionProps
    expenseSection: VatSectionProps
    payment?: VatPaymentProps
    declarationInfo?: DeclarationInfoProps
    prepaidText: string
}

const VatSection: FC<VatSectionProps> = ({ title, table, selectButton, editButtons }) => {
    return React.createElement(
        Fragment,
        null,
        React.createElement('h2', { className: 'taxes__heading' }, title),
        renderVatTable(table),
        selectButton &&
            React.createElement(
                'div',
                null,
                React.createElement(Button, { ...selectButton, className: 'button--primary' }),
            ),
        editButtons &&
            React.createElement(
                'div',
                null,
                ...editButtons.map((button) =>
                    React.createElement(Fragment, null, React.createElement(Button, button), ' '),
                ),
            ),
    )
}

const VatPayment: FC<VatPaymentProps> = ({ title, amount, paidText, paymentLink }) => {
    return React.createElement(
        Fragment,
        null,
        React.createElement('h2', { className: 'taxes__heading nowrap' }, title),
        renderAmount(amount),
        ' ',
        paidText,
        paymentLink &&
            React.createElement(Link, { ...paymentLink, className: 'taxes__overpaid-link' }),
    )
}

const DeclarationInfo: FC<DeclarationInfoProps> = ({ showHideButton, rows }) =>
    React.createElement(
        'div',
        { className: 'top-margin extra' },
        React.createElement(Button, showHideButton),
        ...rows.map(({ text, amount }) => {
            return React.createElement('div', null, text, ' = ', renderAmount(amount))
        }),
    )

export const TaxesVatMain: FC<TaxesVatMainProps> = (props) => {
    const { monthNav, revenueSection, expenseSection, payment, declarationInfo, prepaidText } =
        props

    return React.createElement(
        Fragment,
        null,
        React.createElement(
            'div',
            { className: 'taxes__month-wrapper' },
            React.createElement('div', null, React.createElement(MonthNav, monthNav)),
        ),
        React.createElement(VatSection, revenueSection),
        React.createElement(VatSection, expenseSection),
        payment && React.createElement(VatPayment, payment),
        declarationInfo && React.createElement(DeclarationInfo, declarationInfo),
        React.createElement('div', { className: 'top-margin' }, prepaidText),
    )
}
