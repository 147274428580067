import React, { FC } from 'react'

import { Button, ButtonProps } from '../button'
import { ChoiceProps, renderChoice } from '../choice'
import { Link, LinkProps } from '../link'
import { RevenueArchiveSidebar } from './archive-sidebar'

export interface RevenueArchiveContentsProps {
    title: string
    itemType: ChoiceProps<string>
    discount: ChoiceProps<string>
    clearFiltersButton?: ButtonProps
    showLink: LinkProps
}

export const RevenueArchiveContents: FC<RevenueArchiveContentsProps> = (props) => {
    const { title, itemType, discount, showLink, clearFiltersButton } = props

    return React.createElement(
        'div',
        { className: 'content-area' },
        React.createElement(RevenueArchiveSidebar, { selected: 'contents' }),
        React.createElement(
            'div',
            { className: 'content archive' },
            React.createElement(
                'div',
                null,
                React.createElement('h1', { className: 'title archive__title' }, title),
                React.createElement(
                    'div',
                    null,
                    renderChoice({
                        ...itemType,
                        buttonClassName: 'button--secondary',
                        selectedButtonClassName: 'button--primary',
                    }),
                ),
                React.createElement(
                    'div',
                    { className: 'top-margin' },
                    renderChoice({
                        ...discount,
                        buttonClassName: 'button--secondary',
                        selectedButtonClassName: 'button--primary',
                    }),
                ),
            ),
            React.createElement(
                'div',
                { className: 'top-margin' },
                React.createElement(Link, { ...showLink, className: 'button button--primary' }),
                ' ',
                clearFiltersButton && React.createElement(Button, clearFiltersButton),
            ),
        ),
    )
}
