import { delay } from '../../common/delay'
import { MenuMode, MenuSection } from '../../common/types/menu'
import { dispatch, getState } from './store'

const setMode = (mode: MenuMode) =>
    dispatch(({ menu }) => {
        menu.mode = mode

        if (mode === 'closing') {
            // @ts-expect-error Looks like a TypeScript bug
            menu.visibleSections = menu.visibleSections.clear()
        }
    })

export const toggleMenu = async () => {
    const { mode } = getState().menu

    if (mode === 'open') {
        setMode('closing')
        await delay(500)
        setMode('closed')
    } else if (mode === 'closed') {
        setMode('opening')
        await delay(500)
        setMode('open')
    }
    // If opening or closing, do nothing, let the current action finish
    else if (mode !== 'opening' && mode !== 'closing') {
        throw new Error('Unexpected menu mode: ' + mode)
    }
}

export const toggleSection = (section: MenuSection) => {
    const { mode } = getState().menu

    if (mode !== 'open' && mode !== 'opening') {
        void toggleMenu()
    }

    dispatch(({ menu }) => {
        if (menu.visibleSections.has(section)) {
            // @ts-expect-error Looks like a TypeScript bug
            menu.visibleSections = menu.visibleSections.delete(section)
        } else {
            menu.visibleSections = menu.visibleSections.add(section)
        }
    })
}
