export const cleanString = (str: string, removeSpaces: boolean) => {
    const result = str
        .toLowerCase()
        .replace(/õ|ö/g, 'o')
        .replace(/ä/g, 'a')
        .replace(/ü/g, 'u')
        .replace(/š/g, 's')
        .replace(/ž/g, 'z')
        .replace(/[^a-z0-9 ]/g, '')

    return removeSpaces ? result.replace(/ /g, '') : result
}
