import classnames from 'classnames'
import { EventSubscription } from 'fbemitter'
import React, {
    ClassAttributes,
    Component,
    InputHTMLAttributes,
    TextareaHTMLAttributes,
} from 'react'

import { InputValues, Input as TInput } from '../../common/types/inputs'
import { onFocusInput } from '../event-bus'

type InputElement = HTMLInputElement | HTMLTextAreaElement
type ElemProps<E> = ClassAttributes<E> & (InputHTMLAttributes<E> | TextareaHTMLAttributes<E>)

export interface InputProps {
    input: TInput<string>
    inputValues: InputValues
    type?: 'text' | 'multiline' | 'password'
    placeholder?: string
    onEnter?: () => void
    afterChange?: (newValue: string) => void
    focusOnMount?: boolean
    /** To be used with emitFocusInput() */
    focusEventId?: string
    className?: string
    domId?: string
    disabled?: boolean
    list?: string
    maxLength?: number
    autoResize?: boolean // To be used when type is 'multiline'
}

interface State {
    local: string
    previous: string | null
}

export class Input extends Component<InputProps, State> {
    static defaultProps: Partial<InputProps> = { focusOnMount: false, type: 'text' }

    static getDerivedStateFromProps({ input, inputValues }: InputProps, prevState: State) {
        const value = (input.getRaw(inputValues) as string) || ''

        if (value === prevState.previous) {
            // Skip the update and stop tracking the previous value.
            // The next update should have the correct new value.
            return { previous: null }
        } else {
            // Update the value and stop tracking previous in case
            // it's not null yet.
            return { local: value, previous: null }
        }
    }

    // Originally this component had no local state and would always just render the
    // value from the props. However, since store updates are batched and invoked
    // asynchronously, this leads to an unwanted side effect in React.
    // If the input's props are not updated synchronously to match the actual value
    // of the input, React tries to revert the actual value to what's in the props.
    // So, for example, when editing 'abc' to 'ac', React will briefly change the 'ac'
    // back to 'abc' and on the next tick, the asynchronous update will change it back
    // to 'ac'. Although the final value ends up correct, the cursor position is lost
    // in this process. Duplicating the value in the component state allows us to
    // keep the cursor position.
    // Update: the previous workaround broke at some point, probably after switching
    // to React 16. Additional tracking of the previous value was added to fix it.
    override state = {
        local: (this.props.input.getRaw(this.props.inputValues) as string) || '',
        previous: null,
    }

    node: InputElement | null
    focusListener: EventSubscription | null = null

    override componentDidMount() {
        const { autoResize, focusOnMount, focusEventId } = this.props

        if (autoResize) {
            this.adjustHeight()
        }

        if (focusOnMount && this.node) {
            this.node.focus()
        }

        if (focusEventId) {
            this.focusListener = onFocusInput(focusEventId, () => {
                if (this.node) {
                    this.node.focus()

                    const index = this.node.value.length
                    this.node.setSelectionRange(index, index)
                }

                if (autoResize) {
                    this.adjustHeight()
                }
            })
        }
    }

    override componentWillUnmount() {
        if (this.focusListener) {
            this.focusListener.remove()
        }
    }

    adjustHeight() {
        if (this.node) {
            // A hack to match the element's height to its content.
            // Add a few extra pixels for padding.
            this.node.style.height = 'auto'
            this.node.style.height = this.node.scrollHeight + 4 + 'px'
        }
    }

    override render() {
        const {
            className,
            domId,
            focusOnMount,
            focusEventId,
            input,
            inputValues,
            onEnter,
            afterChange,
            placeholder,
            type,
            disabled,
            list,
            maxLength,
            autoResize,
        } = this.props

        const props: ElemProps<InputElement> = {
            className: classnames(className, { 'auto-resize': autoResize }),
            id: domId,
            placeholder: placeholder || input.getDefaultValue(inputValues) || undefined,
            type: type === 'multiline' ? undefined : type,
            value: this.state.local,
            list,
        }

        if (autoResize) {
            const textareaProps = props as TextareaHTMLAttributes<any>
            textareaProps.rows = 1
        }

        if (disabled) {
            props.disabled = true
        } else {
            props.onChange = (evt) => {
                let { value } = evt.currentTarget

                if (typeof maxLength === 'number' && value.length > maxLength) {
                    value = value.substr(0, maxLength)
                }

                this.setState({ local: value, previous: this.state.local })
                input.set(value)

                if (afterChange) {
                    afterChange(value)
                }

                if (autoResize) {
                    this.adjustHeight()
                }
            }

            if (focusOnMount || focusEventId || autoResize) {
                props.ref = (node) => {
                    this.node = node
                }
            }

            if (onEnter) {
                props.onKeyUp = (evt) => {
                    if (evt.key === 'Enter' && onEnter) {
                        onEnter()
                    }
                }
            }
        }

        return React.createElement(type === 'multiline' ? 'textarea' : 'input', props)
    }
}
