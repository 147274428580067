import React, { FC } from 'react'

interface Props {
    onClick: () => void
}

const commonProps = { x: 0, y: 45, width: 100, height: 10 }

export const MenuToggle: FC<Props> = ({ onClick }) =>
    React.createElement(
        'svg',
        {
            className: 'menu-toggle',
            viewBox: '0 0 100 100',
            onClick,
        },
        React.createElement('rect', { ...commonProps, className: 'top-bar' }),
        React.createElement('rect', { ...commonProps, className: 'middle-bar' }),
        React.createElement('rect', { ...commonProps, className: 'bottom-bar' }),
    )
