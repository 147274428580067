import React, { FC, Fragment, ReactNode } from 'react'

import { assetChangeModes, assetChangeModesArray, assetTypes } from '../../../common/enums'
import { findByDbId } from '../../../common/find-by-db-id'
import { findById } from '../../../common/find-by-id'
import { calculateAssetCurrent, calculateAssetInitial } from '../../../common/item-utils'
import { asMonthNumber } from '../../../common/month-numbers'
import { Day } from '../../../common/time'
import { AssetChangeMode, AssetType } from '../../../common/types/enums'
import { ValidationError, ValidationErrors } from '../../../common/types/errors'
import {
    ApiExpense,
    AssetChangeParams,
    AssetCurrent,
    AssetInitial,
    DbAsset,
} from '../../../common/types/expense'
import { InputValues } from '../../../common/types/inputs'
import { Processes } from '../../../common/types/processes'
import { amountFromString } from '../../amount-from-string'
import { t } from '../../i18n'
import { createCustomInput } from '../../input-utils'
import { inputs } from '../../inputs'
import { renderAmount } from '../../render-amount'
import { setRoute } from '../../route-utils'
import {
    createAssetChange,
    SAVE_ASSET_CHANGE_PROCESS,
    updateAssetChange,
} from '../../state/expense-actions'
import { valErr } from '../../val-err'
import { Button } from '../button'
import { DateInput } from '../date-input'
import { Input } from '../input'
import { LoadingIcon } from '../loading-icon'
import { MenuToggle } from '../menu-toggle'

interface Props {
    changeParams: AssetChangeParams
    expenses: ApiExpense[] | null
    inputValues: InputValues
    processes: Processes
    validationErrors: ValidationErrors
}

const residualInput = inputs.expense.assetChange.residual
const eolInput = inputs.expense.assetChange.eolDate

const getDateRoute = (date: string) => '#/expenses/assets/add-value-change/' + date

const renderDate = (selectedDate: string | undefined) => {
    if (selectedDate) {
        return React.createElement(
            'div',
            null,
            t.assets.valueChange.date.get(),
            ': ',
            React.createElement(
                'span',
                { className: 'sidebar__strong' },
                Day.fromYmd(selectedDate).longDate(),
            ),
        )
    }

    const input = createCustomInput({
        inputType: 'string',
        get: () => '',
        set: (date: string) => setRoute(getDateRoute(date)),
    })

    return React.createElement(
        'div',
        null,
        t.assets.valueChange.date.get(),
        React.createElement(
            'div',
            { className: 'top-margin' },
            React.createElement(DateInput, {
                input,
                inputValues: {},
                maxDate: Day.today(),
                className: 'date-button date-button--white sidebar__wide-button',
                renderAsBlock: true,
            }),
        ),
    )
}

const renderResidualInput = (
    mode: AssetChangeMode,
    inputValues: InputValues,
    valErrors: ValidationError[] | undefined,
) => {
    if (mode !== assetChangeModes.residual) {
        return null
    }

    return React.createElement(
        'div',
        { className: 'top-margin' },
        t.assets.residual.get(),
        ': ',
        React.createElement(Input, {
            input: residualInput,
            inputValues,
            className: 'input input--white sidebar__input amount',
        }),
        ' €',
        valErr(valErrors, 'valueChange.residual'),
    )
}

const renderEolInput = (
    mode: AssetChangeMode,
    type: AssetType,
    inputValues: InputValues,
    valErrors: ValidationError[] | undefined,
) => {
    if (mode !== assetChangeModes.eol || type === assetTypes.land) {
        return null
    }

    return React.createElement(
        'div',
        null,
        t.assets.eol.get(),
        ': ',
        React.createElement(DateInput, {
            input: eolInput,
            inputValues,
            maxDate: Day.today().addYears(50).lastOfYear(),
            className: 'date-button date-button--white',
        }),
        valErr(valErrors, 'valueChange.eolDate', {
            'under-min': t.assets.valueChange.eolUnderMin.get(),
        }),
    )
}

const renderValues = (
    initial: number,
    depreciation: number,
    residual: number,
    eolDate: Day | undefined,
) => {
    const values = [
        React.createElement(
            'div',
            { className: 'top-margin' },
            t.assets.price.get(),
            ': ',
            renderAmount(initial),
        ),
        React.createElement('div', null, t.depreciation.get(), ': ', renderAmount(depreciation)),
        React.createElement('div', null, t.assets.residual.get(), ': ', renderAmount(residual)),
    ]

    if (eolDate) {
        values.push(React.createElement('div', null, t.assets.eol.get(), ': ', eolDate.longDate()))
    }

    return React.createElement(Fragment, null, ...values)
}

const renderNewValues = (
    initial: number,
    newDepreciation: number,
    newResidual: number,
    oldResidual: number,
    newEolDate: Day | undefined,
): { node: ReactNode; canSave: boolean } => {
    if (newResidual > oldResidual) {
        return {
            node: React.createElement(
                'div',
                { className: 'top-margin sidebar__warning' },
                t.assets.valueChange.residual.overMax.get(),
            ),
            canSave: false,
        }
    }

    return {
        node: React.createElement(
            Fragment,
            null,
            React.createElement(
                'h3',
                { className: 'sidebar__section-title' },
                t.assets.valueChange.changed.get(),
            ),
            renderValues(initial, newDepreciation, newResidual, newEolDate),
        ),
        canSave: true,
    }
}

const renderMode = (
    changeParams: AssetChangeParams,
    asset: DbAsset,
    initial: AssetInitial,
    current: AssetCurrent,
    inputValues: InputValues,
    valErrors: ValidationError[] | undefined,
): { node: ReactNode; canSave: boolean } => {
    if (!changeParams.mode) {
        const baseRoute =
            getDateRoute(changeParams.date!) +
            '/' +
            changeParams.expenseId +
            '/' +
            changeParams.assetId

        return {
            node: React.createElement(
                Fragment,
                null,
                ...assetChangeModesArray.map((linkMode) =>
                    React.createElement(
                        'div',
                        { className: 'top-margin' },
                        React.createElement(
                            'a',
                            {
                                className: 'button button--transparent sidebar__wide-button',
                                href: baseRoute + '/' + linkMode,
                            },
                            t.assets.valueChange.modes[linkMode].get(),
                        ),
                    ),
                ),
            ),
            canSave: false,
        }
    } else {
        let { eolDate: newEolDate, residual: newResidual } = current

        if (changeParams.mode === assetChangeModes.residual) {
            newResidual = amountFromString(residualInput.get(inputValues))
        } else if (changeParams.mode === assetChangeModes.eol) {
            if (asset.type === assetTypes.land) {
                throw new Error('Cannot change EoL date of land')
            }

            const eolInputString = eolInput.get(inputValues)
            newEolDate = eolInputString ? Day.fromYmd(eolInputString) : undefined
        } else {
            throw new Error('Unexpected asset change mode: ' + changeParams.mode)
        }

        const newDepreciation = initial.payableWithoutVat - newResidual

        const { node: newValuesNode, canSave } = renderNewValues(
            initial.payableWithoutVat,
            newDepreciation,
            newResidual,
            current.residual,
            newEolDate,
        )

        return {
            node: React.createElement(
                Fragment,
                null,
                React.createElement('h3', { className: 'sidebar__section-title' }, t.edit.get()),
                renderResidualInput(changeParams.mode, inputValues, valErrors),
                renderEolInput(changeParams.mode, asset.type, inputValues, valErrors),
                newValuesNode,
            ),
            canSave,
        }
    }
}

const renderAsset = (props: Props): { node: ReactNode; canSave: boolean } => {
    const { changeParams, expenses, inputValues, validationErrors } = props

    if (!changeParams.date) {
        return { node: null, canSave: false }
    }

    if (!changeParams.expenseId) {
        return {
            node: React.createElement(
                'div',
                { className: 'top-margin' },
                t.assets.valueChange.choose.get(),
            ),
            canSave: false,
        }
    } else {
        if (!changeParams.assetId) {
            throw new Error('Inconsistent selection')
        }

        if (!expenses) {
            return { node: React.createElement(LoadingIcon, { color: 'white' }), canSave: false }
        }

        const expense = findByDbId(expenses, changeParams.expenseId)

        if (!expense) {
            throw new Error('Expense not found')
        }

        const asset = findById(expense.assets!, changeParams.assetId)

        if (!asset) {
            throw new Error('Asset not found')
        }

        if (changeParams.isNew && changeParams.existing) {
            return {
                node: React.createElement(
                    'div',
                    { className: 'top-margin text-multiline sidebar__warning' },
                    t.assets.valueChange.alreadyPending.get(),
                ),
                canSave: false,
            }
        }

        if (asset.amortBegin && changeParams.date < asset.amortBegin) {
            return {
                node: React.createElement(
                    'div',
                    { className: 'top-margin text-multiline sidebar__warning' },
                    t.assets.valueChange.beforeAmortBegin.get(),
                ),
                canSave: false,
            }
        }

        const date = Day.fromYmd(changeParams.date)
        const monthNum = asMonthNumber(date.month())

        const initial = calculateAssetInitial(asset)
        const current = calculateAssetCurrent(date, asset)
        const valErrors = validationErrors[SAVE_ASSET_CHANGE_PROCESS]

        const { node: modeNode, canSave } = renderMode(
            changeParams,
            asset,
            initial,
            current,
            inputValues,
            valErrors,
        )

        return {
            node: React.createElement(
                Fragment,
                null,
                React.createElement(
                    'div',
                    { className: 'top-margin' },
                    t.asOf.suffix.get(
                        date.dayOfMonth() + '. ' + t.month.of[monthNum].get().toLowerCase(),
                    ),
                    ' "',
                    asset.description,
                    '"',
                ),
                renderValues(
                    initial.payableWithoutVat,
                    current.depreciation,
                    current.residual,
                    current.eolDate,
                ),
                modeNode,
            ),
            canSave,
        }
    }
}

const renderSaveButton = (
    canSave: boolean,
    changeParams: AssetChangeParams,
    processes: Processes,
) => {
    if (!canSave) {
        return null
    }

    let onClick

    if (changeParams.isNew) {
        onClick = async () => createAssetChange(changeParams)
    } else {
        onClick = async () => updateAssetChange(changeParams)
    }

    return React.createElement(Button, {
        text: t.save.get(),
        onClick,
        processes,
        processName: SAVE_ASSET_CHANGE_PROCESS,
        className: 'button--white sidebar__wide-button',
        loadingColor: 'white',
    })
}

export const AssetChange: FC<Props> = (props) => {
    const { changeParams, processes } = props
    const onClose = () => setRoute('#/expenses/assets')

    const { node: assetNode, canSave } = renderAsset(props)

    return React.createElement(
        'div',
        { className: 'sidebar sidebar--flex asset-change open' },
        React.createElement(
            'div',
            null,
            React.createElement(MenuToggle, { onClick: onClose }),
            React.createElement(
                'h1',
                { className: 'title sidebar__title' },
                t.assets.valueChange.get(),
            ),
            renderDate(changeParams.date),
            // TODO check if selected month is within current fiscal year
            assetNode,
        ),
        renderSaveButton(canSave, changeParams, processes),
    )
}
