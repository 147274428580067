import { findByDbId } from '../../common/find-by-db-id'
import { ApiUserLimited, UserData } from '../../common/types/user'
import { setInvalid } from './invalid-cache-actions'

export const invalidateCache = () => setInvalid('user')

export const getById = (data: UserData, id: string): ApiUserLimited | undefined => {
    if (!data.usersLimited) {
        throw new Error('Users not loaded')
    }

    return findByDbId(data.usersLimited, id)
}
