import { CompanyRole, CompanyStatus, GlobalRole } from './enums'
import { CommonCompanyFields } from './types/company'

export const USABLE_STATUSES = new Set([CompanyStatus.active, CompanyStatus.init])

export const regularRoles = [CompanyRole.manager, CompanyRole.base, CompanyRole.limited]

// Global role checks

// TODO use GlobalRole | undefined
const isDev = (globalRole: GlobalRole | null) => globalRole === GlobalRole.dev

// Company role checks

// TODO use CompanyRole | undefined (here and elsewhere)
const isTest = (companyRole: CompanyRole | null) => companyRole === CompanyRole.test

const isManagerOrTest = (companyRole: CompanyRole | null) =>
    companyRole === CompanyRole.manager || isTest(companyRole)

const isBaseOrHigher = (companyRole: CompanyRole | null) =>
    companyRole === CompanyRole.base || isManagerOrTest(companyRole)

const isBaseOrLimited = (companyRole: CompanyRole | null) =>
    companyRole === CompanyRole.base || companyRole === CompanyRole.limited

// Company status checks

/** Does not need a role check as such a company only has one user */
const isInit = (companyStatus: CompanyStatus) => companyStatus === CompanyStatus.init

/** Does not need a role check as such a company only has one user */
const isInitWithInterimDate = (company: CommonCompanyFields) =>
    isInit(company.status) && company.interimDate !== ''

const isActive = (companyStatus: CompanyStatus | undefined) =>
    companyStatus === CompanyStatus.active
const isUsable = (companyStatus: CompanyStatus) => USABLE_STATUSES.has(companyStatus)

// Combined checks

const isManagerOrTestAtActive = (
    companyRole: CompanyRole | null,
    companyStatus: CompanyStatus | undefined,
) => isManagerOrTest(companyRole) && isActive(companyStatus)

const isManagerOrTestAtUsable = (companyRole: CompanyRole | null, companyStatus: CompanyStatus) =>
    isManagerOrTest(companyRole) && isUsable(companyStatus)

const isBaseOrHigherAtActive = (
    companyRole: CompanyRole | null,
    companyStatus: CompanyStatus | undefined,
) => isBaseOrHigher(companyRole) && isActive(companyStatus)

export const canViewSettings = isManagerOrTestAtActive
export const canUpdateSettings = isManagerOrTestAtActive
export const canConfirm = isManagerOrTestAtActive
export const canUpdateNote = isManagerOrTestAtActive
export const canRemoveAllExpenses = isManagerOrTestAtActive
export const canRemoveExpensePayments = isManagerOrTestAtActive
export const canRemoveRevenuePayments = isManagerOrTestAtActive
export const canRemoveAllRevenues = isManagerOrTestAtActive
export const canRemoveConfirmedExpenseFile = isManagerOrTestAtActive
export const canRemoveAllLabourCosts = isManagerOrTestAtActive
export const canRemoveLabourPayments = isManagerOrTestAtActive
export const canChangeAssetValues = isManagerOrTestAtActive
export const canChangeStock = isManagerOrTestAtActive
export const canCreateEntry = isManagerOrTestAtActive
export const canCreateCreditRevenue = isManagerOrTestAtActive
export const canViewFullUsers = isManagerOrTestAtActive
export const canAddCreditRevenuePayments = isManagerOrTestAtActive

export const canViewCardPayments = isManagerOrTest

export const canViewAllExpenses = isBaseOrHigherAtActive
export const canUpdateAllExpenses = isBaseOrHigherAtActive
export const canViewAllRevenues = isBaseOrHigherAtActive
export const canUpdateAllRevenues = isBaseOrHigherAtActive
export const canViewAllLabourCosts = isBaseOrHigherAtActive
export const canViewTaxes = isBaseOrHigherAtActive
export const canViewReports = isBaseOrHigherAtActive
export const canUpdateInterimBalance = isBaseOrHigherAtActive
export const canAssignVatMonth = isBaseOrHigherAtActive
export const canAddVatPayments = isBaseOrHigherAtActive

export const canUseCompany = isUsable
export const canUpdateCompanyGeneral = isInit
export const canUpdateInitDate = isInit
export const canUpdateBillingDetails = isInitWithInterimDate
export const canSimulateCardPayment = isInitWithInterimDate
export const canInitFirstPayment = isInitWithInterimDate
export const canActivateCompany = isInitWithInterimDate
export const canArchiveCompany = isManagerOrTestAtUsable
export const canUpdateCard = isManagerOrTestAtUsable
export const canAddUserToCompany = isManagerOrTestAtUsable
export const canUpdateUserRole = isManagerOrTestAtActive
export const canAlwaysChangeRoleFrom = isBaseOrLimited
export const canRemoveUserFromCompany = isManagerOrTestAtActive

export const canViewDashboard = isActive
export const canViewExpenses = isActive
export const canCreateExpenses = isActive
export const canUpdateExpenses = isActive
export const canRemoveExpenses = isActive
export const canViewCreditRevenues = isActive
export const canViewRevenues = isActive
export const canCreateRevenues = isActive
export const canRemoveRevenues = isActive
export const canUpdateRevenues = isActive
export const canAddExpenseFile = isActive
export const canRemoveUnconfirmedExpenseFile = isActive
export const canViewLabourCosts = isActive
export const canCreateLabourCosts = isActive
export const canRemoveLabourCosts = isActive
export const canAddAccounts = isActive
export const canAddPayments = isActive
export const canLoadVatState = isActive

export const canUseTestUtils = isTest
export const canViewLogs = isDev

export const canViewAdminSection = (
    companyRole: CompanyRole | null,
    globalRole: GlobalRole | null,
) => canViewLogs(globalRole) || canUseTestUtils(companyRole)
