import { assetTypes, calculationModes } from '../common/enums'
import {
    calculateAssetCurrentFromValues,
    calculateAssetInitialFromValues,
    calculateAutomaticItemFromValues,
    calculateManualItemFromValues,
    calculateManualItemUnitPriceFromValues,
    calculateTotalsFromSubtotals,
    getTotalsFromAssetInitial,
} from '../common/item-utils'
import { Day } from '../common/time'
import { CalculationMode } from '../common/types/enums'
import { AssetCurrent, AssetInitial } from '../common/types/expense'
import { InputValues } from '../common/types/inputs'
import { ItemInputs } from '../common/types/item'
import { amountFromString } from './amount-from-string'
import { AssetInputs } from './components/asset-item-table'

const getVatPercentage = (strVatPercentage: string, vatPayer: boolean) => {
    if (!vatPayer) {
        return 0
    }

    return strVatPercentage === 'null' ? 0 : amountFromString(strVatPercentage)
}

const calculateAutomaticItemFromInputs = (
    itemInputs: ItemInputs<any>,
    inputValues: InputValues,
    vatPayer: boolean,
) => {
    const quantity = amountFromString(itemInputs.quantity.get(inputValues))
    const unitPrice = amountFromString(itemInputs.unitPrice.get(inputValues))
    const discount = amountFromString(itemInputs.discount.get(inputValues))
    const strVatPercentage = itemInputs.vatPercentage.get(inputValues)
    const vatPercentage = getVatPercentage(strVatPercentage, vatPayer)

    return calculateAutomaticItemFromValues(quantity, unitPrice, discount, vatPercentage)
}

const calculateManualItemFromInputs = (
    itemInputs: ItemInputs<any>,
    inputValues: InputValues,
    vatPayer: boolean,
) => {
    const strVatPercentage = itemInputs.vatPercentage.get(inputValues)
    const vatPercentage = getVatPercentage(strVatPercentage, vatPayer)
    const discountPercentage = amountFromString(itemInputs.discount.get(inputValues))
    const totalWithoutVat = amountFromString(itemInputs.payableWithoutVat!.get(inputValues))

    return calculateManualItemFromValues(totalWithoutVat, discountPercentage, vatPercentage)
}

export const calculateItemFromInputs = (
    calculationMode: CalculationMode,
    itemInputs: ItemInputs<any>,
    inputValues: InputValues,
    vatPayer: boolean,
) => {
    if (calculationMode === calculationModes.automatic) {
        return calculateAutomaticItemFromInputs(itemInputs, inputValues, vatPayer)
    } else if (calculationMode === calculationModes.manual) {
        return calculateManualItemFromInputs(itemInputs, inputValues, vatPayer)
    } else {
        throw new Error('Unexpected calculation mode: ' + calculationMode)
    }
}

export const calculateAssetInitialFromInputs = (
    assetInputs: AssetInputs,
    inputValues: InputValues,
    vatPayer: boolean,
): AssetInitial => {
    const withoutVat = amountFromString(assetInputs.withoutVat.get(inputValues))
    const other = amountFromString(assetInputs.other.get(inputValues))
    const strVatPercentage = assetInputs.vatPercentage.get(inputValues)
    const vatPercentage = getVatPercentage(strVatPercentage, vatPayer)
    return calculateAssetInitialFromValues(withoutVat, other, vatPercentage)
}

export const calculateAssetCurrentFromInputs = (
    refDate: Day,
    assetInputs: AssetInputs,
    inputValues: InputValues,
    vatPayer: boolean,
): AssetCurrent => {
    const totals = calculateAssetInitialFromInputs(assetInputs, inputValues, vatPayer)
    const initialValue = totals.payableWithoutVat
    const type = assetInputs.type.get(inputValues)
    const amortBegin = assetInputs.amortBegin.get(inputValues)
    const lifetime = assetInputs.lifetime.get(inputValues)
    let eolDate

    if (type !== assetTypes.land) {
        eolDate = Day.fromYmd(amortBegin).addYears(lifetime).ymd()
    }

    // Currently this function is only used in edit mode, which is only used for assets that
    // have not yet been confirmed. On an unconfirmed asset, valueChanges is always empty.
    return calculateAssetCurrentFromValues(refDate, type, initialValue, amortBegin, eolDate, [])
}

export const calculateTotalsFromItemInputs = (
    itemIds: string[],
    getItemInputs: (id: string) => ItemInputs<any>,
    inputValues: InputValues,
    calculationMode: CalculationMode,
    vatPayer: boolean,
) => {
    const subtotals = itemIds.map((id) => {
        const itemInputs = getItemInputs(id)
        return calculateItemFromInputs(calculationMode, itemInputs, inputValues, vatPayer)
    })

    return calculateTotalsFromSubtotals(subtotals)
}

export const calculateTotalsFromAssetInputs = (
    assetIds: string[],
    getAssetInputs: (id: string) => AssetInputs,
    inputValues: InputValues,
    vatPayer: boolean,
) => {
    const subtotals = assetIds
        .map(getAssetInputs)
        .map((assetInputs) => calculateAssetInitialFromInputs(assetInputs, inputValues, vatPayer))
        .map(getTotalsFromAssetInitial)

    return calculateTotalsFromSubtotals(subtotals)
}

export const calculateUnitPriceFromItemInputs = (
    itemInputs: ItemInputs<any>,
    inputValues: InputValues,
): number => {
    const quantity = amountFromString(itemInputs.quantity.get(inputValues))
    const payableWithoutVat = amountFromString(itemInputs.payableWithoutVat!.get(inputValues))

    return calculateManualItemUnitPriceFromValues(quantity, payableWithoutVat)
}
