import { roundToNearestCent } from '../../common/amount-utils'
import { moneyForms } from '../../common/enums'
import { findByDbId } from '../../common/find-by-db-id'
import { Day } from '../../common/time'
import { LabourPaymentType } from '../../common/types/enums'
import { PaymentInput } from '../../common/types/payment'
import { getNumeric } from '../get-numeric'
import { inputs } from '../inputs'
import { loadExpenses, loadLabourCosts } from './load-actions'
import { dispatch, getState } from './store'
import { clearErrors } from './validation-actions'

export const SAVE_PAYMENT_PROCESS = 'payment/save'
export const REMOVE_PAYMENT_PROCESS = 'payment/remove'

export const openExpensePaymentForm = async (id: string, type: 'expense' | LabourPaymentType) => {
    clearErrors(SAVE_PAYMENT_PROCESS)
    // TODO loading state?

    let rev

    if (type === 'expense') {
        await loadExpenses()
        const expense = findByDbId(getState().expenseData.expenses!, id)
        rev = expense!.rev
    } else {
        await loadLabourCosts()
        const labourCost = findByDbId(getState().labourCostData.labourCosts!, id)
        rev = labourCost!.rev
    }

    inputs.payment.rev.set(rev)
    inputs.payment.full.set('yes')
    inputs.payment.amount.set('')
    inputs.payment.moneyForm.set(moneyForms.bank)
    inputs.payment.date.set(Day.today().ymd())
    dispatch(({ expenseData }) => (expenseData.paymentState = { type, id }))
}

export const closeExpensePaymentForm = () =>
    dispatch(({ expenseData }) => {
        expenseData.paymentState = null
    })

export const getPaymentInput = (remaining: number): PaymentInput => {
    const { inputValues } = getState()

    const amount =
        inputs.payment.full.get(inputValues) === 'yes'
            ? String(roundToNearestCent(remaining))
            : getNumeric(inputs.payment.amount, inputValues)

    const moneyForm = inputs.payment.moneyForm.get(inputValues)
    const date = inputs.payment.date.get(inputValues)

    return { amount, moneyForm, date }
}
