import { dispatch } from './store'
import { clearErrors, processError } from './validation-actions'

// Wrapper for tracking active processes.
// Used for temporarily replacing buttons with loading icons and preventing multiple clicks.
// Also clears/processes validation errors.
export const run = async (processName: string, callback: () => Promise<void>) => {
    dispatch((draft) => (draft.processes = draft.processes.add(processName)))
    clearErrors(processName)

    try {
        await callback()
    } catch (error) {
        processError(error as Error, processName)
    } finally {
        // @ts-expect-error Looks like a TypeScript bug
        dispatch((draft) => (draft.processes = draft.processes.delete(processName)))
    }
}
