import React from 'react'
import ReactDOM from 'react-dom'

import { Root } from './components/root'
import { emitAfterRender, onUiStateUpdated } from './event-bus'
import { getState } from './state/store'

const render = (container: HTMLElement) => {
    const element = React.createElement(Root, getState())
    ReactDOM.render(element, container, emitAfterRender)
}

export const connectToStore = () => {
    const container = document.querySelector<HTMLElement>('#root')!

    let renderQueued = false

    // Avoid needless re-rendering by batching synchronous updates
    onUiStateUpdated(() => {
        if (!renderQueued) {
            setTimeout(() => {
                renderQueued = false
                render(container)
            }, 0)

            renderQueued = true
        }
    })

    render(container)
}
