import React, { FC } from 'react'

import { assetTypes, expenseItemTypes, expenseTypes } from '../../../common/enums'
import { ExpenseType } from '../../../common/types/enums'
import { InputValues } from '../../../common/types/inputs'
import { t } from '../../i18n'
import { renderInputOrValue } from '../../input-utils'
import { inputs } from '../../inputs'
import { addAsset, addItem } from '../../state/expense-actions'
import { Button } from '../button'
import { CharactersLeft } from '../characters-left'

interface Props {
    editMode: boolean
    inputValues: InputValues
    type: ExpenseType
}

const addGoodsExpense = async () => addItem(expenseItemTypes.goodsExpense)
const addGoodsStock = async () => addItem(expenseItemTypes.goodsStock)
const addGeneral = async () => addItem(expenseItemTypes.general)

const addLand = async () => addAsset(assetTypes.land)
const addBuilding = async () => addAsset(assetTypes.building)
const addDevice = async () => addAsset(assetTypes.device)
const addOtherMaterial = async () => addAsset(assetTypes.otherMaterial)
const addSoftware = async () => addAsset(assetTypes.software)
const addLicense = async () => addAsset(assetTypes.license)
const addOtherImmaterial = async () => addAsset(assetTypes.otherImmaterial)

const renderAddItemButtons = (editMode: boolean, type: ExpenseType) => {
    if (!editMode || type !== expenseTypes.regular) {
        return null
    }

    return React.createElement(
        'div',
        { className: 'expense__add-buttons' },
        React.createElement(Button, {
            text: t.expenses.add.goods.expense.get(),
            onClick: addGoodsExpense,
            className: 'button--secondary',
        }),
        ' ',
        React.createElement(Button, {
            text: t.expenses.add.goods.stock.get(),
            onClick: addGoodsStock,
            className: 'button--secondary',
        }),
        ' ',
        React.createElement(Button, {
            text: t.expenses.add.general.get(),
            onClick: addGeneral,
            className: 'button--secondary',
        }),
    )
}

const renderAddAssetButtons = (editMode: boolean, type: ExpenseType) => {
    if (!editMode || type !== expenseTypes.asset) {
        return null
    }

    return React.createElement(
        'div',
        { className: 'expense__add-buttons' },
        React.createElement(
            'div',
            { className: 'expense__add-button-line' },
            React.createElement(Button, {
                text: t.assets.add.land.get(),
                onClick: addLand,
                className: 'button--secondary',
            }),
            ' ',
            React.createElement(Button, {
                text: t.assets.add.building.get(),
                onClick: addBuilding,
                className: 'button--secondary',
            }),
            ' ',
            React.createElement(Button, {
                text: t.assets.add.device.get(),
                onClick: addDevice,
                className: 'button--secondary',
            }),
            ' ',
            React.createElement(Button, {
                text: t.assets.add.otherMaterial.get(),
                onClick: addOtherMaterial,
                className: 'button--secondary',
            }),
        ),
        React.createElement(
            'div',
            { className: 'expense__add-button-line' },
            React.createElement(Button, {
                text: t.assets.add.software.get(),
                onClick: addSoftware,
                className: 'button--secondary',
            }),
            ' ',
            React.createElement(Button, {
                text: t.assets.add.license.get(),
                onClick: addLicense,
                className: 'button--secondary',
            }),
            ' ',
            React.createElement(Button, {
                text: t.assets.add.otherImmaterial.get(),
                onClick: addOtherImmaterial,
                className: 'button--secondary',
            }),
        ),
    )
}

const renderComment = (editMode: boolean, inputValues: InputValues) =>
    React.createElement(
        'div',
        { className: 'expense__comment-area' },
        renderInputOrValue(editMode, {
            type: 'multiline',
            input: inputs.expense.comment,
            inputValues,
            placeholder: t.expenses.comment.input.get(),
            className: 'expense__comment',
            disabled: !editMode,
            maxLength: 2000,
        }),
        editMode &&
            React.createElement(CharactersLeft, {
                current: inputs.expense.comment.get(inputValues).length,
                max: 2000,
            }),
    )

export const ExpenseBottomLeft: FC<Props> = ({ editMode, inputValues, type }) =>
    React.createElement(
        'div',
        null,
        renderAddItemButtons(editMode, type),
        renderAddAssetButtons(editMode, type),
        renderComment(editMode, inputValues),
    )
