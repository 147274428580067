import numeral from 'numeral'
import 'numeral/locales/et'

import { Language, t } from './i18n'

export const formatAmount = (language: Language, amount: number, noDash: boolean) => {
    const num = numeral(amount)

    if (!noDash && num.value() === 0) {
        return '-'
    }

    if (num.value() === null) {
        return t.notANumber.getForLanguage(language)
    } else {
        // Numeral.js does not (yet) support instance-level locales,
        // so we set the global locale and then revert it.
        const prevLocale = numeral.locale()
        numeral.locale(language)
        const result = num.format('0,0.00')
        numeral.locale(prevLocale)
        return result
    }
}
