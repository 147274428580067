import React, { FC, Fragment } from 'react'

import { highlight } from '../../business-utils'
import { Button, ButtonProps } from '../button'
import { ChoiceProps, renderChoice } from '../choice'
import { Input, InputProps } from '../input'
import { Link, LinkProps } from '../link'
import { LoadingPage } from '../loading-page'
import { RevenueArchiveSidebar } from './archive-sidebar'

export interface ChosenBusinessProps {
    text: string
    button: Omit<ButtonProps, 'className'>
}

export interface FoundBusinessProps {
    onClick: () => void
    businessName: string
    searchText: string
    note: string
}

interface BusinessSearchProps {
    input: InputProps
    foundBusinesses?: FoundBusinessProps[]
    noneFoundText?: string
}

interface BusinessProps {
    title: string
    chosenBusiness?: ChosenBusinessProps
    search?: BusinessSearchProps
}

export interface RevenueArchiveCustomerOkProps {
    loading: false
    title: string
    customerType: ChoiceProps<string>
    business?: BusinessProps
    forwardLink: LinkProps
    showLink: LinkProps
    clearFiltersButton?: ButtonProps
}

export type RevenueArchiveCustomerProps = { loading: true } | RevenueArchiveCustomerOkProps

const ChosenBusiness: FC<ChosenBusinessProps> = ({ text, button }) =>
    React.createElement(
        'span',
        null,
        text,
        ' ',
        React.createElement(Button, { ...button, className: 'button--primary' }),
    )

const FoundBusiness: FC<FoundBusinessProps> = ({ onClick, businessName, searchText, note }) =>
    React.createElement(
        'div',
        { onClick, className: 'business-lookup__match' },
        highlight(businessName, searchText),
        React.createElement('div', { className: 'business-lookup__match-note' }, note),
    )

const BusinessSearch: FC<BusinessSearchProps> = ({ input, foundBusinesses, noneFoundText }) =>
    React.createElement(
        'div',
        null,
        React.createElement(Input, input),
        foundBusinesses &&
            React.createElement(
                'div',
                { className: 'business-lookup__matches top-margin' },
                ...foundBusinesses.map((business) => React.createElement(FoundBusiness, business)),
            ),
        noneFoundText && React.createElement('div', { className: 'top-margin' }, noneFoundText),
    )

const Business: FC<BusinessProps> = ({ title, chosenBusiness, search }) =>
    React.createElement(
        Fragment,
        null,
        React.createElement('h1', { className: 'title archive__title' }, title),
        chosenBusiness && React.createElement(ChosenBusiness, chosenBusiness),
        search && React.createElement(BusinessSearch, search),
    )

export const RevenueArchiveCustomer: FC<RevenueArchiveCustomerProps> = (props) => {
    if (props.loading) {
        return React.createElement(LoadingPage)
    }

    const { title, customerType, business, forwardLink, showLink, clearFiltersButton } = props

    return React.createElement(
        'div',
        { className: 'content-area' },
        React.createElement(RevenueArchiveSidebar, { selected: 'customer' }),
        React.createElement(
            'div',
            { className: 'content archive' },
            React.createElement(
                'div',
                null,
                React.createElement('h1', { className: 'title archive__title' }, title),
                React.createElement(
                    'div',
                    null,
                    renderChoice({
                        ...customerType,
                        buttonClassName: 'button--secondary',
                        selectedButtonClassName: 'button--primary',
                    }),
                ),
                business && React.createElement(Business, business),
            ),
            React.createElement(
                'div',
                { className: 'top-margin' },
                React.createElement(Link, { ...forwardLink, className: 'button button--primary' }),
                ' ',
                React.createElement(Link, { ...showLink, className: 'button button--secondary' }),
                ' ',
                clearFiltersButton && React.createElement(Button, clearFiltersButton),
            ),
        ),
    )
}
