import React, { Component } from 'react'

import { range } from '../../common/range'
import { Day } from '../../common/time'
import { Input, InputValues } from '../../common/types/inputs'
import { createCustomInput, wrapAsStringInput } from '../input-utils'
import { Button } from './button'
import { renderChoice } from './choice'
import { MonthInput } from './month-input'

interface Props {
    input: Input<string>
    inputValues: InputValues
    min?: Day
    max?: Day
    mainButtonClass: string
    monthButtonClass: string
    selectedMonthButtonClass: string
}

// Keep year in component state to avoid having to use additional keys in inputValues
interface State {
    expanded: boolean
    year: number
}

const getInitialYear = (props: Props) => {
    const value = props.input.get(props.inputValues)

    if (value) {
        return Day.fromYm(value).year()
    } else {
        if (props.min?.isAfter(Day.today())) {
            return props.min.year()
        } else if (props.max?.isBefore(Day.today())) {
            return props.max.year()
        } else {
            return Day.today().year()
        }
    }
}

export class MonthButton extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = { expanded: false, year: getInitialYear(props) }
    }

    renderPicker(month: Day) {
        const { expanded, year } = this.state

        if (!expanded) {
            return null
        }

        const { input, monthButtonClass, selectedMonthButtonClass } = this.props

        const monthInput = createCustomInput({
            inputType: 'number',
            get: () => (month.year() === year ? month.month() + 1 : -1),
            set: (newMonth: number) => {
                const value = Day.fromNumeric(year, newMonth, 1).ym()
                input.set(value)
                this.setState({ expanded: false })
            },
        })

        const yearInput = createCustomInput({
            inputType: 'number',
            get: () => year,
            set: (newYear: number) => this.setState({ year: newYear }),
        })

        const min = this.props.min || Day.today().firstOfYear().addYears(-1)
        const max = this.props.max || Day.today().lastOfYear().addYears(9)

        const yearOptions = range(min.year(), max.year()).map((yearChoice) => ({
            id: String(yearChoice),
            label: String(yearChoice),
        }))

        const minMonth = year === min.year() ? min.month() : 1
        const maxMonth = year === max.year() ? max.month() : 12

        return React.createElement(
            'div',
            { className: 'month-picker' },
            React.createElement(MonthInput, {
                input: monthInput,
                inputValues: {},
                minMonth,
                maxMonth,
                buttonClassName: monthButtonClass,
                selectedButtonClassName: selectedMonthButtonClass,
            }),
            React.createElement(
                'div',
                null,
                renderChoice({
                    type: 'dropdown',
                    inputValues: {},
                    input: wrapAsStringInput(yearInput, String, Number),
                    options: yearOptions,
                    forceSelection: true,
                    groupClassName: 'year-dropdown',
                }),
            ),
        )
    }

    override render() {
        const { input, inputValues, mainButtonClass } = this.props
        const { expanded } = this.state

        const monthStr = input.get(inputValues)
        const month = Day.fromYm(monthStr)

        return React.createElement(
            'div',
            { className: 'inline' },
            React.createElement(Button, {
                text: month.longMonth(),
                onClick: () => this.setState({ expanded: !expanded }),
                className: mainButtonClass,
            }),
            this.renderPicker(month),
        )
    }
}
