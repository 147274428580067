import { BANK, CASH } from '../../common/accounts'
import { roundToNearestCent } from '../../common/amount-utils'
import { ServerError } from '../../common/server-error'
import { Day } from '../../common/time'
import { VatTableMode } from '../../common/types/taxes'
import { VatPaymentInput } from '../../common/types/vat'
import { VatCalc } from '../../common/vat-utils'
import { amountFromString } from '../amount-from-string'
import * as api from '../api'
import { fromErrorCode, processWarning } from '../error-manager'
import { getNumeric } from '../get-numeric'
import { t } from '../i18n'
import { inputs } from '../inputs'
import { clearInputs } from './input-actions'
import { setInvalid } from './invalid-cache-actions'
import {
    loadCreditRevenues,
    loadExpenses,
    loadLabourCosts,
    loadRevenues,
    loadVatState,
} from './load-actions'
import { run } from './process-actions'
import { dispatch, getDefaultVatIds, getState } from './store'

export interface VatViewParams {
    month?: string
}

export const VAT_PAYMENT_PROCESS = 'taxes/vat/payment'
export const ASSIGN_REVENUES_PROCESS = 'taxes/vat/assign/revenues'
export const ASSIGN_EXPENSES_PROCESS = 'taxes/vat/assign/expenses'

export const initSummary = () => {
    void loadRevenues()
    void loadExpenses()
    void loadLabourCosts()
}

export const initVat = () => {
    void loadRevenues()
    void loadCreditRevenues()
    void loadExpenses()
    void loadLabourCosts()
    void loadVatState()

    clearInputs(inputs.taxes.vat)

    dispatch(({ taxes: { vat } }) => {
        vat.paymentOpen = false
        vat.revenuesMode = VatTableMode.collapsed
        vat.expensesMode = VatTableMode.collapsed
        vat.ids = getDefaultVatIds()
        vat.declarationExpanded = false
    })
}

export const editRevenueSelection = (month: string) => {
    const {
        invoiceData: { invoices },
        creditRevenueData: { creditRevenues },
    } = getState()
    const revenueIds = new Set<string>()

    for (const revenue of invoices!) {
        if (revenue.vatMonth === month) {
            revenueIds.add(revenue._id)
        }
    }

    const creditRevenueIds = new Set<string>()

    for (const creditRevenue of creditRevenues!) {
        if (creditRevenue.vatMonth === month) {
            creditRevenueIds.add(creditRevenue._id)
        }
    }

    closeVatPayment()

    dispatch(({ taxes: { vat } }) => {
        vat.revenuesMode = VatTableMode.editing
        vat.ids.revenue = revenueIds
        vat.ids.creditRevenue = creditRevenueIds
        vat.declarationExpanded = false
    })
}

export const cancelRevenueSelection = () =>
    dispatch(({ taxes: { vat } }) => {
        vat.revenuesMode = VatTableMode.collapsed
        vat.ids.revenue.clear()
    })

export const assignVatMonthToRevenues = async (month: string) =>
    run(ASSIGN_REVENUES_PROCESS, async () => {
        const {
            taxes: {
                vat: { ids },
            },
        } = getState()

        await api.assignVatMonth({
            month,
            revenueIds: [...ids.revenue],
            creditRevenueIds: [...ids.creditRevenue],
        })

        cancelRevenueSelection()
        setInvalid('invoice')
        setInvalid('credit-revenue')
        await Promise.all([loadRevenues(), loadCreditRevenues()])
    })

export const editExpenseSelection = (month: string) => {
    const {
        expenseData: { expenses },
    } = getState()
    const expenseIds = new Set<string>()

    for (const expense of expenses!) {
        if (expense.vatMonth === month) {
            expenseIds.add(expense._id)
        }
    }

    closeVatPayment()

    dispatch(({ taxes: { vat } }) => {
        vat.expensesMode = VatTableMode.editing
        vat.ids.expense = expenseIds
        vat.declarationExpanded = false
    })
}

export const cancelExpenseSelection = () =>
    dispatch(({ taxes: { vat } }) => {
        vat.expensesMode = VatTableMode.collapsed
        vat.ids.expense.clear()
    })

export const assignVatMonthToExpenses = async (month: string) =>
    run(ASSIGN_EXPENSES_PROCESS, async () => {
        const {
            taxes: {
                vat: { ids },
            },
        } = getState()
        await api.assignVatMonth({ month, expenseIds: [...ids.expense] })
        cancelExpenseSelection()
        setInvalid('expense')
        await loadExpenses()
    })

export const setRevenuesMode = (mode: VatTableMode) =>
    dispatch(({ taxes: { vat } }) => {
        vat.revenuesMode = mode
    })

export const setExpensesMode = (mode: VatTableMode) =>
    dispatch(({ taxes: { vat } }) => {
        vat.expensesMode = mode
    })

export const showDeclarationInfo = () =>
    dispatch(({ taxes: { vat } }) => {
        vat.declarationExpanded = true
    })

export const hideDeclarationInfo = () =>
    dispatch(({ taxes: { vat } }) => {
        vat.declarationExpanded = false
    })

export const openVatPayment = () => {
    clearInputs(inputs.taxes.vat.payment)
    dispatch(({ taxes: { vat } }) => (vat.paymentOpen = true))
}

export const closeVatPayment = () => {
    dispatch(({ taxes: { vat } }) => (vat.paymentOpen = false))
    clearInputs(inputs.taxes.vat.payment)
}

export const clearVatState = () => {
    closeVatPayment()
    cancelExpenseSelection()
    cancelRevenueSelection()
    dispatch(({ taxes: { vat } }) => {
        vat.declarationExpanded = false
    })
}

const getNegativeBalanceErrorMessage = (error: ServerError): string => {
    const { account } = error.response
    const date = Day.fromYmd(error.response.date).dmy()

    if (account === CASH) {
        return t.taxes.vat.negativeBalance.cash.get(date)
    } else if (account === BANK) {
        return t.taxes.vat.negativeBalance.bank.get(date)
    }

    return t.taxes.vat.negativeBalance.get(date, account)
}

export const addVatPayment = async (calc: VatCalc) =>
    run(VAT_PAYMENT_PROCESS, async () => {
        const { month, expenseVat, revenueVatLocal, revenueVatMta, differenceMta } = calc

        const { inputValues } = getState()
        const paymentInputs = inputs.taxes.vat.payment

        const fromPrepaid =
            differenceMta < 0
                ? differenceMta
                : amountFromString(paymentInputs.amount.get(inputValues))

        const monthYm = month.ym()

        const payment: VatPaymentInput = {
            date: paymentInputs.date.get(inputValues),
            month: monthYm,
            expenseVat: roundToNearestCent(expenseVat),
            revenueVatLocal: roundToNearestCent(revenueVatLocal),
            revenueVatMta: roundToNearestCent(revenueVatMta),
            fromPrepaid: roundToNearestCent(fromPrepaid),
            interest: getNumeric(paymentInputs.interest, inputValues),
        }

        try {
            await api.addVatPayment(payment)
            closeVatPayment()
            setInvalid('vat-state')
            await loadVatState()
        } catch (error) {
            if (error instanceof ServerError && error.response.errorCode === 'negative-balance') {
                const message = getNegativeBalanceErrorMessage(error)
                processWarning(fromErrorCode('negative-balance').withMessage(message).dontReport())
            } else {
                throw error
            }
        }
    })
