import { enableMapSet } from 'immer'
import moment from 'moment'
import 'moment/locale/et'
import numeral from 'numeral'
import 'numeral/locales/et'

import * as BannerManager from './banner-manager'
import { addError, init as initErrorManager } from './error-manager'
import { runAfterStoreReset } from './event-bus'
import { setCurrentLanguage } from './i18n'
import { initSentry } from './init-sentry'
import * as Renderer from './renderer'
import './scss/index.scss'
import * as SessionActions from './state/session-actions'
import { init as initStore } from './state/store'
import { enableDebug as enableValidationDebug } from './state/validation-actions'
import { updateView } from './state/view-actions'

moment.locale('et')
numeral.locale('et')
enableMapSet()

const run = async () => {
    initSentry()
    setCurrentLanguage('et')

    window.addEventListener('error', (evt) => {
        if (process.env.NODE_ENV === 'development' && evt.error) {
            // React may cause the same error to be dispatched twice in development mode.
            // We tag the error with a boolean property unlikely to be used by other code.
            // More info: https://github.com/facebook/react/issues/10384
            if (evt.error.markedForReactWeirdness) {
                evt.preventDefault()
                return
            }

            evt.error.markedForReactWeirdness = true
        }

        const error = (evt.error as Error) || new Error(evt.message)

        void addError(error)

        evt.preventDefault()
    })

    const windowAny: any = window

    // Can't use window.addEventListener('unhandledrejection', ...) because it
    // doesn't work well when a Promise polyfill is used, for example in Firefox.
    windowAny.onunhandledrejection = (evt: PromiseRejectionEvent) => {
        void addError(evt.reason)

        if (evt.preventDefault) {
            evt.preventDefault()
        }
    }

    window.addEventListener('hashchange', updateView)

    initErrorManager()
    initStore()
    enableValidationDebug()

    BannerManager.initForBrowser() // Must be before SessionActions init
    SessionActions.init()
    updateView()

    // Subscribe to future resets
    runAfterStoreReset(Renderer.connectToStore)

    // Establish first connection manually
    Renderer.connectToStore()
}

run().catch((error) => console.error(error))

// Avoid import-is-undefined error from sentryProxyModule
export default undefined
