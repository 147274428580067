import {
    Column,
    Content,
    ContentTable,
    ContentText,
    CustomTableLayout,
    Style,
    Table,
    TableCell,
    TDocumentDefinitions,
    TFontDictionary,
} from 'pdfmake/interfaces'

import { MIN_DATE } from './clock'
import { formatAmount } from './format-amount'
import { Language, t } from './i18n'
import { calculateAutomaticItem, calculateTotalsFromSubtotals } from './item-utils'
import { Day } from './time'
import { ApiCustomer, RevenueItem } from './types/invoice'
import { Totals } from './types/item'
import { VendorFields } from './types/vendor'

// pdfMake uses quite a lot of global state and its TypeScript declarations are
// provided by the community and pretty inaccurate, so this is all rather messy.

// This might help: https://github.com/bpampuch/pdfmake/pull/1587

export interface PdfMakeGlobal {
    vfs: Record<string, string>
    fonts: TFontDictionary
    tableLayouts: Record<string, CustomTableLayout>
}

interface PdfColumn {
    header: string
    width?: '*'
    getCell: (item: RevenueItem<number>, totals: Totals<number>) => TableCell | string
}

export interface RevenuePdfInputs {
    number: string | null
    customer: ApiCustomer
    vatPayer: boolean
    date: Day
    dueDate: Day
    items: RevenueItem<number>[]
    comment: string
    vendor: VendorFields<string>
    logoDataUrl: string
}

interface PdfItemTables {
    itemTable: Table
    totalsTableNode: ContentTable | null
    totalBox: Table
}

// TODO move more style information to global state for cleaner test snapshots?

const getCompanyLogoNode = (dataUrl: string): Content => ({
    margin: [19, 25, 0, 65],
    image: dataUrl,
    fit: [300, 42],
})

const getVendorNameNode = (vendorName: string): Content => ({
    margin: [19, 40, 0, 65],
    fontSize: 18,
    text: vendorName,
})

const getCustomerLines = (lang: Language, customer: ApiCustomer, vatPayer: boolean) => {
    if (customer.isBusiness) {
        const lines = [
            customer.name,
            customer.address,
            t.business.regCode.getForLanguage(lang) + ': ' + customer.regCode,
        ]

        if (vatPayer && customer.vatId) {
            lines.push(t.business.vatId.getForLanguage(lang) + ': ' + customer.vatId)
        }

        return lines
    } else {
        return [customer.details || t.citizen.getForLanguage(lang)]
    }
}

const getTotalBox = (lang: Language, total: number): Table => ({
    body: [
        [
            {
                stack: [
                    {
                        font: 'Roboto-Medium',
                        fontSize: 7,
                        bold: true,
                        characterSpacing: 0.4,
                        lineHeight: 2.2,
                        marginTop: 1,
                        marginRight: 70, // Simulate minimum width for box, but allow it to grow wider
                        text: t.payableTotal.getForLanguage(lang).toUpperCase(),
                    },
                    {
                        font: 'Roboto-Thin',
                        fontSize: 18,
                        marginBottom: 1,
                        text: formatAmount(lang, total, true) + ' €',
                    },
                ],
                noWrap: true,
            },
        ],
    ],
})

const getDiscountRow = (lang: Language, discount: number): TableCell[] => [
    { text: t.discount.getForLanguage(lang), noWrap: true },
    { text: formatAmount(lang, discount, true) + ' €', style: 'totalAmount' },
]

// marginTop is missing from typedef
const wrapTotalsTable = (body: TableCell[][]): Column & ContentTable & { marginTop: number } => ({
    width: 'auto',
    marginTop: 20,
    table: {
        // Treat all rows as headers so they won't split over a page break
        headerRows: body.length,
        body,
    },
    layout: 'invoice-totals',
})

const getTotalsTableNode = (lang: Language, vatPayer: boolean, totals: Totals<number>) => {
    const body: TableCell[][] = []

    if (totals.discount > 0) {
        body.push([
            { text: t.sumBeforeDiscount.getForLanguage(lang), noWrap: true },
            {
                text: formatAmount(lang, totals.totalWithoutVat, true) + ' €',
                style: 'totalAmount',
            },
        ])

        body.push(getDiscountRow(lang, totals.discount))
    }

    if (vatPayer) {
        body.push([
            { text: t.sumWithoutVat.getForLanguage(lang), noWrap: true },
            {
                text: formatAmount(lang, totals.payableWithoutVat, true) + ' €',
                style: 'totalAmount',
            },
        ])

        body.push([
            { text: t.vat.getForLanguage(lang), noWrap: true },
            { text: formatAmount(lang, totals.vatAmount, true) + ' €', style: 'totalAmount' },
        ])

        return wrapTotalsTable(body)
    }

    return body.length ? wrapTotalsTable(body) : null
}

const getPdfItemTables = (
    lang: Language,
    items: RevenueItem<number>[],
    vatPayer: boolean,
): PdfItemTables => {
    const columns: PdfColumn[] = [
        {
            header: t.description.getForLanguage(lang),
            width: '*',
            getCell: (item) => ({
                text: item.description,
                style: 'itemTableLeftColumn',
            }),
        },
        {
            header: t.quantity.getForLanguage(lang),
            getCell: (item) => {
                // Not using formatAmount to get integers without ',00'
                let text = String(item.quantity).replace('.', ',')

                if (item.unit) {
                    text += ' ' + t.enums.units[item.unit].getForLanguage(lang)
                }

                return { text, alignment: 'center' }
            },
        },
        {
            header: t.unitPrice.getForLanguage(lang) + '\u00a0€',
            getCell: (item) => ({
                text: formatAmount(lang, item.unitPrice, true),
                style: 'numericCell',
            }),
        },
    ]

    if (vatPayer) {
        columns.push({
            header: t.sumWithoutVat.short.getForLanguage(lang) + '\u00a0€',
            getCell: (_item, totals) => ({
                text: formatAmount(lang, totals.totalWithoutVat, true),
                style: 'numericCell',
            }),
        })
    }

    const hasDiscount = items.some((item) => item.discount > 0)

    if (hasDiscount) {
        columns.push({
            header: t.discount.getForLanguage(lang),
            getCell: (item) => {
                const discountAmt = (item.discount / 100) * item.unitPrice

                return {
                    // Not using formatAmount to get integers without ',00'
                    text:
                        '(' + String(item.discount).replace('.', ',') + '%) ' + discountAmt + ' €',
                    alignment: 'center',
                    noWrap: true,
                }
            },
        })
    }

    if (vatPayer) {
        columns.push({
            header: t.vat.getForLanguage(lang) + '\u00a0€',
            getCell: (item, totals) => ({
                text: '(' + item.vatPercentage + '%) ' + formatAmount(lang, totals.vatAmount, true),
                style: 'numericCell',
            }),
        })
    }

    columns.push({
        header: t.total.getForLanguage(lang) + '\u00a0€',
        getCell: (_item, totals) => ({
            text: formatAmount(lang, totals.payableWithVat, true),
            style: 'numericCell',
            marginRight: 15,
        }),
    })

    const widths: string[] = columns.map(({ width }) => width || 'auto')

    const headerRow: TableCell[] = columns.map(({ header }, idx): TableCell => {
        let style = 'itemTableColumn'

        if (idx === 0) {
            style = 'itemTableLeftColumn'
        } else if (idx === columns.length - 1) {
            style = 'itemTableRightColumn'
        }

        return {
            text: header.toUpperCase(),
            style,
            fontSize: 7,
            bold: true,
            characterSpacing: 0.4,
        }
    })

    const itemsBody: TableCell[][] = [headerRow]
    const subtotals: Totals<number>[] = []

    for (const item of items) {
        // Revenue item calculations currently don't depend on the date, so we can always pass MIN_DATE
        const itemTotals = calculateAutomaticItem(item, MIN_DATE)
        const row: TableCell[] = []

        for (const column of columns) {
            row.push(column.getCell(item, itemTotals))
        }

        itemsBody.push(row)
        subtotals.push(itemTotals)
    }

    const totals = calculateTotalsFromSubtotals(subtotals)

    return {
        itemTable: {
            headerRows: 1,
            dontBreakRows: true,
            widths,
            body: itemsBody,
        },
        totalsTableNode: getTotalsTableNode(lang, vatPayer, totals),
        totalBox: getTotalBox(lang, totals.payableWithVat),
    }
}

const getCommentNode = (
    comment: string | undefined,
    totalBoxTopMargin: number,
): ContentText | null => {
    if (comment) {
        // Should be vertically aligned with the first line in the total box.
        return { margin: [20, totalBoxTopMargin + 17, 0, 0], text: comment }
    } else {
        return null
    }
}

const getFooterLeftColumn = (
    lang: Language,
    vendor: VendorFields<string>,
    vatPayer: boolean,
): Column => {
    const stack: Content[] = [
        vendor.name,
        vendor.address,
        t.business.regCode.getForLanguage(lang) + ': ' + vendor.regCode,
    ]

    if (vatPayer && vendor.vatId) {
        stack.push(t.business.vatId.getForLanguage(lang) + ': ' + vendor.vatId)
    }

    return { stack }
}

const getFooterMiddleColumn = (vendor: VendorFields<string>): Column => {
    const { phone, email, website } = vendor
    const stack = [email, website]

    if (phone) {
        stack.unshift(phone)
    }

    return { alignment: 'center', stack }
}

export const getPdfDefinition = (
    inputs: RevenuePdfInputs,
    lang: Language,
    isCredit: boolean,
): TDocumentDefinitions => {
    const { number, customer, vatPayer, date, dueDate, items, comment, vendor, logoDataUrl } =
        inputs

    const title = isCredit
        ? t.revenues.creditRevenue.getForLanguage(lang) + ' ' + number
        : t.invoice.getForLanguage(lang) + (number ? ' ' + number : '')

    const customerLines = getCustomerLines(lang, customer, vatPayer)
    const { itemTable, totalsTableNode, totalBox } = getPdfItemTables(lang, items, vatPayer)

    const totalBoxTopMargin = totalsTableNode ? 6 : 28
    const commentNode = getCommentNode(comment, totalBoxTopMargin)
    const footerLeftColumn = getFooterLeftColumn(lang, vendor, vatPayer)
    const footerMiddleColumn = getFooterMiddleColumn(vendor)

    const logoNode: Content = logoDataUrl
        ? getCompanyLogoNode(logoDataUrl)
        : getVendorNameNode(vendor.name)

    const totalsTableColumns: Array<Column | null> = [null, totalsTableNode]

    // marginTop is missing from typedefs
    const commentsTotalColumns: Array<(Column & { marginTop?: number }) | null> = [
        commentNode,
        {
            width: 'auto',
            marginTop: totalBoxTopMargin,
            table: totalBox,
            layout: 'total-box',
        },
    ]

    const revenueText = isCredit
        ? t.revenues.creditRevenue.getForLanguage(lang).toUpperCase()
        : t.revenues.invoice.getForLanguage(lang).toUpperCase()

    const content: Content = {
        lineHeight: 1.3,
        stack: [
            logoNode,
            {
                margin: [19, 0, 15, 0],
                columns: [
                    {
                        stack: [
                            {
                                margin: [0, 0, 0, 4],
                                fontSize: 10,
                                bold: true,
                                characterSpacing: 0.3,
                                text: t.revenues.recipient.getForLanguage(lang).toUpperCase(),
                            },
                            ...customerLines,
                        ],
                    },
                    {
                        width: 'auto',
                        alignment: 'right',
                        stack: [
                            {
                                margin: [0, 0, 0, 2],
                                fontSize: 10,
                                bold: true,
                                characterSpacing: 0.3,
                                text: revenueText,
                            },
                            {
                                alignment: 'left',
                                table: {
                                    heights: [null, null, null, 25] as number[],
                                    body: [
                                        [
                                            t.invoices.number.getForLanguage(lang),
                                            { text: number || '', style: 'numericCell' },
                                        ],
                                        [
                                            t.invoices.date.getForLanguage(lang),
                                            { text: date.dmy(), style: 'numericCell' },
                                        ],
                                        [
                                            t.dueDate.getForLanguage(lang),
                                            { text: dueDate.dmy(), style: 'numericCell' },
                                        ],
                                        // Dummy row with fixed height.
                                        // The item table should have a fixed distance from the due date line
                                        // and not be affected by the customer lines unless they get very long.
                                        ['', ''],
                                    ],
                                },
                                layout: 'invoice-number-date',
                            },
                        ],
                    },
                ],
            },
            {
                margin: [0, 5, 0, 0],
                table: itemTable,
                layout: 'invoice-items',
            },
            {
                columns: totalsTableColumns as Column[],
            },
            {
                columns: commentsTotalColumns as Column[],
            },
        ],
    }

    // marginLeft and marginRight are missing from typedefs
    const styles: Record<string, Style & { marginLeft?: number; marginRight?: number }> = {
        numericCell: {
            alignment: 'right',
            noWrap: true,
        },
        totalAmount: {
            alignment: 'right',
            noWrap: true,
            // Right align the numbers to those in the last column of item table
            marginRight: 12.8,
        },
        itemTableLeftColumn: {
            alignment: 'left',
            marginLeft: 15,
            marginRight: 1,
        },
        itemTableColumn: {
            alignment: 'center',
            marginLeft: 1,
            marginRight: 1,
        },
        itemTableRightColumn: {
            alignment: 'center',
            marginLeft: 1,
            marginRight: 15,
        },
    }

    return {
        info: { title },
        compress: true,
        pageSize: 'A4',
        pageMargins: [40, 60, 40, 110],
        defaultStyle: {
            font: 'Roboto-Light',
            fontSize: 6,
            characterSpacing: 0.6,
        },
        content,
        footer: {
            margin: [59, 46],
            stack: [
                {
                    image: 'small-booky-logo.png',
                    width: 43,
                    absolutePosition: { x: 493, y: 7 },
                },
                {
                    canvas: [
                        {
                            type: 'line',
                            x1: -15,
                            y1: -12,
                            x2: 500,
                            y2: -12,
                            lineWidth: 0.1,
                            lineColor: '#000000',
                        },
                    ],
                },
                {
                    lineHeight: 1.2,
                    columns: [
                        footerLeftColumn,
                        footerMiddleColumn,
                        {
                            alignment: 'right',
                            stack: [
                                // TODO multiple bank accounts
                                vendor.bankAccounts[0].name,
                                'IBAN: ' + vendor.bankAccounts[0].number,
                            ],
                        },
                    ],
                },
            ],
        },
        styles,
    }
}

export const fonts: TFontDictionary = {
    'Roboto-Thin': {
        normal: 'Roboto-Thin.ttf',
        bold: 'Roboto-Light.ttf', // TODO different files for these?
        italics: 'Roboto-Thin.ttf',
        bolditalics: 'Roboto-Thin.ttf',
    },
    'Roboto-Light': {
        normal: 'Roboto-Light.ttf',
        bold: 'Roboto-Regular.ttf', // TODO different files for these?
        italics: 'Roboto-Light.ttf',
        bolditalics: 'Roboto-Light.ttf',
    },
    'Roboto-Medium': {
        normal: 'Roboto-Medium.ttf',
        bold: 'Roboto-Medium.ttf', // TODO different files for these?
        italics: 'Roboto-Medium.ttf',
        bolditalics: 'Roboto-Medium.ttf',
    },
}

const BGCOLOR = '#efeff9'

export const tableLayouts: Record<string, CustomTableLayout> = {
    'invoice-number-date': {
        defaultBorder: false,
        paddingTop: () => 4,
        paddingBottom: () => 4,
    },
    'invoice-items': {
        fillColor: (rowIndex: number) => (rowIndex === 0 ? BGCOLOR : null),
        hLineColor: () => BGCOLOR,
        hLineWidth: () => 0.1,
        vLineWidth: () => 0,
        paddingTop: () => 7,
        paddingBottom: (rowIndex: number) => (rowIndex === 0 ? 3 : 5),
    },
    'invoice-totals': {
        defaultBorder: false,
        paddingLeft: () => 5,
        paddingRight: () => 5,
        paddingTop: () => 5,
        paddingBottom: () => 5,
    },
    'total-box': {
        defaultBorder: false,
        paddingLeft: () => 10,
        paddingRight: () => 10,
        paddingTop: () => 15,
        paddingBottom: () => 5,
        fillColor: () => BGCOLOR,
    },
}
