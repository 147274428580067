import React from 'react'

interface Props {
    modifierClass: string
}

export const ArrowIcon = ({ modifierClass }: Props) =>
    React.createElement('img', {
        src: '/icons/triangle-white.svg',
        className: 'arrow ' + modifierClass,
    })
